
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (actions)
import {setCurrentExam} from './actions'

// redux (selectors)
import {getSchedulesData} from 'redux/reducers/selectors'
import {getScheduleDataByExamGuid, getScheduleCount} from 'redux/reducers/schedules/selectors'
import {getExamName, getScheduleGuid, isSchedulePractice} from 'redux/reducers/schedules/schedule/selectors'



// ScheduleDataChecker (not connected to store)
// ----------------------------------------------

class ScheduleDataChecker extends React.Component
{
	componentDidMount()
	{
		const {scheduleCount, schedule} = this.props;

		if (scheduleCount === 0) {
			this.props.onSchedulesNotLoaded();
		}
		else if (!schedule) {
			this.props.onScheduleNotFound();
		}
		else {
			const examName = getExamName(schedule);
			const scheduleGuid = getScheduleGuid(schedule);
			const practiceExam = isSchedulePractice(schedule);
			this.props.onSuccess(examName, scheduleGuid, practiceExam);
		}
	}

	render()
	{
		return null;
	}
}

ScheduleDataChecker.propTypes = {
	schedule: PropTypes.object,
	onSuccess: PropTypes.func.isRequired,
	onScheduleNotFound: PropTypes.func.isRequired,
	onSchedulesNotLoaded: PropTypes.func.isRequired,
	scheduleCount: PropTypes.number.isRequired
}


// ScheduleDataChecker (not connected to store)
// ----------------------------------------------

const mapStoreToProps = (store, {examGuid}) =>
{
	const schedulesData = getSchedulesData(store);

	return {
		schedule: getScheduleDataByExamGuid(schedulesData)(examGuid),
		scheduleCount: getScheduleCount(schedulesData)
	}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSuccess: (examName, scheduleGuid, practice) => {
		const {examGuid, onSuccess} = ownProps;
		dispatch(setCurrentExam(examName, examGuid, scheduleGuid, practice));
		onSuccess();
	}
});

ScheduleDataChecker = connect(mapStoreToProps, mapDispatchToProps)(ScheduleDataChecker);


// ScheduleDataChecker (not connected to store)
// ----------------------------------------------
export {ScheduleDataChecker}