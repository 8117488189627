
// NOTE:
// Helper function for determining if a position
// lies within the bounds of a rectangle


// boundingRectangle structure:
// ------------------------------
// { top, left, bottom, right } OR { x, y, width, height }

const isWithinBounds = ({x, y}, boundingRectangle) =>
{
	const {top, left, bottom, right, width, height} = boundingRectangle;

	const bounds = {
		top: top || boundingRectangle.y,
		left: left || boundingRectangle.x,
		right: right || boundingRectangle.x + width,
		bottom: bottom || boundingRectangle.y + height
	}

	return x > bounds.left && x < bounds.right && 
				 y > bounds.top && y < bounds.bottom;
}


export {isWithinBounds}