
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MaterialText} from 'components/presentation/material-text'


// TextPanel (not connected to styles)
// --------------------------------------------------------------------

let TextPanel = ({classes, text, style}) => (
	<Paper style={style} className={classes.panel}>
		<MaterialText className={classes.text}>{text}</MaterialText>
	</Paper>
)

TextPanel.defaultProps = {
	style: {}
}

TextPanel.propTypes = {
	text: PropTypes.string.isRequired,
	style: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired
}


// TextPanel (connected to styles)
// --------------------------------------------------------------------

const styles = (theme) => ({
	panel: {
		flexGrow: 1,
		maxWidth: 800,
		boxSizing: 'border-box',
		padding: theme.spacing.unit,
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.background.light,
	},
	text: {
		color: theme.palette.background.contrastText
	}
});

TextPanel = withStyles(styles)(TextPanel);


// Export
// --------------------------------------------------------------------
export {TextPanel}