import { check } from "@xams-utils/check-types";

const isResource = (resource, resourceType) => {

    if (!check.nonEmptyString(resource)) {
        return false;
    }

    const parts = resource.split(".");
    if (!check.nonEmptyArray(parts)) {
        return false;
    }
    const extension = parts[parts.length - 1];

    if (check.nonEmptyArray(resourceType)) {
        return resourceType.some((item) => isResourceType(extension, item));
    } else if (check.nonEmptyString(resourceType)) {
        return isResourceType(extension, resourceType);
    }

    return false;
};

const isResourceType = (resource, resourceType) => {
    if (
        !check.nonEmptyString(resource) ||
        !check.nonEmptyString(resourceType)
    ) {
        return false;
    }

    return (
        resource.toLowerCase().substring(0, resourceType.length) ===
        resourceType.toLowerCase()
    );
};

export {isResource}