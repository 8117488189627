import React, { Component } from "react";
import { connect } from "react-redux";

import { setQuestionIdSetFromQuestion } from "./actions";

import { QuestionPanel } from "../question_panel/panel";
import { AnswerPanel } from "../answer_panel/panel";
import { QuestionNumber } from "./question-number";
import { DisplayAllQuestionsBar } from "./display-all-questions-bar";

import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import { check } from "@xams-utils/check-types";

const isFirstQuestionInSection = (currentQuestionId, currentSection) => {
    if (currentSection) {
        const { questions } = currentSection;

        if (check.nonEmptyArray(questions)) {
            return questions[0] === currentQuestionId;
        }
    }

    return false;
};

class DisplayAllQuestionsPanel extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.divRefs = [];
        const { currentSection } = props;
        const { questions } = currentSection;

        this.barRef = React.createRef();
        for (let i = 0; i < questions.length; i++) {
            this.divRefs[questions[i]] = React.createRef();
        }

        this.scrollToQuestion = this.scrollToQuestion.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            currentQuestionId: previousQuestionId,
            currentSection: prevCurrentSection,
        } = prevProps;
        const {
            currentQuestionId,
            currentSection,
            questionIdSetFromQuestion,
            clearQuestionIdSetFromQuestion,
        } = this.props;

        if (questionIdSetFromQuestion) {
            clearQuestionIdSetFromQuestion();
        } else {
            if (currentQuestionId !== previousQuestionId) {
                const { id: prevSectionId } = prevCurrentSection;
                const { id: sectionId } = currentSection;

                if (sectionId !== prevSectionId) {
                    const { questions } = currentSection;

                    this.barRef = React.createRef();
                    this.divRefs = [];
                    for (let i = 0; i < questions.length; i++) {
                        this.divRefs[questions[i]] = React.createRef();
                    }
                    const scrollQuestion = isFirstQuestionInSection(
                        currentQuestionId,
                        currentSection
                    )
                        ? -1
                        : currentQuestionId;

                    this.timer = setTimeout(() => {
                        this.scrollToQuestion(scrollQuestion);
                    }, 250);
                } else {
                    this.scrollToQuestion(currentQuestionId);
                }
            }
        }
    }

    componentDidMount() {
        const { currentQuestionId, currentSection } = this.props;
        if (!isFirstQuestionInSection(currentQuestionId, currentSection)) {
            console.log(currentQuestionId);
            this.timer = setTimeout(() => {
                this.scrollToQuestion(currentQuestionId);
            }, 250);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    scrollToQuestion(questionId, lastScroll = false) {
        const element =
            questionId == -1 ? this.barRef : this.divRefs[questionId];

        if (element && element.current) {
            element.current.scrollIntoView({ behavior: "smooth" });
            // element.current.scrollIntoViewIfNeeded(true);
        } else if (!lastScroll) {
            this.timer = setTimeout(() => {
                this.scrollToQuestion(questionId, true);
            }, 250);
        }
    }

    displayBar() {
        const { currentQuestionId, classes } = this.props;
        const divName = "barDiv";
        return (
            <React.Fragment>
                <div
                    className={classes.scrollTo}                
                    id={divName}
                    key={divName}
                    ref={this.barRef}
                ></div>
                <DisplayAllQuestionsBar currentQuestionId={currentQuestionId} />
            </React.Fragment>
        );
    }

    displayQuestions() {
        const { currentQuestionId, currentSection, classes } = this.props;

        return currentSection.questions.map((questionId, index, questions) => {
            const props = { noQuestionPanel: true, questionId };
            const numberProps = {
                questionId,
                selected: questionId === currentQuestionId,
            };
            const divName = `questionDiv${index}`;
            const panelClass =
                index === questions.length - 1
                    ? classes.lastPanel
                    : classes.panel;

            return (
                <React.Fragment key={index}>
                    <div
                        className={classes.scrollTo}
                        id={divName}
                        key={divName}
                        ref={this.divRefs[questionId]}
                    ></div>
                    <Paper key={index} className={panelClass}>
                        <div className={classes.question}>
                            <div>
                                <QuestionNumber {...numberProps} />
                            </div>
                            <div>
                                <QuestionPanel {...props} />
                                <br />
                                <Divider />
                                <AnswerPanel {...props} />
                            </div>
                        </div>
                    </Paper>
                </React.Fragment>
            );
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.displayBar()}
                {this.displayQuestions()}
            </React.Fragment>
        );
    }
}

const styles = (theme) => {
    const spacing = theme.spacing.unit * 2;

    return {
        lastPanel: {
            margin: `0 ${spacing}px ${spacing}px`,
            // marginBottom: 0,
            padding: spacing,
            backgroundColor: theme.palette.background.light,
        },
        panel: {
            margin: `0 ${spacing}px`,
            // marginBottom: 0,
            padding: spacing,
            backgroundColor: theme.palette.background.light,
        },
        scrollTo: {
            display: "inline-block",
            marginBottom: spacing,
        },
        question: {
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            "&>div:first-child": {
                marginRight: spacing,
                flexGrow: 0,
            },
            "&>div:last-child": {
                display: "inline-block",
                flexGrow: 1,
            },
        },
    };
};

DisplayAllQuestionsPanel = withStyles(styles)(DisplayAllQuestionsPanel);

const mapDispatchToProps = (dispatch) => ({
    clearQuestionIdSetFromQuestion: () => {
        dispatch(setQuestionIdSetFromQuestion(false));
    },
});

DisplayAllQuestionsPanel = connect(
    undefined,
    mapDispatchToProps
)(DisplayAllQuestionsPanel);

export { DisplayAllQuestionsPanel };
