
// xams-utils
import {IdGenerator} from '@xams-utils/id-generator'


const STACK_TYPES = {
	FIFO: 0,
	LIFO: 1
}


// [BACK, ..., ..., ..., FRONT]
// next ->          <- previous 

// On pop()...
// FIFO = remove BACK
// LIFO = remove FRONT


class StackItem
{
	constructor(id, item)
	{
		this.id = id;
		this.item = item;
		this.nextId = null;
		this.previousId = null;
	}

	set NextId(id)
	{
		this.nextId = id;
	}

	set PreviousId(id)
	{
		this.previousId = id;
	}
}


class Stack
{
	constructor(stackType=STACK_TYPES.FIFO)
	{
		this.items = {};
		this.type = stackType;

		this.frontId = null;
		this.backId = null;
	}

	push(item, id=IdGenerator.uuid())
	{
		this.validateNewId(id);

		const newItem = new StackItem(id, item);
		const frontItem = this.FrontItem;

		if (frontItem) {
			this.createReferences(frontItem, newItem);
		}

		this.tryUpdateBackItem(id);
		this.frontId = id;
		this.items[id] = newItem;

		return id;
	}

	validateNewId(id)
	{
		if (this.hasItemWithId(id)) {
			throw `Stack already contains an element with the id: ${id}`;
		}
	}

	tryUpdateBackItem(id)
	{
		if (this.backId === null) {
			this.backId = id;
		}
	}

	pop()
	{
		const isFifo = this.type === STACK_TYPES.FIFO;
		return isFifo ? this.popItem(false) : this.popItem(true);
	}

	popItem(fromFront)
	{
		const popItemId = fromFront ? this.frontId : this.backId;
		if (!popItemId) { return undefined; }

		const itemToPop = this.getStackItem(popItemId);
		const neighbourId = itemToPop[fromFront ? 'previousId' : 'nextId'];

		if (neighbourId) {
			const neighbourItem = this.getStackItem(neighbourId);
			if (fromFront) {
				this.removeReferences(neighbourItem, itemToPop);
				this.frontId = neighbourId;
			}
			else {
				this.removeReferences(itemToPop, neighbourItem);
				this.backId = neighbourId;
			}
		}
		else {
			this.backId = null;
			this.frontId = null;
		}

		delete this.items[popItemId];
		return itemToPop.item;
	}

	createReferences(/*stack items*/)
	{
		for (let i = 1; i < arguments.length; i++) {
			const lhsItem = arguments[i - 1];
			const rhsItem = arguments[i];
			lhsItem.NextId = rhsItem.id;
			rhsItem.PreviousId = lhsItem.id;
		}
	}

	removeReferences(/*stack items*/)
	{
		for (let i = 1; i < arguments.length; i++) {
			const lhsItem = arguments[i - 1];
			const rhsItem = arguments[i];
			lhsItem.NextId = null;
			rhsItem.PreviousId = null;
		}
	}

	get FrontItem()
	{
		if (this.frontId !== null) {
			return this.getStackItem(this.frontId);
		}
	}

	get BackItem()
	{
		if (this.backId !== null) {
			return this.getStackItem(this.backId);
		}
	}

	getStackItem(id)
	{
		return this.items[id];
	}

	hasItemWithId(id)
	{
		return this.items.hasOwnProperty(id);
	}

	*generateElements()
	{
		let currentItem = this.BackItem;
		if (!currentItem) { return; }
		yield currentItem.item;

		while (currentItem.nextId) {
			currentItem = this.getStackItem(currentItem.nextId);
			yield currentItem.item;
		}
	}

	toArray()
	{
		const array = [];

		for (let element in this.generateElements()) {
			array.push(element);
		}

		return array;
	}


	static FromArray(array, stackType)
	{
		const stack = new Stack(stackType);

		for (let i = 0; i < array.length; i++) {
			stack.push(array[i]);
		}

		return stack;
	}

	static FromStack(stackToCopy)
	{
		const stack = new Stack(stackToCopy.type);
		let currentItemId = stackToCopy.backId;

		if (currentItemId === null) {
			return stack;
		}

		const stackItems = stackToCopy.items;
		while (currentItemId) {
			const currentStackItem = stackItems[currentItemId];
			stack.push(currentStackItem.item, currentStackItem.id);
			currentItemId = currentStackItem.nextId;
		}

		if (stack.frontId !== stackToCopy.frontId ||
				stack.backId !== stackToCopy.backId) {
			throw "Failed to create Stack from Stack";
		}

		return stack;
	}
}


export {Stack, STACK_TYPES}