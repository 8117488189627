// npm
import React, { Component } from 'react';

class StaticTextAnswerForm extends Component {
    state = {};
	
	componentWillUnmount() {
        const { value, onChange } = this.props;
        if (value !== "") {
            onChange("visited");
        }
	}
	
    render() {
        return null;
    }
}


export { StaticTextAnswerForm };