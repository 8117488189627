
// npm
import React from 'react'
import {connect} from 'react-redux'

// react
import {SectionHeading} from './section-heading'
import {QuestionButton} from './question-button'
import {NavigationPanel} from 'components/layout/navigation_panel/panel'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getPaperPartId} from 'redux/reducers/exam/content/complex-selectors'
import {getId} from 'redux/reducers/exam/content/questions/question/selectors'
import {getSectionsData, getQuestionsData, getMappingsData, getCurrentQuestionId} from 'redux/reducers/exam/content/selectors'

import {calculateRootSectionsMarks} from './section-marks'


const shouldInclude = (currentPaperPartId, getPaperPartId) => (id) =>
{
	if (currentPaperPartId === id) { return true; }
	return getPaperPartId(id) === currentPaperPartId;
}

const mapStoreToProps = (store, {onQuestionChange}) =>
{
	const contentData = getContentData(getExamData(store));
	const currentQuestionId = getCurrentQuestionId(contentData);
	const _getPaperPartId = getPaperPartId(contentData);
	const currentPaperPartId = _getPaperPartId(currentQuestionId);

	let sectionsData = getSectionsData(contentData);
	let questionsData = getQuestionsData(contentData);
	let mappingsData = getMappingsData(contentData);

	if (currentPaperPartId) { // filter for nodes that only exist in the current paper part
		const cachedResults = {};
		const _shouldInclude = shouldInclude(currentPaperPartId, _getPaperPartId);

		// 1. Filter unneeded sections
		sectionsData = sectionsData.filter((_, sectionId) => {
			cachedResults[sectionId] = _shouldInclude(sectionId);
			return cachedResults[sectionId];
		});

		// 2. Filter unneeded questions
		questionsData = questionsData.filter((_, questionId) => {
			cachedResults[questionId] = _shouldInclude(questionId);
			return cachedResults[questionId];
		});

		// 3. Filter unneeded mappings
		mappingsData = mappingsData.filter((_, mappingId) => {
			return /*mappingId === 'root' || */cachedResults[mappingId];
		});

		// 4. Convert paper part mapping to be 'root'
		const paperPartMapping = mappingsData.get(currentPaperPartId);
		mappingsData = mappingsData.delete(currentPaperPartId);
		mappingsData = mappingsData.set('root', paperPartMapping);
	}

	sectionsData = calculateRootSectionsMarks(sectionsData, mappingsData);

	const sections = (sectionsData.map(sectionData => {
		return <SectionHeading sectionData={sectionData}/>;
	})).toObject();

	const results = (questionsData.map(questionData => {
		const questionId = getId(questionData);
		const onClick = () => onQuestionChange(questionId);
		const props = {questionData, onClick, key: questionId};
		return <QuestionButton {...props}/>;
	})).toObject();

	const mappings = mappingsData.toJS();

	return {
		data: {sections, results, mappings},
		nestResults: true
	}
}

const NavigationPanelContent = connect(mapStoreToProps)(NavigationPanel);


export {NavigationPanelContent}