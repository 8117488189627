
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {ImagePreloader} from './image-preloader'


class MultiImagePreloader extends React.Component
{
	constructor(props)
	{
		super(props);

		this.imageCount = props.urls.length;
		this.loadCount = 0;

		this.onSuccess = () => {
			if (++this.loadCount === this.imageCount) {
				this.props.onSuccess();
			}
		}
	}

	componentDidMount()
	{
		if (this.loadCount === this.imageCount) {
			this.props.onSuccess();
		}
	}

	render()
	{
		const commonProps = {
			onSuccess: this.onSuccess,
			onFail: this.props.onFail
		}

		return this.props.urls.map((url,index) => (
			<ImagePreloader key={index} url={url} {...commonProps}/>
		));
	}
}

MultiImagePreloader.propTypes = {
	urls: PropTypes.arrayOf(PropTypes.string).isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired
}


export {MultiImagePreloader}