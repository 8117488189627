import check from "check-types";

const addMessageParameters = (_message, _parameters) => {
    const parameters = check.array(_parameters) ? _parameters : [_parameters];
    let message = "" + _message;

    for (let i = 0; i < parameters.length; i++) {
        message = message.replace("@", parameters[i]);
    }

    return message;
};

export { addMessageParameters };
