// npm
import React, { Component } from "react";
import { connect } from "react-redux";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { Align } from "components/layout/align";
import CalculatorButtonRow from "./calculator-button-row";
import { CalculatorDisplay } from "./calculator-display";
import {
    isSEB,
    removeCalculatorSymbols,
    isValidPercentage,
    processPercentage,
    isValidSquareRoot,
    processSquareRoot,
} from "./calculator-helper";

import check from "check-types";

import {
    isOperand,
    isOperator,
    getResult,
    plusMinusValue,
    spaceOutResult,
    decimalPointAllowed,
    operatorAllowed,
} from "./calculator-helper";

import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import {
    getCalculatorPercentageButton,
    getCalculatorSquareButton,
} from "redux/reducers/settings/client/selectors";

// constants
import { CALCULATOR_BUTTONS } from "./constants";

// Calculator (not connected to styles)
// ----------------------------------------------------------------------------

class Calculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
        };

        this.justProcessedAnswer = false;
        this.maxLength = -1;
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    handleButtonClick(_button) {
        const { value: _value } = this.state;
        const { evaluationType } = this.props;

        let value = removeCalculatorSymbols(_value);
        const button = removeCalculatorSymbols(_button);

        if (isOperand(button) && this.justProcessedAnswer) {
            value = button;
            this.justProcessedAnswer = false;
        } else if (
            isOperand(button) ||
            (isOperator(button) && operatorAllowed(button, value))
        ) {
            if (button !== "." || decimalPointAllowed(value)) {
                if (this.maxLength === -1 || value.length < this.maxLength) {
                    value += button;
                    this.justProcessedAnswer = false;
                }
            }
        } else if (button === "=") {
            const result = getResult(value, evaluationType);

            if (check.assigned(result)) {
                this.props.onResult(spaceOutResult("" + value, result, button));
                value = "" + result;
                this.justProcessedAnswer = true;
            }
        } else if (button === "C") {
            value = "";
            this.justProcessedAnswer = false;
        } else if (button === "<") {
            value = value.slice(0, -1);
            this.justProcessedAnswer = false;
        } else if (
            button === "@" &&
            (this.maxLength === -1 || value.length < this.maxLength)
        ) {
            value = plusMinusValue(value);
        } else if (button === "%" && isValidPercentage(value)) {
            const result = processPercentage(value);
            if (result) {
                this.props.onResult(spaceOutResult("" + value, result, button));
                value = "" + result;
                this.justProcessedAnswer = true;
            }
        } else if (button === "}" && isValidSquareRoot(value)) {
            const squareRootValue = processSquareRoot(value, evaluationType);

            if (check.assigned(squareRootValue)) {
                this.props.onResult(
                    spaceOutResult("" + value, squareRootValue, button)
                );
                value = "" + squareRootValue;
                this.justProcessedAnswer = true;
            }
        }

        this.setState({ value });
    }

    getCalculatorButtons() {
        return (
            <React.Fragment>
                {this.getExtraButtons()}
                {CALCULATOR_BUTTONS.map((buttonRow, index) => (
                    <CalculatorButtonRow
                        key={index}
                        buttons={buttonRow}
                        onSelect={this.handleButtonClick}
                    />
                ))}
            </React.Fragment>
        );
    }

    getExtraButtons() {
        const { calculatorPercentageButton, calculatorSquareButton } =
            this.props;

        if (calculatorPercentageButton || calculatorSquareButton) {
            let buttonRow = [];
            if (calculatorSquareButton) {
                buttonRow.unshift("}");
            }
            if (calculatorPercentageButton) {
                buttonRow.unshift("%");
            }
            const max=4-buttonRow.length;
            for (let i = 0; i < max; i++) {
                buttonRow.unshift("");
            }

            return (
                <CalculatorButtonRow
                    buttons={buttonRow}
                    onSelect={this.handleButtonClick}
                />
            );
        } else {
            return null;
        }
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        const displayProps = {
            value,
        };
        const className = `${classes.container} ${isSEB() && classes.seb}`;
        return (
            <div className={className}>
                <Align>
                    <div>
                        {/* <div>{navigator.userAgent}</div> */}
                        <CalculatorDisplay {...displayProps} />
                        {this.getCalculatorButtons(this.handleButtonClick)}
                    </div>
                </Align>
            </div>
        );
    }
}

// Calculator (connected to styles)
// ----------------------------------------------------------------------------

const styles = ({ palette, spacing }) => ({
    container: {
        borderWidth: 1,
        boxSizing: "border-box",
        borderStyle: "solid",
        borderColor: palette.background.contrastText,
        borderRadius: 8,
        flexShrink: 0,
        margin: spacing.unit,
        marginTop: 0,
    },
    seb: {
        marginBottom: spacing.unit * 8,
    },
});

Calculator = withStyles(styles)(Calculator);

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    const calculatorPercentageButton =
        getCalculatorPercentageButton(clientSettingsData);
    const calculatorSquareButton =
        getCalculatorSquareButton(clientSettingsData);

    return {
        calculatorPercentageButton,
        calculatorSquareButton,
    };
};

Calculator = connect(mapStoreToProps)(Calculator);

// EXPORT
// ----------------------------------------------------------------------------
export { Calculator };
