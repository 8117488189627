// RESPONSIBILITY:
// --------------------------------------------------------------------------
// This navigation panel renders the following:
// 1. A section navigation panel for traversing through any nested sections
//    <- SECTION 1 ->
//    <- SECTION A ->
//    <- SEGMENT i ->
// 2. A results panel displaying the results for the chosen section
//    [A] [B] [C] [D]
// --------------------------------------------------------------------------

// NOTE:
// --------------------------------------------------------------------------
// n = section nesting depth
//
// When the 'nestResults' prop is set to true, the sections panel will only
// allow n-1 sections to be navigated. The final nested section will instead be
// shown in the results panel, as follows:
//
//    SECTION A
// [i] [ii] [iii] [iv]
//
//    SECTION B
// [a] [b] [c] [d] [e]
// -------------------------------------------------------------------------

// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getExamData } from "redux/reducers/selectors";
import { getContentData } from "redux/reducers/exam/selectors";
import { getCurrentQuestionId } from "redux/reducers/exam/content/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getHideSectionNamesInPlayer} from 'redux/reducers/settings/client/selectors'

// react
import { ResultsPanel } from "./results/panel";
import { SectionNavigationPanel } from "./sections/panel";
import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";

// constants
import { ID_TYPE } from "./constants";

const containerStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
};

const getFirstQuestionFromMappings = (id, mappings) => {
    const mapping = mappings[id];

    if (!mapping) return id;

    for (let i = 0; i < mapping.length; i++) {
        const id = mapping[i];
        const questionId = getFirstQuestionFromMappings(id, mappings);
        if (questionId) return questionId;
    }

    return null;
};

const mappingsHasCurrentQuestion = (id, mappings, currentQuestionId) => {
    const mapping = mappings[id];

    if (!mapping) return false;

    if (mapping.indexOf(currentQuestionId) !== -1) {
        return true;
    }

    for (let i = 0; i < mapping.length; i++) {
        const id = mapping[i];
        if (mappingsHasCurrentQuestion(id, mappings, currentQuestionId)) {
            return true;
        }
    }

    return false;
};

class NavigationPanel extends React.Component {
    constructor(props) {
        super(props);
        this.validateMappingIdsAreSections();
        this.validateExistenceOfRootMapping();
        this.state = { chosenSection: this.findChosenSection("root") };

        this.updateChosenSection = (newSectionId) => {
            if (!this.questionDisplayedOnNewSection(newSectionId)) {
                const questionId = this.getFirstQuestionInSection(newSectionId);

                if (questionId) {
                    const { onQuestionChange } = props;
                    onQuestionChange(questionId);
                    return;
                }
            }

            this.setState({
                chosenSection: this.findChosenSection(newSectionId, true),
            });
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentQuestionId !== prevProps.currentQuestionId) {
            const { chosenSection } = this.state;
            const currentSection = this.findChosenSection("root");
            if (chosenSection !== currentSection) {
                //this.setState({chosenSection: currentSection});
                this.setState({ chosenSection: currentSection }, () => {
                    activityLogger.log(ACTIVITIES.MOVE_SECTION, {
                        id: currentSection,
                    });
                });
            }
        }
    }

    validateMappingIdsAreSections() {
        const { data } = this.props;
        const resultIds = Object.keys(data.results);
        const mappingIds = Object.keys(data.mappings);
        if (resultIds.some((resultId) => mappingIds.hasOwnProperty(resultId))) {
            throw "NavigationPanel: A resultId can't be used as a mapping";
        }
    }

    validateExistenceOfRootMapping() {
        if (!this.props.data.mappings.root) {
            throw "NavigationPanel must have a root mapping";
        }
    }

    findChosenSection(sectionId, changeManually = false) {
        const { currentQuestionId, data } = this.props;
        const { mappings, sections } = data;
        const currentMappings = mappings[sectionId];

        if (!sections.hasOwnProperty(currentMappings[0])) {
            return sectionId;
        }

        if (sectionId === "root" && !changeManually) {
            for (let i = 0; i < currentMappings.length; i++) {
                const currentMapping = currentMappings[i];
                const newSection = this.findSection(currentMapping);
                if (
                    mappingsHasCurrentQuestion(
                        newSection,
                        mappings,
                        currentQuestionId
                    )
                ) {
                    return newSection;
                }
            }
        }

        return this.findSection(sectionId);

        // while (currentMappings && sections.hasOwnProperty(currentMappings[0]))
        // {
        // 	// eslint-disable-next-line
        // 	var parentResultsId = currentResultsId || sectionId;
        // 	var currentResultsId = currentMappings[0];
        // 	currentMappings = mappings[currentResultsId];
        // }

        // return this.props.nestResults ? parentResultsId : currentResultsId;
    }

    findSection(sectionId) {
        const { mappings, sections } = this.props.data;
        let currentMappings = mappings[sectionId];

        while (currentMappings && sections.hasOwnProperty(currentMappings[0])) {
            // eslint-disable-next-line
            var parentResultsId = currentResultsId || sectionId;
            var currentResultsId = currentMappings[0];
            currentMappings = mappings[currentResultsId];
        }

        return this.props.nestResults ? parentResultsId : currentResultsId;
    }

    questionDisplayedOnNewSection(sectionId) {
        const { currentQuestionId, data } = this.props;
        const { mappings } = data;

        return mappingsHasCurrentQuestion(
            sectionId,
            mappings,
            currentQuestionId
        );
    }

    getFirstQuestionInSection(sectionId) {
        const { mappings } = this.props.data;
        return getFirstQuestionFromMappings(mappings[sectionId][0], mappings);
    }

    render() {
        const {
            props: { data, hideSectionNamesInPlayer },
            state: { chosenSection },
        } = this;

        return (
                <div style={containerStyle}>
                    <SectionNavigationPanel
                        chosenSection={chosenSection}
                        data={data}
                        onChange={this.updateChosenSection}
                    />
                    <ResultsPanel data={data} hideSectionNamesInPlayer={hideSectionNamesInPlayer} chosenSection={chosenSection} />
                </div>
        );
    }
}

NavigationPanel.propTypes = {
    data: PropTypes.shape({
        sections: PropTypes.objectOf(PropTypes.node).isRequired,
        results: PropTypes.objectOf(PropTypes.node).isRequired,
        mappings: PropTypes.objectOf(PropTypes.arrayOf(ID_TYPE)).isRequired,
    }).isRequired,
    nestResults: PropTypes.bool,
};

const mapStoreToProps = (store) => {
    const contentData = getContentData(getExamData(store));
    const currentQuestionId = getCurrentQuestionId(contentData);
    const settingsData = getSettingsData(store);
	const clientSettingsData = getClientSettingsData(settingsData);    
    return {
        hideSectionNamesInPlayer: getHideSectionNamesInPlayer(clientSettingsData),
        currentQuestionId,
    };
};

NavigationPanel = connect(mapStoreToProps)(NavigationPanel);

export { NavigationPanel };
