
// EXAM PASSWORD
const SET_PASSWORD = '[exam]set.password';
const CLEAR_PASSWORD = '[exam]clear.password';
const SET_PASSWORD_ERROR = '[exam]set.password.error';

// EXAM
const SET_CURRENT_EXAM = '[exam]set.current';
const SET_EXAM_DATA = '[exam]add.data';
const CLEAR_EXAM = '[exam]clear';
const CLEAR_EXAM_DATA = '[exam]clear.data';
const PAUSE_EXAM = '[exam]pause';
const RESUME_EXAM = '[exam]resume';
const CONFIRM_RESUME_EXAM = '[exam]resume.confirmation';
const SET_FORMRUN_GUID = '[exam]set.formrun.guid';
const SET_FORMRUN_ID = '[exam]set.formrun.id';

// SHOW SCENARIO DROPDOWN
const SET_SHOW_SCENARIO = '[exam]set.show.scenario';

// LEAVE / RESUME EXAM
const SET_LEAVE_EXAM = '[exam]set.leave_exam';
const SET_RESUME_EXAM = '[exam]set.resume_exam';

// FULL SCREEN
const SET_SHOW_EXAM_IN_FULL_SCREEN = '[exam]set.show.exam.in.full_screen'
const SET_INITIAL_FULL_SCREEN = '[exam]set.initial_full_screen'

export {
	SET_PASSWORD,
	CLEAR_PASSWORD,
	SET_PASSWORD_ERROR,
	SET_CURRENT_EXAM,
	SET_EXAM_DATA,
	CLEAR_EXAM,
	CLEAR_EXAM_DATA,
	PAUSE_EXAM,
	RESUME_EXAM,
	CONFIRM_RESUME_EXAM,
	SET_FORMRUN_GUID,
	SET_FORMRUN_ID,
	SET_SHOW_SCENARIO,
	SET_LEAVE_EXAM,
	SET_RESUME_EXAM,
	SET_SHOW_EXAM_IN_FULL_SCREEN,
	SET_INITIAL_FULL_SCREEN
}