// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";

// xams-utils
import { check } from "@xams-utils/check-types";

// material-ui
import Typography from "@material-ui/core/Typography";

// utils
import { htmlToReact } from "utils/html-to-react";

const getSpaceOutReg = (text) => {
    if (!check.nonEmptyString(text)) {
        return text;
    }

    try {
        if (text.indexOf("&lt;") === -1 && text.indexOf("&gt;") === -1) {
            return text;
        }

        const regex_sb = /(\S)((&gt;|&lt;))/gm;
        const regex_sa = /((&gt;|&lt;))(\S)/gm;

        return text
            .replace(regex_sb, "$1 $3")
            .replace(regex_sa, "$1 $3")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">");
    } catch (e) {
        return text;
    }
};

const isHtmlText = (
    text // may need stricter check
) => {
    if (!check.string(text)) {
        return false;
    }
    const openTagIndex = text.indexOf("<");
    return openTagIndex !== -1 && text.indexOf(">", openTagIndex);
};

let MaterialText = ({
    children: text,
    component,
    orgId,
    ...typographyProps
}) => {
    const isHtml = isHtmlText(text);
    const container = component ? component : isHtml ? "div" : undefined; // nested <p> not allowed

    const spaceOut = orgId === 29 || orgId === 15 || orgId === 22;
    const _text = spaceOut ? getSpaceOutReg(text) : text;

    const finalText = isHtml ? htmlToReact.parse(_text) : _text;

    return (
        <Typography component={container} style={{userSelect: "none"}} {...typographyProps}>
            {finalText}
        </Typography>
    );
};

MaterialText.propTypes = {
    children: PropTypes.string.isRequired,
};

const mapStoreToProps = (store) => {
    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);

    return {
        orgId: getOrgId(clientSessionData),
    };
};

MaterialText = connect(mapStoreToProps)(MaterialText);

export { MaterialText };
