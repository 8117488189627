import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { passwordApi } from "libs/api/interface/api-password";

import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getLogoUrl } from "redux/reducers/settings/client/selectors";
import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId, getUrlToken } from "redux/reducers/session/client/selectors";

import Fade from "@material-ui/core/Fade";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import WarningIcon from "@material-ui/icons/Warning";

import { Image } from "components/presentation/image";
import { withMessages } from "components/hocs/messages";
import { MaterialText } from "components/presentation/material-text";
import { Align } from "components/layout/align";
import { ForgotPasswordInfo } from "./forgot-password-info";

import { MESSAGE_IDS } from "constants/message-ids";
import {CONSTANTS} from "../reset_password/constants"

import { styles } from "./styles";

const titleMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.TITLE;
const infoMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.INFO;
const fieldMessageId = MESSAGE_IDS.LOGIN.USERNAME;

const returnMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.RETURN;
const submitMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.SUBMIT;
const submittingMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.SUBMITTING;
const errorMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.ERROR;
const successMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.SUCCESS;

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.initializeBoundMethods();

        this.loginInput = null;

        this.state = {
            disableSubmit: true,
            submitting: false,
            submitted: null,
        };
    }

    initializeBoundMethods() {
        this.onFieldChange = () => {
            const { submitting } = this.state;
            if (!submitting) {
                const filled = this.isFormFilled();
                this.setState({ disableSubmit: !filled });
            }
        };

        this.onSubmit = (e) => {
            e.preventDefault();

            const { orgID } = this.props;
            const data = { logonID: this.loginInput.value, orgID };

            const onSuccess = (response) => {
                const responseCode = response ? parseInt(response) : CONSTANTS.RESULT_CODE.INVALID_TOKEN;
                if (responseCode === CONSTANTS.RESULT_CODE.SUCCESS){
                    this.setState({ submitting: false, submitted: CONSTANTS.SUCCESS });
                }
                else{
                    this.setState({ submitting: false, submitted: CONSTANTS.FAILED });
                }
            };

            const onFail = () => {
                this.setState({ submitting: false, submitted: CONSTANTS.FAILED });
            };

            this.setState({ submitting: true }, () => {
                //debugger;
            passwordApi.forgotPassword(data).then(onSuccess, onFail);
            });
        };

        this.handleReturnToLogin = () => {
            const { history, urlToken } = this.props;

            history.replace(`/login?${urlToken}`);
        };
    }

    get Branding() {
        const { classes } = this.props;

        return (
            <div className={classes.logoBox}>
                <Image
                    className={classes.logo}
                    src={this.props.logoUrl}
                    alt="branding"
                />
            </div>
        );
    }

    get Title() {
        const { messages, classes } = this.props;

        return (
            <Align>
                <MaterialText
                    className={classes.formText}
                    variant="h5"
                >
                    {messages[titleMessageId]}
                </MaterialText>
            </Align>
        );
    }

    get Form() {
        const { classes } = this.props;

        return (
            <form
                className={classes.form}
                autoComplete="false"
                onSubmit={this.onSubmit}
            >
                {this.LoginIdInput}
                {this.ErrorPanel}
                {this.Buttons}
            </form>
        );
    }

    get LoginIdInput() {
        const name = "loginId";
        const label = this.props.messages[fieldMessageId];

        const inputRef = (input) => {
            this.loginInput = input;
        };

        const inputProps = {
            ...this.BasicInputProps,
            name,
            inputRef,
            id: "login-id-input",
        };

        return this.getFormInput(name, label, true, <Input {...inputProps} />);
    }

    get BasicInputProps() {
        const { classes } = this.props;
        const { submitting } = this.state;

        return {
            autoComplete: "off",
            onChange: this.onFieldChange,
            disabled: submitting,
            className: classes.formInputText,
            classes: {
                input: classes.input,
                underline: classes.inputUnderline,
            },
        };
    }

    getFormInput(name, label, required = false, inputElement) {
        const { classes } = this.props;

        return (
            <FormControl margin="normal" required={required} fullWidth>
                <InputLabel
                    className={classes.formInputText}
                    shrink={true}
                    htmlFor={name}
                >
                    {label}
                </InputLabel>
                {inputElement}
            </FormControl>
        );
    }

    get ErrorPanel() {
        const { classes } = this.props;
        const { errorText } = this.state;

        if (!errorText) {
            return null;
        }

        return (
            <span className={classes.errorContainer}>
                <WarningIcon
                    className={`${classes.error} ${classes.errorIcon}`}
                />
                <MaterialText className={classes.error}>
                    {errorText}
                </MaterialText>
            </span>
        );
    }

    get Buttons() {
        const { classes } = this.props;

        return (
            <div className={classes.buttons}>
                <div>{this.ReturnButton}</div>
                <div>{this.SubmitButton}</div>
            </div>
        );
    }

    get SubmitButton() {
        const { disableSubmit, submitting } = this.state;
        const { messages } = this.props;
        const disabled = disableSubmit || submitting;
        const props = {
            type: "submit",
            variant: "contained",
            color: "primary",
            disabled,
        };
        const label = messages[submitMessageId];

        return <Button {...props}>{label}</Button>;
    }

    get ReturnButton() {
        const { submitting } = this.state;
        const { messages } = this.props;
        const disabled = submitting;
        const props = {
            variant: "contained",
            color: "secondary",
            disabled,
            onClick: this.handleReturnToLogin,
        };
        const label = messages[returnMessageId];

        return <Button {...props}>{label}</Button>;
    }

    get InfoPanel() {
        const { submitting, submitted } = this.state;
        const { messages } = this.props;
        const info = messages[infoMessageId].replace(
            "~userName~",
            messages[fieldMessageId]
        ).replace(
            "~submit~",
            messages[submitMessageId].toLowerCase()
        );

        let status = "info";
        let message = info;

        if (submitting) {
            status = "submitting";
            message = messages[submittingMessageId];;
        } else if (submitted) {
            status = submitted;
            message =
                status === CONSTANTS.SUCCESS
                    ? messages[successMessageId]
                    : messages[errorMessageId];
        }

        const props = { message, status };

        return <ForgotPasswordInfo {...props} />;
    }

    isFormFilled() {
        const { value } = this.loginInput;

        return this.isFieldFilled(value);
    }

    isFieldFilled(inputElement) {
        if (inputElement.value !== "") {
            return true;
        }

        const computedStyle = window.getComputedStyle(inputElement);
        const autofilled = computedStyle.content.includes("filled");

        return autofilled;
    }

    render() {
        const { classes } = this.props;

        return (
            <Fade in timeout={1000}>
                <div className={classes.layout}>
                    <Card raised className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            {this.Branding}
                            {this.Title}
                            {this.InfoPanel}
                            {this.Form}
                        </CardContent>
                    </Card>
                </div>
            </Fade>
        );
    }
}

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);
    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);
    return {
        orgID: getOrgId(clientSessionData),
        logoUrl: getLogoUrl(clientSettingsData),
        urlToken: getUrlToken(clientSessionData),
    };
};

ForgotPassword = connect(mapStoreToProps)(ForgotPassword);
ForgotPassword = withStyles(styles)(ForgotPassword);
ForgotPassword = withMessages(ForgotPassword);
ForgotPassword = withRouter(ForgotPassword);

export { ForgotPassword };
