// internal
import { localStorageApi, KEYS } from "../local-storage-api";

class RequestLogMappings {
    constructor() {
        const mappings = localStorageApi.retrieveDataFrom(
            KEYS.REQUEST_LOG_MAPPINGS
        );

        const saveToLocalStorage = () => {
            try{
                localStorageApi.saveDataTo(KEYS.REQUEST_LOG_MAPPINGS, mappings);
                return true;
            }
            catch(e){
                return false;
            }
        };

        const getStaticData = (requestLog) => ({
            referenceCount: 0,
            type: requestLog.type,
            payload: requestLog.payload,
            endpoint: requestLog.endpoint,
            userGuid: requestLog.userGuid,
            examGuid: requestLog.examGuid,
        });

        this.createMapping = (id, requestLog) => {
            const mapping = mappings[id] || getStaticData(requestLog);
            mapping.referenceCount = mapping.referenceCount + 1;
            mappings[id] = mapping;
            saveToLocalStorage();
        };

        this.removeMappingReference = (id, saveIt = true) => {
            const mapping = mappings[id];
            if (!mapping) {
                //throw "Can't remove non-existent mapping";
                return;
            }

            mapping.referenceCount = mapping.referenceCount - 1;
            if (mapping.referenceCount <= 0) {
                delete mappings[id];
            }

            if (saveIt) {
                saveToLocalStorage();
            }
        };

        this.save = () => {
            return saveToLocalStorage();
        }

        this.removeAll = () => {
            Object.keys(mappings).forEach((id) => {
                delete mappings[id];
            });
            saveToLocalStorage();
        };

        this.getMapping = (id) => {
            return mappings[id];
        };

        this.containsUser = (guid) => {
            return !!Object.values(mappings).find((mapping) => {
                return mapping.userGuid === guid;
            });
        };

        this.containsExam = (guid) => {
            return !!Object.values(mappings).find((mapping) => {
                return mapping.examGuid === guid;
            });
        };

        this.dangerously_getMappings = () => {
            return mappings;
        };
    }
}

const requestLogMappings = new RequestLogMappings();
export { requestLogMappings };
