const isInitialized = (appData) => appData.get("initialized");
const hasNetworkErrorOccurred = (appData) => appData.get("networkError");
const isOfflineMode = (appData) => !!appData.get("offline");
const getOfflineReason = (appData) => {
    try {
        return appData.get("offlineReason").toJS();
    } catch (e) {
        debugger;
    }
};
const getNetworkErrorInfo = (appData) => {
    try {
        return appData.get("networkErrorInfo");
    } catch (e) {
        return null;
    }
};

export {
    isInitialized,
    hasNetworkErrorOccurred,
    isOfflineMode,
    getOfflineReason,
    getNetworkErrorInfo,
};
