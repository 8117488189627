import React, { Component } from "react";

import { FormSelectRadio } from "./form-select-radio";
import { FormSelectCheckbox } from "./form-select-checkbox";
import { FormSelectCheckboxSingle } from "./form-select-checkbox-single";
import { FormSelectDropdown } from "./form-select-dropdown";

import { FORM_SELECT_TYPES } from "constants/form";

class FormSelect extends Component {
    state = {};
    render() {
        const { type } = this.props;

        if (type === FORM_SELECT_TYPES.RADIO) {
            return <FormSelectRadio {...this.props} />;
        } else if (
            type === FORM_SELECT_TYPES.CHECKBOX ||
            type === FORM_SELECT_TYPES.CHECKBOX_RADIO
        ) {
            const { single } = this.props;

            return single === "true" ? (
                <FormSelectCheckboxSingle {...this.props} />
            ) : (
                <FormSelectCheckbox {...this.props} />
            );
        } else if (
            type === FORM_SELECT_TYPES.DROPDOWN ||
            type === FORM_SELECT_TYPES.DROPDOWN_MULTI
        ) {
            return <FormSelectDropdown {...this.props} />;
        }

        return null;
    }
}

export { FormSelect };
