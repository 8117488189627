
// npm
import postCss from 'postcss'
import postCssJs from 'postcss-js'


const cssToReactStyle = (cssString) =>
{
	if (!cssString) { return {}; }
	const postCssRootInstance = postCss.parse(cssString);
	return postCssJs.objectify(postCssRootInstance);
}


export {cssToReactStyle}