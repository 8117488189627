import { dbStorageApi, KEYS } from "libs/browser_storage/db-storage-api";
import { check } from "@xams-utils/check-types";
import moment from "moment";

import { displayToConsole} from "./activity-logger-helper";

class ActivityLoggerSent {
    constructor() {
        this.key = KEYS.SENT_EXAMS;
        this.exams = [];
        this.sent = this.sent.bind(this);
        this.reSend = this.reSend.bind(this);
        this.remove = this.remove.bind(this);
    }

    initialise() {
        return new Promise((resolve) => {
            dbStorageApi
                .retrieveAll(this.key, true)
                .then((data) => {
                    this.exams = data;
                    resolve();
                })
                .catch((e) => {
                    debugger;
                });
        });
    }

    hasBeenSent(formRunGuid) {
        return this.exams.some((exam) => exam.formRunGuid === formRunGuid);
    }

    reSend(formRunGuid) {
        if (!this.hasBeenSent(formRunGuid)) {
            return this.sent(formRunGuid);
        }

        return new Promise((resolve) => {
            this.remove(formRunGuid).then(() => {
                this.sent(formRunGuid).then(() => {
                    resolve();
                });
            });
        });
    }

    sent(formRunGuid, needToSave = true, sentDate = null) {
        if (needToSave && !this.hasBeenSent(formRunGuid)) {
            const obj = { formRunGuid };

            if (sentDate) {
                obj.id = new moment(sentDate).format();
            }

            return new Promise((resolve) => {
                dbStorageApi.add(this.key, obj).then((response) => {
                    const { id, rev } = response;
                    this.exams = this.exams.concat([
                        { id, _rev: rev, formRunGuid },
                    ]);
                    resolve();
                });
            });
        }

        return new Promise((resolve) => {
            resolve();
        });
    }

    get(formRunGuid) {
        if (!formRunGuid) return this.exams;
        for (let i = 0; i < this.exams.length; i++) {
            if (this.exams[i].formRunGuid === formRunGuid) {
                return this.exams[i];
            }
        }

        return null;
    }

    remove(formRunGuids) {
        return new Promise((resolve) => {
            let deletedItems = null;
            
            displayToConsole('Delete sent');
            
            if (check.array(formRunGuids)) {
                formRunGuids.forEach((formRunGuid) => {
                    const item = this.get(formRunGuid);
                    if (item) {
                        if (!deletedItems) deletedItems = [];
                        deletedItems = deletedItems.concat([
                            {
                                _id: item.id,
                                _rev: item._rev,
                                _deleted: true,
                            },
                        ]);
                    }
                });

                this.exams = this.exams.filter((exam) => {
                    return formRunGuids.indexOf(exam.formRunGuid) === -1;
                });
            } else {
                const item = this.get(formRunGuids);

                if (item) {
                    this.exams = this.exams.filter(
                        (exam) => exam.formRunGuid !== formRunGuids
                    );

                    deletedItems = [{
                        _id: item.id,
                        _rev: item._rev,
                        _deleted: true,
                    }];
                }
            }

            displayToConsole('deletedItems', deletedItems);

            if (check.nonEmptyArray(deletedItems)) {
                dbStorageApi.remove(this.key, deletedItems).then(() => {
                    dbStorageApi.compactDB(this.key).then((result) => {
                        resolve();
                    });
                });
            } else {
                resolve();
            }
        });
    }

    clear() {
        this.exams = [];
    }
}

const activityLoggerSent = new ActivityLoggerSent();

export { activityLoggerSent };
