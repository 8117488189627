import { authenticationApi } from "libs/api/interface/api-authentication";

import { getSessionData } from "redux/reducers/selectors";
import { getUrlToken } from "redux/reducers/session/client/selectors";
import {
    getClientSessionData,
    getUserSessionData,
} from "redux/reducers/session/selectors";
import {
    isAdmin,
    getUserId,
    getUserGuid,
} from "redux/reducers/session/user/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getAdminUrl as getAppSettingsAdminUrl } from "redux/reducers/settings/app/selectors";
import { getAdminUrl as getClientSettingsAdminUrl } from "redux/reducers/settings/client/selectors";
import { getRemoveCurrentSessionActions } from "components/actions";

import { getLogoutPage } from "components/pages/login/form/login_token.js";

const getAdminRedirectUrl = (userID, orgID, adminUrl) => {
    const returnUrl = encodeURIComponent(window.location);
    const url = `${adminUrl}/user/login/zigzagoon?userID=${userID}&orgID=${orgID}&returnUrl=${returnUrl}`;

    return url;
};

const mapStoreToProps = (store) => {
    const sessionData = getSessionData(store);
    const userSessionData = getUserSessionData(sessionData);
    const clientSessionData = getClientSessionData(sessionData);
    const settingsData = getSettingsData(store);
    const appSettingsData = getAppSettingsData(settingsData);
    const clientSettingsData = getClientSettingsData(settingsData);

    const admin = isAdmin(userSessionData);
    const userID = getUserId(userSessionData);
    const orgID = getOrgId(clientSessionData);
    const appSettingsAdminUrl = getAppSettingsAdminUrl(appSettingsData);
    const clientSettingsAdminUrl =
        getClientSettingsAdminUrl(clientSettingsData);
    const adminUrl = clientSettingsAdminUrl || appSettingsAdminUrl;

    return {
        store,
        userID,
        orgID,
        adminPage: "admin/Default.aspx",
        adminUrl,
    };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mergeProps = (
    { store, userID, orgID, adminPage, adminUrl },
    { dispatch },
    { history, ...ownProps }
) => {
    const removeSessionFromRedux = () => {
        getRemoveCurrentSessionActions().forEach((action) => dispatch(action));
    };

    const notifyServerAboutLogout = (sessionData) => {
        const userGuid = getUserGuid(getUserSessionData(sessionData));
        authenticationApi.logoutUser(userGuid);
    };

    const redirectToLoginPage = (sessionData) => {
        const { url, logout } = getLogoutPage();
        const urlToken = getUrlToken(getClientSessionData(sessionData));
        const logoutUrl = `${url}?${urlToken}`;

        if (logout) history.push(logoutUrl);
        else window.location.replace(logoutUrl);
    };

    return {
        userID,
        orgID,
        adminPage,
        adminUrl,
        ...ownProps,
        logout: (redirect = false) => {
            const sessionData = getSessionData(store);
            notifyServerAboutLogout(sessionData);
            removeSessionFromRedux();
            if (redirect) {
                redirectToLoginPage(sessionData);
            }
        },
    };
};

export { getAdminRedirectUrl, mapStoreToProps, mapDispatchToProps, mergeProps };
