
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react
import {Popup} from 'components/layout/popup'
import {CloseErrorButton} from 'components/pages/exam/initialization/close-error-button'

// redux (selectors)
import { getSchedulesData, getSettingsData } from "redux/reducers/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import { getCantStartMessage } from "redux/reducers/schedules/schedule/selectors";
import { getProctoringMode } from "redux/reducers/settings/client/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// ScheduleCantStartPopup (not connected to store)
// ---------------------------------------------------------------

let ScheduleCantStartPopup = ({message, messages, proctoringMode}) =>
{
	const buttons = proctoringMode === "1" ? [] : [<CloseErrorButton />];
	
	const props = {
		title: messages[MESSAGE_IDS.EXAM.CANT_START],
		buttons,
		content: { text: message },
		canClickBackdrop: false
	}

	return <Popup {...props}/>;
}

ScheduleCantStartPopup.propTypes = {
	message: PropTypes.string.isRequired
}


// ScheduleCantStartPopup (connected to store)
// ---------------------------------------------------------------

const mapStoreToProps = (store, {examGuid}) => {
	const schedulesData = getSchedulesData(store);
	const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);

	const message = scheduleData?getCantStartMessage(scheduleData):"";
	
	const settingsData = getSettingsData(store);
    const proctoringMode =  getProctoringMode(getClientSettingsData(settingsData));

	return { message, guid: undefined, proctoringMode }
}

ScheduleCantStartPopup = connect(mapStoreToProps)(ScheduleCantStartPopup);
ScheduleCantStartPopup = withMessages(ScheduleCantStartPopup);


// Export
// ---------------------------------------------------------------
export {ScheduleCantStartPopup}