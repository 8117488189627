
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {AnswerBox} from './answer-box'
import {AscxAnswerForm} from '../answer_forms/ascx'

// redux (selectors)
import {getResumedAnswer} from 'redux/reducers/exam/content/questions/question/ascx/selectors'


class AscxAnswerBox extends React.Component
{
	constructor(props)
	{
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	// extract information from questionData
	// --------------------------------------
	setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => undefined;
		this.extractResumedAnswer = () => {
			return getResumedAnswer(this.props.data);
		};
		this.extractFormData = () => { return this.props.data };
	}

	// convert between answer values
	// --------------------------------------
	setUpTransformationFunctions()
	{
		this.getFormValue = (formAnswer) => formAnswer;
		this.toFormValue = (localAnswer) => localAnswer;
		this.toLocalAnswer = (formValue) => formValue;
		this.toServerAnswer = (localAnswer) => {
			return {answerText: localAnswer, options: []};
		};

	}

	render()
	{
		const answerBoxProps = {
			questionData: this.props.data,
			FormComponent: AscxAnswerForm,
			toFormValue: this.toFormValue,
			getFormValue: this.getFormValue,
			toLocalAnswer: this.toLocalAnswer,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer
		}

		return <AnswerBox {...answerBoxProps}/>;
	}
}

AscxAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export {AscxAnswerBox}