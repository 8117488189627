
import React, { Component } from 'react';
import { check } from '@xams-utils/check-types';
import moment from "moment";

import { getComponentValue } from "../form-helper";

class FormFieldCalendar extends Component {
    state = {}

    constructor(props) {
        super(props);

        this.month = props.month || 6;
        this.date = new moment().set('month', this.month - 1);
        this.daysInMonth = this.date.daysInMonth();
        this.year = this.date.year();
        this.width = props.width || 25;
        this.selectColour = props.selectColour || 'blue';

        this.handleClick = this.handleClick.bind(this);
    }

    displayMonth() {
        return <tr>
            <td colSpan={7} style={{ width: "100%", textAlign: "center" }} >{this.date.format('MMMM')}</td>
        </tr>
    }

    displayDays(selectedDay) {
        const rows = this.daysInMonth % 7 === 0 ? parseInt(this.daysInMonth / 7) : parseInt(this.daysInMonth / 7) + 1;
        const items = Array.from(Array(rows).keys())

        return items.map(item => this.displayDayRow(item, selectedDay))
    }

    displayDayRow(row, selectedDay) {
        const { enabled } = this.props;
        const startDay = (row * 7) + 1;

        const items = Array.from(Array(7).keys());
        return <tr key={row}>{items.map((item, index) => {
            const day = startDay + item <= this.daysInMonth ? startDay + item : "";
            let cursor = enabled && day <= this.daysInMonth ? "pointer" : "default";
            const props = {
                style: {
                    cursor,
                    width: `${this.width}px`,
                    textAlign: 'center',
                    background: selectedDay === day ? this.selectColour : 'transparent',
                    paddingBottom: "3px",
                    userSelect: "none"
                },
                onClick: enabled && day <= this.daysInMonth ? () => this.handleClick(day) : null,
                key: index,
            }

            return <td {...props}>{day}</td>
        }
        )}</tr>

    }

    handleClick(day) {
        const { onChange, name, enabled } = this.props;

        if (enabled) {
            const value = `${day}/${this.month}/${this.year}`;

            onChange(name, value);
        }
    }

    render() {
        const { formValue, name } = this.props;
        const value = getComponentValue(name, formValue);
        const selectedDay = check.nonEmptyString(value) ? moment(value, 'D/M/YYYY').date() : null;

        return (<table>
            <tbody>
                {this.displayMonth()}
                {this.displayDays(selectedDay)}
            </tbody>
        </table>);
    }
}

export { FormFieldCalendar };