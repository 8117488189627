
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {getSessionData} from 'redux/reducers/selectors'
import {getUserSessionData, getClientSessionData, getTokenData} from 'redux/reducers/session/selectors'


// SessionVerifier (not connected to store)
// ------------------------------------------

class SessionVerifier extends React.Component
{
	componentDidMount()
	{
		const validSession = 
			this.props.user.size > 0 &&
			this.props.client.size > 0 &&
			this.props.tokens.size > 0;

		if (validSession) { this.props.onSuccess(); }
		else { this.props.onFail(); }
	}

	render()
	{
		return null;
	}
}

SessionVerifier.propTypes = {
	user: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	tokens: PropTypes.object.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired
}


// SessionVerifier (connected to store)
// ------------------------------------------

const mapStoreToProps = (store) => ({
	user: getUserSessionData(getSessionData(store)),
	client: getClientSessionData(getSessionData(store)),
	tokens: getTokenData(getSessionData(store))
});

SessionVerifier = connect(mapStoreToProps)(SessionVerifier);


export {SessionVerifier}