
// npm
import {connect} from 'react-redux'

// redux (actions)
import {Callback} from 'components/functional/callback'
import {setNetworkError} from 'components/pages/actions'


const mapDispatchToProps = (dispatch) => ({
	onMount: () => dispatch(setNetworkError(true))
});

const SetNetworkError = connect(undefined, mapDispatchToProps)(Callback);


export {SetNetworkError}