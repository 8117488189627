
// npm
import React from 'react'
import check from 'check-types'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'


class GetDomElement extends React.Component
{
	constructor(props)
	{
		super(props);
		this.childRef = React.createRef();
		this.domElement = null;
	}

	componentDidMount()
	{
		this.updateDomElement();
	}

	componentDidUpdate(previousProps)
	{
		if (previousProps.children !== this.props.children)
		{
			this.updateDomElement();
		}
	}

	updateDomElement()
	{
		const currentDomElement = this.findDomElement();
		this.setDomElement(currentDomElement);
	}

	findDomElement()
	{
		let element = this.childRef.current;
		const isElement = check.instanceStrict(element, Element);
		if (isElement) { return element; }

		element = ReactDOM.findDOMNode(this.childRef.current);
		if (element !== null) { return element; }
		
		throw ```<GetDomElement>
			Child must be one of the following:
			- a DOM react instance
			- a React element declared as a class
		```;
	}

	render()
	{
		return React.cloneElement(this.props.children, {ref: this.childRef});
	}

	setDomElement(domElement)
	{
		this.domElement = domElement;
		this.props.onDomElement(domElement);
	}
}

GetDomElement.propTypes = {
	children: PropTypes.element.isRequired,
	onDomElement: PropTypes.func.isRequired
}


export {GetDomElement}