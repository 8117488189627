import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { AppPageModifier } from "components/pages/app-page-modifier";

import { settingsApi } from "libs/api/interface/api-settings";

import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";
import {
    getExamData,
    getSchedulesData,
    getProctorioData,
} from "redux/reducers/selectors";
import { getGuid } from "redux/reducers/exam/selectors";
import { isScheduleForLastCompletedExamComplete } from "redux/reducers/schedules/selectors";
import { getParent, getDebugMode } from "redux/reducers/proctorio/selectors";

import { MaterialText } from "components/presentation/material-text";
import { Align } from "components/layout/align";
import Paper from "@material-ui/core/Paper";

const styles = (theme) => ({
    paper: {
        flexGrow: 1,
        maxWidth: 800,
        padding: theme.spacing.unit,
        margin: theme.spacing.unit * 2,
        backgroundColor: theme.palette.background.default,
    },
});

class ProctorioFinishPage extends Component {
    constructor(props) {
        super(props);

        this.checkOrgSetting = this.checkOrgSetting.bind(this);
        this.loadOrgSetting = this.loadOrgSetting.bind(this);
        this.returnToSchedules = this.returnToSchedules.bind(this);

        this.state = {
            message: "Finishing Your Proctorio Session",
            title: "Finishing Proctorio Session",
        };
    }

    componentDidMount() {
        const { proctorioActive, returnToProctorio, proctorioDebug, logout } =
            this.props;

        if (proctorioActive && returnToProctorio) {
            //logout();

            if (proctorioDebug) {
                const url = `http://localhost:3000/proctorio/finish`;
                alert("Finish Proctorio");
                window.location = url;
            } else {
                window.top.postMessage(
                    ["exam_state_change", "exam_end"],
                    "https://getproctorio.com"
                );
            }
        } else {
            this.loadOrgSetting();
        }
    }

    loadOrgSetting() {
        const { orgId } = this.props;

        settingsApi
            .getProctorioLoginlessMode(orgId)
            .then((response) => {
                this.checkOrgSetting(response);
            })
            .catch((error) => {
                this.checkOrgSetting(null);
            });
    }

    checkOrgSetting(response = null) {
        let gotoSchedules = true;

        if (check.nonEmptyString(response)) {
            const parsedResponse = JSON.parse(response);

            if (check.nonEmptyObject(parsedResponse)) {
                const { value } = parsedResponse;

                if (check.boolean(value)) {
                    gotoSchedules = !value;
                } else {
                    gotoSchedules = value !== "1";
                }
            }
        }

        if (gotoSchedules) {
            this.returnToSchedules();
        } else {
            this.displayMessage();
        }
    }

    returnToSchedules() {
        const { history } = this.props;

        history.push("/schedules");
    }

    displayMessage() {
        this.setState({
            message: "Your proctorio session has now finished",
            title: "Proctorio Session Finished",
        });
    }

    render() {
        const { message, title } = this.state;
        const { classes } = this.props;
        
        return (
            <React.Fragment>
                <AppPageModifier
                    id="finish-proctoria"
                    appBarProps={{ loading: false, title, logout: false }}
                />
                <Align top>
                    <Paper className={classes.paper}>
                        <MaterialText variant="h6">{message}</MaterialText>
                    </Paper>
                </Align>
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);
    const proctorioActive = getParent(proctorioData);
    const proctorioDebug = getDebugMode(proctorioData);

    const examData = getExamData(store);
    const examGuid = getGuid(examData);

    const schedulesData = getSchedulesData(store);
    const returnToProctorio = proctorioActive
        ? isScheduleForLastCompletedExamComplete(schedulesData, examGuid)
        : false;

    const orgId = getOrgId(getClientSessionData(getSessionData(store)));

    return {
        proctorioActive,
        proctorioDebug,
        returnToProctorio,
        orgId,
    };
};

ProctorioFinishPage = withRouter(ProctorioFinishPage);

ProctorioFinishPage = connect(mapStoreToProps)(ProctorioFinishPage);

ProctorioFinishPage = withStyles(styles)(ProctorioFinishPage);

export { ProctorioFinishPage };
