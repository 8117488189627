
// react
import {entifySupportedBrowsersVersions} from './entify-supported-browsers-versions'


const isResetScheduleCheatActive = (appSettingsData) =>
{
  return appSettingsData.get('useResetScheduleCheat');
}

const getScormServer = (appSettingsData) =>
{
  return appSettingsData.get('scormServer');
}

const getAppVersion = (appSettingsData) =>
{
  return appSettingsData.get('appVersion');
}

const getAdminUrl = (appSettingsData) =>
{
  return appSettingsData.get('adminUrl');
}

const getSupportedBrowsers = (appSettingsData) => 
{
	const supportedBrowsers = appSettingsData.get('supportedBrowsers');
  return entifySupportedBrowsersVersions(supportedBrowsers);
}

const getPdfSupportedBrowsers = (appSettingsData) => {
    return appSettingsData.get("pdfSupportedBrowsers");
};

const isInactivityTimerActive = (appSettingsData) =>
{
	return appSettingsData.get('useInactivityTimer');
}

const getHubUrl = (appSettingsData) =>
{
	return appSettingsData.get('hubUrl');
}

const getApiEndpointUrl = (appSettingsData) =>
{
	return appSettingsData.get('apiEndpointUrl');
}

const getHubEndpointUrl = (appSettingsData) =>
{
	return appSettingsData.get('hubEndpointUrl');
}

const getAuthenticationUrl = (appSettingsData) =>
{
	return appSettingsData.get('authenticationUrl');
}

const shouldProduceRandomNetworkErrors = (appSettingsData) =>
{
	return appSettingsData.get('produceRandomNetworkErrors');
}

const getNetworkErrorToRandomlyProduce = (appSettingsData) =>
{
	return appSettingsData.get('networkErrorCode');
}

const getNetworkErrorFrequency = (appSettingsData) =>
{
	return appSettingsData.get('networkErrorFrequency');
}


export {
	isResetScheduleCheatActive,
	getSupportedBrowsers,
	getPdfSupportedBrowsers,
	isInactivityTimerActive,
	shouldProduceRandomNetworkErrors,
	getNetworkErrorToRandomlyProduce,
	getNetworkErrorFrequency,
	getApiEndpointUrl,
	getHubEndpointUrl,
	getAuthenticationUrl,
	getHubUrl,
	getScormServer,
	getAdminUrl,
	getAppVersion
}