
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

// react
import {withMessages} from 'components/hocs/messages'
import {ActionButton} from 'components/layout/action_button/button'

// other
import {MESSAGE_IDS} from 'constants/message-ids'


const finishMessageId = MESSAGE_IDS.GENERAL.FINISH;


let FinishExamButton = ({onClick, messages}) => (
	<ActionButton Icon={AssignmentTurnedInIcon} alignment='right' onClick={onClick}>
		{messages[finishMessageId]}
	</ActionButton>
)

FinishExamButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[finishMessageId]: PropTypes.string.isRequired
	})
}


FinishExamButton = withMessages(FinishExamButton);


export {FinishExamButton}