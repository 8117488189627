import { Console, LOG_TYPES } from 'custom/console';

const openFullScreen = (elem) => {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
    }
};

const closeFullScreen = (elem) => {
    if (document.exitFullscreen) {
        if (document.fullscreenElement !== null) {
            document
                .exitFullscreen()
                .then(() => {
                    Console.log("Closed iFrame", LOG_TYPES.TEMP);
                })
                .catch((e) => {
                    Console.log(e, LOG_TYPES.TEMP);
                });
        }
    } else if (document.webkitExitFullscreen) {
        /* Safari */
        if (document.webkitFullscreenElement !== null) {
            document.webkitExitFullscreen();
        }
    } else if (document.msExitFullscreen) {
        /* IE11 */
        if (document.msFullscreenElement !== null) {
            document.msExitFullscreen();
        }
    }
};

export { openFullScreen, closeFullScreen };
