
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import MaterialBookmarkIcon from '@material-ui/icons/Bookmark'


// BookmarkIcon (not connected to styles)
// -------------------------------------------------

let BookmarkIcon = ({active, classes}) => {
	if (!active) { return null; }
	
	const props = {
		fontSize: 'small',
		color: 'secondary',
		className: classes.icon
	}

	return <MaterialBookmarkIcon {...props}/>;
}

BookmarkIcon.propTypes = {
	active: PropTypes.bool,
	classes: PropTypes.object.isRequired
}


// BookmarkIcon (connected to styles)
// -------------------------------------------------

const styles = (theme) => ({
	icon: {
		marginTop: -14,
		marginLeft: 18,
		position: 'absolute',
		color: theme.palette.secondary.main
	}
});

BookmarkIcon = withStyles(styles)(BookmarkIcon);


// Export
// -------------------------------------------------
export {BookmarkIcon}