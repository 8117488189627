
// xams-utils
import {random} from '@xams-utils/random'
import {check} from '@xams-utils/check-types'
import {convert} from '@xams-utils/convert-unit'

// constants
import {REQUEST_TYPES} from './constants'


const PRODUCABLE_ERROR_CODES = [444];

const getRandomErrorCode = () => {
  const randomIndex = random.integer(PRODUCABLE_ERROR_CODES.length);
  return PRODUCABLE_ERROR_CODES[randomIndex];
}

const authenicateEndPoint = (endPoint) => {
    if (!endPoint) return false;

    return check.nonEmptyString(endPoint)
        ? endPoint.toLowerCase().indexOf("auth") !== -1
        : false;
};


class HttpRequest
{
  constructor(token, endpoint)
  {
    //this.timeout = convert(30).from('s').to('ms');
    this.endpoint = endpoint;
    this.requestType = null;
    this.responseType = null;
    this.progressListener = null;
    this.payload = null;
    this.onload = () => {};
    this.onerror = () => {};
    this.token = token;
  }

  run()
  {
    if (!check.string(this.endpoint)) {
      throw 'HttpRequest endpoint not a string';
    }

    if (this.shouldErrorOut()) {
      this.runMockErrorRequest();
    }
    else {
      this.runOrdinaryRequest();
    }
  }

  runOrdinaryRequest()
  {
    const request = new XMLHttpRequest();

    request.timeout = this.timeout;
    request.onload = () => { this.onload(request); }
    request.onerror = () => { this.onerror(request); }
    request.ontimeout = () => { this.onerror(request); }
    
    if (this.responseType !== null) {
      request.responseType = this.responseType;
    }

    if (this.progressListener !== null) {
      const onLoad = (e) => this.progressListener(e.loaded);
      request.upload.addEventListener('progress', onLoad);
    }

    if (this.requestType === REQUEST_TYPES.DELETE) {
      this.runDeleteRequest(request);
    }
    else if (!this.payload) {
      this.runGetRequest(request);
    }
    else {
      this.runPostRequest(request);
    }
  }

  runMockErrorRequest()
  {
    const status = this.errorCode || getRandomErrorCode();
    const getAllResponseHeaders = () => "Test headers";
    const response = "Test response";
    this.onerror({status, response, getAllResponseHeaders}); 
  }

  shouldErrorOut = () => {
    if (!this.errorFrequency) { return false; }
    const randomNumber = random.integer(this.errorFrequency);
    return randomNumber === 0;
  }

  runGetRequest(request)
  {
    request.open(REQUEST_TYPES.GET, this.endpoint);
    this.tokenizeRequest(request);
    request.send();
  }

  runDeleteRequest(request)
  {
    request.open(REQUEST_TYPES.DELETE, this.endpoint);
    this.tokenizeRequest(request);
    request.send();
  }

  runPostRequest(request)
  {
    request.open(REQUEST_TYPES.POST, this.endpoint);

    if (!(this.payload instanceof FormData)) {
      this.stringifyPayload();
      this.jsonifyRequest(request);
    }
    else {
      if (authenicateEndPoint(this.endpoint)){
        this.formifyRequest(request);
      }
    }

    this.tokenizeRequest(request);
    request.send(this.payload);
  }

  tokenizeRequest(request)
  {
    const args = ['Authorization', `Bearer ${this.token}`];
    if (!!this.token) { request.setRequestHeader(...args); }
  }

  stringifyPayload()
  {
    const payload = this.payload;
    this.payload = check.string(payload) ? payload : JSON.stringify(payload);
  }

  jsonifyRequest(request)
  {
    request.setRequestHeader('Content-Type', 'application/json');
  }

  formifyRequest(request)
  {
    const payload = this.payload;
    let s = "";

    for (let pair of payload.entries()) {
        if (s.length > 0) s += "&";
        s += `${pair[0]}=${pair[1]}`;
    }

    this.payload = s;

    request.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded"
    );
  }

  set Payload(value)
  {
    this.payload = value;
  }

  set OnLoad(value)
  {
    if (!check.function(value)) {
      throw 'HttpRequest.onload is not a function';
    }

    this.onload = value;
  }

  set OnError(value)
  {
    if (!check.function(value)) {
      throw 'HttpRequest.onerror is not a function';
    }

    this.onerror = value;
  }

  set ResponseType(value)
  {
    this.responseType = value;
  }

  set NetworkErrorConfiguration({frequency, errorCode}={})
  {
    if (check.assigned(frequency)) {
      if (!check.integer(frequency)) {
        throw "HttpRequest error frequency must be an integer";
      }
      if (check.assigned(errorCode) && !check.integer(errorCode)) {
        throw "HttpRequest random error code must be an integer";
      }

      this.errorFrequency = frequency;
      this.errorCode = errorCode;
    }
  }
}


export {HttpRequest}