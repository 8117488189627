
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE NAMES
// #########################################

const STATES = {
	CHECKING_SCHEDULE_COMPLETION : 'checking_schedule_completion',
	SCHEDULE_ALREADY_COMPLETED: 'schedule_aready_completed',
	CHECKING_SCHEDULE_CAN_START: 'checking_schedule_can_start',
	SCHEDULE_CANT_START: 'schedule_cant_start',
	REDIRECT_TO_PROCTORIO: 'redirect_to_proctorio',
	CANT_REDIRECT: 'cant_redirect'
}


// #########################################
// EVENT NAMES
// #########################################

const EVENTS = {
	SCHEDULE_INCOMPLETE: 'schedule_incomplete',
	SCHEDULE_COMPLETE: 'schedule_complete',
	SCHEDULE_CANT_START: 'schedule_cant_start',
	SCHEDULE_CAN_START: 'schedule_can_start',
	ERROR: 'error',
}


// #########################################
// STATES
// #########################################

const checkingScheduleCompletion = new XStateConfig();
checkingScheduleCompletion.addTransition(EVENTS.SCHEDULE_COMPLETE, STATES.SCHEDULE_ALREADY_COMPLETED);
checkingScheduleCompletion.addTransition(EVENTS.SCHEDULE_INCOMPLETE, STATES.CHECKING_SCHEDULE_CAN_START);

const scheduleAlreadyComplete = new XStateConfig();

const checkingScheduleCanStart = new XStateConfig();
checkingScheduleCanStart.addTransition(EVENTS.SCHEDULE_CANT_START, STATES.SCHEDULE_CANT_START);
checkingScheduleCanStart.addTransition(EVENTS.SCHEDULE_CAN_START, STATES.REDIRECT_TO_PROCTORIO);

const scheduleCantStart = new XStateConfig();

const redirectToProctori = new XStateConfig();
redirectToProctori.addTransition(EVENTS.ERROR, STATES.CANT_REDIRECT);

const cantRedirect = new XStateConfig();


// #########################################
// MACHINE
// #########################################

const _proctorioRedirect = new XStateConfig();
_proctorioRedirect.initialState = STATES.CHECKING_SCHEDULE_COMPLETION;
_proctorioRedirect.addState(STATES.CHECKING_SCHEDULE_COMPLETION, checkingScheduleCompletion);
_proctorioRedirect.addState(STATES.SCHEDULE_ALREADY_COMPLETED, scheduleAlreadyComplete);
_proctorioRedirect.addState(STATES.CHECKING_SCHEDULE_CAN_START, checkingScheduleCanStart);
_proctorioRedirect.addState(STATES.SCHEDULE_CANT_START, scheduleCantStart);
_proctorioRedirect.addState(STATES.REDIRECT_TO_PROCTORIO, redirectToProctori);
_proctorioRedirect.addState(STATES.CANT_REDIRECT, cantRedirect);

const machine = Machine(_proctorioRedirect.toObject());
machine.id = "Redirect To Proctorio Machine";


// #########################################
// EXPORT
// #########################################

const proctorioRedirect = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES}
}

export {proctorioRedirect}