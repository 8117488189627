
import PromiseQueue from 'promise-queue'


const MAX_CONCURRENT_ITEMS = 1;
const MAX_QUEUE_SIZE = Infinity;


const examApiQueue = new PromiseQueue(MAX_CONCURRENT_ITEMS, MAX_QUEUE_SIZE);


export {examApiQueue}