import {SET_QUESTION_ID_FROM_QUESTION} from 'redux/reducers/exam/content/action-types'
import {SET_AUDIO_ELAPSED, SET_AUDIO_STATE} from "redux/reducers/exam/content/sections/section/action-types"
import { CONSTANTS } from "components/functional/audio/audio-chip";

const setQuestionIdSetFromQuestion = (value) => ({
	type: SET_QUESTION_ID_FROM_QUESTION,
	value,
});

const setAudioElapsed = (id, value) => ({
	type: SET_AUDIO_ELAPSED,
	id,
	value,
});

const setAudioState = (id, value) => ({
	type: SET_AUDIO_STATE,
	id,
	value,
});

const setAudioStateFinish = (id) => ({
	type: SET_AUDIO_STATE,
	id,
	value: CONSTANTS.finished,
});

const setAudioStatePlay = (id) => ({
	type: SET_AUDIO_STATE,
	id,
	value: CONSTANTS.playing,
});

const setAudioStatePause = (id) => ({
	type: SET_AUDIO_STATE,
	id,
	value: CONSTANTS.paused,
});


export {setQuestionIdSetFromQuestion, setAudioElapsed, setAudioState, setAudioStateFinish, setAudioStatePause, setAudioStatePlay}