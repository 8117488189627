
// npm
import {Map as ImmutableMap} from 'immutable'

// redux (action-types)
import {SAVE_SETTINGS} from './action-types'

// utils
import {api} from 'libs/api/api'


const APP_SETTINGS_REDUCER = (state = ImmutableMap(), action) =>
{
  switch (action.type) {
    case SAVE_SETTINGS:
      return state.withMutations(mutableState => {
        const {json} = action;
        mutableState.set('useResetScheduleCheat', json.useResetScheduleCheat);
        mutableState.set('supportedBrowsers', json.supportedBrowsers);
        mutableState.set('pdfSupportedBrowsers', json.pdfSupportedBrowsers);
        mutableState.set('useInactivityTimer', json.useInactivityTimer);
        mutableState.set('apiEndpointUrl', json.apiEndpointUrl);
        mutableState.set('hubEndpointUrl', json.hubEndpointUrl);
        mutableState.set('hubUrl', json.hubUrl);
        mutableState.set('scormServer', json.scormServer);
        mutableState.set('appVersion', json.version || "0.0.0.0");
        mutableState.set('adminUrl', json.adminUrl);
        mutableState.set('authenticationUrl', json.authenticationUrl);

        const {produceRandomNetworkErrors} = json;
        if (produceRandomNetworkErrors.active) {
          const {frequency, errorCode} = produceRandomNetworkErrors;
          api.produceRandomNetworkErrors(frequency, errorCode);
          mutableState.set('produceRandomNetworkErrors', true);
          mutableState.set('networkErrorFrequency', frequency);
          mutableState.set('networkErrorCode', errorCode);
        }
      });
    default:
      return state;
  }
};


export {APP_SETTINGS_REDUCER}
