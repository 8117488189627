import React, { Component } from 'react';
import {connect} from 'react-redux'

import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {getNumber, isAnswered} from 'redux/reducers/exam/content/questions/question/selectors'

import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "@material-ui/core/Avatar";
import Fab from '@material-ui/core/Fab'


const styles = ({ spacing, palette }) => ({
    avatar: {
        background: palette.primary.main,
        width: "32px",
        height: "32px",
        marginBottom: spacing.unit * 2        
    },
    activeLabel: {
		textDecoration: 'underline'
	},
	button: {
		margin: spacing.unit / 2,
        marginBottom: spacing.unit,
		padding: 0,
	},
	buttonColor: {
		backgroundColor: palette.primary.main + "!important",
		color: palette.primary.contrastText + "!important"
	},
	buttonActiveColor: {
		backgroundColor: palette.primary.dark + "!important",
		color: palette.primary.contrastText + "!important"
	},
	buttonDisabledColor: {
		backgroundColor: palette.primary.light + "!important",
		color: palette.background.contrastText + "!important"
	}    
});


class QuestionNumber extends Component {
    state = {  } 

    displayFab(){
        const {answered, selected, classes, number} = this.props;

        const fabProps = {
            size: 'small',
            // onClick: props.onClick,
            // disabled: true,
            className: classes.button,
            color: answered ? 'primary' : 'default',
            classes: !selected ? {} : {label: classes.activeLabel}
        } 

        return <Fab {...fabProps}>
				{number}
			</Fab>       
    }

    displayAvatar(){
        const {number, classes} = this.props;

        return <Avatar className={classes.avatar}>{number}</Avatar>
    }

    render() { 
        return this.displayFab();
    }
}

QuestionNumber = withStyles(styles)(QuestionNumber);

const mapStoreToProps = (store, {questionId}) => {
	const contentData = getContentData(getExamData(store));
	const questionsData = getQuestionsData(contentData);
	const questionData = getQuestionDataById(questionsData)(questionId);

	return {number: getNumber(questionData), answered: isAnswered(questionData)};
};

QuestionNumber = connect(mapStoreToProps)(QuestionNumber);
 
export {QuestionNumber};