
// npm
import * as Immutable from 'immutable'

// custom
import {getProp} from 'custom/object-helper'

// redux
import {extractMessage} from 'redux/reducers/exam/helpers'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const ROW_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			state = state.set('id', action.data['@ID']);
			const valuesAsText = extractMessage(getProp(action, 'data.TEXT'));
			const values = valuesAsText.split('|');
			return state.set('values', values.map(x => x === "" ? undefined : x));
		default:
			return state;
	}
}

export {ROW_REDUCER}