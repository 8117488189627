import check from "check-types";

const concatParams = (params) => {
    if (!check.object(params)) {
        throw "Api params not an object";
    }
    if (check.emptyObject(params)) {
        return "";
    }

    let finalParams = "?";

    Object.entries(params).forEach(([paramName, paramValue]) => {
        // Object.entries ensures we're working with 'own' properties
        finalParams = `${finalParams}${paramName}=${paramValue}&`;
    });

    // remove final '&' character
    const cutOffIndex = finalParams.lastIndexOf("&"); // using lastIndexOf instead of string length in case..
    return finalParams.slice(0, cutOffIndex); // ..there are any problems with unicode characters
};

export {concatParams}