import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {getSessionData} from 'redux/reducers/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getUserGuid, isAdmin} from 'redux/reducers/session/user/selectors'

import IconButton from '@material-ui/core/IconButton'
import AssessmentIcon from '@material-ui/icons/Assessment'

class ActivityReporter extends Component {
    state = {  }

    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { history } = this.props;
        history.replace("/logs");
    }

    render() {
        const {admin, userGuid, isOn} = this.props;

        if (!isOn || !userGuid || !admin) {
			return null;
        }
      
        return ( 
        <IconButton onClick={this.handleClick}>
			<AssessmentIcon/>
		</IconButton>            
         );
    }
}
 
const mapStoreToProps = (store) =>
{
    const userSessionData = getUserSessionData(getSessionData(store));

	return {
        isOn: true,
        admin: isAdmin(userSessionData),
        userGuid: getUserGuid(userSessionData)
	}
}

ActivityReporter = connect(mapStoreToProps)(ActivityReporter);
ActivityReporter = withRouter(ActivityReporter);


export {ActivityReporter}