
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Divider from '@material-ui/core/Divider'

// react
import {SectionNavigationItem} from './navigation-item'

// constants
import {ID_TYPE} from '../constants'


const containerStyle = {
	width: '100%',
	flexShrink: 0
}


class SectionNavigationPanel extends React.Component
{
	render()
	{
		const parentId = this.getParentId(this.props.chosenSection);									// parent contains the mappings needed for the most deeply-nested nav item
		return parentId ? this.getNavigationItems(parentId) : null;
	}

	getNavigationItems(parentId)
	{
		const navigationItems = [];
		const {data:{sections, mappings}, onChange, chosenSection} = this.props;

		while (parentId)
		{
			const parentMapping = mappings[parentId];
			const nodes = parentMapping.map(id => ({id, node: sections[id]}));
			navigationItems.unshift(<SectionNavigationItem nodes={nodes} onChange={onChange} selected={chosenSection}/>);
			parentId = this.getParentId(parentId);
		}

		return (
			<div style={containerStyle}>
				{navigationItems}
				<Divider/>
			</div>
		)
	}

	getParentId(childId)
	{
		const {mappings} = this.props.data;
		for (let parentId in mappings) {
			const mapping = mappings[parentId];
			if (mapping.includes(childId)) { return parentId; }
		}
	}
}

SectionNavigationPanel.propTypes = {
	data: PropTypes.shape({
		sections: PropTypes.objectOf(PropTypes.node).isRequired,
		mappings: PropTypes.objectOf(PropTypes.arrayOf(ID_TYPE)).isRequired
	}).isRequired,
	onChange: PropTypes.func.isRequired
}


export {SectionNavigationPanel}