
// npm
import React from 'react'

// react
import {MaterialScrollWrapper} from 'components/layout/scroll_wrapper/material-scroll-wrapper'


const ContentPanel = ({children}) => (
	<MaterialScrollWrapper horizontal={false}>
		<div style={{flexGrow: 1}}>
			{children}
		</div>
	</MaterialScrollWrapper>
)


export {ContentPanel}