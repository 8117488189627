
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Map as ImmutableMap} from 'immutable'
import ImmutablePropTypes from 'immutable-prop-types'

// material-ui
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'

// redux (selectors)
import {getTheme} from 'redux/reducers/theme/selectors'
import {getThemeData, getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getThemesData} from 'redux/reducers/settings/client/selectors'

import {ThemeSelectorItem} from './theme-selector-item'

// redux (action-types)
import {SET_THEME} from 'redux/reducers/theme/action-types'


// ThemeSelector (not connected)
// --------------------------------------------------------------------

let ThemeSelector = (props) =>
{
	const {classes} = props;

	if (props.themeNames.size === 0) {
		return null;
	}

	const selectProps = {
		className: classes.bgr,
		inputProps: {className: classes.text},
		onChange: props.setCurrentTheme,
		value: props.currentTheme,
		classes: {icon: classes.text},
		renderValue: (value)=>value
	}

	// const children = props.themeNames.map(themeName => (
	// 	<MenuItem key={themeName} value={themeName}>
	// 		{themeName}
	// 	</MenuItem>
	// ))

	const children = props.themes.map(theme => {
		const {name} = theme;
		return (
		<MenuItem key={name} value={name}>
			<ThemeSelectorItem {...theme} />
		</MenuItem>
	)});

	return (
		<Select {...selectProps}>
			{children}
		</Select>
	);
}

ThemeSelector.propTypes = {
	classes: PropTypes.object.isRequired,
	currentTheme: PropTypes.string.isRequired,
	setCurrentTheme: PropTypes.func.isRequired,
	themeNames: ImmutablePropTypes.seq.isRequired,
}


// ThemeSelector (connected to store)
// --------------------------------------------------------------------

const withDefaultFirst = (a, b) => {
	if (a === 'Default') { return -1; }
	else if (b === 'Default') { return 1; }
	return 0;
}

const mapStoreToProps = (store) => {
	const clientSettingsData = getClientSettingsData(getSettingsData(store));
	const themesData = getThemesData(clientSettingsData) || ImmutableMap();
	return {
		currentTheme: getTheme(getThemeData(store)),
		themeNames: themesData.keySeq().sort(withDefaultFirst),
		themes: themesData.keySeq().sort(withDefaultFirst).map(theme=>{
			return {
				name: theme,
				data: themesData.get(theme).toJS()
			}
		}).toJS()
	}
};

const mapDispatchToProps = (dispatch) => ({
	setCurrentTheme: ({target:{value}}) => {
		dispatch({type: SET_THEME, value})
	}
});

ThemeSelector = connect(mapStoreToProps, mapDispatchToProps)(ThemeSelector);


// ThemeSelector (connected to styles)
// --------------------------------------------------------------------

const styles = ({spacing, palette}) => ({
	text: {
		color: palette.primary.contrastText
	},
	bgr: {
		color: palette.primary.dark,
		marginLeft: spacing.unit * 2,
		marginRight: spacing.unit
	}
})

ThemeSelector = withStyles(styles)(ThemeSelector);


// ThemeSelector (EXPORT)
// --------------------------------------------------------------------

export {ThemeSelector}