
// npm
import React from 'react'
import PropTypes from 'prop-types'
import reactTimer from 'react-timer-hoc'
import { connect } from "react-redux";

import { getExamData } from "redux/reducers/selectors";
import { getLeaveExam, getResumeExam } from "redux/reducers/exam/selectors";

import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";

import {addLog, displayDateTime,displayTime} from './timer-debug'


const getElapsedDateTime = (lastDateTime, currentDateTime) => {
	let elapsedDateTime = currentDateTime - lastDateTime;
	if (elapsedDateTime < 0) {
		elapsedDateTime = 0
	}
	return elapsedDateTime;
}

// Timer (not connected to reactTimer)
// -------------------------------------------------------

class Timer extends React.Component
{
	constructor(props)
	{
		super(props);

		const resumeTime = props.resumeTime || props.startTime;

		this.state = {
			time: resumeTime,
			// cache constant props
			resumeTime: resumeTime,
			startTime: props.startTime,
			endTime: props.endTime,
			tickTime: props.tickTime,
			direction: props.direction,
			ticker: null
		};

		this.lastDateTime = null;

		this.finished = this.isFinished();

		this.lastDateTime = null;
        this.debugTimerCounter = 0;
	}

	componentWillUnmount(){
		if (this.props.timer) this.props.timer.stop();
	}

	componentDidMount()
	{
		if (!this.finished)
		{
			this.validateTimerDirection();
			this.timerStartDt = this.getDateTime();
			this.props.timer.setDelay(this.state.tickTime);
		}
		else
		{
			if (this.props.timer) this.props.timer.stop();
			this.props.onFinish();
		}
	}

	validateTimerDirection()
	{
		const {endTime, startTime, direction} = this.state;
		const timeToElapse = endTime - startTime;
		const expectedDirection = Math.round(timeToElapse / Math.abs(timeToElapse));
		if (expectedDirection !== direction) {
			throw `timer direction is not representative of start/end times. start: ${startTime}, end: ${endTime}`;
		}
	}

	onTimeChange()
	{
		if (this.finished) {
			if (this.props.timer) this.props.timer.stop();
			this.props.onFinish();
		}
		else if (this.props.onTick) { this.props.onTick(this.state.time, this.state.ticker); }
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.paused && !this.props.paused) {
			this.timerStartDt = this.getDateTime();
			this.setState({resumeTime: this.state.time});
		}

		this.finished = this.isFinished();
		this.checkTime(prevState.time);
		this.tryUpdateTime(prevProps.timer.tick);
	}

	checkTime(oldTime)
	{
		const newTime = this.state.time;

		if (oldTime !== newTime) {
			this.onTimeChange();
		}
	}

	tryUpdateTime(oldTickValue)
	{
		if (this.props.paused) {
			return;
		}

		const newTickValue = this.props.timer.tick;
		if (oldTickValue !== newTickValue) 
		{
			// temporary solution for bypassing bug in 'react-timer-hoc'
			// see: https://github.com/troch/react-timer-hoc/issues/4	
			if (newTickValue % 2 === 1)
			{
				this.updateTime();
			}
		}
	}

	checkTimeDifference(currentDateTime){
		const {showDebug}=this.props;

        const {resumeTime, direction} = this.state;
        let elapsedDateTime = getElapsedDateTime(this.timerStartDt, currentDateTime);
        let newTime = resumeTime + (direction * elapsedDateTime);
        
        const timeDifference = this.lastDateTime ? currentDateTime - this.lastDateTime:0;


        if (timeDifference<0 || (timeDifference>60*1000 && !this.hasLeftExam(currentDateTime, timeDifference))){
			
			const previousTimerStartDt=parseInt(this.timerStartDt);
			const description =
                timeDifference < 0
                    ? "System clock moved back so moving exam start date back"
                    : "System clock moved forward so moving exam start date forward";

			if (showDebug){
				//console.clear();
				console.log('Elapsed Time', elapsedDateTime, displayTime(elapsedDateTime));
				console.log('New Time', newTime, displayTime(newTime));
				console.log('Start Time', this.timerStartDt, displayDateTime(this.timerStartDt));
				console.log('Current Time', currentDateTime, displayDateTime(currentDateTime));
				console.log('Last Time', this.lastDateTime, displayDateTime(this.lastDateTime));
				console.log('Time Difference', timeDifference, displayTime(Math.abs(timeDifference)));
			}
            
            this.timerStartDt+=timeDifference;
            let elapsedDateTime = getElapsedDateTime(this.timerStartDt, currentDateTime);
            let newTime = resumeTime + (direction * elapsedDateTime);

			if (showDebug){
				console.log(description);
				console.log('New Start Time', this.timerStartDt, displayDateTime(this.timerStartDt));
				console.log('New Elapsed Time', elapsedDateTime, displayTime(elapsedDateTime));
				console.log('New Time', elapsedDateTime, displayTime(newTime));     
			}
			
			const log = {
				lastDateTime: this.lastDateTime,
				currentDateTime,
				timeDifference,
				previousTimerStartDt,
				newTimerStartDt: this.timerStartDt,
				description
			};
			if (showDebug){
				console.log('');
				console.log('Log', log);
			}

			activityLogger.log(ACTIVITIES.CHANGE_SYSTEM_TIME, log);
        }
        else if (showDebug){
            this.debugTimerCounter++;

            if (this.debugTimerCounter % 60 === 0) {
                console.log('');
                console.log('Current Time', currentDateTime, displayDateTime(currentDateTime));
                console.log('New Elapsed Time', elapsedDateTime, displayTime(elapsedDateTime));
                console.log('New Time', newTime, displayTime(newTime));
				console.log('Time Difference', timeDifference, displayTime(Math.abs(timeDifference)));
            }
        }

        this.lastDateTime = currentDateTime;
    }

	hasLeftExam(currentDateTime, timeDifference) {
		const { showDebug } = this.props;
		if (showDebug){
			console.log('debugTimerCounter', this.debugTimerCounter);
			console.log(
                "Checking to see if left exam for time difference:",
                timeDifference,
                displayTime(Math.abs(timeDifference)),
                displayDateTime(currentDateTime)
            );	
		}
        const { leaveExam, resumeExam } = this.props;

        if (!leaveExam) {
            if (showDebug) {
                console.log("Has not left app");
            }
            return false;
        } else if (!resumeExam || leaveExam > resumeExam) {
            if (showDebug) {
                console.log("Has left exam but has not yet resumed");
            }
            return true;
        }

        const diff = currentDateTime - resumeExam;
        if (showDebug) {
            console.log(
                "Has resumed exam and diff from current time is ",
                diff
            );
        }
        return diff < 1000;
    }

	updateTime()
	{
		const {time, resumeTime, direction} = this.state;
		const currentDateTime = this.getDateTime();

		this.checkTimeDifference(currentDateTime);

		const elapsedDateTime = getElapsedDateTime(this.timerStartDt, currentDateTime);
		const newTime = resumeTime + (direction * elapsedDateTime);
		const ticker = this.lastDateTime ? getElapsedDateTime(this.lastDateTime, currentDateTime):getElapsedDateTime(this.timerStartDt, currentDateTime);
		// console.log('Ticker', ticker);
		// console.log({
		// 	resumeTime,
		// 	timerStartDt: this.timerStartDt,
		// 	currentDt: currentDateTime,
		// 	elapsed: elapsedDateTime,
		// 	oldTime: time,
		// 	newTime
		// });
		this.lastDateTime = parseInt(currentDateTime, 10);

		this.setState({time: newTime, ticker});
	}

	getDateTime()
	{
		return Date.now();
	}

	render()
	{
		if (this.finished) { return null; }

		const uiProps = {
			time: this.state.time, 
			endTime: this.state.endTime,
			startTime: this.state.startTime
		}

		return React.cloneElement(this.props.children, uiProps);
	}

	isFinished()
	{
		const {direction, time, endTime} = this.state;
		return direction === 1 ? (time >= endTime) : (time <= endTime);
	}
}

Timer.propTypes = {
	children: PropTypes.node.isRequired,
	resumeTime: PropTypes.number,							// in ms
	startTime: PropTypes.number.isRequired,		// in ms
	endTime: PropTypes.number.isRequired, 		// in ms
	tickTime: PropTypes.number.isRequired, 		// in ms
	onFinish: PropTypes.func.isRequired,
	direction: PropTypes.oneOf([-1, 1]).isRequired,
	onTick: PropTypes.func,
	paused: PropTypes.bool,

	// timer hoc props
	timer: PropTypes.object.isRequired
}

Timer.defaultProps = {
	direction: -1, // 1 for stopwatch
	startTime: 10000,
	endTime: 0,
	tickTime: 1000
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);

    return {
        leaveExam: getLeaveExam(examData),
        resumeExam: getResumeExam(examData),
		showDebug: false,
    };
};

Timer = connect(mapStoreToProps)(Timer);


// Timer (connected to reactTimer)
// -------------------------------------------------------
// the delay will change based on the incoming 'tickTime' prop)
Timer = reactTimer(1000)(Timer); 


// Export
// -------------------------------------------------------
export {Timer}