
class InactivityManager
{
	constructor()
	{
		this.listener = null;
	}

	notifyInactivity()
	{
		if (this.listener) {
			this.listener();
		}
	}

	subscribe(listener)
	{
		if (this.listener) {
			throw "Trying to overwrite inactivity listener. Are you trying to subscribe in multiple places?";
		}

		this.listener = listener;
	}

	unsubscribe()
	{
		this.listener = null;
	}
}



const inactivityManager = new InactivityManager();

export {inactivityManager}