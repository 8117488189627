
// npm
import * as Immutable from 'immutable'

// redux
import * as ACTION_TYPES from './action-types'


const CREDENTIALS_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.SET_USERNAME:
			return state.set('u', action.value);
		case ACTION_TYPES.SET_PASSWORD:
			return state.set('p', action.value);
		case ACTION_TYPES.CLEAR:
			return Immutable.Map();
		case ACTION_TYPES.CLEAR_PASSWORD:
			return state.delete('p');
		default:
			return state;
	}
}


export {CREDENTIALS_REDUCER}