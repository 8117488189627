// npm
import React, { Component } from 'react';
import {connect} from 'react-redux'

import { AppPageModifier } from "components/pages/app-page-modifier";
import { LoadingSpinner } from "components/presentation/loading-spinner";

// redux
import * as ACTIONS from 'components/pages/login/form/actions'
import {getClientSessionData} from 'redux/reducers/session/selectors'
import {getOrgId} from 'redux/reducers/session/client/selectors'
import {getSessionData} from 'redux/reducers/selectors'
import {getMessagesData} from 'redux/reducers/selectors'
import {getMessageById} from 'redux/reducers/messages/complex-selectors'

// utils
import {authenticationApi} from 'libs/api/interface/api-authentication'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'
import { setError } from './actions';
import { check } from '@xams-utils/check-types';


// AuthenicateUser (not connected to store)
// --------------------------------------------------

const getScreenResolutionData = () =>
{
	const {screen, devicePixelRatio} = window;

	const cssWidth = screen.width;
	const cssHeight = screen.height;

	const screenWidth = cssWidth * devicePixelRatio; // see https://coderwall.com/p/ygcyha/how-to-get-real-mobile-css-resolutions-for-responsive-design
	const screenHeight = cssHeight * devicePixelRatio;

	return {
		css: {width: cssWidth, height: cssHeight},
		screen: {width: screenWidth, height: screenHeight},
		pixelRatio: devicePixelRatio
	}
}

const getScreenResolutionString = () =>
{
	const {css, screen, pixelRatio} = getScreenResolutionData();

	const cssResolution = `${css.width}x${css.height}`;
	const actualResolution = `${screen.width}x${screen.height}`;

	return `[css=${cssResolution}] [screen=${actualResolution}] [pixelRatio=${pixelRatio}]`;
}

const getErrorObj=(response)=>{
	try{
		if (response && check.nonEmptyString(response.response)){
			const obj=JSON.parse(response.responseText);
			if (check.nonEmptyObject(obj)){
				return obj;
			}
		}
		return {}
	}
	catch(e){
		return {}
	}
}

class AuthenicateUser extends React.Component
{
	componentDidMount()
	{
		const payload = {
			username: this.props.userInfo1,
			password: this.props.userInfo2,
			orgId: this.props.orgId,
			screenRes: getScreenResolutionString(),
			userAgent: navigator.userAgent,
			noNewSession: true,
			redirectedViaProctorio: true
		}

		const onSuccess = (response) => {
			// 1. Parse response
			const parsedResponse = JSON.parse(response);

			// 2. Extract important properties
			const {access_token, refresh_token} = parsedResponse;
			const {UserLanguageID:userLanguageId, UserID:userId} = parsedResponse;
			const {UserGUID:userGuid, UserFirstName:userFirstName} = parsedResponse;
			const {UserLastName:userLastName} = parsedResponse;
			const {DOB:dateOfBirth} = parsedResponse;
			const {Admin:admin} = parsedResponse;
			const {NINumber:learnerIdentifier} = parsedResponse;

			// 3. Save tokens
			this.props.saveTokens(access_token, refresh_token);

			// 4. Save user data
			this.props.createUserSession(userId, userGuid, userFirstName, userLastName, userLanguageId, dateOfBirth, learnerIdentifier);

			// 5. Final touches
			this.props.onSuccess();
		}

		const onFail = (response) => {
			this.props.setError({name:"Authenticate User", error: getErrorObj(response), payload})
			this.props.onFail();
		}

        authenticationApi.requestAuthentication(payload)
        .then(onSuccess, onFail);
	}

    render() { 
        const appBarProps = {
            title: "Authenticating User",
            loadingTitle: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="authenicating-user"
                    appBarProps={appBarProps}
                    loading
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => ({
	// u: getUsername(getCredentialData(getLoginData(store))),
	// p: getPassword(getCredentialData(getLoginData(store))),
	orgId: getOrgId(getClientSessionData(getSessionData(store))),
	wrongPasswordText: getMessageById(getMessagesData(store))(MESSAGE_IDS.LOGIN.WRONG_PASSWORD),
	invalidLoginTokenText: getMessageById(getMessagesData(store))(MESSAGE_IDS.LOGIN.INVALID_LOGIN_TOKEN),
});

const mapDispatchToProps = (dispatch) => ({
	saveTokens: (access, refresh) => {
		dispatch(ACTIONS.saveTokens({access, refresh}));
	},
	createUserSession: (id, guid, firstName, lastName, languageId, dateOfBirth, admin, learnerIdentifier) => {
		const data = {id, guid, firstName, lastName, languageId, dateOfBirth, admin, learnerIdentifier};
		dispatch(ACTIONS.createUserSession(data));
	},
	clearFormData: () => {
		dispatch(ACTIONS.clearCredentials());
		dispatch(ACTIONS.clearLoginError());
	},
	clearPassword: () => {
		dispatch(ACTIONS.clearPassword());
	},
	setLoginError: (msg) => {
		dispatch(ACTIONS.setLoginError(msg));
	},
	setError: (value) => {
		dispatch(setError(value))
	}
})

AuthenicateUser = connect(mapStoreToProps, mapDispatchToProps)(AuthenicateUser);


export {AuthenicateUser}