// npm
import React from "react";
import PropTypes from "prop-types";
import check from "check-types";

// material-ui
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

// react
import { MaterialText } from "components/presentation/material-text";
import { DisableAnsweringContext } from "components/pages/exam/disabled-context";
import { isEmptyRow } from "./table-helper";

import { MultiLineTextEditor } from "components/presentation/text_editors/multi_line";
import { SingleLineTextEditor } from "components/presentation/text_editors/single_line";

// utils
import { keyListener, KEYS } from "utils/key-listener";

const disableArrowKeyListeners = () => {
    keyListener.disable(KEYS.LEFT_ARROW);
    keyListener.disable(KEYS.RIGHT_ARROW);
};

const enableArrowKeyListeners = () => {
    keyListener.enable(KEYS.LEFT_ARROW);
    keyListener.enable(KEYS.RIGHT_ARROW);
};

class TableAnswerForm extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table>
                    {this.Headings}
                    {this.Content}
                </Table>
                {this.TextBox}
            </React.Fragment>
        );
    }

    get Headings() {
        if (isEmptyRow(this.props.formData.rows[0])) {
            return null;
        }

        const headingData = this.props.formData.rows[0];
        const cells = headingData.values.map((value) => {
            const _value = check.assigned(value) ? value : " ";
            return this.getTextCell(_value, true);
        });

        return <TableHead>{this.createRow(cells)}</TableHead>;
    }

    get Content() {
        const {
            value,
            formData: { rows },
        } = this.props;

        const contentRows = isEmptyRow(this.props.formData.rows[0])
            ? rows
            : rows.slice(1);

        const getCells = (rowId, cellValues) => {
            let inputCellNumber = 0;
            const rowValues = value.find((x) => x.rowId === rowId).values;
            return cellValues.map((cellValue) => {
                if (!!cellValue) {
                    return this.getTextCell(cellValue);
                }
                const index = inputCellNumber++;
                return this.getInputCell(rowValues[index], ({ target }) => {
                    this.props.onChange({ rowId, index, value: target.value });
                });
            });
        };

        const tableRows = contentRows.map(({ id, values }) => {
            return this.createRow(getCells(id, values));
        });

        return <TableBody>{tableRows}</TableBody>;
    }

    get TextBox() {
        const {
            classes: { textBox },
            value,
            formData: { answerBox },
        } = this.props;

        if (!answerBox) return null;

        const lastRow = value.length > 0 ? value[value.length - 1] : null;

        const answerBoxValue =
            lastRow && check.assigned(lastRow.answerBox)
                ? lastRow.answerBox
                : null;

        const { size, label } = answerBox;

        if (size === 1) {
            return this.getSingleLineEditor(label, answerBoxValue);
        }
        else {
            return this.getMultiLineEditor(label, answerBoxValue, size);
        }

        // const textProps = {
        //     id: "extra-text",
        //     label: label || "",
        //     margin: "normal",
        //     multiline: true,
        //     rows: size,
        //     variant: "outlined",
        //     fullWidth: true,
        //     value: answerBoxValue,
        //     InputLabelProps: {
        //         shrink: true,
        //     },
        //     onChange: ({ target }) => {
        //         this.props.onChange({ answerBox: true, value: target.value });
        //     },
        //     onFocus: disableArrowKeyListeners,
        //     onBlur: enableArrowKeyListeners,
        // };

        // return (
        //     <div className={textBox}>
        //         <TextField {...textProps} />
        //     </div>
        // );
    }

    getSingleLineEditor(label, value){
        const {classes}=this.props;
        const props = {
            value,
            disabled: this.context,
            onChange: ({ target }) => {
                this.props.onChange({ answerBox: true, value: target.value });
            },
            InputProps: {
                className: classes.inputText,
            }
        };

        return (
            <div className={classes.textBox}>
                {<MaterialText>{label}</MaterialText>}
                <SingleLineTextEditor
                    {...props}
                />
            </div>
        );        
    }

    getMultiLineEditor(label, value, size){
        const {classes}=this.props;
        const props = {
            lineCount: size,
            value,
            disabled: this.context,
            onChange: ({ target }) => {
                this.props.onChange({ answerBox: true, value: target.value });
            },
            InputProps: {
                className: classes.inputText,
            }
        };

        return (
            <div className={classes.textBox}>
                {<MaterialText>{label}</MaterialText>}
                <MultiLineTextEditor
                    {...props}
                />
            </div>
        );        
    }

    getContentRows(rows) {
        const lastRow = rows.slice(rows.length - 1);
        debugger;
        return lastRow.answerBox
            ? rows.slice(1, rows.length - 1)
            : rows.slice(1);
    }

    getTextCell(text, isHeading) {
        if (isHeading && !text) {
            //throw "Table Q has an empty heading";
            return null;
        }
        const variant = isHeading ? "h6" : "body1";

        return this.createCell(
            <MaterialText className={this.props.classes.text} variant={variant}>
                {text}
            </MaterialText>
        );
    }

    getInputCell(value, onChange) {
        const disabled = this.context;

        let inputProps = {
            spellCheck: false,
            onPaste: (e) => {
                e.preventDefault();
            },
            onDrop: (e) => {
                e.preventDefault();
            },
            onFocus: disableArrowKeyListeners,
            onBlur: enableArrowKeyListeners,
        };

        const { classes } = this.props;

        const props = {
            className: classes.inputText,
            classes: { underline: classes.inputUnderline },
            disabled,
            onChange,
            value,
            inputProps,
        };

        return this.createCell(<Input {...props} />);
    }

    createCell(child) {
        const {
            classes: { cell },
        } = this.props;
        return <TableCell className={cell}>{child}</TableCell>;
    }

    createRow(children) {
        const {
            classes: { row },
        } = this.props;
        return <TableRow className={row}>{children}</TableRow>;
    }
}

TableAnswerForm.contextType = DisableAnsweringContext;

TableAnswerForm.propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                values: PropTypes.array.isRequired,
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

const styles = ({ palette, spacing }) => {
    const borderStyle = `1.3px solid ${palette.background.contrastText} !important`;

    return {
        text: { color: palette.background.contrastText },
        inputText: { color: palette.primary.main },
        cell: { border: borderStyle },
        row: { border: borderStyle },
        inputUnderline: {
            "&:before": {
                borderBottom: `1px solid ${palette.background.contrastText} !important`,
            },
            "&:after": {
                borderBottom: `1px solid ${palette.background.contrastText} !important`,
            },
        },
        textBox: {
            width: "100%",
            marginTop: spacing.unit * 4,
            marginBottom: spacing.unit * 2,
        },
    };
};

TableAnswerForm = withStyles(styles)(TableAnswerForm);

export { TableAnswerForm };
