import React, { Component } from "react";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";

import { getGuid } from "redux/reducers/exam/selectors";
import { getExamData, getSchedulesData } from "redux/reducers/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import {
    getProctoringMode,
    getProctoringType,
    getIntegrityAdvocateAppId,
} from "redux/reducers/settings/client/selectors";
import { getSessionData } from "redux/reducers/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import {
    getUserId,
    getFirstName,
    getLastName,
    getEmail,
} from "redux/reducers/session/user/selectors";
import {
    getProctorProvider,
    getObjectId,
    getQualificationId,
} from "redux/reducers/schedules/schedule/selectors";

import { PROCTORING_TYPES } from "constants/proctoring";

import { Align } from "components/layout/align";
import { MaterialText } from "components/presentation/material-text";
import { AppPageModifier } from "components/pages/app-page-modifier";
import { LoadingSpinner } from "components/presentation/loading-spinner";

class OpenIntegrityAdvocate extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleIntegrityAdvocateReady =
            this.handleIntegrityAdvocateReady.bind(this);
        this.handleScriptLoaded = this.handleScriptLoaded.bind(this);

        this.state = { error: null, loaded: false };
    }

    componentDidMount() {
        if (this.isIntegrityAdvocate()) {
            this.addIntegrityAdvocateScript();

            document.addEventListener(
                "IA_Ready",
                this.handleIntegrityAdvocateReady
            );
        } else {
            const { onSuccess } = this.props;
            console.log("Not Integrity Advocate");
            onSuccess();
        }
    }

    componentWillUnmount() {
        if (this.isIntegrityAdvocate()) {
            document.removeEventListener(
                "IA_Ready",
                this.handleIntegrityAdvocateReady
            );

            if (this.script) {
                this.script.removeEventListener(
                    "load",
                    this.handleScriptLoaded
                );
                this.script.removeEventListener(
                    "error",
                    this.handleScriptLoaded
                );
            }
        }
    }

    isIntegrityAdvocate() {
        const { proctoringProvider, integrityAdvocateAppId } = this.props;

        return (
            proctoringProvider === PROCTORING_TYPES.INTEGRITY_ADVOCATE &&
            check.nonEmptyString(integrityAdvocateAppId)
        );
    }

    addIntegrityAdvocateScript() {
        const error = this.checkParmeters();
        if (check.nonEmptyObject(error)) {
            this.setState({ error });
            return;
        }

        const {
            firstName,
            lastName,
            userId,
            email,
            objectId,
            qualificationId,
            integrityAdvocateAppId,
        } = this.props;
        // const appId = 'ff4b26d6-7bba-4c62-adf2-84d1109d354f';

        let url =
            "https://ca.integrityadvocateserver.com/participants/integrity";
        url += `?appid=${integrityAdvocateAppId}`;
        url += `&participantidentifier=${userId}`;
        url += `&courseid=${qualificationId}`;
        url += `&participantfirstname=${firstName}`;
        url += `&participantlastname=${lastName}`;
        url += `&participantemail=${email}`;
        url += `&activityid=${objectId}`;

        this.script = document.createElement("script");
        this.script.type = "application/javascript";
        this.script.src = url;
        this.script.async = true;

        document.body.appendChild(this.script);

        this.script.addEventListener("load", this.handleScriptLoaded);
        this.script.addEventListener("error", this.handleScriptLoaded);
    }

    checkParmeters() {
        const {
            firstName,
            lastName,
            userId,
            email,
            objectId,
            qualificationId,
        } = this.props;

        if (!check.nonEmptyString(firstName)) {
            return { text: "First name is blank" };
        } else if (!check.nonEmptyString(lastName)) {
            return { text: "Last name is blank" };
        } else if (!check.integer(userId)) {
            return { text: "User ID is blank" };
        } else if (!check.nonEmptyString(email)) {
            return { text: "Email is blank" };
        } else if (
            !check.integer(qualificationId) &&
            !check.nonEmptyString(qualificationId)
        ) {
            return { text: "qualification ID (course ID) is blank" };
        } else if (
            !check.integer(objectId) &&
            !check.nonEmptyString(objectId)
        ) {
            return { text: "activity ID (object ID) is blank" };
        }

        return null;
    }

    handleIntegrityAdvocateReady(e) {
        const { onSuccess } = this.props;
        console.log("handleIntegrityAdvocateReady", e);
        onSuccess();
    }

    handleScriptLoaded(e) {
        console.log("handleScriptLoaded", e);
        if (e.type === "load") {
            console.log("Integrity Advocate Script Loaded", e);
            this.setState({ loaded: true });
        } else {
            console.log("Integrity Advocate Script Error", e);
            this.setState({ error: e });
        }
    }

    displayError(_error) {
        const error = check.nonEmptyObject(_error)
            ? _error
            : { text: "Error loading script" };

        const a = [];
        for (const [key, value] of Object.entries(error)) {
            a.push([key, value]);
        }

        return (
            <div>
                {a.map((item) => (
                    <div>
                        <MaterialText>
                            <b>{item[0]}</b>: {item[1]}
                        </MaterialText>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        if (!this.isIntegrityAdvocate()) {
            return null;
        }

        const { error } = this.state;
        const title = "Integrity Advocate";

        if (error) {
            return (
                <React.Fragment>
                    <AppPageModifier
                        id="integrity-advocate-error"
                        appBarProps={{
                            loading: false,
                            title: `${title} Error`,
                            logout: true,
                        }}
                    />
                    <Align top>
                        <div style={{ paddingTop: "20px" }}>
                            <div>
                                <MaterialText variant="h6">
                                    {title} Error
                                </MaterialText>
                            </div>
                            <div>{this.displayError(error)}</div>
                        </div>
                    </Align>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <AppPageModifier
                    id="integrity-advocate-loading"
                    appBarProps={{
                        loading: true,
                        title: `Starting ${title}`,
                        logout: false,
                        loadingTitle: true,
                    }}
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const examGuid = getGuid(examData);

    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    const schedulesData = getSchedulesData(store);
    const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
    const proctoringType = getProctoringType(clientSettingsData);
    const proctoringMode = getProctoringMode(clientSettingsData);
    const proctoringProvider = getProctorProvider(scheduleData);
    const objectId = getObjectId(scheduleData);
    const qualificationId = getQualificationId(scheduleData);

    const userSessionData = getUserSessionData(getSessionData(store));
    const firstName = getFirstName(userSessionData);
    const lastName = getLastName(userSessionData);
    const userId = getUserId(userSessionData);
    const email = getEmail(userSessionData);

    const integrityAdvocateAppId =
        getIntegrityAdvocateAppId(clientSettingsData);

    return {
        proctoringMode,
        proctoringType,
        proctoringProvider,
        firstName,
        lastName,
        userId,
        email,
        examGuid,
        objectId,
        qualificationId,
        integrityAdvocateAppId,
    };
};

OpenIntegrityAdvocate = connect(mapStoreToProps)(OpenIntegrityAdvocate);

export { OpenIntegrityAdvocate };
