
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'


let SingleLineTextEditor = (props) =>
{
	const {canPaste, InputProps={}, ...additionalProps} = props;
	const onPaste = canPaste
	? null
	: (e) => {
		  e.preventDefault();
	  };
	const editorProps = {
		margin: "normal",
		variant: "filled",
		fullWidth: true,
		InputProps: {
			spellCheck: false,
			endAdornment: props.suffix ? <InputAdornment position="start">{props.suffix}</InputAdornment> : null,
			onPaste,
			onDrop: (e) => { e.preventDefault(); },
			
			...InputProps
		}
	}

	return <TextField {...editorProps} {...additionalProps}/>;
}

SingleLineTextEditor.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
}

SingleLineTextEditor.defaultProps = {
	disabled: false,
	value: ""
}


// EXPORT
// ------------------------------------------------------------
export {SingleLineTextEditor}