import React, { Component } from "react";

import { FormCell } from "./form-cell";
import { getStyle, rowStateHidden } from "./form-helper";
import { check } from "@xams-utils/check-types";

const isStringBoolean = (string) => {
    if (check.nonEmptyString(string)) {
        return string === "true" || string === "false";
    }

    return false;
};

class FormRow extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.timer = null;

        this.handleFinishedTimer = this.handleFinishedTimer.bind(this);
    }

    componentDidUpdate() {
        const { finished, enabled, rowFamily, rowStates, rowState } =
            this.props;

        const isFinished =
            check.nonEmptyString(finished) && finished === "true";

        if (
            isFinished &&
            enabled &&
            !rowStateHidden(rowState, rowStates, rowFamily)
        ) {
            this.setTimer = setTimeout(this.handleFinishedTimer, 1000);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleFinishedTimer() {
        const { onClick } = this.props;
        onClick("finished", true);
    }

    getRowVisible() {}

    render() {
        const {
            cells,
            onChange,
            onClick,
            onAction,
            formAction,
            enabled,
            formValue,
            fontObj,
            rowFamily,
            rowFamilyVisible,
            rowStates,
            rowState,
        } = this.props;
        const styleObj = getStyle(this.props, { display: "flex" });

        // console.log('Render');
        // console.log('formAction', formAction)
        // console.log('rowFamily', rowFamily)
        // console.log('rowFamilyVisible', rowFamilyVisible);
        // console.log('');

        if (isStringBoolean(rowFamilyVisible)) {
            let rowVisible = rowFamilyVisible === "true";

            if (check.nonEmptyString(formAction)) {
                //debugger;
                const a = formAction.split(",");

                for (let i = 0; i < a.length; i++) {
                    const b = a[i].split("=");

                    if (b[1] === "variable" && b[0] === rowFamily) {
                        //debugger;
                        const c = b[2].split(":");

                        if (c[0] === "visible" && isStringBoolean(c[1])) {
                            rowVisible = c[1] === "true";
                            break;
                        }
                    }
                }
            }

            if (!rowVisible) {
                return null;
            }
        }

        if (rowStateHidden(rowState, rowStates, rowFamily)) {
            return null;
        }

        return (
            <div style={styleObj}>
                {cells.map((cell, index) => {
                    const props = {
                        key: index,
                        onChange,
                        onAction,
                        formAction,
                        onClick,
                        enabled,
                        formValue,
                        fontObj,
                        ...cell,
                    };

                    return <FormCell {...props} />;
                })}
            </div>
        );
    }
}

export { FormRow };
