// redux
import { ROWS_REDUCER } from "./rows/reducer";
import { SET_EXAM_DATA } from "redux/reducers/exam/action-types";

// custom
import { getProp } from "custom/object-helper";

const handleSetExamData = (state, action) => {
    const showAnswerInputField =
        !!action.data["@ShowAnswerInputField"];
    state = state.set("showAnswerInputField", showAnswerInputField);

    const noOfAnswerLines = showAnswerInputField
        ? action.data["@NoOfAnswerLines"]
            ? parseInt(action.data["@NoOfAnswerLines"])
            : 0
        : 0;
    state = state.set("noOfAnswerLines", noOfAnswerLines);

    const answerLabel = showAnswerInputField
        ? action.data["@AnswerLabel"]
            ? action.data["@AnswerLabel"]
            : ''
        : '';
    state = state.set("answerLabel", answerLabel);

    const columnCount = action.data["@NumberOfColumns"];
    state = state.set("resumedAnswer", action.data["ANSWER"]);

    const rowsData = getProp(action, "data.OPTIONS.OPTION");
    const rowsAction = { ...action, data: rowsData, columnCount };
    return state.set("rows", ROWS_REDUCER(undefined, rowsAction));
};

// Note: state should always be initialized at this point (via QUESTION_REDUCER)
const TABLE_QUESTION_REDUCER = (state, action) => {
    switch (action.type) {
        case SET_EXAM_DATA:
            return handleSetExamData(state, action);
        default:
            return state;
    }
};

export { TABLE_QUESTION_REDUCER };
