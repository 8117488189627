
// redux (action-types)
import {SET_CURRENT_QUESTION_ID} from 'redux/reducers/exam/content/action-types'


const setCurrentQuestionId = (value) => ({
	type: SET_CURRENT_QUESTION_ID,
	value
});


export {setCurrentQuestionId}