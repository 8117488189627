
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {GeneralMessageFetcher} from './general/fetcher'
import {GeneralMessageChecker} from './general/checker'
import {SessionMessageFetcher} from './session/fetcher'
import {SessionMessageChecker} from './session/checker'


class MessageLoader extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {alreadyLoaded: null};

		this.updateLoadedState = (loaded) => {
			if (this.state.alreadyLoaded !== null) { throw "[messageloader]"; }
			this.setState({alreadyLoaded: loaded});
		}
	}

	componentDidUpdate(previousProps, previousState)
	{
		if (this.state.alreadyLoaded) { this.props.onLoad(); }
	}

	render()
	{
		switch (this.state.alreadyLoaded)
		{
			case null:
				return this.MessageChecker;
			case false:
				return this.MessageFetcher;
			default:
				return null;
		}
	}

	get MessageChecker()
	{
		return this.props.session ?
			<SessionMessageChecker onFinish={this.updateLoadedState}/> :
			<GeneralMessageChecker onFinish={this.updateLoadedState}/>;
	}

	get MessageFetcher()
	{
		const messageFetcherProps = {
			onSuccess: this.props.onLoad,
			onFail: this.props.onError
		}

		return this.props.session ?
			<SessionMessageFetcher {...messageFetcherProps}/> :
			<GeneralMessageFetcher {...messageFetcherProps}/>;
	}
}

MessageLoader.propTypes = {
	session: PropTypes.bool,
	onLoad: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
}


export {MessageLoader}