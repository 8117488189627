
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'


// MobileActionButton (not connected to styles)
// ----------------------------------------------------------------------

let MobileActionButton = (props) =>
{
	const {Icon, children, classes, color, ...buttonProps} = props;

	const colorClass = color === 'primary' ? classes.primaryBackground : 
										 color === 'secondary' ? classes.secondaryBackground : "";

	const iconButtonProps = {
		className: `${classes.button} ${colorClass}`,
		classes: {
			disabled: classes.buttonDisabled
		},
		...buttonProps
	}

	return (
		<IconButton {...iconButtonProps}>
			<Icon/>
		</IconButton>
	)
}

MobileActionButton.propTypes = {
	Icon: PropTypes.oneOfType([
		PropTypes.instanceOf(React.Component),
		PropTypes.func
	]).isRequired,
	classes: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired
	// remaining props are spread to IconButton component
}

MobileActionButton.defaultProps = {
	color: 'primary'
}


// MobileActionButton (not connected to styles)
// ----------------------------------------------------------------------

const styles = ({palette, spacing}) => ({
	button: {
		marginLeft: spacing.unit,
		marginRight: spacing.unit,
		padding: spacing.unit / 2
	},
	primaryBackground: {
		backgroundColor: palette.primary.main + "!important",
		color: palette.primary.contrastText + "!important"
	},
	secondaryBackground: {
		backgroundColor: palette.secondary.main + "!important",
		color: palette.secondary.contrastText + "!important"
	},
	buttonDisabled: {
		backgroundColor: palette.background.light + "!important",
		color: palette.background.contrastText + "!important"
	}
});

MobileActionButton = withStyles(styles)(MobileActionButton);


// Export
// ----------------------------------------------------------------------
export {MobileActionButton}