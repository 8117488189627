
const getName = (examData) => examData.get('name');
const getGuid = (examData) => examData.get('guid');
const getScheduleGuid = (examData) => examData.get('scheduleGuid');
const getExamPassword = (examData) => examData.get('p');
const isExamPaused = (examData) => examData.get('paused');
const getConfirmExamResume = (examData) => examData.get('confirmResume');
const isPracticeExam = (examData) => examData.get('practice');
const getPasswordError = (examData) => examData.get('passwordError');
const getFormRunGuid = (examData) => examData.get('formRunGuid');
const isQuestionPanelOpen = (examData) => examData.get('questionPanelOpen');

const shouldShowFeedback = (examData) => examData.get('showFeedbackReport');
const canDownloadFeedback = (examData) => examData.get('canDownloadFeedbackReport')

const getTimeData = (examData) => examData.get('time');
const getContentData = (examData) => examData.get('content');
const getMessageData = (examData) => examData.get('messages');
const getResultData = (examData) => examData.get('result');

const getShowScenario = (examData) => examData.get('showScenario');

const getLeaveExam = (examData) => examData.get('leaveExam');
const getResumeExam = (examData) => examData.get('resumeExam');

const getShowExamInFullScreen =  (examData) => examData.get('showExamInFullScreen');
const getInitialFullScreen = (examData) => examData.get('initialFullScreen');


export {
	getName,
	getGuid,
	getScheduleGuid, 
	getExamPassword,
	getPasswordError,
	getFormRunGuid,
	isExamPaused,
	getConfirmExamResume,
	isPracticeExam,
	getTimeData,
	getContentData,
	getMessageData,
	isQuestionPanelOpen,
	getResultData,
	shouldShowFeedback,
	canDownloadFeedback,
	getShowScenario,
	getLeaveExam,
	getResumeExam,
	getShowExamInFullScreen,
	getInitialFullScreen
}