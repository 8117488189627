import { getProp } from "custom/object-helper";
import { OPTIONS_REDUCER } from "../radios/options/reducer";
import { SET_EXAM_DATA } from "redux/reducers/exam/action-types";

// Note: state should always be initialized at this point
// (via QUESTION_REDUCER)
const RADIOS_TEXT_QUESTION_REDUCER = (state, action) => {
    switch (action.type) {
        case SET_EXAM_DATA:
            const lines = getProp(action, "data.@NoOfAnswerLines");
            state = state.set("lines", lines);

            const showWordCount= true;
            state = state.set("showWordCount", showWordCount);

            const otherText = getProp(action, "data.OtherText");
            state = state.set("otherText", otherText);

            const optionsData = getProp(action, "data.OPTIONS.OPTION");
            const optionsAction = { ...action, data: optionsData };
            return state.set(
                "options",
                OPTIONS_REDUCER(undefined, optionsAction)
            );
        default:
            return state;
    }
};

export { RADIOS_TEXT_QUESTION_REDUCER };
