
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {CanStartChecker} from './can-start-checker'
import {ScheduleCompletedPopup} from './completed-popup'
import {ScheduleCantStartPopup} from './cant-start-popup'
import {ScheduleCompletionChecker} from './completion-checker'
import {InitializingScheduleView} from './initializing_schedule/view'
import {PasswordRequirementChecker} from './password-requirement-checker'

// machines
import {examInit} from 'machines/exam-init'


const {EVENTS, VALIDATING_SCHEDULE_STATES:STATES} = examInit;


const ValidatingScheduleView = (examGuid) =>
{
	return (
		<StateView>
			{{
				[STATES.INITIALIZING_SCHEDULE_DATA]: () => (
					InitializingScheduleView(examGuid)
				),
				[STATES.CHECKING_SCHEDULE_COMPLETION]: () => (
					<StateControl onComplete={EVENTS.SCHEDULE_COMPLETE} onIncomplete={EVENTS.SCHEDULE_INCOMPLETE}>
						{(props) => <ScheduleCompletionChecker examGuid={examGuid} {...props}/>}
					</StateControl>
				),
				[STATES.SCHEDULE_ALREADY_COMPLETED]: () => (
					<ScheduleCompletedPopup/>
				),
				[STATES.CHECKING_SCHEDULE_CAN_START]: () => (
					<StateControl onSuccess={EVENTS.SCHEDULE_CAN_START} onFail={EVENTS.SCHEDULE_CANT_START} onError={EVENTS.ERROR}>
						{(props) => <CanStartChecker examGuid={examGuid} {...props}/>}
					</StateControl>
				),
				[STATES.SCHEDULE_CANT_START]: () => (
					<ScheduleCantStartPopup examGuid={examGuid}/>
				),
				[STATES.CHECKING_PASSWORD_REQUIREMENT]: () => (
					<StateControl onRequired={EVENTS.PASSWORD_REQUIRED} onNotRequired={EVENTS.PASSWORD_NOT_REQUIRED}>
						{(props) => <PasswordRequirementChecker examGuid={examGuid} {...props}/>}
					</StateControl>
				)
			}}
		</StateView>
	)
}


export {ValidatingScheduleView}