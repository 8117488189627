
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {Switch, Route, withRouter} from 'react-router-dom'

// react
import {ExamPage} from './_exam/page'
import {CoursePage} from './e_learning/course/page'
import {AdminPage} from './admin/admin-page'
import {AdminRedirectIframe} from './login/admin_redirect/iframe'
import {RubricPage} from './rubric/page'
import {SchedulesPage} from './schedules/page'
import {ActivityLogsPage} from './activity_logs/activity-logs'
import {ActivityLogPage} from './activity_logs/activity-log'
import {InactivityDetector} from './inactivity-detector'
import {NetworkRequestLogger} from './network-request-logger'
import {ClientSettingsFetcher} from './client-settings-fetcher'
import {ScheduledBugReportSender} from './scheduled-bug-report-sender'
import {ExamRequestSaverProvider} from 'components/hocs/exam_request_saver/provider'
import {RedirectProctorioMachinePage} from 'components/pages/proctorio/redirect/redirect-machine-page'
import {ProctorioEndPage} from 'components/pages/proctorio/end/end-page'

// utils
import {routingBlocker, BLOCKING_TYPES} from 'utils/routing-blocker'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


class SessionPageRouter extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {settingsFetched: false};

		this.unsubscribe = routingBlocker.subscribe((location, action) => {
			if (action !== "POP") { return; }

			const currentPath = this.props.location.pathname;
			const nextPath = location.pathname;

			if (!currentPath.includes('exam') && nextPath.includes('exam')) {
				const {messages} = this.props;

				return {
					type: BLOCKING_TYPES.BLOCK,
					message: messages[MESSAGE_IDS.EXAM.CANT_NAVIGATE]
				}
			}
		})
	}

	render()
	{
		if (!this.state.settingsFetched) {
			return <ClientSettingsFetcher onSuccess={() => this.setState({settingsFetched: true})}/>;
		}
		
		return (
			<NetworkRequestLogger>
				<InactivityDetector>
					<ScheduledBugReportSender>
						<ExamRequestSaverProvider>
							<Switch location={this.props.location}>
								<Route path="/rubric" component={RubricPage}/>
								<Route path="/schedules" component={SchedulesPage}/>
								<Route path="/logs" component={ActivityLogsPage}/>
								<Route path="/log/:logId" component={ActivityLogPage}/>								
								<Route path="/exam/:examGuid" component={ExamPage}/>
								<Route path="/elearning/:elearningGuid" component={CoursePage}/>
								<Route path="/admin" component={AdminRedirectIframe}/>
								<Route path="/proctoring/:proctorProvider/:proctoringSessionID/:examGuid" exact component={RedirectProctorioMachinePage}/>
								<Route path="/proctorio/end" exact component={ProctorioEndPage}/>								
							</Switch>
						</ExamRequestSaverProvider>
					</ScheduledBugReportSender>
				</InactivityDetector>
			</NetworkRequestLogger>
		)
	}

	componentWillUnmount()
	{
		this.unsubscribe();
	}
}

SessionPageRouter.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
}

SessionPageRouter = withRouter(SessionPageRouter);
SessionPageRouter = withMessages(SessionPageRouter);


export {SessionPageRouter}