
// npm
import React from 'react'
import {check} from '@xams-utils/check-types'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getOrgId} from 'redux/reducers/session/client/selectors'
import {getClientSessionData} from 'redux/reducers/session/selectors'

// utils
import {api} from 'libs/api/api'
import {errorLogger} from 'utils/error-logger'


class ErrorLogger extends React.Component
{
	componentDidCatch(e, info)
	{
		if (api.isEndpointInitialized()) {
			if (check.string(e)) {
				errorLogger.log({message: e, stackTrace: info.componentStack});
			}
			else if (e && e.message && e.stack) {
				errorLogger.log({message: e.message, stackTrace: e.stack});
			}
			else {
				const error = e;
				debugger
			}
		}
	}

	render()
	{
		return this.props.children;
	}
}


export {ErrorLogger}