
// npm
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react (layout)
import {ExamLayout} from '../../../../exam/_layout/layout'
import {Align} from 'components/layout/align'

// react (concrete)
import {IntroTitle} from './intro-title'
import {StartExamButton} from './start-exam-button'
import {InstructionsPanel} from '../../../../exam/running/instructions-panel'
import {ReturnToSchedulesButton} from './return-to-schedules-button'

// redux (selectors)
import {getGuid, getShowExamInFullScreen,} from 'redux/reducers/exam/selectors'
import {getExamData, getSchedulesData} from 'redux/reducers/selectors'
import {getVersion, hasScheduleCommenced,} from 'redux/reducers/schedules/schedule/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'
import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getProctoringMode} from 'redux/reducers/settings/client/selectors'

import { openInFullScreen } from "components/pages/_exam/initialized/running/leave_resume_exam/full_screen/helper"

import { SET_INITIAL_FULL_SCREEN } from "redux/reducers/exam/action-types";


// ExamIntroPage (not connected to store)
// -----------------------------------------------------------------

class ExamIntroPage extends Component {
	constructor(props) {
        super(props);

        this.handleExamStart = this.handleExamStart.bind(this);
        this.handleFullScreenChange = this.handleFullScreenChange.bind(this);
    }

    componentDidMount() {
        const { showExamInFullScreen } = this.props;

        if (showExamInFullScreen) {
            document.addEventListener(
                "fullscreenchange",
                this.handleFullScreenChange
            );
        }
    }

    componentWillUnmount() {
        const { showExamInFullScreen } = this.props;

        if (showExamInFullScreen) {
            document.removeEventListener(
                "fullscreenchange",
                this.handleFullScreenChange
            );
        }
    }

    handleFullScreenChange() {
        const { onExamStart } = this.props;
        if (document.fullscreenElement) {
            onExamStart();
        }
    }

    handleExamStart() {
        const { showExamInFullScreen, onExamStart, setInitialFullScreen } =
            this.props;

        if (showExamInFullScreen) {
            setInitialFullScreen(document.fullscreenElement);
            openInFullScreen(true);
        } else {
            onExamStart();
        }
    }

	render() { 
		const {showReturnButton, resume} = this.props;

		const examLayoutProps = {
			bar: {
				content: (
					<React.Fragment>
						<IntroTitle/>
						{showReturnButton && !resume ? <ReturnToSchedulesButton/> : null}
						<StartExamButton resume={resume} onClick={this.handleExamStart}/>
					</React.Fragment>
				)
			},
			centre: {
				content: (
					<Align top>
						<InstructionsPanel/>
					</Align>
				)
			}
		}
	
		return <ExamLayout {...examLayoutProps}/>;
	}
}

ExamIntroPage.propTypes = {
	onExamStart: PropTypes.func.isRequired,
	showReturnButton: PropTypes.bool.isRequired
}


// ExamIntroPage (connected to store)
// -----------------------------------------------------------------

const mapStoreToProps = (store) =>
{
	const examData = getExamData(store);
	const examGuid = getGuid(examData);

	const schedulesData = getSchedulesData(store);
	const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
	const proctoringMode = getProctoringMode(getClientSettingsData(getSettingsData(store)));
	const showReturnButton = proctoringMode == 1 ? false : !getVersion(scheduleData);

	const showExamInFullScreen = getShowExamInFullScreen(examData);
    const resume = showExamInFullScreen
        ? hasScheduleCommenced(scheduleData)
        : false;

	return {
		showReturnButton,
		showExamInFullScreen,
        resume,		
	}
}

const mapDispatchToProps = (dispatch) => ({
    setInitialFullScreen: (value) => {
        dispatch({
            type: SET_INITIAL_FULL_SCREEN,
            value,
        });
    },
});

ExamIntroPage = connect(mapStoreToProps, mapDispatchToProps)(ExamIntroPage);


// ExamIntroPage (EXPORT)
// -----------------------------------------------------------------
export {ExamIntroPage}