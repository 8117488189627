
// npm
import React from 'react'
import PropTypes from 'prop-types'

// redux (selectors)
import {getLocalAnswer, isDragAndDrop} from 'redux/reducers/exam/content/questions/question/selectors'
import {getOptionsData, getMaxOptions} from 'redux/reducers/exam/content/questions/question/checks/selectors'
import {getOptionId, getOptionText} from 'redux/reducers/exam/content/questions/question/checks/options/option/selectors'
import {getSelectedOptions, getOptionTextById} from 'redux/reducers/exam/content/questions/question/checks/options/selectors'

// react
import {AnswerBox} from './answer-box'
import {ChecksAnswerForm} from '../answer_forms/checks'
import {ChecksDragAndDropAnswerForm} from '../answer_forms/drag_and_drop/checks'


class ChecksAnswerBox extends React.Component
{
	constructor(props)
	{
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => [];

		this.extractResumedAnswer = () => {
			const optionsData = getOptionsData(this.props.data);
			const selectedOptions = getSelectedOptions(optionsData);
			return selectedOptions.map(option => getOptionId(option)).toJS();							// limit use of immutable objects where practical
		}

		this.extractFormData = () => {
			const {data} = this.props;
			const maxOptions = getMaxOptions(data);
			const optionsData = getOptionsData(data);

			return {
				maxOptions,
				options: optionsData.map(optionData => {
					const id = getOptionId(optionData);
					const text = getOptionText(optionData);
					return {id, text};
				}),
				getExcessiveAnswers: () => {
					// Returns the optionIds to remove in order to meet maxOptions limit.
					// 'Checks' answer form can invoke 'onChange' on each of these options.
					// This ensures the form is properly decoupled from any saving logic
					const localAnswer = getLocalAnswer(data);
					return localAnswer.slice(maxOptions);
				}
			}
		}
	}

	setUpTransformationFunctions()
	{
		this.getFormValue = (formAnswer) => {
			const {optionId:answer, checked} = formAnswer;
			return checked ? this.addAnswer(answer) : this.removeAnswer(answer);
		}

		this.toServerAnswer = (localAnswer) => {
			const optionsData = getOptionsData(this.props.data);
			const _getOptionTextById = getOptionTextById(optionsData);

			const localAnswerValues = localAnswer.map(optionId => {
				return _getOptionTextById(optionId);
			});

			return {
				answerText: localAnswerValues.join('|'),
				options: localAnswer.map((optionId, index) => {
					const optionText = localAnswerValues[index];
					return {optionId, text: optionText};
				})
			}
		}
	}

	addAnswer(answer)
	{
		const localAnswer = getLocalAnswer(this.props.data);
		if (localAnswer.indexOf(answer) === -1) { localAnswer.push(answer); }
		return localAnswer;
	}

	removeAnswer(answer)
	{
		var localAnswer = getLocalAnswer(this.props.data);
		localAnswer.splice(localAnswer.indexOf(answer), 1);
		return localAnswer;
	}

	render()
	{
		const dnd = isDragAndDrop(this.props.data);
		const AnswerForm = dnd ? ChecksDragAndDropAnswerForm : ChecksAnswerForm;

		const answerBoxProps = {
			FormComponent: AnswerForm,
			questionData: this.props.data,
			getFormValue: this.getFormValue,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer
		}

		return <AnswerBox {...answerBoxProps}/>;
	}
}

ChecksAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export {ChecksAnswerBox}