
// redux (action types)

import {
	SET_EXAM_TIME_REMAINING,
	SET_READING_TIME_REMAINING as SET_EXAM_READING_TIME_REMAINING
} from 'redux/reducers/exam/time/action-types'

import {
	SET_TIME_REMAINING as SET_SECTION_TIME_REMAINING,
	SET_READING_TIME_REMAINING as SET_SECTION_READING_TIME_REMAINING
} from 'redux/reducers/exam/content/sections/section/action-types'


// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getUserGuid} from 'redux/reducers/session/user/selectors'


// utils
import {inactivityManager} from 'utils/inactivity-manager'


// Constants
// ----------------------------------------------------------------------------
const MAX_INACTIVITY_INTERVAL = 600000; // 10m

const EXEMPT_FROM_ACTIVITY_CHECK = [
	SET_EXAM_TIME_REMAINING,
	SET_EXAM_READING_TIME_REMAINING,
	SET_SECTION_TIME_REMAINING,
	SET_SECTION_READING_TIME_REMAINING
];



// Validation
// ----------------------------------------------------------------------------
const hasUserSession = (storeState) =>
{
	const sessionData = getSessionData(storeState);
	if (!sessionData) { return false; }

	const userSessionData = getUserSessionData(sessionData);
	if (!userSessionData) { return false; }

	const userGuid = getUserGuid(userSessionData);
	if (!userGuid) { return false; }

	return true;
}


// Timeout stuff
// ----------------------------------------------------------------------------
let timeoutId = null;

const tryClearTimeout = () =>
{
	if (timeoutId) {
		clearTimeout(timeoutId);
		timeoutId = null;
	}
}

const createTimeout = () =>
{
	timeoutId = setTimeout(() => {
		inactivityManager.notifyInactivity();
	}, MAX_INACTIVITY_INTERVAL);
}

const resetTimeout = () =>
{
	tryClearTimeout();
	createTimeout();
}


// Middleware
// ----------------------------------------------------------------------------
const shouldResetTimer = (action) => (
	!EXEMPT_FROM_ACTIVITY_CHECK.includes(action.type)
)

const detectInactivity = (store) => (next) => (action) =>
{
	const storeState = store.getState();
	const containsUserSession = hasUserSession(storeState);

	if (containsUserSession) {
		if (!timeoutId) {
			createTimeout();
		}
		else if (shouldResetTimer(action)) {
			resetTimeout();
		}
	}
	else {
		tryClearTimeout();
	}

	return next(action);
}


export {detectInactivity}