const CONSTANTS = {
    SET: "set",
    RESET: "resit",
    INFORMATION: 'info',
    SUBMITTING: 'submitting',
    SUCCESS: "success",
    FAILED: "failed",
    RESULT_CODE : {
        SUCCESS: 0,
        NO_EMAIL: 1,
        INVALID_LOGON_ID: 2,
        INVALID_TOKEN: 3,
    }
};

export {CONSTANTS}