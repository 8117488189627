
// npm
import check from 'check-types'
import {milliseconds, seconds} from 'time-convert'


const getMarks = (sectionData) => sectionData.get('marks');
const getSectionId = (sectionData) => sectionData.get('id');
const getSectionName = (sectionData) => sectionData.get('name');
const getSectionText = (sectionData) => sectionData.get('text');
const getScenarioText = (sectionData) => sectionData.get('scenarioText');
const getResourceUrl = (sectionData) => sectionData.get('resourceUrl');
const requiresWorkings = (sectionData) => sectionData.get('requiresWorkings');
const requiresCalculator = (sectionData) => sectionData.get('requiresCalculator');

// one way stuff
const isOneWay = (sectionData) => sectionData.get('oneWay');
const isComplete = (sectionData) => sectionData.get('complete');


const getAllowedTime = (timeData) =>
{
	const allowedTime = timeData.get('allowedTime');
	if (check.number(allowedTime)) { return milliseconds.from(seconds)(allowedTime); }
}

const getWarningTime = (timeData) =>
{
	const warningTime = timeData.get('warningTime');
	if (check.number(warningTime)) { return milliseconds.from(seconds)(warningTime); }
}

const getSectionTimeRemaining = (timeData) =>
{
	const sectionTimeRemaining = timeData.get('sectionTimeRemaining');
	if (check.number(sectionTimeRemaining)) { return milliseconds.from(seconds)(sectionTimeRemaining); }
}

const getReadingTimeRemaining = (timeData) =>
{
	const readingTimeRemaining = timeData.get('readingTimeRemaining');
	if (check.number(readingTimeRemaining)) { return milliseconds.from(seconds)(readingTimeRemaining); }
}

const getTimeRemaining = (timeData) =>
{
	const sectionTimeRemaining = getSectionTime(timeData);
	const readingTimeRemaining = getReadingTimeRemaining(timeData);

	if (check.number(sectionTimeRemaining) && check.number(readingTimeRemaining)) {
		const timeRemaining = sectionTimeRemaining + readingTimeRemaining;
		return milliseconds.from(seconds)(timeRemaining);
	}
}

const getSectionTime = (timeData) =>
{
	const sectionTime = timeData.get('sectionTime');
	if (check.number(sectionTime)) { return milliseconds.from(seconds)(sectionTime); }
}

const getReadingTime = (timeData) =>
{
	const readingTime = timeData.get('readingTime');
	if (check.number(readingTime)) { return milliseconds.from(seconds)(readingTime); }
}

const canBypassReadingTime = (timeData) => (
	timeData.get('canBypassReadingTime')
)


export {
	getMarks,
	getSectionId,
	getSectionName,
	getSectionText,
	getScenarioText,
	getResourceUrl,
	requiresWorkings,
	requiresCalculator,
	isComplete,

	// one way stuff
	isOneWay,
	getAllowedTime,
	getWarningTime,
	getSectionTimeRemaining,
	getReadingTimeRemaining,
	getTimeRemaining,
	getSectionTime,
	getReadingTime,
	canBypassReadingTime
}