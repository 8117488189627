// npm
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//material-ui
import { styles } from "../form/form-styles";
import Fade from "@material-ui/core/Fade";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import WarningIcon from "@material-ui/icons/Warning";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

// redux
import { getLoginData } from "redux/reducers/selectors";
import { getLoginError } from "redux/reducers/login/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getLogoUrl } from "redux/reducers/settings/client/selectors";
import * as ACTIONS from "../form/actions";

// react
import { Image } from "components/presentation/image";
import { withMessages } from "components/hocs/messages";
import { MaterialText } from "components/presentation/material-text";
import { LoadingSpinner } from "components/presentation/loading-spinner";

import { authenticationApi } from "libs/api/interface/api-authentication";

// constants
import { MESSAGE_IDS } from "constants/message-ids";

import {
    getSetPasswordToken,
    getSetPasswordUserDetails,
} from "../form/login_token";
import { check } from "@xams-utils/check-types";

const loginMessageId = MESSAGE_IDS.SET_PASSWORD.MESSAGE;
const loginTitleMessageId = MESSAGE_IDS.SET_PASSWORD.TITLE;
const usernameMessageId = MESSAGE_IDS.LOGIN.USERNAME;
const loginButtonMessageId = MESSAGE_IDS.SET_PASSWORD.BUTTON;
const passwordMessageId = MESSAGE_IDS.GENERAL.PASSWORD;
const retypePasswordMessageId = MESSAGE_IDS.SET_PASSWORD.RETYPE;
const noMatchMessageId = MESSAGE_IDS.SET_PASSWORD.NO_MATCH;
const invalidPasswordMessageId = MESSAGE_IDS.SET_PASSWORD.INVALID_PASSWORD;


const RESULT_CODE = {
    SUCCESS: 0,
    NO_EMAIL: 1,
    INVALID_LOGON_ID: 2,
    INVALID_TOKEN: 3,
};

const hasPasswordBeenSet=(response)=>{
    if (!response) return false;

    const result = parseInt(response);
    if (isNaN(result)) return false;

    return result === RESULT_CODE.SUCCESS;

}

class SetPassword extends Component {
    constructor(props) {
        super(props);

        this.initializeBoundMethods();

        this.userInput = null;
        this.passwordInput = null;
        this.retypePasswordInput = null;

        this.state = {
            submitting: false,
            showSpinner: false,
            disableSubmit: true,
            disableInputs: false,
        };
    }

    initializeBoundMethods() {
        this.onFieldChange = () => {
            const {submitting} = this.state;
            if (!submitting){
                const filled = this.isFormFilled();    
                this.setState({disableSubmit: !filled})
            }
        };

        this.onSubmit = (e) => {
            e.preventDefault();

            const password = this.passwordInput.value;
            const retypePassword = this.retypePasswordInput.value;

            if (password !== retypePassword) {
                const { setLoginError } = this.props;
                const errorText = this.props.messages[noMatchMessageId];

                setLoginError(errorText);
                this.passwordInput.focus();
            } else {
                this.submitPassword(password);
            }
        };

        this.submitPassword=()=>{
            const setPasswordFailed = (response) => {
                debugger;
            };
            const password = this.passwordInput.value;
            this.setState(
                {
                    submitting: true,
                    showSpinner: true,
                    disableSubmit: true,
                    disableInputs: true,
                },
                () => {
                    const loginToken = getSetPasswordToken();
                    const payload = { password, token: loginToken };

                    authenticationApi
                        .resetPassword(payload)
                        .then(this.setPasswordSuccess, setPasswordFailed);
                }
            );            
        }

        this.setPasswordSuccess = (result) => {
            if (hasPasswordBeenSet(result)){
                const {
                    saveUsername,
                    savePassword,
                    onPasswordSet,
                    clearLoginError,
                } = this.props;
                const { id: username } = getSetPasswordUserDetails();
                const newPassword = this.passwordInput.value;

                clearLoginError();
                saveUsername(username);
                savePassword(newPassword);

                onPasswordSet();
            } else {
                const { setLoginError } = this.props;
                const errorText = this.props.messages[invalidPasswordMessageId];
                setLoginError(errorText);
                
                this.setState({
                    submitting: false,
                    showSpinner: false,
                    disableSubmit: false,
                    disableInputs: false,
                });
            }
        };

        this.checkForAutofill = () => {
            if (!this.isFormFilled()) {
                let count = 0;
                const maxCount = 20;
                const interval = 100;

                const intervalId = setInterval(() => {
                    if (count++ >= maxCount) {
                        clearInterval(intervalId);
                    }
                    if (this.isFormFilled()) {
                        clearInterval(intervalId);
                        this.onFieldChange();
                    }
                }, interval);
            } else {
                this.onFieldChange();
            }
        };

        this.handleShowPassword = () => {
            const { showPassword } = this.state;
            const _showPassword = !showPassword;
            this.setState({ showPassword: _showPassword });
        };
    }

    componentDidUpdate(previousProps, prevState) {
        if (!!prevState.showSpinner && !this.state.showSpinner) {
            this.passwordInput.focus();
        }
    }

    render() {
        const { showSpinner } = this.state;
        return (
            <Fade in timeout={1000} onEntered={this.checkForAutofill}>
                <div className={this.props.classes.layout}>
                    <LoadingSpinner color="secondary" open={!!showSpinner}>
                        <Card raised className={this.props.classes.card}>
                            <CardContent
                                className={this.props.classes.cardContent}
                            >
                                {this.Branding}
                                {this.Title}
                                {this.Form}
                            </CardContent>
                        </Card>
                    </LoadingSpinner>
                </div>
            </Fade>
        );
    }

    get Branding() {
        const { classes } = this.props;

        return (
            <div className={classes.logoBox}>
                <Image
                    className={classes.logo}
                    src={this.props.logoUrl}
                    alt="branding"
                />
            </div>
        );
    }

    get Title() {
        return (
            <MaterialText className={this.props.classes.formText} variant="h5">
                {this.props.messages[loginTitleMessageId]}
            </MaterialText>
        );
    }

    get Form() {
        return (
            <form
                className={this.props.classes.form}
                autoComplete="false"
                onSubmit={this.onSubmit}
            >
                {this.UserInput}
                {this.PasswordInput}
                {this.ErrorPanel}
                {this.LoginButton}
                {this.InfoPanel}
            </form>
        );
    }

    get UserInput() {
        const name = "existing-username";
        const label = this.props.messages[usernameMessageId];
        const { id } = getSetPasswordUserDetails();

        const inputRef = (input) => {
            this.userInput = input;
        };
        const inputProps = {
            ...this.BasicInputProps,
            name,
            inputRef,
            id: "exisiting-user-input",
            readOnly: true,
            value: id,
        };

        return this.getFormInput(name, label, false, <Input {...inputProps} />);
    }

    get PasswordInput() {
        return (
            <React.Fragment>
                {this.InitialPasswordInput}
                {this.RetypePasswordInput}
            </React.Fragment>
        );
    }

    get InitialPasswordInput() {
        const { showPassword } = this.state;
        const name = "initialPassword";
        const label = this.props.messages[passwordMessageId];

        const type = showPassword ? null : "password";
        const inputRef = (input) => {
            this.passwordInput = input;
        };
        const inputProps = {
            ...this.BasicInputProps,
            name,
            type,
            required: true,
            inputRef,
            id: "initial-password-input",
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleShowPassword}
                    >
                        {showPassword ? (
                            <VisibilityIcon />
                        ) : (
                            <VisibilityOffIcon />
                        )}
                    </IconButton>
                </InputAdornment>
            ),
        };

        return this.getFormInput(name, label, true, <Input {...inputProps} />);
    }

    get RetypePasswordInput() {
        const { showPassword } = this.state;
        const name = "retypePassword";
        const label = this.props.messages[retypePasswordMessageId];

        const type = showPassword ? null : "password";
        const inputRef = (input) => {
            this.retypePasswordInput = input;
        };

        const inputProps = {
            ...this.BasicInputProps,
            name,
            type,
            inputRef,
            id: "retype-password-input",
        };

        return this.getFormInput(name, label, true, <Input {...inputProps} />);
    }

    get BasicInputProps() {
        const { classes } = this.props;
        const { disableInputs } = this.state;

        return {
            autoComplete: "off",
            onChange: this.onFieldChange,
            disabled: !!disableInputs,
            className: this.props.classes.formInputText,
            classes: {
                input: classes.input,
                underline: classes.inputUnderline,
            },
        };
    }

    getFormInput(name, label, required = false, inputElement) {
        const { classes } = this.props;

        return (
            <FormControl margin="normal" required={required} fullWidth>
                <InputLabel className={classes.formInputText} shrink={true} htmlFor={name}>
                    {label}
                </InputLabel>
                {inputElement}
            </FormControl>
        );
    }

    get ErrorPanel() {
        const { classes, errorText } = this.props;

        if (!errorText) {
            return null;
        }

        return (
            <span className={classes.errorContainer}>
                <WarningIcon
                    className={`${classes.error} ${classes.errorIcon}`}
                />
                <MaterialText className={classes.error}>
                    {errorText}
                </MaterialText>
            </span>
        );
    }

    get LoginButton() {
        const { disableSubmit, disableInputs } = this.state;
        const props = {
            type: "submit",
            fullWidth: true,
            variant: "contained",
            color: "primary",
            className: this.props.classes.submit,
            classes: { disabled: this.props.classes.submitDisabled },
            disabled: !!disableSubmit || !!disableInputs,
        };

        return (
            <Button {...props}>
                {this.props.messages[loginButtonMessageId]}
            </Button>
        );
    }

    get InfoPanel() {
        const { first } = getSetPasswordUserDetails();
        const message = this.props.messages[loginMessageId].replace("~", first);
        return (
            <Hidden xsDown>
                <div className={this.props.classes.loginInfo}>
                    <MaterialText className={this.props.classes.formText}>
                        {message}
                    </MaterialText>
                </div>
            </Hidden>
        );
    }

    isFormFilled() {
        const { passwordInput, retypePasswordInput } = this;

        return (
            this.isFieldFilled(passwordInput) &&
            this.isFieldFilled(retypePasswordInput)
        );
    }

    isFieldFilled(inputElement) {
        if (inputElement.value !== "") {
            return true;
        }

        const computedStyle = window.getComputedStyle(inputElement);
        const autofilled = computedStyle.content.includes("filled");
        return autofilled;
    }
}

const mapStoreToProps = (store) => ({
    errorText: getLoginError(getLoginData(store)),
    logoUrl: getLogoUrl(getClientSettingsData(getSettingsData(store))),
});

const mapDispatchToProps = (dispatch) => ({
    saveUsername: (value) => dispatch(ACTIONS.setUsername(value)),
    savePassword: (value) => dispatch(ACTIONS.setPassword(value)),
    setLoginError: (msg) => {
        dispatch(ACTIONS.setLoginError(msg));
    },
    clearLoginError: () => {
        dispatch(ACTIONS.clearLoginError());
    },
});

SetPassword = connect(mapStoreToProps, mapDispatchToProps)(SetPassword);
SetPassword = withStyles(styles)(SetPassword);
SetPassword = withMessages(SetPassword);

export { SetPassword };
