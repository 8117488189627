import { detect } from "detect-browser";
import { check } from "@xams-utils/check-types";

const isBrowserSupported = (supportedBrowser, currentBrowser) => {
    const { name, version } = currentBrowser;

    if (
        !check.nonEmptyObject(supportedBrowser) ||
        !check.nonEmptyString(name)
    ) {
        return false;
    }

    if (!browserNameMatch(supportedBrowser, currentBrowser)) {
        return false;
    }

    const supportedBrowserVersion = check.assigned(supportedBrowser.versions)
        ? supportedBrowser.versions
        : "";

    // console.log("supportedBrowserVersion", supportedBrowserVersion);

    if (check.string(supportedBrowserVersion)) {
        if (supportedBrowserVersion.trim() === "") {
            return true;
        }
        return compareVersion(version, supportedBrowserVersion);
    } else if (check.nonEmptyArray(supportedBrowserVersion)) {
        const comparisons = supportedBrowserVersion.reduce(
            (a, item) => {
                const { comparison } = getComparisonAndVersion(item);

                if (comparison === "=") a[0].push(item);
                else a[1].push(item);

                return a;
            },
            [[], []]
        );

        const checkEquals =
            comparisons[0].length > 0
                ? comparisons[0].some((item) => compareVersion(version, item))
                : false;
        const checkNotEquals =
            comparisons[1].length > 0
                ? comparisons[1].every((item) => compareVersion(version, item))
                : false;

        return checkEquals || checkNotEquals;
    }
};

const getComparisonAndVersion = (item) => {
    const firstChar = item.charAt(0);
    const isFirstNumber = firstChar >= "0" && firstChar <= "9";
    const comparison = isFirstNumber ? "=" : firstChar;
    const version = getVersionBits(isFirstNumber ? item : item.substring(1));

    return { version, comparison };
};

const compareVersion = (currentVersion, supported) => {
    if (supported.trim() === "") {
        return true;
    }
    const { version: supportedVersion, comparison } =
        getComparisonAndVersion(supported);

    const size =
        currentVersion.length > supportedVersion.length
            ? supportedVersion.length
            : currentVersion.length;

    for (let i = 0; i < size; i++) {
        const last = i === size - 1;
        if (
            !check.assigned(currentVersion[i]) ||
            !check.assigned(supportedVersion[i])
        ) {
            return true;
        }
        if (comparison === "=" && currentVersion[i] !== supportedVersion[i]) {
            return false;
        } else if (comparison === ">") {
            if (last || currentVersion[i] !== supportedVersion[i]) {
                return currentVersion[i] > supportedVersion[i];
            }
        } else if (
            comparison === "<" &&
            currentVersion[i] >= supportedVersion[i]
        ) {
            return false;
        } else if (
            comparison === "!" &&
            currentVersion[i] !== supportedVersion[i]
        ) {
            return false;
        }
    }

    return true;
};

const getSupportedVersionText = (supportedBrowser) => {
    const versions = supportedBrowser.versions;

    if (check.string(versions)) {
        return `version ${getVersionText(versions)}`;
    } else if (check.nonEmptyArray(versions)) {
        //console.log("Versions", versions);
        return `version ${versions.reduce((text, version) => {
            if (!check.assigned(text)) {
                const { comparison } = getComparisonAndVersion(version);
                if (comparison === ">") {
                    return getVersionText(version);
                }
            }
            return text;
        }, null)}`;
    }
};

const getVersionText = (versionText) => {
    const { version, comparison } = getComparisonAndVersion(versionText);
    if (comparison === "=") return displayVersion(version);
    if (comparison === ">")
        return `${displayVersion(nextVersion(version))} or greater`;
    return null;
};

const displayVersion = (version) => {
    const _version = check.string(version) ? getVersionBits(version) : version;

    return removeTrailingZeros(_version).join(".");
};

const removeTrailingZeros = (version) => {
    let _version = [];
    for (let i = version.length - 1; i >= 0; i--) {
        if (_version.length > 0 || version[i]) {
            _version.unshift(version[i]);
        }
    }

    return _version;
};

const nextVersion = (version) => {
    for (let i = 2; i >= 0; i--) {
        if (check.assigned(version[i])) {
            version[i]++;
            break;
        }
    }

    return version;
};

const getVersionBits = (versionString) => {
    const versions = check.nonEmptyString(versionString)
        ? versionString.split(".").map((version) => parseInt(version, 10))
        : [];

    return versions;
};

const browserNameMatch = (supportedBrowser, currentBrowser) => {
    const { name } = currentBrowser;

    const supportedBrowserName = check.nonEmptyString(supportedBrowser.name)
        ? supportedBrowser.name.toLowerCase()
        : null;

    return supportedBrowserName === name.toLowerCase();
};

const getOtherSupportedBrowsers = (supportedBrowsers, supportedBrowser, os) => {
    const displayOthers = check.nonEmptyObject(supportedBrowser)
        ? supportedBrowser.displayOthers
        : true;

    if (check.assigned(displayOthers) && !displayOthers) {
        return null;
    }
    const otherBrowsers = supportedBrowsers.filter((browser) => {
        const { displayInOthers } = browser;
        if (check.assigned(displayInOthers) && !displayInOthers) {
            return false;
        }

        if (
            (check.nonEmptyObject(supportedBrowser) &&
                // browser.name === supportedBrowser.name) ||
                browserNamesTheSame(browser, supportedBrowser)) ||
            !check.nonEmptyArray(browser.os)
        ) {
            return false;
        }

        return browser.os.some(
            (browserOS) => browserOS.toLowerCase() === os.toLowerCase()
        );
    });

    return otherBrowsers;
};

const browserNamesTheSame = (browser, supportedBrowser) => {
    if (browser.name === supportedBrowser.name) {
        return true;
    }
    if (
        check.nonEmptyString(browser.display) &&
        check.nonEmptyString(supportedBrowser.display)
    ) {
        if (browser.display === supportedBrowser.display) {
            return true;
        }
    }
    return false;
};

const getBrowserName = (browser) => {
    const { name, display } = browser;

    return check.nonEmptyString(display) ? display : name;
};

const populateMessage = (message, variables) => {
    for (let i = 0; i < variables.length; i++) {
        message = message.replace(`[${variables[i][0]}]`, variables[i][1]);
    }
    return message;
};

const getCurrentBrowser = () => {
    const { name, version, os } = detect();
    const browser = { name, version, os };

    const sebBrowser = getSebBrowser();

    if (check.nonEmptyObject(sebBrowser)) {
        browser.version = sebBrowser.version;
        browser.name = sebBrowser.name;
    }

    // browser.name = "chrome";
    // browser.version = "79.0.0";
    // browser.os = "Chrome OS";

    return browser;
};

const getSebBrowser = () => {
    const sebBrowser = {};
    const sebCheck = "seb/";
    const userAgent = navigator.userAgent;

    if (check.nonEmptyString(userAgent)) {
        const a = userAgent.split(" ");

        for (let i = 0; i < a.length; i++) {
            const s = a[i].trim().toLowerCase();
            if (check.nonEmptyString(s) && s.length >= sebCheck.length) {
                if (
                    sebCheck === s.substring(0, sebCheck.length).toLowerCase()
                ) {
                    const v = s.split("/");

                    if (check.nonEmptyArray(v) && v.length === 2) {
                        sebBrowser.version = v[1].trim();
                        sebBrowser.name = "SEB";
                        break;
                    }
                }
            }
        }
    }
    return sebBrowser;
};

const isOSExempt = (os) => {
    const oSExempt = isCromeOS(os);

    return oSExempt;
};

const isCromeOS = (os) => {
    return check.nonEmptyString(os) && os.toLowerCase() === "chrome os";
};

const checkPdfBrowserSupport = (supportedBrowsers) => {
    const { name, version, os } = getCurrentBrowser();

    if (isOSExempt(os)) {
        return { supported: true };
    }

    const currentVersion = getVersionBits(version);
    const currentBrowser = { name, version: currentVersion, os };

    const isSupported = supportedBrowsers.some((supportedBrowser) =>
        isBrowserSupported(supportedBrowser, currentBrowser)
    );

    if (isSupported) {
        return { supported: true };
    }

    const supportedBrowser = supportedBrowsers.find((supportedBrowser) => {
        return browserNameMatch(supportedBrowser, currentBrowser);
    });

    if (check.nonEmptyObject(supportedBrowser)) {
        if (!check.nonEmptyArray(supportedBrowser.versions)) {
            return { notSupported: true };
        }

        return { versionNotSupported: supportedBrowser };
    } else {
        return { notSupported: true };
    }
};

const useOldPdfViewer=()=>{
	const { version, os } = getCurrentBrowser();

	if (isCromeOS(os)){
		const currentVersion = getVersionBits(version);
		const oldVersion = "<80"
		const useOldVersion=compareVersion(currentVersion, oldVersion);

		return useOldVersion;
	}

	return false;
}

export {
    checkPdfBrowserSupport,
    getCurrentBrowser,
    displayVersion,
    getBrowserName,
    getOtherSupportedBrowsers,
    getSupportedVersionText,
    populateMessage,
    useOldPdfViewer
};
