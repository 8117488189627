// other modules
import {api} from '../api'
import {ENDPOINTS} from '../constants'


const forgotPassword = (data) =>
{
	return api.post(ENDPOINTS.PASSWORD.FORGOT, data);
}

const resetPassword = (data) =>
{
	return api.post(ENDPOINTS.PASSWORD.RESET, data);
}

const getLoginToken = (data) =>
{
	return api.get(ENDPOINTS.PASSWORD.GET_LOGIN_DATA, data);	
}

const passwordApi = {
	forgotPassword,
	resetPassword,
	getLoginToken
};

export {passwordApi}