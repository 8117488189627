
// internal
import {StorageApi} from './storage-api'


const KEYS = {
	USERS: 'users',
	EXAMS: 'exams',
	EXAM_QUEUES: 'persistent_exam_queus',
	NETWORK_REQUEST_LOG: 'network_request_log',
	REQUEST_LOG_MAPPINGS: 'request_log_mappings',
	FAILED_EXAM_REQUESTS: 'failed_exam_requests',
	SCHEDULED_BUG_REPORTS: 'scheduled_bug_reports'
}

const localStorageApi = new StorageApi(window.localStorage, Object.values(KEYS));
Object.freeze(localStorageApi);

export {localStorageApi, KEYS}