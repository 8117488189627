
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE NAMES
// #########################################

const STATES = {
	UNFILLED: 'form_not_filled',
	SUBMITTABLE: 'form_can_be_submitted',
	SUBMITTING: 'form_being_submitted',
	SUBMITTED: 'form_submitted',
	SUBMISSION_FAILED: 'form_submission_failed',
	SUBMISSION_ERROR: 'form_submission_error'
}

const SUBMITTING_STATES = {
	VALIDATING_FORM: 'validating_form',
	SUBMITTING_FORM: 'submitting_form'
}


// #########################################
// EVENT NAMES
// #########################################

const EVENTS = {
	CAN_SUBMIT: 'form.can.submit',
	CANT_SUBMIT: 'form.cant.submit',
	SUBMIT: 'submit.form',
	SUCCESS: 'submit.success',
	FAIL: 'submit.fail',
	ERROR: 'submit.error'
}


// #########################################
// SUBMITTING STATES
// #########################################

const validatingForm = new XStateConfig();
validatingForm.addTransition(EVENTS.SUCCESS, SUBMITTING_STATES.SUBMITTING_FORM);

const submittingForm = new XStateConfig();


// #########################################
// FORM STATES
// #########################################

const unfilled = new XStateConfig();
unfilled.addTransition(EVENTS.CAN_SUBMIT, STATES.SUBMITTABLE);

const submittable = new XStateConfig();
submittable.addTransition(EVENTS.CANT_SUBMIT, STATES.UNFILLED);
submittable.addTransition(EVENTS.SUBMIT, STATES.SUBMITTING);

const submitting = new XStateConfig();
submitting.initialState = SUBMITTING_STATES.VALIDATING_FORM;
submitting.addState(SUBMITTING_STATES.VALIDATING_FORM, validatingForm);
submitting.addState(SUBMITTING_STATES.SUBMITTING_FORM, submittingForm);
submitting.addTransition(EVENTS.FAIL, STATES.SUBMISSION_FAILED);
submitting.addTransition(EVENTS.ERROR, STATES.SUBMISSION_ERROR);
submitting.addTransition(EVENTS.SUCCESS, STATES.SUBMITTED);

const submissionFailed = new XStateConfig();
submissionFailed.addTransition(EVENTS.CANT_SUBMIT, STATES.UNFILLED);
submissionFailed.addTransition(EVENTS.CAN_SUBMIT, STATES.SUBMITTABLE);

const submissionError = new XStateConfig();
const submitted = new XStateConfig();


// #########################################
// FORM MACHINE
// #########################################

const _form = new XStateConfig();
_form.initialState = STATES.UNFILLED;
_form.addState(STATES.UNFILLED, unfilled);
_form.addState(STATES.SUBMITTABLE, submittable);
_form.addState(STATES.SUBMITTING, submitting);
_form.addState(STATES.SUBMISSION_FAILED, submissionFailed);
_form.addState(STATES.SUBMISSION_ERROR, submissionError);
_form.addState(STATES.SUBMITTED, submitted);

const machine = Machine(_form.toObject());
machine.id = "Form Machine";


// #########################################
// EXPORT
// #########################################

const form = {
	machine,
	EVENTS,
	STATES: {...STATES}, 
	SUBMITTING_STATES: {...SUBMITTING_STATES}
}

export {form}