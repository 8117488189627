
// npm
import React from 'react'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {Align} from 'components/layout/align'
import {AppPageModifier} from 'components/pages/app-page-modifier'
import {MaterialText} from 'components/presentation/material-text'


// NoClientPage (not connected)
// -------------------------------------------------------------------------------

let NoClientPage = ({classes}) => (
	<React.Fragment>
		<AppPageModifier id="no-client" appBarProps={{title: "Client not found"}}/>
		<Align>
			<MaterialText className={classes.text} variant='h5'>
				{"Please use your client's login address"}
			</MaterialText>
		</Align>
	</React.Fragment>
)


// NoClientPage (connected to styles)
// -------------------------------------------------------------------------------

const styles = ({palette}) => ({
	text: {
		color: palette.background.contrastText
	}
})

NoClientPage = withStyles(styles)(NoClientPage);



export {NoClientPage}