
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Map as ImmutableMap, fromJS} from 'immutable'

// material-ui
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'

// redux (selectors)
import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getThemesData} from 'redux/reducers/settings/client/selectors'
import {getThemeData} from 'redux/reducers/selectors'
import {getTheme} from 'redux/reducers/theme/selectors'

// utils
import {ThemeConfig} from 'utils/theme-config'

// constants
import {COLOR_NAMES, DEFAULT_THEME} from 'constants/theme'


const colorNames = Object.values(COLOR_NAMES);


// Helper methods
// -------------------------------------------------------------------

const augmentNonStandardColors = (palette) =>																			// MUI creates 'light/dark' variations for primary, secondary and error colours only
{																																									// Any additional colour types (e.g. background) must have their light/dark colours calculated
	const {background} = palette;
	palette.background = palette.augmentColor(background, 300, 500, 700);
}

const convertToMuiPalette = (themeData) =>
{
	const muiPalette = {};

	colorNames.forEach(colorName => {
		muiPalette[colorName] = { main: themeData.get(colorName) }
	});

	return adjustMuiPalette(themeData, muiPalette);
}

const adjustMuiPalette = (themeData, muiPalette) => {
    if (
        themeData.get("primary") === "#030303" &&
        themeData.get("background") === "#800080"
    ) {
        // Black on purple hack
        muiPalette.background.contrastText = "#000";
    }
    return muiPalette;
};

const createDefaultTheme = () =>
{
	const themeConfig = new ThemeConfig(DEFAULT_THEME);
	const defaultTheme = themeConfig.generateTheme();
	return ImmutableMap(fromJS(defaultTheme));
}

const getMuiPalette = (themesData, themeName) =>
{
	const theme = themesData ? themesData.get(themeName) : createDefaultTheme();
	return convertToMuiPalette(theme);
}


// AppTheme (not connected to store)
// -------------------------------------------------------------------

let AppTheme = ({themeName, themesData, children}) =>
{
	const muiThemeConfig = {
		palette: getMuiPalette(themesData, themeName),
		typography: {useNextVariants: true}
	};
	
	const theme = createMuiTheme(muiThemeConfig);
	augmentNonStandardColors(theme.palette);

	return (
		<MuiThemeProvider theme={theme}>
			{children}
		</MuiThemeProvider>
	)
}

AppTheme.propTypes = {
	children: PropTypes.node,
	themeName: PropTypes.string,
	themesData: PropTypes.object
}


// AppTheme (connected to store)
// -------------------------------------------------------------------

const mapStoreToProps = (store) => {
	const settingsData = getSettingsData(store);
	const clientSettingsData = getClientSettingsData(settingsData);
	const currentTheme = getTheme(getThemeData(store)) || "default";
	
	return {
		themeName: getTheme(getThemeData(store)),
		themesData: getThemesData(clientSettingsData)
	}
}

AppTheme = connect(mapStoreToProps)(AppTheme);


// EXPORT
// -------------------------------------------------------------------
export {AppTheme}