
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {Document, Page} from 'react-pdf'

// material-ui
import IconButton from '@material-ui/core/IconButton'
import MobileStepper from '@material-ui/core/MobileStepper'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

// react
import {DocumentLoader} from './document-loader'
import {ActionButton} from 'components/layout/action_button/button'
import {MaterialScrollWrapper} from 'components/layout/scroll_wrapper/material-scroll-wrapper'

// constants
import {SCALE} from './constants'
import {contentBarHeight} from '../../../constants'


// PdfResourcePanel (not connected to styles)
// -------------------------------------------------

class PdfResourcePanel extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			loaded: false,
			pageCount: null,
			pageNumber: 1,
			maxPageWidth: 0
		}

		this.initializeBoundMethods();
	}

	navigate(direction)
	{
		this.setState(prevState => ({
			pageNumber: prevState.pageNumber + direction
		}));
	}

	initializeBoundMethods()
	{
		this.onDocumentLoad = ({pageCount, maxPageWidth}) => {
			this.setState({pageCount, maxPageWidth, loaded: true});
		}

		this.navigateBackward = this.navigate.bind(this, -1);
		this.navigateForward = this.navigate.bind(this, 1);
	}

	render()
	{
		const {props:{classes}, state:{maxPageWidth}} = this;

		return (
			<div className={classes.wrapper} style={{width: maxPageWidth}}>
				{this.state.loaded ? this.LoadedDocument : this.DocumentLoader}
			</div>
		);
	}

	get DocumentLoader()
	{
		const loaderProps = {
			resourceUrl: this.props.resourceUrl,
			onLoad: this.onDocumentLoad
		}

		return <DocumentLoader {...loaderProps}/>;
	}

	get LoadedDocument()
	{
		const {classes} = this.props;

		return (
			<React.Fragment>
				<div className={classes.navigationContainer}>
					{this.MobileStepper}
				</div>
				<MaterialScrollWrapper>
					<div className={classes.documentContainer}>
						{this.Document}
					</div>
				</MaterialScrollWrapper>
			</React.Fragment>
		);
	}

	get MobileStepper()
	{
		const {classes} = this.props;
		const variant =  this.state.pageCount > 10 ? 'progress': 'dots';
		const props = {
			variant,
			position: 'static',
			steps: this.state.pageCount,
			className: classes.stepper,
			classes: {dot: classes.dot, dotActive: classes.dotActive},
			activeStep: this.state.pageNumber - 1,
			backButton: this.getStepperButton(-1),
			nextButton: this.getStepperButton(1)
		}

		return <MobileStepper {...props}/>;
	}

	getStepperButton(direction=1)
	{
		const Icon = direction === -1 ? KeyboardArrowLeft : KeyboardArrowRight;
		const onClick = direction === -1 ? this.navigateBackward : this.navigateForward;
		const disabled = direction === -1 ? this.isFirstPage : this.isLastPage;
		const alignment = direction === -1 ? 'left' : 'right';

		const props = {Icon, onClick, disabled, alignment, color: 'secondary', size: 'small'};

		return (
			<ActionButton {...props}>
				{disabled ? "" : `Page ${this.state.pageNumber + direction}`}
			</ActionButton>
		);
	}

	get Document()
	{
		return (
			<Document file={this.props.resourceUrl} loading={this.SpinnerNode} onContextMenu={(e) => e.preventDefault()}>
				<Page scale={SCALE} pageNumber={this.state.pageNumber} loading={this.SpinnerNode}/>
			</Document>
		);
	}

	get SpinnerNode()
	{
		return (
			<div className={this.props.classes.loading}>
				<CircularProgress color="secondary" className={this.props.classes.progress}/>
			</div>
		);
	}

	get isFirstPage()
	{
		return this.state.pageNumber === 1;
	}

	get isLastPage()
	{
		return this.state.pageNumber === this.state.pageCount;
	}
}

PdfResourcePanel.propTypes = {
	classes: PropTypes.object.isRequired,
	resourceUrl: PropTypes.string.isRequired
}


// PdfResourcePanel (connected to styles)
// -------------------------------------------------

const styles = ({palette}) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: 'calc(100vh - 50px)'
	},
	navigationContainer: {
		flexGrow: 0,
		flexShrink: 0
	},
	documentContainer: {
		flexShrink: 1
	},
	stepper: {
		paddingTop: 0,
		paddingBottom: 0,
		height: contentBarHeight,
		backgroundColor: palette.background.light
	},
	loading: {
		textAlign: 'center'
	},
	progress: {
		marginTop: 50
	},
	dot: {
		backgroundColor: palette.secondary.dark
	},
	dotActive: {
		backgroundColor: palette.secondary.light
	}
});

PdfResourcePanel = withStyles(styles)(PdfResourcePanel);


// Export
// -------------------------------------------------
export {PdfResourcePanel}