import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import { getFirstFileType, getFileExtension } from './common';

const uploadImage = {
  display: 'inline-flex',
  borderRadius: '2px',
  border: '1px solid #eaeaea',
  marginBottom: '8px',
  marginRight: '8px',
  width: '60px',
  height: '60px',
  padding: '4px',
  boxSizing: 'border-box'
};
const uploadDocument = {
  textAlign: 'center',
  paddingTop: '12px',
  width: '100%',
  height: 'calc(100% - 12px)',
  background: 'grey',
  textTransform: 'uppercase',
  fontWeight: 'bold'
};

const isImage = file => getFirstFileType(file.type) === 'image';

class FileToUpload extends Component {
  state = {
    preview: isImage(this.props.file)
      ? URL.createObjectURL(this.props.file)
      : null
  };
  componentWillUnmount = () => {
    if (this.state.preview !== null) URL.revokeObjectURL(this.state.preview);
  };
  handleDelete = () => {
    if (!this.props.disabled) this.props.onDelete(this.props.index);
  };
  getFilePreview = () => {
    const { file } = this.props;
    return this.state.preview !== null ? (
      <img src={this.state.preview} alt={file.name} style={uploadImage} />
    ) : (
      <div style={uploadImage}>
        <div style={uploadDocument}>{getFileExtension(file.name)}</div>
      </div>
    );
  };
  render() {
    const { file, disabled } = this.props;
    return (
      <React.Fragment>
        <li>
          <Divider />
        </li>
        <ListItem>
          {this.getFilePreview()}
          <ListItemText
            primary={file.name}
            primaryTypographyProps={{ noWrap: true }}
          />

          <ListItemSecondaryAction>
            <IconButton
              aria-label="Delete"
              disabled={disabled}
              onClick={this.handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </React.Fragment>
    );
  }
}

export default FileToUpload;
