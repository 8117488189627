// npm
import React from "react";

// react
import { AscxAnswerBox } from "./answer_boxes/ascx";
import { ComboAnswerBox } from "./answer_boxes/combo";
import { RadiosAnswerBox } from "./answer_boxes/radios";
import { ChecksAnswerBox } from "./answer_boxes/checks";
import { MatchAnswerBox } from "./answer_boxes/match";
import { TableAnswerBox } from "./answer_boxes/table";
import { BlanksAnswerBox } from "./answer_boxes/blanks";
import { MultiTextAnswerBox } from "./answer_boxes/multi-text";
import { IctAnswerBox } from "./answer_boxes/ict";
import { StaticTextAnswerBox } from "./answer_boxes/static_text";
import { FormAnswerBox } from "./answer_boxes/form";
import { RadiosTextAnswerBox } from "./answer_boxes/radios-text";
import { UnsupportedAnswerBox } from "./answer_boxes/unsupported";

// redux
import {
    getType,
    getId,
} from "redux/reducers/exam/content/questions/question/selectors";

// constants
import * as QUESTION_TYPES from "constants/question-types";

const getAnswerBox = (questionData, noQuestionPanel=false) => {
    const props = { data: questionData, key: getId(questionData), noQuestionPanel };

    switch (getType(questionData)) {
        case QUESTION_TYPES.RADIOS:
        case QUESTION_TYPES.TRUE_OR_FALSE:
            return <RadiosAnswerBox {...props} />;
        case QUESTION_TYPES.COMBO:
            return <ComboAnswerBox {...props} />;
        case QUESTION_TYPES.CHECKS:
            return <ChecksAnswerBox {...props} />;
        case QUESTION_TYPES.MULTI_TEXT:
            return <MultiTextAnswerBox {...props} />;
        case QUESTION_TYPES.MATCH:
            return <MatchAnswerBox {...props} />;
        case QUESTION_TYPES.BLANKS:
            return <BlanksAnswerBox {...props} />;
        case QUESTION_TYPES.TABLE:
            return <TableAnswerBox {...props} />;
        case QUESTION_TYPES.ASCX:
            return <AscxAnswerBox {...props} />;
        case QUESTION_TYPES.ICT:
            return <IctAnswerBox {...props} />;
        case QUESTION_TYPES.STATIC_TEXT:
            return <StaticTextAnswerBox {...props} />;
        case QUESTION_TYPES.FORM:
            return <FormAnswerBox {...props} />;
        case QUESTION_TYPES.RADIOS_TEXT:
            return <RadiosTextAnswerBox {...props} />;
        default:
            return <UnsupportedAnswerBox {...props} />;
    }
};

export { getAnswerBox };
