// npm
import check from 'check-types'
import {Map as ImmutableMap} from 'immutable'

// custom
import {getProp} from 'custom/object-helper'

// redux
import {MAPPING_REDUCER} from './mapping/reducer'
import * as EXAM_ACTION_TYPES from '../../action-types'


// Note: state is mutable here
// --------------------------------------------------------------------
const createMapping = (mappees) =>
{
  const action = {type: EXAM_ACTION_TYPES.SET_EXAM_DATA, mappees};
  return MAPPING_REDUCER(undefined, action);
}

const populateMappings = (state, taskGroups) =>
{
  if (!check.nonEmptyArray(taskGroups)) { return; }

  taskGroups.forEach(taskGroup => {
    const paperParts = getProp(taskGroup, 'OBJECTS.PAPERPART');
    const sections = paperParts || getProp(taskGroup, 'OBJECTS.TASKGROUP');
    const questions = getProp(taskGroup, 'OBJECTS.QUESTION');
    state.set(taskGroup['@ID'], createMapping(sections || questions));
    if (sections) {
      populateMappings(state, sections);
    }
  });
}
// --------------------------------------------------------------------

const MAPPINGS_REDUCER = (state = ImmutableMap(), action) =>
{
  switch (action.type)
  {
    case EXAM_ACTION_TYPES.SET_EXAM_DATA:
      return state.withMutations(mutableState => {
        const {data: {EXAM: exam}} = action;

        // 1. Create root mappings
        const paperParts = getProp(exam, 'OBJECTS.PAPERPART');
        const taskGroups = paperParts || getProp(exam, 'OBJECTS.TASKGROUP');
        const questions = getProp(exam, 'OBJECTS.QUESTION');
        mutableState.set('root', createMapping(taskGroups || questions));

        // 2. Create other mappings
        if (taskGroups) { populateMappings(mutableState, taskGroups); }
      });
    default:
      return state;
  }
};

export {MAPPINGS_REDUCER}