// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// utils
import {assessmentApi} from 'libs/api/interface/api-assessment'

// redux (selectors)
import {getUserGuid} from 'redux/reducers/session/user/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getScheduleGuid, getGuid} from 'redux/reducers/exam/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'
import {getSessionData, getExamData, getSchedulesData} from 'redux/reducers/selectors'
import {getScheduleType, getExamGuid, getExamTypeGuid, getVersion, getPracticeExtraTime} from 'redux/reducers/schedules/schedule/selectors'

// redux (actions)
import {setExamData} from './actions'


// ExamFetcher (not connected to store)
// ----------------------------------------

class ExamFetcher extends React.Component
{
  componentDidMount()
  {
    const args = [
      this.props.userGuid,
      this.props.scheduleGuid,
      this.props.scheduleType,
      this.props.examGuid,
      this.props.examTypeGuid,
      this.props.version,
      this.props.extraTime
    ]

    assessmentApi.getExam(...args).then(this.props.onSuccess, this.props.onFail);
  }

  render()
  {
    return null;
  }
}

ExamFetcher.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  userGuid: PropTypes.string.isRequired,
  scheduleGuid: PropTypes.string.isRequired,
  scheduleType: PropTypes.string.isRequired,
  examGuid: PropTypes.string.isRequired,
  examTypeGuid: PropTypes.string.isRequired,
  version: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
}


// ExamFetcher (connected to store)
// ----------------------------------------

const mapStoreToProps = (store) =>
{
  const examData = getExamData(store);
  const examGuid = getGuid(examData);
  const scheduleGuid = getScheduleGuid(examData);

  const schedulesData = getSchedulesData(store);
  const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);

  const userGuid = getUserGuid(getUserSessionData(getSessionData(store)));
  const scheduleType = getScheduleType(scheduleData);
  const examTypeGuid = getExamTypeGuid(scheduleData);
  const version = getVersion(scheduleData);

  const extraTime = getPracticeExtraTime(scheduleData)

  return {userGuid, scheduleGuid, scheduleType, examGuid, examTypeGuid, version, extraTime};
}

const mapDispatchToProps = (dispatch, {onSuccess}) => ({
  onSuccess: (response) => {
    response = JSON.parse(response);
    dispatch(setExamData(response));
    onSuccess();
  }
})

ExamFetcher = connect(mapStoreToProps, mapDispatchToProps)(ExamFetcher);


// EXPORT
// ----------------------------------------
export {ExamFetcher}