import React, { Component } from "react";
import { getStyle, getComponentValue } from "./form-helper";
import { check } from "@xams-utils/check-types";

class FormHotspot extends Component {
    state = {};

    constructor(props) {
        super(props);

        const { checkbox } = props;
        this.hasCheckbox = check.nonEmptyString(checkbox);

        if (this.hasCheckbox) {
            const items = checkbox.split(",");
            if (check.nonEmptyArray(items)) {
                this.checkBoxPosition = [items[0], items[1]];
                this.checkBoxSize = items.length > 2 ? items[2] : 1;
            }
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { enabled, name } = this.props;

        if (enabled) {
            if (this.hasCheckbox) {
                this.handleChecked(null);
            } else {
                const { onClick } = this.props;

                onClick(name);
            }
        }
    }

    handleChecked(_checked) {
        const { enabled, onChange, name } = this.props;
        if (enabled) {
            const checked = check.boolean(checked)
                ? _checked
                : !this.getChecked();
            const newValue = checked ? "checked" : "";
            onChange(name, newValue);
        }
    }

    getChecked() {
        const { formValue, name } = this.props;

        const value = getComponentValue(name, formValue);
        return value === "checked";
    }

    render() {
        const { enabled, name } = this.props;
        const styleObj = getStyle(this.props);

        if (!enabled) {
            styleObj.cursor = "default";
        }
        styleObj.display = "inline-block";
        const hotSpotProps = {
            style: styleObj,
            onClick: this.handleClick,
        };

        if (this.hasCheckbox) {
            const inputProps = {
                name,
                type: "checkbox",
                checked: this.getChecked(),
                onChange: (e) => this.handleChecked(e.target.checked),
                style: { transform: `scale(${this.checkBoxSize})` },
                disabled: !enabled,
            };
            const divProps = {
                style:{
                marginLeft: `${this.checkBoxPosition[0]}px`,
                marginTop: `${this.checkBoxPosition[1]}px`,
                }
            };

            return (
                <div {...hotSpotProps}>
                    <div {...divProps}>
                        <input {...inputProps} />
                    </div>
                </div>
            );
        }

        return <div {...hotSpotProps}></div>;
    }
}

export { FormHotspot };
