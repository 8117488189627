
const FONT_FORMATS = {
	"Andale Mono": 		"andale mono, times",
	"Arial": 					"arial, helvetica, sans-serif",
	"Arial Black": 		"arial black, avant garde",
	"Book Antiqua": 	"book antiqua, palatino",
	"Comic Sans MS": 	"comic sans ms, sans-serif",
	"Courier New": 		"courier new, courier",
	"Georgia": 				"georgia, palatino",
	"Helvetica": 			"helvetica",
	"Impact": 				"impact, chicago",
	"Tahoma": 				"tahoma, arial, helvetica, sans-serif",
	"Terminal": 			"terminal, monaco",
	"Times New Roman": "times new roman, times",
	"Trebuchet MS": 	"trebuchet ms, geneva",
	"Verdana": 				"verdana, geneva",
	"Signature": 			"dancing script"
}


const FONTS = Object.keys(FONT_FORMATS).map(fontName => {
	return `${fontName}=${FONT_FORMATS[fontName]}`;
});


const fontFormats = FONTS.join(';');


export {fontFormats}