import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import ImageIcon from '@material-ui/icons/Image';
import DocumentIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplaceIcon from '@material-ui/icons/Cached';
import FileDragAndDrop from './file-drag-and-drop';
import { getFirstFileType } from './common';
import Moment from 'react-moment';
import {downloadFile} from './services/upload-service';


const getFileIcon = file =>
{
  if (getFirstFileType(file.type) === 'image') { return <ImageIcon/>; }
  else { return <DocumentIcon/>; }
}


class UploadedFile extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {};
    this.uploadInputRef = null;
    this.initializeBoundMethods();
  }

  initializeBoundMethods()
  {
    this.handleDelete = () => {
      this.props.onDelete(this.props.file.index);
    }

    this.handleReplace = (files) => {
      const newFiles = [];
      for (let i = 0; i < files.length; i++) newFiles.push(files[i]);
      this.props.onReplace(this.props.file.index, newFiles);
    }

    this.handleFileSelect = (e) => {
      this.handleReplace(e.target.files);  
    }
  }

  render()
  {
    const {onReplace} = this.props;
    const disableDragAndDrop = this.props.disableButtons || onReplace === null;

    return (
      <React.Fragment>
        <FileDragAndDrop disabled={disableDragAndDrop} onFileDrop={this.handleReplace}>
          <li><Divider/></li>
          {this.UploadedItem}
        </FileDragAndDrop>
        {this.UploadInput}
      </React.Fragment>
    );
  }

  get UploadedItem()
  {
    return (
      <ListItem button onClick={() => downloadFile(this.props.file.index)}>
        {this.UploadedItemIcon}
        {this.UploadedItemText}
        {this.UploadedItemActions}
      </ListItem>
    );
  }

  get UploadedItemIcon()
  {
    return (
      <Avatar>
        {getFileIcon(this.props.file)}
      </Avatar>
    );
  }

  get UploadedItemText()
  {
    const {onReplace, file} = this.props;
    const itemWidth = onReplace === null ? 'calc(100% - 48px)' : '100%';

    const props = {
      primary: file.name,
      primaryTypographyProps: {noWrap: true, style: {width: itemWidth}},
      secondary: <Moment format="D MMM YYYY">{file.date}</Moment>
    }

    return <ListItemText {...props}/>;
  }

  get UploadedItemActions()
  {
    return (
      <ListItemSecondaryAction>
        <div>
          {this.DeleteAction}
          {this.ReplaceAction}
        </div>
      </ListItemSecondaryAction>
    );
  }

  get DeleteAction()
  {
    const {handleDelete, props:{disabled}} = this;

    return (
      <Tooltip title="Delete" aria-label="Delete" placement="left">
        <IconButton aria-label="Delete" onClick={handleDelete} disabled={disabled}>
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
    )
  }

  get ReplaceAction()
  {
    if (this.props.onReplace === null) { return null; }

    const {disabled} = this.props;
    const onClick = () => this.uploadInputRef.click();

    return (
      <Tooltip title="Replace" aria-label="Replace" placement="right">
        <IconButton aria-label="Replace" onClick={onClick} disabled={disabled}>
          <ReplaceIcon/>
        </IconButton>
      </Tooltip>
    )
  }

  get UploadInput()
  {
    const inputProps = {
      type: 'file',
      multiple: this.props.multiple,
      style: {visibility: 'hidden'},
      ref: (reference) => { this.uploadInputRef = reference; },
      onChange: this.handleFileSelect
    }

    return <input {...inputProps}/>;
  }
}


export default UploadedFile;