
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react
import {SchedulesPageMachine} from './machine'
import {AppPageModifier} from 'components/pages/app-page-modifier'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'

// redux (selectors)
import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getProctoringMode} from 'redux/reducers/settings/client/selectors'


let SchedulesPage = ({messages, proctoringMode}) =>
{
	const appBarProps = {
		title: messages[MESSAGE_IDS.SCHEDULE.APP_BAR_TITLE],
		welcomeMessage: true,
		logout: proctoringMode == null,
		logo: true
	}

	return (
		<React.Fragment>
			<AppPageModifier id="schedules" appBarProps={appBarProps}/>
			<SchedulesPageMachine/>
		</React.Fragment>
	)
}

// SchedulesPage.propTypes = {
// 	proctoringMode: PropTypes.number.isRequired
// }

const mapStoreToProps = (store) =>
{
	return {
		proctoringMode: getProctoringMode(getClientSettingsData(getSettingsData(store)))
	}
}

SchedulesPage = withMessages(SchedulesPage);
SchedulesPage = connect(mapStoreToProps)(SchedulesPage);

export {SchedulesPage}