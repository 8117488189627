import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { check } from "@xams-utils/check-types";

import { AppPageModifier } from "components/pages/app-page-modifier";

import Fade from "@material-ui/core/Fade";
import withStyles from "@material-ui/core/styles/withStyles";

import { CourseTitle } from "./title";
import { CourseScos } from "./scos";
import { PlaySco } from "./play-sco";
import { CONSTANTS } from "./constants";
import { CourseInfoMessage } from "./info-message";
import { CourseCompleteMessage } from "./complete-message";

const styles = ({ spacing, breakpoints, palette }) => ({
    layout: {
        width: "auto",
        display: "block", // Fix IE11 issue.
        marginLeft: spacing.unit * 2,
        marginRight: spacing.unit * 2,
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 2,
        [breakpoints.up(800 + spacing.unit * 3 * 2)]: {
            width: 800,
            marginLeft: "auto",
            marginRight: "auto",
        },
        [breakpoints.down("xs")]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
});

class Course extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handlePlaySco = this.handlePlaySco.bind(this);
        this.handleCloseSco = this.handleCloseSco.bind(this);
        this.displaySco = this.displaySco.bind(this);
        this.handleReturnToSchedules = this.handleReturnToSchedules.bind(this);


        this.state = {
            display: CONSTANTS.COURSE,
            scoIndex: null,
            showCompletedMessage: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { status: prevStatus } = prevProps;
        const { status } = this.props;

        if (status === 2 && prevStatus !== 2) {
            this.setState({ showCompletedMessage: true });
        }
    }

    handlePlaySco(scoIndex) {
        if (check.assigned(scoIndex)) {
            this.setState({ display: CONSTANTS.SCO, scoIndex });
        }
    }

    handleCloseSco() {
        this.setState({ display: CONSTANTS.COURSE, scoIndex: null });
    }

    checkIfCourseHasJustCompleted() {
        const { completed } = this.state;
        if (completed) return false;

        const { scos } = this.props;
        return scos.every((sco) => sco.status === 2);
    }

    handleReturnToSchedules() {
        const { history } = this.props;

        history.replace("/schedules");
    }

    get CourseContents() {
        const { display } = this.state;

        return display === CONSTANTS.SCO ? this.displaySco() : this.Course;
    }

    get Course() {
        const { showCompletedMessage } = this.state;

        const {
            name,
            status,
            info,
            dateStarted,
            dateEnded,
            classes,
            scos,
            updateCourseData,
            attemptId: courseAttemptId,
        } = this.props;

        const titleProps = {
            name,
            info,
            status,
            dateStarted,
            dateEnded,
            courseAttemptId,
            onReturnToSchedules: this.handleReturnToSchedules,
            updateCourseData,
            page: CONSTANTS.COURSE,
        };

        const infoProps = {
            name,
            info,
            showCompletedMessage,
        };

        const completeProps = {
            name,
            showCompletedMessage,
        };

        const scosProps = {
            onPlaySco: this.handlePlaySco,
            updateCourseData,
            showCompletedMessage,
            scos,
        };

        return (
            <div className={classes.layout}>
                <Fade in timeout={1000}>
                    <React.Fragment>
                        <CourseTitle {...titleProps} />
                        <CourseCompleteMessage {...completeProps} />
                        <CourseScos {...scosProps} />
                    </React.Fragment>
                </Fade>
            </div>
        );
    }

    displaySco() {
        const { scoIndex } = this.state;
        if (!check.assigned(scoIndex)) return null;

        const {
            scos,
            name,
            dateStarted,
            dateEnded,
            status,
            attemptId,
            updateCourseData,
        } = this.props;
        //debugger;
        const scoData = scos[scoIndex];

        const _courseData = { name, dateStarted, dateEnded, status, attemptId };
        const scoProps = {
            courseData: _courseData,
            updateCourseData,
            onCloseSco: this.handleCloseSco,
            onReturnToSchedules: this.handleReturnToSchedules,
            ...scoData,
        };

        return <PlaySco {...scoProps} />;
    }

    render() {
        const { name } = this.props;

        const appBarProps = {
            title: name,
            logout: true,
            logo: true,
            loadingTitle: false,
        };

        return (
            <React.Fragment>
                <AppPageModifier id="course" appBarProps={appBarProps} />
                {this.CourseContents}
            </React.Fragment>
        );
    }
}

Course = withStyles(styles)(Course);
Course = withRouter(Course);

export { Course };
