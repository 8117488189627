
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {withAppPage} from 'components/contexts/app_page'


class AppPageModifier extends React.Component
{
	componentDidMount()
	{
		// console.log("MOUNTING: " + this.props.id);

		const {appBarProps, loading, appPage} = this.props;
		if (appBarProps) { appPage.pushAppBarState(appBarProps); }
		appPage.pushLoadingState(loading || false);
	}

	componentWillUnmount()
	{
		// console.log("UNMOUNTING: " + this.props.id);

		const {appBarProps, loading, appPage} = this.props;
		if (appBarProps) { appPage.revokeAppBarState(); }
		appPage.revokeLoadingState();
	}

	render() { return null; }
}

AppPageModifier.propTypes = {
	appBarProps: PropTypes.object,
	loading: PropTypes.bool,
	appPage: PropTypes.shape({
		pushAppBarState: PropTypes.func.isRequired,
		pushLoadingState: PropTypes.func.isRequired,
		revokeAppBarState: PropTypes.func.isRequired,
		revokeLoadingState: PropTypes.func.isRequired
	})
}


AppPageModifier = withAppPage(AppPageModifier);


export {AppPageModifier}