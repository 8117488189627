import React, { Component } from "react";
import { connect } from "react-redux";

import { getSessionData } from "redux/reducers/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import {
    getUserId,
    getUserGuid,
    getFirstName,
    getLastName,
} from "redux/reducers/session/user/selectors";
import {
    getScormServer,
    getUseLocalScormApi,
} from "redux/reducers/settings/app/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getSettingsData } from "redux/reducers/selectors";

import { eLearningApi } from "libs/api/interface/api-e-learning";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import { LoadingSpinner } from "components/presentation/loading-spinner";
import { Console, LOG_TYPES } from 'custom/console';

import { CourseTitle } from "./title";
import { getInitialScormData, getCourseDataFromSaveResponse } from "../helper";
import { SaveCounter } from "./save-counter";
import {openFullScreen, closeFullScreen} from './full-screen'

const styles = ({ breakpoints, spacing }) => ({
    layout: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    iframeContainer: {
        flex: 1,
        display: "flex",
        backgroundColour: "lime",
    },
    iframe: {
        margin: `0px ${spacing.unit * 2}px`,
        display: "block",
        width: `calc(100% - ${spacing.unit * 4}px)`,
        height: `calc(100% - ${spacing.unit * 2}px)`,
        [breakpoints.down("xs")]: {
            width: "100%",
            margin: "0px",
            height: "56.25vw",
        },
    },
    courseTitle: {
        margin: `${spacing.unit * 2}px ${spacing.unit * 2}px 0px ${
            spacing.unit * 2
        }px`,
        [breakpoints.down("xs")]: {
            margin: `${spacing.unit * 2}px 0px 0px 0px`,
        },
    },
    visible: {
        visibility: "visible",
    },
    hidden: {
        visibility: "hidden",
    },
});

const CONSTANTS = {
    WINDOW: "window",
    IFRAME: "iframe",
    SAVE_DATA: "saveData",
    SAVE_DATA_CLOSE: "saveDataClose",
    CLOSE: "close",
    READY: "playerReady",
    SET_PARAMETERS: "setParameters",
};

class PlaySco extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleMessageFromPlayer = (evt) => {
            const { data } = evt;
            const { type } = data;

            if (type === CONSTANTS.READY) {
                const message = {
                    type: CONSTANTS.SET_PARAMETERS,
                    parameters: this.getPlayerParameters(),
                };
                this.sendMessageToPlayer(message);
                this.loadingTimer = setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            } else if (type === "saveData") {
                this.saveScormData(data.data);
            } else if (type === "saveDataClose") {
                debugger;
                this.saveScormData(data.data, true);
            } else if (type === "close") {
                this.closePlayer();
            }
        };

        this.sendMessageToPlayer = (data = {}) => {
            //window.parent.postMessage(data, "*");
            if (this.scormType === CONSTANTS.IFRAME) {
                this.waitIframe(data);
            } else this.scoPlayer.postMessage(data, "*");
        };

        this.waitIframe = (data) => {
            if (!this.scoPlayer) {
                Console.log('No scoPlayer', LOG_TYPES.TEMP);
                Console.log(data, LOG_TYPES.TEMP);
                // this.waitIframeTimer = setTimeout(() => {
                //     this.waitIframe(data);
                // }, 5000);
            } else {
                this.scoPlayer.contentWindow.postMessage(data, "*");
            }
        };

        this.resizeIframe = () => {
            this.setState({fullScreen: true});
            openFullScreen(this.scoPlayer);
        }

        this.saveScormData = this.saveScormData.bind(this);

        this.scormType = CONSTANTS.IFRAME;
        this.saveCounter = new SaveCounter();

        this.state = {
            initialData: null,
            waitingToClose: false,
            loading: true,
            fullScreen: false
        };
    }

    // componentDidMount() {
    //     if (this.scormType === CONSTANTS.WINDOW) this.displayWindow();
    // }

    componentDidMount() {
        const { courseData, attemptId: scoAttemptId, userId } = this.props;
        const { attemptId: courseAttemptId } = courseData;

        const onSuccess = (response) => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse) {
                const {
                    updateCourseData,
                    userName: name,
                    userId: id,
                } = this.props;
                const userInfo = { name, id };
                const { initialData, courseData } = getInitialScormData(
                    parsedResponse,
                    userInfo
                );

                this.setState({ initialData }, () => {
                    if (courseData) updateCourseData(courseData);
                });
            }
        };

        const onFail = (data) => {
            debugger;
        };

        window.addEventListener("message", this.handleMessageFromPlayer);

        eLearningApi.getScormData(scoAttemptId).then(onSuccess, onFail);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessageFromPlayer);
        window.clearTimeout(this.waitIframeTimer);
        window.clearTimeout(this.loadingTimer);
    }

    saveScormData = (data, close = false) => {
        const localSaveCounter = parseInt(this.saveCounter.getCounter());

        const onSuccess = (response, close = false) => {
            this.saveCounter.removeCounter(localSaveCounter);
            const { waitingToClose } = this.state;
            const needToClosePlayer = close || waitingToClose;
            const parsedResponse = JSON.parse(response);

            if (parsedResponse) {
                const courseData = getCourseDataFromSaveResponse(
                    parsedResponse
                );

                if (courseData) {
                    const { updateCourseData } = this.props;
                    updateCourseData(courseData).then(() => {
                        if (needToClosePlayer) this.closePlayer();
                    });
                } else {
                    if (needToClosePlayer) this.closePlayer();
                }
            }
        };

        const onFail = (response) => {
            this.saveCounter.removeCounter(localSaveCounter);
            debugger;
        };

        const { attemptId: scoAttemptId } = this.props;

        eLearningApi.saveScormData(scoAttemptId, { data }).then((response) => {
            onSuccess(response, close);
        }, onFail);
    };

    closePlayer() {
        const {fullScreen} = this.state;
        if (fullScreen) closeFullScreen(this.scoPlayer);
        if (this.saveCounter.isEmpty()) {
            const { onCloseSco } = this.props;
            onCloseSco();
        } else {
            this.setState({ waitingToClose: true, fullScreen: false });
        }
    }

    getPlayerUrl() {
        const version = "0.000003";
        const player =
            this.scormType === CONSTANTS.WINDOW
                ? "sco-player"
                : "sco-iframe-player";
        const { scormServer } = this.props;
        // http://localhost/projects/sco_player
        const url = `${scormServer}/player/${player}.html?v=${version}`;

        return url;
    }

    getPlayerParameters() {
        const { initialData } = this.state;
        const { href, height } = this.props;

        return {
            initialData,
            url: href,
            height,
            debug: 1,
        };
    }

    displayIframe() {
        const { initialData, waitingToClose } = this.state;
        if (!initialData) {
            return <LoadingSpinner color="primary" />;
        }

        const { classes } = this.props;
        const url = this.getPlayerUrl();
        const divClass = `${classes.iframeContainer} ${
            waitingToClose ? classes.hidden : classes.visible
        }`;

        return (
            <div className={divClass}>
                <iframe
                    ref={(frame) => {
                        this.scoPlayer = frame;
                    }}
                    className={classes.iframe}
                    frameBorder={0}
                    height={"100%"}
                    src={url}
                />
            </div>
        );
    }

    displayWindow() {
        const url = this.getPlayerUrl();

        this.scoPlayer = window.open(url, "_blank");
        this.scoPlayer.focus();
    }

    get FullButton() {
        const buttonProps = {
            variant: "contained",
            color: "secondary",
            size: "small",
            key: 'viewFullScreen',
            onClick: () => this.resizeIframe(0),
        };

        return <Button {...buttonProps}>View Full Screen</Button>;
    }    

    get Buttons() {
        return [this.FullButton];
    }    

    render() {
        const { waitingToClose, loading } = this.state;
        const {
            name,
            status,
            dateStarted,
            dateEnded,
            classes,
            courseData,
        } = this.props;
        const { name: courseName } = courseData;

        let message = null;
        if (loading) message = "Loading";
        else if (waitingToClose) message = "Updating";

        const courseProps = {
            name,
            status,
            dateStarted,
            dateEnded,
            courseName,
            message,
            buttons: this.Buttons
        };

        return (
            <div className={classes.layout}>
                <div ref={this.titleRef} className={classes.courseTitle}>
                    {<CourseTitle {...courseProps} />}
                </div>
                {this.scormType === CONSTANTS.IFRAME && this.displayIframe()}
            </div>
        );
    }
}

const mapStoreToProps = (store) => {
    const userSessionData = getUserSessionData(getSessionData(store));
    const appSettingsData = getAppSettingsData(getSettingsData(store));
    const firstName = getFirstName(userSessionData);
    const lastName = getLastName(userSessionData);
    const userName = `${firstName} ${lastName}`;
    return {
        userId: getUserId(userSessionData),
        userGuid: getUserGuid(userSessionData),
        userName,
        scormServer: getScormServer(appSettingsData),
    };
};

PlaySco = connect(mapStoreToProps)(PlaySco);

PlaySco = withStyles(styles)(PlaySco);

export { PlaySco };
