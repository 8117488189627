
// npm
import chroma from 'chroma-js'

// xams-utils
import {check} from '@xams-utils/check-types'

// helpers
import {ColorHelper} from 'custom/color-helper'

// constants
import {COLOR_NAMES} from 'constants/theme'


const STEP = 0.05;
const MIN_CONTRAST = 4.5;
const MAX_CONTRAST = 6;

const colorNames = Object.values(COLOR_NAMES);

const requiredColorNames = [
	COLOR_NAMES.PRIMARY,
	COLOR_NAMES.SECONDARY,
	COLOR_NAMES.BACKGROUND
];


const validateConfig = (config) =>
{
	if (!check.object(config)) {
		throw "Theme config must be an object";
	}

	colorNames.forEach(colorName => {
		const color = config[colorName];

		if (check.assigned(color) && !chroma.valid(color)) {
			throw `Theme '${colorName} colour' is invalid`;
		}
	});

	if (requiredColorNames.every(colorName => !colorName)) {
		throw "Theme must have 1 of the following: primary/secondary/background";
	}
}


class ThemeConfig
{
	constructor(config)
	{
		validateConfig(config);
		this.initializeBaseColors(config);

		this.leadingColorName = this.getLeadingColorName();														// When generating background colour, we contrast against the 'leading colour' (either primary or secondary)
		this.trailingColorName = this.getTrailingColorName(this.leadingColorName);		// The trailing colour then becomes the opposite of the leading colour (again, either primary or secondary)
	}

	initializeBaseColors({primary, secondary, error, background})
	{
		this.primary = primary && chroma(primary).hex();
		this.secondary = secondary && chroma(secondary).hex();
		this.error = error && chroma(error).hex();
		this.background = background && chroma(background).hex();
	}

	getLeadingColorName()
	{
		const {PRIMARY, SECONDARY} = COLOR_NAMES;
		return this.background || this.primary ? PRIMARY : SECONDARY;
	}

	getTrailingColorName(leadingColorName)
	{
		const {PRIMARY, SECONDARY} = COLOR_NAMES;
		return leadingColorName === PRIMARY ? SECONDARY : PRIMARY;
	}

	generateTheme()
	{
		this.offsetExtremeColors();
		this.generateMissingColors();

		return {
			primary: this.primary,
			secondary: this.secondary,
			error: this.error,
			background: this.background
		}
	}

	// NOTE:
	// Each theme colour has 3 variations: light, main (supplied), dark
	// To ensure that the 'main' colour can always be distinguished from light & dark,
	// we need to brighten/darken the supplied colour ONLY if it is white/black.
	offsetExtremeColors()
	{
		colorNames.forEach(colorName => {
			this.tryOffsetExtremeColor(colorName);
		});
	}

	tryOffsetExtremeColor(colorName)
	{
		const colorValue = this[colorName];
		if (!colorValue) { return; }

		const color = chroma(colorValue);
		const lightness = color.get('hsl.l');

		if (lightness === 1) {
			this[colorName] = color.darken(STEP).hex();
		}
		else if (lightness === 0) {
			this[colorName] = color.brighten(STEP).hex();
		}
	}

	generateMissingColors()
	{
		if (!this.background) {
			this.generateBackgroundColor();
		}

		if (!this[this.leadingColorName]) {
			this.generateLeadingColor();
		}

		if (!this[this.trailingColorName]) {
			this.generateTrailingColor();
		}

		if (!this.error) {
			this.generateErrorColor();
		}
	}

	generateBackgroundColor()
	{
		const contrastingColor = this[this.leadingColorName];
		this.background = new ColorHelper(contrastingColor).contrast();
	}

	generateLeadingColor()
	{
		const colorHelper = new ColorHelper(this.background);
		this[this.leadingColorName] = colorHelper.contrast();
	}

	generateTrailingColor() // opposite of leading
	{
		const {leadingColorName, trailingColorName} = this;
		const leadingColor = this[leadingColorName];

		const colorAfterMix = chroma.mix(leadingColor, this.background);
		const colorHelper = new ColorHelper(colorAfterMix);
		const contrastingColor = this.background;
		this[trailingColorName] = colorHelper.contrast({contrastingColor});
	}

	generateErrorColor()
	{
		const colorAfterMix = chroma.mix('red', this.background);
		const colorHelper = new ColorHelper(colorAfterMix);
		this.error = colorHelper.contrast({contrastingColor: this.background});
	}
}


export {ThemeConfig}