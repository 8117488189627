
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

// react
import {MessagesContext} from './context'

// redux (selectors)
import {getMessagesData} from 'redux/reducers/selectors'
import {getMessageIds, getMessageById} from 'redux/reducers/messages/complex-selectors'


// Not connected to store
// --------------------------------------------------------------------------

let MessagesProvider = ({children, messages}) => (
	<MessagesContext.Provider value={messages}>
		{children}
	</MessagesContext.Provider>
)

MessagesProvider.propTypes = {
	children: PropTypes.node.isRequired,
	messages: PropTypes.objectOf(PropTypes.string.isRequired).isRequired
}


// Connected to store
// --------------------------------------------------------------------------

const getMessagesContext = (messagesData, messageIds) =>
{
	const messages = {};

	messageIds.forEach(id => {
		messages[id] = getMessageById(messagesData)(id) || "MESSAGE UNDEFINED";
	});

	return messages;
}

const messagesContextSelector = createSelector(
	(messagesData) => messagesData,
	(_, messageIds) => messageIds,
	getMessagesContext
)

const messageIdsSelector = createSelector(
	getMessagesData,
	getMessageIds
)

const emptyObject = {};

const mapStoreToProps = (store, props) =>
{
	const messagesData = getMessagesData(store);
	if (!messagesData) { return {messages: emptyObject}; }

	const messageIds = messageIdsSelector(store);
	const messages = messagesContextSelector(messagesData, messageIds);

	return {messages}
}

MessagesProvider = connect(mapStoreToProps)(MessagesProvider);


// Export
// --------------------------------------------------------------------------
export {MessagesProvider}