import check from "check-types";

const isEmptyRow = (row) => {
    if (check.nonEmptyObject(row)) {
        const { values } = row;

        return check.nonEmptyArray(values)
            ? values.every((value) => !check.assigned(value))
            : false;
    }

    return false;
};

export { isEmptyRow };
