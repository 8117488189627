
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (actions)
import {clearSchedules, clearElearning} from './actions'


// SchedulesClearer (not connected to store)
// -----------------------------------------------

class SchedulesClearer extends React.Component
{
	componentDidMount() { this.props.clearSchedules();this.props.clearElearning(); }
	render() { return null; }
}

SchedulesClearer.propTypes = {
	clearSchedules: PropTypes.func.isRequired
}


// SchedulesClearer (connected to store)
// --------------------------------------------------

const mapDispatchToProps = (dispatch, {onSuccess}) => ({
	clearSchedules: () => {
		dispatch(clearSchedules());
		onSuccess();
	},
	clearElearning: () => {
		dispatch(clearElearning());
		onSuccess();
	}	
})

SchedulesClearer = connect(undefined, mapDispatchToProps)(SchedulesClearer);


// EXPORT
// -----------------------------------------------
export {SchedulesClearer}