import React from "react";
import { connect } from "react-redux";

import { withMessages } from "components/hocs/messages";

import { isResetScheduleCheatActive } from "redux/reducers/settings/app/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getSettingsData } from "redux/reducers/selectors";

import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Hidden from "@material-ui/core/Hidden";


import { MESSAGE_IDS } from "constants/message-ids";

const schedulesMessageId = MESSAGE_IDS.SCHEDULE.APP_BAR_TITLE;

const styles = ({ spacing, palette, breakpoints }) => ({
    button: {
        [breakpoints.up('sm')]: {
            width: 120,
        },
        [breakpoints.down('xs')]: {
            paddingRight: 0,
            minWidth: "0px !important",
        },        
        backgroundColor: palette.secondary.main + "!important",
        color: palette.secondary.contrastText + "!important",
    },
    leftIcon: {
            marginRight: spacing.unit,
    },
});

let GoSchedulesButton = (props) => {
    const { classes, useResetScheduleCheat, onClick, messages } = props;
    const title = messages[schedulesMessageId];
    const buttonProps = {
        variant: "contained",
        color: useResetScheduleCheat ? "primary" : null,
        size: "small",
        className: classes.button,
        onClick,
    };

    return (
        <Button {...buttonProps}>
            {!useResetScheduleCheat && (
                <ArrowBackIcon className={classes.leftIcon} />
            )}
            <Hidden xsDown>{title}</Hidden>
        </Button>
    );
};

const mapStoreToProps = (store) => ({
    useResetScheduleCheat: isResetScheduleCheatActive(
        getAppSettingsData(getSettingsData(store))
    ),
});

GoSchedulesButton = connect(mapStoreToProps)(GoSchedulesButton);

GoSchedulesButton = withStyles(styles)(GoSchedulesButton);

GoSchedulesButton = withMessages(GoSchedulesButton);

export { GoSchedulesButton };
