
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {Popup} from 'components/layout/popup'
import {ConfirmButton} from './buttons/confirm'
import {withMessages} from 'components/hocs/messages'

// utils
import {detokenizeMessage} from 'utils/detokenize-message'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'
import {MESSAGE_TOKENS} from 'constants/message-tokens'


const finishPartTitle = MESSAGE_IDS.EXAM.FINISH_PAPER_PART_ALERT_TITLE;
const finishPartText = MESSAGE_IDS.EXAM.FINISH_PAPER_PART_ALERT_INFO;


let PartFinishedPopup = ({onConfirm, messages, data}) =>
{
	const tokenMappings = {
		[MESSAGE_TOKENS.CURRENT_PAPER_PART]: data.paperPartName,
		[MESSAGE_TOKENS.NEXT_PAPER_PART]: data.nextPaperPartName
	}

	const popupTitle = messages[finishPartTitle];
	const popupText = messages[finishPartText];

	const props = {
		title: detokenizeMessage(popupTitle, tokenMappings),
		content: {text: detokenizeMessage(popupText, tokenMappings)},
		buttons: [<ConfirmButton onClick={onConfirm}/>]
	}

	return <Popup {...props}/>;
}

PartFinishedPopup.propTypes = {
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[finishPartTitle]: PropTypes.string.isRequired,
		[finishPartText]: PropTypes.string.isRequired
	}).isRequired
}


PartFinishedPopup = withMessages(PartFinishedPopup);


export {PartFinishedPopup}