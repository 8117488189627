import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getProctorioData } from "redux/reducers/selectors";
import { getParent, getDebugMode, getClientName, getUserInfo1, getUserInfo2 } from "redux/reducers/proctorio/selectors";
import {encodeString} from "../helper"


class ProctorioEndPage extends Component {

    componentDidMount() {
        const {clientName, userInfo1, userInfo2, history} = this.props;
        const url = `/proctorio-return/${clientName}/${encodeString(userInfo1)}/${encodeString(userInfo2)}`;

        history.push(url);
    }


    render() {
        return null;
    }
}

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);

    return {proctorioActive: getParent(proctorioData),
        clientName: getClientName(proctorioData),
        userInfo1: getUserInfo1(proctorioData),
        userInfo2: getUserInfo2(proctorioData),
        proctorioDebug: getDebugMode(proctorioData)
    };
};

ProctorioEndPage = connect(mapStoreToProps)(ProctorioEndPage);

ProctorioEndPage = withRouter(ProctorioEndPage);

export { ProctorioEndPage };
