import moment from "moment";
import { check } from "@xams-utils/check-types";

import { ACTIVITIES } from "./activity-logger";
import { dbStorageApi, KEYS } from "libs/browser_storage/db-storage-api";
import { assessmentApi } from "libs/api/interface/api-assessment";
import { activityLoggerSent } from "./activity-logger-sent";
import { displayToConsole } from "./activity-logger-helper";

const isActivityOnAnotherDay = (activity) => {
    const date1 = new moment();
    const date2 = new moment(activity.id);

    return date1.date() !== date2.date();
};

const hasActivityDateExpired = (activity, currentDate = null, expired = 90) => {
    const { id } = activity;

    const expiredDate = getExpiredDate(currentDate, expired);
    const activityDate = new moment(id);

    // console.log(
    //     activityDate.format("DD/MM/YY HH:mm:ss:SSS"),
    //     "<",
    //     expiredDate.format("DD/MM/YY HH:mm:ss:SSS"),
    //     activityDate < expiredDate
    // );

    return activityDate < expiredDate;
};

const getExpiredDate = (currentDate, expired) => {
    const date = currentDate ? new moment(currentDate) : new moment();
    //return date.subtract(12, "hours");
    return date.subtract(expired, "days");
};

const removeFormRunGuid = (activity) => {
    const _activity = {};
    Object.keys(activity).forEach((key) => {
        if (key !== "formRunGuid") _activity[key] = activity[key];
    });
    return _activity;
};

const maintainActivityLog = (activities) => {
    return new Promise((resolve) => {
        const totalRecords = parseInt(activities.length);

        displayToConsole("Maintain activity log");
        displayToConsole("totalRecords", totalRecords);

        if (totalRecords === 0) {
            resolve();
        } else {
            const formRunGuids = {};
            let formRunGuidFound = false;

            activities.forEach((activity) => {
                const { formRunGuid } = activity;

                if (!activityLoggerSent.hasBeenSent(formRunGuid)) {
                    if (formRunGuids[formRunGuid]) {
                        formRunGuids[formRunGuid].activities.push(
                            removeFormRunGuid(activity)
                        );
                    } else {
                        if (isActivityOnAnotherDay(activity)) {
                            formRunGuidFound = true;
                            formRunGuids[formRunGuid] = {
                                activities: [removeFormRunGuid(activity)],
                            };
                        }
                    }
                    if (
                        formRunGuids[formRunGuid] &&
                        activity.type === ACTIVITIES.FINISH_EXAM
                    ) {
                        formRunGuids[formRunGuid].finish = true;
                    }
                }
            });

            uploadOutstandingActivities(formRunGuids).then(() => {
                deleteExpiredExams(activities).then(() => resolve());
            });
        }
    });
};

const uploadOutstandingActivities = (formRunGuids) => {
    return new Promise((resolve) => {
        displayToConsole("uploadOutstandingActivities");
        displayToConsole("formRunGuids", Object.keys(formRunGuids).length, formRunGuids);

        if (Object.keys(formRunGuids).length === 0) {
            resolve();
        } else {
            let formRunGuidsChecked = 0;
            let deletedActivities = [];

            const formRunGuidsToCheck = Object.keys(formRunGuids).reduce(
                (total, formRunGuid) => {
                    if (!formRunGuids[formRunGuid].finish) {
                        total++;
                    }
                    return total;
                },
                0
            );

            displayToConsole("formRunGuidsToCheck", formRunGuidsToCheck);

            if (formRunGuidsToCheck === 0) {
                resolve();
            } else {
                Object.keys(formRunGuids).forEach((formRunGuid) => {
                    if (!formRunGuids[formRunGuid].finish) {
                        const payload = {
                            formRunGuid,
                            activities: formRunGuids[
                                formRunGuid
                            ].activities.map((activity) => {
                                const { id, type, value } = activity;
                                return {
                                    id,
                                    type,
                                    value,
                                };
                            }),
                        };

                        const onComplete = () => {
                            formRunGuidsChecked++;
                            
                            displayToConsole("onComplete", formRunGuidsChecked,formRunGuidsToCheck);

                            if (formRunGuidsChecked === formRunGuidsToCheck) {
                                resolve(deletedActivities);
                            }
                        };

                        const onSuccess = () => {
                            return activityLoggerSent
                                .sent(formRunGuid)
                                .then(() => {
                                    deletedActivities = deletedActivities.concat(
                                        formRunGuids[formRunGuid].activities
                                    );
                                    onComplete();
                                });
                        };

                        displayToConsole("saveActivityLog", formRunGuid);

                        assessmentApi
                            .saveActivityLog(payload)
                            .then(onSuccess, onComplete);
                    }
                });
            }
        }
    });
};

const deleteExpiredExams = (activities) => {
    return new Promise((resolve) => {
        displayToConsole("deleteExpiredExams");

        const formRunGuids = {};
        const alreadyCheckedFormRunGuids = {};
        const currentDate = new moment();
        let recordsToDelete = false;

        activities.forEach((activity) => {
            const { formRunGuid } = activity;

            if (formRunGuids[formRunGuid]) {
                formRunGuids[formRunGuid].activities.push(activity);
            } else if (!alreadyCheckedFormRunGuids[formRunGuid]) {
                if (hasActivityDateExpired(activity, currentDate)) {
                    recordsToDelete = true;
                    formRunGuids[formRunGuid] = {
                        activities: [activity],
                    };
                } else {
                    alreadyCheckedFormRunGuids[formRunGuid] = true;
                }
            }
        });

        displayToConsole("recordsToDelete", recordsToDelete);

        if (!recordsToDelete){
            resolve();
        }
        else {
            let deletedActivities = [];

            Object.keys(formRunGuids).forEach((formRunGuid) => {
                deletedActivities = deletedActivities.concat(
                    formRunGuids[formRunGuid].activities.map((activity) => ({
                        _id: activity.id,
                        _rev: activity._rev,
                        _deleted: true,
                    }))
                );
            });

            displayToConsole("deletedActivities", deletedActivities.length);

            if (deletedActivities.length > 0) {

                dbStorageApi
                    .remove(KEYS.STUDENT_ACTIVITY, deletedActivities)
                    .then(() => {
                        dbStorageApi
                            .compactDB(KEYS.STUDENT_ACTIVITY)
                            .then((result) => {
                                activityLoggerSent
                                    .remove(Object.keys(formRunGuids))
                                    .then(() => resolve());
                            });
                    });
            } else {
                resolve();
            }
        }
    });
};

export { maintainActivityLog };
