
// redux (action-types)
import {SET_FORMRUN_GUID} from 'redux/reducers/exam/action-types'
import {SET_COMMENCED} from 'redux/reducers/schedules/schedule/action-types'


const setFormRunGuid = (value) => ({type: SET_FORMRUN_GUID, value});
const commenceSchedule = (guid) => ({type: SET_COMMENCED, guid});


export {setFormRunGuid, commenceSchedule}