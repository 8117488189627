
// npm
import chroma from 'chroma-js'
import {Map as ImmutableMap, fromJS} from 'immutable'

// xams-utils
import {check} from '@xams-utils/check-types'

// internal
import {ThemeConfig} from 'utils/theme-config'

// redux (action-types)
import * as ACTION_TYPES from './action-types'

// utils
import * as colorHelper from 'custom/color-helper'
import {sessionStorageApi, KEYS} from 'libs/browser_storage/session-storage-api'
import { activityLogger } from "libs/activity_logger/activity-logger";


const ASSISTIVE_THEMES = {
	['Default']: {
		primary: "#1CA286",
		secondary: "#364651"
	},
	['White on Black']: {
		primary: "#FFFFFF",
		background: "#000000"
	},
	['Black on White']: {
		primary: "#000000",
		background: "#FFFFFF"
	},
	['Bright on Black']: {
		primary: "#0090b2",
		background: "#000000"
	},
	['Dark on Bright']: {
		primary: "#1C304A",
		background: "#00CFFF"
	},
	['Black on Yellow']: {
		primary: "#000000",
		background: "#FFFF00"
	},
	['Yellow on Black']: {
		primary: "#FFFF00",
		background: "#000000"
	},
	['White on Purple']: {
		primary: "#FFFFFF",
		background: "#800080"
	},
	['Purple on White']: {
		primary: "#800080",
		background: "#FFFFFF"
	},
	['Light on Medium']: {
		primary: "#83EFFF",
		background: "#046B99"
	},
	['Medium on Light']: {
		primary: "#046B99",
		background: "#83EFFF"
	},
	['Medium on White']: {
		primary: "#046B99",
		background: "#FFFFFF"
	},
	['Black on Purple']: {
		primary: "#000000",
		background: "#800080"
	}
}


const extractThemesFromPrimarySecondaryColors = ({primaryColour, secondaryColour}) =>
{
	if (!primaryColour || !secondaryColour) {
		return;
	}

	return {
		...ASSISTIVE_THEMES,
		Default: {
			primary: primaryColour,
			secondary: secondaryColour,
			background: 'white'
		}
	}
}

const extractThemes = (data) => 																									// data = action/queryStringObject/sessionStorage
{
	const themes = data.themes ||
	 	extractThemesFromPrimarySecondaryColors(data) ||
		ASSISTIVE_THEMES;

	Object.keys(themes).forEach(themeName => {
		const themeConfig = new ThemeConfig(themes[themeName]);
		themes[themeName] = themeConfig.generateTheme();
	});

	return themes;
}



// CREATE SETTINGS
// ---------------------------------------------------------------------------

const handleSettingsCreation = (action) =>
{
	const data = {
		logoUrl: action.logo,
		themes: extractThemes(action),
		appBarLogoUrl: action.appBarLogo,
		uploadFileTypesAllowed: ".doc,.docx,.rtf,.odt,.wps,.xls,.xlsx,.csv,.xml,.xlr,.pptx,.ppt,.pps,.jpeg,.jpg,.gif,.bmp,.png,.tiff,.avi,.mp4,.mp3,.wav,.m4a,.wmv,.flv,.m4v,.mov,.mpg,.mpeg,.mkv,.pdf,.txt,.dat,.odp".split(','), //action.allowedFileTypes
		proctoringMode: action.proctoringMode,
		previewMode: action.previewMode,
		demoMode: action.demoMode,
		canDownloadResult: action.downloadResult,
		proctoringType: action.proctoringType,
		proctoringSessionID: action.proctoringSessionID,
		enableForgottenPassword: action.enableForgottenPassword,
		legacySingleSignon: action.legacySingleSignon,
		adminUrl: action.adminUrl
	}

	sessionStorageApi.saveDataTo(KEYS.CLIENT_SETTINGS, data);
	activityLogger.setPreviewMode(data.previewMode);
	
	const returnValue = ImmutableMap(fromJS(data));
	
	return returnValue
}


// REMOVE SETTINGS
// ---------------------------------------------------------------------------

const handleSettingsRemoval = () =>
{
	sessionStorageApi.resetDataFor(KEYS.CLIENT_SETTINGS);
	return ImmutableMap();
}


// LOAD SETTINGS
// ---------------------------------------------------------------------------

const loadFromQueryString = () =>
{
	const urlParameters = new URLSearchParams(window.location.search);
	const externalSessionData = urlParameters.get('x');
	if (!externalSessionData) { return; }

	const parsedSessionData = JSON.parse(externalSessionData);
	const data = parsedSessionData.settings;
	data.themes = extractThemes(data);
	sessionStorageApi.saveDataTo(KEYS.CLIENT_SETTINGS, data);

	return data;
}

const handleSettingsLoad = () =>
{
	const key = KEYS.CLIENT_SETTINGS;
	const data = loadFromQueryString() || sessionStorageApi.retrieveDataFrom(key);

	// TEMP MB 07/09/20, REMOVED 27/10
	// if (data.demoMode === '1'){
	// 	data.downloadResult = '1';
	// }

	activityLogger.setPreviewMode(data.previewMode);
	return ImmutableMap(fromJS(data));
}
// ---------------------------------------------------------------------------


const CLIENT_SETTINGS_REDUCER = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.CREATE_SETTINGS:
			return handleSettingsCreation(action);
		case ACTION_TYPES.REMOVE_SETTINGS:
			return handleSettingsRemoval();
		case ACTION_TYPES.LOAD_SETTINGS:
			return handleSettingsLoad();
		case ACTION_TYPES.SET_NOTIFY_IMMEDIATELY_ON_LOST_CONNECTION:
			return state.set('notifyImmediatelyOnLostConnection', action.value === "1");
		case ACTION_TYPES.SET_CALCULATOR_EVALUATION_MODE:
			return state.set('calculatorEvaluationMode', check.string(action.value) ? parseInt(action.value, 10) : undefined);
		case ACTION_TYPES.SET_CONFIRM_DOB_MODE:
			return state.set('confirmDobMode', check.string(action.value) ? parseInt(action.value, 10) : undefined);
		case ACTION_TYPES.SET_CONFIRM_LEARNER_IDENTIFIER_MODE:
			return state.set('confirmLearnerIdentifierMode', check.string(action.value) ? parseInt(action.value, 10) : undefined);			
		case ACTION_TYPES.SET_HIDE_SECTION_NAMES_IN_PLAYER:
			return state.set('hideSectionNamesInPlayer', check.string(action.value) ? parseInt(action.value, 10) === 1 : false);
		case ACTION_TYPES.SET_REMEMBER_SCENARIO:
			return state.set('rememberScenario', check.string(action.value) ? parseInt(action.value, 10) === 1 : false);
		case ACTION_TYPES.SET_UPLOAD_FILE_TYPES:{
			let uploadFileTypes=".doc,.docx,.rtf,.odt,.wps,.xls,.xlsx,.csv,.xml,.xlr,.pptx,.ppt,.pps,.jpeg,.jpg,.gif,.bmp,.png,.tiff,.avi,.mp4,.mp3,.wav,.m4a,.wmv,.flv,.m4v,.mov,.mpg,.mpeg,.mkv,.pdf,.txt,.dat,.odp,.zip".split(',');

			if (check.array(action.value)) {
				uploadFileTypes=action.value;
			} else if (check.nonEmptyString(action.value)) {
				uploadFileTypes = action.value
					.split(",")
					.filter((item) => check.nonEmptyString(item))
					.map((item) => item.trim());
			}

			return state.set('uploadFileTypes', uploadFileTypes);
		}
		case ACTION_TYPES.SET_RESULT_FEEDBACK_MASK:{
			return state.set('resultFeedbackMask', check.nonEmptyString(action.value) ? parseInt(action.value, 10) : -1);
		}		
		case ACTION_TYPES.SET_INTEGRITY_ADVOCATE_APP_ID:{
			return state.set('integrityAdvocateAppId', check.nonEmptyString(action.value) ? action.value : "");
		}
		case ACTION_TYPES.SET_ORG_FULL_SCREEN_EXAM:
			return state.set('fullScreenExam', check.string(action.value) ? parseInt(action.value, 10) === 1 : false);
		case ACTION_TYPES.SET_ORG_CALCULATOR_EXTRA_BUTTONS_FLAG:
			return state.set(
				"calculatorExtraButtonsFlag",
				check.nonEmptyString(action.value)
					? parseInt(action.value, 10)
					: 0
			);									
		default:
			return state;
	}
}


export {CLIENT_SETTINGS_REDUCER}