
// npm
import {milliseconds, seconds} from 'time-convert'

// redux (action-types)
import {SET_CURRENT_QUESTION_ID} from 'redux/reducers/exam/content/action-types'

import {
	SET_EXAM_TIME_REMAINING,
	SET_READING_TIME_REMAINING as SET_EXAM_READING_TIME_REMAINING,
	INCREMENT_EXAM_ELAPSED_TIME	
} from 'redux/reducers/exam/time/action-types'

import {
	SET_COMPLETE,
	SET_TIME_REMAINING as SET_SECTION_TIME_REMAINING,
	SET_READING_TIME_REMAINING as SET_SECTION_READING_TIME_REMAINING,
} from 'redux/reducers/exam/content/sections/section/action-types'


const setCurrentQuestionId = (value) => ({
	type: SET_CURRENT_QUESTION_ID,
	value
});

const setPaperPartComplete = (id) => ({
	type: SET_COMPLETE,
	id
})


const setExamTimeRemaining = (value) => ({
	type: SET_EXAM_TIME_REMAINING,
	value: seconds.from(milliseconds)(value)
});

const setExamReadingTimeRemaining = (value) => ({
	type: SET_EXAM_READING_TIME_REMAINING,
	value: seconds.from(milliseconds)(value)
});

const setSectionTimeRemaining = (value, id) => ({
	type: SET_SECTION_TIME_REMAINING,
	value: seconds.from(milliseconds)(value),
	id
});

const setSectionReadingTimeRemaining = (value, id) => ({
	type: SET_SECTION_READING_TIME_REMAINING,
	value: seconds.from(milliseconds)(value),
	id
});

const incrementExamElapsedTime = (value)=>({
	type: INCREMENT_EXAM_ELAPSED_TIME,
	value: value,	
})


export {
	setCurrentQuestionId,
	setPaperPartComplete,
	setExamTimeRemaining,
	setSectionTimeRemaining,
	setExamReadingTimeRemaining,
	setSectionReadingTimeRemaining,
	incrementExamElapsedTime
}