// npm
import check from "check-types";
import * as Immutable from "immutable";

// redux
import * as ACTION_TYPES from "./action-types";
import { SET_EXAM_DATA } from "redux/reducers/exam/action-types";

const extractTimeData = ({ EXAM }) => {

    const examTime = EXAM["@AllowedTime"];
    const readingTime = EXAM["@ReadingTime"];
    const warningTime = EXAM["@WarningTime"];

    const totalTime = examTime + readingTime;
    const totalTimeRemaining = EXAM["@TimeRemaining"] || totalTime;

    const examTimeRemaining =
        totalTimeRemaining < examTime ? totalTimeRemaining : examTime;
    const readingTimeRemaining =
        totalTimeRemaining < examTime ? 0 : totalTimeRemaining - examTime;

    const elapsedTime = EXAM["@ElapsedTime"]
        ? parseInt(EXAM["@ElapsedTime"], 10) * 1000
        : 0;

    return {
        examTime,
        readingTime,
        warningTime,
        examTimeRemaining,
        readingTimeRemaining,
        canBypassReadingTime: !!EXAM.EXAMPROPERTIES["@AllowBypassReadingTime"],
        elapsedTime,
    }; // note: look into minimizing properties and instead infer these values in selectors
};

const TIME_REDUCER = (state = Immutable.Map(), action) => {
    switch (action.type) {
        case SET_EXAM_DATA:
            return Immutable.Map(extractTimeData(action.data));
        case ACTION_TYPES.SET_EXAM_TIME_REMAINING:
            return state.set(
                "examTimeRemaining",
                action.value < 0 ? 0 : action.value
            );
        case ACTION_TYPES.SET_READING_TIME_REMAINING:
            return state.set(
                "readingTimeRemaining",
                action.value < 0 ? 0 : action.value
            );
        case ACTION_TYPES.INCREMENT_EXAM_ELAPSED_TIME: {
            const elapsedTime = state.get("elapsedTime");
            // console.log(
            //     "elapsedTime",
            //     elapsedTime,
            //     action.value,
            //     elapsedTime + action.value
            // );
            return state.set("elapsedTime", elapsedTime + action.value);
        }
        case ACTION_TYPES.SET_EXAM_ELAPSED_TIME:
            return state.set("elapsedTime", action.value);
        default:
            return state;
    }
};

export { TIME_REDUCER };
