
// npm
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react (layout)
import {ExamLayout} from 'components/pages/exam/_layout/layout'
import {Align} from 'components/layout/align'

// react (concrete)
import {OutroTitle} from './outro-title'
import {CloseButton} from './close-button'
import {OutroTextPanel} from './outro-text-panel'
import {LearnerFeedbackPanel} from './learner-feedback-panel'

// redux (selectors)
import {getExamData, getSchedulesData, getProctorioData} from 'redux/reducers/selectors'
import {getVersion} from 'redux/reducers/schedules/schedule/selectors'
import {getGuid, shouldShowFeedback} from 'redux/reducers/exam/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'

import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getProctoringMode} from 'redux/reducers/settings/client/selectors'
import { getParent } from "redux/reducers/proctorio/selectors";


// ExamOutroPage (not connected to store)
// -----------------------------------------------------------------

let ExamOutroPage = ({showCloseButton, showFeedbackPanel, proctorioActive}) =>
{

	const closeButtonProps = {
		proctorio: proctorioActive
	}
	const examLayoutProps = {
		bar: {
			content: (
				<React.Fragment>
					<OutroTitle/>
					{showCloseButton ? <CloseButton {...closeButtonProps}/> : null}
				</React.Fragment>
			)
		},
		centre: {
			content: (
				<Align top>
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						{showFeedbackPanel ? <LearnerFeedbackPanel/> : null}
						<OutroTextPanel/>
					</div>
				</Align>
			)
		}
	}

	return <ExamLayout {...examLayoutProps}/>;
}

ExamOutroPage.propTypes = {
	showCloseButton: PropTypes.bool.isRequired,
	showFeedbackPanel: PropTypes.bool.isRequired
}


// ExamOutroPage (connected to store)
// -----------------------------------------------------------------

const mapStoreToProps = (store) =>
{
	const examData = getExamData(store);
	const examGuid = getGuid(examData);

	const schedulesData = getSchedulesData(store);
	const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);

	const proctorioData = getProctorioData(store);

	return {
		showCloseButton: !(getVersion(scheduleData) || (getProctoringMode(getClientSettingsData(getSettingsData(store))) == "1")),
		showFeedbackPanel: shouldShowFeedback(examData),
		proctorioActive: getParent(proctorioData),
	}
}

ExamOutroPage = connect(mapStoreToProps)(ExamOutroPage);


// ExamOutroPage (EXPORT)
// -----------------------------------------------------------------
export {ExamOutroPage}