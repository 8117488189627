
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE & EVENT NAMES
// #########################################

const STATES = {
  INITIALIZING: 'initializing',
  INITIALIZE_ERROR: 'initialize_error',
  INITIALIZED: 'initialized'
}

const INITIALIZING_STATES = {
  INITIALIZING_APP_SETTINGS: 'initializing_app_settings',
  VALIDATING_APP_SETTINGS: 'validating_app_settings',
  INITIALIZING_CLIENT: 'initializing_client',
  INITIALIZING_SESSION_MESSAGES: 'initializing_session_messages',
  INITIALIZING_GENERAL_MESSAGES: 'initializing_general_messages',
  PRELOADING_LOGOS: 'preloading_logos',
  NO_CLIENT: 'no_client_to_initialize',
}

const VALIDATING_APP_SETTINGS_STATES = {
  CHECKING_BROWSER_SUPPORT: 'checking_browser_support',
  UNSUPPORTED_BROWSER: 'unsupported_browser_detected'
}

const INITIALIZING_CLIENT_STATES = {
  CHECKING_LOGIN_ATTEMPT: 'checking_login_attempt',
  LOADING_SESSION: 'loading_session',
  VERIFYING_SESSION: 'verifying_session',
  INITIALIZING_SESSION: 'initializing_session',
  INITIALIZING_LOGIN_URL: 'initializing_login_url'
}

const EVENTS = {
  ERROR: 'error',
  SUCCESS: 'success',
  LOGIN_ATTEMPT: 'login.attempt',
  VALID_SESSION: 'valid.session',
  SESSION_LOADED: 'session.loaded',
  INVALID_SESSION: 'invalid.session',
  NO_LOGIN_ATTEMPT: 'no.login.attempt',
  CONFIG_INITIALIZED: 'config.initialized',
  CLIENT_INITIALIZED: 'client.initialized',
  SESSION_INITIALIZED: 'session.initialized',
  INITIALIZE_FAILED: 'initialization.failed',
  MESSAGES_INITIALIZED: 'messages.initialized',
  SESSION_MESSAGES_INITALIZED: 'session.messages.initialized',
  DETECTED_UNSUPPORTED_BROWSER: 'detected.unsupported.browser'
}


// #########################################
// INITIALIZING CLIENT STATES
// #########################################

const checkingLoginAttempt = new XStateConfig();
checkingLoginAttempt.addTransition(EVENTS.NO_LOGIN_ATTEMPT, INITIALIZING_CLIENT_STATES.LOADING_SESSION);
checkingLoginAttempt.addTransition(EVENTS.LOGIN_ATTEMPT, INITIALIZING_CLIENT_STATES.INITIALIZING_SESSION);

const loadingSession = new XStateConfig();
loadingSession.addTransition(EVENTS.SESSION_LOADED, INITIALIZING_CLIENT_STATES.VERIFYING_SESSION);

const verifyingSession = new XStateConfig();
verifyingSession.addTransition(EVENTS.INVALID_SESSION, INITIALIZING_CLIENT_STATES.INITIALIZING_SESSION);

const initializingSession = new XStateConfig();
initializingSession.addTransition(EVENTS.SESSION_INITIALIZED, INITIALIZING_CLIENT_STATES.INITIALIZING_LOGIN_URL);

const initializingLoginUrl = new XStateConfig();


// #########################################
// VALIDATING APP SETTINGS STATES
// #########################################

const checkingBrowserSupport = new XStateConfig();
checkingBrowserSupport.addTransition(EVENTS.DETECTED_UNSUPPORTED_BROWSER, VALIDATING_APP_SETTINGS_STATES.UNSUPPORTED_BROWSER);

const unsupportedBrowser = new XStateConfig();


// #########################################
// INITIALIZING STATES
// #########################################

const initializingAppSettings = new XStateConfig();
initializingAppSettings.addTransition(EVENTS.CONFIG_INITIALIZED, INITIALIZING_STATES.VALIDATING_APP_SETTINGS);

const validatingAppSettings = new XStateConfig();
validatingAppSettings.initialState = VALIDATING_APP_SETTINGS_STATES.CHECKING_BROWSER_SUPPORT;
validatingAppSettings.addTransition(EVENTS.SUCCESS, INITIALIZING_STATES.INITIALIZING_CLIENT);
validatingAppSettings.addState(VALIDATING_APP_SETTINGS_STATES.CHECKING_BROWSER_SUPPORT, checkingBrowserSupport);
validatingAppSettings.addState(VALIDATING_APP_SETTINGS_STATES.UNSUPPORTED_BROWSER, unsupportedBrowser);

const initializingClient = new XStateConfig();
initializingClient.initialState = INITIALIZING_CLIENT_STATES.CHECKING_LOGIN_ATTEMPT;
initializingClient.addTransition(EVENTS.VALID_SESSION, INITIALIZING_STATES.INITIALIZING_SESSION_MESSAGES);
initializingClient.addTransition(EVENTS.CLIENT_INITIALIZED, INITIALIZING_STATES.INITIALIZING_GENERAL_MESSAGES);
initializingClient.addTransition(EVENTS.INITIALIZE_FAILED, INITIALIZING_STATES.NO_CLIENT);
initializingClient.addState(INITIALIZING_CLIENT_STATES.CHECKING_LOGIN_ATTEMPT, checkingLoginAttempt);
initializingClient.addState(INITIALIZING_CLIENT_STATES.LOADING_SESSION, loadingSession);
initializingClient.addState(INITIALIZING_CLIENT_STATES.VERIFYING_SESSION, verifyingSession);
initializingClient.addState(INITIALIZING_CLIENT_STATES.INITIALIZING_SESSION, initializingSession);
initializingClient.addState(INITIALIZING_CLIENT_STATES.INITIALIZING_LOGIN_URL, initializingLoginUrl);

const initializingSessionMessages = new XStateConfig();
initializingSessionMessages.addTransition(EVENTS.SESSION_MESSAGES_INITALIZED, INITIALIZING_STATES.INITIALIZING_GENERAL_MESSAGES);

const initializingGeneralMessages = new XStateConfig();
initializingGeneralMessages.addTransition(EVENTS.MESSAGES_INITIALIZED, INITIALIZING_STATES.PRELOADING_LOGOS);

const preloadingLogos = new XStateConfig();
const noClient = new XStateConfig();


// #########################################
// INIT STATES
// #########################################

const initializing = new XStateConfig();
initializing.initialState = INITIALIZING_STATES.INITIALIZING_APP_SETTINGS;
initializing.addState(INITIALIZING_STATES.INITIALIZING_APP_SETTINGS, initializingAppSettings);
initializing.addState(INITIALIZING_STATES.VALIDATING_APP_SETTINGS, validatingAppSettings);
initializing.addState(INITIALIZING_STATES.INITIALIZING_CLIENT, initializingClient);
initializing.addState(INITIALIZING_STATES.INITIALIZING_SESSION_MESSAGES, initializingSessionMessages);
initializing.addState(INITIALIZING_STATES.INITIALIZING_GENERAL_MESSAGES, initializingGeneralMessages);
initializing.addState(INITIALIZING_STATES.PRELOADING_LOGOS, preloadingLogos);
initializing.addState(INITIALIZING_STATES.NO_CLIENT, noClient);
initializing.addTransition(EVENTS.MESSAGES_INITIALIZED, STATES.INITIALIZED); // temp
initializing.addTransition(EVENTS.SUCCESS, STATES.INITIALIZED);
initializing.addTransition(EVENTS.ERROR, STATES.INITIALIZE_ERROR);

const initialized = new XStateConfig();
const initializeError = new XStateConfig();


// #########################################
// INIT MACHINE
// #########################################

const _init = new XStateConfig();
_init.initialState = STATES.INITIALIZING;
_init.addState(STATES.INITIALIZING, initializing);
_init.addState(STATES.INITIALIZE_ERROR, initializeError);
_init.addState(STATES.INITIALIZED, initialized);

const machine = Machine(_init.toObject());
machine.id = "Init Machine";


// #########################################
// EXPORT
// #########################################

const init = {
  machine,
  EVENTS: {...EVENTS},
  STATES: {...STATES},
  INITIALIZING_STATES: {...INITIALIZING_STATES},
  INITIALIZING_CLIENT_STATES: {...INITIALIZING_CLIENT_STATES},
  VALIDATING_APP_SETTINGS_STATES: {...VALIDATING_APP_SETTINGS_STATES}
}

export {init}