import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class CommonDialogOption extends Component {
  handleClick = () => {
    this.props.onSelect(this.props.index);
  };
  render() {
    return (
      <Button onClick={this.handleClick} color="primary">
        {this.props.text}
      </Button>
    );
  }
}

export default CommonDialogOption;
