const FORM_TYPES = {
    FIELD: 'field',
    IMAGE: 'image',
    HOTSPOT: 'hotspot',
    FILE: 'file',
    BUTTON: 'button',
    SELECT: 'select',
    DELAY: 'delay'
}

const FORM_FIELD_TYPES = {
    TEXT: 'text',
    INPUT: 'input',
    TEXT_AREA: 'text_area',
    DATE: 'date',
    TIME: 'time',
    VALUE: 'value',
    CALENDAR: 'calendar'
}

const FORM_SELECT_TYPES = {
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    CHECKBOX_RADIO: 'checkbox_radio',
    DROPDOWN: 'dropdown',
    DROPDOWN_MULTI: 'dropdown_muli'
}

const FORM_SEPERATORS = ["~", "¬", "|", "}"]

export { FORM_TYPES, FORM_FIELD_TYPES, FORM_SELECT_TYPES, FORM_SEPERATORS }