
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamPasswordForm} from './form'

// machines
import {form} from 'machines/form'


const {EVENTS, STATES} = form;


const renderFormFromView = () =>
{
	return (
		<StateView>
			{{
				[STATES.UNFILLED]: () => (
					<StateControl onCanSubmit={EVENTS.CAN_SUBMIT}>
						{(props) => <ExamPasswordForm {...props} disableSubmit/>}
					</StateControl>
				),
				[STATES.SUBMITTABLE]: () => (
					<StateControl onCantSubmit={EVENTS.CANT_SUBMIT} onSubmit={EVENTS.SUBMIT}>
						{(props) => <ExamPasswordForm {...props}/>}
					</StateControl>
				),
				[STATES.SUBMITTING]: () => ( // Empty <StateControl> to avoid React tree teardown (see https://reactjs.org/docs/reconciliation.html )
					<StateControl>
						{() => <ExamPasswordForm disableInputs disableSubmit showSpinner/>}
					</StateControl>
				),
				[STATES.SUBMISSION_FAILED]: () => (
					<StateControl onCanSubmit={EVENTS.CAN_SUBMIT} onCantSubmit={EVENTS.CANT_SUBMIT}>
						{(props) => <ExamPasswordForm {...props} disableSubmit/>}
					</StateControl>
				)
			}}
		</StateView>
	)
}


export {renderFormFromView}