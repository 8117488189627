
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamPasswordPage} from './password/page'
import {InitializingExamView} from './initializing_exam/view'
import {ValidatingScheduleView} from './validating_schedule/view'
import {CheckingResourcesView} from './checking_resources/view'
import {AppPageModifier} from 'components/pages/app-page-modifier'

// machines
import {examInit} from 'machines/exam-init'


const {EVENTS, INITIALIZING_STATES:STATES} = examInit;


const InitializingView = (examGuid) =>
{
	const appBarProps = {
		title: "Initializing your exam",
		loadingTitle: true,
		logo: true,
		logout: false
	}

	return (
		<React.Fragment>
			<AppPageModifier appBarProps={appBarProps} loading/>
			<StateView>
				{{
					[STATES.VALIDATING_SCHEDULE]: () => (
						ValidatingScheduleView(examGuid)
					),
					[STATES.INPUTTING_PASSWORD]: () => (
						<StateControl onFinish={EVENTS.SUCCESS}>
							{({onFinish}) => <ExamPasswordPage onFinish={onFinish}/>}
						</StateControl>
					),
					[STATES.INITIALIZING_EXAM]: () => (
						InitializingExamView()
					),
					[STATES.CHECKING_RESOURCES]: () => (
						CheckingResourcesView()
					)	
				}}
			</StateView>
		</React.Fragment>
	)
}


export {InitializingView}