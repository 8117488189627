
// utils
import {combineImmutableReducers} from 'utils/combine-immutable-reducers'

// redux (reducers)
import {USER_REDUCER} from './user/reducer'
import {CLIENT_REDUCER} from './client/reducer'
import {TOKENS_REDUCER} from './tokens/reducer'


const SESSION_REDUCER = combineImmutableReducers(undefined, {
	client: CLIENT_REDUCER,
	tokens: TOKENS_REDUCER,
	user: USER_REDUCER
});


export {SESSION_REDUCER}