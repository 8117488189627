import { check } from "@xams-utils/check-types";
import { FORM_SEPERATORS } from "constants/form";
import { getInitialValues } from "components/pages/exam/_layout/content/panel/answer_panel/answer_forms/form/form-helper";


const getFormData = (formData) => formData.get("formData");
const getResumedAnswer = (formData) => formData.get("resumedAnswer");

const isAnswered = (localAnswer, questionData) => {
    if (check.nonEmptyString(localAnswer)) {
        const parts = localAnswer.split(FORM_SEPERATORS[0]);
        //console.log('parts', parts);

        if (check.nonEmptyArray(parts) && parts.length > 1) {
            if (
                check.nonEmptyString(parts[0]) ||
                (parts.length > 2 && check.nonEmptyString(parts[2]))
            ) {
                //console.log('0 or 2 empty', parts.length, parts[0], parts[2], check.nonEmptyString(parts[0]), check.nonEmptyString(parts[2]));
                //console.log('');
                return true;
            }
            if (check.nonEmptyString(parts[1])) {
                const formData = questionData.get("formData");
                const initialValues = getInitialValues(formData);
                const fields = parts[1].split(FORM_SEPERATORS[1]);

                const hasChanged = fields.reduce((changed, field) => {
                    const item = field.split(FORM_SEPERATORS[2]);
                    if (item[1] !== getInitialValue(item[0], initialValues)) {
                        return true;
                    }
                    return changed;
                }, false);

                return hasChanged;
            }
        }
    }

    return false;
};

const getInitialValue = (name, values) => {
    if (check.nonEmptyArray(values)) {
        for (let i = 0; i < values.length; i++) {
            if (values[i].name === name) {
                return values[i].value;
            }
        }
    }
    return "";
};

export { getFormData, getResumedAnswer, isAnswered };
