import React from "react";

import { AppPageModifier } from "components/pages/app-page-modifier";

import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS } from "constants/message-ids";

import { SetPassword } from "./set-password";

let SetPasswordPage = (props) => {
    const title = props.messages[MESSAGE_IDS.SET_PASSWORD.APP_BAR_TITLE];
    const {onPasswordSet} = props;
    const setPasswordProps={onPasswordSet};
    return (
        <React.Fragment>
            <AppPageModifier appBarProps={{ title, logo: true }} />
            <SetPassword {...setPasswordProps} />
        </React.Fragment>
    );
};

SetPasswordPage = withMessages(SetPasswordPage);

export { SetPasswordPage };
