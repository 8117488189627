import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileDragAndDrop from './file-drag-and-drop';
import FileToUpload from './file-to-upload';
import './files-to-upload-list.css';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  cardDivider: {
    listStyleType: 'none'
  },
  list: {
    maxHeight: 'calc(33vh - 20px)',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper
  },
  listSubHeader: {
    backgroundColor: 'inherit',
    fontWeight: 'bold',
    color: theme.palette.primary.text
  }
});

class FilesToUploadList extends Component {
  state = { disabled: false };
  handleFileDrop = files => {
    this.props.onAddFilesToUpload(files);
  };
  handleFileSelect = e => {
    this.props.onAddFilesToUpload(e.target.files);
  };
  handleDeleteAll = () => {
    this.props.onDelete(-1);
  };
  isUploading = () => {
    return this.props.uploadProgress !== -1;
  };
  getUploadBoxContent = () => {
    const { disabled, disableUploading, isDeleting } = this.props;
    if (this.isUploading())
      return (
        <div className="uploadBoxProgress">
          <Typography variant="h6">
            Uploading
            {this.props.uploadProgress > 0
              ? ' ' + this.props.uploadProgress + '%'
              : ''}
          </Typography>
        </div>
      );
    else if (isDeleting)
      return (
        <div className="uploadBoxProgress">
          <Typography variant="h6">Deleting...</Typography>
        </div>
      );
    else {
      return (
        <React.Fragment>
          <input
            className="inputfile"
            type="file"
            id="myFile"
            multiple={this.props.multiple}
            size="50"
            onChange={this.handleFileSelect}
            disabled={disabled || disableUploading || isDeleting}
          />
          <label disabled={disabled || disableUploading} htmlFor="myFile">
            <Typography variant="h6">
              {`Drop file${
                this.props.multiple ? 's' : ''
              } here or click to upload`}
            </Typography>
          </label>
        </React.Fragment>
      );
    }
  };
  getUploadOptions = () => {
    const { classes, disableUploading, instantUpload, isDeleting } = this.props;
    const buttonDisabled =
      this.isUploading() ||
      this.props.files.length === 0 ||
      disableUploading ||
      isDeleting;
    if (!instantUpload)
      return (
        <React.Fragment>
          <li className={classes.cardDivider}>
            <Divider variant="fullWidth" />
          </li>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={buttonDisabled}
              onClick={this.handleDeleteAll}
            >
              Remove All Files
              <DeleteIcon className={classes.rightIcon} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={buttonDisabled || disableUploading}
              onClick={this.props.onUpload}
            >
              Upload
              <CloudUploadIcon className={classes.rightIcon} />
            </Button>
          </div>
          <li className={classes.cardDivider}>
            <Divider variant="fullWidth" />
          </li>
          <List
            className={classes.list}
            subheader={
              <ListSubheader className={classes.listSubHeader} component="div">
                {`${
                  this.props.files.length === 0 ? 'No' : this.props.files.length
                } file${this.props.files.length !== 1 ? 's' : ''} to upload`}
              </ListSubheader>
            }
          >
            {this.props.files.map(f => (
              <FileToUpload
                key={f.index}
                index={f.index}
                file={f.file}
                onDelete={this.props.onDelete}
                disabled={this.isUploading()}
              />
            ))}
          </List>
        </React.Fragment>
      );
  };
  render() {
    const { disableUploading, isDeleting } = this.props;
    return (
      <Card>
        <FileDragAndDrop
          disabled={this.isUploading() || disableUploading || isDeleting}
          onFileDrop={this.handleFileDrop}
        >
          <div className="uploadBox">{this.getUploadBoxContent()}</div>
        </FileDragAndDrop>
        {this.getUploadOptions()}
      </Card>
    );
  }
}
FilesToUploadList.defaultProps = {
  multiple: false,
  maxFilesToUpload: 0,
  maxFileSize: 0,
  minFileSize: 0,
  accepts: []
};
export default withStyles(styles)(FilesToUploadList);
