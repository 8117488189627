import { check } from "@xams-utils/check-types";
import { activityLogger } from "libs/activity_logger/activity-logger";

const removeActiviesFromPayload = (_payload) => {
    const payload = {};
    Object.keys(_payload).forEach(key => {
        if (key!=='activities') payload[key] = _payload[key];
    });
    return {...payload};   
};

const addActiviesToPayLoad = (_payload) => {

    return new Promise((resolve) => {
        if (_payload.activities) resolve(_payload);
        const payload = { ..._payload };
        const {formRunGuid} = payload;
        activityLogger.get(formRunGuid).then((activities) => {
            payload.activities = activities;
            resolve(payload);
        });
    });
};

const removeFormRunGuidFromActivityLog=(payload)=>{

    if (!payload) return Promise.resolve();

    const {formRunGuid} = payload;

    if (!formRunGuid) return Promise.resolve();
    
    return activityLogger.remove(formRunGuid);
}

const _getErrorObject=(type, error)=>{
    const {responseText, status} = error;
    const obj = {_type: type, responseText, status};
    return obj;    
}

const getErrorObject=(errorObj)=>{
    var obj={};
    
    if (errorObj){
        for (let key in errorObj) {
            obj[key] = errorObj[key];
        }
    }

    return obj;
}

export { addActiviesToPayLoad, removeActiviesFromPayload, removeFormRunGuidFromActivityLog, getErrorObject };
