
// redux (action-types)
import {SET_CURRENT_EXAM} from 'redux/reducers/exam/action-types'
import {ADD_SCHEDULE} from 'redux/reducers/schedules/action-types'


const addSchedule = (schedule) => ({
	type: ADD_SCHEDULE,
	...schedule
});

const setCurrentExam = (name, examGuid, scheduleGuid, practice) => ({
	type: SET_CURRENT_EXAM,
	scheduleGuid,
	examGuid,
	name,
	practice
});


export {setCurrentExam, addSchedule}