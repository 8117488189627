
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux
import {getSchedulesData} from 'redux/reducers/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'
import {hasScheduleCompleted} from 'redux/reducers/schedules/schedule/selectors'



// ScheduleCompletionChecker (not connected to store)
// ------------------------------------------------

class ScheduleCompletionChecker extends React.Component
{
	componentDidMount()
	{
		const {schedule, onComplete, onIncomplete} = this.props;
		if (hasScheduleCompleted(schedule)) { onComplete(); }
		else { onIncomplete(); }
	}

	render()
	{
		return null;
	}
}

ScheduleCompletionChecker.propTypes = {
	schedule: PropTypes.object.isRequired,
	onComplete: PropTypes.func.isRequired,
	onIncomplete: PropTypes.func.isRequired
}


// ScheduleCompletionChecker (connected to store)
// ------------------------------------------------

const mapStoreToProps = (store, {examGuid}) => ({
	schedule: getScheduleDataByExamGuid(getSchedulesData(store))(examGuid)
});

ScheduleCompletionChecker = connect(mapStoreToProps)(ScheduleCompletionChecker);


// EXPORT
// ------------------------------------------------
export {ScheduleCompletionChecker}