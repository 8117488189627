import { check } from "@xams-utils/check-types";

const sortElearning = (eLearning) => {
    return eLearning.sort((a, b) => {
        if (a.status > b.status) return -1;
        else if (a.status < b.status) return 1;

        let dateA = null;
        let dateB = null;
        if (a.status === 2) {
            dateA = getTimeStamp(a.dateEnded);
            dateB = getTimeStamp(b.dateEnded);
        } else if (a.status === 1) {
            dateA = getTimeStamp(a.dateStarted);
            dateB = getTimeStamp(b.dateStarted);
        }
        if (dateA && dateB) {
            if (dateA < dateB) return -1;
            else if (dateA < dateB) return 1;
        }

        return a.attemptId < b.attemptId ? -1 : 1;
    });
};

const getTimeStamp = (date) => {
    if (check.integer(date)) return date * 1000;
    const _date = new Date(date);
    return _date.getTime();
};

export { sortElearning };
