const calculateRootSectionsMarks = (sectionData, mappingsData) => {
    const root = mappingsData.get("root");
    const rootMarks = [];

    if (!root) {
        return sectionData;
    }

    root.forEach((sectionId) => {
        const section = sectionData.get(sectionId);

        if (section) {
            const marks = section.get("marks");

            if (isNaN(marks)) {
                rootMarks[sectionId] = getMarksForSection(
                    sectionId,
                    sectionData,
                    mappingsData
                );
            }
        }
    });

    return sectionData.map((item, index) => {
        return rootMarks[index] ? item.set("marks", rootMarks[index]) : item;
    });
};

const getMarksForSection = (sectionId, sectionData, mappingsData) => {
    const section = mappingsData.get(sectionId);
    const marks = section.reduce((total, sectionId) => {
        const marks = sectionData.get(sectionId).get("marks");
        if (!isNaN(marks)) total += marks;
        return total;
    }, 0);

    return marks;
};

export { calculateRootSectionsMarks };
