import React, { Component } from "react";

import { ConfirmLearnerIdentifierText } from "./confirm-learner-identifier-text";
import { getLearnerIdentifierLabel } from "./learner-identification-helper";

import { MaterialText } from "components/presentation/material-text";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { addMessageParameters } from "utils/message-helper";

class ConfirmLearnerIdentifier extends Component {
    state = {};

    get Checkbox() {
        const { onChange } = this.props;
        return <Checkbox color="primary" onChange={onChange} />;
    }

    render() {
        const {
            identifier,
            confirmMode,
            onChange,
            textClass,
            confirmMessage,
            enterMessage,
        } = this.props;

        if (confirmMode === 2) {
            const label = addMessageParameters(
                enterMessage,
                getLearnerIdentifierLabel(identifier)
            );
            const props = {
                onChange,
                identifier,
                label,
                textClass
            };

            return <ConfirmLearnerIdentifierText {...props} />;
        } else if (confirmMode === 1) {
            const labelText = addMessageParameters(confirmMessage, [
                getLearnerIdentifierLabel(identifier),
                identifier,
            ]);
            const label = (
                <MaterialText className={textClass} variant="subtitle2">
                    {labelText}
                </MaterialText>
            );

            return <FormControlLabel label={label} control={this.Checkbox} />;
        }

        return null;
    }
}

export { ConfirmLearnerIdentifier };