import {api} from '../api'
import {ENDPOINTS} from '../constants'


const getCourseAttempts=(payload)=>{
    return api.post(ENDPOINTS.ELEARNING.ATTEMPTS.GET, payload);
}

const getCourseAttemptDetails=(formRunGuid)=>{
    const payload = {
		parameters:[
			{
				name: "GUID",
				value: formRunGuid,
				expression: "equals"
			}
        ],
        properties: [
            "Course.ID",
            "Course.GUID",
            "Course.Name",
            "ID",
            "GUID",
            "Status",
            "DateStarted",
            "DateEnded",
            "LearningObjectID",
		    "LearningObject.TextMessageID",
            "ScoAttempts"
        ],
        collections: [
            {
                name: "ScoAttempts",
                properties: [
                    "Sco.ID",
                    "Sco.GUID",
                    "Sco.Name",
                    "Sco.Url",
                    "ID",
                    "GUID",
                    "Status",
                    "DateStarted",
                    "DateEnded"
                ]
            }
        ],
        "resolvers": [
            {
                "service": "/xams.Repo.Service.App/Service",
                "api": "/api/query",
                "entity": "Objects",
                "property": "LearningObject",
                "parameters": [
                    {
                        "name": "ID",
                        "value": "LearningObjectID",
                        "expression": "equals"
                    }
                ],
                "properties": [
                    "ID",
                    "TextMessageID"
                ]
            }
        ]        
	}

	return api.post(ENDPOINTS.ELEARNING.COURSE.QUERY, payload);    
}

const getCourseAttemptDetailsDev=(formRunGuid)=>{
    const payload = {
		parameters:[
			{
				name: "GUID",
				value: formRunGuid,
				expression: "equals"
			}
        ],
        properties: [
            "Course.ID",
            "Course.GUID",
            "Course.Name",
            "ID",
            "GUID",
            "Status",
            "DateStarted",
            "DateEnded",
            "ScoAttempts"
        ],
        collections: [
            {
                name: "ScoAttempts",
                properties: [
                    "Sco.ID",
                    "Sco.GUID",
                    "Sco.Name",
                    "Sco.Url",
                    "ID",
                    "GUID",
                    "Status",
                    "DateStarted",
                    "DateEnded"
                ]
            }
        ]        
	}

	return api.post(ENDPOINTS.ELEARNING.COURSE.QUERY, payload);    
}

const getCourseInfo=(payload)=>{
    return api.post(ENDPOINTS.ELEARNING.COURSE.INFO, payload);    
}

const getCourseDetails=(payload)=>{
    return api.post(ENDPOINTS.ELEARNING.COURSE.GET, payload);
}

const getScormData=(scoAttemptId)=>{
    const endPoint = `${ENDPOINTS.ELEARNING.SCORM.GET}/${scoAttemptId}`;
    return api.get(endPoint, {});
}

const saveScormData=(scoAttemptId, payload)=>{
    const endPoint = `${ENDPOINTS.ELEARNING.SCORM.SAVE}/${scoAttemptId}`;
    return api.post(endPoint, payload);
}

const resetScormData=(payload)=>{
    return api.post(ENDPOINTS.ELEARNING.SCORM.RESET, payload);
}

const eLearningApi = {
    getCourseAttempts,
    getCourseAttemptDetails,
    getCourseAttemptDetailsDev,
    getScormData,
    saveScormData,
    resetScormData,
    getCourseDetails,
    getCourseInfo
};

export { eLearningApi };
