
// npm
import React from 'react'
import check from 'check-types'
import PropTypes from 'prop-types'
import {withWindowSizeListener} from 'react-window-size-listener'

// react
import {SidePanel} from './side/panel'
import {SideDrawer} from './side/drawer'
import {ContentBox} from './content/content-box'
import {ContentBar} from './content/content-bar'
import {ContentPanel} from './content/content-panel'
import {BoundsListener} from 'components/functional/bounds-listener'


const MIN_CONTENT_WIDTH = 600;


// DesktopExamLayout (not connected to windowSizeListener)
// -------------------------------------------------------------

class DesktopExamLayout extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			leftDrawerOpen: null,																												// null means no drawer (use panel instead)
			rightDrawerOpen: null
		}

		this.leftContentWidth = 0;
		this.rightContentWidth = 0;
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.setPanel = (side) => () => {
			this.setState({[`${side}DrawerOpen`]: null});
		}

		this.setDrawer = (side) => () => {
			this.setState({[`${side}DrawerOpen`]: false});
		}

		this.toggleDrawer = (side) => () => {
			const stateProperty = `${side}DrawerOpen`;
			if (stateProperty === null) { throw "Drawer not available"; }
			this.setState({[stateProperty]: !this.state[stateProperty]});
		}

		this.closeDrawers = () => {
			this.setState({leftDrawerOpen: false, rightDrawerOpen: false});
		}

		this.isDrawer = (side) => {
			return this.state[`${side}DrawerOpen`] !== null;
		}

		this.findAvailableSidePanels = () => {
			const availableSidePanels = [];
			if (this.props.left) { availableSidePanels.push('left'); }									// getPanelSidePriority depends on this push order!
			if (this.props.right) { availableSidePanels.push('right'); }
			return availableSidePanels;
		}

		this.getPanelSidePriority = () => { // left defaults as priority
			const availableSidePanels = this.findAvailableSidePanels();
			if (availableSidePanels.length < 2) { return availableSidePanels; }
			if (this.props.left.prioritize) { return availableSidePanels; }
			if (!this.props.right.prioritize) { return availableSidePanels; }
			return ['right', 'left'];
		}

		this.findPanelSidesThatFitOnScreen = (panelSidesByPriority) => {
			const result = [];

			const viewportWidth = document.body.clientWidth;
			let totalContentWidth = MIN_CONTENT_WIDTH;																	// this value increases with every side panel we check throughout function
			if (viewportWidth < totalContentWidth) { return result; }

			for (let i = 0; i < panelSidesByPriority.length; i++) {
				const nextPanelSide = panelSidesByPriority[i];
				totalContentWidth += this[`${nextPanelSide}ContentWidth`];
				if (viewportWidth < totalContentWidth) { break; }
				result.push(nextPanelSide);
			}

			return result;
		}

		this.updatePanelStates = () => {
			const panelSidesByPriority = this.getPanelSidePriority();
			const panelSidesThatFit = this.findPanelSidesThatFitOnScreen(panelSidesByPriority);
			const newState = {};
			panelSidesByPriority.forEach(panelSide => {
				const statePropertyName = `${panelSide}DrawerOpen`;
				const panelFits = panelSidesThatFit.includes(panelSide);
				const isDrawer = this.isDrawer(panelSide);
				if (panelFits && isDrawer) { newState[statePropertyName] = null; }
				else if (!panelFits && !isDrawer) { newState[statePropertyName] = false; }
			})
			if (!check.emptyObject(newState)) { this.setState(newState); }
		}

		this.updatePanelWidth = (side) => (width) => {
			this[`${side}ContentWidth`] = width;
			this.updatePanelStates();
		}
	}

	componentDidUpdate(prevProps)
	{
		const oldWindowSize = prevProps.windowSize;
		const newWindowSize = this.props.windowSize;

		if (oldWindowSize !== newWindowSize)
		{
			const existsDrawer = this.isDrawer('left') || this.isDrawer('right');
			const widthIncreased = newWindowSize.windowWidth > oldWindowSize.windowWidth;
			if (existsDrawer && widthIncreased) { this.updatePanelStates(); }

			const existsPanel = !this.isDrawer('left') || !this.isDrawer('right');
			if (existsPanel && !widthIncreased) { this.updatePanelStates(); }
		}
	}

	render()
	{
		return (
			<React.Fragment>
				{this.getPanelOrDrawer('left')}
				{this.Content}
				{this.getPanelOrDrawer('right')}
			</React.Fragment>
		);
	}

	get Content()
	{
		return (
			<ContentBox>
				{this.ContentBar}
				{this.ContentPanel}
			</ContentBox>
		)
	}

	get ContentBar()
	{
		const contentBarProps = {};

		if (this.props.left && this.isDrawer('left')) {
			contentBarProps.left = {
				Icon: this.props.left.Icon,
				open: this.state.leftDrawerOpen,
				onClick: this.toggleDrawer('left')
			}
		}

		if (this.props.right && this.isDrawer('right')) {
			contentBarProps.right = {
				Icon: this.props.right.Icon,
				open: this.state.rightDrawerOpen,
				onClick: this.toggleDrawer('right')
			}
		}

		return <ContentBar {...contentBarProps}>{this.props.bar.content}</ContentBar>;
	}

	getPanelOrDrawer(side)
	{
		if (!this.props[side]) { return null; }
		return this.isDrawer(side) ? this.getDrawer(side) : this.getPanel(side);
	}

	getDrawer(side)
	{
		const drawerProps = {
			open: this.state[`${side}DrawerOpen`],
			anchor: side
		}

		return (
			<SideDrawer {...drawerProps}>
				{this.props[side].content}
			</SideDrawer>
		);
	}

	getPanel(side)
	{
		return (
			<SidePanel>
				<BoundsListener onSizeChange={this.updatePanelWidth(side)}>
					{this.props[side].content}
				</BoundsListener>
			</SidePanel>
		)
	}

	get ContentPanel()
	{
		return <ContentPanel>{this.props.centre.content}</ContentPanel>;
	}
}

DesktopExamLayout.propTypes = {
	bar: PropTypes.shape({
		content: PropTypes.node
	}),
	left: PropTypes.shape({
		content: PropTypes.node,
		prioritize: PropTypes.bool,
		Icon: PropTypes.oneOfType([
			PropTypes.instanceOf(React.Component),
			PropTypes.func
		])
	}),
	centre: PropTypes.shape({
		content: PropTypes.node
	}),
	right: PropTypes.shape({
		content: PropTypes.node,
		prioritize: PropTypes.bool,
		Icon: PropTypes.oneOfType([
			PropTypes.instanceOf(React.Component),
			PropTypes.func
		])
	})
}


// DesktopExamLayout (connected to windowSizeListener)
// -------------------------------------------------------------

DesktopExamLayout = withWindowSizeListener(DesktopExamLayout);


// Export
// -------------------------------------------------------------
export {DesktopExamLayout}