
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {AnswerBox} from './answer-box'
import {ComboAnswerForm} from '../answer_forms/combo'

// redux (selectors)
import {getSelectedOptionData} from 'redux/reducers/exam/content/questions/question/combo/options/selectors'
import {getOptionsData, getDropdownLabel} from 'redux/reducers/exam/content/questions/question/combo/selectors'
import {getOptionText, getOptionId} from 'redux/reducers/exam/content/questions/question/combo/options/option/selectors'



class ComboAnswerBox extends React.Component
{
	constructor(props)
	{
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => null;

		this.extractResumedAnswer = () => {
			const optionsData = getOptionsData(this.props.data);
			const selectedOptionData = getSelectedOptionData(optionsData);
			return !!selectedOptionData ? getOptionId(selectedOptionData) : null;
		}

		this.extractFormData = () => {
			const options = getOptionsData(this.props.data).map(optionData => {
				const value = getOptionId(optionData).toString();
				const text = getOptionText(optionData);
				return {value, text};
			});

			const label = getDropdownLabel(this.props.data);
			return {options, label};
		}
	}

	setUpTransformationFunctions()
	{
		this.getFormValue = (formAnswer) => formAnswer.target.value;
		this.toFormValue = (localAnswer) => localAnswer && localAnswer.toString();
		this.toLocalAnswer = (formValue) => parseInt(formValue, 10);

		this.toServerAnswer = (localAnswer) => {
			if (!localAnswer) { return localAnswer; }
			const selectedOption = this.getSelectedOption(localAnswer);
			const optionId = getOptionId(selectedOption);
			const optionText = getOptionText(selectedOption);
			const options = [{optionID: optionId, optionText}];
			return {options, answerText: optionText};
		}
	}

	render()
	{
		const answerBoxProps = {
			questionData: this.props.data,
			FormComponent: ComboAnswerForm,
			toFormValue: this.toFormValue,
			getFormValue: this.getFormValue,
			toLocalAnswer: this.toLocalAnswer,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer,
		}

		return <AnswerBox {...answerBoxProps}/>;
	}

	getSelectedOption(localAnswer)
	{
		const optionsData = getOptionsData(this.props.data);
		return optionsData.find(optionData => {
			return getOptionId(optionData) === localAnswer;
		})
	}
}

ComboAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export {ComboAnswerBox}