
// npm
import {connect} from 'react-redux'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getMessageData} from 'redux/reducers/exam/selectors'
import {getIntroText} from 'redux/reducers/exam/messages/selectors'

// react
import {TextPanel} from '../_presentation/text-panel'


const mapStoreToProps = (store) => ({
	text: getIntroText(getMessageData(getExamData(store)))
})

const InstructionsPanel = connect(mapStoreToProps)(TextPanel);


export {InstructionsPanel}