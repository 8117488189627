
import {extractMessage} from 'redux/reducers/exam/helpers'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const ICT_QUESTION_REDUCER = (state, action) => {
  switch (action.type) {
    case SET_EXAM_DATA:
			return state.withMutations(mutableState => {
				const {data} = action;
        const answer = data['ANSWER'];
        mutableState.set('resumedAnswer', answer===''?'visited':answer);
        mutableState.set('evidenceText', extractMessage(data['EVIDENCETEXT']));

			});    
    // case SET_EXAM_DATA:
    //   return state.set('evidenceText', extractMessage(action.data.EVIDENCETEXT));
    default:
      return state;
  }
}


export {ICT_QUESTION_REDUCER};