
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {ResultPanel} from './result-panel'
import {SectionPanel} from './section-panel'

// constants
import {ID_TYPE} from '../constants'


const containerStyle = {
	flexGrow: 1,
	flexShrink: 0,
	marginBottom: 10,
	width: '100%'
}


class ResultsPanel extends React.Component
{
	render()
	{
		const {data:{mappings}, chosenSection} = this.props;
		const renderableIds = mappings[chosenSection];
		const renderables = this.areSections(renderableIds) ?
			this.getSectionPanels(renderableIds) : this.getResultPanel(renderableIds);

		return <div style={containerStyle}>{renderables}</div>;
	}

	getSectionPanels(ids)
	{
		const {data:{mappings, sections}, hideSectionNamesInPlayer} = this.props;

		if (hideSectionNamesInPlayer){
			return this.getResultPanelForAllSections(ids, mappings);
		}

		return ids.map((id, index) => {
			const headingNode = sections[id];
			const resultPanel = this.getResultPanel(mappings[id]);

			return <SectionPanel key={index} headingNode={headingNode} resultPanel={resultPanel}/>;
		});
	}

	getResultPanel(ids)
	{
		const {results} = this.props.data;
		return <ResultPanel>{ids.map(id => results[id])}</ResultPanel>;
	}

	getResultPanelForAllSections(ids, mappings){
		const results = ids.reduce((total, id) => {
			return total.concat(mappings[id]);
		}, []);

		// let allResults = [];
		
		// ids.forEach(id=>{
		// 	const ids = mappings[id];
		// 	allResults = allResults.concat(ids);
		// });

		return this.getResultPanel(results);		
	}

	areSections(renderableIds)
	{
		return this.props.data.sections.hasOwnProperty(renderableIds[0]);
	}
}

ResultsPanel.propTypes = {
	data: PropTypes.shape({
		sections: PropTypes.objectOf(PropTypes.node).isRequired,
		results: PropTypes.objectOf(PropTypes.node).isRequired,
		mappings: PropTypes.objectOf(PropTypes.arrayOf(ID_TYPE)).isRequired
	}).isRequired,
	chosenSection: ID_TYPE.isRequired
}


export {ResultsPanel}