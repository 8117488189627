
// npm
import React from 'react'
import generateId from 'uuid/v4'
import PropTypes from 'prop-types'

// material-ui
import Card from '@material-ui/core/Card'

// react
import {DraggableOption} from './draggable-option'
import {DroppableOption} from './droppable-option'

// utils
import {htmlToReact} from 'utils/html-to-react'



const ERRORS = { NO_REF: "Dnd (checks): Include 'ref' in droppable component" };

class ChecksDragAndDropAnswerForm extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundProperties();
		this.initializeBoundMethods();

		this.state = {
			draggableKeys: props.formData.options.map(() => generateId()), 
			highlightedIndex: null
		};
	}

	initializeBoundProperties()
	{
		const {formData:{maxOptions}} = this.props;

		this.droppableRefs = new Array(maxOptions).fill(null).map(x => {
			return React.createRef();
		})
	}

	initializeBoundMethods()
	{
		this.resetDraggable = (index) => {
			const {draggableKeys} = this.state;
			this.setState({draggableKeys: draggableKeys.set(index, generateId())});
		}

		this.findHoveredDroppable = (x, y) => {
			// 1. Check currently-highlighted droppable first (for efficiency reasons)
			const {droppableRefs, state:{highlightedIndex}} = this;
			const existsHighlighted = highlightedIndex !== null;
			const droppableRef = existsHighlighted && droppableRefs[highlightedIndex];
			const droppable = droppableRef && droppableRef.current;
			if (droppable && droppable.containsPoint(x, y)) { return droppableRef; }

			// If not, find the new droppable we're hovering over, 
			// ...skipping the one we just checked
			return droppableRefs.find(ref => {
				if (ref === droppableRef) { return false; }
				if (!ref.current) { throw ERRORS.NO_REF; }
				return ref.current.containsPoint(x, y);
			});
		}

		this.onOptionDrag = (draggableIndex, e, data) => {
			const droppableRef = this.findHoveredDroppable(e.clientX, e.clientY);
			const {highlightedIndex} = this.state;
			const existsHighlighted = highlightedIndex !== null;
			const exitingDroppable = existsHighlighted && !droppableRef;
			const enteringDroppable = !existsHighlighted && droppableRef;
			if (!exitingDroppable && !enteringDroppable) { return; }
			const newHighlightedIndex = exitingDroppable ? null : 
				this.droppableRefs.indexOf(droppableRef);
			this.setState({highlightedIndex: newHighlightedIndex});
		}

		this.onOptionDrop = (droppableIndex, e, data) => {
			const {state:{highlightedIndex}, props:{formData}} = this;
			const existsHighlighted = highlightedIndex !== null;
			if (!existsHighlighted) { return this.resetDraggable(droppableIndex); }			// returns nothing (used as filter)

			this.setState({highlightedIndex: null});

			const currentValue = this.props.value[highlightedIndex];
			if (!!currentValue) {
				this.props.onChange({optionId: currentValue, checked: false});
			}

			const optionId = formData.options.get(droppableIndex).id;
			this.props.onChange({optionId, checked: true});
		}
	}

	render() 
	{
		return (
			<div id='checks-dnd'>
				<Card style={{backgroundColor: 'transparent'}}>
					<div style={{position: 'relative'}}>
						<div style={{padding: 5}}>{this.Draggables}</div>
						<div style={{padding: 5}}>{this.Droppables}</div>
					</div>
				</Card>
			</div>
		)
	}

	get Draggables()
	{
		const {state:{draggableKeys}, props:{value, formData:{options}}} = this;

		const commonProps = {
			bounds: '#checks-dnd',
			onDragStop: this.onOptionDrop,
			onDrag: this.onOptionDrag
		}

		return options.map((option, index) => {
			if (value.includes(option.id)) { return null; }

			const uniqueProps = {
				draggableKey: draggableKeys.get(index),
				value: htmlToReact.parse(option.text),
				id: index,
			}

			return <DraggableOption {...commonProps} {...uniqueProps}/>;
		})
	}

	get Droppables()
	{
		const droppables = [];
		const {value, formData:{maxOptions}} = this.props;
		
		for (let i = 0; i < maxOptions; i++) 
		{
			droppables.push(this.getDroppable(i));
			if (!value[i]) { break; }
		}

		return droppables;
	}

	getDroppable(index)
	{
		const {formData} = this.props;
		const optionId = this.props.value[index];
		const option = optionId && formData.options.find(x => x.id === optionId);

		const ref = this.droppableRefs[index];
		const value = option ? option.text : null;
		const onReset = () => this.props.onChange({optionId, checked: false});
		const highlighted = this.state.highlightedIndex === index;

		const props = {ref, value, onReset, highlighted};
		return <span style={{padding: 5}}><DroppableOption {...props}/></span>
	}
}

ChecksDragAndDropAnswerForm.propTypes = {
	value: PropTypes.arrayOf(PropTypes.number).isRequired,
	onChange: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		maxOptions: PropTypes.number.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired
		}).isRequired).isRequired
	})
}


export {ChecksDragAndDropAnswerForm}