
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Toolbar from '@material-ui/core/Toolbar'
import MaterialAppBar from '@material-ui/core/AppBar'
import withStyles from '@material-ui/core/styles/withStyles'
import Hidden from '@material-ui/core/Hidden'

// react
import {AppBarLogo} from './logo'
import {BugReporter} from './bug_reporter'
import { ActivityReporter } from "components/pages/activity_logs/activity-reporter"
import { ProctoringIndicator} from "components/pages/proctorio/proctoring-indicator"
import {LogoutButton} from './logout-button'
import {ThemeSelector} from './theme-selector'
import {VersionNumber} from "./version-number"
import {withMessages} from 'components/hocs/messages'
import {LoadingText} from 'components/presentation/loading-text'
import {MaterialText} from 'components/presentation/material-text'

// constants
import {appBarHeight} from './constants'
import {MESSAGE_IDS} from 'constants/message-ids'


const welcomeMessageId = MESSAGE_IDS.APP_BAR.WELCOME;


// AppBar (not connected to styles/messages)
// ------------------------------------------

class AppBar extends React.Component
{
	render()
	{
		const props = {
			classes: {
				colorPrimary: this.props.classes.appBar
			},
			style: {height: appBarHeight},
			position: 'relative',
			elevation: 0
		}

		return (
			<MaterialAppBar {...props}>
				<Toolbar variant='dense'>
					{this.Logo}
					{this.Title}
					<div style={{flexGrow: 1}}/>
					{this.WelcomeMessage}
					{this.OtherContent}
					<ThemeSelector/>
					<ProctoringIndicator/>
					<VersionNumber/>
					<div style={{flexBasis: 8}}/>
					{this.LogoutButton}
					<BugReporter/>
					<ActivityReporter/>					
				</Toolbar>
			</MaterialAppBar>
		)
	}

	get Logo()
	{
		if (!this.props.logo) { return null; }
		return <AppBarLogo/>;
	}

	get Title()
	{
		if (!this.props.title) { return null; }
		
		const {classes, loadingTitle} = this.props;
		const className = `${classes.title} ${classes.text}`;
		const TitleComponent = loadingTitle ? LoadingText : MaterialText;

		return (
            <React.Fragment>
                <Hidden xsDown>
                    <TitleComponent className={className} variant="h5" noWrap>
                        {this.props.title}
                    </TitleComponent>
                </Hidden>
                <Hidden smUp>
                    <TitleComponent className={className} variant="h6" noWrap>
                        {this.props.title}
                    </TitleComponent>
                </Hidden>
            </React.Fragment>
        );
	}

	get WelcomeMessage()
	{
		if (!this.props.welcomeMessage) {
            return null;
		}
		
        const { classes, messages } = this.props;
		
		return (
            <Hidden xsDown>
                <MaterialText className={`${classes.text}`}>
                    {messages[welcomeMessageId]}
                </MaterialText>
            </Hidden>
        );
	}

	get LogoutButton()
	{
		if (!this.props.logout) { return null; }
		return <LogoutButton/>;
	}

	get OtherContent()
	{
		if (!this.props.other) { return null; }
		return this.props.other;
	}
}

AppBar.propTypes = {
	welcomeMessage: PropTypes.bool.isRequired,
	logout: PropTypes.bool.isRequired,
	logo: PropTypes.bool.isRequired,
	loadingTitle: PropTypes.bool,
	title: PropTypes.string,
	other: PropTypes.node
}

AppBar.defaultProps = {
	welcomeMessage: false,
	logout: false,
	logo: false
}


// AppBar (connected to styles/messages)
// ------------------------------------------

const styles = ({palette:{primary}, spacing}) =>
{
	return {
		text: {
			color: primary.contrastText
		},
		title: {
			marginLeft: spacing.unit * 2,
			marginRight: spacing.unit * 2
		},
		appBar: {
			backgroundColor: primary.light + "!important"
		}
	}
}

AppBar = withStyles(styles)(AppBar);
AppBar = withMessages(AppBar);


// Export
// ------------------------------------------
export {AppBar}