
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import ImmutablePropTypes from 'immutable-prop-types'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getId, getType} from 'redux/reducers/exam/content/questions/question/selectors'
import {getQuestionIds, getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {getControlName, getProperties, getResumedAnswer} from 'redux/reducers/exam/content/questions/question/ascx/selectors'

// redux (actions)
import {setAscxHtml} from './actions'

// utils
import {getAscx} from 'libs/api/interface/api-ascx'

// constants
import {ASCX} from 'constants/question-types'


// AscxInitializer (not connected to store)
// ------------------------------------------------------------

class AscxInitializer extends React.Component
{
	componentDidMount()
	{
		const {ascxQuestions, onFail} = this.props;
		const questionCount = ascxQuestions.length;

		if (questionCount === 0) {
			this.props.onSuccess();
		}
		else {
			let loadCount = 0;

			ascxQuestions.forEach(ascxQuestion => {
				const questionId = getId(ascxQuestion);
				const controlName = getControlName(ascxQuestion);
				const initialValue = getResumedAnswer(ascxQuestion);
				const properties = getProperties(ascxQuestion);

				const onSuccess = (response) => {
					this.props.saveAscxHtml(questionId, response);
					if (++loadCount === questionCount) {
						this.props.onSuccess();
					}
				}

				getAscx(questionId, controlName, initialValue, properties)
				.then(onSuccess, onFail);
			});
		}
	}

	render()
	{
		return null;
	}
}

AscxInitializer.propTypes = {
	onFail: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	saveAscxHtml: PropTypes.func.isRequired,
	ascxQuestions: PropTypes.arrayOf(ImmutablePropTypes.map).isRequired
}


// AscxInitializer (connected to store)
// ------------------------------------------------------------

const mapStoreToProps = (store) => {
	const contentData = getContentData(getExamData(store));
	const questionsData = getQuestionsData(contentData);
	const questionIds = getQuestionIds(questionsData);
	const getQuestionData = getQuestionDataById(questionsData);

	const questions = questionIds.map(id => getQuestionData(id));
	const ascxQuestions = questions.filter(q => getType(q) === ASCX);

	return {ascxQuestions};
}

const mapDispatchToProps = (dispatch) => ({
	saveAscxHtml: (questionId, html) => dispatch(setAscxHtml(questionId, html))
});

AscxInitializer = connect(mapStoreToProps, mapDispatchToProps)(AscxInitializer);


// Export
// ------------------------------------------------------------
export {AscxInitializer}