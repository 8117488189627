// other modules
import {api} from '../api'
import {ENDPOINTS} from '../constants'


const requestMessages = (iDs, orgID, userGUID, langID) =>
{
	const payload = {iDs, orgID, userGUID, langID};
	return api.post(ENDPOINTS.MESSAGES, payload);
}


const messagesApi = {requestMessages};
export {messagesApi}