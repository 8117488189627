// npm
import { Map as ImmutableMap } from "immutable";

// custom
import { getProp } from "custom/object-helper";

// redux
import * as ACTION_TYPES from "./action-types";
import * as EXAM_ACTION_TYPES from "../action-types";
import { SECTIONS_REDUCER } from "./sections/reducer";
import { MAPPINGS_REDUCER } from "./mappings/reducer";
import { QUESTIONS_REDUCER } from "./questions/reducer";

// utils
import { combineImmutableReducers } from "utils/combine-immutable-reducers";
import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";

const ownReducer = (state = ImmutableMap(), action) => {
    switch (action.type) {
        case EXAM_ACTION_TYPES.SET_EXAM_DATA:
            state = state.set(
                "initialPage",
                getProp(action, "data.EXAM.@Page") || 1
            );

            state = state.set(
                "requiresCalculator",
                getProp(action, "data.EXAM.@ShowCalculator") === "True" || false
            );            

            return state.set(
                "resourceUrl",
                getProp(action, "data.EXAM.@ResourceURL")
            );
        case ACTION_TYPES.SET_CURRENT_QUESTION_ID: {
            if (action.setFromQuestion){
                state = state.set(
                    "questionIdSetFromQuestion",
                    true
                );
            }

            activityLogger.log(ACTIVITIES.MOVE_QUESTION, action);
            return state.set("currentQuestionId", action.value);
        }
        case ACTION_TYPES.SET_QUESTION_ID_FROM_QUESTION: {
            return state.set("questionIdSetFromQuestion", action.value);
        }         
        default:
            return state;
    }
};

const CONTENT_REDUCER = combineImmutableReducers(ownReducer, {
    sections: SECTIONS_REDUCER,
    questions: QUESTIONS_REDUCER,
    mappings: MAPPINGS_REDUCER,
});

export { CONTENT_REDUCER };
