
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE & EVENT NAMES
// #########################################

const STATES = {
	UNCHECKED: 'unchecked',
	CHECKED: 'all_checked',
	FINISHED: 'finished'
}

const EVENTS = {
	CHECKED: 'checkboxes.checked',
	UNCHECKED: 'checkboxes.unchecked',
	FINISHED: 'checkboxes.submitted'
}


// #########################################
// RUBRIC STATES
// #########################################

const unchecked = new XStateConfig();
unchecked.addTransition(EVENTS.CHECKED, STATES.CHECKED);

const checked = new XStateConfig();
checked.addTransition(EVENTS.UNCHECKED, STATES.UNCHECKED);
checked.addTransition(EVENTS.FINISHED, STATES.FINISHED);

const finished = new XStateConfig();


// #########################################
// RUBRIC MACHINE
// #########################################

const _rubric = new XStateConfig();
_rubric.initialState = STATES.UNCHECKED;
_rubric.addState(STATES.CHECKED, checked);
_rubric.addState(STATES.UNCHECKED, unchecked);
_rubric.addState(STATES.FINISHED, finished);

const machine = Machine(_rubric.toObject());
machine.id = "Rubric Machine";


// #########################################
// EXPORT
// #########################################

const rubric = {
	machine,
	EVENTS: { ...EVENTS },
	STATES: { ...STATES }
}

export {rubric}