import { check } from "@xams-utils/check-types";
import { FORM_TYPES } from "constants/form";

const getFormGridImages = (examQuestionData) => {
    const { formData } = examQuestionData;

    if (check.nonEmptyObject(formData)) {
        const { rows } = formData;

        if (check.nonEmptyArray(rows)) {
            return rows.reduce((images, row) => {
                const rowImages = getRowImages(row);

                return check.nonEmptyArray(rowImages)
                    ? images.concat(rowImages)
                    : images;
            }, []);
        }
    }
    return null;
};

const getRowImages = (row) => {
    if (check.nonEmptyObject(row)) {
        const { cells } = row;

        if (check.nonEmptyArray(cells)) {
            return cells.reduce((images, cell) => {
                const cellImages = getCellImages(cell);

                return check.nonEmptyArray(cellImages)
                    ? images.concat(cellImages)
                    : images;
            }, []);
        }
    }

    return null;
};

const getCellImages = (cell) => {
    if (check.nonEmptyObject(cell)) {
        const { components } = cell;

        if (check.nonEmptyArray(components)) {
            return components.reduce((images, _component) => {
                const { component } = _component;
                //console.log(component);
                if (component === FORM_TYPES.IMAGE) {
                    const { src } = _component;
                    images.push(src);
                }

                return images;
            }, []);
        }
    }

    return null;
};

export { getFormGridImages };
