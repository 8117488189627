
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import ChromeReaderIcon from '@material-ui/icons/ChromeReaderMode'

// react
import {withMessages} from 'components/hocs/messages'
import {ActionButton} from 'components/layout/action_button/button'

// other
import {MESSAGE_IDS} from 'constants/message-ids'


const examInstructionsMessageId = MESSAGE_IDS.EXAM.INSTRUCTIONS;


let InstructionsButton = ({onClick, messages}) => (
	<ActionButton color='secondary' Icon={ChromeReaderIcon} alignment='right' onClick={onClick}>
		{messages[examInstructionsMessageId]}
	</ActionButton>
)

InstructionsButton.propTypes = {
	onClick: PropTypes.func.isRequired
}


InstructionsButton = withMessages(InstructionsButton);


export {InstructionsButton}