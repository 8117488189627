
// npm
import {detect} from 'detect-browser'

// @xams-entities
import {Version} from '@xams-entities/version'
import {Browser} from '@xams-entities/browser'


const detectBrowser = () => {
  const { name, version, os } = detect();
  const _version = Version.create(version);
  const browser = Browser.create(name, os);
  return { version: _version , browser };
};


export {detectBrowser}