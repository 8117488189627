import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { setParent, setEntered } from "./actions";
import { getProctorioData } from "redux/reducers/selectors";
import { getDebugMode } from "redux/reducers/proctorio/selectors";

import { AppPageModifier } from "components/pages/app-page-modifier";


class ProctorioParentChecker extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleMessageFromProctorio = (evt) => {
            const { data, origin } = evt;
            if (origin === "https://getproctorio.com") {
                const { active } = data;

                if (active) {
                    this.setProctorioActive();
                }
            }
        };

        this.redirectToSchedule=()=>{
            const {history, enteredSet} = this.props;

            clearTimeout(this.timer);
            enteredSet(false);
            history.push(`/schedules`);
        }

        this.setProctorioActive=()=>{
            const { onSuccess, parentSet } = this.props;

            clearTimeout(this.timer);
            document.body.style.height = "1000px"; 
            parentSet(true);
            onSuccess();
        }

        this.timer = null;
    }

    componentDidMount() {
        const {debugMode, examGuid} = this.props;

        if (!examGuid) {
            const {onSuccess} = this.props;

            onSuccess();
        }
        else{
            if (debugMode){
                alert('Start Proctorio')
                this.setProctorioActive();
            }
            else{
                window.addEventListener("message", this.handleMessageFromProctorio);

                window.top.postMessage(
                    ["proctorio_status"],
                    "https://getproctorio.com"
                );

                //this.timer = setTimeout(()=>{this.redirectToSchedule()}, 5000);
            }
        }
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
        window.removeEventListener("message", this.handleMessageFromProctorio);
    }

    render() {
        const appBarProps = {
            title: "Checking Proctorio",
            loadingTitle: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="proctorio-parent-checking"
                    appBarProps={appBarProps}
                    loading
                />
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);
    const debugMode = getDebugMode(proctorioData)

    return {
        debugMode
    };
};

const mapDispatchToProps = (dispatch) => ({
    parentSet: (value) => dispatch(setParent(value)),
    enteredSet: (value) => dispatch(setEntered(value)),
});

ProctorioParentChecker = connect(
    mapStoreToProps,
    mapDispatchToProps
)(ProctorioParentChecker);

ProctorioParentChecker = withRouter(ProctorioParentChecker);

export { ProctorioParentChecker };
