import { check } from "@xams-utils/check-types";
// redux (selectors)
import { getExamData } from "redux/reducers/selectors";
import { getContentData } from "redux/reducers/exam/selectors";
import {
    getCurrentQuestionId,
    getQuestionIdSetFromQuestion,
} from "redux/reducers/exam/content/selectors";

import { getPaperPartId } from "redux/reducers/exam/content/complex-selectors";
import {
    getSectionsData,
    getQuestionsData,
    getMappingsData,
} from "redux/reducers/exam/content/selectors";

import { isAudio } from "components/functional/audio/audio-helper";

const findCurrentSection = (currentQuestionId, mappings, sections) => {
    for (let key in mappings) {
        if (mappings[key].indexOf(currentQuestionId) !== -1) {
            const data = sections[key];
            //data.displayAllQuestions = key === "817";

            return { id: key, questions: mappings[key], data };
        }
    }

    return null;
};

const shouldInclude = (currentPaperPartId, getPaperPartId) => (id) => {
    if (currentPaperPartId === id) {
        return true;
    }
    return getPaperPartId(id) === currentPaperPartId;
};

const getMappingsAndSections = (store, _currentQuestionId = null) => {
    const contentData = getContentData(getExamData(store));
    const currentQuestionId = _currentQuestionId
        ? _currentQuestionId
        : getCurrentQuestionId(contentData);
    const questionIdSetFromQuestion = getQuestionIdSetFromQuestion(contentData);
    const _getPaperPartId = getPaperPartId(contentData);
    const currentPaperPartId = _getPaperPartId(currentQuestionId);

    let sectionsData = getSectionsData(contentData);
    let questionsData = getQuestionsData(contentData);
    let mappingsData = getMappingsData(contentData);

    if (currentPaperPartId) {
        // filter for nodes that only exist in the current paper part
        const cachedResults = {};
        const _shouldInclude = shouldInclude(
            currentPaperPartId,
            _getPaperPartId
        );

        // 1. Filter unneeded sections
        sectionsData = sectionsData.filter((_, sectionId) => {
            cachedResults[sectionId] = _shouldInclude(sectionId);
            return cachedResults[sectionId];
        });

        // 2. Filter unneeded questions
        questionsData = questionsData.filter((_, questionId) => {
            cachedResults[questionId] = _shouldInclude(questionId);
            return cachedResults[questionId];
        });

        // 3. Filter unneeded mappings
        mappingsData = mappingsData.filter((_, mappingId) => {
            return /*mappingId === 'root' || */ cachedResults[mappingId];
        });

        // 4. Convert paper part mapping to be 'root'
        const paperPartMapping = mappingsData.get(currentPaperPartId);
        mappingsData = mappingsData.delete(currentPaperPartId);
        mappingsData = mappingsData.set("root", paperPartMapping);
    }

    // sectionsData = calculateRootSectionsMarks(sectionsData, mappingsData);

    const mappings = mappingsData.toJS();
    const sections = sectionsData.toJS();

    return { mappings, sections, questionIdSetFromQuestion, currentQuestionId };
};

const checkSectionsForAudio = (sections) => {
    let hasAudio = false;

    for (let key in sections) {
        const { media } = sections[key];
        if (isAudio(media)) {
            hasAudio = true;
        }

        if (hasAudio) {
            break;
        }
    }

    return hasAudio;
};

const displayAllQuestionsInSection = (section) =>
    section && section.data && section.data.displayAllQuestions;


export { getMappingsAndSections, findCurrentSection, checkSectionsForAudio, displayAllQuestionsInSection };
