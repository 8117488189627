
import {getSelectedId} from './option/selectors'


const getOptionDataById = (optionsData) => (optionId) => {
	return optionsData.find(x => x.get('id') === optionId);
}

const getAnsweredOptions = (optionsData) => {
	return optionsData.filter(x => !!getSelectedId(x));
}

export {getOptionDataById, getAnsweredOptions}