
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import ImmutablePropTypes from 'immutable-prop-types'

// redux (selectors)
import {getExamData, getSchedulesData} from 'redux/reducers/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getContentData, getGuid} from 'redux/reducers/exam/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'
import {hasScheduleCommenced} from 'redux/reducers/schedules/schedule/selectors'
import {getResumedQuestionId} from 'redux/reducers/exam/content/complex-selectors'

// redux (actions)
import {setCurrentQuestionId} from './actions'


// CommencedChecker (not connected to store)
// ----------------------------------------------------------------------------

class CommencedChecker extends React.Component
{
	componentDidMount()
	{
		const {schedulesData, examGuid} = this.props;
		const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);

		if (hasScheduleCommenced(scheduleData)) {
			this.props.onCommenced();
		}
		else {
			this.props.onNotCommenced();
		}
	}

	render()
	{
		return null;
	}
}

CommencedChecker.propTypes = {
	onCommenced: PropTypes.func.isRequired,
	onNotCommenced: PropTypes.func.isRequired,
	examGuid: PropTypes.string.isRequired,
	schedulesData: ImmutablePropTypes.list.isRequired
}


// CommencedChecker (connected to store)
// ----------------------------------------------------------------------------

const mapStoreToProps = (store) => {
	const contentData = getContentData(getExamData(store));
	
	return {
		initialQuestionId: getResumedQuestionId(contentData),
		examGuid: getGuid(getExamData(store)),
		schedulesData: getSchedulesData(store)
	}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	onCommenced: (initialQuestionId) => () => {
		dispatch(setCurrentQuestionId(initialQuestionId));														// Set up question to resume to!
		ownProps.onCommenced();
	},
	onNotCommenced: (initialQuestionId) => () => {
		dispatch(setCurrentQuestionId(initialQuestionId));														// Set up question to resume to!
		ownProps.onNotCommenced();
	}
})

const mergeProps = (storeProps, dispatchProps, ownProps) => ({
	examGuid: storeProps.examGuid,
	schedulesData: storeProps.schedulesData,
	onCommenced: dispatchProps.onCommenced(storeProps.initialQuestionId),
	onNotCommenced: dispatchProps.onNotCommenced(storeProps.initialQuestionId)
});

const args = [mapStoreToProps, mapDispatchToProps, mergeProps];
CommencedChecker = connect(...args)(CommencedChecker);


// Export
// ----------------------------------------------------------------------------
export {CommencedChecker}