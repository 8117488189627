
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {AnswerBox} from './answer-box'
import {MatchAnswerForm} from '../answer_forms/match'
import {MatchDragAndDropAnswerForm} from '../answer_forms/drag_and_drop/match'

// redux (selectors)
import {getLocalAnswer, isDragAndDrop} from 'redux/reducers/exam/content/questions/question/selectors'
import {getSelectionDataById} from 'redux/reducers/exam/content/questions/question/match/selections/selectors'
import {getSelectionsData, getOptionsData} from 'redux/reducers/exam/content/questions/question/match/selectors'
import {getOptionDataById, getAnsweredOptions} from 'redux/reducers/exam/content/questions/question/match/options/selectors'
import {getSelectionId, getSelectionText} from 'redux/reducers/exam/content/questions/question/match/selections/selection/selectors'
import {getOptionId, getSelectedId, getOptionText} from 'redux/reducers/exam/content/questions/question/match/options/option/selectors'



class MatchAnswerBox extends React.Component
{
	constructor(props)
	{
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	// extract information from questionData
	// --------------------------------------
	setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => ({});

		this.extractResumedAnswer = () => {
			const optionsData = getOptionsData(this.props.data);
			const answeredOptions = getAnsweredOptions(optionsData);
			if (answeredOptions.size === 0) { return undefined; }
			const resumedAnswer = {};
			answeredOptions.forEach(answeredOption => {
				const optionId = getOptionId(answeredOption);
				resumedAnswer[optionId] = getSelectedId(answeredOption);
			});
			return resumedAnswer;
		}

		this.extractFormData = () => ({
			selections: getSelectionsData(this.props.data).map(selectionData => {
				const id = getSelectionId(selectionData);
				const text = getSelectionText(selectionData);
				return {id, text};
			}).toArray(),
			options: getOptionsData(this.props.data).map(optionData => {
				const id = getOptionId(optionData);
			 	const text = getOptionText(optionData);
			 	return {id, text};
			}).toArray()
		})
	}

	// convert between answer values
	// --------------------------------------
	setUpTransformationFunctions()
	{
		this.getFormValue = (formAnswer) => {
			const {optionId, selectionId} = formAnswer;
			let formValue = {...getLocalAnswer(this.props.data)};
			formValue[optionId] = selectionId;
			return formValue;
		}
		
		this.toFormValue = (localAnswer) => localAnswer;
		this.toLocalAnswer = (formValue) => formValue;

		this.toServerAnswer = (localAnswer) => {
			const {data} = this.props;
			if (!localAnswer) { return localAnswer; }
			const answeredEntries = Object.entries(localAnswer).filter(x => !!x[1]);
			const _getOptionDataById = getOptionDataById(getOptionsData(data));
			const _getSelectionDataById = getSelectionDataById(getSelectionsData(data));

			return {
				answerText: answeredEntries.map(([optionId, selectionId]) => {
					const optionText = getOptionText(_getOptionDataById(parseInt(optionId, 10))); // parseInt here because object keys auto-convert to strings
					const selectionText = getSelectionText(_getSelectionDataById(selectionId));
					return `${optionText} = ${selectionText}`;
				}).join('|'),
				options: answeredEntries.map(([optionId, selectionId]) => {
					return {optionId, selectedOptionId: selectionId};
				})
			}
		};
	}

	render()
	{
		const dnd = isDragAndDrop(this.props.data);
		const AnswerForm = dnd ? MatchDragAndDropAnswerForm : MatchAnswerForm;

		const answerBoxProps = {
			FormComponent: AnswerForm,
			questionData: this.props.data,
			toFormValue: this.toFormValue,
			getFormValue: this.getFormValue,
			toLocalAnswer: this.toLocalAnswer,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer,
		}

		return <AnswerBox {...answerBoxProps}/>;
	}
}

MatchAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export {MatchAnswerBox}
