
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// utils
import {findExamGuidsByQuery} from '../query-logs'
import {localStorageApi, KEYS} from 'libs/browser_storage/local-storage-api'


class ExamSelector extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {value: null}

		this.update = ({target:{value}}) => {
			this.setState({value});
			this.props.onChange(value);
		}
	}

	render()
	{
		return (
			<Select value={this.state.value} onChange={this.update}>
				{this.Options}
			</Select>
		);
	}

	get Options()
	{
		const options = [this.NullOption];
		const exams = localStorageApi.retrieveDataFrom(KEYS.EXAMS);

		const getExamName = (guid) => {
			const exam = exams[guid];
			return exam ? exam.name : guid;
		}

		const examGuids = findExamGuidsByQuery({userGuid: this.props.userGuid});
		examGuids.forEach(examGuid => {
			const key = this.createKey(examGuid);
			const examName = getExamName(examGuid);
			options.push(<MenuItem key={key} value={examGuid}>{examName}</MenuItem>);
		});

		return options;
	}

	get NullOption()
	{
		const key = this.createKey(null);
		return <MenuItem key={key} value={null}>{"Not specified"}</MenuItem>;
	}

	createKey(examGuid)
	{
		return `exam-selector-${examGuid}`;
	}
}

ExamSelector.propTypes = {
	userGuid: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
}


export {ExamSelector}