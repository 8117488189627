
// xams-utils
import {check} from '@xams-utils/check-types'


const KEYS = {
	LEFT_ARROW: 37,
	RIGHT_ARROW: 39
}


const keySubscriptions = {};		// keyCode -> {disabled: bool, listeners: <Array>listener}


document.addEventListener('keydown', ({keyCode}) => {
	const subscription = keySubscriptions[keyCode];

	if (subscription && !subscription.disabled) {
		subscription.listeners.forEach(listener => {
			listener();
		});
	}
});

const getUnsubscribeFunction = (keyCode, listener) => () => {
	const {listeners} = keySubscriptions[keyCode];
	const listenerIndex = listeners.indexOf(listener);
	listeners.splice(listenerIndex, 1);
}


const subscribe = (keyCode, listener) => {
	if (!check.number(keyCode)) {
		throw "Key code must be a number";
	}

	if (!check.function(listener)) {
		throw "Key listener must be a function";
	}

	if (!keySubscriptions[keyCode]) {
		keySubscriptions[keyCode] = {
			disabled: false,
			listeners: [listener]
		};
	}
	else {
		keySubscriptions[keyCode].listeners.push(listener);
	}

	return getUnsubscribeFunction(keyCode, listener);
}

const disable = (keyCode) => {
	if (keyCode in keySubscriptions) {
		keySubscriptions[keyCode].disabled = true;
	}
}

const enable = (keyCode) => {
	if (keyCode in keySubscriptions) {
		keySubscriptions[keyCode].disabled = false;
	}	
}



const keyListener = {subscribe, enable, disable};
Object.freeze(keyListener);


export {keyListener, KEYS}