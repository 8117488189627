
// npm
import {Map as ImmutableMap} from 'immutable'

// redux
import * as ACTION_TYPES from './action-types'
import * as EXAM_ACTION_TYPES from 'redux/reducers/exam/action-types'


const WORKINGS_REDUCER = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case EXAM_ACTION_TYPES.SET_EXAM_DATA:
			return state.set('value', action.data.WORKINGS || "");
		case ACTION_TYPES.CHANGE:
			return state.set('value', action.value);
		default:
			return state;
	}
}

export {WORKINGS_REDUCER}