
// npm
import React from 'react'


const DisableNavigationContext = React.createContext();

const DisableAnsweringContext = React.createContext();


export {
	DisableNavigationContext,
	DisableAnsweringContext
}