import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Hidden from "@material-ui/core/Hidden";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

const getShortMessage = (message) => {
    if (!message || typeof message !== "string") return message;

    const words = message.split(" ");

    if (!words || words.length < 2) return message;

    return capitalize(words[words.length - 1].trim());
};

const ElearningCompletedSwitch = (props) => {
    const { onChange, checked } = props;
    const message = "Include completed";
    const shortMessage = getShortMessage(message);
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    name="checkedB"
                    color="primary"
                />
            }
            label={
                <React.Fragment>
                    <Hidden xsDown>{message}</Hidden>
                    <Hidden smUp>{shortMessage}</Hidden>
                </React.Fragment>
            }
            labelPlacement="start"
        />
    );
};

export { ElearningCompletedSwitch };
