// npm
import React from "react";
import check from "check-types";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// react
import { MultiImagePreloader } from "components/functional/image_preloading/multi-image-preloader";

// utils
import { deepExtract } from "utils/deep-extract";
import { imageSrcExtractor } from "utils/image-src-extractor";
import { getAscxImageUrl } from "utils/get-ascx-image-url";
import { getFormGridImages } from "utils/get-form-grid-images";

// redux (selectors)
import { getExamData, getSettingsData } from "redux/reducers/selectors";
import { getMessageData, getContentData } from "redux/reducers/exam/selectors";
import {
  getQuestionsData,
  getSectionsData,
} from "redux/reducers/exam/content/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getApiEndpointUrl } from "redux/reducers/settings/app/selectors";

// ExamImagePreloader (not connected to store)
// ---------------------------------------------------------------

class ExamImagePreloader extends React.Component {
  constructor(props) {
    super(props);

    this.imageUrls = [];
    this.populateImageUrlsFor(deepExtract(props.examMessageData));
    this.populateImageUrlsFor(deepExtract(props.examSectionsData));
    this.populateImageUrlsFor(deepExtract(props.examQuestionsData));
    this.populateAscxImageParameters(props.examQuestionsData);
    this.populateFormGridImageParameters(props.examQuestionsData);
  }

  populateAscxImageParameters(examQuestionsData) {
    const { apiEndpointUrl } = this.props;
    for (const examQuestionData in examQuestionsData) {
      const { properties } = examQuestionsData[examQuestionData];

      if (check.assigned(properties)) {
        properties.forEach((property) => {
          const { value } = property;
          const imageUrl = getAscxImageUrl(value, apiEndpointUrl);

          if (imageUrl) {
            this.imageUrls.push(imageUrl);
          }
        });
      }
    }
  }

  populateFormGridImageParameters(examQuestionsData) {
    const { apiEndpointUrl } = this.props;
    for (const examQuestionData in examQuestionsData) {
      const { type } = examQuestionsData[examQuestionData];
      if (type === "QGRID") {
        const images = getFormGridImages(
          examQuestionsData[examQuestionData]
        );

        if (check.nonEmptyArray(images)) {
          for (let i = 0; i < images.length; i++) {
            const imageUrl = getAscxImageUrl(
              images[i],
              apiEndpointUrl
            );
            this.imageUrls.push(imageUrl);
          }
        }
      }
    }
  }

  populateImageUrlsFor(values) {
    values.forEach((value) => {
      if (!check.string(value)) {
        return;
      }
      imageSrcExtractor.fromHtmlString(value).forEach((imageUrl) => {
        this.imageUrls.push(imageUrl);
      });
    });
  }

  render() {
    const props = {
      urls: this.imageUrls,
      onFail: this.props.onFail,
      onSuccess: this.props.onSuccess,
    };

    return <MultiImagePreloader {...props} />;
  }
}

ExamImagePreloader.propTypes = {
  onFail: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  examMessageData: PropTypes.object.isRequired,
  examSectionsData: PropTypes.object.isRequired,
  examQuestionsData: PropTypes.object.isRequired,
};

// ExamImagePreloader (connected to store)
// ---------------------------------------------------------------

const mapStoreToProps = (store) => {
  const examData = getExamData(store);

  const settingsData = getSettingsData(store);
  const appSettingsData = getAppSettingsData(settingsData);

  const examContentData = getContentData(examData);

  // CAUTION: These are potentially large immutable maps.
  // This component only renders once, and should remain that way to avoid
  // the expensive operation of converting them to plain objects multiple times
  return {
    examMessageData: getMessageData(examData).toJS(),
    examSectionsData: getSectionsData(examContentData).toJS(),
    examQuestionsData: getQuestionsData(examContentData).toJS(),
    apiEndpointUrl: getApiEndpointUrl(appSettingsData),
  };
};

ExamImagePreloader = connect(mapStoreToProps)(ExamImagePreloader);

// EXPORT
// ---------------------------------------------------------------
export { ExamImagePreloader };
