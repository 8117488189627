import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import { FormConfirmation } from "./form-confirmation";
import { FormRow } from "./form-row";
import { FormPopulated } from "./form-populated";
import {
    getStyle,
    getFontStyles,
    createFormValue,
    getFormValue,
    getFormAction,
    hasFormBeenSelected,
    addSpacesToName,
    getInitialValues,
    isChangeRowState,
    getRowState,
} from "./form-helper";

import { FORM_SEPERATORS } from "constants/form";

const getInitialRowState=(formData, value)=>{
    if (check.nonEmptyString(value)){
        const items=value.split(FORM_SEPERATORS[0]);

        if (check.nonEmptyArray(items) && items.length>2){
            const action=items[2];
            const rowState=getRowState(action);   

            if (check.assigned(rowState)){
                return rowState;
            }
        }
    }

    const {rowState}=formData;
    return rowState;
}

class Form extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.handleAction = this.handleAction.bind(this);

        const { value, disabled, formData } = props;
        const selected = hasFormBeenSelected(value, FORM_SEPERATORS);
        const rowState = getInitialRowState(formData, value);
        const enabled = !disabled && !selected;

        this.state = {
            enabled,
            showConfirmation: false,
            confirmationName: null,
            formAction: "",
            rowState,
        };
    }

    handleClick(name, bypassConfirmation=false) {
        if (bypassConfirmation){
            this.handleConfirmation(true, name);
        }
        else{
            this.setState({ showConfirmation: true, confirmationName: name });
        }
    }

    handleConfirmation(confirmed, _name=null) {
        const { onChange, value } = this.props;
        const newState = { showConfirmation: false, confirmationName: null };

        if (confirmed) {
            const { confirmationName } = this.state;
            const name=check.nonEmptyString(_name)?_name:confirmationName;
            //console.log("Clicked", name);
            let formValue = "";
            let action = "";

            if (check.nonEmptyString(value)) {
                const items = value.split(FORM_SEPERATORS[0]);

                if (check.nonEmptyArray(items) && items.length > 1) {
                    formValue = items[1];

                    if (items.length > 2) {
                        action = items[2];
                    }
                }
            }
            const formName = addSpacesToName(name);
            const newValue = [formName, formValue, action].join(
                FORM_SEPERATORS[0]
            );

            onChange(newValue);

            newState.enabled = false;
        }

        this.setState(newState);
    }

    handleChange(name, _value, _action=null) {
        const { value, onChange } = this.props;

        let selected = "";
        let action = "";
        let formValue = {};

        if (check.nonEmptyString(value)) {
            const items = value.split(FORM_SEPERATORS[0]);
            if (check.nonEmptyArray(items)) {
                selected = items[0];
                action = items.length > 2 ? items[2] : "";
                const formValues = items[1].split(FORM_SEPERATORS[1]);
                if (check.nonEmptyArray(formValues)) {
                    formValue = formValues.reduce((obj, item) => {
                        if (check.nonEmptyString(item)) {
                            const items = item.split(FORM_SEPERATORS[2]);
                            if (check.nonEmptyArray(items)) {
                                obj[items[0]] = items[1];
                            }
                        }
                        return obj;
                    }, {});
                }
            }
        }

        const formName = addSpacesToName(name);
        formValue[formName] = _value;

        const newValue = [
            selected,
            createFormValue(formValue, FORM_SEPERATORS),
            check.assigned(_action)?_action:action,
        ].join(FORM_SEPERATORS[0]);
        
        console.log(newValue);
        onChange(newValue);

        if (check.assigned(_action)){
            const rowState=getRowState(_action);

            if (check.assigned(rowState)){
                this.setState({rowState})     
            }
        }
    }

    handleAction(action) {
        //console.log('Action', action);
        // cookies=style=visibility:visible

        const rowState=getRowState(action);   
        if (check.assigned(rowState)){
            this.setState({rowState})     
        }

        const { value, onChange } = this.props;
        let newValue = ";";
        if (check.nonEmptyString(value)) {
            const items = value.split(FORM_SEPERATORS[0]);
            newValue = [items[0], items[1], action].join(FORM_SEPERATORS[0]);
            //console.log("newValue",newValue);
        } else {
            newValue = ["", "", action].join(FORM_SEPERATORS[0]);
            //console.log("newValue",newValue);
        }
        onChange(newValue);
    }

    displayForm() {
        const { formData } = this.props;
        const styleObj = getStyle(formData);
       

        return (
            <div style={styleObj}>
                {this.displayRows()}
                {this.displayPopulated()}
            </div>
        );
    }

    displayRows(){
        const { formData, value } = this.props;
        const { enabled, rowState } = this.state;
        const { rows, rowStates, rowStateDefault } = formData;
        const styleObj = getStyle(formData);
        const fontObj = getFontStyles(styleObj);

        const formValue = getFormValue(value, FORM_SEPERATORS);
        const formAction = getFormAction(value, FORM_SEPERATORS);

        return  rows.map((row, index) => {
                    const props = {
                        key: index,
                        onChange: this.handleChange,
                        onAction: this.handleAction,
                        onClickAction: this.handleClickAction,
                        formAction,
                        enabled,
                        onClick: this.handleClick,
                        formValue,
                        rowState,
                        rowStates,
                        rowStateDefault,
                        fontObj,
                        seps: FORM_SEPERATORS,
                        ...row,
                    };

                    return <FormRow {...props} />;
                })


    }

    displayPopulated() {
        const { value } = this.props;

        if (hasFormBeenSelected(value, FORM_SEPERATORS)) {
            const props = { value, seps: FORM_SEPERATORS };
            return <FormPopulated {...props} />;
        }
        return null;
    }

    displayConfirmation() {
        const { showConfirmation, confirmationName } = this.state;
        const props = {
            onConfirm: this.handleConfirmation,
            showConfirmation,
            confirmationName,
        };

        return <FormConfirmation {...props} />;
    }

    render() {
        return (
            <React.Fragment>
                {this.displayForm()}
                {this.displayConfirmation()}
            </React.Fragment>
        );
    }
}

export { Form };
