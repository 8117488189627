
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {MobileActionButton} from './mobile'
import {DesktopActionButton} from './desktop'
import {ViewSwitcher} from 'components/functional/view-switcher'


class ActionButton extends React.Component
{
	constructor(props)
	{
		super(props);

		this.getMobileActionButton = () => {
			const buttonProps = {...this.props, alignment: undefined};
			return <MobileActionButton {...buttonProps}/>;	
		}

		this.getDesktopActionButton = () => {
			return <DesktopActionButton {...this.props}/>;	
		}
	}

	render()
	{
		const props = {
			getMobileView: this.getMobileActionButton,
			getDesktopView: this.getDesktopActionButton
		}

		return <ViewSwitcher {...props}/>;
	}
}

ActionButton.propTypes = {
	Icon: PropTypes.oneOfType([
		PropTypes.instanceOf(React.Component),
		PropTypes.func
	]).isRequired,
	alignment: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
	// remaining props are spread to child <Button> component
}

ActionButton.defaultProps = {
	color: 'primary',
	disabled: false
}


export {ActionButton}