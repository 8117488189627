
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {PopupButton} from './popup-button'
import {withMessages} from 'components/hocs/messages'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const cancelMessageId = MESSAGE_IDS.GENERAL.CANCEL;


let CancelButton = ({onClick, messages}) =>
{
	const text = messages[cancelMessageId];
	return <PopupButton text={text} onClick={onClick}/>;
}

CancelButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[cancelMessageId]: PropTypes.string.isRequired
	}).isRequired
}


CancelButton = withMessages(CancelButton);


export {CancelButton}