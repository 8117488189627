
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux
import {getSchedulesData} from 'redux/reducers/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'
import {requiresSchedulePassword} from 'redux/reducers/schedules/schedule/selectors'


// PasswordRequirementChecker (not connected to store)
// ----------------------------------------------------

class PasswordRequirementChecker extends React.Component
{
	componentDidMount()
	{
		const {schedule, onRequired} = this.props;
		if (requiresSchedulePassword(schedule)) { onRequired(); }
		else { this.props.onNotRequired(); }
	}

	render()
	{
		return null;
	}
}

PasswordRequirementChecker.propTypes = {
	schedule: PropTypes.object.isRequired,
	onRequired: PropTypes.func.isRequired,
	onNotRequired: PropTypes.func.isRequired
}


// PasswordRequirementChecker (connected to store)
// ----------------------------------------------------

const mapStoreToProps = (store, {examGuid}) => ({
	schedule: getScheduleDataByExamGuid(getSchedulesData(store))(examGuid)
});

PasswordRequirementChecker = connect(mapStoreToProps)(PasswordRequirementChecker);


export {PasswordRequirementChecker}