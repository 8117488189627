
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {milliseconds, seconds} from 'time-convert'
import { AppPageModifier } from "components/pages/app-page-modifier";

import { getTimeStamp } from "utils/time-stamp";

// react (hocs)
import {withExamRequestSaver} from 'components/hocs/exam_request_saver'

// react
import {Popup} from 'components/layout/popup'
import {LoadingSpinner} from 'components/presentation/loading-spinner'

// redux (selectors)
import {isOfflineMode} from 'redux/reducers/app/selectors'
import {getExamData, getAppData} from 'redux/reducers/selectors'
import {getTimeRemaining} from 'redux/reducers/exam/time/selectors'
import {getPaperPartId} from 'redux/reducers/exam/content/complex-selectors'
import {getSectionDataById} from 'redux/reducers/exam/content/sections/selectors'
import {getSectionsData, getCurrentQuestionId} from 'redux/reducers/exam/content/selectors'
import {getSectionTimeRemaining} from 'redux/reducers/exam/content/sections/section/selectors'
import {getTimeData, getContentData, getFormRunGuid, getScheduleGuid} from 'redux/reducers/exam/selectors'
import {getExamElapsedTime} from 'redux/reducers/exam/time/selectors'

// redux (actions)
import {completeSchedule} from './actions'

import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS, messageNotDefined } from "constants/message-ids";


// ExamCompleter (not connected to store)
// ----------------------------------------

class ExamCompleter extends React.Component
{
	componentDidMount()
	{
		const onSuccess = () => {
			this.props.completeSchedule(this.props.scheduleGuid);
			this.props.onComplete();
		}

		const {formRunGuid, timeRemaining, examRequestSaver, elapsedTime} = this.props;
		let examTimeRemaining = seconds.from(milliseconds)(timeRemaining);
		examTimeRemaining = Math.round(examTimeRemaining);

		const ClientTime = getTimeStamp();

		const payload = {formRunGuid, examTimeRemaining, ClientTime, elapsedTime};

		examRequestSaver.completeExam(payload, {onSuccess});
	}

	render() 
	{
		const {offline, messages} = this.props;

		if (!offline) {
			const appBarProps = {
				title: "Completing Exam",
				loadingTitle: true,
				logo: true,
			};

			return (
				<React.Fragment>
					<AppPageModifier
						id="completing-exam"
						appBarProps={appBarProps}
						loading
					/>
					<LoadingSpinner size="lg" />;
				</React.Fragment>
			);
		}

		let title = messages[MESSAGE_IDS.ERRORS.EXAM_COMPLETER_OFFLINE_TITLE];
		let message = messages[MESSAGE_IDS.ERRORS.EXAM_COMPLETER_OFFLINE_MESSAGE];

		if (messageNotDefined(title)){
			title="Oops. Your internet connection was lost";
		}

		if (messageNotDefined(message)){
			message="Not to worry. We'll save your progress as soon as you're back online :)";
		}

		const popupProps = {
			title,
			content: {
				text: message
			}
		}

		return <Popup {...popupProps}/>;
	}
}

ExamCompleter.propTypes = {
	offline: PropTypes.bool.isRequired,
	//scheduleGuid: PropTypes.string.isRequired,
	formRunGuid: PropTypes.string.isRequired,
	timeRemaining: PropTypes.number.isRequired,
	completeSchedule: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	examRequestSaver: PropTypes.shape({
		completeExam: PropTypes.func.isRequired
	}).isRequired
}


// ExamCompleter (connected to store)
// ----------------------------------------

const mapStoreToProps = (store) =>
{
	const offline = isOfflineMode(getAppData(store));

	const examData = getExamData(store);
	const contentData = getContentData(examData);
	
	const currentQuestionId = getCurrentQuestionId(contentData);
	const paperPartId = getPaperPartId(contentData)(currentQuestionId);
	const elapsedTime = getExamElapsedTime(getTimeData(examData));
	
	if (paperPartId)
	{
		const sectionsData = getSectionsData(contentData);
		const paperPartData = getSectionDataById(sectionsData)(paperPartId);
		
		return {
			offline,
			scheduleGuid: getScheduleGuid(getExamData(store)),
			formRunGuid: getFormRunGuid(getExamData(store)),
			timeRemaining: getSectionTimeRemaining(paperPartData),
			elapsedTime
		}
	}
	else
	{
		const timeData = getTimeData(examData);
		
		return {
			offline,
			scheduleGuid: getScheduleGuid(getExamData(store)),
			formRunGuid: getFormRunGuid(getExamData(store)),
			timeRemaining: getTimeRemaining(timeData),
			elapsedTime
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	completeSchedule: (scheduleGuid) => dispatch(completeSchedule(scheduleGuid))
});

ExamCompleter = connect(mapStoreToProps, mapDispatchToProps)(ExamCompleter);
ExamCompleter = withExamRequestSaver(ExamCompleter);
ExamCompleter = withMessages(ExamCompleter);


// EXPORT
// ----------------------------------------
export {ExamCompleter}