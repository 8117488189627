import React, { Component } from "react";

import { getComponentValue, getStyle, addFontStyles } from "../form-helper";

class FormSelectCheckboxSingle extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        const { fontObj } = props;
        const styleObj = addFontStyles(getStyle(props), fontObj);
        this.state = { styleObj };
    }

    handleChange(checked) {
        const { onChange, name } = this.props;
        const newValue = checked ? "checked" : "";
        onChange(name, newValue);
    }

    getValue() {
        const { formValue, name } = this.props;

        return getComponentValue(name, formValue);
    }

    render() {
        const { name, enabled } = this.props;
        const { styleObj } = this.state;

        const value = this.getValue();
        const checked = value === "checked";
        const inputProps = {
            name,
            type: "checkbox",
            checked,
            onChange: (e) => this.handleChange(e.target.checked),
            style: styleObj,
            disabled: !enabled,
        };

        return (
            <div style={styleObj}>
                <input {...inputProps} />
            </div>
        );
    }
}

export { FormSelectCheckboxSingle };
