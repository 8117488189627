
// npm
import React from 'react'

// react
import {AppPageModifier} from 'components/pages/app-page-modifier'


const SchedulesInitializationPage = ({children}) =>
{
	const appBarProps = {
		title: 'Fetching your schedules',
		welcomeMessage: true,
		loadingTitle: true,
		logout: true,
		logo: true
	}

	return (
		<React.Fragment>
			<AppPageModifier id="fetching-schedules" appBarProps={appBarProps} loading/>
			{children}
		</React.Fragment>
	)
}


export {SchedulesInitializationPage}