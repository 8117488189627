
// redux
import * as LOGIN_ACTION_TYPES from 'redux/reducers/login/action-types'
import * as USER_SESSION_ACTION_TYPES from 'redux/reducers/session/user/action-types'
import * as TOKEN_ACTION_TYPES from 'redux/reducers/session/tokens/action-types'
import * as LOGIN_CREDENTIALS_ACTION_TYPES from 'redux/reducers/login/credentials/action-types' 



const setUsername = (value) => ({
	type: LOGIN_CREDENTIALS_ACTION_TYPES.SET_USERNAME,
	value
});

const setPassword = (value) => ({
	type: LOGIN_CREDENTIALS_ACTION_TYPES.SET_PASSWORD,
	value
});

const clearCredentials = () => ({
	type: LOGIN_CREDENTIALS_ACTION_TYPES.CLEAR
});

const clearPassword = () => ({
	type: LOGIN_CREDENTIALS_ACTION_TYPES.CLEAR_PASSWORD
});



const setLoginError = (value) => ({
	type: LOGIN_ACTION_TYPES.SET_ERROR,
	value
});

const clearLoginError = () => ({
	type: LOGIN_ACTION_TYPES.CLEAR_ERROR
});



const createUserSession = (data) => ({
	type: USER_SESSION_ACTION_TYPES.CREATE_SESSION,
	...data
});

const saveTokens = (data) => ({
	type: TOKEN_ACTION_TYPES.SAVE_TOKENS,
	...data
});



export {
	setUsername,
	setPassword,
	clearCredentials,
	clearPassword,
	setLoginError,
	clearLoginError,
	createUserSession,
	saveTokens
}