
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {MobileExamLayout} from './mobile'
import {DesktopExamLayout} from './desktop'
import {ViewSwitcher} from 'components/functional/view-switcher'


// Size = 100% of PageWrapper
// Flex-align children along the horizontal axis
const ExamLayoutContainer = ({children}) =>
{
	const divStyle = {
		display: 'flex',
		alignItems: 'stretch',
		width: '100%',
		height: '100%'
	}

	return <div style={divStyle}>{children}</div>;
}


const ExamLayout = (props) =>
{
	const viewSwitcherProps = {
		getMobileView: () => (
			<MobileExamLayout {...props}/>
		),
		getDesktopView: () => (
			<DesktopExamLayout {...props}/>
		)
	}

	return (
		<ExamLayoutContainer>
			<ViewSwitcher {...viewSwitcherProps}/>
		</ExamLayoutContainer>
	);
}

ExamLayout.propTypes = {
	bar: PropTypes.shape({
		content: PropTypes.node
	}),
	left: PropTypes.shape({
		content: PropTypes.node,
		prioritize: PropTypes.bool,
		icon: PropTypes.instanceOf(React.Component)
	}),
	centre: PropTypes.shape({
		content: PropTypes.node
	}),
	right: PropTypes.shape({
		content: PropTypes.node,
		prioritize: PropTypes.bool,
		icon: PropTypes.instanceOf(React.Component)
	})
}


export {ExamLayout}