
// npm
import * as Immutable from 'immutable'

// xams-utils
import {check} from '@xams-utils/check-types'

// redux
import * as ACTION_TYPES from './action-types'

// utils
import {api} from 'libs/api/api'
import {COOKIE_NAMES, cookies} from 'utils/cookies'
import {KEYS, sessionStorageApi} from 'libs/browser_storage/session-storage-api'


const saveTokensToApi = (access, refresh) =>
{
	api.RefreshToken = refresh;
	api.Token = access;
}

const loadFromQueryString = () =>
{
	const urlParameters = new URLSearchParams(window.location.search);
	const externalSessionData = urlParameters.get('x');
	if (!externalSessionData) { return; }

	const parsedSessionData = JSON.parse(externalSessionData);
	const data = parsedSessionData.token;
	sessionStorageApi.saveDataTo(KEYS.TOKENS, data);

	return data;
}

const handleTokenLoading = () =>
{
	const data = loadFromQueryString() || sessionStorageApi.retrieveDataFrom(KEYS.TOKENS);
	saveTokensToApi(data.access, data.refresh);
	return Immutable.Map(data);
}

const handleTokenSaving = (access, refresh) =>
{
	saveTokensToApi(access, refresh);
	const tokenData = {access, refresh};
	sessionStorageApi.saveDataTo(KEYS.TOKENS, tokenData);
	return Immutable.Map(tokenData);
}

const handleTokenDiscarding = () =>
{
	saveTokensToApi(null, null);
	sessionStorageApi.resetDataFor(KEYS.TOKENS);
	return Immutable.Map();
}

const TOKENS_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.LOAD_TOKENS:
			return handleTokenLoading();
		case ACTION_TYPES.SAVE_TOKENS:
			return handleTokenSaving(action.access, action.refresh);
		case ACTION_TYPES.DISCARD_TOKENS:
			return handleTokenDiscarding();
		default:
			return state;
	}
}


export {TOKENS_REDUCER}