import React, { Component } from "react";
import { connect } from "react-redux";

import { PROCTORING_TYPES } from "constants/proctoring";
import { getUserSessionData } from "redux/reducers/session/selectors";
import { getUserId } from "redux/reducers/session/user/selectors";
import { getSessionData, getProctorioData } from "redux/reducers/selectors";
import { getDebugMode } from "redux/reducers/proctorio/selectors";

import { assessmentApi } from "libs/api/interface/api-assessment";

class RedirectToProctorio extends Component {
    state = {};

    componentDidMount() {
        const { debugMode, examGuid, proctorProvider } = this.props;

        if (proctorProvider === PROCTORING_TYPES.PROCTORIO) {
            if (debugMode) {
                const userName = "";
                const url = `http://localhost:3000/proctorio/sfjuk/benanderson/Debu2476/${examGuid}`;
                console.log(url);

                window.location.replace(url);
            } else {
                const { userID, proctoringSessionID, onError } = this.props;

                let options = "";
                options += "recordvideo";
                options += ",recordaudio";
                options += ",recordscreen";
                options += ",recordwebtraffic";
                options += ",recordroomstart";
                options += ",verifyvideo";
                options += ",verifyaudio";
                options += ",verifydesktop";
                options += ",verifyidauto";
                options += ",verifysignature";
                options += ",fullscreenmoderate";
                options += ",clipboard";
                options += ",notabs";
                options += ",closetabs";
                options += ",onescreen";
                options += ",print";
                options += ",downloads";
                //options += ",cache";
                options += ",rightclick";
                options += ",noreentry";

                const host = window.location.host;

                assessmentApi
                    .getProctorioLaunchUrl(userID, proctoringSessionID, options, host)
                    .then((url) => {
                        //console.log("Proctorio URL");
                        //console.log(url);
                        //debugger;
                        //logout();
                        window.location.replace(url);
                    }, onError)
                    .catch(() => {
                        onError();
                    });
            }
        }
    }

    render() {
        return null;
    }
}

const mapStoreToProps = (store) => {
    const userSessionData = getUserSessionData(getSessionData(store));
    const proctorioData = getProctorioData(store);

    return {
        userID: getUserId(userSessionData),
        debugMode: getDebugMode(proctorioData),
    };
};

RedirectToProctorio = connect(mapStoreToProps)(RedirectToProctorio);

export { RedirectToProctorio };
