import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getProctorioData } from "redux/reducers/selectors";
import { getDebugMode } from "redux/reducers/proctorio/selectors";

class RedirectToExam extends Component {
    state = {};

    componentDidMount() {
        const { history, examGuid, debugMode } = this.props;

        if (!examGuid) {
            history.push(`/schedules`);
        }
        else{
        
            if (!debugMode){
                window.top.postMessage(
                    ["exam_state_change", "exam_take"],
                    "https://getproctorio.com"
                );
            }

            history.push(`/exam/${examGuid}`);
        }

    }

    render() {
        return null;
    }
}

RedirectToExam = withRouter(RedirectToExam);

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);
    const debugMode = getDebugMode(proctorioData)

    return {
        debugMode
    };
};

RedirectToExam = connect(mapStoreToProps)(RedirectToExam);

export { RedirectToExam };
