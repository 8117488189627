import React, { Component } from 'react';
import { connect } from "react-redux";

import { AppPageModifier } from "components/pages/app-page-modifier";

import {getAdminRedirectUrl, mapStoreToProps, mapDispatchToProps, mergeProps} from './helper'

class AdminRedirectUrl extends Component {
    state = {  }

    componentDidMount(){
        const { userID, orgID, adminUrl, logout } = this.props;
        const url = getAdminRedirectUrl(userID, orgID, adminUrl);

        logout();
        window.location.replace(url);
    }

    render() {
        const appBarProps = {
            title: "Redirecting to Admin",
            loadingTitle: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="admin-redirect-url"
                    appBarProps={appBarProps}
                    loading
                />
            </React.Fragment>
        );
    }
}

AdminRedirectUrl = connect(
    mapStoreToProps,
    mapDispatchToProps,
    mergeProps
)(AdminRedirectUrl);
 
export {AdminRedirectUrl};