
// npm
import React from 'react'

// xams-components
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {SchedulesForm} from './form'
import {InitializingSchedulesView} from './initializing_schedules/view'
import {SetNetworkError} from 'components/pages/network_error/set-network-error'

// machines
import {schedules} from 'machines/schedules'


const {STATES, EVENTS} = schedules;


const SchedulesPageMachine = () =>
{
	return (
		<StateMachine machine={schedules.machine}>
			<StateView>
				{{
					[STATES.INITIALIZING_SCHEDULES]: () => (
						InitializingSchedulesView()
					),
					[STATES.DISPLAYING_SCHEDULES]: () => (
						<StateControl onRefresh={EVENTS.REFRESH}>
							{({onRefresh}) => <SchedulesForm onRefresh={onRefresh}/>}
						</StateControl>
					),
					[STATES.INITIALIZATION_ERROR]: () => (
						<SetNetworkError/>
					)
				}}
			</StateView>
		</StateMachine>
	)
}


export {SchedulesPageMachine}