// utils
import { uploadApi } from 'libs/api/interface/api-upload';
import { Console, LOG_TYPES } from 'custom/console';

import {
  activityLogger,
  ACTIVITIES,
} from "libs/activity_logger/activity-logger";

let uploadObject = { files: [], index: 0, oldFileIndex: -1 };

const mapApiJsonToFile = json => {
  return {
    index: json.guid,
    name: json.fileName,
    type: json.mimeType,
    date: json.dateTimeUploaded,
    size: json.fileSize
  };
};

export function getFiles(formRunGuid, onSuccess, onFailure) {
  uploadObject.finishedCallback = onSuccess;
  uploadObject.failureCallback = onFailure;
  uploadApi.getFiles(formRunGuid).then(filesGot, filesGotFailed);
}
function filesGot(response) {
  const parsedResponse = JSON.parse(response);
  let files = [];
  for (let i = 0; i < parsedResponse.length; i++)
    files.push(mapApiJsonToFile(parsedResponse[i]));
  uploadObject.finishedCallback(files);
}
function filesGotFailed(response) {
  uploadObject.failureCallback([]);
}

export function deleteFile(file, deletedCallback, failedCallback) {
  if (file){
    const {name, size, type} = file;

    activityLogger.log(ACTIVITIES.DELETE_UPLOAD_ONLINE, {name, size, fileType: type});
  }

  uploadObject.file = file;
  uploadObject.deletedCallback = deletedCallback;
  uploadObject.failedCallback = failedCallback;
  uploadApi.deleteFile(file.index).then(fileDeleted, fileDeletedFailed);
}
function fileDeleted() {
  uploadObject.deletedCallback(uploadObject.file);
}
function fileDeletedFailed() {
  uploadObject.failedCallback(uploadObject.file);
}

export function uploadFiles(
  formRunGuid,
  files,
  progressCallback = null,
  uploadedCallback,
  failedCallback
) {
  uploadObject.files = files;
  uploadObject.uploadedSoFar = 0;
  uploadObject.uploadedTotal = 0;
  for (let i = 0; i < files.length; i++) {
    //const size=files[i].file===undefined?files[i].size:
    uploadObject.uploadedTotal += files[i].file.size;
  }
  uploadObject.formRunGuid = formRunGuid;
  uploadObject.index = 0;
  uploadObject.progressCallback = progressCallback;
  uploadObject.uploadedCallback = uploadedCallback;
  uploadObject.failedCallback = failedCallback;
  uploadObject.oldFileIndex = -1;
  uploadFile();
}

function uploadFile() {
  const firstFileUploaded = uploadObject.index === 0;
  if (firstFileUploaded && uploadObject.progressCallback !== null) {
    uploadObject.progressCallback(0);
  }
  const fileToUpload =
    uploadObject.files[uploadObject.index].file !== undefined
      ? uploadObject.files[uploadObject.index].file
      : uploadObject.files[uploadObject.index];
    
  if (fileToUpload){
    const {name, size, type} = fileToUpload;

    activityLogger.log(ACTIVITIES.UPLOAD_ONLINE, {name, size, fileType: type});
  }

  uploadApi
    .uploadFile(
      uploadObject.formRunGuid,
      fileToUpload,
      uploadObject.progressCallback !== null ? fileUploadProgress : null
    )
    .then(fileUploaded, fileUploadedFail);
}
const fileUploadProgress = uploaded => {
  const uploadedSoFar = uploadObject.uploadedSoFar + uploaded;
  const percent = parseInt(
    (uploadedSoFar / uploadObject.uploadedTotal) * 100,
    10
  );
  Console.log('fileUploadProgress', LOG_TYPES.TEMP);
  Console.log('uploaded, ' + uploaded, LOG_TYPES.TEMP);
  Console.log('uploadedSoFar, ' + uploadedSoFar, LOG_TYPES.TEMP);
  Console.log('uploadedTotal, ' + uploadObject.uploadedTotal, LOG_TYPES.TEMP);
  Console.log('percent, ' + percent, LOG_TYPES.TEMP);
  if (uploadObject.progressCallback != null)
    uploadObject.progressCallback(percent);
};
function fileUploaded(response) {
  const parsedResponse = JSON.parse(response);
  const fileToUpload =
    uploadObject.files[uploadObject.index].file !== undefined
      ? uploadObject.files[uploadObject.index].file
      : uploadObject.files[uploadObject.index];
  const uploadedFile = mapApiJsonToFile(parsedResponse);
  uploadObject.uploadedSoFar += uploadedFile.size;
  let position = -1;
  if (uploadObject.oldFileIndex !== -1) {
    position = uploadObject.oldFileIndex + uploadObject.index;
    // tempFiles.splice(position, 0, uploadedFile);
  } else {
    position = -1;
    // tempFiles.push(uploadedFile);
  }
  uploadObject.index++;
  const finishedUpload = uploadObject.index === uploadObject.files.length;
  uploadObject.uploadedCallback(
    { fileToUploadIndex: fileToUpload.index, uploadedFile, position },
    finishedUpload
  );
  if (!finishedUpload) uploadFile();
}
function fileUploadedFail(response) {
  const fileToUpload =
    uploadObject.files[uploadObject.index].file !== undefined
      ? uploadObject.files[uploadObject.index].file
      : uploadObject.files[uploadObject.index];
  uploadObject.failedCallback({
    fileToUploadIndex: fileToUpload.index,
    name: fileToUpload.name
  });
}

export function replaceFiles(
  formRunGuid,
  fileToReplace,
  fileToReplacePosition,
  filesToUpload,
  deleteCallback,
  progressCallback,
  finishedCallback
) {
  uploadObject.files = filesToUpload;
  uploadObject.uploadedSoFar = 0;
  uploadObject.uploadedTotal = 0;
  for (let i = 0; i < filesToUpload.length; i++) {
    uploadObject.uploadedTotal += filesToUpload[i].size;
  }
  uploadObject.formRunGuid = formRunGuid;
  uploadObject.index = 0;
  uploadObject.deleteCallback = deleteCallback;
  uploadObject.progressCallback = progressCallback;
  uploadObject.uploadedCallback = finishedCallback;
  uploadObject.oldFileIndex = fileToReplacePosition;
  deleteFile(fileToReplace, replaceFilesUpload);
}
function replaceFilesUpload(file) {
  uploadObject.deleteCallback(file);
  uploadFile();
}


export function downloadFile(uploadGuid) {
  uploadApi.downloadFile(uploadGuid).then(({blob, fileName}) => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.target = "_blank";
    a.dispatchEvent(new MouseEvent('click'));
  });
}