
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material design
import {MaterialText} from 'components/presentation/material-text'

// constants
import {BROWSER_IMAGES} from './constants'

// @xams-entities
import {Browser} from '@xams-entities/browser'

// css
import './supported-browser.css'


const displayVersionsText = (versionsText, isIncludeText) => {
  if (!versionsText) {
    return null;
  }

  const startingText = isIncludeText ? '' : 'excluding ';
  
  return versionsText.map(text => (
    <div>
      <MaterialText variant="h6" className="supportedBrowserText">
          {`${startingText}${text}`}
        </MaterialText>
    </div>
  ));
}

class SupportedBrowser extends React.Component
{
  render()
  {
    const {include, exclude} = this.props;
  	return (
      <div className="supportedBrowser">
        {this.image}
        <div>
          {this.heading}
          {displayVersionsText(include, true)}
          {displayVersionsText(exclude, false)}
        </div>
      </div>
    );
  }

  get image()
  {
    const {browser} = this.props;
    const {name} = browser;
    const imageIndex = BROWSER_IMAGES.indexOf(name);
    return imageIndex === -1 ? <div>&nbsp;</div> : <div className={name} />;
  }

  get heading()
  {
    const {browser} = this.props;
    return (
    	<div>
        <MaterialText variant='h5' className="supportedBrowserText">
        	{Browser.toString(browser)}
        </MaterialText>
      </div>
    )
  }
}


SupportedBrowser.propTypes = {
  browser: PropTypes.object.isRequired,
  include: PropTypes.arrayOf(
    PropTypes.object
  ),
  exclude: PropTypes.arrayOf(
    PropTypes.object
  )  
};


export {SupportedBrowser}