import { check } from "@xams-utils/check-types";

const isAudio = (_url) => {
    const url=check.object(_url)?_url.url:_url;

    if (check.nonEmptyString(url)){
        const formats=["mp3","m4a"];

        return formats.some(format=>url.indexOf(format) !== -1);
    }
    return false;
    
    // return (check.nonEmptyString(url))?url.indexOf(".mp3") !== -1:false;

};

const getAudioProps=(audioObj)=>{
    const props={}
    
    for (const key in audioObj) {
        if (key!=='elapsed'){
            props[key]=audioObj[key];
        }
    }

    return props;
}

const getMediaPropsFromSection=(section)=>{
    const props={}
    
    if (check.nonEmptyObject(section)){
        const {data}=section;
        if (check.nonEmptyObject(data)){
            const {media}=data;
            if (check.nonEmptyObject(media)){            
                if (isAudio(media)){
                    props.audio={...media}
                }
            }
        }
    }

    return props;
}

const urlHasChanged=(audio, prevAudio)=>{
    if (audio && prevAudio){
        return audio.url!==prevAudio.url;
    }
    return audio || prevAudio;
}

const hasAudioStateChanged=(audio, prevAudio)=>{
    if (audio && prevAudio){
        return audio.state!==prevAudio.state;
    }
    return audio || prevAudio;
}

export {isAudio,getAudioProps, getMediaPropsFromSection, urlHasChanged,hasAudioStateChanged}