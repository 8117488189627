// xams-utils
import { check } from "@xams-utils/check-types";
import moment from "moment";
import PouchDB from "pouchdb-browser";
import { incrementTimeStamp, getTimeStamp } from "utils/time-stamp";

class DBApi {
    constructor(keys) {
        this.keys = [...keys];
        this.lastID = { value: null };
        this.dbs = {};
        this.initialized = { value: false };

        this.initialize();
    }

    initialize() {
        return new Promise((resolve) => {
            this.initializeDB(0).then(() => {
                this.initializeDB(1).then(() => {
                    this.initializeDB(2).then(() => {
                        this.initialized.value = true;
                        resolve();
                    });
                });
            });
        });
    }

    initializeDB(key) {
        return new Promise((resolve) => {
            const db = this.keys[key];
            this.dbs[db] = new PouchDB(db);

            this.dbs[db]
                .viewCleanup()
                .then((result) => {
                    resolve();
                })
                .catch((err) => {
                    debugger;
                });
        });
    }

    isInitialised() {
        return this.initialized.value;
    }

    removeRecords(db, records) {
        records.forEach((record) => {
            db.get(record.id).then((doc) => {
                return db.remove(doc);
            });
        });
    }

    add(key, data) {
        if (!check.object(data) && !check.array(data)) {
            throw "storage data must be an object";
        }
        const db = this.getDB(key);

        if (check.object(data)) {
            let id = !data.id ? getTimeStamp() : "" + data.id;
            if (data.id) delete data.id;

            // if (this.lastID.value === id) id = incrementTimeStamp(id);

            if (
                this.lastID.value &&
                moment(id).isSameOrBefore(this.lastID.value)
            ) {
                id = incrementTimeStamp(this.lastID.value);
            }

            this.lastID.value = "" + id;

            const newRecord = {
                _id: id,
                ...data,
            };

            return new Promise((resolve) => {
                db.put(newRecord)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((e) => {
                        if (e.name === "conflict") {
                            const id = getTimeStamp();
                            this.lastID.value = "" + id;

                            const newRecord = {
                                _id: id,
                                ...data,
                            };

                            db.put(newRecord)
                                .then((response) => {
                                    resolve(response);
                                })
                                .catch((e) => {
                                    resolve();
                                });
                        } else {
                            resolve();
                        }
                    });
            });
        }

        if (data.length > 0) {
            const lastItem = data[data.length - 1];
            const lastId = "" + lastItem._id;
            this.lastID.value = "" + lastId;
        }

        return db.bulkDocs(data);
    }

    retrieveAll(key, getRev = false) {
        const db = this.getDB(key);
        if (!db) debugger;
        return new Promise((resolve, reject) => {
            db.allDocs({
                include_docs: true,
                //descending: true,
            })
                .then(function (result) {
                    const _results = result.rows.map((row) => {
                        const data = { ...row.doc };
                        data.id = row.doc._id;
                        delete data._id;
                        if (!getRev) delete data._rev;

                        return data;
                    });
                    resolve(_results);
                })
                .catch(function (err) {
                    debugger;
                    reject([]);
                });
        });
    }

    remove(key, data) {
        if (!check.array(data)) {
            throw "storage data must be an array";
        }

        const db = this.getDB(key);

        return db.bulkDocs(data);
    }

    getDB(key) {
        return this.dbs[key];
    }

    compactDB(key) {
        const db = this.getDB(key);
        return db.compact();
    }

    saveFile(key, data) {
        const db = this.getDB(key);
        data._id = getTimeStamp();

        return db.put(data);
    }

    getFile(key, file) {
        const db = this.getDB(key);
        const { id } = file;

        return db.get(id, { attachments: true });
    }

    get(key, id) {
        const db = this.getDB(key);

        return db.get(id);
    }

    reset(key) {
        return new Promise((resolve) => {
            if (!this.dbs[key]) {
                this.dbs[key] = new PouchDB(key);
                resolve();
            } else {
                this.dbs[key]
                    .destroy()
                    .then(() => {
                        this.dbs[key] = null;
                        this.dbs[key] = new PouchDB(key);
                        resolve();
                    })
                    .catch((err) => {
                        debugger;
                        resolve();
                    });
            }
        });
    }

    delete(key) {
        return new Promise((resolve) => {
            if (!this.dbs[key]) {
                resolve();
            } else {
                this.dbs[key]
                    .destroy()
                    .then(() => {
                        this.dbs[key] = null;
                        resolve();
                    })
                    .catch((err) => {
                        debugger;
                        resolve();
                    });
            }
        });
    }
}

export { DBApi };
