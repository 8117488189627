import React from 'react';
import { check } from "@xams-utils/check-types";

import { MaterialText } from "components/presentation/material-text";

const getWordCountOld = (text) => {
    if (!check.string(text)) return 0;
    if (text.trim() === "") return 0;

    return text.trim().split(" ").length;
};

const getWordCount = (text) => {
    return check.nonEmptyString(text)
        ? text.split(" ").filter(function (n) {
            return n != "";
        }).length
        : 0;
}

const WordCount = ({ text }) => {
    const wordCount = getWordCount(text)
    const label = wordCount === 1 ? "1 WORD" : `${wordCount} WORDS`;

    return <div><MaterialText>{label}</MaterialText></div>

}

export { WordCount };