
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {Popup} from 'components/layout/popup'
import {CancelButton} from './buttons/cancel'
import {ConfirmButton} from './buttons/confirm'
import {withMessages} from 'components/hocs/messages'

// utils
import {detokenizeMessage} from 'utils/detokenize-message'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'
import {MESSAGE_TOKENS} from 'constants/message-tokens'


const finishConfirmPartTitle2 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_PART_TITLE_2;
const finishConfirmPart2 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_PART_INFO_2;


let PartFinishConfirmPopup2 = ({onClose, onConfirm, messages, data}) =>
{
	const tokenMappings = {
		[MESSAGE_TOKENS.CURRENT_PAPER_PART]: data.paperPartName,
		[MESSAGE_TOKENS.NEXT_PAPER_PART]: data.nextPaperPartName
	}

	const popupTitle = messages[finishConfirmPartTitle2];
	const popupText = messages[finishConfirmPart2];

	const props = {
		title: detokenizeMessage(popupTitle, tokenMappings),
		content: {text: detokenizeMessage(popupText, tokenMappings)},
		buttons: [
			<ConfirmButton onClick={onConfirm}/>,
			<CancelButton onClick={onClose}/>
		],
		onClose
	}

	return <Popup {...props}/>;
}

PartFinishConfirmPopup2.propTypes = {
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[finishConfirmPartTitle2]: PropTypes.string.isRequired,
		[finishConfirmPart2]: PropTypes.string.isRequired
	}).isRequired
}


PartFinishConfirmPopup2 = withMessages(PartFinishConfirmPopup2);


export {PartFinishConfirmPopup2}