// npm
import React from 'react'
import check from 'check-types'
import {Parser, ProcessNodeDefinitions} from 'html-to-react'

// react
import {Image} from 'components/presentation/image'

// utils
import {htmlAttributesToProps} from './html-attributes-to-props'


const isValidNode = () => true
const htmlParser = new Parser();
const nodeProcessingDefinitions = new ProcessNodeDefinitions(React);


// node:
// {
// 	type: "tag", 							// tag/text
// 	attribs: {},
// 	next: node,
// 	prev: node,
// 	parent: node,

// 	// WHEN TYPE="text"...
// 	data: string?,

// 	// WHEN TYPE="tag"...
// 	name: "p",
// 	children: array<node>
// }


const processingInstructions = [
	{
		shouldProcessNode: (node) => {
			return node.name === 'img';
		},
		processNode: function(node) {
			const props = htmlAttributesToProps(node.attribs);
			return React.createElement(Image, props);
		}
	},
	{
		shouldProcessNode: isValidNode,
		processNode: nodeProcessingDefinitions.processDefaultNode
	}
]


const createError = (text) => (
	`htmlToReact: Must parse string. Got ${text}`
);

const htmlToReact = {
	parse: (text) => {
		if (check.emptyString(text)) { return text; }
		if (!check.string(text)) { throw createError(text); }
		if (!text.includes('<')) { return text; }
		return htmlParser.parseWithInstructions(text, isValidNode, processingInstructions);
	}
}


export {htmlToReact};