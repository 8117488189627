const { check } = require("@xams-utils/check-types");

const isImageProperty = (propertyValue) => {
    if (!check.string(propertyValue)) return false;

    if (propertyValue < 5) return false;

    const delimiter = propertyValue.substr(propertyValue.length - 4, 1);

    if (delimiter !== ".") return false;

    const stringEnd = propertyValue
        .substr(propertyValue.length - 3)
        .toLowerCase();
    const imageTypes = ["jpg", "png", "bmp"];

    return imageTypes.indexOf(stringEnd) !== -1;
};

const alreadyStartsWithResourceUrl = (propertyValue, resourceUrl) => {
    if (propertyValue.length >= resourceUrl.length) {
        const startString = propertyValue.substring(0, resourceUrl.length);

        if (startString.toLowerCase() === resourceUrl) return true;
    }

    return false;
};

const addResourceUrlBeforeRoot = (propertyValue, resourceUrl) => {
    const rootStart = propertyValue.toLowerCase().indexOf("root");

    if (rootStart !== -1) {
        return `${resourceUrl}${propertyValue.substring(rootStart)}`;
    }

    return null;
};

const getAscxImageUrl = (propertyValue, apiEndpointUrl) => {
    const resourceUrl = `${apiEndpointUrl}resource/`.toLowerCase();

    if (isImageProperty(propertyValue)) {
        if (alreadyStartsWithResourceUrl(propertyValue, resourceUrl)) {
            return propertyValue;
        }

        return addResourceUrlBeforeRoot(propertyValue, resourceUrl);
    }

    return null;
};

const isFormGridImage = (value) => {
    return false;
};

export { getAscxImageUrl, isFormGridImage };
