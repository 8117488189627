
// npm
import React from 'react'

// react
import {AppPage} from './pages/app-page'

// css
import './app.css'


let App = () =>
{
	return <AppPage/>;
}


export {App}