import React from "react";
import { connect } from "react-redux";

import { isResetScheduleCheatActive } from "redux/reducers/settings/app/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getSettingsData } from "redux/reducers/selectors";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

let ResetScormButton = (props) => {
    const { useResetScheduleCheat, onClick } = props;

    if (true || !useResetScheduleCheat) return null;

    const buttonProps = {
        edge: "end",
        ariaLabel: "reset",
        onClick,
    };

    return (
        <Hidden xsDown>
            <IconButton {...buttonProps}>
                <DeleteIcon />
            </IconButton>
        </Hidden>
    );
};

const mapStoreToProps = (store) => ({
    useResetScheduleCheat: isResetScheduleCheatActive(
        getAppSettingsData(getSettingsData(store))
    ),
});

ResetScormButton = connect(mapStoreToProps)(ResetScormButton);

export { ResetScormButton };
