import { api } from "../api";
import { ENDPOINTS } from "../constants";

const subscribe = (query, groupID) => {
  console.log(ENDPOINTS.DASH.EVENTS);
    return api.subscribeSignalR(ENDPOINTS.DASH.EVENTS, query, groupID);
};

const dashApi = {
    subscribe: (apiData) => {
        return subscribe(apiData);
    },
};

export { dashApi };
