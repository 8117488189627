// npm
import React from "react";
import PropTypes from "prop-types";

// material-ui
import IconButton from "@material-ui/core/IconButton";
import MobileStepper from "@material-ui/core/MobileStepper";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

// constants
import { ID_TYPE } from "../constants";

const calcSelectedNode = (selected, nodes) => {
  const currentNode = selected
    ? nodes.findIndex((node) => node.id === selected)
    : 0;

  return currentNode === -1 ? 0 : currentNode;
};

class SectionNavigationItem extends React.Component {
  constructor(props) {
    super(props);

    const { selected, nodes } = props;
    const currentNode = calcSelectedNode(selected, nodes);

    this.state = { currentNode };
  }

  componentDidUpdate(prevProps) {
    const { selected, nodes } = this.props;
    if (prevProps.selected !== selected) {
      const currentNode = calcSelectedNode(selected, nodes);
      this.setState({ currentNode });
    }
  }

  render() {
    const props = {
      position: "static",
      steps: this.props.nodes.length,
      activeStep: this.state.currentNode,
      nextButton: this.getNavigationButton(1),
      backButton: this.getNavigationButton(-1),
    };

    return (
      <div style={{ width: "100%" }}>
        {this.props.nodes[this.state.currentNode].node}
        <MobileStepper {...props} />
      </div>
    );
  }

  getNavigationButton(direction) {
    const { nodes } = this.props;
    const nextNode = this.state.currentNode + direction;

    const onClick = () => {
      this.setState({ currentNode: nextNode });
      this.props.onChange(nodes[nextNode].id);
    };

    const disabled = nextNode < 0 || nextNode === nodes.length;

    const Icon = direction < 0 ? NavigateBeforeIcon : NavigateNextIcon;
    return (
      <IconButton disabled={disabled} onClick={onClick}>
        <Icon />
      </IconButton>
    );
  }
}

SectionNavigationItem.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: ID_TYPE.isRequired,
      node: PropTypes.node.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { SectionNavigationItem };
