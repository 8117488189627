
// npm
import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getProctoringMode} from 'redux/reducers/settings/client/selectors'

// react
import {ExamPasswordFormMachine} from './form/machine'
import {AppPageModifier} from 'components/pages/app-page-modifier'


let ExamPasswordPage = ({ onFinish, proctoringMode }) => {
    const title = "Password";
	const logout = proctoringMode == null;

    return (
        <React.Fragment>
            <AppPageModifier
                id="exam-password"
                appBarProps={{ title, logo: true, logout }}
            />
            <ExamPasswordFormMachine onFinish={onFinish} />
        </React.Fragment>
    );
};

ExamPasswordPage.propTypes = {
	onFinish: PropTypes.func.isRequired
}

const mapStoreToProps = (store) => ({
	proctoringMode: getProctoringMode(getClientSettingsData(getSettingsData(store)))
});

ExamPasswordPage = connect(mapStoreToProps)(ExamPasswordPage);


export {ExamPasswordPage}