import React from "react";
import { connect } from "react-redux";

import { isResetScheduleCheatActive } from "redux/reducers/settings/app/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getSettingsData } from "redux/reducers/selectors";

import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Hidden from "@material-ui/core/Hidden";

import { getStatusButtonText } from "../helper";

const styles = ({ spacing, palette, breakpoints }) => ({
    button: {
        [breakpoints.up('sm')]: {
            width: 120,
        },
        [breakpoints.down('xs')]: {
            paddingLeft: 0,
            minWidth: "0px !important",
        },        
        backgroundColor: palette.secondary.main + "!important",
        color: palette.secondary.contrastText + "!important",
    },
    rightIcon: {
        marginLeft: spacing.unit,
    },
});

let PlayScormButton = (props) => {
    const { classes, status, useResetScheduleCheat, onClick } = props;

    const buttonProps = {
        variant: "contained",
        color: useResetScheduleCheat ? "primary" : null,
        size: "small",
        className: !useResetScheduleCheat ? classes.button : null,
        onClick,
    };

    return (
        <Button {...buttonProps}>
            <Hidden xsDown>{getStatusButtonText(status)}</Hidden>
            {!useResetScheduleCheat && (
                <ArrowForwardIcon className={classes.rightIcon} />
            )}
        </Button>
    );
};

const mapStoreToProps = (store) => ({
    useResetScheduleCheat: isResetScheduleCheatActive(
        getAppSettingsData(getSettingsData(store))
    ),
});

PlayScormButton = connect(mapStoreToProps)(PlayScormButton);

PlayScormButton = withStyles(styles)(PlayScormButton);

export { PlayScormButton };
