
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import Button from '@material-ui/core/Button'

// react
import {BugReportForm} from './form'
import {SubmitButton} from './submit-button'
import {CancelButton} from './cancel-button'
import {Popup} from 'components/layout/popup'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getUserGuid} from 'redux/reducers/session/user/selectors'

// utils
import {findUserActivityByQuery} from './query-logs'
import {errorApi} from 'libs/api/interface/api-error'


const title = "Bug Submission Form";


class BugReportPopup extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			canSubmit: false
		}

		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		let formData = {};

		this.updateFormData = (formValues, valid) => {
			formData = {...formValues};

			if (valid !== this.state.canSubmit) {
				this.setState({canSubmit: valid});
			}
		}

		this.submitForm = () => {
			const payload = {
				reporterGuid: this.props.reporterGuid,
				bugDescription: formData.bugDescription,
				userActivity: findUserActivityByQuery(formData)
			};

			errorApi.sendBugReport(payload)
			.then(() => {
				alert("Your bug has been reported! We will investigate it shortly");
				this.props.onClose();
			})
			.catch(() => {
				alert("Bug submission failed. Please try again in a few minutes");
			});
		}
	}

	render()
	{
		const popupProps = {
			title,
			canClickBackdrop: true,
			content: {node: <BugReportForm onChange={this.updateFormData}/>},
			buttons: [this.SubmitButton, <CancelButton onClick={this.props.onClose}/>]
		}

		return <Popup {...popupProps}/>;
	}

	get SubmitButton()
	{
		const props = {
			enabled: this.state.canSubmit,
			onClick: this.submitForm
		}

		return <SubmitButton {...props}/>;
	}
}

BugReportPopup.propTypes = {
	onClose: PropTypes.func.isRequired,
	reporterGuid: PropTypes.string.isRequired
}


const mapStoreToProps = (store) =>
{
	const userSessionData = getUserSessionData(getSessionData(store));
	return {reporterGuid: getUserGuid(userSessionData)}
}

BugReportPopup = connect(mapStoreToProps)(BugReportPopup);


export {BugReportPopup}