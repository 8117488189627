const getOptionText = (optionData) => optionData.get('text');
const getAnswerText = (optionData) => optionData.get('answer');
const getAnswerMsgPK = (optionData) => optionData.get('answerMsgPK');
const getOptionId = (optionData) => optionData.get('id');
const getSelectedId = (optionData) => optionData.get('selectedId');

export {
	getOptionText, 
	getOptionId, 
	getAnswerText, 
	getAnswerMsgPK,
	getSelectedId
}