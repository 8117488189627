
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

// material-ui
import Button from '@material-ui/core/Button'

// redux (actions)
import {clearExamPaper} from '../actions'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// Not connected
// --------------------------------------------------------------------------

let ImmediateResitButton = ({history, match:{params:{examGuid}}, messages}) =>
{
	const onClick = () => {
		setTimeout(() => history.replace(`/exam/${examGuid}`), 10);
		history.push('/schedules');
	}

	const buttonText = messages[MESSAGE_IDS.EXAM.SIT_NEXT_RESIT];
	return <Button variant="contained" color="primary" onClick={onClick} fullWidth>{buttonText}</Button>;
}

ImmediateResitButton.propTypes = {
	clearExamPaper: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	messages: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			examGuid: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}


// Connected to router
// --------------------------------------------------------------------------

ImmediateResitButton = withRouter(ImmediateResitButton);


// Conenected to store
// --------------------------------------------------------------------------

const mapDispatchToProps = (dispatch) => ({
	clearExamPaper: () => dispatch(clearExamPaper())
});

ImmediateResitButton = connect(undefined, mapDispatchToProps)(ImmediateResitButton);
ImmediateResitButton = withMessages(ImmediateResitButton);


// Export
// --------------------------------------------------------------------------
export {ImmediateResitButton}