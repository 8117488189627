
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'


// DesktopActionButton (not connected to styles)
// ----------------------------------------------------------------------

let DesktopActionButton = (props) =>
{
	const {Icon, alignment, classes, children, ...buttonProps} = props;

	const buttonComponentProps = {
		className: classes.button,
		classes: {disabled: classes.buttonDisabled},
		...buttonProps
	}

	return (
		<Button {...buttonComponentProps}>
			{alignment === 'left' && <Icon className={classes.iconLeft}/>}
			{children}
			{alignment === 'right' && <Icon className={classes.iconRight}/>}
		</Button>
	);
}

DesktopActionButton.propTypes = {
	Icon: PropTypes.oneOfType([
		PropTypes.instanceOf(React.Component),
		PropTypes.func
	]).isRequired,
	alignment: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired
	// remaining props are spread to Button component
}

DesktopActionButton.defaultProps = {
	color: 'primary',
	size: 'small',
	variant: 'contained',
	alignment: 'left'
}


// DesktopActionButton (connected to styles)
// ----------------------------------------------------------------------

const styles = (theme) =>
{
	const spacing = theme.spacing.unit;
	const {palette} = theme;

	return {
		button: {
			marginLeft: spacing,
			marginRight: spacing
		},
		buttonDisabled: {
			backgroundColor: palette.background.light + "!important",
			color: palette.background.contrastText + "!important"
		},
		iconLeft: {
			marginRight: spacing
		},
		iconRight: {
			marginLeft: spacing
		}
	}
}

DesktopActionButton = withStyles(styles)(DesktopActionButton);


// Export
// ----------------------------------------------------------------------
export {DesktopActionButton}