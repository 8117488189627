// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// libs
import {configApi} from 'libs/api/interface/api-config'

// redux (actions)
import {saveSettings} from './actions'


// SettingsInitializer (not connected to store)
// --------------------------------------------

class SettingsInitializer extends React.Component
{
  componentDidMount()
  {
    configApi.getConfig().then(this.props.saveSettings, this.props.onFail);
  }

  render()
  {
    return null;
  }
}

SettingsInitializer.propTypes = {
  saveSettings: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired
};


// SettingsInitializer (connected to store)
// --------------------------------------------

const mapDispatchToProps = (dispatch, {onSuccess}) => ({
  onSuccess: undefined,
  saveSettings: parsedResponse => {
    dispatch(saveSettings(parsedResponse));
    onSuccess();
  }
});

SettingsInitializer = connect(undefined, mapDispatchToProps)(SettingsInitializer);

SettingsInitializer.propTypes = {
  onSuccess: PropTypes.func.isRequired
};


// Export
// --------------------------------------------
export {SettingsInitializer}