
// npm
import React from 'react'

// react
import {Popup} from 'components/layout/popup'
import {CloseErrorButton} from 'components/pages/exam/initialization/close-error-button'


const ScheduleNotFoundPopup = () =>
{
	const props = {
		title: 'Schedule not found',
		buttons: [<CloseErrorButton/>],
		canClickBackdrop: false
	}

	return <Popup {...props}/>;
}


export {ScheduleNotFoundPopup}