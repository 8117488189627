
// xams-utils
import {check} from '@xams-utils/check-types'


class StorageApi
{
	constructor(storageObject, keys)
	{
		this.storage = storageObject;
		this.keys = [...keys];
		this.initializeDefaultStorageValues();
	}

	initializeDefaultStorageValues()
	{
		this.emptyKeys.forEach(key => {
			this.resetDataFor(key);
		});
	}

	retrieveDataFrom(key)
	{
		this.validateStorageExists(key);
		const serializedState = this.storage.getItem(key);
		if (!serializedState) { alert(`Storage key '${key}' not initialized`); }
		return JSON.parse(serializedState);
	}

	saveDataTo(key, data)
	{
		if (!check.object(data)) { throw "storage data must be an object"; }
		const serializedState = JSON.stringify(data);
		this.storage.setItem(key, serializedState);
	}

	hasData(key)
	{
		this.validateStorageExists(key);
		const serializedState = this.storage.getItem(key);
		return serializedState && serializedState !== "{}";
	}

	resetAllData()
	{
		this.storageTypes.forEach(key => {
			this.resetDataFor(key)
		});
	}

	resetDataFor(key)
	{
		this.storage.setItem(key, JSON.stringify({}));
	}

	get emptyKeys()
	{
		return this.keys.filter(key => {
			return this.storage.getItem(key) === null;
		})
	}

	validateStorageExists(key)
	{
		if (!this.keys.includes(key)) {
			alert(`No '${key}' storage type`);
		}
	}
}


export {StorageApi}