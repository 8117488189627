
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {SessionLoader} from './session-loader'
import {SessionVerifier} from './session-verifier'
import {LoginUrlChecker} from './login-url-checker'
import {SessionInitializer} from './session-initializer'
import {LoginUrlInitializer} from './login-url-initializer'

// machines
import {init} from 'machines/init'


const {EVENTS, INITIALIZING_CLIENT_STATES:STATES} = init;


const InitializingClientView = () => (
	<StateView>
		{{
			[STATES.CHECKING_LOGIN_ATTEMPT]: () => (
				<StateControl onLoginUrl={EVENTS.LOGIN_ATTEMPT} onOtherUrl={EVENTS.NO_LOGIN_ATTEMPT}>
					{(props) => <LoginUrlChecker {...props}/>}
				</StateControl>
			),
			[STATES.LOADING_SESSION]: () => (
				<StateControl onLoad={EVENTS.SESSION_LOADED}>
					{({onLoad}) => <SessionLoader onLoad={onLoad}/>}
				</StateControl>
			),
			[STATES.VERIFYING_SESSION]: () => (
				<StateControl onSuccess={EVENTS.VALID_SESSION} onFail={EVENTS.INVALID_SESSION}>
					{(props) => <SessionVerifier {...props}/>}
				</StateControl>
			),
			[STATES.INITIALIZING_SESSION]: () => (
				<StateControl onSuccess={EVENTS.SESSION_INITIALIZED} onFail={EVENTS.INITIALIZE_FAILED} onError={EVENTS.ERROR}>
					{(props) => <SessionInitializer {...props}/>}
				</StateControl>
			),
			[STATES.INITIALIZING_LOGIN_URL]: () => (
				<StateControl onFinish={EVENTS.CLIENT_INITIALIZED}>
					{({onFinish}) => <LoginUrlInitializer onFinish={onFinish}/>}
				</StateControl>
			),
		}}
	</StateView>
)


export {InitializingClientView}