
const getElearning = (elearningData) => {
	return elearningData.get('elearning').toJS();
}

const getElearningCount = (eLearningData) => {
	return eLearningData.get('elearning').size;
}

const getShowCompleted = (eLearningData) => {
	return eLearningData.get('showCompleted')
}

const getShowCourseInfo = (eLearningData) => {
	return eLearningData.get('showCourseInfo')
}

const getCourseInfo = (eLearningData, courseId) => {
	debugger;
}

export {
	getElearning,
	getElearningCount,
	getShowCompleted,
	getShowCourseInfo,
	getCourseInfo
}