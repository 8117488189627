import { check } from "@xams-utils/check-types";
import { dbStorageApi, KEYS } from "libs/browser_storage/db-storage-api";

import { ensureUniqueIDS, encryptValue } from "./activity-logger-helper";
import { getTimeStamp } from "utils/time-stamp";
import { maintainActivityLog } from "./activity-logger-maintain";
import { ActivityLoggerQuestion } from "./activity-logger-question";
import { activityLoggerSent } from "./activity-logger-sent";
import { createDummyData } from "./dummy_data/create-dummy-date";

const ACTIVITIES = {
    LOCAL_ANSWER: 0,
    SERVER_ANSWER: 1,
    WORKINGS: 2,
    MOVE_QUESTION: 3,
    MOVE_NEXT_QUESTION: 4,
    MOVE_PREVIOUS_QUESTION: 5,
    START_EXAM: 6,
    FINISH_EXAM: 7,
    TIMED_OUT: 8,
    RESUME_EXAM: 9,
    SET_FORM_RUN_GUID: 10,
    MOVE_SECTION: 11,
    OFFLINE: 12,
    ONLINE: 13,
    UPLOAD_ONLINE: 14,
    UPLOAD_OFFLINE: 15,
    DELETE_UPLOAD_ONLINE: 16,
    DELETE_UPLOAD_OFFLINE: 17,
    CONFIRM_POPUP: 18,
    DISPLAY_POPUP: 19,
    POPUP_ERROR: 20,
    EXAM_TIMER: 21,
    FULL_SCREEN: 22,
    CHANGE_SYSTEM_TIME: 23
};

class ActivityLogger {
    constructor() {
        this.formRunGuid = null;
        this.navigation = null;
        this.mode = 0;
        this.previewMode = false;
        this.lastType = null;
        this.initialised = false;
        this.maintained = false;

        this.noFormRunGuid = [];

        this.currentQuestion = new ActivityLoggerQuestion([
            ACTIVITIES.LOCAL_ANSWER,
            ACTIVITIES.SERVER_ANSWER,
            ACTIVITIES.WORKINGS,
        ]);

        //dbStorageApi.initialize();

        // dbStorageApi.initialize().then(() => {
        //     activityLoggerSent.initialise();
        // });
    }

    initialise() {
        return new Promise((resolve) => {
            if (this.previewMode) {
                this.initialised = true;
                resolve();
            } else {
                this.initialiseActivityLog(resolve);
            }
        });
    }

    initialiseActivityLog(resolve) {
        if (dbStorageApi.isInitialised()) {
            activityLoggerSent.initialise().then(() => {
                this.initialised = true;
                resolve();
            });
        } else {
            this.timer = setTimeout(() => {
                this.initialiseActivityLog(resolve);
            }, 500);
        }
    }

    maintain() {
        if (this.previewMode) {
            return new Promise((resolve) => {
                this.maintained = true;
                resolve();
            });
        }

        // return new Promise((resolve) => {
        //     createDummyData().then(()=>{
        //         this.maintained=true;
        //         resolve();
        //     });
        // });

        return new Promise((resolve) => {
            this.maintainActivityLog(resolve);
        });
    }

    maintainActivityLog(resolve) {
        if (dbStorageApi.isInitialised()) {
            activityLoggerSent.initialise().then(() => {
                dbStorageApi
                    .retrieveAll(KEYS.STUDENT_ACTIVITY, true)
                    .then((data) => {
                        maintainActivityLog(data).then(() => {
                            this.maintained = true;
                            resolve();
                        });
                    });
            });
        } else {
            this.timer = setTimeout(() => {
                this.maintainActivityLog(resolve);
            }, 500);
        }
    }

    log(type, value) {
        if (!this.isLogging()) {
            return new Promise((resolve) => {
                resolve();
            });
        }

        if (
            type === ACTIVITIES.ONLINE &&
            this.lastType === ACTIVITIES.FINISH_EXAM
        ) {
            return new Promise((resolve) => {
                resolve();
            });
        }

        this.lastType = type;
        //const value = encryptObject({..._value});

        if (this.formRunGuid && this.currentQuestion.canSave(type)) {
            this.currentQuestion.save(type, value);
            return;
        } else if (this.moveNextOrPreviousQuestion(type)) {
            this.setNavigation(type);
            return;
        } else if (this.finishOrMoveQuestion(type)) {
            this.currentQuestion.finish();
        }

        if (type === ACTIVITIES.SET_FORM_RUN_GUID)
            this.updateFormRunGuid(value.formRunGuid);
        else {
            if (type === ACTIVITIES.MOVE_QUESTION && this.navigation) {
                type = parseInt(this.navigation);
                this.navigation = null;
            }

            return this.addToDB(type, value);
        }
    }

    addToDB(type, value) {
        if (!this.formRunGuid) {
            this.noFormRunGuid = this.noFormRunGuid.concat([
                { _id: getTimeStamp(), type, value: encryptValue(value) },
            ]);
        } else {
            return dbStorageApi.add(KEYS.STUDENT_ACTIVITY, {
                formRunGuid: this.formRunGuid,
                type,
                value: encryptValue(value),
            });
        }
    }

    updateFormRunGuid(formRunGuid) {
        if (!this.isLogging()) return;

        if (!this.formRunGuid) {
            this.currentQuestion.setFormRunGuid(formRunGuid);
            const data = ensureUniqueIDS(
                this.noFormRunGuid.map((item) => ({ formRunGuid, ...item }))
            );

            this.noFormRunGuid = [];
            this.formRunGuid = formRunGuid;

            if (this.mode === 1) dbStorageApi.add(KEYS.STUDENT_ACTIVITY, data);
        } else {
            //debugger;
        }
    }

    moveNextOrPreviousQuestion(type) {
        return (
            [
                ACTIVITIES.MOVE_NEXT_QUESTION,
                ACTIVITIES.MOVE_PREVIOUS_QUESTION,
            ].indexOf(type) !== -1
        );
    }

    finishOrMoveQuestion(type) {
        return (
            [
                ACTIVITIES.FINISH_EXAM,
                ACTIVITIES.TIMED_OUT,
                ACTIVITIES.MOVE_QUESTION,
            ].indexOf(type) !== -1
        );
    }

    setNavigation(type) {
        this.navigation = type;
    }

    get(formRunGuid = null) {
        return new Promise((resolve) => {
            dbStorageApi.retrieveAll(KEYS.STUDENT_ACTIVITY).then((data) => {
                const activities = data
                    .filter(
                        (item) =>
                            !formRunGuid || item.formRunGuid === formRunGuid
                    )
                    .map((item) => {
                        const _item = { ...item };
                        delete _item.formRunGuid;
                        return _item;
                    });

                resolve(activities);
            });
        });
    }

    getLogs() {
        return new Promise((resolve) => {
            dbStorageApi
                .retrieveAll(KEYS.STUDENT_ACTIVITY)
                .then((activities) => {
                    const formRunGuids = activities.reduce(
                        (formRunGuids, activity) => {
                            const { formRunGuid, id } = activity;
                            if (
                                !formRunGuids.some(
                                    (_formRunGuid) =>
                                        _formRunGuid.formRunGuid === formRunGuid
                                )
                            ) {
                                const formRunGuidObj = {
                                    formRunGuid,
                                    id: parseInt(formRunGuids.length) + 1,
                                    date: id,
                                };
                                const sent = activityLoggerSent.get(
                                    formRunGuid
                                );
                                if (sent) formRunGuidObj.sent = sent.id;
                                //formRunGuids[formRunGuid] = formRunGuidObj;
                                return formRunGuids.concat(formRunGuidObj);
                            }
                            return formRunGuids;
                        },
                        []
                    );

                    resolve(formRunGuids);
                });
        });
    }

    remove(formRunGuid = null) {
        return new Promise((resolve) => {
            dbStorageApi
                .retrieveAll(KEYS.STUDENT_ACTIVITY, true)
                .then((data) => {
                    const activities = data
                        .filter(
                            (item) =>
                                !formRunGuid || item.formRunGuid === formRunGuid
                        )
                        .map((item) => ({
                            _id: item.id,
                            _rev: item._rev,
                            _deleted: true,
                        }));

                    dbStorageApi
                        .remove(KEYS.STUDENT_ACTIVITY, activities)
                        .then(() => {
                            resolve(true);
                        });
                });
        });
    }

    reset() {
        this.formRunGuid = null;
        this.navigation = null;
        this.noFormRunGuid = [];
        this.currentQuestion.reset();
    }

    sent(formRunGuid) {
        this.reset();
        return activityLoggerSent.sent(formRunGuid, this.mode !== 0);
    }

    setMode(_mode) {
        if (this.previewMode && _mode !== 0) {
            return;
        }
        if (check.number(_mode) && _mode >= 0 && _mode < 3) {
            this.mode = _mode;
            this.currentQuestion.setMode(_mode);
        }
    }

    isLogging() {
        return this.mode > 0;
    }

    setPreviewMode(_previewMode) {
        if (_previewMode) {
            this.previewMode = true;
            this.reset();
            this.setMode(0);
        }
    }
}

const activityLogger = new ActivityLogger();

export { activityLogger, ACTIVITIES };
