
// npm
import React from 'react'

// material-ui
import IconButton from '@material-ui/core/IconButton'
import BugReportIcon from '@material-ui/icons/BugReport'


const BugReportButton = ({onClick}) =>
{
	return (
		<IconButton onClick={onClick}>
			<BugReportIcon/>
		</IconButton>
	);
}


export {BugReportButton}