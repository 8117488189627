
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

// material-ui
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

// libs
import {api} from 'libs/api/api'
import {GENERIC_ERRORS} from 'libs/api/constants'

// react
import {MaterialText} from 'components/presentation/material-text'
import {withMessages} from 'components/hocs/messages'

// redux (actions)
import {getRemoveCurrentSessionActions} from '../actions'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getUserGuid} from 'redux/reducers/session/user/selectors'
import {getUrlToken} from 'redux/reducers/session/client/selectors'
import {getClientSessionData, getUserSessionData} from 'redux/reducers/session/selectors'

// libs
import {authenticationApi} from 'libs/api/interface/api-authentication'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


// DoubleLogin (not connected to the store/router)
// ----------------------------------------------------------------------------

class DoubleLogin extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = { showingPopup: false }

		this.exitToLogin = () => {
			this.setState({showingPopup: false});
			this.props.revokeCurrentSession();
		}

		const doubleLoginError = GENERIC_ERRORS.DOUBLE_LOGIN;

		this.unsubscribeFromApi = api.addGenericErrorHandler(doubleLoginError, () => {
			this.setState({showingPopup: true});
			throw GENERIC_ERRORS.DOUBLE_LOGIN_THROW; 																		// must throw error here so 'tryAtMost' can break out of its for-loop
		})
	}

	render() 
	{
		const showPopup = this.state.showingPopup;
		return showPopup ? this.Prompt : (this.props.children || null);
	}

	componentWillUnmount()
	{
		this.unsubscribeFromApi();
	}

	get Prompt()
	{
		const {messages} = this.props;

		const dialogProps = {
			open: true,
			disableBackdropClick: true,
			disableEscapeKeyDown: true
		}

		return (
			<Dialog {...dialogProps}>
				<DialogTitle>
					{messages[MESSAGE_IDS.SESSION.DOUBLE_LOGIN_HEADING]}
				</DialogTitle>
				<DialogContent>
					<MaterialText>
						{messages[MESSAGE_IDS.SESSION.DOUBLE_LOGIN]}
					</MaterialText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.exitToLogin}>
						{messages[MESSAGE_IDS.GENERAL.CONFIRM]}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

DoubleLogin.propTypes = {
	children: PropTypes.node,
	revokeCurrentSession: PropTypes.func.isRequired,
	urlToken: PropTypes.string.isRequired,
	messages: PropTypes.shape({
		[MESSAGE_IDS.SESSION.DOUBLE_LOGIN]: PropTypes.string.isRequired,
		[MESSAGE_IDS.GENERAL.CONFIRM]: PropTypes.string.isRequired
	}).isRequired
}


// DoubleLogin (connected to the store/router)
// ----------------------------------------------------------------------------

const mapStoreToProps = (store) => {
	const sessionData = getSessionData(store);

	return {
		urlToken: getUrlToken(getClientSessionData(sessionData)),
		userGuid: getUserGuid(getUserSessionData(sessionData))
	}
}

const mapDispatchToProps = (dispatch) => ({dispatch});

const mergeProps = ({userGuid, urlToken}, {dispatch}, {history, ...ownProps}) =>
{
	const removeSessionFromRedux = () => {
		getRemoveCurrentSessionActions().forEach(action => dispatch(action));
	}

	const redirectToLoginPage = () => {
		history.push(`/login?${urlToken}`);
	}

	return {
		urlToken,
		...ownProps,
		revokeCurrentSession: () => {
			authenticationApi.abandonUserSession(userGuid, "Double-Login");
			removeSessionFromRedux();
			redirectToLoginPage();
		}
	}
}

DoubleLogin = connect(mapStoreToProps, mapDispatchToProps, mergeProps)(DoubleLogin)
DoubleLogin = withRouter(DoubleLogin);


// DoubleLogin (connected to HOCs)
// ----------------------------------------------------------------------------
DoubleLogin = withMessages(DoubleLogin);


// EXPORT
// ----------------------------------------------------------------------------
export {DoubleLogin}