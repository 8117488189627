
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {Popup} from 'components/layout/popup'
import {CancelButton} from './buttons/cancel'
import {ConfirmButton} from './buttons/confirm'
import {withMessages} from 'components/hocs/messages'

// utils
import {detokenizeMessage} from 'utils/detokenize-message'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'
import {MESSAGE_TOKENS} from 'constants/message-tokens'


const finishConfirmPartTitle1 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_PART_TITLE_1;
const finishConfirmPart1 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_PART_INFO_1;


let PartFinishConfirmPopup = ({onClose, onConfirm, messages, data}) =>
{
	const tokenMappings = {
		[MESSAGE_TOKENS.CURRENT_PAPER_PART]: data.paperPartName,
		[MESSAGE_TOKENS.NEXT_PAPER_PART]: data.nextPaperPartName
	}

	const popupTitle = messages[finishConfirmPartTitle1];
	const popupText = messages[finishConfirmPart1];

	const props = {
		title: detokenizeMessage(popupTitle, tokenMappings),
		content: {text: detokenizeMessage(popupText, tokenMappings)},
		buttons: [
			<ConfirmButton onClick={onConfirm}/>,
			<CancelButton onClick={onClose}/>
		],
		onClose
	}

	return <Popup {...props}/>;
}

PartFinishConfirmPopup.propTypes = {
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[finishConfirmPartTitle1]: PropTypes.string.isRequired,
		[finishConfirmPart1]: PropTypes.string.isRequired
	}).isRequired
}


PartFinishConfirmPopup = withMessages(PartFinishConfirmPopup);


export {PartFinishConfirmPopup}