
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'


// LoginUrlChecker (not connected to router)
// ----------------------------------------------

class LoginUrlChecker extends React.Component {
    componentDidMount() {
        if (this.isLoginUrl()) {
            this.props.onLoginUrl();
        } else {
            this.props.onOtherUrl();
        }
    }

    isLoginUrl() {
        const {
            location: { pathname },
        } = this.props.history;
        return (
            pathname === "/" ||
            pathname.startsWith("/login") ||
            pathname.startsWith("/user") ||
            pathname.startsWith("/clients/")
        );
    }

    render() {
        return null;
    }
}

LoginUrlChecker.propTypes = {
	history: PropTypes.object.isRequired,
	onLoginUrl: PropTypes.func.isRequired,
	onOtherUrl: PropTypes.func.isRequired
}


// LoginUrlChecker (connected to router)
// ----------------------------------------------

LoginUrlChecker = withRouter(LoginUrlChecker);


// EXPORT
// ----------------------------------------------

export {LoginUrlChecker}