// npm
import check from 'check-types';
import { Map as ImmutableMap } from 'immutable';

// redux
// import * as ACTION_TYPES from './action-types'
import { QUESTION_REDUCER } from './question/reducer';
import * as EXAM_ACTION_TYPES from '../../action-types';
import * as QUESTION_ACTION_TYPES from './question/action-types';

// custom
import { getProp } from 'custom/object-helper';

// TEMPORARY HACK!
import {CHANGE} from './question/workings/action-types'
import {SET_ASCX_HTML} from './question/ascx/action-types'

// as we traverse the questions, we'll build up the current question number
// to be injected as data into the 'question' reducer
let questionNumber;

// Note: state is mutable in the functions below
// -----------------------------------------------------------------------
const populateQuestions = (state, questions) => {
  if (!check.nonEmptyArray(questions)) {
    return;
  }
  const actionType = EXAM_ACTION_TYPES.SET_EXAM_DATA;

  questions.forEach(question => {
    const action = {
      type: actionType,
      data: question,
      number: questionNumber++
    };
    state.set(question['@ID'], QUESTION_REDUCER(undefined, action));
  });
};

const populateQuestionsFromSections = (state, sections) => {
  if (!check.nonEmptyArray(sections)) {
    return;
  }

  sections.forEach(section => {
    const paperParts = getProp(section, 'OBJECTS.PAPERPART');
    const taskGroups = getProp(section, 'OBJECTS.TASKGROUP');
    const _sections = paperParts || taskGroups;
    if (!_sections) {
      const questions = getProp(section, 'OBJECTS.QUESTION');
      populateQuestions(state, questions);
    }
    else {
      populateQuestionsFromSections(state, _sections);
    }
  });
};

const delegateToQuestionReducer = (state, action) => {
  const questionState = state.get(action.questionId);
  return state.set(action.questionId, QUESTION_REDUCER(questionState, action));
};
// -----------------------------------------------------------------------

const questionActionTypes = Object.values(QUESTION_ACTION_TYPES);

const QUESTIONS_REDUCER = (state = ImmutableMap(), action) => {
  if (
    questionActionTypes.includes(action.type) ||
    action.type === SET_ASCX_HTML || action.type === CHANGE
  ) {
    return delegateToQuestionReducer(state, action);
  } else {
    switch (action.type) {
      case EXAM_ACTION_TYPES.SET_EXAM_DATA:
        questionNumber = 1;
        return state.withMutations(mutableState => {
          const paperParts = getProp(action, 'data.EXAM.OBJECTS.PAPERPART');
          const taskGroups = getProp(action, 'data.EXAM.OBJECTS.TASKGROUP');
          const sections = paperParts || taskGroups;  
          if (!sections) {
            const questions = getProp(action, 'data.EXAM.OBJECTS.QUESTION');
            populateQuestions(mutableState, questions);
          }
          else {
            populateQuestionsFromSections(mutableState, sections);
          }
        });
      default:
        return state;
    }
  }
};

export { QUESTIONS_REDUCER };
