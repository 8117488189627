
// other modules
import {api} from '../api'
import {ENDPOINTS} from '../constants'


const ping = () => api.get(ENDPOINTS.PING, {});
const init = (url) => api.post(ENDPOINTS.INIT, {url});
const getTime = (userGuid) => api.get(ENDPOINTS.TIME, {userGuid});

const fetchResource = (resourceUrl) => api.download(resourceUrl);


const generalApi = {ping, init, getTime, fetchResource};
Object.freeze(generalApi);

export {generalApi}