
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {SchedulesInitializationPage} from './page'
import {SchedulesClearer} from './schedules-clearer'
import {SchedulesFetcher} from './schedules-fetcher'

// machines
import {schedules} from 'machines/schedules'


const {EVENTS, INITIALIZING_STATES:STATES} = schedules;


const InitializingSchedulesView = () =>
{
	return (
		<SchedulesInitializationPage>
			<StateView>
				{{
					[STATES.CLEARING_SCHEDULES]: () => (
						<StateControl onSuccess={EVENTS.SUCCESS}>
							{({onSuccess}) => <SchedulesClearer onSuccess={onSuccess}/>}
						</StateControl>
					),
					[STATES.FETCHING_SCHEDULES]: () => (
						<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
							{(props) => <SchedulesFetcher {...props}/>}
						</StateControl>
					)
				}}
			</StateView>
		</SchedulesInitializationPage>
	)
}


export {InitializingSchedulesView}