import check from 'check-types'

const getLearnerIdentifierLabel = (learnerIdentifier) => {
    if (isLearnerIdentifierNI(learnerIdentifier)) {
        return "National Insurance Number (NI)";
    } else if (isLearnerIdentifierPPS(learnerIdentifier)) {
        return "Personal Public Service Number (PPS)";
    }

    return "Learner Identifier";
};

const isLearnerIdentifierNI = (learnerIdentifier) => {
    const format = "CCNNNNNNC";

    return isFormat(learnerIdentifier, format);
};

const isLearnerIdentifierPPS = (learnerIdentifier) => {
    const format = "NNNNNNNCC";

    return isFormat(learnerIdentifier, format);
};

const isFormat = (_value, format) => {
    const value = removeSpaces(_value);

    if (check.nonEmptyString(value) && value.length === format.length) {
        return [...value].reduce((correct, character, index) => {
            if (correct) {
                const f = format[index];

                if (f === "N" && !isCharacterNumber(character)) {
                    correct = false;
                } else if (f === "C" && !isCharacterAlphabetic(character)) {
                    correct = false;
                }
            }

            return correct;
        }, true);
    }

    return false;
};

const isCharacterNumber = (value) => {
    return value >= "0" && value <= "9";
};

const isCharacterAlphabetic = (value) => {
    return (value >= "a" && value <= "z") || (value >= "A" && value <= "Z");
};

const removeSpaces = (value) => {
    // return value.replace(/ /g, "");
    return [...value].reduce((newValue, character) => {
        if (isCharacterNumber(character) || isCharacterAlphabetic(character)) {
            newValue += character;
        }
        return newValue;
    }, "");
};

export { getLearnerIdentifierLabel, removeSpaces };
