// Responsiblity:
// Deep extract all values from an iterable object

// npm
import check from 'check-types'


// A reference list of any iterables we've come across during extraction. 
// This is done so as to ensure we don't meet infinite loops/recursion 
// during extraction due to unexpected circular references.
let referenceCache = [];


const isIterable = (iterable) =>
{
	return check.object(iterable) || 
		check.array(iterable) || 
		check.instanceStrict(iterable, Set) || 
		check.instanceStrict(iterable, Map);
}

const alreadyMet = (iterable) =>
{
	return referenceCache.includes(iterable);
}

const deepExtractFromIterable = (iterable) =>
{
	const values = [];
	referenceCache.push(iterable);
	const iterableValues = Object.values(iterable);

	iterableValues.forEach(value => {
		values.push(...deepExtractFrom(value));
	});
	
	return values;
}

const deepExtractFrom = (item) =>
{
	if (!isIterable(item)) { return [item]; }
	return alreadyMet(item) ? [] : deepExtractFromIterable(item);
}

const deepExtract = (structure) =>
{
	if (referenceCache.length > 0) { throw "Reference cache in use"; }

	const values = deepExtractFrom(structure);
	referenceCache = [];
	return values;
}



export {deepExtract}