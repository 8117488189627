import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import { getComponentValue, getStyle, addFontStyles } from "../form-helper";

import { FORM_SELECT_TYPES, FORM_SEPERATORS } from "constants/form";

const getAction=(action, value)=>{
    const actions=action.split(';');

    if (check.nonEmptyArray(actions)){
        for (let i=0;i<actions.length;i++){
            const actionValue=getActionValue(actions[i], value);

            if (check.nonEmptyString(actionValue)){
                return actionValue;
            }
        }
    }

    return action;
}

const getActionValue=(action, value)=>{
    if (check.nonEmptyString(action)){
        const items=action.split("=");

        if (check.nonEmptyArray(items) && items.length===3){
            const parts=items[2].split(',');

            if (parts.some(item=>item.toLowerCase()===value.toLowerCase())){
                return `${items[0]}=${items[1]}`;
            }
        }
    }

    return null;
}

class FormSelectDropdown extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        const { type } = props;
        this.isMultiple = type === FORM_SELECT_TYPES.DROPDOWN_MULTI;
    }

    handleChange(e) {
        const { onChange, name, options, action } = this.props;

        if (this.isMultiple) {
            const dropdownOptions = e.target.options;

            const selected = [];
            for (let i = 0; i < dropdownOptions.length; i++) {
                if (dropdownOptions[i].selected) {
                    selected.push(i);
                }
            }

            const value = check.nonEmptyArray(selected)
                ? selected
                      .map((item) => options[item])
                      .join(FORM_SEPERATORS[3])
                : "";

            //console.log("Set Value", name, value);
            onChange(name, value);
        } else {
            const index = parseInt(e.target.value);
            const value = options[index];

            //console.log("Set Value", name, value);

            if (check.nonEmptyString(action)){
                const selectAction=getAction(action, value);
                
                if (check.nonEmptyString(selectAction)){
                    onChange(name, value, selectAction);
                    return;
                }
            }
            onChange(name, value);
        }
    }

    getValue() {
        const { formValue, name, options } = this.props;
        const value = getComponentValue(name, formValue);

        if (this.isMultiple) {
            const values = check.nonEmptyString(value)
                ? value.split(FORM_SEPERATORS[3])
                : [];
            const indexes = values.map((value) =>
                options.findIndex((option) => value === option)
            );
            
            return indexes;
        } else {
            const index = options.findIndex((option) => value === option);

            return index;
        }
    }

    render() {
        const { enabled, options, fontObj } = this.props;
        const styleObj = addFontStyles(getStyle(this.props), fontObj);
        const value = this.getValue();
        const selectProps = {
            style: styleObj,
            value,
            onChange: this.handleChange,
            multiple: this.isMultiple,
        };

        return (
            <div>
                <select {...selectProps}>
                    {options.map((option, index) => {
                        const optionProps = {
                            key: index,
                            value: index,
                            style: styleObj,
                            disabled: !enabled,
                        };
                        return <option {...optionProps}>{option}</option>;
                    })}
                </select>
            </div>
        );
    }
}

export { FormSelectDropdown };
