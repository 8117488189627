
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE NAMES
// #########################################

const STATES = {
	CLEARING_PREVIOUS_EXAM: 'clearing_previous_exam',
	INITIALIZING: 'initializing',
	NETWORK_ERROR: 'exam_network_error',
	INITIALIZED: 'initialized'
}

const INITIALIZING_STATES = {
	VALIDATING_SCHEDULE: 'validating_schedule',
	INPUTTING_PASSWORD: 'inputting_schedule_password',
	INITIALIZING_EXAM: 'initializing_exam',
	CHECKING_RESOURCES: 'checking_resources'
}

const INITIALIZING_EXAM_STATES = {
	FETCHING_EXAM: 'fetching_exam',
	INITIALIZING_IMAGES: 'preloading_exam_images',
	INITIALIZING_RESOURCES: 'preloading_resources',
	INITIALIZING_ASCX_QUESTIONS: 'preloading_ascx_questions',
	INITIALIZING_ANSWERS: 'resuming_answers'
}

const CHECKING_RESOURCES_STATES = {
	CHECKING_AUDIO: 'checking_audio',
	CHECKING_PDF: 'checking_pdf',
	CHECKING_INTEGRITY_ADVOCATE: 'checking_integrity_advocate',
	CHECKING_PROCTORIO: 'checking_proctorio'
}

const VALIDATING_SCHEDULE_STATES = {
	INITIALIZING_SCHEDULE_DATA: 'initializing_schedule_data',
	CHECKING_SCHEDULE_COMPLETION: 'checking_if_schedule_completed',
	SCHEDULE_ALREADY_COMPLETED: 'schedule_already_complete',
	CHECKING_SCHEDULE_CAN_START: 'checking_if_schedule_can_start',
	SCHEDULE_CANT_START: 'schedule_cant_start',
	CHECKING_PASSWORD_REQUIREMENT: 'checking_if_schedule_needs_password'
}

const INITIALIZING_SCHEDULE_DATA_STATES = {
	CHECKING_DATA: 'looking_for_schedule_data',
	FETCHING_DATA: 'fetching_schedule_data',
	DATA_NOT_FOUND: 'schedule_data_not_found'
}


// #########################################
// EVENT NAMES
// #########################################

const EVENTS = {
	ERROR: 'error',
	SUCCESS: 'success',
	SCHEDULES_NOT_LOADED: 'schedule_not_loaded',
	SCHEDULE_NOT_FOUND: 'schedule_not_found',
	SCHEDULE_INCOMPLETE: 'schedule_incomplete',
	SCHEDULE_COMPLETE: 'schedule_complete',
	SCHEDULE_CANT_START: 'schedule_cant_start',
	SCHEDULE_CAN_START: 'schedule_can_start',
	PASSWORD_REQUIRED: 'password_required',
	PASSWORD_NOT_REQUIRED: 'password_not_required',
	PREVIOUS_EXAM_CLEARED: 'previous_exam_cleared'
}


// #########################################
// INITIALIZING_SCHEDULE_DATA STATES
// #########################################

const checkingScheduleData = new XStateConfig();
checkingScheduleData.addTransition(EVENTS.SCHEDULES_NOT_LOADED, INITIALIZING_SCHEDULE_DATA_STATES.FETCHING_DATA);
checkingScheduleData.addTransition(EVENTS.SCHEDULE_NOT_FOUND, INITIALIZING_SCHEDULE_DATA_STATES.DATA_NOT_FOUND);

const fetchingScheduleData = new XStateConfig();
fetchingScheduleData.addTransition(EVENTS.SUCCESS, INITIALIZING_SCHEDULE_DATA_STATES.CHECKING_DATA);

const scheduleDataNotFound = new XStateConfig();


// #########################################
// VALIDATING_SCHEDULE STATES
// #########################################

const initializingScheduleData = new XStateConfig();
initializingScheduleData.initialState = INITIALIZING_SCHEDULE_DATA_STATES.CHECKING_DATA;
initializingScheduleData.addState(INITIALIZING_SCHEDULE_DATA_STATES.CHECKING_DATA, checkingScheduleData);
initializingScheduleData.addState(INITIALIZING_SCHEDULE_DATA_STATES.FETCHING_DATA, fetchingScheduleData);
initializingScheduleData.addState(INITIALIZING_SCHEDULE_DATA_STATES.DATA_NOT_FOUND, scheduleDataNotFound);
initializingScheduleData.addTransition(EVENTS.SUCCESS, VALIDATING_SCHEDULE_STATES.CHECKING_SCHEDULE_COMPLETION);

const checkingScheduleCompletion = new XStateConfig();
checkingScheduleCompletion.addTransition(EVENTS.SCHEDULE_COMPLETE, VALIDATING_SCHEDULE_STATES.SCHEDULE_ALREADY_COMPLETED);
checkingScheduleCompletion.addTransition(EVENTS.SCHEDULE_INCOMPLETE, VALIDATING_SCHEDULE_STATES.CHECKING_SCHEDULE_CAN_START);

const scheduleAlreadyComplete = new XStateConfig();

const checkingScheduleCanStart = new XStateConfig();
checkingScheduleCanStart.addTransition(EVENTS.SCHEDULE_CANT_START, VALIDATING_SCHEDULE_STATES.SCHEDULE_CANT_START);
checkingScheduleCanStart.addTransition(EVENTS.SCHEDULE_CAN_START, VALIDATING_SCHEDULE_STATES.CHECKING_PASSWORD_REQUIREMENT);

const scheduleCantStart = new XStateConfig();

const checkingPasswordRequirement = new XStateConfig();


// #########################################
// INITIALIZING_EXAM STATES
// #########################################

const fetchingExam = new XStateConfig();
fetchingExam.addTransition(EVENTS.SUCCESS, INITIALIZING_EXAM_STATES.INITIALIZING_IMAGES);

const initializingExamImages = new XStateConfig();
initializingExamImages.addTransition(EVENTS.SUCCESS, INITIALIZING_EXAM_STATES.INITIALIZING_RESOURCES);

const initializingResources = new XStateConfig();
initializingResources.addTransition(EVENTS.SUCCESS, INITIALIZING_EXAM_STATES.INITIALIZING_ASCX_QUESTIONS);

const initializingAscxQuestions = new XStateConfig();
initializingAscxQuestions.addTransition(EVENTS.SUCCESS, INITIALIZING_EXAM_STATES.INITIALIZING_ANSWERS);

const initializingExamAnswers = new XStateConfig();

// #########################################
// CHECKING_RESOURCES STATES
// #########################################

const checkingAudio = new XStateConfig();
checkingAudio.addTransition(EVENTS.SUCCESS, CHECKING_RESOURCES_STATES.CHECKING_PDF);

const checkingPdf = new XStateConfig();
checkingPdf.addTransition(EVENTS.SUCCESS, CHECKING_RESOURCES_STATES.CHECKING_INTEGRITY_ADVOCATE);

const checkingIntegrityAdvocate = new XStateConfig();
checkingIntegrityAdvocate.addTransition(EVENTS.SUCCESS, CHECKING_RESOURCES_STATES.CHECKING_PROCTORIO);

const checkingProctorio = new XStateConfig();


// #########################################
// INITIALIZING STATES
// #########################################

const validatingSchedule = new XStateConfig();
validatingSchedule.initialState = VALIDATING_SCHEDULE_STATES.INITIALIZING_SCHEDULE_DATA;
validatingSchedule.addState(VALIDATING_SCHEDULE_STATES.INITIALIZING_SCHEDULE_DATA, initializingScheduleData);
validatingSchedule.addState(VALIDATING_SCHEDULE_STATES.CHECKING_SCHEDULE_COMPLETION, checkingScheduleCompletion);
validatingSchedule.addState(VALIDATING_SCHEDULE_STATES.SCHEDULE_ALREADY_COMPLETED, scheduleAlreadyComplete);
validatingSchedule.addState(VALIDATING_SCHEDULE_STATES.CHECKING_SCHEDULE_CAN_START, checkingScheduleCanStart);
validatingSchedule.addState(VALIDATING_SCHEDULE_STATES.SCHEDULE_CANT_START, scheduleCantStart);
validatingSchedule.addState(VALIDATING_SCHEDULE_STATES.CHECKING_PASSWORD_REQUIREMENT, checkingPasswordRequirement);
validatingSchedule.addTransition(EVENTS.PASSWORD_REQUIRED, INITIALIZING_STATES.INPUTTING_PASSWORD);
validatingSchedule.addTransition(EVENTS.PASSWORD_NOT_REQUIRED, INITIALIZING_STATES.INITIALIZING_EXAM);

const inputtingPassword = new XStateConfig();
inputtingPassword.addTransition(EVENTS.SUCCESS, INITIALIZING_STATES.INITIALIZING_EXAM);

const initializingExam = new XStateConfig();
initializingExam.initialState = INITIALIZING_EXAM_STATES.FETCHING_EXAM;
initializingExam.addState(INITIALIZING_EXAM_STATES.FETCHING_EXAM, fetchingExam);
initializingExam.addState(INITIALIZING_EXAM_STATES.INITIALIZING_IMAGES, initializingExamImages);
initializingExam.addState(INITIALIZING_EXAM_STATES.INITIALIZING_RESOURCES, initializingResources);
initializingExam.addState(INITIALIZING_EXAM_STATES.INITIALIZING_ASCX_QUESTIONS, initializingAscxQuestions);
initializingExam.addState(INITIALIZING_EXAM_STATES.INITIALIZING_ANSWERS, initializingExamAnswers);
initializingExam.addTransition(EVENTS.SUCCESS, INITIALIZING_STATES.CHECKING_RESOURCES);

const checkingResources = new XStateConfig();
checkingResources.initialState = CHECKING_RESOURCES_STATES.CHECKING_PDF;
checkingResources.addState(CHECKING_RESOURCES_STATES.CHECKING_AUDIO, checkingAudio);
checkingResources.addState(CHECKING_RESOURCES_STATES.CHECKING_PDF, checkingPdf);
checkingResources.addState(CHECKING_RESOURCES_STATES.CHECKING_INTEGRITY_ADVOCATE, checkingIntegrityAdvocate);
checkingResources.addState(CHECKING_RESOURCES_STATES.CHECKING_PROCTORIO, checkingProctorio);


// #########################################
// EXAM STATES
// #########################################

const clearingPreviousExam = new XStateConfig();
clearingPreviousExam.addTransition(EVENTS.PREVIOUS_EXAM_CLEARED, STATES.INITIALIZING);

const initializing = new XStateConfig();
initializing.initialState = INITIALIZING_STATES.VALIDATING_SCHEDULE;
initializing.addState(INITIALIZING_STATES.VALIDATING_SCHEDULE, validatingSchedule);
initializing.addState(INITIALIZING_STATES.INPUTTING_PASSWORD, inputtingPassword);
initializing.addState(INITIALIZING_STATES.INITIALIZING_EXAM, initializingExam);
initializing.addState(INITIALIZING_STATES.CHECKING_RESOURCES, checkingResources);
initializing.addTransition(EVENTS.SUCCESS, STATES.INITIALIZED);
initializing.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const networkError = new XStateConfig();
const initialized = new XStateConfig();


// #########################################
// EXAM MACHINE
// #########################################

const _examInit = new XStateConfig();
_examInit.initialState = STATES.CLEARING_PREVIOUS_EXAM;
_examInit.addState(STATES.CLEARING_PREVIOUS_EXAM, clearingPreviousExam);
_examInit.addState(STATES.INITIALIZING, initializing);
_examInit.addState(STATES.INITIALIZED, initialized);
_examInit.addState(STATES.NETWORK_ERROR, networkError);

const machine = Machine(_examInit.toObject());
machine.id = "Exam Init Machine";


// #########################################
// EXPORT
// #########################################

const examInit = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES},
	INITIALIZING_STATES: {...INITIALIZING_STATES},
	INITIALIZING_EXAM_STATES: {...INITIALIZING_EXAM_STATES},
	CHECKING_RESOURCES_STATES: {...CHECKING_RESOURCES_STATES},
	VALIDATING_SCHEDULE_STATES: {...VALIDATING_SCHEDULE_STATES},
	INITIALIZING_SCHEDULE_DATA_STATES: {...INITIALIZING_SCHEDULE_DATA_STATES}
}

export {examInit}