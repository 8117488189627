import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import { FormField } from "./field/form-field";
import { FormSelect } from "./select/form-select";
import { FormButton } from "./button/form-button";
import { FormFile } from "./button/form-file";
import { FormRow } from "./form-row";
import { FormImage } from "./form-image";
import { FormHotspot } from "./form-hotspot";
import { FormDelay } from "./form-delay";

import { getStyle } from "./form-helper";

import { FORM_TYPES } from "constants/form";

class FormCell extends Component {
    state = {};

    displayComponents() {
        const { components, formValue, onChange, onClick, onAction, formAction, enabled, fontObj } =
            this.props;

        const styleObj = getStyle(this.props);

        return (
            <div style={styleObj}>
                {components.map((_component, index) => {
                    const { component } = _component;
                    const props = {
                        key: index,
                        formValue,
                        onChange,
                        onClick,
                        onAction,
                        enabled,
                        fontObj,
                        formAction,
                        ..._component,
                    };

                    if (component === FORM_TYPES.FIELD) {
                        return <FormField {...props} />;
                    } else if (component === FORM_TYPES.SELECT) {
                        return <FormSelect {...props} />;
                    } else if (component === FORM_TYPES.BUTTON) {
                        return <FormButton {...props} />;
                    } else if (component === FORM_TYPES.FILE) {
                        return <FormFile {...props} />;
                    } else if (component === FORM_TYPES.IMAGE) {
                        return <FormImage {...props} />;
                    } else if (component === FORM_TYPES.HOTSPOT) {
                        return <FormHotspot {...props} />;
                    } else if (component === FORM_TYPES.DELAY) {
                        return <FormDelay {...props} />;                        
                    }

                    return null;
                })}
            </div>
        );
    }

    displayRows() {
        const { rows, formValue, onChange, onClick, enabled } = this.props;
        const styleObj = getStyle(this.props);

        return (
            <div style={styleObj}>
                {rows.map((row, index) => {
                    const props = {
                        key: index,
                        onChange,
                        onClick,
                        enabled,
                        formValue,
                        ...row,
                    };

                    return <FormRow {...props} />;
                })}
            </div>
        );
    }

    render() {
        const { components, rows } = this.props;

        if (check.nonEmptyArray(rows)) {
            return this.displayRows();
        } else if (check.nonEmptyArray(components)) {
            return this.displayComponents();
        }

        const styleObj = getStyle(this.props);

        return <div style={styleObj}>&nbsp;</div>;
    }
}

export { FormCell };
