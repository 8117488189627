import React, { Component } from "react";
import { DisableAnsweringContext } from "components/pages/exam/disabled-context";
import {Form} from "./form"

class FormAnswerForm extends Component {
    state = {};

    displayForm(disabled) {
        const onChange = disabled ? undefined : this.props.onChange;
        const props = { onChange, disabled, ...this.props };

        return <Form {...props} />;
    }

    render() {
        return (
            <DisableAnsweringContext.Consumer>
                {(disabledValue) => this.displayForm(disabledValue)}
            </DisableAnsweringContext.Consumer>
        );
    }
}

export { FormAnswerForm };
