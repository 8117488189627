
// npm
import React from 'react'
import PropTypes from 'prop-types'

// utils
import {isWithinBounds} from 'utils/is-within-bounds'

// react
import {MaterialText} from 'components/presentation/material-text'
import {DisableAnsweringContext} from 'components/pages/exam/disabled-context'


const REF_ERROR = `DroppableOption: Ref not initialized`;


class DroppableOption extends React.Component
{
	constructor(props)
	{
		super(props);
		this.ref = React.createRef();

		this.containsPoint = (x, y) => {
			const point = {x, y};
			const {current:node} = this.ref;
			if (!node) { throw REF_ERROR; }
			return isWithinBounds(point, node.getBoundingClientRect());
		}
	}

	render()
	{
		const {fontSize, value, highlighted, defaultColor, highlightedColor} = this.props;
		const padding = Math.floor(fontSize / 3);
		const margin = Math.floor(fontSize / 4);

		const spanProps = {
			ref: this.ref,
			style: {
				padding,
				minWidth: 100,
				marginTop: margin,
				textAlign: 'center',
				minHeight: fontSize,
				marginBottom: margin,
				position: 'relative',
				verticalAlign: 'middle',
				cursor: value ? 'pointer' : undefined, 																		// careful that value != 0 here
				display: this.props.inline ? 'inline-block' : 'block',
				border: `1px solid ${highlighted ? highlightedColor : defaultColor}`
			},
			onClick: value && !this.isDisabled() && this.props.onReset									// careful that value != 0 here
		}

		const materialTextProps = {
			color: 'primary',
			style: {
				display: 'inline',
				float: 'left'
			}
		}

		return (
			<span {...spanProps}>
				<MaterialText {...materialTextProps}>{value || ""}</MaterialText>
				{value && this.DropboxClearIcon}
			</span>
		);
	}

	get DropboxClearIcon()
	{
		const props = {
			color: 'primary',
			style: {
				display: 'inline',
				float: 'right',
				padding: 0
			}
		}
		return <MaterialText {...props}>x</MaterialText>;
	}

	isDisabled()
	{
		return this.context;
	}
}

DroppableOption.contextType = DisableAnsweringContext;

DroppableOption.propTypes = {
	value: PropTypes.node,
	inline: PropTypes.bool.isRequired,
	onReset: PropTypes.func.isRequired,
	fontSize: PropTypes.number.isRequired,
	highlighted: PropTypes.bool.isRequired,
	defaultColor: PropTypes.string.isRequired,
	highlightedColor: PropTypes.string.isRequired
}

DroppableOption.defaultProps = {
	fontSize: 12,
	inline: true,
	highlighted: false,
	defaultColor: '#ccc',
	highlightedColor: 'black'
}

export {DroppableOption}