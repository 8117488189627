
// npm
import attributeToPropName from 'react-attr-converter'

// utils
import {cssToReactStyle} from './css-to-react-style'


const htmlAttributesToProps = (htmlAttributes={}) =>
{
	const props = {};

	Object.keys(htmlAttributes).forEach(attributeName => {
		const propName = attributeToPropName(attributeName);
		const isStyle = propName === 'style';

		const attributeValue = htmlAttributes[attributeName];
		const propValue = isStyle ? cssToReactStyle(attributeValue) : attributeValue;
		
		props[propName] = propValue;
	});

	return props;
}


export {htmlAttributesToProps}