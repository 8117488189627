import React, { Component } from "react";
import { connect } from "react-redux";

import {
    SET_LEAVE_EXAM,
    SET_RESUME_EXAM,
} from "redux/reducers/exam/action-types";
import { getSettingsData, getSessionData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getPreviewMode } from "redux/reducers/settings/client/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";
import { getExamData } from "redux/reducers/selectors";
import { getShowExamInFullScreen } from "redux/reducers/exam/selectors";

import { openInFullScreen } from "./full_screen/helper";
import { FullScreenDialog } from "./full_screen/full-screen-dialog";

import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";

import { displayDateTime } from "components/functional/timer-debug";

class LeaveResumeExam extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleLeaveExam = this.handleLeaveExam.bind(this);
        this.handleResumeExam = this.handleResumeExam.bind(this);
        this.handleLeaveBrowser = this.handleLeaveBrowser.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);

        this.handleFullScreenChange = this.handleFullScreenChange.bind(this);
        this.getDialogProps = this.getDialogProps.bind(this);

        this.state = { fullScreen: true };
    }

    handleLeaveExam() {
        const { setLeaveExam, showDebug } = this.props;
        var currentDateTime = Date.now();
        if (showDebug) {
            console.log("Leave Exam", displayDateTime(currentDateTime));
        }
        setLeaveExam(currentDateTime);
    }

    handleResumeExam() {
        const { setResumeExam, showDebug } = this.props;
        var currentDateTime = Date.now();
        if (showDebug) {
            console.log("Resume Exam", displayDateTime(currentDateTime));
        }
        setResumeExam(currentDateTime);
    }

    handleRightClick(ev) {
        ev.preventDefault();
    }

    handleLeaveBrowser(ev) {
        ev.preventDefault();
        return (ev.returnValue = "Are you sure you want to close?");
    }

    componentDidMount() {
        const {
            detectLeaveExam,
            alertLeaveBrowser,
            preventRightClick,
            showExamInFullScreen,
        } = this.props;

        if (detectLeaveExam) {
            window.onfocus = () => {
                this.handleResumeExam();
            };
            window.onblur = () => {
                this.handleLeaveExam();
            };
        }

        if (alertLeaveBrowser) {
            window.addEventListener("beforeunload", this.handleLeaveBrowser);
        }

        if (preventRightClick) {
            document.addEventListener("contextmenu", this.handleRightClick);
        }

        if (showExamInFullScreen) {
            document.addEventListener(
                "fullscreenchange",
                this.handleFullScreenChange
            );
        }
    }

    componentWillUnmount() {
        const {
            detectLeaveExam,
            alertLeaveBrowser,
            preventRightClick,
            showExamInFullScreen,
        } = this.props;

        if (detectLeaveExam) {
            window.onfocus = null;
            window.onblur = null;
        }

        if (alertLeaveBrowser) {
            window.removeEventListener("beforeunload", this.handleLeaveBrowser);
        }

        if (preventRightClick) {
            document.removeEventListener("contextmenu", this.handleRightClick);
        }

        if (showExamInFullScreen) {
            document.removeEventListener(
                "fullscreenchange",
                this.handleFullScreenChange
            );
        }
    }

    handleFullScreenChange(event) {
        const fullScreen = document.fullscreenElement;
        activityLogger.log(ACTIVITIES.FULL_SCREEN, { fullScreen });
        this.setState({ fullScreen });
    }

    handleGoToFullScreen() {
        openInFullScreen();
    }

    getDialogProps() {
        const { showExamInFullScreen } = this.props;
        const { fullScreen, tabFocus } = this.state;

        let dialogProps = { open: false };

        if (showExamInFullScreen) {
            if (!fullScreen) {
                dialogProps = {
                    open: true,
                    title: "Full Screen",
                    message: "This exam must be taken in Full Screen",
                    buttonText: "Return to Exam",
                    buttonClick: this.handleGoToFullScreen,
                };
            }
        }

        return dialogProps;
    }

    render() {
        const { children, showExamInFullScreen } = this.props;
        const dialogProps = this.getDialogProps();

        if (!showExamInFullScreen) return children;

        return (
            <React.Fragment>
                {children}
                <FullScreenDialog {...dialogProps} />
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);
    const previewMode = getPreviewMode(clientSettingsData) === "1";

    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);
    const orgId = getOrgId(clientSessionData);

    const alertLeaveBrowser = orgId === 29 && !previewMode;

    const examData = getExamData(store);
    const showExamInFullScreen = getShowExamInFullScreen(examData);

    return {
        detectLeaveExam: true,
        showDebug: false,
        alertLeaveBrowser,
        preventRightClick: true,
        showExamInFullScreen,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setLeaveExam: (value) => {
        dispatch({
            type: SET_LEAVE_EXAM,
            value,
        });
    },
    setResumeExam: (value) => {
        dispatch({
            type: SET_RESUME_EXAM,
            value,
        });
    },
});

LeaveResumeExam = connect(mapStoreToProps, mapDispatchToProps)(LeaveResumeExam);

export { LeaveResumeExam };
