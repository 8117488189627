
// redux (action-types)
import {INITIALIZED} from 'redux/reducers/app/action-types'


const setAppInitialized = () => ({
	type: INITIALIZED
});


export {setAppInitialized}