
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Button from '@material-ui/core/Button'


const PopupButton = (props) =>
{
	const buttonProps = {
		size: 'small',
		color: props.color,
		variant: 'contained',
		disableRipple: true,
		onClick: props.onClick,
		disableFocusRipple: true,
		style: {
			...(props.style || {}),
			marginLeft: 4,
			marginRight: 4
		}
	}

	return <Button {...buttonProps}>{props.text}</Button>;
}

PopupButton.propTypes = {
	color: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired
}

PopupButton.defaultProps = {
	color: 'primary'
}


export {PopupButton}