
// npm
import * as Immutable from 'immutable'

// redux
import {OPTION_REDUCER} from './option/reducer'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const createOptions = (state, {type, data}) =>
{
	for (let i = 0; i < data.length; i++)
	{
		const optionData = data[i];
		const action = {type, data: optionData};
		state = state.push(OPTION_REDUCER(undefined, action));
	}

	return state;
}

const OPTIONS_REDUCER = (state=Immutable.List(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			return createOptions(state, action);
		default:
			return state;
	}
}


export {OPTIONS_REDUCER}