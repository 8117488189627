
// libs
import {errorApi} from 'libs/api/interface/api-error'

import {detectBrowser} from 'components/pages/app_initialization/initializing/validating_app_settings/browser_checker/detect-browser'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getClientSessionData, getUserSessionData} from 'redux/reducers/session/selectors'
import {getUserId, getLanguageId} from 'redux/reducers/session/user/selectors'
import {getOrgId} from 'redux/reducers/session/client/selectors'


class ErrorLogger
{
	constructor()
	{
		this.previousState = null;
		this.action = null;
		this.stateChanges = null;
	}

	setAppState({previousState, action, stateChanges, currentState})
	{
		this.previousState = previousState;
		this.action = action;
		this.stateChanges = stateChanges;
		this.currentState = currentState;
	}

	log(error)
	{
		const sessionData = this.currentState && getSessionData(this.currentState);
		const clientSessionData = sessionData && getClientSessionData(sessionData);
		const userSessionData = sessionData && getUserSessionData(sessionData);

		const {browser, version} = detectBrowser();

		errorApi.logError({
			orgID: clientSessionData ? getOrgId(clientSessionData) : null,
			userID: userSessionData ? getUserId(userSessionData) : null,
			languageId: userSessionData ? getLanguageId(userSessionData) : null,
			errorMessage: error.message,
			stackTrace: error.stackTrace,
			info: this.getInfo(),
			siteId: null,
			centreId: null,
			app: "xams.UI.Web.Player",
			browser: {
				browser, 
				version
			}
		})
	}

	getInfo()
	{
		if (!this.stateChanges) { return null; }

		return JSON.stringify({
			action: this.action,
			previousState: this.previousState.toJS(),
			stateChanges: this.stateChanges.toJS()
		});
	}
}


const errorLogger = new ErrorLogger();
export {errorLogger}