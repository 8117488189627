
// Responsibility:
// Render a Component differently depending on the view we're in


// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Hidden from '@material-ui/core/Hidden'

// react
import {Callback} from 'components/functional/callback'


// Hidden breakpoints:
// xs: mobile
// sm: tablet
// mdUp: desktop


const EMPTY_STATE = {
	mobile: false,
	tablet: false,
	desktop: false
}

class ViewSwitcher extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {mobile: false, tablet: false, desktop: true};
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		const setView = (view) => {
			const newState = {...EMPTY_STATE};
			newState[view] = true;
			this.setState(newState);
		}

		this.setMobileView = () => setView('mobile');
		this.setTabletView = () => setView('tablet');
		this.setDesktopView = () => setView('desktop');
	}

	render()
	{
		const nonTablet = ['xs', 'md', 'lg', 'xl'];

		return (
			<React.Fragment>
				<Hidden smUp><Callback onMount={this.setMobileView}/></Hidden>
				<Hidden only={nonTablet}><Callback onMount={this.setTabletView}/></Hidden>
				<Hidden smDown><Callback onMount={this.setDesktopView}/></Hidden>
				{this.View}
			</React.Fragment>
		)
	}

	get View()
	{
		return this.state.mobile ? this.props.getMobileView() :
					 this.state.tablet ? this.props.getDesktopView() : 											// NOTE: Change this to 'getTabletView' when we start implementing for tablet screens!!!
					 this.props.getDesktopView();
	}

	getInitialView()
	{
		return this.getDesktopView ? 'desktop' :
					 this.getTabletView ? 'tablet' :
					 this.getMobileView ? 'mobile' : undefined;
	}
}

ViewSwitcher.propTypes = {
	getMobileView: PropTypes.func.isRequired,
	// getTabletView: PropTypes.func.isRequired,  																	// NOTE: Uncomment this when we start implementing for tablet screens!!!
	getDesktopView: PropTypes.func.isRequired
}


export {ViewSwitcher}