
// npm
import checkTypes from 'check-types'

// function predicates
import {generator} from './methods/function/generator'

// array predicates
import {arrayLike} from './methods/array/array-like'


const check = {
	...checkTypes,
	arrayLike,
	generator,
}


export {check}