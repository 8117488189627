import { check } from "@xams-utils/check-types";
import { MESSAGE_IDS } from "constants/message-ids";

const trimIt = (text) => {
    return check.string(text) ? text.trim() : text;
};

const getSettings = (messages) => {
    const messageId = MESSAGE_IDS.AUDIO.TEST_PAGE;
    const message = messages[messageId];

    const defaultSettings = {
        title: "Audio Check Page",
        text: `This exam contains some audio.<br/><br/>Click the audio chip below to play some music.<br/><br/>If you can hear the music: tick the check box and then select the <b>START</b> button.<br/>`,
        autoPlay: "false",
        autoDelay: 1,
        autoRepeat: "true",
        canPause: "false",
        check: "Yes I can hear the music",
        url: "https://scorm-common.xams.co.uk/content/sample/no-worries-16479.mp3",
    };

    const a = check.nonEmptyString(message)
        ? message.split(",").map(trimIt)
        : {};
    const settings = a.reduce((obj, setting) => {
        const items = setting.split(":").map(trimIt);
        obj[items[0]] = items.filter((item, index) => index > 0).join(":");
        return obj;
    }, defaultSettings);

    const bools = ["autoPlay", "canPause", "autoRepeat"];
    bools.forEach(
        (bool) => (settings[bool] = settings[bool].toLowerCase() === "true")
    );

    return settings;
};

export { getSettings };
