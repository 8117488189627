// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// react
import { DisableAnsweringContext } from "components/pages/exam/disabled-context";

// redux (selectors)
import { getHtml } from "redux/reducers/exam/content/questions/question/ascx/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getApiEndpointUrl } from "redux/reducers/settings/app/selectors";
import { getCacheData } from "redux/reducers/selectors";
import { getCachedImageData } from "redux/reducers/cache/selectors";
import { getCachedUrl } from "redux/reducers/cache/images/selectors";

import { api } from "libs/api/api";
import { errorLogger } from "utils/error-logger";

// styles
import "./ascx.css";

// AscxAnswerForm (not connected)
// -------------------------------------------------------------

class AscxAnswerForm extends React.Component {
    constructor(props) {
        super(props);

        this.iFrameId = "ascxQuestionIframe";

        this.handleMessageFromIframe = (evt) => {
            const { data } = evt;
            const { type } = data;

            if (type === "iframe") {
                const { value } = data;
                props.onChange(value);
            } else if (type === "image") {
                const { imageId, imageUrl } = data;
                const cachedImageUrl = props.getCachedImageUrl(imageUrl);
                const iFrame = document.getElementById(
                    this.iFrameId
                ).contentWindow;
                if (iFrame) {
                    iFrame.postMessage(
                        { type: "image", imageId, imageUrl: cachedImageUrl },
                        "*"
                    );
                }
            } else if (type === "error") {
                if (api.isEndpointInitialized()) {
                    const { error, ascx } = data;
                    const message=`Ascx ${ascx} Error - ${error}`;
                    
                    errorLogger.log({ message, stackTrace:null });
                }
            }
        };

        window.addEventListener("message", this.handleMessageFromIframe);

        let { value } = props;
        const { apiEndpointUrl } = props;

        if (value === undefined) value = "";
        const valStart = props.html.indexOf("var _value='");
        const valEnd = props.html.indexOf("';", valStart);
        const val = props.html.substring(valStart, valEnd + 2);
        //const html = props.html.replace(val, "var _value='" + value + "';");
        const html = props.html.replace(
            val,
            `var _value='${value}';var _resourceUrl='${apiEndpointUrl}resource/';`
        );

        const blobContent = new Blob([html], {type: "text/html"});
        const blobUrl = URL.createObjectURL(blobContent);

        this.state = { key: new Date().getTime(), html: html, blobUrl };
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessageFromIframe);
    }

    render() {
        return (
            <DisableAnsweringContext.Consumer>
                {(value) => this.getIframe(value)}
            </DisableAnsweringContext.Consumer>
        );
    }

    getIframe(disabled) {
        const props = {
            width: "100%",
            height: "1000px",
            src: this.state.blobUrl,
            // srcDoc: this.state.html,
            //srcDoc: this.props.html,
            className: disabled ? "disabled" : undefined,
            style: { borderWidth: "0px", overflowX: "hidden" },
        };

        return <iframe id={this.iFrameId} title={"ASCX Question"} {...props} />;
    }
}

// PLEASE INSERT PROPTYPES (these are ones I added)
AscxAnswerForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    html: PropTypes.any.isRequired,
};

// AscxAnswerForm (connected)
// -------------------------------------------------------------

const mapStoreToProps = (store, { formData }) => {
    const settingsData = getSettingsData(store);
    const appSettingsData = getAppSettingsData(settingsData);
    const cachedImageData = getCachedImageData(getCacheData(store));

    return {
        html: getHtml(formData),
        apiEndpointUrl: getApiEndpointUrl(appSettingsData),
        getCachedImageUrl: (url) => getCachedUrl(cachedImageData, url),
    };
};

AscxAnswerForm = connect(mapStoreToProps)(AscxAnswerForm);

// AscxAnswerForm (Export)
// -------------------------------------------------------------
export { AscxAnswerForm };
