import {
    SET_ENTERED,
    SET_PARENT,
    SET_CLIENT_NAME,
    SET_USER_INFO_1,
    SET_USER_INFO_2,
    SET_EXAM_GUID,
    CLEAR_DATA,
    SET_COMPLETED_EXAM_GUID,
    SET_ERROR
} from "redux/reducers/proctorio/action-types";

const setEntered = (value) => ({
    type: SET_ENTERED,
    value,
});

const setParent = (value) => ({
    type: SET_PARENT,
    value,
});

const setClientName = (value) => ({
    type: SET_CLIENT_NAME,
    value,
});

const setUserInfo1 = (value) => ({
    type: SET_USER_INFO_1,
    value,
});

const setUserInfo2 = (value) => ({
    type: SET_USER_INFO_2,
    value,
});

const setExamGuid = (value) => ({
    type: SET_EXAM_GUID,
    value,
});

const clearData = (value) => ({
    type: CLEAR_DATA,
    value,
});

const setCompletedExamGuid = (value) => ({
    type: SET_COMPLETED_EXAM_GUID,
    value,
});

const setError = (value) => ({
    type: SET_ERROR,
    value,
});

export {
    setEntered,
    setParent,
    setClientName,
    setUserInfo1,
    setUserInfo2,
    setExamGuid,
    clearData,
    setCompletedExamGuid,
    setError
};
