import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class FormConfirmation extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(state) {
        const { onConfirm } = this.props;

        onConfirm(state);
    }

    render() {
        const { showConfirmation, confirmationName } = this.props;
        const title=`Confirm - ${confirmationName}`;
        return (
            <Dialog
                open={showConfirmation}
                onClose={() => this.handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish you to confirm this action, once confirmed the form can not be changed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleClose(true)}
                        color="primary"
                    >
                        Confirm
                    </Button>
                    <Button
                        onClick={() => this.handleClose(false)}
                        color="primary"
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export { FormConfirmation };
