
// @xams-interactors
import {checkVersionSupport} from './check-version-support'
import {detectBrowser} from './detect-browser'

// @xams-entities
import {Browser} from '@xams-entities/browser'


const checkBrowserSupport = (supportedBrowsers) => {
  const {browser, version} = detectBrowser();

  return Object.keys(supportedBrowsers).some(name => {
    const supportedBrowser = Browser.create(name);
    if (Browser.equals(browser, supportedBrowser)) {
      return checkVersionSupport(version, supportedBrowsers[name]);
    }
    return false;
  });
};


export {checkBrowserSupport}
