
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'
import {Callback} from '@xams-components-functional/callback'

// react
import {LoginValidator} from './validator'
import {LoginAuthenticator} from './authenticator'
import {SetNetworkError} from 'components/pages/network_error/set-network-error'

// machines
import {form} from 'machines/form'


const {EVENTS, STATES, SUBMITTING_STATES} = form;


const SubmittingView = () =>
{
	return (
		<StateView>
			{{
				[SUBMITTING_STATES.VALIDATING_FORM]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.FAIL}>
						{(props) => <LoginValidator {...props}/>}
					</StateControl>
				),
				[SUBMITTING_STATES.SUBMITTING_FORM]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.FAIL} onError={EVENTS.ERROR}>
						{(props) => <LoginAuthenticator {...props}/>}
					</StateControl>
				)
			}}
		</StateView>
	)
}

const renderOtherViews = (onFinish) =>
{
	return (
		<StateView>
			{{
				[STATES.SUBMITTING]: () => (
					SubmittingView()
				),
				[STATES.SUBMISSION_ERROR]: () => (
					<SetNetworkError/>
				),
				[STATES.SUBMITTED]: () => (
					<Callback onMount={onFinish}/>
				)
			}}
		</StateView>
	)
}


export {renderOtherViews}