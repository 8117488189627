
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getCurrentQuestionId} from 'redux/reducers/exam/content/selectors'


let RedirectToExamQuestions = ({examGuid, currentQuestionId}) => (
	<Redirect to={`/exam/${examGuid}/${currentQuestionId}`}/>
)


const mapStoreToProps = (store) => ({
	currentQuestionId: getCurrentQuestionId(getContentData(getExamData(store)))
});

RedirectToExamQuestions = connect(mapStoreToProps)(RedirectToExamQuestions);


export {RedirectToExamQuestions}