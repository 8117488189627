// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// material-ui
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";

// react
import { withMessages } from "components/hocs/messages";
import { MaterialText } from "components/presentation/material-text";

// redux (actions)
import { getRemoveCurrentSessionActions } from "components/actions";

// redux (selectors)
import { getSessionData } from "redux/reducers/selectors";
import { getUserGuid } from "redux/reducers/session/user/selectors";
import { getUrlToken } from "redux/reducers/session/client/selectors";
import {
    getClientSessionData,
    getUserSessionData,
} from "redux/reducers/session/selectors";

// libs
import { authenticationApi } from "libs/api/interface/api-authentication";

import { getLogoutPage } from "components/pages/login/form/login_token.js";

// constants
import { MESSAGE_IDS } from "constants/message-ids";

const logOffMessageId = MESSAGE_IDS.GENERAL.LOG_OFF;

// LogoutButton (not connected)
// ------------------------------------------------------------------------

let LogoutButton = ({ onClick, classes, messages }) => {
	const buttonProps = {
        className: classes.button,
        onClick,
	}
    const iconButtonProps = {
        ariaLabel: messages[logOffMessageId],
        component: "span",
		...buttonProps
	};

    return (
        <React.Fragment>
            <Hidden xsDown>
                <Button {...buttonProps}>
                    <MaterialText className={classes.text}>
                        {messages[logOffMessageId]}
                    </MaterialText>
                </Button>
            </Hidden>
            <Hidden smUp>
                <IconButton {...iconButtonProps}>
                    <ExitToAppIcon />
                </IconButton>
            </Hidden>
        </React.Fragment>
    );
};

LogoutButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    messages: PropTypes.shape({
        [logOffMessageId]: PropTypes.string.isRequired,
    }),
};

// LogoutButton (connected to store)
// ------------------------------------------------------------------------				// these connectors are ordered for increased performance

const mapStoreToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

const mergeProps = ({ store }, { dispatch }, { history, ...ownProps }) => {
    const removeSessionFromRedux = () => {
        getRemoveCurrentSessionActions().forEach((action) => dispatch(action));
    };

    const notifyServerAboutLogout = (sessionData) => {
        const userGuid = getUserGuid(getUserSessionData(sessionData));
        authenticationApi.logoutUser(userGuid);
    };

    const redirectToLoginPage = (sessionData) => {
        const { url, logout } = getLogoutPage();
        const urlToken = getUrlToken(getClientSessionData(sessionData));
        const logoutUrl = `${url}?${urlToken}`;

        if (logout) history.push(logoutUrl);
        else window.location.replace(logoutUrl);
    };

    return {
        ...ownProps,
        onClick: () => {
            const sessionData = getSessionData(store);
            notifyServerAboutLogout(sessionData);
            removeSessionFromRedux();
            redirectToLoginPage(sessionData);
        },
    };
};

LogoutButton = connect(
    mapStoreToProps,
    mapDispatchToProps,
    mergeProps
)(LogoutButton);

// LogoutButton (connected to router)
// ------------------------------------------------------------------------

LogoutButton = withRouter(LogoutButton);

// LogoutButton (connected to messages)
// ------------------------------------------------------------------------

LogoutButton = withMessages(LogoutButton);

// LogoutButton (connected to styles)
// ---------------------------------------------------

const styles = ({ palette: { primary }, breakpoints, spacing }) => ({
    button: {
        backgroundColor: primary.dark,
        [breakpoints.down("xs")]: {
			color: primary.contrastText,
			padding: 8
        },
    },
    text: {
        color: primary.contrastText,
    },
});

LogoutButton = withStyles(styles)(LogoutButton);

// Export
// ---------------------------------------------------
export { LogoutButton };
