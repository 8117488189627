import React, { Component } from "react";
import { connect } from "react-redux";

import { getElearningData } from "redux/reducers/selectors";
import { getElearning, getShowCompleted } from "redux/reducers/elearning/selectors";
import {setShowCompleted} from "./actions"

import { styles } from "./styles";
import { MaterialText } from "components/presentation/material-text";
import { ElearningSchedule } from "./elearning-schedule";
import {ElearningCompletedSwitch} from './elearning-completed-switch'

import SchoolIcon from "@material-ui/icons/School";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { checkForCompletedSwitch, getNotCompleted
 } from "./helper";



class ElearningSchedules extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleSwitchChange = this.handleSwitchChange.bind(this);

        const {eLearning, showCompletedValue} = props;
        const {hasCompletedSwitch, completedSwitch} = checkForCompletedSwitch(eLearning, showCompletedValue);
        this.hasCompletedSwitch = hasCompletedSwitch;

        this.state = { completedSwitch };
    }

    handleSwitchChange(event) {
        const {showCompleted} = this.props;
        const {checked} = event.target;

        showCompleted(checked);
        this.setState({ completedSwitch: checked });
    }

    get Title() {
        const { classes } = this.props;
        const { completedSwitch } = this.state;
        const message = "Your E-Learning";
        const switchProps = {
            checked: completedSwitch,
            onChange: this.handleSwitchChange,
        };

        return (
            <div className={classes.schedulesText}>
                <SchoolIcon className={classes.clockIcon} color="primary" />
                <MaterialText style={{ display: "flex", flex: 2 }} className={classes.text} variant="h6">
                    {message}
                </MaterialText>
                {this.hasCompletedSwitch && <div className={classes.switch}><ElearningCompletedSwitch {...switchProps} /></div>}
            </div>
        );
    }

    get ElearningList() {
        const { eLearning } = this.props;
        const { completedSwitch } = this.state;
        const displayElearning = completedSwitch?eLearning:getNotCompleted(eLearning);
        return (
            <List>
                {displayElearning.map((_eLearning, index) => {
                    const eLearningProps = {
                        key: index,
                        ..._eLearning,
                    };

                    return (
                        <React.Fragment key={index}>
                            <Divider />
                            <ElearningSchedule {...eLearningProps} />
                        </React.Fragment>
                    );
                })}
            </List>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    {this.Title}
                </div>
                {this.ElearningList}
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const eLearningData = getElearningData(store);

    return{
        eLearning: getElearning(eLearningData),
        showCompletedValue: getShowCompleted(eLearningData)
    }
};

const mapDispatchToProps = (dispatch) => ({
	showCompleted: (value) => dispatch(setShowCompleted(value))
});


ElearningSchedules = connect(mapStoreToProps, mapDispatchToProps)(ElearningSchedules);

ElearningSchedules = withStyles(styles)(ElearningSchedules);

export { ElearningSchedules };
