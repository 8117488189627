import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import Input from '@material-ui/core/Input'
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";

import { removeSpaces } from "./learner-identification-helper";
import { MaterialText } from "components/presentation/material-text";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';


const styles = ({spacing}) => ({
    root: {
        marginLeft: "-2px",
        display: "flex",
        width: "100%",
        "&>div:last-child": {
            flex: 1,
            width: "100%",
            marginTop:"2px"
        },
        "&>div:nth-child(2)": {
            marginRight: spacing.unit,
            marginTop:spacing.unit*1,        
        },
        "&>div:nth-child(1)": {
            marginRight: spacing.unit,
            marginTop:spacing.unit*1,        
        },                        
    }
});

class ConfirmLearnerIdentifierText extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = { value: "", match: false };
    }

    handleChange(e) {
        const {match:prevMatch}=this.state;
        const { onChange, identifier } = this.props;

        const value = e.target.value;
        const match = removeSpaces(value).toLowerCase() === removeSpaces(identifier).toLowerCase();

        this.setState({ value, match }, () => {
            if (match!==prevMatch) onChange(null, match);
        });
    }

    displayCheckBox(){
        const {match}=this.state;
        const props={color:"primary"}
        return match?<CheckBoxIcon {...props}/>:<CheckBoxOutlineBlankIcon {...props}/>
    }

    render() {
        const { value, match } = this.state;
        const { label, classes, textClass } = this.props;
        const textProps = { onChange: this.handleChange, value  };

        return (
            <div className={classes.root}>
                <div>
                    {this.displayCheckBox()}
                    {/* <Checkbox
                        className={classes.checkBox}
                        checked={match}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    /> */}
                </div>
                <div>
                    <MaterialText className={textClass} variant="subtitle2">
                        {label}
                    </MaterialText>
                </div>
                <div>
                    <form noValidate autoComplete="off">
                        <Input {...textProps} />
                    </form>
                </div>
            </div>
        );
    }
}

ConfirmLearnerIdentifierText = withStyles(styles)(ConfirmLearnerIdentifierText);

export { ConfirmLearnerIdentifierText };