
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import ImmutablePropTypes from 'immutable-prop-types'

// react
import {Hide} from 'components/presentation/hide'
import {getAnswerBox} from '../../../exam/_layout/content/panel/answer_panel/get-answer-box'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getQuestionDataValues} from 'redux/reducers/exam/content/questions/selectors'


// ExamAnswerInitializer (not connected to store)
// ------------------------------------------------------------

class ExamAnswerInitializer extends React.Component
{
	componentDidMount()
	{
		this.props.onFinish();
	}

	render()
	{
		const {questionDataList} = this.props;
		return <Hide>{questionDataList.map(data => getAnswerBox(data))}</Hide>;
	}
}

ExamAnswerInitializer.propTypes = {
	onFinish: PropTypes.func.isRequired,
	questionDataList: PropTypes.arrayOf(ImmutablePropTypes.map).isRequired
}


// ExamAnswerInitializer (connected to store)
// ------------------------------------------------------------

const mapStoreToProps = (store) => {
	const contentData = getContentData(getExamData(store));
	const questionsData = getQuestionsData(contentData);
	return {
		questionDataList: getQuestionDataValues(questionsData)
	}
};

ExamAnswerInitializer = connect(mapStoreToProps)(ExamAnswerInitializer);


// Export
// ------------------------------------------------------------
export {ExamAnswerInitializer}