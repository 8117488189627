import React from 'react';
import { check } from "@xams-utils/check-types";

import { getBrowserIcon } from "react-browser-icons";
import HelpOutlineIcon from "@material-ui/icons//HelpOutline";

const PdfCheckBrowserIcon = (props) => {
    const {browser, size:_size}=props;
    const size = check.integer(_size)?_size:42;
    const name=check.nonEmptyObject(browser)?browser.name:""
    let browserName = "";
    
    switch (name.toLowerCase()) {
        case "chrome":
            browserName = "Chrome";
            break;
        case "safari":
            browserName = "Safari";
            break;
        case "firefox":
            browserName = "Firefox";
            break;
        case "edge-chromium":
            browserName = "Chromium";
            break;
        case "edge":
            browserName = "Edge";
            break;            
    }

    const icon =
        browserName === "" ? (
            <HelpOutlineIcon fontSize="large" />
        ) : (
            getBrowserIcon({
                browser: browserName,
                className: "class",
                style: { marginTop: 0 },
                size,
            })
        );

    return icon;
}


export {PdfCheckBrowserIcon};