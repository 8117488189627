
// npm
import check from 'check-types'
import * as Immutable from 'immutable'


const validateMappingsType = (mappings) =>
{
	const correctType = check.object.of.function(mappings);
	if (!correctType) { throw "CombineImmtableReducers: Bad 'mappings' type"; }
}

const validateReducerType = (reducer) =>
{
	if (reducer === undefined) { return; }
	const correctType = check.function(reducer);
	if (!correctType) { throw "CombineImmtableReducers: Bad 'reducer' type"; }
}

const combineImmutableReducers = (ownReducer, childReducerMappings) =>
{
	validateReducerType(ownReducer);
	validateMappingsType(childReducerMappings);

	return (state=Immutable.Map(), action) => {
		Object.keys(childReducerMappings).forEach(key => {
			let _state = state.get(key);
			let _reducer = childReducerMappings[key];
			state = state.set(key, _reducer(_state, action));
		});

		return !!ownReducer ? ownReducer(state, action) : state;
	}
}


export {combineImmutableReducers}