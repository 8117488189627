
// npm
import React from 'react'
import {Redirect} from 'react-router-dom'

// xams-components
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {RubricForm} from './form'

// machines
import {rubric} from 'machines/rubric'


const {STATES, EVENTS} = rubric;


const RubricFormMachine = () =>
{
	return (
		<StateMachine machine={rubric.machine}>
			<StateView>
				{{
					[STATES.UNCHECKED]: () => (
						<StateControl onFilled={EVENTS.CHECKED}>
							{(props) => <RubricForm {...props}/>}
						</StateControl>
					),
					[STATES.CHECKED]: () => (
						<StateControl onUnfilled={EVENTS.UNCHECKED} onSubmit={EVENTS.FINISHED}>
							{(props) => <RubricForm {...props}/>}
						</StateControl>
					),
					[STATES.FINISHED]: () => (
						<Redirect to='/schedules'/>
					)
				}}
			</StateView>
		</StateMachine>
	)
}


export {RubricFormMachine}