
// npm
import React from 'react'

// react
import {AppPageContext} from './context'


const withAppPage = (Component) =>
{
	return (props) => {
		return (
			<AppPageContext.Consumer>
				{(appPage) => <Component appPage={appPage} {...props}/>}
			</AppPageContext.Consumer>
		)
	}
}


export {withAppPage}