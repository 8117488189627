
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter} from 'react-router-dom'

// react
import {AppRouteBlocker} from './app-route-blocker'
import {getPopup} from 'utils/routing-blocker'


const AppRouter = (props) => (
	<BrowserRouter getUserConfirmation={getPopup}>
		<AppRouteBlocker>
			{props.children}
		</AppRouteBlocker>
	</BrowserRouter>
)

AppRouter.propTypes = {
	children: PropTypes.node
}


export {AppRouter}