import React from "react";

import moment from "moment";
import isEqual from "lodash/isEqual";

import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { lighten } from "@material-ui/core/styles/colorManipulator";

import { ACTIVITIES } from "libs/activity_logger/activity-logger";
import { decryptValue, getActivityTypeName } from "libs/activity_logger/activity-logger-helper";

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
    counter += 1;
    return { id: counter, name, calories, fat, carbs, protein };
}

const displayDate = (dateString) => {
    if (!dateString) return "";
    return new moment(dateString).format("Do MMMM YY HH:mm:ss:SSS");
};

const displayActivityType = (activity) => {
    if (activity === ACTIVITIES.LOCAL_ANSWER) return "LOCAL ANSWER";
    else if (activity === ACTIVITIES.SERVER_ANSWER) return "SERVER ANSWER";
    else if (activity === ACTIVITIES.MOVE_QUESTION) return "MOVE QUESTION";
    else if (activity === ACTIVITIES.MOVE_NEXT_QUESTION)
        return "MOVE NEXT QUESTION";
    else if (activity === ACTIVITIES.MOVE_PREVIOUS_QUESTION)
        return "MOVE PREVIOUS QUESTION";
    else if (activity === ACTIVITIES.WORKINGS) return "WORKINGS";
    else if (activity === ACTIVITIES.START_EXAM) return "START EXAM";
    else if (activity === ACTIVITIES.RESUME_EXAM) return "RESUME EXAM";
    else if (activity === ACTIVITIES.SET_FORM_RUN_GUID)
        return "SET RUN FORM GUID";
    else if (activity === ACTIVITIES.TIMED_OUT) return "TIMED OUT";
    else if (activity === ACTIVITIES.FINISH_EXAM) return "FINISH EXAM";
    else if (activity === ACTIVITIES.MOVE_SECTION) return "MOVE SECTION";
    else if (activity === ACTIVITIES.OFFLINE) return "OFFLINE";
    else if (activity === ACTIVITIES.ONLINE) return "ONLINE";
    else if (activity === ACTIVITIES.UPLOAD_ONLINE) return "ONLINE UPLOAD";
    else if (activity === ACTIVITIES.UPLOAD_OFFLINE) return "OFF UPLOAD";
    else if (activity === ACTIVITIES.DELETE_UPLOAD_ONLINE) return "DELETE ONLINE UPLOAD";
    else if (activity === ACTIVITIES.DELETE_UPLOAD_OFFLINE) return "DELETE OFF UPLOAD";    
    else if (activity === ACTIVITIES.CONFIRM_POPUP) return "USER FINISH";
    else if (activity === ACTIVITIES.DISPLAY_POPUP) return "DISPLAY POPUP";
    else if (activity === ACTIVITIES.POPUP_ERROR) return "DISPLAY POPUP";    

    return activity;
};

const displayActivityValue = (value) => {
    //return value ? window.atob(value) : "";
    return decryptValue(value);
};

const displayCellData = (column, rowData) => {
    const data = rowData[column.id];

    if (column.date) return displayDate(data);
    else if (column.activityType){
        //return displayActivityType(data);
        return getActivityTypeName(ACTIVITIES, data);
    }
    else if (column.activityValue) return displayActivityValue(data);

    return data;
};

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            rowCount,
            rows,
            displayCheckBox,
        } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {displayCheckBox ? (
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={
                                    numSelected > 0 && numSelected < rowCount
                                }
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>
                    ) : (
                        <TableCell padding="default" />
                    )}
                    {rows.map(
                        (row) => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? "right" : "left"}
                                padding={
                                    row.disablePadding ? "none" : "default"
                                }
                                sortDirection={
                                    orderBy === row.id ? order : false
                                }
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={
                                        row.numeric
                                            ? "bottom-end"
                                            : "bottom-start"
                                    }
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
    icons: {
        display: "flex",
    },
});

let EnhancedTableToolbar = (props) => {
    const { numSelected, classes, title, icons } = props;

    return (
        <Toolbar
            className={classes.root}
        >
            <div className={classes.title}>
                <Typography variant="h6" id="tableTitle">
                    {title}
                </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                <div className={classes.icons}>
                    {icons.map((icon, index) => {
                        const { disabled, name, onSelect } = icon;
                        return (
                            <Tooltip
                                key={index}
                                title={name}
                                disabled={disabled}
                                onClick={onSelect}
                            >
                                <IconButton aria-label={icon.name}>
                                    {icon.icon}
                                </IconButton>
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: "auto",
    },
});

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);

        const { data } = props;

        this.state = {
            order: "asc",
            orderBy: "calories",
            selected: [],
            data,
            page: 0,
            rowsPerPage: props.rowsPerPage || 5,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { data: prevData, selected: prevSelected } = prevState;
        const { selected } = this.state;
        const { data, onSelectChange } = this.props;

        if (prevData.length !== data.length) this.setState({ data });
        if (!isEqual(selected, prevSelected) && onSelectChange)
            onSelectChange(selected);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState((state) => ({
                selected: state.data.map((n) => n.id),
            }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        this.setState({ selected: newSelected });
    };

    handleSelect = (event, activity) => {
        const { onSelect } = this.props;
        if (onSelect) onSelect(activity);
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes, rows, title, checkBox, icons } = this.props;
        const {
            data,
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
        } = this.state;
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                <EnhancedTableToolbar
                    title={title}
                    icons={icons || []}
                    numSelected={selected.length}
                />
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                            rows={rows}
                            displayCheckBox={checkBox}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((n) => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            {checkBox ? (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isSelected}
                                                        onClick={(event) =>
                                                            this.handleClick(
                                                                event,
                                                                n.id
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell padding="default" />
                                            )}
                                            {rows.map((row, index) => {
                                                return (
                                                    <TableCell
                                                        key={index}
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        onClick={(event) =>
                                                            this.handleSelect(
                                                                event,
                                                                n
                                                            )
                                                        }
                                                    >
                                                        {displayCellData(
                                                            row,
                                                            n
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "Next Page",
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

EnhancedTable = withStyles(styles)(EnhancedTable);

export { EnhancedTable };
