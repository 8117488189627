import check from "check-types";

const getPartsData = (multiTextData) => multiTextData.get("parts");

const isAnswered = (localAnswer) => {
    if (!localAnswer) {
        return false;
    }
    return Object.values(localAnswer).some((value) =>
        check.nonEmptyString(value)
    );
};

const getShowWordCount = (multiTextData) => {
    return multiTextData.get("showWordCount");
};

export { getPartsData, isAnswered, getShowWordCount };
