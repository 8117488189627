
// redux (action-types)
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'
import {CACHE_PDF} from 'redux/reducers/cache/pdfs/action-types'
import {SET_ASCX_HTML} from 'redux/reducers/exam/content/questions/question/ascx/action-types'


const setExamData = (data) => ({
	type: SET_EXAM_DATA,
	data
});

const cachePdfResource = (pdfUrl, blobUrl) => ({
	type: CACHE_PDF,
	pdfUrl,
	blobUrl
});

const setAscxHtml = (questionId, html) => ({
	type: SET_ASCX_HTML,
	questionId,
	html
});


export {setExamData, cachePdfResource, setAscxHtml}