
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'


const cardStyle = {
	backgroundColor: 'transparent',
	padding:'0px'
}


const SectionPanel = ({headingNode, resultPanel}) => (
	<Card style={cardStyle} elevation={0}>
		<CardContent style={{paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 16}}>
			{headingNode}
			{resultPanel}
		</CardContent>
	</Card>
)

SectionPanel.propTypes = {
	headingNode: PropTypes.node.isRequired,
	resultPanel: PropTypes.node.isRequired
}


export {SectionPanel}