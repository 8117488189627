
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react

import { PdfCheckPage } from './pdf_check/pdf-check-page';
import { OpenIntegrityAdvocate } from './proctoring/open-integrity-advocate';
import { CheckingProctorio } from './proctoring/checking-proctorio';


// machines
import {examInit} from 'machines/exam-init'


const {EVENTS, CHECKING_RESOURCES_STATES:STATES} = examInit;


const CheckingResourcesView = () =>
{
	return (
		<StateView>
			{{
				[STATES.CHECKING_PDF]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <PdfCheckPage {...props}/>}
					</StateControl>
				),
				[STATES.CHECKING_INTEGRITY_ADVOCATE]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <OpenIntegrityAdvocate {...props}/>}
					</StateControl>
				),				
				[STATES.CHECKING_PROCTORIO]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <CheckingProctorio {...props}/>}
					</StateControl>
				),								
			}}
		</StateView>
	)
}


export {CheckingResourcesView}

