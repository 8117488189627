const BLANKS = 'QUESTIONBLANKS'
const CHECKS = 'QUESTIONCHECKS'
const COMBO = 'QUESTIONCOMBO'
const LONG_TEXT = 'QUESTIONLTEXT'
const MATCH = 'QUESTIONMATCH'
const MULTI_TEXT = 'QUESTIONMULTITEXT'
const RADIOS = 'QUESTIONRADIOS'
const TABLE = 'QUESTIONTABLE'
const TRUE_OR_FALSE = 'QUESTIONTRUEFALSE'
const ASCX = 'QUESTIONASCX'
const ICT = 'QUPLOAD'
const STATIC_TEXT = 'STATICTEXT'
const FORM='QGRID'
const RADIOS_TEXT = 'QUESTIONOPENTEXTOPTION'

export {
  BLANKS,
  CHECKS,
  COMBO,
  LONG_TEXT,
  MATCH,
  MULTI_TEXT,
  RADIOS,
  TABLE,
  TRUE_OR_FALSE,
  ASCX,
  ICT,
  STATIC_TEXT,
  FORM,
  RADIOS_TEXT  
}