
// redux (action-types)
import * as EXAM_ACTION_TYPES from 'redux/reducers/exam/action-types'


const setExamPassword = (value) => ({
	type: EXAM_ACTION_TYPES.SET_PASSWORD,
	value
});

const clearPassword = () => ({
	type: EXAM_ACTION_TYPES.CLEAR_PASSWORD
});

const setPasswordError = (value) => ({
	type: EXAM_ACTION_TYPES.SET_PASSWORD_ERROR,
	value
});


export {setExamPassword, clearPassword, setPasswordError}