
const styles = (theme) => ({
	loadingDiv: {
		height: 320,
		position: 'relative'
	},
	progress: {
		zIndex: 2000,
		margin: 'auto',
		width: 'auto',
		display: 'block',
		marginTop: 100,
	}
});

export {styles}