// internal
import { localStorageApi, KEYS } from "../local-storage-api";

class StoredUsers {
    constructor() {
        const users = localStorageApi.retrieveDataFrom(KEYS.USERS);

        const save = () => {
            localStorageApi.saveDataTo(KEYS.USERS, users);
        };

        this.set = (guid, userData) => {
            if (users.hasOwnProperty(guid)) {
                return;
            }
            users[guid] = userData;
            save();
        };

        this.remove = (guid) => {
            if (!users.hasOwnProperty(guid)) {
                return;
            }
            delete users[guid];
            save();
        };

        this.removeAll = () => {
            Object.keys(users).forEach((id) => {
                delete users[id];
            });
            save();
        };

        this.dangerously_getUsers = () => {
            return users;
        };
    }
}

const storedUsers = new StoredUsers();
export { storedUsers };
