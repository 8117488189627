
const getVersion = (scheduleData) => scheduleData.get('version');
const getScheduleType = (scheduleData) => scheduleData.get('type');
const getScheduleInfo = (scheduleData) => scheduleData.get('info');
const getScheduleGuid = (scheduleData) => scheduleData.get('guid');
const getScheduleStartTime = (scheduleData) => scheduleData.get('startDt');
const getScheduleStartTimeFmt = (scheduleData) => scheduleData.get('startDtFmt');
const getScheduleCompletedTime = (scheduleData) => scheduleData.get('completeDt');
const getScheduleCompletedTimeFmt = (scheduleData) => scheduleData.get('completeDtFmt');
const hasScheduleCommenced = (scheduleData) => scheduleData.get('commenced') || false;
const hasScheduleCompleted = (scheduleData) => scheduleData.get('completed') || false;
const getCantStartMessage = (scheduleData) => scheduleData.get('cantStartMessage');
const isSchedulePractice = (scheduleData) => scheduleData.get('practice') || false;
const requiresSchedulePassword = (scheduleData) => scheduleData.get('requiresPassword') || false;

const getExamName = (scheduleData) => scheduleData.get('examName');
const getExamGuid = (scheduleData) => scheduleData.get('examGuid');
const getExamTypeGuid = (scheduleData) => scheduleData.get('examTypeGuid');

const getProctorProvider = (scheduleData) => scheduleData.get('proctorProvider');
const getProctoringSessionID = (scheduleData) => scheduleData.get('proctoringSessionID');

const getObjectId = (scheduleData) => scheduleData.get('objectID');
const getQualificationId = (scheduleData) => scheduleData.get('qualificationID');

const getPracticeExtraTime = (scheduleData) => scheduleData.get('practiceExtraTime');

export {
	getVersion,
	getScheduleType,
	getScheduleInfo,
	getScheduleGuid,
	getScheduleStartTime,
	getScheduleStartTimeFmt,
	getScheduleCompletedTime,
	getScheduleCompletedTimeFmt,
	getCantStartMessage,
	hasScheduleCommenced,
	hasScheduleCompleted,
	isSchedulePractice,
	requiresSchedulePassword,

	getExamName,
	getExamGuid,
	getExamTypeGuid,

	getProctorProvider,
	getProctoringSessionID,
	
	getObjectId,
	getQualificationId,

	getPracticeExtraTime
}