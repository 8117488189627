import React from "react";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";
import PDFJSBackend from "components/pages/exam/_layout/side/panels/pdf_resource/pdf_js_viewer/pdf-js-backend";

import { getCacheData } from "redux/reducers/selectors";
import { getCachedPdfData } from "redux/reducers/cache/selectors";
import { getCachedUrl } from "redux/reducers/cache/pdfs/selectors";

class PreloadPdfs extends React.Component {
    constructor(props) {
        super(props);

        this.viewerRef = React.createRef();
        this.backend = new PDFJSBackend();

        this.loadPdf = this.loadPdf.bind(this);
    }

    componentDidMount() {
        this.loadPdf(0);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    loadPdf(currentUrl) {
        const { resourceUrls, onComplete, cachedPdfData } = this.props;
        if (
            !check.nonEmptyArray(resourceUrls) ||
            currentUrl >= resourceUrls.length
        ) {
            onComplete();
        } else {
            const resourceUrl = resourceUrls[currentUrl];

            const cachedUrl = getCachedUrl(cachedPdfData, resourceUrl);
            const finalResourceUrl = cachedUrl || resourceUrl;
            console.log(currentUrl, finalResourceUrl);

            const element = this.viewerRef.current;

            this.backend.init(finalResourceUrl, element);
            this.backend.displayed = false;
            this.timer = setTimeout(() => this.loadPdf(currentUrl + 1), 1000);
        }
    }

    render() {
        const { maxPageWidth } = this.props;

        return (
            <div
                ref={this.viewerRef}
                id="preload_viewer"
                style={{ display: "none" }}
            ></div>
        );
    }
}

const mapStoreToProps = (store) => ({
    cachedPdfData: getCachedPdfData(getCacheData(store)),
});

PreloadPdfs = connect(mapStoreToProps)(PreloadPdfs);

export { PreloadPdfs };
