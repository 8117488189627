
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {MaterialText} from './material-text'


class LoadingText extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {dotCount: props.maxDots};

		this.updateDotCount = () => {
			const {dotCount} = this.state;
			const {minDots, maxDots} = this.props;
			this.setState({dotCount: dotCount === maxDots ? minDots : dotCount + 1});
			this.timeoutId = setTimeout(this.updateDotCount, this.props.interval);
		}

		this.timeoutId = setTimeout(this.updateDotCount, props.interval);
	}

	componentWillUnmount()
	{
		clearTimeout(this.timeoutId);
	}

	render()
	{
		const text = `${this.props.children}${'.'.repeat(this.state.dotCount)}`;
		return <MaterialText {...this.MaterialTextProps}>{text}</MaterialText>;
	}

	get MaterialTextProps()
	{
		const {children, minDots, maxDots, interval, ...materialTextProps} = this.props;
		return materialTextProps;
	}
}

LoadingText.propTypes = {
	children: PropTypes.string.isRequired,
	minDots: PropTypes.number.isRequired,
	maxDots: PropTypes.number.isRequired,
	interval: PropTypes.number.isRequired
}

LoadingText.defaultProps = {
	text: '',
	minDots: 0,
	maxDots: 3,
	interval: 250
}


export {LoadingText}