import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getGuid } from "redux/reducers/exam/selectors";
import { getExamData, getSchedulesData } from "redux/reducers/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import {
    getProctoringMode,
    getProctoringType,
} from "redux/reducers/settings/client/selectors";

import {
    getProctorProvider,
    getProctoringSessionID,
} from "redux/reducers/schedules/schedule/selectors";

import { getProctorioData } from "redux/reducers/selectors";
import { getParent, getDebugMode } from "redux/reducers/proctorio/selectors";

import { PROCTORING_TYPES } from "constants/proctoring";

class CheckingProctorio extends Component {
    state = {};

    componentDidMount() {
        if (this.isProctorio()) {
            const { proctorProvider, proctoringSessionId, examGuid } =
                this.props;
            const url = `/proctoring/${proctorProvider}/${proctoringSessionId}/${examGuid}`;

            this.props.history.push(url);
        } else {
            const { onSuccess } = this.props;
            console.log("Not Proctorio");

            onSuccess();
        }
    }

    isProctorio() {
        const { proctorProvider, proctorioActive } = this.props;

        return proctorProvider === PROCTORING_TYPES.PROCTORIO && !proctorioActive;
    }

    render() {
        return null;
    }
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const examGuid = getGuid(examData);

    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    const schedulesData = getSchedulesData(store);
    const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
    const proctoringType = getProctoringType(clientSettingsData);
    const proctoringMode = getProctoringMode(clientSettingsData);

    const proctoringSessionId = getProctoringSessionID(scheduleData);
    const proctorProvider = getProctorProvider(scheduleData);

    const proctorioData = getProctorioData(store);
    const proctorioActive = getParent(proctorioData);

    return {
        proctoringMode,
        proctoringType,
        proctorProvider,
        proctoringSessionId,
        examGuid,
        proctorioActive,
    };
};

CheckingProctorio = connect(mapStoreToProps)(CheckingProctorio);

CheckingProctorio = withRouter(CheckingProctorio);

export { CheckingProctorio };
