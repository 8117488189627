const SET_ENTERED = '[proctorio]set.entered';
const SET_PARENT = '[proctorio]set.parent';
const SET_CLIENT_NAME = '[proctorio]set.client.name';
const SET_USER_INFO_1 = '[proctorio]set.user.info1';
const SET_USER_INFO_2 = '[proctorio]set.user.info2';
const SET_EXAM_GUID = '[proctorio]set.exam.guid';
const CLEAR_DATA = '[proctorio]clear.data';
const SET_COMPLETED_EXAM_GUID = '[proctorio]set.completed.exam.guid';
const SET_ERROR = '[proctorio]set.error';

export {
	SET_ENTERED,
	SET_PARENT,
	SET_CLIENT_NAME,
	SET_USER_INFO_1,
	SET_USER_INFO_2,
	SET_EXAM_GUID,
	CLEAR_DATA,
	SET_COMPLETED_EXAM_GUID,
	SET_ERROR
}