import check from "check-types";
import {keyListener, KEYS} from 'utils/key-listener'

const getStyle = (_styleString, startStyle = {}) => {
    const styleString = check.nonEmptyObject(_styleString)
        ? _styleString.style
        : _styleString;

    let styleObject = startStyle;
    if (check.nonEmptyString(styleString)) {
        const styles = styleString.split(";");
        styleObject = styles.reduce((obj, style) => {
            if (check.nonEmptyString(style)) {
                const items = style.split(":");
                if (items.length === 2) {
                    const name = getStyleName(items[0]);
                    const value = items[1].trim();
                    if (
                        check.nonEmptyString(name) &&
                        check.nonEmptyString(value)
                    ) {
                        obj[name] = value;
                    }
                }
            }
            return obj;
        }, startStyle);
    }

    if (check.nonEmptyObject(_styleString)) {
        // const parameters=['left', 'top', 'width', 'height'];
        // for (let i=0;i<parameters.length;i++){
        //     const parameter=parameters[i];
        //     if (!check.assigned(styleObject[parameter]) && check.nonEmptyString(_styleString[parameter])){
        //         styleObject[parameter]=_styleString[parameter];
        //     }
        // }

        ["left", "top", "width", "height"].forEach((property) => {
            if (
                !check.assigned(styleObject[property]) &&
                check.nonEmptyString(_styleString[property])
            ) {
                const newProperty =
                    property === "left"
                        ? "marginLeft"
                        : property === "top"
                        ? "marginTop"
                        : property;
                styleObject[newProperty] = `${_styleString[property]}px`;
            }
        });
    }

    return styleObject;
};

const getStyleName = (name) => {
    if (check.nonEmptyString(name)) {
        if (name.indexOf("-") !== -1) {
            const items = name.split("-").map((item) => item.trim());
            if (check.nonEmptyArray(items) && items.length === 2) {
                return (
                    items[0].toLowerCase() +
                    items[1].charAt(0).toUpperCase() +
                    items[1].slice(1).toLowerCase()
                );
            }
        }
        return name.trim().toLowerCase();
    }
    return "";
};

const createFormValue = (formValue, seps) => {
    if (check.nonEmptyObject(formValue)) {
        const items = [];
        for (const property in formValue) {
            items.push([property, formValue[property]].join(seps[2]));
        }
        if (check.nonEmptyArray(items)) {
            return items.join(seps[1]);
        }
    }
    return "";
};

const getFormValue = (value, seps) => {
    const formValue = {};
    if (check.nonEmptyString(value)) {
        const parts = value.split(seps[0]);
        if (check.nonEmptyArray(parts)) {
            if (check.nonEmptyString(parts[1])) {
                const items = parts[1].split(seps[1]);
                for (let i = 0; i < items.length; i++) {
                    if (check.nonEmptyString(items[i])) {
                        const data = items[i].split(seps[2]);
                        if (check.nonEmptyArray(data)) {
                            const formName = removeSpacesFromName(data[0]);
                            formValue[formName] = data[1];
                        }
                    }
                }
            }
        }
    }
    return formValue;
};

const getFormSelected = (value, seps) => {
    const empty = null;
    if (check.nonEmptyString(value)) {
        if (check.nonEmptyString(value)) {
            const items = value.split(seps[0]);
            if (check.nonEmptyArray(items) && items.length > 1) {
                return check.nonEmptyString(items[0]) ? items[0] : empty;
            }
        }
    }
    return empty;
};

const hasFormBeenSelected = (value, seps) => {
    const selected = getFormSelected(value, seps);

    return check.nonEmptyString(selected);
};

const getComponentValue = (name, formValue) => {
    const empty = "";

    if (check.nonEmptyObject(formValue)) {
        const formName = removeSpacesFromName(name);
        return formValue[formName];
    }

    return empty;
};

const removeSpacesFromName = (name) => {
    if (check.nonEmptyString(name)) {
        return name.replace(/ /g, "_");
    }
    return "";
};

const addSpacesToName = (name) => {
    if (check.nonEmptyString(name)) {
        return name.replace(/_/g, " ");
    }
    return "";
};

const isFontProperty = (property) => property.startsWith("font");

const getFontStyles = (styleObj) => {
    const fontObj = {};

    if (check.nonEmptyObject(styleObj)) {
        for (const property in styleObj) {
            if (isFontProperty(property)) {
                fontObj[property] = styleObj[property];
            }
        }
    }

    return fontObj;
};

const addFontStyles = (styleObj, fontObj) => {
    if (!check.nonEmptyObject(fontObj)) {
        return styleObj;
    }

    // let hasFontProperty = false;

    // for (const property in styleObj) {
    //     if (isFontProperty(property)) {
    //         hasFontProperty = true;
    //     }
    // }

    // return hasFontProperty ? styleObj : { ...fontObj, ...styleObj };

    const newStyleObj = { ...styleObj };

    for (const fontProperty in fontObj) {
        let propertyFound = false;
        for (const property in styleObj) {
            if (property === fontProperty) {
                propertyFound = true;
            }
        }

        if (!propertyFound) {
            newStyleObj[fontProperty] = fontObj[fontProperty];
        }
    }

    return newStyleObj;
};

const getActionObj = (prevProps, props) => {
    const prevFormAction = prevProps ? prevProps.formAction : "";
    const { formAction, name } = props;

    //console.log("button", prevFormAction, formAction);

    if (
        formAction === prevFormAction ||
        !check.nonEmptyString(formAction) ||
        !check.nonEmptyString(name)
    ) {
        return null;
    }

    const names = formAction.split(",");

    if (!check.nonEmptyArray(names)) {
        return null;
    }

    const obj = names.reduce((n, item) => {
        if (check.nonEmptyString(item)) {
            const a = item.split("=");

            if (
                check.nonEmptyArray(a) &&
                a.length > 1 &&
                a[0].toLowerCase() === name.toLowerCase()
            ) {
                if (check.nonEmptyString(a[1])) {
                    if (a[1].toLowerCase() === "style") {
                        if (check.nonEmptyString(a[2])) {
                            const styles = a[2].split(";").reduce((s, item) => {
                                if (check.nonEmptyString(item)) {
                                    const a = item.split(":");

                                    if (a.length === 2) {
                                        s[a[0]] = a[1];
                                    }
                                }

                                return s;
                            }, {});

                            return { name: a[0], styles };
                        }
                    } else if (a[1].toLowerCase() === "message") {
                        if (a.length === 3 && check.nonEmptyString(a[2])) {
                            return { name: a[0], message: a[2] };
                        }
                    }
                }
            }
        }

        return n;
    }, null);

    return obj;
};

const getFormAction = (value, seps) => {
    if (check.nonEmptyString(value)) {
        const items = value.split(seps[0]);

        if (check.nonEmptyArray(items) && items.length == 3) {
            return items[2];
        }
    }

    return "";
};

const getInitialValues = (formData) => {
    const newValue = [];

    if (formData) {
        const { rows } = formData;

        if (check.nonEmptyArray(rows)) {
            rows.forEach((row) => {
                const { cells } = row;

                if (check.nonEmptyArray(cells)) {
                    cells.forEach((cell) => {
                        const { components } = cell;

                        if (check.nonEmptyArray(components)) {
                            components.forEach((component) => {
                                const { startingValue, name } = component;

                                if (check.nonEmptyString(startingValue)) {
                                    newValue.push({
                                        name,
                                        value: startingValue,
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    return newValue;
};

const rowStateHidden = (rowState, rowStates, rowFamily) => {
    if (
        !check.nonEmptyString(rowState) ||
        !check.nonEmptyObject(rowStates) ||
        !check.nonEmptyString(rowFamily)
    ) {
        return false;
    }

    const currentRowState = rowStates[rowState];

    if (check.nonEmptyArray(currentRowState)) {
        return currentRowState.every((row) => row !== rowFamily);
    }

    return false;
};

const isChangeRowState = (action) => {
    if (check.nonEmptyString(action)) {
        const actions = action.split();
        const parts = action.split("=");

        if (check.nonEmptyArray(parts) && parts.length > 1) {
            return parts[0] === "rowState";
        }
    }
    return false;
};
const getRowState = (action) => {
    if (check.nonEmptyString(action)) {
        const actions = action.split(";");
        const rowState = actions.reduce((r, action) => {
            const parts = action.split("=");
            if (check.nonEmptyArray(parts) && parts.length > 1) {
                if (parts[0] === "rowState") {
                    return parts[1];
                }
            }
            return r;
        }, null);
        return rowState;
    }
};

const insertVariablesIntoText = (text, formData) => {
    if (check.nonEmptyString(text)) {
        const hasDelimiter = text.indexOf("{");

        if (hasDelimiter !== -1) {
            var regex = /{(.*?)}/g;
            const t = text.replace(regex, function (match) {
                var variable = check.nonEmptyString(match)?match.substring(1, match.length-1):"";

                return check.nonEmptyString(variable) &&
                    check.assigned(formData[variable])
                    ? formData[variable]
                    : "";
            });

            return t;
        }
    }
    return text;
};

const disableArrowKeyListeners = () =>
{
	keyListener.disable(KEYS.LEFT_ARROW);
	keyListener.disable(KEYS.RIGHT_ARROW);
}

const enableArrowKeyListeners = () =>
{
	keyListener.enable(KEYS.LEFT_ARROW);
	keyListener.enable(KEYS.RIGHT_ARROW);
}

export {
    getStyle,
    createFormValue,
    getFormValue,
    hasFormBeenSelected,
    getComponentValue,
    getFormSelected,
    addSpacesToName,
    removeSpacesFromName,
    getFontStyles,
    addFontStyles,
    getActionObj,
    getFormAction,
    getInitialValues,
    rowStateHidden,
    isChangeRowState,
    getRowState,
    insertVariablesIntoText,
    disableArrowKeyListeners,
    enableArrowKeyListeners
};
