// other modules
import {StorageApi} from './storage-api'


const KEYS = {
	USER: 'user',
	TOKENS: 'tokens',
	CLIENT_SESSION: 'client.session',
	CLIENT_SETTINGS: 'client.settings',
	EXTERNAL_EXAM: 'external.exam',
	EXTERNAL_SCHEDULE: 'external.schedule',
	STUDENT_LOGIN: 'student.login',
	STUDENT_LOGIN_DETAILS: 'student.login.details',
	STUDENT_LOGIN_RESPONSE: 'student.login.response',
	STUDENT_REFERRER: 'student.referrer'
}

const sessionStorageApi = new StorageApi(window.sessionStorage, Object.values(KEYS));
Object.freeze(sessionStorageApi);

export {sessionStorageApi, KEYS}