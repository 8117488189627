// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AppPageModifier } from "components/pages/app-page-modifier";
import { check } from "@xams-utils/check-types";

// react
import { LoadingSpinner } from "components/presentation/loading-spinner";

// redux (selectors)
import {
    getExamData,
    getSessionData,
    getSettingsData,
} from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import {
    shouldShowFeedback,
    getFormRunGuid,
} from "redux/reducers/exam/selectors";
import {
    getClientSessionData,
    getUserSessionData,
} from "redux/reducers/session/selectors";
import {
    getUserGuid,
    getLanguageId,
} from "redux/reducers/session/user/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";
import { getIsDownloadablePracticeExam } from "redux/reducers/settings/client/selectors";

// redux (actions)
import { setExamResult, setFormRunId } from "./actions";

// other
import { messagesApi } from "libs/api/interface/api-messages";
import { assessmentApi } from "libs/api/interface/api-assessment";

const hasExamBeenCompleted = (parsedResponse) => {
    //return parsedResponse.results && parsedResponse.results[0] && parsedResponse.results[0].Completed;
    return (
        parsedResponse.results &&
        parsedResponse.results[0] &&
        parsedResponse.results[0].Processed
    );
};

// ResultFetcher (not connected to store)
// ----------------------------------------

class ResultFetcher extends React.Component {
    constructor(props) {
        super(props);

        this.tryCounter = 0;
        this.tryCounterMax = 6;
        this.tryDelay = 10000;

        this.initializeBoundMethods();
    }

    initializeBoundMethods() {
        const saveExamResult = (result, message = "") => {
            const {
                isDownloadablePracticeExam,
                onFinish,
                onComplete,
                setFormRunId,
                setExamResult,
            } = this.props;

            setExamResult({
                marks: result.score,
                totalMarks: result.maxScore,
                score: result.percentScore,
                grade: result.grade,
                passed: result.pass,
                message: message || "",
                allowImmediateResit: result.immediateResitAvailable,
            });

            setFormRunId(result.formRunID);

            if (isDownloadablePracticeExam) {
                onComplete();
            } else {
                onFinish();
            }
        };

        const saveResult = (result) => {
            const { orgId, userGuid, languageId } = this.props;
            const { feedbackMessage, feedbackMessageID } = result;

            if (check.nonEmptyString(feedbackMessage)) {
                saveExamResult(result, feedbackMessage);
            } else if (check.assigned(feedbackMessageID)) {
                messagesApi
                    .requestMessages(
                        [feedbackMessageID],
                        orgId,
                        userGuid,
                        languageId
                    )
                    .then((response) => {
                        const messages = JSON.parse(response);
                        const message = check.nonEmptyArray(messages)
                            ? messages[0]
                            : "";

                        saveExamResult(result, message);
                    })
                    .catch(() => saveExamResult(result, ""));
            } else {
                saveExamResult(result, "");
            }
        };

        const resultStillBeingProcessed = () => {
            const {
                orgId,
                userGuid,
                languageId,
                setExamResult,
                onFinish,
                onComplete,
                isDownloadablePracticeExam,
            } = this.props;
            const messageId = "ResultStillBeingProcessed";
            const defaultMessage = "Result still being processed";

            messagesApi
                .requestMessages([messageId], orgId, userGuid, languageId)
                .then((response) => {
                    const messages = JSON.parse(response);
                    const message = check.nonEmptyArray(messages)
                        ? messages[0]
                        : defaultMessage;

                    setExamResult({
                        message,
                        resultStillBeingProcessed: true,
                    });

                    if (isDownloadablePracticeExam) {
                        onComplete();
                    } else {
                        onFinish();
                    }
                });
        };

        const trySaveResult = (response) => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.marked) {
                saveResult(parsedResponse);
            } else {
                if (this.tryCounter === this.tryCounterMax) {
                    resultStillBeingProcessed();
                } else {
                    this.tryCounter++;
                    this.tryFetchResults();
                }
            }
        };

        const trySaveComplete = (response) => {
            const parsedResponse = JSON.parse(response);

            if (hasExamBeenCompleted(parsedResponse)) {
                const { shouldFetchResults, onComplete } = this.props;

                if (shouldFetchResults) {
                    this.tryFetchResults();
                } else {
                    onComplete();
                }
            } else {
                this.tryExamComplete();
            }
        };

        this.tryFetchResults = () => {
            setTimeout(() => {
                assessmentApi
                    .getResult(this.props.formRunGuid)
                    .then(trySaveResult)
                    .catch(this.props.onError);
            }, this.tryDelay);
        };

        this.tryExamComplete = () => {
            setTimeout(() => {
                assessmentApi
                    .isComplete(this.props.formRunGuid)
                    .then(trySaveComplete)
                    .catch(this.props.onError);
            }, this.tryDelay);
        };
    }

    componentDidMount() {
        const { shouldFetchResults, isDownloadablePracticeExam, onFinish } =
            this.props;

        if (isDownloadablePracticeExam) {
            this.tryExamComplete();
        } else if (shouldFetchResults) {
            this.tryFetchResults();
        } else {
            onFinish();
        }
    }

    render() {
        const { shouldFetchResults, isDownloadablePracticeExam } = this.props;
        const needToGetResults =
            shouldFetchResults || isDownloadablePracticeExam;

        if (!needToGetResults) return <LoadingSpinner size="lg" />;

        const appBarProps = {
            title: "Getting your results",
            loadingTitle: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="getting-results"
                    appBarProps={appBarProps}
                    loading
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

ResultFetcher.propTypes = {
    shouldFetchResults: PropTypes.bool.isRequired,
    formRunGuid: PropTypes.string.isRequired,
    setExamResult: PropTypes.func.isRequired,
    setFormRunId: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    orgId: PropTypes.number.isRequired,
    languageId: PropTypes.number.isRequired,
    userGuid: PropTypes.string.isRequired,
};

// ResultFetcher (connected to store)
// ----------------------------------------

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const sessionData = getSessionData(store);
    const userSessionData = getUserSessionData(sessionData);
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    return {
        shouldFetchResults: shouldShowFeedback(examData),
        formRunGuid: getFormRunGuid(examData),
        orgId: getOrgId(getClientSessionData(sessionData)),
        languageId: getLanguageId(userSessionData),
        userGuid: getUserGuid(userSessionData),
        isDownloadablePracticeExam:
            getIsDownloadablePracticeExam(clientSettingsData),
    };
};

const mapDispatchToProps = (dispatch) => ({
    setExamResult: (data) => dispatch(setExamResult(data)),
    setFormRunId: (id) => dispatch(setFormRunId(id)),
});

ResultFetcher = connect(mapStoreToProps, mapDispatchToProps)(ResultFetcher);

// EXPORT
// ----------------------------------------
export { ResultFetcher };
