// redux
import { SET_EXAM_DATA } from "redux/reducers/exam/action-types";

// custom
import { getProp } from "custom/object-helper";
import { extractMessage } from "redux/reducers/exam/helpers";
import { check } from "@xams-utils/check-types";
import {
    FORM_TYPES,
    FORM_FIELD_TYPES,
    FORM_SELECT_TYPES,
} from "constants/form";

const getString = (data, name) => {
    if (check.nonEmptyObject(data)) {
        if (check.assigned(data[name])) {
            return "" + data[name];
        }
    }
    return "";
};

const extractFromString = (toExtract, string) => {
    if (!check.nonEmptyArray(toExtract) || !check.nonEmptyString(string)) {
        return { extracted: [], remaining: string };
    }

    const extracted = {};
    const remaining = [];
    const a = string.split(";");

    for (let i = 0; i < a.length; i++) {
        const b = a[i].split(":");
        let found = false;

        if (b.length === 2) {
            const stringName = b[0].trim();
            const stringValue = b[1].trim();

            for (let j = 0; j < toExtract.length; j++) {
                const extractName = toExtract[j].trim();

                if (extractName === stringName) {
                    extracted[stringName] = stringValue;
                    found = true;
                    break;
                }
            }
        }

        if (!found) {
            remaining.push(a[i]);
        }
    }

    return { extracted, remaining: remaining.join(";") };
};

const handleSetExamData = (state, action) => {
    state = state.set("resumedAnswer", action.data["ANSWER"]);

    return state.set("formData", getFormData(action));
};

const getFormData = (action) => {
    const styleString = getString(action.data, "@CssStyle");
    const { extracted, remaining } = extractFromString(
        ["rowState", "rowStates", "rowStateDefault", "setRowStates"],
        styleString
    );

    const formData = {
        style: remaining,
        rows: getRows(getProp(action, "data.OBJECTS.QGRIDROW")),
    };

    if (check.nonEmptyObject(extracted)) {
        const { rowState, rowStates, rowStateDefault, setRowStates } =
            extracted;

        if (check.nonEmptyString(rowState)) {
            formData.rowState = rowState;
        }

        if (check.nonEmptyString(rowStateDefault)) {
            formData.rowStateDefault = rowStateDefault;
        }

        if (check.nonEmptyString(rowStates)) {
            formData.rowStates = getRowStates(rowStates);
        }

        if (check.nonEmptyString(setRowStates)) {
            formData.rowStates = getSetRowStates(setRowStates);
        }
    }

    return formData;
};

const getRowStates = (text) => {
    const rowStates = text.split("~").reduce((obj, item) => {
        const parts = item.split("=");

        if (check.nonEmptyArray(parts) && parts.length == 2) {
            const rows = parts[1].split(",");

            if (check.nonEmptyArray(rows) && rows.length > 0) {
                obj[parts[0]] = rows;
            }
        }

        return obj;
    }, {});

    return rowStates;
};

const getSetRowStates = (text) => {
    if (check.nonEmptyString(text)) {
        const parts = text.split(",");

        if (check.nonEmptyArray(parts) && parts.length > 1) {
            const rowStateNameStart =
                parts.length > 2 && check.nonEmptyString(parts[2])
                    ? parts[2]
                    : "rowState";
            const rowNameStart =
                parts.length > 3 && check.nonEmptyString(parts[3])
                    ? parts[3]
                    : "row";
            const start = parseInt(parts[0]);
            const end = parseInt(parts[1]);

            if (!isNaN(start) && !isNaN(end) && start < end) {
                const rowStates = {};

                for (let i = start; i <= end; i++) {
                    const rowStateName = `${rowStateNameStart}${i}`;
                    const rowName = `${rowNameStart}${i}`;

                    rowStates[rowStateName] = [rowName];
                }

                return rowStates;
            }
        }
    }

    return null;
};

const getRows = (rows) => {
    if (check.nonEmptyArray(rows)) {
        return rows.map((row) => getRow(row));
    }
    return [];
};

const getRow = (rowData) => {
    const styleString = getString(rowData, "@CssStyle");
    const { extracted, remaining } = extractFromString(
        ["rowFamily", "rowFamilyVisible","finished"],
        styleString
    );

    const row = {
        style: remaining,
        width: getString(rowData, "@Width"),
        height: getString(rowData, "@Height"),
        cells: getCells(getProp(rowData, "OBJECTS.QGRIDCELL")),
        ...extracted,
    };

    return row;
};

const getCells = (cells) => {
    if (check.nonEmptyArray(cells)) {
        return cells.map((cell) => getCell(cell));
    }
    return [];
};

const getCell = (cellData) => {
    const components = getProp(cellData, "OBJECTS.QUESTION");
    const cell = {
        style: getCellProperty(cellData, "@CssStyle"),
        width: getCellProperty(cellData, "@Width"),
        height: getCellProperty(cellData, "@Height"),
        components: getComponents(components),
    };

    return cell;
};

const getCellProperty = (cellData, property) => {
    const rootValue = getString(cellData, property);
    if (check.nonEmptyString(rootValue)) {
        return rootValue;
    }

    const cellProperties = getProp(cellData, "QGRIDCELLPROPERTIES");

    if (check.nonEmptyObject(cellProperties)) {
        const propertyValue = getString(cellProperties, property);

        return propertyValue;
    }

    return "";
};

const getComponents = (components) => {
    if (check.nonEmptyArray(components)) {
        return components.map((component) => getComponent(component));
    }
    return [];
};

const getComponent = (componentData) => {
    const styleString = getString(componentData, "@Style");
    const componentType = getString(componentData, "@QuestionType");

    const { extracted, remaining } = extractFromString(
        getComponentExtractVariables(componentType, componentData),
        styleString
    );

    const left = getString(componentData, "@Left");
    const top = getString(componentData, "@Top");
    const width = getString(componentData, "@Width");
    const height = getString(componentData, "@Height");

    let component = {};

    if (componentType === "COMPFIELD" || componentType === "COMPTEXT") {
        component = getFieldComponent(componentData);
    } else if (componentType === "COMPSELECT") {
        component = getSelectComponent(componentData);
    } else if (componentType === "COMPBUTTON") {
        component = getButtonComponent(componentData);
    } else if (componentType === "COMPFILE") {
        component = getFileComponent(componentData);
    } else if (componentType === "COMPIMAGE") {
        component = getImageComponent(componentData);
    } else if (componentType === "COMPHOTSPOT") {
        component = getHotspotComponent(componentData);
    } else if (componentType === "COMPDELAY") {
        component = getDelayComponent(componentData);
    } else {
        debugger;
    }

    return {
        style: remaining,
        left,
        top,
        width,
        height,
        ...extracted,
        ...component,
    };
};

const getComponentExtractVariables = (componentType, componentData) => {
    const variables = [];

    if (componentType === "COMPFIELD") {
        variables.push("maxLength", "spellCheck", "autoTab", "noPaste");

        const fieldType = getString(componentData, "@FieldType");
        if (fieldType === "Calendar") {
            variables.push("month", "selectColour");
        }
    } else if (componentType === "COMPBUTTON") {
        variables.push("changeAction");
    } else if (componentType === "COMPSELECT") {
        variables.push("actionOnSelect");
        const selectType = getString(componentData, "@SelectType");
        if (selectType === "Checkbox") {
            variables.push("single");
        }

    } else if (componentType === "COMPTEXT") {
        variables.push("action","loading", "instruction");
    } else if (componentType === "COMPHOTSPOT") {
        variables.push("checkbox");        
    }

    return variables;
};

const getFieldComponent = (componentData) => {
    const component = {
        component: FORM_TYPES.FIELD,
        name: getString(componentData, "@Name"),
    };

    const componentType = getString(componentData, "@QuestionType");

    if (componentType === "COMPTEXT") {
        const text = extractMessage(getProp(componentData, "TEXT"));
        component.text = text;
        component.type = check.nonEmptyString(text)
            ? FORM_FIELD_TYPES.TEXT
            : FORM_FIELD_TYPES.VALUE;
    } else {
        const fieldType = getString(componentData, "@FieldType");

        const startingValue = getTextStartingValue(componentData);
        if (check.nonEmptyString(startingValue)) {
            component.startingValue = startingValue;
        }

        if (fieldType === "Text") {
            component.type = FORM_FIELD_TYPES.INPUT;
        } else if (fieldType === "Date") {
            component.type = FORM_FIELD_TYPES.DATE;
        } else if (fieldType === "Time") {
            component.type = FORM_FIELD_TYPES.TIME;
        } else if (fieldType === "Calendar") {
            component.type = FORM_FIELD_TYPES.CALENDAR;
        }

        component.action = getString(componentData, "@Action");
    }

    return component;
};

const getTextStartingValue = (componentData) => {
    const text = extractMessage(getProp(componentData, "TEXT"));

    if (check.nonEmptyString(text)) {
        return text.trim() === "Question Text" ? null : text.trim();
        // const checkValue='value:';

        // if (text.toLowerCase().startsWith(checkValue)){
        //     const value=text.substring(checkValue.length).trim();

        //     return value;
        // }
    }

    return null;
};

const getSelectComponent = (componentData) => {
    const component = {
        component: FORM_TYPES.SELECT,
        name: getString(componentData, "@Name"),
        text: extractMessage(getProp(componentData, "TEXT")),
        options: getOptions(getProp(componentData, "OPTIONS.OPTION")),
    };
    const selectType = getString(componentData, "@SelectType");

    const startingValue = getTextStartingValue(componentData);
    if (check.nonEmptyString(startingValue)) {
        component.startingValue = startingValue;
    }

    if (selectType === "Radio") {
        component.type = FORM_SELECT_TYPES.RADIO;
    } else if (selectType === "Checkbox") {
        component.type = FORM_SELECT_TYPES.CHECKBOX;
    } else if (selectType === "Dropdown") {
        component.type = FORM_SELECT_TYPES.DROPDOWN;
    } else {
        debugger;
    }

    component.action = getString(componentData, "@Action");

    return component;
};

const getOptions = (options) => {
    if (check.nonEmptyArray(options)) {
        return options.map((option) => extractMessage(getProp(option, "TEXT")));
    } else if (check.nonEmptyObject(options)) {
        return [extractMessage(getProp(options, "TEXT"))];
    }
    return [];
};

const getButtonComponent = (componentData) => {
    const component = {
        component: FORM_TYPES.BUTTON,
        name: getString(componentData, "@Name"),
        text: extractMessage(getProp(componentData, "TEXT")),
        action: getString(componentData, "@Action"),
    };

    return component;
};

const getFileComponent = (componentData) => {
    const _field = getString(componentData, "@Field");
    const field = check.nonEmptyString(_field) ? _field : "attached";
    const component = {
        component: FORM_TYPES.FILE,
        name: getString(componentData, "@Name"),
        text: extractMessage(getProp(componentData, "TEXT")),
        field,
        action: getString(componentData, "@Action"),
    };

    return component;
};

const getImageComponent = (componentData) => {
    const component = {
        component: FORM_TYPES.IMAGE,
        name: getString(componentData, "@Name"),
        src: getString(componentData, "@Source"),
    };

    return component;
};

const getHotspotComponent = (componentData) => {
    const component = {
        component: FORM_TYPES.HOTSPOT,
        name: getString(componentData, "@Name"),
    };

    return component;
};

const getDelayComponent = (componentData) => {
    const component = {
        component: FORM_TYPES.DELAY,
        delay: getString(componentData, "@Delay"),
        action: getString(componentData, "@Action"),
    };

    return component;
};

// Note: state should always be initialized at this point (via QUESTION_REDUCER)
const FORM_QUESTION_REDUCER = (state, action) => {
    switch (action.type) {
        case SET_EXAM_DATA:
            return handleSetExamData(state, action);
        default:
            return state;
    }
};

export { FORM_QUESTION_REDUCER };
