
const styles = ({palette, spacing, breakpoints}) => ({
	text: {
		color: palette.background.contrastText
	},
	scroller: {
		height: '100%',
		overflowY: 'scroll',
		paddingBottom: 200,
	},
	layout: {
	  width: 'auto',
	  display: 'block', // Fix IE11 issue.
	  marginLeft: spacing.unit * 2,
		marginRight: spacing.unit * 2,
		paddingTop: spacing.unit * 2,
	  [breakpoints.up(800 + spacing.unit * 3 * 2)]: {
			width: 600,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	form: {
		padding: spacing.unit * 2,
		position: 'relative',
		backgroundColor: palette.background.light
	},
	formInputText: {
		color: palette.background.contrastText + "!important"
	},
	passwordText: {
		paddingLeft: 50,
		marginBottom: spacing.unit
	},
	lockIcon: {
		fontSize: 38,
		left: 15,
		marginTop: 2,
		position: 'absolute',
		display: 'inline-block',
	},
	error: {
		color: palette.error.main,
		height: spacing.unit * 3,
	},
	buttons: {
		textAlign: 'right'
	},
	button: {
		backgroundColor: palette.primary.main + "!important",
		color: palette.primary.contrastText + "!important"
	},
	buttonDisabled: {
		backgroundColor: palette.background.light + "!important",
		color: palette.background.contrastText + "!important"
	},
	submit: {
		marginLeft: spacing.unit
	},
	inputUnderline: {
		'&:before': {
			borderBottom: `1px solid ${palette.background.contrastText} !important`
		}
	}
});


export {styles}