
// npm
import React from 'react'

import { check } from '@xams-utils/check-types'
import PropTypes from 'prop-types'

// xams-components
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamStarter} from './exam-starter'
import {ExamPausedPage} from './pause/paused-page'
import {ExamPauseManager} from './pause/pause-manager'
import {PlayingExamView} from './playing_exam/view'
import {SetNetworkError} from 'components/pages/network_error/set-network-error'
import {ScheduleCompletionChecker} from 'components/pages/_exam/initializing/validating_schedule/completion-checker'
import {ScheduleCompletedPopup} from 'components/pages/_exam/initializing/validating_schedule/completed-popup'

// machines
import {examRunning} from 'machines/exam-running'


const {STATES, EVENTS} = examRunning;


const ExamUnpauseControl = () => (
	<StateControl onUnpause={EVENTS.UNPAUSE_EXAM}>
		{({onUnpause}) => <ExamPauseManager onUnpause={onUnpause}/>}
	</StateControl>
)

const ExamPauseControl = () => (
	<StateControl onPause={EVENTS.PAUSE_EXAM}>
		{({onPause}) => <ExamPauseManager onPause={onPause}/>}
	</StateControl>
)

const getExamGuid = (_examGuid) => {
    if (check.object(_examGuid)) {
        const { examGuid } = _examGuid;
        if (check.string(examGuid)) {
            return examGuid;
        }
    } else if (check.string(_examGuid)) {
        return _examGuid;
    }

    debugger;
};

const ExamRunningPageMachine = (_examGuid) =>
{
	const examGuid = getExamGuid(_examGuid);

	return (
		<StateMachine machine={examRunning.machine}>
			<StateView>
				{{
					[STATES.CHECKING_SCHEDULE_COMPLETION]: () => (
						<StateControl onComplete={EVENTS.SCHEDULE_COMPLETE} onIncomplete={EVENTS.SCHEDULE_INCOMPLETE}>
							{(props) => <ScheduleCompletionChecker examGuid={examGuid} {...props}/>}
						</StateControl>
					),
					[STATES.SCHEDULE_ALREADY_COMPLETED]: () => (
						<ScheduleCompletedPopup/>
					),					
					[STATES.STARTING_EXAM]: () => (
						<StateControl onStarted={EVENTS.EXAM_STARTED} onFail={EVENTS.ERROR}>
							{(props) => <ExamStarter {...props}/>}
						</StateControl>
					),
					[STATES.RUNNING]: () => (
						<React.Fragment>
							<ExamPauseControl/>
							{PlayingExamView()}
						</React.Fragment>
					),
					[STATES.PAUSED]: () => (
						<React.Fragment>
							<ExamUnpauseControl/>							
							<ExamPausedPage/>
						</React.Fragment>
					),
					[STATES.NETWORK_ERROR]: () => (
						<SetNetworkError/>
					)
				}}
			</StateView>
		</StateMachine>
	);
}


export {ExamRunningPageMachine}