// npm
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { getSettingsData, getExamData } from "redux/reducers/selectors";
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import { getIsDownloadablePracticeExam } from "redux/reducers/settings/client/selectors";

import {getFormRunGuid} from 'redux/reducers/exam/selectors'
import * as actions from './actions'

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

// react
import { LoadingSpinner } from "components/presentation/loading-spinner";
import { MaterialText } from "components/presentation/material-text";
import {AppPageModifier} from 'components/pages/app-page-modifier'

const styles = ({ spacing, palette, breakpoints }) => ({
    panel: {
        backgroundColor: palette.background.light,
        [breakpoints.down(800)]: {
            width: "80%",
        },
        [breakpoints.up(801)]: {
            width: 800,
        },
        marginTop: spacing.unit * 2,
        alignSelf: "stretch",
    },
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    inputDiv: {
        width: "calc(100% - 8px)",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: spacing.unit*2,
        "&>div:first-child": {
            width: "100%",
        },
    },
    buttonDiv: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
});

const saveUserName = (formRunGuid, userName) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const errorText = userName.includes('bum') ? 'User name not acceptable' : '';
            resolve(errorText);
        }, 3000);
    });
};

class PublicExamPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleComplete = this.handleComplete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        this.state = { busy: false, value: "", errorText: "" };
    }

    componentDidMount() {
        const { onComplete, isDownloadablePracticeExam } = this.props;
        
        if (isDownloadablePracticeExam) {
            if (this.nameInput) {
                this.nameInput.focus();
            }
        }
        else{
            onComplete();
        }
    }

    handleComplete() {
        const { value } = this.state;
        const { onComplete, formRunGuid, setPublicExamUserName } = this.props;
        this.setState({ busy: true }, () => {
            setPublicExamUserName(value);
            onComplete();

            // saveUserName(formRunGuid, value).then((errorText) => {
            //     if (errorText && errorText!=='') {
            //         this.setState({errorText, busy:false})
            //     }
            //     else{
            //         onComplete();
            //     }
            // });
        });
    }

    handleKeyPress(e){
        if(e.keyCode == 13){
            this.handleComplete();
         }        
    }

    handleChange(value) {
        this.setState({ value });
    }

    handleClear() {
        this.setState({ value: "" });
    }

    render() {
        const { busy, value, errorText } = this.state;
        const { classes, isDownloadablePracticeExam } = this.props;

        if (!isDownloadablePracticeExam || busy) {
            return <LoadingSpinner size="lg" />;
        }
        const buttonDisabled = value.length === 0;
        const message = "Please enter your name";
        const error = errorText!=='';
        const helperText =error?errorText:null;
        const title = 'Identification';

        return (
            <React.Fragment>
            <AppPageModifier appBarProps={{title, logo: true}}/>
            <div className={classes.root}>
                <Card className={classes.panel}>
                    <CardContent>
                        <div>
                            <MaterialText variant="h6">{message}</MaterialText>
                        </div>
                        <form noValidate autoComplete="off">
                            <div className={classes.inputDiv}>
                                <div>
                                    <TextField
                                        id="nameField"
                                        label="Name"
                                        value={value}
                                        placeholder="Your name"
                                        fullWidth
                                        margin="normal"
                                        error={error}
                                        helperText={helperText}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            this.handleChange(e.target.value);
                                        }}
                                        onKeyDown={this.handleKeyPress}
                                        inputRef={(input) => {
                                            this.nameInput = input;
                                        }}
                                    />
                                </div>
                                <div>
                                    <IconButton
                                        aria-label="clear input"
                                        disabled={buttonDisabled}
                                        onClick={this.handleClear}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.buttonDiv}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={buttonDisabled}
                                    onClick={this.handleComplete}
                                >
                                    Complete
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
            </React.Fragment>
        );
    }
}

PublicExamPage.propTypes = {
    onComplete: PropTypes.func.isRequired,
};

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);
    const examData=getExamData(store);

    return  {
        isDownloadablePracticeExam: getIsDownloadablePracticeExam(clientSettingsData),
        formRunGuid: getFormRunGuid(examData),
    };
};

const mapDispatchToProps = (dispatch) => ({
	setPublicExamUserName: (name) => dispatch(actions.setPublicExamUserName(name)),
});


PublicExamPage = withStyles(styles)(PublicExamPage);
PublicExamPage = connect(mapStoreToProps, mapDispatchToProps)(PublicExamPage);

export { PublicExamPage };
