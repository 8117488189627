
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-utils
import {check} from '@xams-utils/check-types'

// material-ui
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContentText from '@material-ui/core/DialogContentText'

// react
import {MaterialText} from 'components/presentation/material-text'


class Popup extends React.Component {
    render() {
        const props = {
            classes: {
                paper: this.props.classes.paper,
            },
            onClose: this.props.onClose,
            disableBackdropClick: !this.props.canClickBackdrop,
            open: true,
        };

        return (
            <Dialog {...props}>
                {this.Title}
                {this.Content}
                {this.Actions}
            </Dialog>
        );
    }

    get Title() {
        const { title, classes } = this.props;
        if (!title) {
            return null;
        }
        return (
            <DialogTitle>
                <MaterialText
                    variant="h6"
                    component="span"
                    className={classes.text}
                >
                    {title}
                </MaterialText>
            </DialogTitle>
        );
    }

    get Content() {
        const { content, classes } = this.props;
        if (!content) {
            return null;
        }

        const { text, node } = content;
        if (!check.string(text) && !node) {
            throw "No popup content";
        }

        return (
            <DialogContent>
                {text ? (
                    <DialogContentText>
                        <MaterialText component="span" className={classes.text}>
                            {text}
                        </MaterialText>
                    </DialogContentText>
                ) : null}
                {node || null}
            </DialogContent>
        );
    }

    get Actions() {
        const { buttons } = this.props;

        if (!buttons) {
            return null;
        }

        const _buttons = buttons.map((button, index) => {
            return check.assigned(button.key)
                ? button
                : { ...button, key: index };
        });

        return <DialogActions>{_buttons}</DialogActions>;
    }
}

Popup.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	content: PropTypes.shape({
		text: PropTypes.string, // used for string content (including nodes that render strings)
		node: PropTypes.node
	}),
	onClose: PropTypes.func,
	buttons: PropTypes.arrayOf(PropTypes.node),
	canClickBackdrop: PropTypes.bool
}



const styles = ({palette}) => ({
	text: {
		color: palette.background.contrastText + "!important"
	},
	paper: {
		backgroundColor: palette.background.light
	}
})

Popup = withStyles(styles)(Popup)


export {Popup}