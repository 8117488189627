
// npm
import React from 'react'
import check from 'check-types'

// react (HOCs)
import {MessagesProvider} from 'components/hocs/messages/provider'

// react
import {PageRouter} from './page-router'
import {AppBar} from './layout/app_bar/app-bar'
import {PageWrapper} from './layout/page-wrapper'
import {AppPageProvider} from 'components/contexts/app_page/provider'


class AppPage extends React.Component
{
	constructor(props)
	{
		super(props);

		this.appBarProps = {
			defaultValue: {},
			stack: []
		}

		this.loading = {
			defaultValue: false,
			stack: []
		}

		this.state = {
			appBarProps: this.appBarProps.defaultValue,
			loading: this.loading.defaultValue
		};

		this.initializeBoundMethods();
		this.initializeContext();
	}

	initializeBoundMethods()
	{
		this.setStateValue = (name, value) => {
			this[name].stack.push(value);
			this.setState({[name]: value});
		}

		this.unsetStateValue = (name) => {
			const stack = this[name].stack;
			const defaultValue = this[name].defaultValue;
			stack.pop();

			const nextValue = stack[stack.length - 1] || defaultValue;
			this.setState({[name]: nextValue});
		}
	}

	initializeContext()
	{
		this.contextValue = {
			pushAppBarState: (appBarProps) => {
				if (!check.object(appBarProps)) { throw "updateAppBar type error"; }
				this.setStateValue('appBarProps', appBarProps);
			},
			pushLoadingState: (loading) => {
				this.setStateValue('loading', loading);
			},
			revokeAppBarState: () => {
				this.unsetStateValue('appBarProps');
			},
			revokeLoadingState: () => {
				this.unsetStateValue('loading');
			}
		}
	}

	render()
	{
		return (
			<AppPageProvider value={this.contextValue}>
				<MessagesProvider>
					<AppBar {...this.state.appBarProps}/>
					<PageWrapper loading={this.state.loading}>
						<PageRouter/>
					</PageWrapper>
				</MessagesProvider>
			</AppPageProvider>
		)
	}
}


export {AppPage}