import React, { Component } from "react";
import {withRouter} from 'react-router-dom'

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { getStatusIcon, getStatusText, hasScormFailed } from "./helper";
import { PlayScormButton } from "./course/play-scorm-button";


class ElearningSchedule extends Component {
    state = {};

    get Icon() {
        const { status } = this.props;

        return getStatusIcon(status);
    }

    get PrimaryText() {
        const { courseName, name } = this.props;

        return name || courseName;
    }

    get SecondaryText() {
        const { status, dateStarted, dateEnded } = this.props;

        return getStatusText(status, dateStarted, dateEnded);
    }

    get SecondaryAction() {
        const { status } = this.props;

        if (hasScormFailed(status)) return null;

        const buttonProps = {
            status,
            onClick: this.openElearning
        };

        return (
            <ListItemSecondaryAction>
                <PlayScormButton {...buttonProps}/>
            </ListItemSecondaryAction>
        );
    }

    openElearning = () => {
        const { attemptGUID, guid } = this.props;
        const _guid = guid || attemptGUID;

        this.props.history.push(`/elearning/${_guid}`);
    };

    render() {
        const { status } = this.props;
        const isButton = !hasScormFailed(status);
        const eLearning = hasScormFailed(status)?null:this.openElearning;

        return (
            <ListItem button={isButton} onClick={eLearning}>
                <ListItemAvatar>
                    <Avatar>{this.Icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={this.PrimaryText}
                    secondary={this.SecondaryText}
                />
                {this.SecondaryAction}
            </ListItem>
        );
    }
}

ElearningSchedule = withRouter(ElearningSchedule);

export { ElearningSchedule };
