
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getResourceUrls} from 'redux/reducers/exam/content/resource-urls-selector'

// redux (actions)
import {cachePdfResource} from './actions'

// utils
import {generalApi} from 'libs/api/interface/api-general'


// ExamResourceLoader (not connected)
// -------------------------------------------------------------------

class ExamResourceLoader extends React.Component
{
	componentDidMount()
	{
		if (this.props.resourceUrls.length === 0) {
			this.props.onSuccess();
		}
		else {
			this.loadUrls();
		}
	}

	loadUrls()
	{
		const {resourceUrls, onFail} = this.props;
		const urlCount = resourceUrls.length;
		
		let successCount = 0;

		const onSuccess = (resourceUrl) => ({blob}) => {
			const blobUrl = URL.createObjectURL(blob);
			this.props.cachePdf(resourceUrl, blobUrl);

			if (++successCount === urlCount) {
				this.props.onSuccess();
			}
		}

		resourceUrls.forEach(resourceUrl => {
			generalApi.fetchResource(resourceUrl)
			.then(onSuccess(resourceUrl), onFail);
		});
	}

	render()
	{
		return null;
	}
}

ExamResourceLoader.propTypes = {
	resourceUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
	onSuccess: PropTypes.func.isRequired,
	cachePdf: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired
}


// ExamResourceLoader (connected)
// -------------------------------------------------------------------

const mapStoreToProps = (store) =>
{
	const examData = getExamData(store);
	const contentData = getContentData(examData);

	const resourceUrls = getResourceUrls(contentData);

	return {resourceUrls}
}

const mapDispatchToProps = (dispatch) => ({
	cachePdf: (url, blobUrl) => dispatch(cachePdfResource(url, blobUrl))
});

ExamResourceLoader = connect(mapStoreToProps, mapDispatchToProps)(ExamResourceLoader);


// ExamResourceLoader (export)
// -------------------------------------------------------------------

export {ExamResourceLoader}