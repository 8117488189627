
import {randomize} from 'utils/randomize'
import {validateAsArray} from 'utils/type-validator'


const containsDuplicates = function(array)
{
	validateAsArray(array);
	return (new Set(array)).size !== array.length;
}

const hasIndex = function(array, index)
{
	validateAsArray(array);
	return index >= 0 && index < array.length;
}

const shuffle = function(array)
{
	validateAsArray(array);
	const shuffledArray = [...array];

	for (let i = shuffledArray.length - 1; i > 0; i--)
	{
		const swapIndex = randomize.integer(0, i + 1);
		const swapValue = shuffledArray[swapIndex];

		shuffledArray[swapIndex] = shuffledArray[i];
		shuffledArray[i] = swapValue;
	}

	return shuffledArray;
}

export {containsDuplicates, hasIndex, shuffle}