
// other modules
import {api} from '../api'
import {ENDPOINTS} from '../constants'


const logError = (payload) => api.post(ENDPOINTS.LOG_ERROR, payload);
const sendBugReport = (payload) => api.post(ENDPOINTS.BUG_REPORT, payload);


const errorApi = {logError, sendBugReport};


export {errorApi}