
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {NoClientPage} from './no-client-page'
import {LogoPreloader} from './logo-preloader'
import {SettingsInitializer} from './settings-initializer'
import {InitializingClientView} from './initializing_client/view'
import {ValidatingAppSettingsView} from './validating_app_settings/view'
import {MessageLoader} from 'components/functional/message_loader/loader'

// machines
import {init} from 'machines/init'


const {EVENTS, INITIALIZING_STATES:STATES} = init;


const InitializingView = () => (
	<StateView>
		{{
			[STATES.INITIALIZING_APP_SETTINGS]: () => (
				<StateControl onSuccess={EVENTS.CONFIG_INITIALIZED} onFail={EVENTS.ERROR}>
					{(props) => <SettingsInitializer {...props}/>}
				</StateControl>
			),
			[STATES.VALIDATING_APP_SETTINGS]: () => (
				ValidatingAppSettingsView()
			),
			[STATES.INITIALIZING_CLIENT]: () => (
				InitializingClientView()
			),
			[STATES.INITIALIZING_SESSION_MESSAGES]: () => (
				<StateControl onLoad={EVENTS.SESSION_MESSAGES_INITALIZED} onError={EVENTS.ERROR}>
					{(props) => <MessageLoader {...props} session/>}
				</StateControl>
			),
			[STATES.INITIALIZING_GENERAL_MESSAGES]: () => (
				<StateControl onLoad={EVENTS.MESSAGES_INITIALIZED} onError={EVENTS.ERROR}>
					{(props) => <MessageLoader {...props}/>}
				</StateControl>
			),
			[STATES.PRELOADING_LOGOS]: () => (
				<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
					{(props) => <LogoPreloader {...props}/>}
				</StateControl>
			),
			[STATES.NO_CLIENT]: () => (
				<NoClientPage/>
			)
		}}
	</StateView>
)


export {InitializingView}