import React, { Component } from "react";
import { connect } from "react-redux";

import { getExamData } from "redux/reducers/selectors";
import { isExamPaused } from "redux/reducers/exam/selectors";

class ExamPauseManager extends Component {
    state = {};

    componentDidMount() {
        const { paused, onPause } = this.props;
		
        if (paused && onPause) {
            onPause();
        }
    }

    componentDidUpdate(prevProps) {
        const { paused: prevPaused } = prevProps;
        const { paused, onPause, onUnpause } = this.props;

        if (paused !== prevPaused) {
			console.log('ExamPauseManager - Prev Paused',prevPaused,'Paused',paused);

            if (paused && onPause) {
				console.log('ExamPauseManager - onPause')
                onPause();
            } else if (onUnpause) {
				console.log('ExamPauseManager - onUnpause')
                onUnpause();
            }
        }
    }

    render() {
        return null;
    }
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const paused = isExamPaused(examData);

    return { paused };
};

ExamPauseManager = connect(mapStoreToProps)(ExamPauseManager);

export { ExamPauseManager };
