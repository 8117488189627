
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react
import {PdfResourcePanel as PdfResourcePanelOld} from './panel'
import {PdfResourcePanel as PdfResourcePanelNew} from './panel-pdf-js'

// redux (selectors)
import {getCacheData} from 'redux/reducers/selectors'
import {getCachedPdfData} from 'redux/reducers/cache/selectors'
import {getCachedUrl} from 'redux/reducers/cache/pdfs/selectors'
import { isSEB } from '../workings/calculator-helper'
import { useOldPdfViewer } from 'components/pages/_exam/initializing/checking_resources/pdf_check/pdf-browser-support';


// CachedPdfResourcePanel (not connected)
// -------------------------------------------------------------------------

let CachedPdfResourcePanel = ({cachedPdfData, resourceUrl}) =>
{
	const cachedUrl = getCachedUrl(cachedPdfData, resourceUrl);
	const finalResourceUrl = cachedUrl || resourceUrl;

	if (useOldPdfViewer()){
		return <PdfResourcePanelOld resourceUrl={finalResourceUrl}/>;
	}
	else{
		return <PdfResourcePanelNew resourceUrl={finalResourceUrl}/>;	
	}
	
	// return isSEB()?<PdfResourcePanelOld resourceUrl={finalResourceUrl}/>:<PdfResourcePanelNew resourceUrl={finalResourceUrl}/>;
	//return <PdfResourcePanelNew resourceUrl={finalResourceUrl}/>
}

CachedPdfResourcePanel.propTypes = {
	cachedPdfData: PropTypes.object.isRequired,
	resourceUrl: PropTypes.string.isRequired
}


// CachedPdfResourcePanel (connected)
// -------------------------------------------------------------------------

const mapStoreToProps = (store) => ({
	cachedPdfData: getCachedPdfData(getCacheData(store))
});

CachedPdfResourcePanel = connect(mapStoreToProps)(CachedPdfResourcePanel);


// CachedPdfResourcePanel (export)
// -------------------------------------------------------------------------

export {CachedPdfResourcePanel}