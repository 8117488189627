
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'

// react
import {Align} from 'components/layout/align'


const SIZES = {
	xs: 20,
	sm: 30,
	md: 40,
	lg: 50,
	xl: 60
}


const LoadingSpinner = ({children, size, color, disableModal, open}) =>
{
	const modalProps = {
		disableBackdropClick: true,
		disableEscapeKeyDown: true,
		hideBackdrop: true,
		open
	}

	return (
		<React.Fragment>
			<Modal {...modalProps}>
				<div style={{height: '100vh', width: '100vw'}}>
					<Align>
						<CircularProgress color={color} size={SIZES[size]}/>
					</Align>
				</div>
			</Modal>
			{children || null}
		</React.Fragment>
	)
}

LoadingSpinner.propTypes = {
	disableModal: PropTypes.bool.isRequired,																				// only applies when loadingspinner contains children
	size: PropTypes.string.isRequired,																							// size of spinner (following material-ui size convention)
	open: PropTypes.bool.isRequired,																								// whether to show spinner or not
	children: PropTypes.node,																												// the content to display underneath the modal spinner
	color: PropTypes.string																													// the color of the spinner
}

LoadingSpinner.defaultProps = {
	size: 'md',
	open: true,
	disableModal: false
}


export {LoadingSpinner}