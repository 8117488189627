
// custom
import {getProp} from 'custom/object-helper'

// redux
import {OPTIONS_REDUCER} from './options/reducer'
import {SELECTIONS_REDUCER} from './selections/reducer'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


// Note: state should always be initialized at this point 
// (via QUESTION_REDUCER)
const MATCH_QUESTION_REDUCER = (state, action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			const subData = getProp(action, 'data.OPTIONS.MATCHOPTION');
			const subAction = {...action, data: subData};																// 'options' and 'selections' use the same action data
			state = state.set('options', OPTIONS_REDUCER(undefined, subAction));
			return state.set('selections', SELECTIONS_REDUCER(undefined, subAction));		// selections = potential answers for each option
		default:
			return state;
	}
}

export {MATCH_QUESTION_REDUCER}