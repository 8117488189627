
// npm
import {Map as ImmutableMap} from 'immutable'

// custom
import {getProp} from 'custom/object-helper'

// redux
import {extractMessage} from 'redux/reducers/exam/helpers'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const SELECTION_REDUCER = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:{
			const id = getProp(action, 'data.@ID');
			var selectionData = getProp(action, 'data.ANSWER');

			if (!selectionData) { throw "match q selection not found"; }
			// state = state.set('id', getProp(selectionData, 'MESSAGE.@PK'));
			state = state.set('id', id);

			return state.set('text', extractMessage(selectionData));
		}
		default:
			return state;
	}
}


export {SELECTION_REDUCER}