// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { milliseconds, seconds } from "time-convert";
import { check } from "@xams-utils/check-types";

// material-ui
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import BuildIcon from "@material-ui/icons/Build";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import UploadIcon from "@material-ui/icons/CloudUploadOutlined";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";

// react
import { ExamPopup, POPUP_TYPES } from "./exam_popup";
import { ExamLayout } from "components/pages/exam/_layout/layout";
import { MaterialText } from "components/presentation/material-text";
import { ExamTimer } from "components/pages/exam/running/exam-timer";
import { ExamContent } from "components/pages/exam/_layout/content/panel/content";
import { UploadPanel } from "components/pages/exam/_layout/side/panels/upload/panel";
import { WorkingsPanel } from "components/pages/exam/_layout/side/panels/workings/panel";
import { MiniQuestionNavigation } from "components/pages/exam/running/mini-question-navigation";
import { FinishExamButton } from "components/pages/exam/running/questions_screen/finish-button";
import { StartAnsweringButton } from "components/pages/exam/running/reading_screen/start-button";
import { CachedPdfResourcePanel } from "components/pages/exam/_layout/side/panels/pdf_resource/index";
import { QuestionsNavigationPanel } from "components/pages/exam/_layout/side/panels/navigation/panel";
import { InstructionsButton } from "components/pages/exam/running/questions_screen/instructions-button";
import { PrintStaticTextButton } from "components/pages/exam/running/questions_screen/print_static_text/print-static-text-button";
import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";
import { DisplayAllQuestionsBar } from "components/pages/exam/_layout/content/panel/display_all_questions/display-all-questions-bar";

// react (temporary!!!!)
import { ExamSaver } from "components/pages/exam/exam_saver";

// redux (selectors)
import { getExamData, getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getReadingTimeRemaining } from "redux/reducers/exam/complex-selectors";
import { canBypassReadingTime } from "redux/reducers/exam/time/selectors";
import { getType } from "redux/reducers/exam/content/questions/question/selectors";
import { getSectionDataById } from "redux/reducers/exam/content/sections/selectors";
import { getUploadFileTypesAllowed } from "redux/reducers/settings/client/selectors";
import { getQuestionDataById } from "redux/reducers/exam/content/questions/selectors";
import { getSectionName } from "redux/reducers/exam/content/sections/section/selectors";
import {
    getContentData,
    getFormRunGuid,
    isPracticeExam,
    getTimeData,
} from "redux/reducers/exam/selectors";
import {
    getQuestionsData,
    getSectionsData,
    getCurrentQuestionId,
} from "redux/reducers/exam/content/selectors";
import {
    getResourceUrl,
    hasUnansweredQuestions,
    hasPaperPartUnansweredQuestions,
    getPaperPartId,
    getNextPaperPartId,
    getFirstQuestionIdInSection,
    requiresWorkings,
    requiresCalculator,
} from "redux/reducers/exam/content/complex-selectors";
import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";
import {
    getShowExamInFullScreen,
    getInitialFullScreen,
} from "redux/reducers/exam/selectors";

// constants
import { ICT, STATIC_TEXT } from "constants/question-types";

// redux (actions)
import { setPaperPartComplete } from "./actions";

// redux (action-types)
import { SET_READING_TIME_REMAINING } from "redux/reducers/exam/time/action-types";
import { SET_READING_TIME_REMAINING as SET_SECTION_READING_TIME_REMAINING } from "redux/reducers/exam/content/sections/section/action-types";

import { closeFullScreen } from "components/pages/_exam/initialized/running/leave_resume_exam/full_screen/helper"

const setReadingTimeRemaining = (value) => ({
    type: SET_READING_TIME_REMAINING,
    value: seconds.from(milliseconds)(value),
});

const setSectionReadingTimeRemaining = (value, id) => ({
    type: SET_SECTION_READING_TIME_REMAINING,
    value: seconds.from(milliseconds)(value),
    id,
});

const doesEndWith = (fileName, extension = "pdf") => {
    if (check.nonEmptyString(fileName) && check.nonEmptyString(extension)) {
        const parts = fileName.split(".");

        if (check.nonEmptyArray(parts)) {
            const endPart = parts[parts.length - 1];
            if (check.nonEmptyString(endPart)) {
                return endPart.startsWith(extension);
            }
        }
    }

    return false;
};

// ExamQuestionPage (not connected to store)
// -----------------------------------------------

class ExamQuestionPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: null,
            popupData: {
                paperPartName: props.paperPartName,
                nextPaperPartName: props.nextPaperPartName,
            },
        };

        this.popupType = null;

        this.closePopup = () => {
            this.setState({ popup: null });
        };

        this.openPopup = (popupType) => () => {
            this.setState({ popup: popupType });
        };

        this.onFinish = () => {
            const { needToCloseFullScreen } = this.props;

            this.setState({ popup: null }, () => {
                activityLogger
                    .log(ACTIVITIES.CONFIRM_POPUP, { popup: this.popupType })
                    .then(() => {
                        if (needToCloseFullScreen) {
                            const { nextPaperPartName } = this.props;

                            if (!check.nonEmptyString(nextPaperPartName)) {
                                closeFullScreen();
                            }
                        }
                        
                        this.props.onFinish();
                    });
            });
        };

        this.timesUp = () => {
            const { needToCloseFullScreen } = this.props;
            activityLogger.log(ACTIVITIES.TIMED_OUT, {}).then(() => {
                if (needToCloseFullScreen) {
                    closeFullScreen();
                }

                this.props.onFinish();
            });
        };

        this.paperPartTimedOut = (timerPaperPartId) => {
            if (timerPaperPartId) {
                const { currentPaperPartId } = this.props;

                if (currentPaperPartId !== timerPaperPartId) {
                    activityLogger.log(ACTIVITIES.POPUP_ERROR, {
                        currentPaperPartId,
                        timerPaperPartId,
                    });

                    return;
                }
            }

            this.setState({ popup: POPUP_TYPES.PART_FINISHED });
        };
    }

    tryUpdatePopupData() {
        if (this.props.paperPartName !== this.state.popupData.paperPartName) {
            const { paperPartName, nextPaperPartName } = this.props;
            this.setState({ popupData: { paperPartName, nextPaperPartName } });
        }
    }

    checkPopupChange(prevState) {
        const { popup: prevPopup } = prevState;
        const { popup: newPopup } = this.state;

        if (prevPopup !== newPopup) {
            const payload = {
                prevPopup,
                newPopup,
            };

            activityLogger.log(ACTIVITIES.DISPLAY_POPUP, payload);
        }
    }

    render() {
        const examLayoutProps = {
            bar: {
                content: (
                    <React.Fragment>
                        <MiniQuestionNavigation
                            onQuestionChange={this.props.onQuestionChange}
                        />
                        <div style={{ flexGrow: 1 }} />
                        <InstructionsButton
                            onClick={this.props.onShowInstructions}
                        />
                        {this.StartOrFinishButton}
                        {this.ExamPopup}
                        {this.ExamTimer}
                        {this.DownloadResourceButton}
                        {this.PrintResourceButton}
                    </React.Fragment>
                ),
            },
            centre: {
                bar: (
                    <div>
                        <DisplayAllQuestionsBar
                            currentQuestionId={this.props.questionId}
                        />
                    </div>
                ),
                content: (
                    <div style={{ flexGrow: 1 }}>
                        <ExamContent
                            currentQuestionId={this.props.questionId}
                            reading={this.props.inReadingTime}
                        />
                    </div>
                ),
            },
            left: {
                Icon: MenuIcon,
                content: QuestionsNavigationPanel(this.props.onQuestionChange), // See <BoundsListener> error for why I do this
            },
            right: this.RightContent,
        };

        return <ExamLayout {...examLayoutProps} />;
    }

    componentDidUpdate(prevProps, prevState) {
        this.tryUpdatePopupData();
        this.checkPopupChange(prevState);
    }

    get ExamPopup() {
        const { popup, popupData } = this.state;

        if (!check.assigned(popup)) {
            return null;
        }

        const popupProps = {
            type: popup,
            data: popupData,
            onClose: this.closePopup,
            onConfirm: this.getPopupOnConfirm(popup),
        };

        return <ExamPopup {...popupProps} />;
    }

    getPopupOnConfirm(type) {
        this.popupType = type;

        switch (type) {
            case POPUP_TYPES.EXAM_FINISH_CONFIRM:
                return this.props.hasUnansweredQuestions
                    ? this.openPopup(POPUP_TYPES.EXAM_FINISH_CONFIRM_2)
                    : this.onFinish;
            case POPUP_TYPES.PART_FINISH_CONFIRM:
                return this.props.hasUnansweredQuestions
                    ? this.openPopup(POPUP_TYPES.PART_FINISH_CONFIRM_2)
                    : this.onFinish;
            case POPUP_TYPES.PRACTICE_EXAM_FINISH_CONFIRM:
                return this.openPopup(
                    POPUP_TYPES.PRACTICE_EXAM_FINISH_CONFIRM_2
                );
            case POPUP_TYPES.PRACTICE_EXAM_FINISH_CONFIRM_2:
            case POPUP_TYPES.EXAM_FINISH_CONFIRM_2:
            case POPUP_TYPES.PART_FINISH_CONFIRM_2:
            case POPUP_TYPES.PART_FINISHED:
                return this.onFinish;
            default:
                return null;
        }
    }

    get ExamTimer() {
        const props = {
            timerPaperPartId: this.props.currentPaperPartId,
            reading: this.props.inReadingTime,
            currentQuestionId: this.props.questionId,
            onFinish: this.props.inReadingTime
                ? this.props.clearReadingTime
                : this.props.nextPaperPartName
                ? this.paperPartTimedOut
                : this.timesUp,
        };

        return <ExamTimer {...props} />;
    }

    get RightContent() {
        const { hasDownload, resourceUrl, hasCalculator, hasWorkings } =
            this.props;

        if (hasDownload) {
            return this.UploadPanel;
        } else if (this.isPdfResourceViewable) {
            return this.CachedPdfResourcePanel;
        } else if (hasWorkings || hasCalculator) {
            return this.WorkingsPanel;
        }

        return undefined;
    }

    get UploadPanel() {
        const props = {
            formRunGuid: this.props.formRunGuid,
            accepts: this.props.uploadFileTypesAllowed,
            instantUpload: true,
        };

        return {
            Icon: UploadIcon,
            content: <UploadPanel {...props} />,
        };
    }

    get CachedPdfResourcePanel() {
        const { resourceUrl } = this.props;

        return {
            Icon: ImportContactsIcon,
            prioritize: true,
            content: (
                <CachedPdfResourcePanel
                    key={resourceUrl}
                    resourceUrl={resourceUrl}
                />
            ),
        };
    }

    get WorkingsPanel() {
        const props = {
            key: this.props.questionId,
            disableCalculator: !this.props.hasCalculator,
        };

        return {
            Icon: BuildIcon,
            content: <WorkingsPanel {...props} />,
        };
    }

    get DownloadResourceButton() {
        if (!this.isDownloadableResource) {
            return null;
        }

        const { resourceUrl } = this.props;

        return (
            <IconButton onClick={() => window.open(resourceUrl, "_blank")}>
                <DownloadIcon />
            </IconButton>
        );
    }

    get PrintResourceButton() {
        if (!this.isPrintableResource) {
            return null;
        }

        const { questionId } = this.props;
        const props = { questionId };

        return <PrintStaticTextButton {...props} />;
    }

    get StartOrFinishButton() {
        const { inReadingTime, bypassReadingTime, clearReadingTime } =
            this.props;

        if (inReadingTime) {
            if (bypassReadingTime) {
                return <StartAnsweringButton onClick={clearReadingTime} />;
            }

            return null;
        } else {
            const { practiceExam, nextPaperPartName } = this.props;
            const { EXAM_FINISH_CONFIRM: finishExam } = POPUP_TYPES;
            const { PART_FINISH_CONFIRM: finishPart } = POPUP_TYPES;
            const { PRACTICE_EXAM_FINISH_CONFIRM: finishPractice } =
                POPUP_TYPES;

            const onClick = practiceExam
                ? this.openPopup(finishPractice)
                : nextPaperPartName
                ? this.openPopup(finishPart)
                : this.openPopup(finishExam);

            return <FinishExamButton onClick={onClick} />;
        }
    }

    get isDownloadableResource() {
        const { resourceUrl, questionType } = this.props;

        return (
            resourceUrl &&
            (questionType === STATIC_TEXT || !doesEndWith(resourceUrl, "pdf"))
        );
        //return resourceUrl && (questionType === STATIC_TEXT || !resourceUrl.endsWith('pdf'));
    }

    get isPrintableResource() {
        const { questionType, orgID } = this.props;
        const orgOK = orgID === 4 || orgID === 12;

        return questionType === STATIC_TEXT && orgOK;
    }

    get isPdfResourceViewable() {
        const { resourceUrl, questionType } = this.props;

        return (
            resourceUrl &&
            questionType !== STATIC_TEXT &&
            doesEndWith(resourceUrl, "pdf")
        );
        //return resourceUrl && (questionType !== STATIC_TEXT && resourceUrl.endsWith('pdf'));
        //return resourceUrl && resourceUrl.endsWith('pdf');
    }

    get isPdfResource() {
        const { resourceUrl } = this.props;

        return resourceUrl && doesEndWith(resourceUrl, "pdf");
        //return resourceUrl && resourceUrl.endsWith('pdf');
    }
}

ExamQuestionPage.propTypes = {
    hasUnansweredQuestions: PropTypes.bool.isRequired,
    onShowInstructions: PropTypes.func.isRequired,
    onQuestionChange: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    resourceUrl: PropTypes.string,
    hasCalculator: PropTypes.bool.isRequired,
    hasWorkings: PropTypes.bool.isRequired,
    paperPartName: PropTypes.string,
    nextPaperPartName: PropTypes.string,
    inReadingTime: PropTypes.bool.isRequired,
    practiceExam: PropTypes.bool.isRequired,
    clearReadingTime: PropTypes.func.isRequired,
};

// ExamQuestionPage (connected to store)
// -----------------------------------------------

const areThereUnansweredQuestions = (contentData, paperPartId) => {
    if (!paperPartId) {
        return hasUnansweredQuestions(contentData);
    }
    return hasPaperPartUnansweredQuestions(contentData)(paperPartId);
};

const mapStoreToProps = (store, ownProps) => {
    const examData = getExamData(store);
    const contentData = getContentData(examData);
    const questionId = parseInt(ownProps.questionId, 10);
    const paperPartId = getPaperPartId(contentData)(questionId);
    const nextPaperPartId =
        paperPartId && getNextPaperPartId(contentData)(paperPartId);
    const inReadingTime = getReadingTimeRemaining(examData, questionId) > 0;
    const bypassReadingTime =
        inReadingTime && canBypassReadingTime(getTimeData(examData));

    const sectionsData = getSectionsData(contentData);
    const paperPart =
        paperPartId && getSectionDataById(sectionsData)(paperPartId);
    const paperPartName = paperPart && getSectionName(paperPart);

    const nextPaperPart =
        nextPaperPartId && getSectionDataById(sectionsData)(nextPaperPartId);
    const nextPaperPartName = nextPaperPart && getSectionName(nextPaperPart);

    const questionsData = getQuestionsData(contentData);
    const questionData = getQuestionDataById(questionsData)(questionId);
    const questionType = getType(questionData);
    const hasDownload = questionType === ICT;

    const formRunGuid = getFormRunGuid(examData);
    const settingsData = getSettingsData(store);
    const clientData = getClientSettingsData(settingsData);
    const uploadFileTypesAllowed = getUploadFileTypesAllowed(clientData);

    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);
    const orgID = getOrgId(clientSessionData);

    const showExamInFullScreen = getShowExamInFullScreen(examData);
    const initialFullScreen = getInitialFullScreen(examData);
    const needToCloseFullScreen = showExamInFullScreen && !initialFullScreen;

    const returnObject = {
        hasUnansweredQuestions: areThereUnansweredQuestions(
            contentData,
            paperPartId
        ),
        hasCalculator: requiresCalculator(contentData, questionId),
        hasWorkings: requiresWorkings(contentData, questionId),
        resourceUrl: getResourceUrl(contentData),
        practiceExam: isPracticeExam(examData),
        inReadingTime,
        bypassReadingTime,
        questionId,

        // used by 'mergeProps()'
        nextPartQuestionId:
            nextPaperPartId &&
            getFirstQuestionIdInSection(contentData)(nextPaperPartId),
        paperPartId,
        paperPartName,
        nextPaperPartName,
        questionType,
        orgID,
        hasDownload,
        needToCloseFullScreen,
    };

    if (hasDownload) {
        returnObject.formRunGuid = formRunGuid;
        returnObject.uploadFileTypesAllowed = uploadFileTypesAllowed;
    }

    return returnObject;
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mergeProps = (storeProps, { dispatch }, ownProps) => {
    const { paperPartId, nextPartQuestionId } = storeProps;

    const clearReadingTime = paperPartId
        ? () => dispatch(setSectionReadingTimeRemaining(0, paperPartId))
        : () => dispatch(setReadingTimeRemaining(0));

    const onFinish = !nextPartQuestionId
        ? ownProps.onFinish
        : () => {
              dispatch(setPaperPartComplete(paperPartId));
              ownProps.onQuestionChange(nextPartQuestionId);
          };

    return {
        ...ownProps,
        ...storeProps,
        clearReadingTime,
        onFinish: onFinish,
        currentPaperPartId: paperPartId,
        paperPartId: undefined,
        nextPartQuestionId: undefined,
    };
};

const args = [mapStoreToProps, mapDispatchToProps, mergeProps];
ExamQuestionPage = connect(...args)(ExamQuestionPage);

ExamQuestionPage.propTypes = {
    onQuestionChange: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    onShowInstructions: PropTypes.func.isRequired,
    questionId: PropTypes.string.isRequired,
};

// EXPORT
// --------------------------------------------------------------------
export { ExamQuestionPage };
