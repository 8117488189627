
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {AnswerBox} from './answer-box'
import {BlanksAnswerForm} from '../answer_forms/blanks'
import {BlanksDragAndDropAnswerForm} from '../answer_forms/drag_and_drop/blanks'

// redux (selectors)
import {getLocalAnswer, isDragAndDrop} from 'redux/reducers/exam/content/questions/question/selectors'
import {getOptions, getAnswerText, getResumedAnswer, getVariableOptions} from 'redux/reducers/exam/content/questions/question/blanks/selectors'



class BlanksAnswerBox extends React.Component
{
	constructor(props)
	{
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	// extract information from questionData
	// --------------------------------------
	setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => {
			const options = getOptions(this.props.data);
			return (new Array(options.length)).fill(null);
		}

		this.extractResumedAnswer = () => {
			const resumedAnswer = getResumedAnswer(this.props.data);
			return resumedAnswer && resumedAnswer.split('|');
		}

		this.extractFormData = () => ({
			text: getAnswerText(this.props.data),
			options: [...getOptions(this.props.data)],
			variableOptions: getVariableOptions(this.props.data)
		});
	}

	// convert between answer values
	// --------------------------------------
	setUpTransformationFunctions()
	{
		this.getFormValue = (formAnswer) => {
			const {selectionIndex, option} = formAnswer;
			const formValue = [...getLocalAnswer(this.props.data)];
			formValue[selectionIndex] = option;
			return formValue;
		}

		this.toFormValue = (localAnswer) => localAnswer;
		this.toLocalAnswer = (formValue) => formValue;

		this.toServerAnswer = (localAnswer) => ({
			answerText: localAnswer.join('|'),
			options: []
		});
	}

	render()
	{
		const dnd = isDragAndDrop(this.props.data);
		const AnswerForm = dnd ? BlanksDragAndDropAnswerForm : BlanksAnswerForm;

		const answerBoxProps = {
			FormComponent: AnswerForm,
			questionData: this.props.data,
			toFormValue: this.toFormValue,
			getFormValue: this.getFormValue,
			toLocalAnswer: this.toLocalAnswer,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer,
		}

		return <AnswerBox {...answerBoxProps}/>;
	}
}

BlanksAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export {BlanksAnswerBox}