
// npm
import React from 'react'
import PropTypes from 'prop-types'


const getFlexAlignment = (start, end) =>
{
	if (start) { return 'flex-start'; }
	if (end) { return 'flex-end'; }
	return 'center';
}

const Align = function({top, right, bottom, left, children})
{
	const style = {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: getFlexAlignment(top, bottom),
		justifyContent: getFlexAlignment(left, right)
	}

	return <div style={style}>{children}</div>;
}

Align.propTypes = {
	children: PropTypes.node,
	top: PropTypes.bool,
	right: PropTypes.bool,
	bottom: PropTypes.bool,
	left: PropTypes.bool
}


export {Align}