import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import { MaterialText } from "components/presentation/material-text";
import { FormFieldTextLoading } from "./form-field-text-loading";

import { getStyle, insertVariablesIntoText } from "../form-helper";


const getMultipleText = (text) => {
    if (check.nonEmptyString(text)) {
        if (text.indexOf("<br />") !== -1) {
            return text
                .split("<br />")
                .map((item) => (check.nonEmptyString(item) ? item : "\u00A0"));
        }
    }
    return text;
};

class FormFieldText extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        const { onAction, action } = this.props;

        onAction(action);
    }

    render() {
        const { text, formValue, action, loading, instruction } = this.props;
        const isInstructionText=instruction==="true";
        const _text = insertVariablesIntoText(text, formValue);
        const multipleText = getMultipleText(text);
        const styleObj = getStyle(this.props);
        const divProps = { style: styleObj };

        if (check.nonEmptyString(action)) {
            divProps.onClick = this.handleOnClick;
        }

        if (check.nonEmptyArray(multipleText) && !isInstructionText) {
            return (
                <div {...divProps}>
                    {multipleText.map((text, index) => (
                        <div key={index}>{text}</div>
                    ))}
                </div>
            );
        }

        if (loading) {
            return (
                <div {...divProps}>
                    {_text}
                    <FormFieldTextLoading params={loading} />
                </div>
            );
        }
        if (isInstructionText) {
            // const htmlText=htmlToReact.parse(_text);
            // debugger;
            // return <div {...divProps}>{htmlText}</div>
            return <div {...divProps}><MaterialText>{_text}</MaterialText></div>
        }
        return <div {...divProps}>{_text}</div>;
    }
}

export { FormFieldText };
