import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUrlToken } from "redux/reducers/session/client/selectors";
import {
    getClientSessionData,
    getUserSessionData,
} from "redux/reducers/session/selectors";
import { getSessionData } from "redux/reducers/selectors";
import { isAdmin } from "redux/reducers/session/user/selectors";
import { getSettingsData } from "redux/reducers/selectors";
    import {getClientSettingsData} from 'redux/reducers/settings/selectors'
    import {getLegacySingleSignon} from 'redux/reducers/settings/client/selectors'    

import { AppPageModifier } from "components/pages/app-page-modifier";

class CheckAdminRedirect extends Component {
    state = {};

    componentDidMount() {
        const {
            admin,
            onNotAdmin,
            loginViaIframe,
            onAdminIframe,
            onAdminUrl,
            history,
            urlToken,
            iFrameRedirect,
            singleSignOn
        } = this.props;

        if (singleSignOn && admin) {
            if (loginViaIframe) {
                if (iFrameRedirect){
                    const url = `/admin?${urlToken}`
                    history.push(url);
                }
                else onAdminIframe();
            } else {
                onAdminUrl();
            }
        } else {
            onNotAdmin();
        }
    }

    render() {
        const appBarProps = {
            title: "Checking Redirection",
            loadingTitle: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="checking-admin-redirect"
                    appBarProps={appBarProps}
                    loading
                />
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const sessionData = getSessionData(store);
    const userSessionData = getUserSessionData(sessionData);
    const urlToken = getUrlToken(getClientSessionData(sessionData));
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);
    const singleSignOn = getLegacySingleSignon(clientSettingsData);
    const admin = isAdmin(userSessionData);

    return {
        admin,
        loginViaIframe: false,
        iFrameRedirect: true,
        urlToken,
        singleSignOn
    };
};

CheckAdminRedirect = withRouter(CheckAdminRedirect);
CheckAdminRedirect = connect(mapStoreToProps)(CheckAdminRedirect);

export { CheckAdminRedirect };
