
// npm
import check from 'check-types'

// custom
import {getProp} from 'custom/object-helper'



const extractMessage = (serverMessageObject) => 
{
	if (!check.object(serverMessageObject)) { return ""; }
	return getProp(serverMessageObject, 'MESSAGE.MSGINST') || "";
}



export {extractMessage}