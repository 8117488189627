
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MaterialText} from 'components/presentation/material-text'


let ContentBarTitle = ({children, classes}) => (
	<MaterialText className={classes.text} variant='h6'>
		{children}
	</MaterialText>
)

ContentBarTitle.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.string.isRequired
}


const styles = ({palette}) => ({
	text: {
		flexGrow: 1,
		color: palette.background.contrastText
	}
})

ContentBarTitle = withStyles(styles)(ContentBarTitle);


export {ContentBarTitle}