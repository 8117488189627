
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

// utils
import {routingBlocker} from 'utils/routing-blocker'


class AppRouteBlocker extends React.Component
{
	componentDidMount()
	{
		this.unblock = routingBlocker.block(this.props.history);
	}

	render()
	{
		return this.props.children;
	}

	componentWillUnmount()
	{
		this.unblock();
	}
}

AppRouteBlocker.propTypes = {
	history: PropTypes.object.isRequired
}

AppRouteBlocker = withRouter(AppRouteBlocker);


export {AppRouteBlocker}