
// npm
import React from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'

// material-ui
import Button from '@material-ui/core/Button'

// react
import {DisableAnsweringContext} from 'components/pages/exam/disabled-context'


class DraggableOption extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.onStop = (e, data) => {
			this.props.onDragStop(this.props.id, e, data);
		}

		this.onDrag = (e, data) => {
			this.props.onDrag(this.props.id, e, data);
		}
	}

	render()
	{
		const draggableProps = {
			onStop: this.onStop,
			onDrag: this.onDrag,
			bounds: this.props.bounds,
			disabled: this.isDisabled(),
			key: this.props.draggableKey
		}

		const buttonProps = {
			size: 'small',
			variant: 'outlined',
			disableRipple: true,
			color: this.props.color,
			disableFocusRipple: true,
			style: {textTransform: 'none'}
		}

		return (
			<Draggable {...draggableProps}>
				<div style={{display: 'inline-block'}}>
					<Button {...buttonProps}>
						{this.props.value}
					</Button>
				</div>
			</Draggable>
		)
	}

	isDisabled()
	{
		return this.context;
	}
}

DraggableOption.contextType = DisableAnsweringContext;

DraggableOption.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.string, 
		PropTypes.number
	]).isRequired,
	bounds: PropTypes.string,
	draggableKey: PropTypes.string,
	onDragStop: PropTypes.func,
	onDrag: PropTypes.func,
	color: PropTypes.string.isRequired,
	value: PropTypes.node.isRequired
}

DraggableOption.defaultProps = {
	color: 'secondary'
}

export {DraggableOption}