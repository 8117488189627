import React, { Component } from "react";
import {withRouter} from 'react-router-dom'

import moment from "moment";

import {EnhancedTable} from './activity-log-table'
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import withStyles from "@material-ui/core/styles/withStyles";

import { activityLogger } from "libs/activity_logger/activity-logger";
import { AppPageModifier } from "components/pages/app-page-modifier";

const styles = ({ spacing, breakpoints, palette }) => ({
    root: {
        margin: spacing.unit * 3,
        overflowX: 'auto',
      }
});

class ActivityLogPage extends Component {
    constructor(props) {
        super(props);

        const {
            match: { params },
          } = props;

        this.formRunGuid = params.logId;
        this.timer=null;

        this.state = { activities: []};
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    getData() {
        if (activityLogger.initialised) {
            activityLogger.get(this.formRunGuid).then((activities) => {
                this.setState({ activities });
            });
        } else {
            this.timer = setTimeout(() => {
                this.getData();
            }, 500);
        }
    }

    render() {
        const appBarProps = {
            title: `Activity Log - ${this.formRunGuid}`,
            welcomeMessage: true,
            logout: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier id="schedules" appBarProps={appBarProps} />
                {this.displayActivities()}
            </React.Fragment>
        );
    }

    displayActivities() {
        const { activities } = this.state;
        const { classes } = this.props;

        const rows = [
            { id: 'id', date: true, disablePadding: false, label: 'Date' },
            { id: 'type', activityType: true, disablePadding: true, label: 'Activity' },
            { id: 'value', activityValue: true, disablePadding: true, label: 'Value' }
          ];

          const icons = [
            {name:'Activity Logs', icon: <ArrowBackIcon/>, onSelect:()=>{this.movePage('logs')}},            
            {name:'Schedules', icon: <ArrowUpwardIcon/>, onSelect:()=>{this.movePage('schedules')}},
          ]

        const props = {
            title: 'Activity Logs',
            rowsPerPage: 10,
            checkBox: false,
            rows,
            data: activities,
            icons
        };

        return <div className={classes.root}><EnhancedTable {...props} /></div>;
    }

    movePage(page){
        const { history } = this.props;
        history.replace(`/${page}`);        
    }
}

ActivityLogPage = withStyles(styles)(ActivityLogPage);
ActivityLogPage = withRouter(ActivityLogPage);

export { ActivityLogPage };
