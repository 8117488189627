
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamFetcher} from './exam-fetcher'
import {AscxInitializer} from './ascx-initializer'
import {ExamImagePreloader} from './image-preloader'
import {ExamResourceLoader} from './resource-loader'
import {ExamAnswerInitializer} from './answer-initializer'

// machines
import {examInit} from 'machines/exam-init'


const {EVENTS, INITIALIZING_EXAM_STATES:STATES} = examInit;


const InitializingExamView = () =>
{
	return (
		<StateView>
			{{
				[STATES.FETCHING_EXAM]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <ExamFetcher {...props}/>}
					</StateControl>
				),
				[STATES.INITIALIZING_IMAGES]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <ExamImagePreloader {...props}/>}
					</StateControl>
				),
				[STATES.INITIALIZING_RESOURCES]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <ExamResourceLoader {...props}/>}
					</StateControl>
				),
				[STATES.INITIALIZING_ASCX_QUESTIONS]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <AscxInitializer {...props}/>}
					</StateControl>
				),
				[STATES.INITIALIZING_ANSWERS]: () => (
					<StateControl onFinish={EVENTS.SUCCESS}>
						{({onFinish}) => <ExamAnswerInitializer onFinish={onFinish}/>}
					</StateControl>
				)
			}}
		</StateView>
	)
}


export {InitializingExamView}