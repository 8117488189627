
// npm
import {Map as ImmutableMap} from 'immutable'

// redux (action-types)
import * as ACTION_TYPES from './action-types'


const CACHED_IMAGES_REDUCER = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.CACHE_IMAGE:
			return state.set(action.imageUrl, action.blobUrl);
		default:
			return state;
	}
}


export {CACHED_IMAGES_REDUCER}