
// npm
import {milliseconds, seconds} from 'time-convert'


const getAllowedTime = (timeData) => (
	milliseconds.from(seconds)(timeData.get('allowedTime'))
)

const getWarningTime = (timeData) => (
	milliseconds.from(seconds)(timeData.get('warningTime'))
)

const getExamTimeRemaining = (timeData) => (
	milliseconds.from(seconds)(timeData.get('examTimeRemaining'))
)

const getReadingTimeRemaining = (timeData) => (
	milliseconds.from(seconds)(timeData.get('readingTimeRemaining'))
)

const getTimeRemaining = (timeData) => (
	getExamTimeRemaining(timeData) + getReadingTimeRemaining(timeData)
)

const getExamTime = (timeData) => (
	milliseconds.from(seconds)(timeData.get('examTime'))
)

const getReadingTime = (timeData) => (
	milliseconds.from(seconds)(timeData.get('readingTime'))
)

const canBypassReadingTime = (timeData) => (
	timeData.get('canBypassReadingTime')
)

const getExamElapsedTime = (timeData) => (
	Math.round(timeData.get('elapsedTime')/1000)
)


export {
	getExamTime,
	getReadingTime,
	getAllowedTime,
	getWarningTime,
	getTimeRemaining,
	getExamTimeRemaining,
	getReadingTimeRemaining,
	canBypassReadingTime,
	getExamElapsedTime
}