
// npm
import React from 'react'
import PropTypes from 'prop-types'


class MessageChecker extends React.Component
{
	componentDidMount()
	{
		const loaded = this.props.messageIds.every(messageId => {
			return this.props.getMessage(messageId) !== undefined
		});

		this.props.onFinish(loaded);
	}

	render() { return null; }
}

MessageChecker.propTypes = {
	messageIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	getMessage: PropTypes.func.isRequired,
	onFinish: PropTypes.func.isRequired
}


export {MessageChecker}