import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import { getStyle, addFontStyles, getActionObj } from "../form-helper";

class FormButton extends Component {
    state = {};

    constructor(props) {
        super(props);

        const { fontObj } = props;
        const styleObj = addFontStyles(getStyle(props), fontObj);

        this.state = { styleObj };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const actionObj = getActionObj(null, this.props);
        //console.log("componentDidMount", actionObj);

        this.updateAction(actionObj);
    }

    componentDidUpdate(prevProps) {
        const actionObj = getActionObj(prevProps, this.props);
        //console.log("componentDidUpdate", actionObj);

        this.updateAction(actionObj);
    }

    updateAction(actionObj) {
        if (check.nonEmptyObject(actionObj)) {
            if (check.nonEmptyObject(actionObj.styles)) {
                const { styleObj } = this.state;
                const newStyleObj = { ...styleObj, ...actionObj.styles };

                this.setState({ styleObj: newStyleObj });
            } else if (check.nonEmptyString(actionObj.message)) {
            }
        }
    }

    handleClick() {
        const {
            enabled,
            onClick,
            onAction,
            onChange,
            name,
            action,
            changeAction,
        } = this.props;

        if (enabled) {
            if (check.nonEmptyString(action)) {
                if (check.nonEmptyString(changeAction)) {
                    const items=changeAction.split(",");

                    if (check.nonEmptyArray(items) && items.length>1 && items[1]==="true"){
                        onChange(items[0], name, action);
                    }
                    else{
                        onChange(name, changeAction, action);
                    }
                } else {
                    onAction(action);
                }
            } else {
                onClick(name);
            }
        }
    }

    render() {
        const { text, enabled } = this.props;
        const { styleObj } = this.state;
        const _styleObj = { ...styleObj };

        if (!enabled && styleObj.cursor) {
            try {
                delete _styleObj.cursor;
            } catch (e) {
                console.log(e);
            }
        }
        const props = { style: _styleObj, onClick: this.handleClick };

        return (
            <div>
                <button {...props}>{text}</button>
            </div>
        );
    }
}

export { FormButton };
