
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE & EVENT NAMES
// #########################################

const STATES = {
	INITIALIZING_SCHEDULES: 'initializing_schedules',
	DISPLAYING_SCHEDULES: 'displaying_schedules',
	INITIALIZATION_ERROR: 'schedule_initialization_error'
}

const INITIALIZING_STATES = {
	CLEARING_SCHEDULES: 'clearing_old_schedules',
	FETCHING_SCHEDULES: 'fetching_updated_schedules'
}

const EVENTS = {
	SUCCESS: 'success',
	REFRESH: 'refresh',
	ERROR: 'error'
}


// #########################################
// INITIALIZING STATES
// #########################################

const clearingSchedules = new XStateConfig();
clearingSchedules.addTransition(EVENTS.SUCCESS, INITIALIZING_STATES.FETCHING_SCHEDULES);

const fetchingSchedules = new XStateConfig();


// #########################################
// SCHEDULES STATES
// #########################################

const initializingSchedules = new XStateConfig();
initializingSchedules.initialState = INITIALIZING_STATES.CLEARING_SCHEDULES;
initializingSchedules.addState(INITIALIZING_STATES.CLEARING_SCHEDULES, clearingSchedules);
initializingSchedules.addState(INITIALIZING_STATES.FETCHING_SCHEDULES, fetchingSchedules);
initializingSchedules.addTransition(EVENTS.SUCCESS, STATES.DISPLAYING_SCHEDULES);
initializingSchedules.addTransition(EVENTS.ERROR, STATES.INITIALIZATION_ERROR);

const displayingSchedules = new XStateConfig();
displayingSchedules.addTransition(EVENTS.REFRESH, STATES.INITIALIZING_SCHEDULES);

const initializationError = new XStateConfig();


// #########################################
// SCHEDULES MACHINE
// #########################################

const _schedules = new XStateConfig();
_schedules.initialState = STATES.INITIALIZING_SCHEDULES;
_schedules.addState(STATES.INITIALIZING_SCHEDULES, initializingSchedules);
_schedules.addState(STATES.DISPLAYING_SCHEDULES, displayingSchedules);
_schedules.addState(STATES.INITIALIZATION_ERROR, initializationError);

const machine = Machine(_schedules.toObject());
machine.id = "Schedules Machine";


// #########################################
// EXPORT
// #########################################

const schedules = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES},
	INITIALIZING_STATES: {...INITIALIZING_STATES}
}

export {schedules}