
// @xams-utils
import {check} from '@xams-utils/check-types'

// @xams-entities
import {Version} from '@xams-entities/version'
import {VersionRange} from '@xams-entities/version-range'


const entifySupportedBrowsersVersions = (supportedBrowsers) =>
{
  let returnObj = {};

  for (const supportedBrowser in supportedBrowsers) {
    returnObj[supportedBrowser] = entifySupportedBrowserVersions(supportedBrowsers[supportedBrowser]);
  }

  return returnObj;
}

const entifySupportedBrowserVersions = (supportedBrowser) =>
{
  let returnObj = {};

  if (check.assigned(supportedBrowser.include)) {
    returnObj.include = getVersionsEntities(supportedBrowser.include);
  }
  if (check.assigned(supportedBrowser.exclude)) {
    returnObj.exclude = getVersionsEntities(supportedBrowser.exclude);
  }

  return returnObj;
}

const getVersionsEntities = (versions) =>
{
  versions = putAllVersionsIntoArray(versions);
  return versions.map(version => getRangeEntity(version));
}

const putAllVersionsIntoArray = (versions) =>
{
  if (!check.array(versions)) {  
    versions = [versions];
  }

  return versions.reduce((a, version) => {
    if (check.string(version) && version.includes(',')) {
      const items = version.split(',').map(function(item) {
        return item.trim();
      });
      return a.concat(items);
    }
    a.push(version);
    return a;
  }, []);
}

const getRangeEntity = (range) =>
{
  if (check.integer(range)) {
    return Version.create(range);
  }
    
  if (check.string(range)) {
    range = range.trim();

    if (range.startsWith('<')) {
      return getLessThanRange(range);
    }
    if (range.startsWith('>')) {
      return getGreaterThanRange(range);
    }
    if (range.includes('-')) {
      return getBetweenRange(range);      
    }

    return Version.create(range);
  }
  
  return null;
}

const getLessThanRange = (range) =>
{
  const max = Version.create(range.substring(1).trim());
  return VersionRange.create(null, max);
}

const getGreaterThanRange = (range) =>
{
  const min = Version.create(range.substring(1).trim());
  return VersionRange.create(min, null);
}

const getBetweenRange = (range) =>
{
  const ranges = range.split('-').map(function(item) {
    item = item.trim();
    return check.emptyString(item) ? null : item;
  });
  const min = check.assigned(ranges[0]) 
    ? Version.create(ranges[0]) 
    : null;
  const max = check.assigned(ranges[1]) 
    ? Version.create(ranges[1]) 
    : null;
  return VersionRange.create(min, max);
}


export {entifySupportedBrowsersVersions}