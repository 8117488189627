
// npm
import React from 'react'
import {Redirect} from 'react-router-dom'

// xams-components-functional
import {XamsCallback} from 'temp/xams.UI.Components/callback'
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {LoginFormMachine} from './form/machine'
import {InitializingSessionMessagesPage} from './initializing-session-messages-page'
import {MaintainingActivityLogPage} from './maintaining-activity-log'
import {CheckingSetPasswordToken} from './set_password/checking-set-password-token'
import {SetPasswordPage} from './set_password/page'
import {ResetPasswordPage} from '../reset_password/page'
import {LoginAuthenticator} from './form/authenticator'
import {CheckAdminRedirect} from './admin_redirect/check'
import {AdminRedirectUrl} from './admin_redirect/url'
import {AdminRedirectIframe} from './admin_redirect/iframe'
//import {UploadingOfflineFilesPage} from './uploading-offline-files'

// machines
import {login} from 'machines/login'


const {STATES, EVENTS} = login;


const LoginPageMachine = () =>
{	
	return (
		<StateMachine machine={login.machine}>
			<StateView>
				{{
					[STATES.CHECKING_SET_PASSWORD]: () => (
						<StateControl onNeedToSetPassword={EVENTS.NEED_TO_SET_PASSWORD} onNoNeedToSetPassword={EVENTS.NO_NEED_TO_SET_PASSWORD}>
							{(props) => <CheckingSetPasswordToken {...props}/>}
						</StateControl>
					),					
					[STATES.SET_PASSWORD]: () => (
						<StateControl onPasswordSet={EVENTS.PASSWORD_SET} onCancel={EVENTS.NO_NEED_TO_SET_PASSWORD}>
							{(props) => <ResetPasswordPage {...props}/>}
						</StateControl>
					),	
					[STATES.LOGIN_SET_PASSWORD]: () => (
						<StateControl onSuccess={EVENTS.AUTHENTICATED} onFail={EVENTS.NO_NEED_TO_SET_PASSWORD} onError={EVENTS.NO_NEED_TO_SET_PASSWORD}>
							{(props) => <LoginAuthenticator {...props}/>}
						</StateControl>
					),														
					[STATES.SHOWING_FORM]: () => (
						<StateControl onFinish={EVENTS.AUTHENTICATED}>
							{({onFinish}) => <LoginFormMachine onFinish={onFinish}/>}
						</StateControl>
					),
					[STATES.CHECKING_ADMIN_REDIRECT]: () => (
						<StateControl onNotAdmin={EVENTS.NOT_ADMIN} onAdminUrl={EVENTS.REDIRECTING_ADMIN_URL}  onAdminIframe={EVENTS.REDIRECTING_ADMIN_IFRAME}>
							{(props) => <CheckAdminRedirect {...props}/>}
						</StateControl>
					),										
					[STATES.ADMIN_REDIRECT_URL]: () => (
						<StateControl>
							{(props) => <AdminRedirectUrl {...props}/>}
						</StateControl>
					),
					[STATES.ADMIN_REDIRECT_IFRAME]: () => (
						<StateControl>
							{(props) => <AdminRedirectIframe {...props}/>}
						</StateControl>
					),																													
					[STATES.INITIALIZING_SESSION_MESSAGES]: () => (
						<StateControl onLoad={EVENTS.MESSAGES_INITIALIZED} onError={EVENTS.ERROR}>
							{(props) => <InitializingSessionMessagesPage {...props}/>}
						</StateControl>
					),
					[STATES.MAINTAINING_ACTIVITY_LOG]: () => (
						<StateControl onComplete={EVENTS.ACTIVITY_LOG_MAINTAINED} >
							{(props) => <MaintainingActivityLogPage {...props}/>}
						</StateControl>
					),					
					// [STATES.UPLOADING_OFFLINE_FILES]: () => (
					// 	<StateControl onComplete={EVENTS.OFFLINE_FILES_UPLOADED} >
					// 		{(props) => <UploadingOfflineFilesPage {...props}/>}
					// 	</StateControl>
					// ),						
					[STATES.REDIRECTING_TO_RUBRIC]: () => (
						<Redirect to='/rubric' push/>
					)
				}}
			</StateView>
		</StateMachine>
	)
}


export {LoginPageMachine}