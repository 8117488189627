
// npm
import React from 'react'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import {styles} from './styles'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getUserGuid} from 'redux/reducers/session/user/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'

// utils
import {generalApi} from 'libs/api/interface/api-general'


const FAILED = 'failed';

class Clock extends React.Component {

	constructor(props) 
	{
		super(props);

		this.state = {
			formattedDateString: null,
			serverTime : null
		}
	}

	componentDidMount()
	{
		this.getServerDateTime();
	}

	componentWillUnmount() {
    clearTimeout(this.timer);
  }

	handleTimer() {
		var delta = new Date().getTime() - this.timerStart;
		
		this.setState({
			serverTime: new Date(this.serverStartTime.getTime() + delta)
		});
  }

	getServerDateTime()
	{
		const onSuccess = (response) => {
			var parsedResponse = JSON.parse(response);
			this.serverStartTime = new Date(parsedResponse.time);
			this.setState({
				serverTime: this.serverStartTime,
				formattedDateString: parsedResponse.formattedDate
			});
			this.timerStart = new Date().getTime();
			this.timer = setInterval( () => {
				this.handleTimer();
			}, 1000);
		}

		const onError = (response) => {
			this.setState({serverTime: FAILED});
		}

		generalApi.getTime(this.props.userGuid).then(onSuccess, onError);
	}

	render() 
	{
		if (this.state.serverTime === null) {
			return <Typography className={this.props.classes.text}>{"Fetching server time..."}</Typography>;
		}

		if (this.state.serverTime === FAILED) {
			return <Typography color='error'>{"Failed to load server time"}</Typography>;
		}

		return (
			<Typography className={this.props.classes.text}>
				{this.state.formattedDateString || <Moment format="LL" date={this.state.serverTime}/>}
				{", "}
				<Moment format="LTS" date={this.state.serverTime}/>
			</Typography>
		)
	}
}

Clock.propTypes = {
	classes: PropTypes.object.isRequired,
	userGuid: PropTypes.string.isRequired
}

const mapStoreToProps = (store) => ({
	userGuid: getUserGuid(getUserSessionData(getSessionData(store)))
})

Clock = connect(mapStoreToProps)(Clock);
Clock = withStyles(styles)(Clock);

export {Clock}


