
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (selectors)
import {getLoginData} from 'redux/reducers/selectors'
import {getCredentialData} from 'redux/reducers/login/selectors'
import {getUsername, getPassword} from 'redux/reducers/login/credentials/selectors'

import {clearLoginError} from './actions'


// LoginValidator (not connected to store)
// -------------------------------------------------

class LoginValidator extends React.Component
{
	componentDidMount()
	{
		this.props.clearLoginError();
		if (this.isFormValid()) { this.props.onSuccess(); }
		else { this.props.onFail(); }
	}

	render() 
	{ 
		return null; 
	}

	isFormValid()
	{
		return this.props.u !== "" && this.props.p !== "";
	}
}

LoginValidator.propTypes = {
	u: PropTypes.string.isRequired,																									// username
	p: PropTypes.string.isRequired,																									// password
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	clearLoginError: PropTypes.func.isRequired
}


// LoginValidator (connected to store)
// -------------------------------------------------

const mapStoreToProps = (store) => ({
	u: getUsername(getCredentialData(getLoginData(store))),
	p: getPassword(getCredentialData(getLoginData(store)))
});

const mapDispatchToProps = (dispatch) => ({
	clearLoginError: () => dispatch(clearLoginError())
});

LoginValidator = connect(mapStoreToProps, mapDispatchToProps)(LoginValidator);


// EXPORT
// -------------------------------------------------
export {LoginValidator}