
// npm
import diffImmutable from 'immutable-diff'

// redux (action-types)
import {SET_EXAM_TIME_REMAINING, SET_READING_TIME_REMAINING} from 'redux/reducers/exam/time/action-types'

// custom
import {Console, LOG_TYPES} from 'custom/console'

// utils
import {errorLogger} from 'utils/error-logger'


const EXEMPT_FROM_SNAPSHOTS = [
	SET_READING_TIME_REMAINING,
	SET_EXAM_TIME_REMAINING
];


const logToConsole = (oldState, action, newState) =>
{
	const toLog = {
		oldState: oldState.toJS(),
		action: action.type,
		newState: newState.toJS()
	}

	//Console.log(toLog, LOG_TYPES.TEMP);
}

const snapshotStore = store => next => action =>
{
	const oldState = store.getState();
	const returnValue = next(action);
	const newState = store.getState();
	const stateChanges = diffImmutable(oldState, newState);
	
	if (!EXEMPT_FROM_SNAPSHOTS.includes(action.type)) {
		logToConsole(oldState, action, newState);
		
		errorLogger.setAppState({
			previousState: oldState,
			action: action.type,
			stateChanges,
			currentState: newState
		});
	}
	
	return returnValue;
}


export {snapshotStore}