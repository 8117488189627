const getId = (partData) => partData.get('id');
const getText = (partData) => partData.get('text');
const getMarks = (partData) => partData.get('marks');
const getLineCount = (partData) => partData.get('lineCount');
const usesRichText = (partData) => partData.get('usesRichText');
const getResumedAnswer = (partData) => partData.get('answerText');
const getWorkingAreaSize = (partData) => partData.get('workingAreaSize');
const getSuffix = (partData) => partData.get('suffix');

export {
	getId,
	getText,
	getMarks,
	getLineCount,
	usesRichText,
	getResumedAnswer,
	getWorkingAreaSize,
	getSuffix
}
