
// npm
import * as Immutable from 'immutable'

// redux
import {PART_REDUCER} from './part/reducer'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const createParts = (state, {type, data}) =>
{
	for (let i = 0; i < data.length; i++)
	{
		const partData = data[i];
		const action = {type, partData};
		state = state.push(PART_REDUCER(undefined, action));
	}

	return state;
}

const PARTS_REDUCER = (state=Immutable.List(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			return createParts(state, action);
		default:
			return state;
	}
}


export {PARTS_REDUCER}