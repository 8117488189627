// npm
import React from "react";

import { connect } from "react-redux";

import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getDemoMode } from "redux/reducers/settings/client/selectors";

// react
import { Popup } from "components/layout/popup";
import { CloseErrorButton } from "components/pages/exam/initialization/close-error-button";

// messages
import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS } from "constants/message-ids";

let CantRedirectPopup = ({ messages, demoMode }) => {
    const props = {
        title: 'Error',
        content: { text: "Unable to Redirect to Proctorio" },
        //buttons: [<CloseErrorButton/>],
        canClickBackdrop: false,
    };

    if (!demoMode) {
        props.buttons = [<CloseErrorButton />];
    }

    return <Popup {...props} />;
};

CantRedirectPopup = withMessages(CantRedirectPopup);

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    return {
        demoMode: getDemoMode(clientSettingsData),
    };
};

CantRedirectPopup = connect(mapStoreToProps)(CantRedirectPopup);

export { CantRedirectPopup };
