// npm
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {withMessages} from 'components/hocs/messages'


// react (layout)
import { ExamLayout } from "../../../../exam/_layout/layout";
import { Align } from "components/layout/align";

// react (concrete)
import { PageTitle } from "./title";
import { StartExamButton } from "../page/start-exam-button";
import { AudioCheckPanel } from "./audio-check-panel";
import { ReturnToSchedulesButton } from "../page/return-to-schedules-button";

// redux (selectors)
import { getGuid } from "redux/reducers/exam/selectors";
import { getExamData, getSchedulesData } from "redux/reducers/selectors";
import { getVersion } from "redux/reducers/schedules/schedule/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import {
    getProctoringMode,
    getPreviewMode,
} from "redux/reducers/settings/client/selectors";

import {
    getMappingsAndSections,
    checkSectionsForAudio,
} from "components/pages/exam/_layout/content/panel/content-helper";

import {getSettings} from "./settings"

// AudioCheckPage (not connected to store)
// -----------------------------------------------------------------

class AudioCheckPage extends Component {
    state = {};
    constructor(props) {
        super(props);

        this.settings=getSettings(props.messages);

        this.handleAudioChecked = this.handleAudioChecked.bind(this);
        this.audioChecked = this.audioChecked.bind(this);

        this.state = { audioChecked: false };
    }

    componentDidMount() {
        const { hasAudio } = this.props;

        if (!hasAudio) {
            this.audioChecked();
        } else {
            const actions = [
                "play",
                "pause",
                "stop",
                "seekbackward",
                "seekforward",                
                "previoustrack",
                "nexttrack",
                "skipad",
            ];
            actions.forEach((action) => {
                try {
                    navigator.mediaSession.setActionHandler(
                        action,
                        function () {}
                    );
                } catch (e) {}
            });
        }
    }

    handleAudioChecked() {
        const { audioChecked } = this.state;

        this.setState({ audioChecked: !audioChecked });
    }

    audioChecked() {
        const { onAudioChecked } = this.props;

        onAudioChecked();
    }

    render() {
        const { hasAudio } = this.props;
        if (!hasAudio) {
            return null;
        }

        const { audioChecked } = this.state;
        const { showReturnButton, previewMode } = this.props;

        const autoPlay = previewMode?false:this.settings.autoPlay;

        const audio = {
            url: this.settings.url,
            autoPlay,
            autoDelay: this.settings.autoDelay,
            canPause: this.settings.canPause,
            autoRepeat: this.settings.autoRepeat,
        };

        const message = this.settings.text;

        const audioProps = {
            checked: audioChecked,
            onChange: this.handleAudioChecked,
            audio,
            message,
            label: this.settings.check,
        };
        const buttonProps = {
            onClick: this.audioChecked,
            disabled: !audioChecked,
        };
        const titleProps = { title: this.settings.title };

        const examLayoutProps = {
            bar: {
                content: (
                    <React.Fragment>
                        <PageTitle {...titleProps} />
                        {showReturnButton ? <ReturnToSchedulesButton /> : null}
                        <StartExamButton {...buttonProps} />
                    </React.Fragment>
                ),
            },
            centre: {
                content: (
                    <Align top>
                        <AudioCheckPanel {...audioProps} />
                    </Align>
                ),
            },
        };

        return <ExamLayout {...examLayoutProps} />;
    }
}

AudioCheckPage.propTypes = {
    onExamStart: PropTypes.func.isRequired,
    showReturnButton: PropTypes.bool.isRequired,
};

// AudioCheckPage (connected to store)
// -----------------------------------------------------------------

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const examGuid = getGuid(examData);

    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    const schedulesData = getSchedulesData(store);
    const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
    const proctoringMode = getProctoringMode(clientSettingsData);
    const showReturnButton =
        proctoringMode == 1 ? false : !getVersion(scheduleData);

    const { sections, mappings } = getMappingsAndSections(store);
    const hasAudio = checkSectionsForAudio(sections, mappings);
    // const hasAudio = false;

    const previewMode = getPreviewMode(clientSettingsData);

    return {
        previewMode,
        showReturnButton,
        hasAudio,
    };
};

AudioCheckPage = connect(mapStoreToProps)(AudioCheckPage);

AudioCheckPage = withMessages(AudioCheckPage);

// AudioCheckPage (EXPORT)
// -----------------------------------------------------------------
export { AudioCheckPage };
