
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {MessageLoader} from 'components/functional/message_loader/loader'
import {AppPageModifier} from 'components/pages/app-page-modifier'

// machines
import {login} from 'machines/login'


const InitializingSessionMessagesPage = (props) => 
{
	const appBarProps = {
		title: "Initializing session messages",
		loadingTitle: true
	}

	return (
		<React.Fragment>
			<AppPageModifier id="initializing-session-msgs" appBarProps={appBarProps} loading/>
			<MessageLoader {...props} session/>
		</React.Fragment>
	);
}

InitializingSessionMessagesPage.propTypes = {
	onLoad: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
}


export {InitializingSessionMessagesPage}