
// npm
import {Map as ImmutableMap} from 'immutable'

// redux (action-types)
import * as ACTION_TYPES from './action-types'


const GENERAL_MESSAGES_REDUCER = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.SET_MESSAGE:
			return state.set(action.id, action.value || "MESSAGE_UNDEFINED");
		case ACTION_TYPES.CLEAR_MESSAGES:
			return ImmutableMap();
		default:
			return state;
	}
}


export {GENERAL_MESSAGES_REDUCER}