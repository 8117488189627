import {api} from '../api'
import {OAUTH, ENDPOINTS, RETRY_COUNT} from '../constants'
import {toFormData} from 'custom/object-helper'


const requestAuthentication = (payload) =>
{
	payload = toOauthPayload(payload, OAUTH.GRANT_TYPES.PASSWORD);
	
	const checkRequestError = (request) => {
		return request.status && request.status!==0;
	}

	return api.post(ENDPOINTS.AUTHENTICATION, payload, RETRY_COUNT, null, null, checkRequestError);
	//return api.post(ENDPOINTS.AUTHENTICATION, payload);
}

const checkLoginToken = (token) =>
{
	api.Token = token;
	return api.get(ENDPOINTS.LOGIN_TOKEN, {});
}

const refreshApiToken = () =>
{
	const payload = toOauthPayload({}, OAUTH.GRANT_TYPES.REFRESH);
	return api.post(ENDPOINTS.AUTHENTICATION, payload);
}

const toOauthPayload = (payload, grant_type) =>
{
	payload.client_id = OAUTH.ID;
	payload.grant_type = grant_type;
	payload.client_secret = OAUTH.SECRET;
	payload.refresh_token = api.RefreshToken;
	return toFormData(payload);
}

const logoutUser = (userGuid) =>
{
	return api.get(ENDPOINTS.LOGOUT, {userGUID: userGuid});
}

const abandonUserSession = (userGuid, reason) =>
{
	return api.get(ENDPOINTS.ABANDON_SESSION, {userGUID: userGuid, reason});
}

const checkSetPasswordToken = (token) =>
{
	const endpoint = `${ENDPOINTS.SET_PASSWORD_TOKEN.CHECK}/${token}`;

	return api.get(endpoint, {});
}

const resetPassword = (payload) =>
{
	return api.post(ENDPOINTS.SET_PASSWORD_TOKEN.RESET, payload);
}

const adminLogin = (userID, adminUrl) => {
	const endPoint = `${adminUrl}/${ENDPOINTS.ADMIN_LOGIN}`;

	return api.get(endPoint, {userID});
}



const authenticationApi = {
	requestAuthentication,
	refreshApiToken,
	abandonUserSession,
	logoutUser,
	checkLoginToken,
	checkSetPasswordToken,
	resetPassword,
	adminLogin
}

export {authenticationApi}