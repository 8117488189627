
// utils
import {combineImmutableReducers} from 'utils/combine-immutable-reducers'

// redux (reducers)
import {CACHED_PDFS_REDUCER} from './pdfs/reducer'
import {CACHED_IMAGES_REDUCER} from './images/reducer'


const CACHE_REDUCER = combineImmutableReducers(undefined, {
	images: CACHED_IMAGES_REDUCER,
	pdfs: CACHED_PDFS_REDUCER
});


export {CACHE_REDUCER}