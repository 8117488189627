
const COLOR_NAMES = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	ERROR: 'error',
	BACKGROUND: 'background'
}

const DEFAULT_THEME = {
	[COLOR_NAMES.PRIMARY]: '#B2B9EA',
	[COLOR_NAMES.SECONDARY]: '#56678E',
	[COLOR_NAMES.BACKGROUND]: '#122054',
	[COLOR_NAMES.ERROR]: '#D6CBB3'
}


export {COLOR_NAMES, DEFAULT_THEME}