
// npm
import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'immutable-prop-types'


class ExamQuestionSaver extends React.Component
{
	render()
	{
		return null;
	}

	componentDidUpdate(previousProps)
	{
		if (previousProps.currentQuestionId !== this.props.currentQuestionId) {
			// console.log('Save previous question',previousProps.currentQuestionId, this.props.currentQuestionId);
			this.props.savePreviousQuestion();
		}

		if (previousProps.paperPartId !== this.props.paperPartId) {
			// console.log('Changed section - save current question',previousProps.paperPartId, this.props.paperPartId);
			this.props.saveCurrentQuestion();
		}
	}

	componentWillUnmount()
	{
		this.props.saveCurrentQuestion();
	}
}

ExamQuestionSaver.propTypes = {
	savePreviousQuestion: PropTypes.func.isRequired,
	saveCurrentQuestion: PropTypes.func.isRequired,
	currentQuestionId: PropTypes.number.isRequired
}


export {ExamQuestionSaver}