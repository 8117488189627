
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-utils
import {check} from '@xams-utils/check-types'

// internal
import {StateView} from './state-view'
import {StateControl} from './state-control'
import {MachineContext} from './machine-context'


const RESET_MACHINE = '**reset-machine**';


class StateMachine extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {current: this.InitialState};
		this.initializeBoundMethods();
	}

	*getActiveStateNames(state)
	{
		if (check.object(state)) {
			for (let parentStateName in state) {
				yield parentStateName;
				yield* this.getActiveStateNames(state[parentStateName]);
			}
		}

		yield state;
	}

	initializeBoundMethods()
	{
		this.transition = (event) => {
			if (!check.string(event)) { throw "Invalid state machine event type"; }
			else if (event === RESET_MACHINE) { this.resetState(); }
			else { this.applyStateTransition(event); }
		}
	}

	applyStateTransition(event)
	{
		const {machine} = this.props;
		const {current} = this.state;
		const nextState = machine.transition(current, event).value;

		//console.log({currentState: current, event, nextState});

		this.setState({current: nextState}, this.onChange);
	}

	resetState()
	{
		this.setState({current: this.InitialState});
	}

	render()
	{
		return (
			<MachineContext.Provider value={this.MachineContextValue}>
				{this.props.children}
			</MachineContext.Provider>
		)
	}

	componentDidUpdate(previousProps, previousState)
	{
		if (!this.props.onChange) { return; }

		if (previousState.current !== this.state.current) {
			this.props.onChange(this.state.current);
		}
	}

	get InitialState()
	{
		// In a hierarchical machine, machine.initial returns only the initial
		// state of the topmost machine. Emitting a fake event will force the
		// machine to build up a complete 'initial state' by attempting to 
		// apply the transition.
		const {machine} = this.props;
		return machine.transition(machine.initial, "fake_event").value;
	}

	get MachineContextValue()
	{
		return {
			transition: this.transition,
			state: this.state.current
		}
	}
}

StateMachine.propTypes = {
	onChange: PropTypes.func,
	children: PropTypes.node.isRequired,
	machine: PropTypes.shape({
		transition: PropTypes.func.isRequired,
		initial: PropTypes.string.isRequired,
		id: PropTypes.string
	})
}


export {StateMachine, StateView, StateControl, RESET_MACHINE}