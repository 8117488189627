const getRowsData = (tableData) => tableData.get('rows');
const getResumedAnswer = (tableData) => tableData.get('resumedAnswer');
const getHasAnswerBox = (tableData) => tableData.get('showAnswerInputField');
const getAnswerBoxSize = (tableData) => tableData.get('noOfAnswerLines');
const getAnswerBoxLabel = (tableData) => tableData.get('answerLabel');

const isAnswered = (localAnswer) => {
	if (!localAnswer) { return false; }
	return localAnswer.some(answer => {
		if (answer.answerBox){
			return !!answer.answerBox;
		}
		else if (answer.values){
			return answer.values.some(value => !!value);
		}
		else{
			return false;
		}
	});
}

export {getRowsData, getResumedAnswer, isAnswered, getHasAnswerBox, getAnswerBoxSize, getAnswerBoxLabel}