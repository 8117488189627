import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Align } from "components/layout/align";
import { FullScreenError } from "./error";

const Transition = (props) =>{
    return <Slide direction="up" {...props} />;
  }

class FullScreenDialog extends Component {
    state = {};

    render() {
        const { open, title, message, buttonClick, buttonText, classes } =
            this.props;

        const diagProps = {
            fullScreen: true,
            open,
            disableBackdropClick: true,
            TransitionComponent: Transition
            // style: {width: "50vw", height: "50vh"}
        };

        const contentProps = {
            style: { height: "50vh" },
        };

        const buttonProps = {
            color: "primary",
            variant: "contained",
            onClick: buttonClick,
        };

        const errorProps = { title, message, buttonClick, buttonText };

        return (
            <Dialog {...diagProps}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={buttonClick}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.flex}
                        >
                            {title}
                        </Typography>
                        <Button color="inherit" onClick={buttonClick}>
                            {buttonText}
                        </Button>
                    </Toolbar>
                </AppBar>
                <Align>
                    <FullScreenError {...errorProps} />
                </Align>
            </Dialog>
        );
    }
}

const styles = {
    appBar: {
        position: "relative",
    },
    flex: {
        flex: 1,
    },
};

FullScreenDialog = withStyles(styles)(FullScreenDialog);

export { FullScreenDialog };
