
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'


let DrawerIconButton = ({Icon, open, onClick, classes}) =>
{
	return (
		<IconButton onClick={onClick}>
			<Icon className={open ? classes.open : classes.closed}/>
		</IconButton>
	);
}

DrawerIconButton.propTypes = {
	Icon: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.object
	]).isRequired,
	open: PropTypes.bool,
	onClick: PropTypes.func.isRequired
}


const styles = ({palette}) => ({
	closed: {color: palette.background.contrastText},
	open: {color: palette.primary.main}
});

DrawerIconButton = withStyles(styles)(DrawerIconButton);


export {DrawerIconButton}