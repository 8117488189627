// npm
import React from "react";
import PropTypes from "prop-types";

// material-ui
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { Bookmarker } from "./bookmarker";
import { SectionText } from "./section-text";
import { QuestionText } from "./question-text";
import { QuestionIntro } from "./question-intro";
import { EvidenceText } from "./evidence-text";

// QuestionPanel (not connected to styles)
// ---------------------------------------------------

let QuestionPanel = ({ questionId, classes, noQuestionPanel }) => {
  const contents = (
      <div className={classes.wrapper}>
          <span className={classes.leftSection}>
              <SectionText questionId={questionId} />
              <QuestionIntro questionId={questionId} />
              <QuestionText questionId={questionId} />
              <EvidenceText questionId={questionId} />
          </span>
          <span className={classes.rightSection}>
              <Bookmarker questionId={questionId} />
          </span>
      </div>
  );

  return noQuestionPanel ? (
      contents
  ) : (
      <Paper className={classes.panel}>{contents}</Paper>
  );
};

QuestionPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    questionId: PropTypes.number.isRequired,
};

// QuestionPanel (connected to styles)
// ---------------------------------------------------

const styles = ({spacing, palette}) => {
  const _spacing = spacing.unit * 2;

  return {
      panel: {
          margin: _spacing,
          padding: _spacing,
          backgroundColor: palette.background.light,
      },
      wrapper: {
          display: "flex",
          flexDirection: "row",
      },
      leftSection: {
          flexGrow: 1,
      },
      rightSection: {
          flexShrink: 0,
      },
  };
};

QuestionPanel = withStyles(styles)(QuestionPanel);

// Export
// ---------------------------------------------------
export { QuestionPanel };
