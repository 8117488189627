
// npm
import React from 'react'
import {withRouter} from 'react-router-dom'

// material-ui
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

// react
import {ActionButton} from 'components/layout/action_button/button'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// CloseButton (not connected to router)
// --------------------------------------------------
let CloseButton = ({history, messages, proctorio}) =>
{
	const onClick = proctorio? () => history.push('/proctorio/finish') : () => history.push('/schedules');

	return (
		<ActionButton Icon={ExitToAppIcon} alignment={'right'} onClick={onClick}>
			{messages[MESSAGE_IDS.GENERAL.CLOSE]}
		</ActionButton>
	)
}


// CloseButton (connected to router)
// --------------------------------------------------
CloseButton = withRouter(CloseButton);
CloseButton = withMessages(CloseButton);


// Export
// --------------------------------------------------
export {CloseButton}