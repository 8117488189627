
// npm
import * as Immutable from 'immutable'

// redux
import * as ACTION_TYPES from './action-types'
import {SCHEDULE_REDUCER} from './schedule/reducer'
import * as SCHEDULE_ACTION_TYPES from './schedule/action-types'


const SCHEDULES_REDUCER = (state=Immutable.List(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.CLEAR_SCHEDULES:
			return Immutable.List();
		case ACTION_TYPES.ADD_SCHEDULE:
			return state.push(SCHEDULE_REDUCER(undefined, action));
		case ACTION_TYPES.CLEAR_SCHEDULES_EXCEPT:
			return state.filter(x => x.get('guid') === action.guid);
		case SCHEDULE_ACTION_TYPES.SET_COMMENCED:
		case SCHEDULE_ACTION_TYPES.SET_COMPLETED:
		case SCHEDULE_ACTION_TYPES.SET_CANT_START_MESSAGE:
			const scheduleIndex = state.findIndex(x => x.get('guid') === action.guid);
			if (scheduleIndex === -1) { throw "error in schedules reducer"; }
			const scheduleState = state.get(scheduleIndex);
			return state.set(scheduleIndex, SCHEDULE_REDUCER(scheduleState, action));
		default:
			return state;
	}
}


export {SCHEDULES_REDUCER}