// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// material-ui
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { getAnswerBox } from "./get-answer-box";

// redux (selectors)
import { getExamData } from "redux/reducers/selectors";
import { getContentData } from "redux/reducers/exam/selectors";
import { getQuestionsData } from "redux/reducers/exam/content/selectors";
import { getQuestionDataById } from "redux/reducers/exam/content/questions/selectors";

import * as QUESTION_TYPES from "constants/question-types";

const styles = (theme) => {
    const spacing = theme.spacing.unit * 2;
    return {
        panel: {
            margin: spacing,
            padding: spacing,
            backgroundColor: theme.palette.background.light,
        },
    };
};

const hasAnswerBox = (questionData) => {
    const questionType = questionData.get("type");

    return questionType !== QUESTION_TYPES.STATIC_TEXT;
};

// AnswerPanel (not connected to store/styles)
// ------------------------------------------------------------------------

let AnswerPanel = ({ questionData, classes, noQuestionPanel }) => {
    return hasAnswerBox(questionData) && !noQuestionPanel ? (
        <Paper className={classes.panel}>{getAnswerBox(questionData)}</Paper>
    ) : (
        getAnswerBox(questionData, noQuestionPanel)
    );
};

AnswerPanel.propTypes = {
    questionData: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

// AnswerPanel (not connected to store/styles)
// ------------------------------------------------------------------------

const mapStoreToProps = (store, { questionId }) => {
    const contentData = getContentData(getExamData(store));
    const questionsData = getQuestionsData(contentData);
    const questionData = getQuestionDataById(questionsData)(questionId);
    return { questionData, questionId: undefined };
};

AnswerPanel = connect(mapStoreToProps)(AnswerPanel);
AnswerPanel = withStyles(styles)(AnswerPanel);

// Export
// ------------------------------------------------------------------------
export { AnswerPanel };
