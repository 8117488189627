
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux
import {getExamData} from 'redux/reducers/selectors'
import {getExamPassword} from 'redux/reducers/exam/selectors'


// ExamPasswordValidator (not connected to store)
// -------------------------------------------------
class ExamPasswordValidator extends React.Component
{
	componentDidMount()
	{
		if (this.isPasswordValid()) { this.props.onSuccess(); }
		else { this.props.onFail(); }
	}

	isPasswordValid()
	{
		return this.props.p !== "";
	}

	render()
	{
		return null;
	}
}

ExamPasswordValidator.propTypes = {
	p: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired
}


// ExamPasswordValidator (connected to store)
// -------------------------------------------------

const mapStoreToProps = (store) => ({
	p: getExamPassword(getExamData(store))
});

ExamPasswordValidator = connect(mapStoreToProps)(ExamPasswordValidator);


// EXPORT
// -------------------------------------------------
export {ExamPasswordValidator}