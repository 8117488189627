import isEqual from "lodash/isEqual";
import { dbStorageApi, KEYS } from "libs/browser_storage/db-storage-api";
import {encryptValue} from './activity-logger-helper'

const showTime=()=>{
    const d = new Date();
    const s =`${d.getMinutes()}:${d.getSeconds()}`;
    return s;
}

class ActivityLoggerQuestion {
    constructor(_activityTypes) {
        const activityTypes = _activityTypes;
        const activities = [];
        let mode = 0;

        activityTypes.forEach((activityType) => {
            activities[activityType] = new QuestionActivity(activityType);
        });

        this.canSave = (activityType) => {
            return activityTypes.indexOf(activityType) !== -1;
        };

        this.save = (activityType, value) => {
            activities[activityType].save(value);
        };

        this.setFormRunGuid = (formRunGuid) => {
            activityTypes.forEach((activityType) => {
                activities[activityType].setFormRunGuid(formRunGuid);
            });
        };

        this.reset = () => {
            activityTypes.forEach((activityType) => {
                activities[activityType].reset();
            });
        };

        this.finish = () => {
            activityTypes.forEach((activityType) => {
                activities[activityType].finish();
            });
        };

        this.setMode = (_mode) => {
            mode = _mode;
            activityTypes.forEach((activityType) => {
                activities[activityType].setMode(_mode);
            });            
        }
    }
}

class QuestionActivity {
    constructor(_activityType) {
        const activityType = _activityType;
        const timerDelay = 1000 * 30;
        let saveOnCounter = activityType===2?1:2; // When we start saving values to DB, 1=when move to question, 2=when user types something in
        let value = null;
        let timer = null;
        let lastDBValue = null;
        let formRunGuid = null;
        let saveCounter = 0;
        let mode = 0;

        const saveToDB = (resetBeforeSave=false) => {
            const _value = value;

            if (resetBeforeSave){
                _reset();
            }

            return dbStorageApi.add(KEYS.STUDENT_ACTIVITY, {
                formRunGuid,
                type: activityType,
                value: encryptValue(_value),
            });
        };

        const setTimer = () => {
            timer = setTimeout(() => {
                try {
                    timedOut();
                } catch (error) {}
            }, timerDelay);
        };

        const clearTimer = () => {
            clearTimeout(timer);
            timer = null;
        };

        const timedOut = () => {
            if (formRunGuid) {
                if (
                    saveCounter == saveOnCounter &&
                    !isEqual(lastDBValue, value)
                ) {
                    lastDBValue = value;
                    saveToDB().then(() => {
                        setTimer();
                    });
                } else {
                    lastDBValue = value;
                    setTimer();
                }
            }
        };

        const _reset = () => {
            value = null;
            lastDBValue = null;
            timer = null;
            saveCounter = 0;
        };

        this.setFormRunGuid = (_formRunGuid) => {
            formRunGuid = _formRunGuid;
        };

        this.save = (_value) => {
            //clearTimer();
            const doSetTimer = mode ===1 && saveCounter===0;
            if (saveCounter < saveOnCounter) saveCounter++;
            value = _value;
            if (doSetTimer) setTimer();
        };

        this.finish = () => {
            clearTimer();
            if (formRunGuid) {
                if (
                    saveCounter === saveOnCounter &&
                    !isEqual(lastDBValue, value)
                ) {
                    saveToDB(true);
                } else{
                    _reset();
                }
            }
        };

        this.reset = () => {
            formRunGuid = null;
            clearTimer();
            _reset();
        };

        this.setMode = (_mode) => {
            mode = _mode;
            saveOnCounter = activityType===2?1:_mode;
        }

        this.save = this.save.bind(this);
        this.finish = this.finish.bind(this);
        this.setFormRunGuid = this.setFormRunGuid.bind(this);
        this.reset = this.reset.bind(this);
        this.setMode = this.setMode.bind(this);
    }
}

export { ActivityLoggerQuestion };
