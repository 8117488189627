
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// utils
import {findUserGuidsByQuery} from '../query-logs'
import {localStorageApi, KEYS} from 'libs/browser_storage/local-storage-api'


class UserSelector extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {value: null}

		this.update = ({target:{value}}) => {
			this.setState({value});
			this.props.onChange(value);
		}
	}

	render()
	{
		return (
			<Select value={this.state.value} onChange={this.update}>
				{this.Options}
			</Select>
		);
	}

	get Options()
	{
		const options = [this.NullOption];
		const users = localStorageApi.retrieveDataFrom(KEYS.USERS);

		const getUserName = (guid) => {
			const user = users[guid];
			return user ? user.name : guid;
		}

		const userGuids = findUserGuidsByQuery();
		userGuids.forEach((userGuid, index) => {
			const key = this.createKey(userGuid);
			const userName = getUserName(userGuid);
			options.push(<MenuItem key={key} value={userGuid}>{userName}</MenuItem>);
		});

		return options;
	}

	get NullOption()
	{
		const key = this.createKey(null);
		return <MenuItem key={key} value={null}>{"Not specified"}</MenuItem>;
	}

	createKey(userGuid)
	{
		return `user-selector-${userGuid}`;
	}
}

UserSelector.propTypes = {
	onChange: PropTypes.func.isRequired
}


export {UserSelector}