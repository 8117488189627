import React, { Component } from 'react';
import { connect } from "react-redux";

import { getGuid } from "redux/reducers/exam/selectors";
import { getExamData, getSchedulesData } from "redux/reducers/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import { getProctorProvider } from 'redux/reducers/schedules/schedule/selectors';

import { PROCTORING_TYPES } from 'constants/proctoring';

class CloseIntegrityAdvocate extends Component {
    state = {}

    componentDidMount() {
        const { onComplete } = this.props;

        if (this.isIntegrityAdvocate()) {
            try{
                console.log('Closing Integrity Advocate');

                window.IntegrityAdvocate.endSession();

                let elem = window.document.getElementById('integrityadvocate_container');
                if (elem !== null && elem !== undefined) {
                    elem.remove();
                }
                elem = window.document.getElementById('integrityadvocate_tab');
                if (elem !== null && elem !== undefined) {
                    elem.remove();
                }
            }
            catch(e){
                console.log("Unable to close IA", e);    
            }
        }

        onComplete();
    }

    isIntegrityAdvocate() {
        const { proctoringProvider } = this.props;

        return proctoringProvider === PROCTORING_TYPES.INTEGRITY_ADVOCATE;
    }

    render() {
        return null;
    }
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const examGuid = getGuid(examData);

    const schedulesData = getSchedulesData(store);
    const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
    const proctoringProvider = getProctorProvider(scheduleData)


    return { proctoringProvider }
}

CloseIntegrityAdvocate = connect(mapStoreToProps)(CloseIntegrityAdvocate);

export { CloseIntegrityAdvocate };