
const getEntered = (proctorioData) => {
	return proctorioData.get('entered')
}

const getParent = (proctorioData) => {
	return proctorioData.get('parent')
}

const getClientName = (proctorioData) => {
	return proctorioData.get('clientName')
}

const getUserInfo1 = (proctorioData) => {
	return proctorioData.get('userInfo1')
}

const getUserInfo2 = (proctorioData) => {
	return proctorioData.get('userInfo2')
}

const getExamGuid = (proctorioData) => {
	return proctorioData.get('examGuid')
}

const getCompletedExamGuid = (proctorioData) => {
	return proctorioData.get('completedExamGuid')
}

const getDebugMode = (proctorioData) => {
	return proctorioData.get('debug')
}

const getCallingMode = (proctorioData) => {
	return proctorioData.get('calling')
}

const isCallingModeSingle = (proctorioData) => {
	const callingMode = proctorioData.get('calling');
	return callingMode === 'single';
}

const isCallingModeMultiple = (proctorioData) => {
	const callingMode = proctorioData.get('calling');
	return callingMode === 'multi';
}

const getError = (proctorioData) => {
	return proctorioData.get('error')
}

export {
	getEntered,
	getParent,
	getClientName,
	getUserInfo1,
	getUserInfo2,
	getExamGuid,
	getCompletedExamGuid,
	getDebugMode,
	getCallingMode,
	isCallingModeSingle,
	isCallingModeMultiple,
	getError
}