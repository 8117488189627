// npm
import React, { Component } from "react";
import PropTypes from "prop-types";
import { check } from "@xams-utils/check-types";

// material-ui
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MaterialText } from "components/presentation/material-text";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";

// react
import { DisableAnsweringContext } from "components/pages/exam/disabled-context";
import { ConfirmationDialog } from "./confirmation-dialog";
import { MultiLineTextEditor } from "components/presentation/text_editors/multi_line";

import {MESSAGE_IDS} from 'constants/message-ids'
import {withMessages} from 'components/hocs/messages'
import { keyListener, KEYS } from "utils/key-listener";
import { WordCount } from "components/presentation/text_editors/word-count";


const disableArrowKeyListeners = () => {
    keyListener.disable(KEYS.LEFT_ARROW);
    keyListener.disable(KEYS.RIGHT_ARROW);
};

const enableArrowKeyListeners = () => {
    keyListener.enable(KEYS.LEFT_ARROW);
    keyListener.enable(KEYS.RIGHT_ARROW);
};


const getOptions = (
    currentValue,
    { options },
    disabled,
    classes,
    displayDescriptionInOption
) => {
    const radioClasses = {
        root: classes.radioRoot,
        checked: classes.radioChecked,
    };

    const getLabelNode = (label, description, checked) => (
        <div>
            <MaterialText className={classes.radioText}>{label}</MaterialText>
            {displayDescriptionInOption && checked && (
                <MaterialText className={classes.radioText}>
                    {description}
                </MaterialText>
            )}
        </div>
    );

    const getRadioButton = (checked) => (
        <Radio classes={radioClasses} disabled={disabled} checked={checked} />
    );

    return options.map(({ value, label, description }, index) => {
        const key = `${label}${index}`;
        const labelNode = getLabelNode(
            label,
            description,
            value === currentValue
        );
        const control = getRadioButton(value === currentValue);

        return (
            <FormControlLabel
                key={key}
                value={value}
                label={labelNode}
                control={control}
                classes={{ root: classes.radioItem }}
            />
        );
    });
};

const displayOptions = (props, disabled) => {
    const onChange = disabled ? undefined : this.handleRadioOnChange;
    const { value, formData } = props;

    return (
        <RadioGroup onChange={onChange} value={value}>
            {getOptions(value, formData, disabled, props.classes)}
        </RadioGroup>
    );
};

// let McqAnswerForm = (props) => (
// 	<FormControl component="fieldset">
// 		<DisableAnsweringContext.Consumer>
// 			{value => getOptionGroup(props, value)}
// 		</DisableAnsweringContext.Consumer>
// 	</FormControl>
// )

class McqTextAnswerForm extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);

        this.displayDescriptionInOption = false;
        this.confirmRadio = false;
        this.confirmationText =props.messages[MESSAGE_IDS.EXAM.CONFIRM_MULTI_TEXT_OPTION_CHANGE];

        this.state = { confirmation: false };
    }

    displayOptions() {
        const disabled = this.isDisabled();
        const onChange = disabled ? undefined : this.handleRadioChange;
        const { value, formData } = this.props;
        const radioValue = value ? value.radio : undefined;

        return (
            <RadioGroup onChange={onChange} value={value}>
                {getOptions(
                    radioValue,
                    formData,
                    disabled,
                    this.props.classes,
                    this.displayDescriptionInOption
                )}
            </RadioGroup>
        );
    }

    handleRadioChange(radio) {
        const { onChange, value, messages } = this.props;
        const text = value ? value.text : null;

        if (check.nonEmptyString(text) && check.nonEmptyString(this.confirmationText)) {
            this.confirmRadio = radio.target.value;
            this.setState({ confirmation: true });
        } else {
            onChange({ radio });
        }
    }

    handleTextChange(text) {
        const { onChange } = this.props;

        onChange({ text });
    }

    displayText() {
        const { value } = this.props;
        const lines = 15;
        const textValue = value ? value.text : "";
        const radioValue = value ? value.radio : undefined;
        const textProps = {
            multiline: true,
            rowsMax: lines,
            rows: lines,
            InputLabelProps: {
                shrink: true,
            },
            label: "Text",
            variant: "outlined",
            fullWidth: true,
            onChange: this.handleTextChange,
            value: textValue,
            disabled: !radioValue,
            onFocus: disableArrowKeyListeners,
            onBlur: enableArrowKeyListeners,              
        };

        return (
            <div>
                {!this.displayDescriptionInOption && this.displayDescription()}
                {/* <TextField {...textProps} /> */}
                {this.getMultiLineTextEditor()}
                {this.getWordCount()}
            </div>
        );
    }

    getWordCount(){
        const {formData}=this.props;
        const { showWordCount } = formData;

        if (showWordCount){
            const { value, classes } = this.props;
            const textValue = value ? value.text : "";

            return <div className={classes.wordCount}><WordCount text={textValue}/></div>
        }

        return null;
    }

    getMultiLineTextEditor() {
        const { value, classes, formData } = this.props;

        let { lines } = formData;
        if (!check.number(lines)) {
            lines = 15;
        }

        const textValue = value ? value.text : "";
        const radioValue = value ? value.radio : undefined;

        const props = {
            lineCount: lines,
            value: textValue,
            disabled: !radioValue,
            onChange: this.handleTextChange,
            InputProps: {
                className: classes.inputText,
                // classes: {underline: classes.inputUnderline}
            },
            // onFocus: disableArrowKeyListeners,
            // onBlur: enableArrowKeyListeners,  
        };

        return (
            <MultiLineTextEditor
                {...props}
            />
        );
    }

    displayDescription() {
        const description = this.getCurrentDescription();
        if (!description) return null;

        const { classes } = this.props;

        return (
            <div>
                <Divider />
                <MaterialText className={classes.description}>
                    {description}
                </MaterialText>
            </div>
        );
    }

    getCurrentDescription() {
        const { formData, value } = this.props;
        const { options } = formData;
        const currentValue = value ? value.radio : undefined;

        const description = options.reduce((text, option) => {
            const { value, description } = option;
            return value === currentValue ? description : text;
        }, null);

        return description;
    }

    getLabel(_value) {
        const { formData, value } = this.props;
        const { options } = formData;
        const currentValue = check.assigned(_value)
            ? _value
            : value
            ? value.radio
            : null;

        const label = options.reduce((text, option) => {
            const { value, label } = option;
            return value === currentValue ? label : text;
        }, null);

        return label;
    }

    displayConfirmation() {
        if (!check.nonEmptyString(this.confirmationText)){
            return null;
        }
        
        const { confirmation } = this.state;
        const props = {
            open: confirmation,
            onClose: this.handleConfirmation,
            label: this.confirmationText,
            values:{from: this.getLabel(), to:this.getLabel(this.confirmRadio)}
        };

        return <ConfirmationDialog {...props} />;
    }

    handleConfirmation(confirm) {
        if (confirm && check.assigned(this.confirmRadio)) {
            const { onChange } = this.props;

            onChange({
                radio: {
                    target: { value: this.confirmRadio },
                },
                text: { target: { value: "" } },
            });
        }

        this.setState({ confirmation: null });
        this.confirmRadio = null;
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div>{this.displayOptions()}</div>
                <div>{this.displayText()}</div>
                {this.displayConfirmation()}
            </div>
        );
    }

    isDisabled() {
        return this.context;
    }
}

McqTextAnswerForm.contextType = DisableAnsweringContext;

// TEMP

const styles = ({ palette, spacing }) => ({
    root: {},
    radioRoot: {
        color: palette.background.contrastText,
        padding: `0px ${spacing.unit}px ${spacing.unit}px`,
    },
    radioText: {
        color: palette.background.contrastText,
        marginBottom: spacing.unit * 3,
    },
    radioChecked: {
        color: palette.primary.main + "!important",
    },
    radioItem: {
        alignItems: "flex-start",
    },
    description: {
        margin: `${spacing.unit * 2}px 0px`,
    },
    wordCount:{
        marginTop: spacing.unit,        
    },
    inputText: {
		color: `${palette.primary.main} !important`
	},    
});

McqTextAnswerForm = withStyles(styles)(McqTextAnswerForm);

McqTextAnswerForm = withMessages(McqTextAnswerForm);

// ---

McqTextAnswerForm.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export { McqTextAnswerForm };
