import { check } from "@xams-utils/check-types";

import {
    sessionStorageApi,
    KEYS,
} from "libs/browser_storage/session-storage-api";

const getLoginToken = () => {
    const { loginToken } = sessionStorageApi.retrieveDataFrom(
        KEYS.STUDENT_LOGIN
    );

    return loginToken;
};

const getSetPasswordToken = () => {
    const { setPasswordToken } = sessionStorageApi.retrieveDataFrom(
        KEYS.STUDENT_LOGIN
    );

    return setPasswordToken;
};

const getSetPasswordUserDetails=()=>{
    return sessionStorageApi.retrieveDataFrom(
        KEYS.STUDENT_LOGIN_DETAILS
    );
}

const doesOrgNeedLoginToken = (orgId) => {
    if (orgId === 29) return true;

    return false;
};

const doesUserNeedLoginToken = (orgId, userTypeId) => {
    // 248	29	Super Admin
    // 249	29	Admin
    // 250	29	Centre Admin
    // 251	29	Student
    // 252	29	Public
    // 253	29	Service Account
    // 254	29	Higher Admin
    // 255	29	Assessor
    if (orgId === 29) {
        return check.string(userTypeId) ? userTypeId === "4" : userTypeId === 4;
    }

    return false;
};

const getStudentLoginResponse = () => {
    const { response } = sessionStorageApi.retrieveDataFrom(
        KEYS.STUDENT_LOGIN_RESPONSE
    );

    return response;
};

const loginTokenErrorMessage =
    "Invalid login token, you need to logged in by an admin user";

const getLogoutPage = () => {
    let url = `/login`;
    let logout = true;

    const loginTokin = getLoginToken();

    if (check.assigned(loginTokin)) {
        const { referrer } = sessionStorageApi.retrieveDataFrom(
            KEYS.STUDENT_REFERRER
        );

        if (referrer && check.string(referrer)) {
            const server = getServerName(referrer);

            if (server) {
                url = `${server}/login`;
                logout = false;
            }
        }
    }

    return { logout, url };
};

const getServerName = (url) => {
    const items = url.split("/");

    return items.length > 2 && items[1] === ""
        ? `${items[0]}//${items[2]}`
        : null;
};

export {
    getLoginToken,
    doesOrgNeedLoginToken,
    doesUserNeedLoginToken,
    getStudentLoginResponse,
    loginTokenErrorMessage,
    getLogoutPage,
    getSetPasswordToken,
    getSetPasswordUserDetails
};
