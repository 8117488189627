
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE NAMES
// #########################################

const STATES = {
	STARTING_EXAM: 'starting_exam',
	RUNNING: 'exam_running',
	PAUSED: 'exam_paused',
	NETWORK_ERROR: 'network_error',
	CHECKING_SCHEDULE_COMPLETION: 'checking_if_schedule_completed',
	SCHEDULE_ALREADY_COMPLETED: 'schedule_already_complete',	
}

const RUNNING_STATES = {
	QUESTIONS: 'showing_questions',
	INSTRUCTIONS: 'showing_instructions'
}


// #########################################
// EVENT NAMES
// #########################################

const EVENTS = {
	ERROR: 'error',
	PAUSE_EXAM: 'pause_exam',
	UNPAUSE_EXAM: 'unpause_exam',
	EXAM_STARTED: 'exam_started',
	SHOW_INSTRUCTIONS: 'show_exam_instructions',
	SHOW_QUESTIONS: 'show_exam_questions',
	SCHEDULE_INCOMPLETE: 'schedule_incomplete',
	SCHEDULE_COMPLETE: 'schedule_complete',	
}


// #########################################
// RUNNING_STATES
// #########################################

const questions = new XStateConfig();
questions.addTransition(EVENTS.SHOW_INSTRUCTIONS, RUNNING_STATES.INSTRUCTIONS);

const instructions = new XStateConfig();
instructions.addTransition(EVENTS.SHOW_QUESTIONS, RUNNING_STATES.QUESTIONS);


// #########################################
// STATES
// #########################################

const checkingScheduleCompletion = new XStateConfig();
checkingScheduleCompletion.addTransition(EVENTS.SCHEDULE_COMPLETE, STATES.SCHEDULE_ALREADY_COMPLETED);
checkingScheduleCompletion.addTransition(EVENTS.SCHEDULE_INCOMPLETE, STATES.STARTING_EXAM);

const scheduleAlreadyComplete = new XStateConfig();

const startingExam = new XStateConfig();
startingExam.addTransition(EVENTS.EXAM_STARTED, STATES.RUNNING);
startingExam.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const running = new XStateConfig();
running.initialState = RUNNING_STATES.QUESTIONS;
running.addState(RUNNING_STATES.QUESTIONS, questions);
running.addState(RUNNING_STATES.INSTRUCTIONS, instructions);
running.addTransition(EVENTS.PAUSE_EXAM, STATES.PAUSED);
running.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const paused = new XStateConfig();
paused.addTransition(EVENTS.UNPAUSE_EXAM, STATES.RUNNING);
paused.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const networkError = new XStateConfig();


// #########################################
// MACHINE
// #########################################

const _examRunning = new XStateConfig();
// _examRunning.initialState = STATES.STARTING_EXAM;
_examRunning.initialState = STATES.CHECKING_SCHEDULE_COMPLETION;

_examRunning.addState(STATES.CHECKING_SCHEDULE_COMPLETION, checkingScheduleCompletion);
_examRunning.addState(STATES.SCHEDULE_ALREADY_COMPLETED, scheduleAlreadyComplete);
_examRunning.addState(STATES.STARTING_EXAM, startingExam);
_examRunning.addState(STATES.RUNNING, running);
_examRunning.addState(STATES.PAUSED, paused);
_examRunning.addState(STATES.NETWORK_ERROR, networkError);

const machine = Machine(_examRunning.toObject());
machine.id = "Exam Running Machine";


// #########################################
// EXPORT
// #########################################

const examRunning = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES},
	RUNNING_STATES: {...RUNNING_STATES}
}

export {examRunning}