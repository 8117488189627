
// redux (action-types)
import {SET_FORMRUN_ID, CLEAR_EXAM_DATA} from 'redux/reducers/exam/action-types'
import {SET_EXAM_RESULT} from 'redux/reducers/exam/result/action-types'
import {SET_COMPLETED} from 'redux/reducers/schedules/schedule/action-types'


const completeSchedule = (guid) => ({
	type: SET_COMPLETED,
	guid
});

const setExamResult = (resultData) => ({
	type: SET_EXAM_RESULT,
	...resultData
});

const setFormRunId = (id) => ({
	type: SET_FORMRUN_ID,
	id
});

const clearExamPaper = () => ({
	type: CLEAR_EXAM_DATA
});


export {completeSchedule, setExamResult, setFormRunId, clearExamPaper}