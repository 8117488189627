// npm
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "material-ui-pickers";

// internal
import { getStore } from "redux/store";
import { AppWrapper } from "components/app-wrapper";

const store = getStore();
const Xams = () => (
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <AppWrapper />
        </MuiPickersUtilsProvider>
    </Provider>
);

ReactDOM.render(<Xams />, document.getElementById("root"));
