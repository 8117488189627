import { check } from '@xams-utils/check-types';
import React, { Component } from 'react';

class FormFieldTextLoading extends Component {
    state = {  } 

    constructor(props){
        super(props);

        this.timer=null;
        this.delay=.5;
        this.counterMax=2;
        this.character="."

        const {params}=this.props;
        if (check.nonEmptyString(params)){
            const items=params.split(',');
            
            if (check.nonEmptyArray(items)){
                const delay=parseFloat(items[0]);
                if (!isNaN(delay)){
                    this.delay=delay;
                }
                if (items.length>1){
                    this.counterMax=parseInt(items[1])
                }
            }
        }

        this.handleTimer=this.handleTimer.bind(this);
        this.setTimer=this.setTimer.bind(this);

        this.state={counter:0}
    }

    componentDidMount(){
        this.setTimer();
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    setTimer(){
        this.timer=setTimeout(this.handleTimer, this.delay*1000)
    }

    handleTimer(){
        const {counter}=this.state;
        const newCounter=counter===this.counterMax?0:counter+1;

        this.setState({counter: newCounter}, ()=>{this.setTimer()})
    }

    render() {
        const {counter}=this.state;
        const str = '.'.repeat(counter) 

        return str;
    }
}
 
export {FormFieldTextLoading};