
import {getNumber, getId} from './question/selectors'


const getQuestionCount = (questionsData) => questionsData.size;
const getQuestionIds = (questionsData) => questionsData.keySeq().toArray();
const getQuestionDataById = (questionsData) => (id) => questionsData.get(id);
const getQuestionDataValues = (questionsData) => questionsData.valueSeq().toArray();

const getQuestionDataByNumber = (questionsData) => (number) => {
	return questionsData.find(questionData => getNumber(questionData) === number);
}

const getQuestionIdByNumber = (questionsData) => (number) => {
	const questionData = getQuestionDataByNumber(questionsData)(number);
	return questionData ? getId(questionData) : undefined;
}

export {
	getQuestionIds,
	getQuestionCount,
	getQuestionDataById,
	getQuestionDataValues,
	getQuestionIdByNumber,
	getQuestionDataByNumber
}