// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// material-ui
import Button from "@material-ui/core/Button";

// react
import { Popup } from "components/layout/popup";

// redux (selectors)
import { isOfflineMode, getOfflineReason } from "redux/reducers/app/selectors";
import { getUserGuid } from "redux/reducers/session/user/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import { getGuid as getExamGuid } from "redux/reducers/exam/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { shouldNotifyImmediatelyOnLostConnection } from "redux/reducers/settings/client/selectors";
import {
    getAppData,
    getSettingsData,
    getExamData,
    getSessionData,
} from "redux/reducers/selectors";

// redux (action-types)
import { PAUSE_EXAM, RESUME_EXAM } from "redux/reducers/exam/action-types";

// utils
import { scheduledBugReports } from "libs/browser_storage/apis/scheduled-bug-reports";

const setExamPaused = (value) => ({
    type: value ? PAUSE_EXAM : RESUME_EXAM,
});

const POPUPS = {
    NONE: 0,
    CONNECTION_LOST: 1,
    CONNECTION_RETURNED: 2,
};

class ConnectionLossNotifier extends React.Component {
    constructor(props) {
        super(props);
        this.state = { popup: POPUPS.NONE };
    }

    componentDidUpdate(previousProps) {
        if (previousProps.offline !== this.props.offline) {
            if (this.props.offline) {
                this.handleOffline();
            } else {
                this.handleOnline();
            }
        }
    }

    handleOffline() {
        this.scheduleBugReport();
        if (this.props.notifyImmediately) {
            this.setState({ popup: POPUPS.CONNECTION_LOST });
            this.props.setExamPaused(true);
        }
    }

    handleOnline() {
        if (this.props.notifyImmediately) {
            this.setState({ popup: POPUPS.CONNECTION_RETURNED });
            this.props.setExamPaused(false);
        }
    }

    scheduleBugReport() {
        const { userGuid, examGuid, offlineReason } = this.props;

        const message = "[Automated Report] Network error during exam";
        const query = { userGuid: userGuid, examGuid: examGuid };

        scheduledBugReports.schedule(message, query, offlineReason);
    }

    render() {
        if (!this.props.notifyImmediately) {
            return null;
        }

        switch (this.state.popup) {
            case POPUPS.NONE:
                return null;
            case POPUPS.CONNECTION_LOST:
                return this.ConnectionLostPopup;
            case POPUPS.CONNECTION_RETURNED:
                return this.ConnectionReturnedPopup;
            default:
                throw "Connection lost - invalid popup";
        }
    }

    get ConnectionLostPopup() {
        const title = "Connection lost!";
        const text =
            "We've noticed that your connection has gone down. Please speak " +
            "to your invigilator about this. Any unsaved answers will be " +
            "synced to XAMS as soon as the connection returns.";

        return <Popup title={title} content={{ text }} />;
    }

    get ConnectionReturnedPopup() {
        const title = "Connection returned! :)";
        const text =
            "Your connection has returned and your answers were saved. " +
            "Click 'OK' to continue your exam";

        const onClick = () => {
            this.setState({ popup: POPUPS.NONE });
            this.props.setExamPaused(false);
        };

        const okButton = <Button onClick={onClick}>{"OK"}</Button>;

        return <Popup title={title} content={{ text }} buttons={[okButton]} />;
    }
}

ConnectionLossNotifier.propTypes = {
    offline: PropTypes.bool.isRequired,
    notifyImmediately: PropTypes.bool.isRequired,

    // temp
    examGuid: PropTypes.string.isRequired,
    userGuid: PropTypes.string.isRequired,
};

const mapStoreToProps = (store) => {
    const appData = getAppData(store);

    return {
        offline: isOfflineMode(appData),
        offlineReason: getOfflineReason(appData),
        notifyImmediately: shouldNotifyImmediatelyOnLostConnection(
            getClientSettingsData(getSettingsData(store))
        ),

        // temp
        examGuid: getExamGuid(getExamData(store)),
        userGuid: getUserGuid(getUserSessionData(getSessionData(store))),
    };
};

const mapDispatchToProps = (dispatch) => ({
    setExamPaused: (value) => dispatch(setExamPaused(value)),
});

ConnectionLossNotifier = connect(
    mapStoreToProps,
    mapDispatchToProps
)(ConnectionLossNotifier);

export { ConnectionLossNotifier };
