
import {getProp} from 'custom/object-helper'
import {extractMessage} from 'redux/reducers/exam/helpers'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'
import {OPTIONS_REDUCER} from './options/reducer'


// Note: state should always be initialized at this point (via QUESTION_REDUCER)
const COMBO_QUESTION_REDUCER = (state, action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			state = state.set('dropdownLabel', extractMessage(action.data.LABEL));
			const optionsData = getProp(action, 'data.OPTIONS.OPTION');
			const optionsAction = {...action, data: optionsData};
			return state.set('options', OPTIONS_REDUCER(undefined, optionsAction));
		default:
			return state;
	}
}

export {COMBO_QUESTION_REDUCER}