
// npm
import React from 'react'
import {connect} from 'react-redux'

// react
import {Align } from 'components/layout/align'
import {AppPageModifier} from 'components/pages/app-page-modifier'
import {MaterialText} from 'components/presentation/material-text'
import {SupportedBrowser} from './supported-browser'
import {sortSupportedBrowsers} from './sort-supported-browsers'

// redux
import {getAppSettingsData} from 'redux/reducers/settings/selectors'
import {getSettingsData} from 'redux/reducers/selectors'
import {getSupportedBrowsers} from 'redux/reducers/settings/app/selectors'

// @xams-interactor
import {detectBrowser} from '../browser_checker/detect-browser'

// @xams-entities
import {Version} from '@xams-entities/version'
import {Browser} from '@xams-entities/browser'

// css
import './page.css';


const browserListStyle = {
  overflow: 'hidden',
  float: 'none'
};

const textStyle = {
  color: '#fff',
  'padding-bottom': '20px'
}

const displayCurrentBrowserText = (browser, version, currentBrowserSupported) => {
  const starting = currentBrowserSupported
    ? `Version, ${Version.toString(version)} , of your`
    :  'Your ';
  
    return (
    <React.Fragment>
        <MaterialText variant="h6" style={textStyle}>
            {`${starting} current <u>${Browser.toString(browser)}</u> browser is not supported`}
        </MaterialText>
        <MaterialText variant="h6" style={textStyle}>
          Listed below are the supported browsers and their versions
        </MaterialText>
    </React.Fragment>
  );
}

const displaySupportedBrowsers = (supportedBrowsers) => 
{
  const {browser, version} = detectBrowser();    
  const sortedSupportedBrowsers = sortSupportedBrowsers(browser, supportedBrowsers);  
  const currentBrowserSupported = isCurrentBrowserSupported(browser, sortedSupportedBrowsers);
  
  return (
    <div className="supportedBrowsers" style={browserListStyle}>
      <div>
        {displayCurrentBrowserText(browser, version, currentBrowserSupported)}
      </div>
      {sortedSupportedBrowsers.map((browser, index) => (
        <SupportedBrowser key={index} {...browser} />
      ))}
    </div>
  );
};

const isCurrentBrowserSupported = (browser, browserList) =>
{
  if (browserList.length === 0) {
    return false;
  }
  return Browser.equals(browser, browserList[0].browser);
}

class UnsupportedBrowserPage extends React.Component 
{  
  render()
  {
    const {supportedBrowsers} = this.props;
    const {browser, version} = detectBrowser();
    const title = `Browser not supported: ${Browser.toString(browser)} (${Version.toString(version)})`

    return (
      <React.Fragment>
        <AppPageModifier id="unsupported-browser" appBarProps={{title}}/>
        <Align top={true}>{displaySupportedBrowsers(supportedBrowsers)}</Align>
      </React.Fragment>
    );
  }
}


// BrowserNotSupportedPage (connected to store)
// ------------------------------------------------------------

const mapStoreToProps = store => ({
    supportedBrowsers: getSupportedBrowsers(getAppSettingsData(getSettingsData(store)))
});

UnsupportedBrowserPage = connect(mapStoreToProps)(UnsupportedBrowserPage);


export {UnsupportedBrowserPage}