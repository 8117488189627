import React, { Component } from 'react';

class LoadSchedule extends Component {
    state = {  }

    componentDidMount(){
       const {onSuccess}  = this.props;
       onSuccess();
    }

    render() { 
        return null;
    }
}
 
export {LoadSchedule};