
// npm
import React from 'react'

// react
import {App} from './app'
import {AppTheme} from './app-theme'
import {AppRouter} from './app-router'
import {ErrorLogger} from './error-logger'
import {NetworkErrorBrowserNavigation} from './network-error-browser-navigation'


const AppWrapper = () =>
{
	return (
		<ErrorLogger>
			<AppTheme>
				<AppRouter>
					<NetworkErrorBrowserNavigation/>
					<App/>
				</AppRouter>
			</AppTheme>
		</ErrorLogger>
	)
}


export {AppWrapper}