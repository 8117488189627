import * as ASCX_SELECTORS from './ascx/selectors'
import * as COMBO_SELECTORS from './combo/selectors'
import * as MATCH_SELECTORS from './match/selectors'
import * as TABLE_SELECTORS from './table/selectors'
import * as BLANKS_SELECTORS from './blanks/selectors'
import * as CHECKS_SELECTORS from './checks/selectors'
import * as RADIOS_SELECTORS from './radios/selectors'
import * as LONG_TEXT_SELECTORS from './long_text/selectors'
import * as MULTI_TEXT_SELECTORS from './multi_text/selectors'
import * as ICT_SELECTORS from './ict/selectors'
import * as STATIC_TEXT_SELECTORS from './static_text/selectors'
import * as FORM_SELECTORS from './form/selectors'
import * as QUESTION_TYPES from 'constants/question-types'


const getQuestionSelectors = questionType =>
{
  switch (questionType)
  {
    case QUESTION_TYPES.RADIOS:
    case QUESTION_TYPES.RADIOS_TEXT: 
    case QUESTION_TYPES.TRUE_OR_FALSE:
      return RADIOS_SELECTORS;
    case QUESTION_TYPES.BLANKS:
      return BLANKS_SELECTORS;
    case QUESTION_TYPES.COMBO:
      return COMBO_SELECTORS;
    case QUESTION_TYPES.CHECKS:
      return CHECKS_SELECTORS;
    case QUESTION_TYPES.TABLE:
      return TABLE_SELECTORS;
    case QUESTION_TYPES.MULTI_TEXT:
      return MULTI_TEXT_SELECTORS;
    case QUESTION_TYPES.MATCH:
      return MATCH_SELECTORS;
    case QUESTION_TYPES.LONG_TEXT:
      return LONG_TEXT_SELECTORS;
    case QUESTION_TYPES.ASCX:
      return ASCX_SELECTORS;
    case QUESTION_TYPES.ICT:
      return ICT_SELECTORS;
    case QUESTION_TYPES.STATIC_TEXT:
      return STATIC_TEXT_SELECTORS;
    case QUESTION_TYPES.FORM:
      return FORM_SELECTORS;            
    default:
      throw 'Unknown question type';
  }
}


const getId = questionData => questionData.get('id');
const getType = questionData => questionData.get('type');
const getText = questionData => questionData.get('text');
const getMarks = questionData => questionData.get('marks');
const getNumber = questionData => questionData.get('number');
const getIntroText = questionData => questionData.get('introText');
const getLocalAnswer = questionData => questionData.get('localAnswer');
const getServerAnswer = questionData => questionData.get('serverAnswer');
const getResourceUrl = questionData => questionData.get('resourceUrl');
const getWorkingsData = questionData => questionData.get('workings');

const isDisabled = questionData => questionData.get('disabled');
const isBookmarked = questionData => questionData.get('bookmarked');
const requiresCalculator = questionData => questionData.get('requiresCalculator');
const requiresWorkings = questionData => questionData.get('requiresWorkings');
const isDragAndDrop = questionData => questionData.get('dragAndDrop');

const getVersionId = questionData => questionData.get('versionId');
const getVersionNumber = questionData => questionData.get('versionNumber');

const isAnswered = questionData => {
  const questionType = getType(questionData);
  const questionSelectors = getQuestionSelectors(questionType);
  if (questionType===QUESTION_TYPES.FORM){
    return questionSelectors.isAnswered(getLocalAnswer(questionData), questionData);  
  }
  return questionSelectors.isAnswered(getLocalAnswer(questionData));
};


export {
  // getters
  getId,
  getType,
  getText,
  getMarks,
  getNumber,
  getIntroText,
  getLocalAnswer,
  getServerAnswer,
  getResourceUrl,
  getWorkingsData,
  // predicates
  isAnswered,
  isDisabled,
  isBookmarked,
  requiresCalculator,
  requiresWorkings,
  isDragAndDrop,
  getVersionId,
  getVersionNumber
}