
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {PopupButton} from './popup-button'
import {withMessages} from 'components/hocs/messages'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const confirmMessageId = MESSAGE_IDS.GENERAL.CONFIRM;


let ConfirmButton = ({onClick, messages}) =>
{
	const text = messages[confirmMessageId];
	return <PopupButton color='secondary' text={text} onClick={onClick}/>;
}

ConfirmButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[confirmMessageId]: PropTypes.string.isRequired
	}).isRequired
}


ConfirmButton = withMessages(ConfirmButton);


export {ConfirmButton}