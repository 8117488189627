import React, { Component } from 'react';
import { connect } from "react-redux";

import { getExamData } from "redux/reducers/selectors";
import { getConfirmExamResume } from "redux/reducers/exam/selectors";
import { PAUSE_EXAM, RESUME_EXAM, CONFIRM_RESUME_EXAM } from "redux/reducers/exam/action-types";

import { Align } from "components/layout/align";

import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

import {PauseMessage} from "./pause-message"
import {PauseImage} from "./pause-image"

const styles = (theme) => ({
    paper: {
        flexGrow: 1,
        maxWidth: 800,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
        backgroundColor: theme.palette.background.default,
    },
    title: {
        display: "flex",
        justifyContent: "left",
        "&>div:nth-child(2)": {
            marginTop: ".5em",
            marginLeft: theme.spacing.unit * 2,
        },
    },
    message: {
        marginTop: theme.spacing.unit * 2,
    },
});

const setExamPaused = (value) => ({
    type: value ? PAUSE_EXAM : RESUME_EXAM,
});

const setConfirmResumeExam = (value) => ({
    type: CONFIRM_RESUME_EXAM,
    value,
});

class ExamPausedPage extends Component {
    state = {  } 

    constructor(props){
        super(props);

        this.handleConfirmation=this.handleConfirmation.bind(this);
    }

    handleConfirmation(){
        const {confirmResumeExam, setExamPaused}=this.props;

        confirmResumeExam(false);
        setExamPaused(false);
    }

    render() { 
        const { classes, confirmExamResume } = this.props;
        const props={confirmExamResume, onConfirmation: this.handleConfirmation}
        console.log('confirmExamResume', confirmExamResume)

        return (
            <Align top>
                <Paper className={classes.paper}>
                    <PauseImage {...props}/>
                </Paper>
            </Align>
        );
    }
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const confirmExamResume = getConfirmExamResume(examData);

    return { confirmExamResume };
};

const mapDispatchToProps = (dispatch) => ({
    setExamPaused: (value) => dispatch(setExamPaused(value)),
    confirmResumeExam: (value) => dispatch(setConfirmResumeExam(value)),
});

ExamPausedPage = connect(mapStoreToProps, mapDispatchToProps)(ExamPausedPage);

ExamPausedPage = withStyles(styles)(ExamPausedPage);


export { ExamPausedPage };
