export const needToCheckProp = prop => {
  if (prop === null) return false;
  if (Array.isArray(prop)) return prop.length > 0;
  if (typeof prop === 'number') return prop > 0;
  return false;
};

export const getFirstFileType = fileType => {
  const fileTypes = fileType.split('/');
  return fileTypes.length === 0 ? '' : fileTypes[0];
};

export const getFileExtension = fileName => fileName.split('.').pop();
