
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Button from '@material-ui/core/Button'


const SubmitButton = ({onClick, enabled}) =>
{
	const props = {
		size: 'small',
		color: 'primary',
		disabled: !enabled,
		variant: 'outlined',
		disableRipple: true,
		disableFocusRipple: true,
		onClick
	}

	return (
		<Button {...props}>
			{"Submit"}
		</Button>
	);
}

SubmitButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	enabled: PropTypes.bool.isRequired
}


export {SubmitButton}