
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE NAMES
// #########################################

const STATES = {
	ATTEMPTING_TO_RESUME: 'attempting_to_resume',
	AUDIO_CHECK_SCREEN: 'audio_check_screen',
	INTRO_SCREEN: 'intro_screen',
	PUBLIC_EXAM: 'public_exam',
	STARTING_ACTIVITY_LOGGER: 'starting_activity_logger',
	RESUMING_ACTIVITY_LOGGER: 'resuming_activity_logger',	
	REDIRECT_TO_EXAM_QUESTIONS: 'redirecting_to_exam_questions'
}


// #########################################
// EVENT NAMES
// #########################################

const EVENTS = {
	CAN_RESUME_EXAM: 'can_resume_exam',
	CANT_RESUME_EXAM: 'cant_resume_exam',
	STARTED_ACTIVITY_LOGGER: 'started_activity_logger',
	RESUMED_ACTIVITY_LOGGER: 'resumed_activity_logger',
	DISPLAY_AUDIO_CHECK: 'display_audio_check',	
	DISPLAY_INTRO: 'display_intro',
	START_EXAM: 'start_exam'
}


// #########################################
// STATES
// #########################################

const attemptingToResume = new XStateConfig();
attemptingToResume.addTransition(EVENTS.CANT_RESUME_EXAM, STATES.PUBLIC_EXAM);
attemptingToResume.addTransition(EVENTS.CAN_RESUME_EXAM, STATES.RESUMING_ACTIVITY_LOGGER);

const publicExam = new XStateConfig();
publicExam.addTransition(EVENTS.DISPLAY_INTRO, STATES.AUDIO_CHECK_SCREEN);

const audioCheckScreen = new XStateConfig();
audioCheckScreen.addTransition(EVENTS.START_EXAM, STATES.INTRO_SCREEN);

const introScreen = new XStateConfig();
introScreen.addTransition(EVENTS.DISPLAY_AUDIO_CHECK, STATES.STARTING_ACTIVITY_LOGGER);

const resumingActivityLogger = new XStateConfig();
resumingActivityLogger.addTransition(EVENTS.RESUMED_ACTIVITY_LOGGER, STATES.REDIRECT_TO_EXAM_QUESTIONS);

const startingActivityLogger = new XStateConfig();
startingActivityLogger.addTransition(EVENTS.STARTED_ACTIVITY_LOGGER, STATES.REDIRECT_TO_EXAM_QUESTIONS);

const redirectToExamQuestions = new XStateConfig();


// #########################################
// MACHINE
// #########################################

const _examIntro = new XStateConfig();
_examIntro.initialState = STATES.ATTEMPTING_TO_RESUME;
_examIntro.addState(STATES.ATTEMPTING_TO_RESUME, attemptingToResume);
_examIntro.addState(STATES.PUBLIC_EXAM, publicExam);
_examIntro.addState(STATES.INTRO_SCREEN, introScreen);
_examIntro.addState(STATES.AUDIO_CHECK_SCREEN, audioCheckScreen);
_examIntro.addState(STATES.RESUMING_ACTIVITY_LOGGER, resumingActivityLogger);
_examIntro.addState(STATES.STARTING_ACTIVITY_LOGGER, startingActivityLogger);
_examIntro.addState(STATES.REDIRECT_TO_EXAM_QUESTIONS, redirectToExamQuestions);

const machine = Machine(_examIntro.toObject());
machine.id = "Exam Intro Machine";


// #########################################
// EXPORT
// #########################################

const examIntro = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES}
}

export {examIntro}