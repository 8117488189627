import React, { Component } from "react";
import { connect } from "react-redux";
import * as signalR from "@microsoft/signalr";

import {PrintStaticTextDialog} from './print-static-text-dialog'

import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";

import {
    getExamData,
    getSessionData,
    getSchedulesData,
    getSettingsData,
} from "redux/reducers/selectors";
import {
    getFormRunGuid,
    getScheduleGuid,
    getGuid as getExamGuid,
} from "redux/reducers/exam/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import { getUserGuid } from "redux/reducers/session/user/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import {
    getScheduleType,
    getExamTypeGuid,
} from "redux/reducers/schedules/schedule/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import {
    getApiEndpointUrl,
    getHubUrl,
} from "redux/reducers/settings/app/selectors";

import { assessmentApi } from "libs/api/interface/api-assessment";

const getUrlParameterStringFromObject = (obj) => {
    let str = "";

    if (obj) {
        for (let key in obj) {
            if (str !== "") {
                str += "&";
            }
            str += `${key}=${encodeURIComponent(obj[key])}`;
        }
    }

    return str;
};

class PrintStaticTextButton extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
        this.printByApiCall = this.printByApiCall.bind(this);
        this.printByHub = this.printByHub.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);

        this.state = { printing: false, error: false };
    }

    handleOnClick() {
        this.printByApiCall();
        //this.printByHub();
    }

    getPayload() {
        const {
            questionId,
            formRunGuid,
            userGuid,
            scheduleGuid,
            scheduleType,
            examGuid,
            examTypeGuid,
        } = this.props;

        const payload = {
            questionId,
            userGuid,
            scheduleGuid,
            scheduleType,
            examGuid,
            examTypeGuid,
            formRunGuid,
        };

        return payload;
    }

    handleCloseDialog = () => {
        this.setState({ printing: false, error: false });
    }    

    printByApiCall() {
        const { apiEndpointUrl } = this.props;

        const payload = {...this.getPayload(), filename:'question.pdf'};

        const handleError = (e) => {
            this.setState({ printing: false, error: true });
        };

        this.setState({ printing: true });

        assessmentApi
            .getPrintStaticTextUrl(payload)
            .then((response) => {
                const data = JSON.parse(response);
                if (data) {
                    const { link, error } = data;

                    if (link) {
                        this.handleCloseDialog();

                        const url = `${apiEndpointUrl}${link}&inline=true`;
                        window.open(url);
                    } else if (error) {
                        handleError(error);
                    }
                }
            }, handleError)
            .catch(() => {
                handleError();
            });
    }

    printByHub() {
        const { hubUrl } = this.props;
        const method = "apiProgressHub";
        const url = `${hubUrl}${method}`;

        let connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .build();

        connection.on("update", (data) => {
            debugger;
        });

        connection
            .start()
            .then((data) => {
                debugger;
            })
            .catch((e) => {
                console.log(e);
                debugger;
            });
    }

    render() {
        const { printing, error } = this.state;

        const iconProps = { onClick: this.handleOnClick };

        const dialogProps = { printing, error, onErrorClose: this.handleCloseDialog };

        return (
            <React.Fragment>
                <IconButton {...iconProps}>
                    <PrintIcon />
                </IconButton>
                <PrintStaticTextDialog {...dialogProps} />
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store, ownProps) => {
    const questionId = parseInt(ownProps.questionId, 10);

    const examData = getExamData(store);
    const formRunGuid = getFormRunGuid(examData);
    const scheduleGuid = getScheduleGuid(examData);
    const examGuid = getExamGuid(examData);

    const sessionData = getSessionData(store);
    const userData = getUserSessionData(sessionData);
    const userGuid = getUserGuid(userData);

    const schedulesData = getSchedulesData(store);
    const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);

    const scheduleType = getScheduleType(scheduleData);
    const examTypeGuid = getExamTypeGuid(scheduleData);

    const settingsData = getSettingsData(store);
    const appSettingsData = getAppSettingsData(settingsData);
    const apiEndpointUrl = getApiEndpointUrl(appSettingsData);
    const hubUrl = getHubUrl(appSettingsData);

    return {
        questionId,
        formRunGuid,
        userGuid,
        scheduleGuid,
        scheduleType,
        examGuid,
        examTypeGuid,
        apiEndpointUrl,
        hubUrl,
    };
};

PrintStaticTextButton = connect(mapStoreToProps)(PrintStaticTextButton);

export { PrintStaticTextButton };