import { getProp } from "custom/object-helper";
import { PARTS_REDUCER } from "./parts/reducer";
import { SET_EXAM_DATA } from "redux/reducers/exam/action-types";
import { check } from "@xams-utils/check-types";

// Note: state should always be initialized at this point (via QUESTION_REDUCER)
const MULTI_TEXT_QUESTION_REDUCER = (state, action) => {
    switch (action.type) {
        case SET_EXAM_DATA:
            const partsData = getProp(action, "data.PARTS.MULTITEXTPART");
            const partsAction = { ...action, data: partsData };

            const showWordCount = getProp(action, "data.@ShowWordCount");
            state = state.set(
                "showWordCount",
                check.boolean(showWordCount) ? showWordCount : false
            );

            if (state.get("requiresWorkings")) {
                const totalWorkingLines = partsData.reduce(
                    (total, partData) => {
                        return total + partData["@WorkingAreaSize"];
                    },
                    0
                );
                state.set("requiresWorkings", totalWorkingLines > 0);
            }

            return state.set("parts", PARTS_REDUCER(undefined, partsAction));
        default:
            return state;
    }
};

export { MULTI_TEXT_QUESTION_REDUCER };
