import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({ spacing, palette }) => ({
    root: {
        display: "flex",
        "&>div": {
            display: "flex",
            boxSizing: "border-box",
        },
        "&>div:not(:last-child)": {
            width: spacing.unit * 2,
        },
        "&>div:last-child": {
            paddingLeft: spacing.unit,
        },
    },
    bgr: {
        color: palette.primary.dark,
        marginLeft: spacing.unit * 2,
        marginRight: spacing.unit,
    },
});

let ThemeSelectorItem = (props) => {
    const { name, data, classes } = props;
    const { primary: colour1, background: colour2 } = data;
    const _name = name.toLowerCase();
    const colour = "white";
    const border = "1px #d9d9d9 solid";
    const border1 = _name.lastIndexOf(colour, 0) === 0 ? border : null;
    const border2 =
        _name.indexOf(colour, name.length - colour.length) !== -1
            ? border
            : null;

    return (
        <div className={classes.root}>
            <div style={{ background: colour1, border: border1 }} />
            <div style={{ background: colour2, border: border2 }} />
            <div>{name}</div>
        </div>
    );
};

ThemeSelectorItem = withStyles(styles)(ThemeSelectorItem);

export { ThemeSelectorItem };
