
// npm
import chroma from 'chroma-js'

// utils
import {roundToNearest} from 'custom/math-helper'


// PRIVATE METHODS

class ColorHelper
{
	constructor(color)
	{
		if (!chroma.valid(color)) {
			throw "ColorHelper received invalid color: " + color;
		}
		
		this.color = chroma(color);
		this.initializeMethods();
	}

	initializeMethods()
	{
		// PRIVATE METHODS
		// ------------------------------------------------------------------------

		// ------------------------------------------------------------------------
		// getShades()
		// ------------------------------------------------------------------------ 

		const getShades = (color, step, range={min: 0, max: 1}) => {
			const shades = [];

			for (let i = range.min; i <= range.max; i += step) {
				shades.push(color.set('hsl.l', i));
			}

			return shades;
		}

		// ------------------------------------------------------------------------
		// contrast()
		// ------------------------------------------------------------------------ 

		const single_contrast = (range, step) => {
			const shades = getShades(this.color, step);

			let contrastingShade;
			let shadeContrastValue = 0;

			shades.forEach((shade, index) => {
				const newContrastValue = chroma.contrast(this.color, shade);
				if (newContrastValue < range.min) { return; }
				if (newContrastValue > range.max) { return; }
				if (newContrastValue > shadeContrastValue) {
					shadeContrastValue = newContrastValue;
					contrastingShade = shade;
				}
			});

			if (!contrastingShade) {
				const contrastRange = `${range.min}-${range.max}`;
				throw `Could not contrast ${this.color.hex()} using a range of ${contrastRange}`;
			}

			return contrastingShade.hex();
		}

		const multi_contrast = (range, step, contrastingColor) => {
			const shades = getShades(this.color, step);

			let contrastingShade;
			let shadeContrastValue = 0;

			shades.forEach((shade, index) => {
				const newContrastValue = chroma.contrast(contrastingColor, shade);
				if (newContrastValue < range.min) { return; }
				if (newContrastValue > range.max) { return; }
				if (newContrastValue > shadeContrastValue) {
					shadeContrastValue = newContrastValue;
					contrastingShade = shade;
				}
			});

			if (!contrastingShade) {
				const color1 = this.color.hex();
				const color2 = contrastingColor.hex();
				const contrastRange = `${range.min}-${range.max}`;
				throw `Could not contrast ${color1} against ${color2} using a contrast range of ${contrastRange}`;
			}

			return contrastingShade.hex();			
		}


		// PUBLIC METHODS
		// ------------------------------------------------------------------------

		this.contrast = (configuration={}) => {
			const range = configuration.range || {min: 4.5, max: 6};
			const {step=0.05, contrastingColor} = configuration;

			return contrastingColor ?
				multi_contrast(range, step, chroma(contrastingColor)) :
				single_contrast(range, step);
		}
	}
}


export {ColorHelper}