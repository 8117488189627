
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react (layout)
import {ContentBarTitle} from 'components/pages/exam/_layout/content/bar/title'

// react (presentation)
import {withMessages} from 'components/hocs/messages'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const examInstructionsMessageId = MESSAGE_IDS.EXAM.INSTRUCTIONS;


let InstructionsTitle = ({messages}) => (
	<ContentBarTitle>
		{messages[examInstructionsMessageId]}
	</ContentBarTitle>
)


InstructionsTitle = withMessages(InstructionsTitle);


export {InstructionsTitle}