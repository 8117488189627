
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Checkbox from '@material-ui/core/Checkbox'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import DialogActions from '@material-ui/core/DialogActions'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {MaterialText} from 'components/presentation/material-text'

// react
import {withMessages} from 'components/hocs/messages'
import {DisableAnsweringContext} from 'components/pages/exam/disabled-context'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const confirmMessageId = MESSAGE_IDS.GENERAL.CONFIRM;


class ChecksAnswerForm extends React.Component
{
	constructor(props)
	{
		super(props);

		this.removeExcessiveAnswers = () => {
			const answersToRemove = this.props.formData.getExcessiveAnswers();
			answersToRemove.forEach(answer => {
				this.props.onChange({target: {value: answer, checked: false}});
			});
		}
	}

	render()
	{
		const {value, formData:{maxOptions}} = this.props;

		return (
			<React.Fragment>
				{this.Form}
				{value.length > maxOptions && this.MaxOptionsWarning}
			</React.Fragment>
		)
	}

	get Form()
	{
		return (
			<FormControl component="fieldset">
				<DisableAnsweringContext.Consumer>
					{value => this.getOptions(value)}
				</DisableAnsweringContext.Consumer>
			</FormControl>
		)
	}

	getOptions(disabled)
	{
		const {classes} = this.props;

		return this.props.formData.options.map(option => {
			const controlProps = {
				disabled: disabled,
				classes: {
					root: classes.root,
					checked: classes.checked
				},
				checked: this.props.value.indexOf(option.id) >= 0,
				onChange: ({target}) => {
					this.props.onChange({
						optionId: option.id,
						checked: target.checked
					});
				}
			}

			const control = <Checkbox {...controlProps}/>;
			return this.getFormControlLabel(control, option.text);
		});
	}

	getFormControlLabel(control, value)
	{
		const {classes} = this.props;
		const labelNode = <MaterialText className={classes.text}>{value}</MaterialText>;
		return <FormControlLabel control={control} value={value} label={labelNode}/>;
	}

	get MaxOptionsWarning()
	{
		const buttonProps = {
			autoFocus: true,
			color: "secondary",
			onClick: this.removeExcessiveAnswers
		}

		return (
			<Dialog open>
				<DialogTitle id="alert-dialog-title">
					{this.MaxOptionsWarningMessage}
				</DialogTitle>
				<DialogActions>
					<Button {...buttonProps}>
						{this.props.messages[confirmMessageId]}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	get MaxOptionsWarningMessage()
	{
		const {formData} = this.props;
		return `You have already selected ${formData.maxOptions} options!`
	}
}

ChecksAnswerForm.propTypes = {
	value: PropTypes.arrayOf(PropTypes.number).isRequired,
	onChange: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		maxOptions: PropTypes.number.isRequired,
		getExcessiveAnswers: PropTypes.func.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired
		}).isRequired).isRequired
	}),
	messages: PropTypes.shape({
		[confirmMessageId]: PropTypes.string.isRequired
	})
}


ChecksAnswerForm = withMessages(ChecksAnswerForm);


const styles = ({palette}) => ({
	root: {
		color: palette.background.contrastText
	},
	text: {
		color: palette.background.contrastText
	},
	checked: {
		color: palette.primary.main + "!important"
	}
})

ChecksAnswerForm = withStyles(styles)(ChecksAnswerForm);


export {ChecksAnswerForm}