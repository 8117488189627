import React, { Component } from 'react';

import {FormFieldInput} from "./form-field-input"
import {FormFieldText} from "./form-field-text"
import {FormFieldValue} from "./form-field-value"
import {FormFieldTextArea} from "./form-field-text-area"
import {FormFieldCalendar} from "./form-field-calendar"

import { FORM_FIELD_TYPES } from 'constants/form';

class FormField extends Component {
    state = {  } 
    render() {
        const {type} =this.props;
        if (type===FORM_FIELD_TYPES.TEXT ){
            return <FormFieldText {...this.props}/>
        }        
        else if (type===FORM_FIELD_TYPES.TEXTAREA ){
            return <FormFieldTextArea {...this.props}/>
        }
        else if (type===FORM_FIELD_TYPES.VALUE ){
            return <FormFieldValue {...this.props}/>
        }
        else if (type===FORM_FIELD_TYPES.CALENDAR ){
            return <FormFieldCalendar {...this.props}/>
        }                                        

        return <FormFieldInput {...this.props}/>
    }
}
 
export {FormField}