// npm
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getExamData } from "redux/reducers/selectors";
import { getShowExamInFullScreen } from "redux/reducers/exam/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getOrgFullScreenExam } from "redux/reducers/settings/client/selectors";

import { SET_SHOW_EXAM_IN_FULL_SCREEN } from "redux/reducers/exam/action-types";

// react
import { ExamIntroPageMachine } from "./machine";

class ExamIntro extends Component {
    constructor(props) {
        super(props);

        const {
            match: { params },
        } = props;

        const { needToSetShowExamInFullScreen, setShowExamInFullScreen } =
            props;

        if (needToSetShowExamInFullScreen) {
            setShowExamInFullScreen(true);
        }

        this.examGuid = params.examGuid;
    }

    render() {
        return <ExamIntroPageMachine examGuid={this.examGuid} />;
    }
}

ExamIntro.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            examGuid: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

const mapStoreToProps = (store) => {
    let needToSetShowExamInFullScreen = false;

    const examData = getExamData(store);
    const showExamInFullScreen = getShowExamInFullScreen(examData);

    if (!showExamInFullScreen) {
        const settingsData = getSettingsData(store);
        const clientSettingsData = getClientSettingsData(settingsData);

        needToSetShowExamInFullScreen =
            getOrgFullScreenExam(clientSettingsData);
    }

    return { needToSetShowExamInFullScreen };
};

const mapDispatchToProps = (dispatch) => ({
    setShowExamInFullScreen: (value) => {
        dispatch({
            type: SET_SHOW_EXAM_IN_FULL_SCREEN,
            value,
        });
    },
});

ExamIntro = connect(mapStoreToProps, mapDispatchToProps)(ExamIntro);

export { ExamIntro };
