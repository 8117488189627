
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react (layout)
import {ExamLayout} from 'components/pages/exam/_layout/layout'
import {Align} from 'components/layout/align'

// react (concrete)
import {ResumeButton} from './resume-button'
import {InstructionsTitle} from './instructions-title'
import {ExamTimer} from 'components/pages/exam/running/exam-timer'
import {InstructionsPanel} from 'components/pages/exam/running/instructions-panel'


let ExamInstructionsPage = (props) =>
{
	const examLayoutProps = {
		bar: {
			content: (
				<React.Fragment>
					<InstructionsTitle/>
					<ResumeButton onClick={props.onShowQuestions}/>
					<ExamTimer onFinish={props.onShowQuestions}/>
				</React.Fragment>
			)
		},
		centre: {
			content: (
				<Align top>
					<InstructionsPanel/>
				</Align>
			)
		}
	}

	return <ExamLayout {...examLayoutProps}/>;
}

ExamInstructionsPage.propTypes = {
	onShowQuestions: PropTypes.func.isRequired
}


export {ExamInstructionsPage}