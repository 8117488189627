
// npm
import React from 'react'

// react
import {MessagesContext} from './context'


const withMessages = (Component) =>
{
	return (props) => {
		return (
			<MessagesContext.Consumer>
				{(messages) => <Component messages={messages} {...props}/>}
			</MessagesContext.Consumer>
		)
	}
}


export {withMessages}