// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//material-ui
import { styles } from "./form-styles";
import Fade from "@material-ui/core/Fade";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import WarningIcon from "@material-ui/icons/Warning";

// redux
import { setUsername, setPassword } from "./actions";
import { getLoginData } from "redux/reducers/selectors";
import { getLoginError } from "redux/reducers/login/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getLogoUrl, getEnableForgottenPassword } from "redux/reducers/settings/client/selectors";
import { getUrlToken, getOrgId } from "redux/reducers/session/client/selectors";
import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";

// react
import { Image } from "components/presentation/image";
import { withMessages } from "components/hocs/messages";
import { MaterialText } from "components/presentation/material-text";
import { LoadingSpinner } from "components/presentation/loading-spinner";

// constants
import { MESSAGE_IDS } from "constants/message-ids";


const loginMessageId = MESSAGE_IDS.LOGIN.MESSAGE;
const loginTitleMessageId = MESSAGE_IDS.LOGIN.TITLE;
const usernameMessageId = MESSAGE_IDS.LOGIN.USERNAME;
const loginButtonMessageId = MESSAGE_IDS.LOGIN.BUTTON;
const passwordMessageId = MESSAGE_IDS.GENERAL.PASSWORD;
const forgottenPasswordMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.TITLE;

// LoginForm (not connected to store)
// -------------------------------------------------------------

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.initializeBoundMethods();
        this.userInput = null;
        this.passwordInput = null;
    }

    initializeBoundMethods() {
        this.onFieldChange = () => {
            const filled = this.isFormFilled();
            const { onCanSubmit, onCantSubmit } = this.props;
            if (filled && onCanSubmit) {
                onCanSubmit();
            } else if (!filled && onCantSubmit) {
                onCantSubmit();
            }
        };

        this.onSubmit = (e) => {
            e.preventDefault();
            const username = this.userInput.value;
            const password = this.passwordInput.value;
            this.props.saveUsername(username);
            this.props.savePassword(password);
            this.props.onSubmit();
        };

        this.checkForAutofill = () => {
            if (!this.isFormFilled()) {
                let count = 0;
                const maxCount = 20;
                const interval = 100;

                const intervalId = setInterval(() => {
                    if (count++ >= maxCount) {
                        clearInterval(intervalId);
                    }
                    if (this.isFormFilled()) {
                        clearInterval(intervalId);
                        this.onFieldChange();
                    }
                }, interval);
            } else {
                this.onFieldChange();
            }
        };

        this.handleForgottenPassword = () => {
            const { history, urlToken } = this.props;

            history.replace(`/forgot?${urlToken}`);
        };
    }

    componentDidUpdate(previousProps) {
        if (!!previousProps.showSpinner && !this.props.showSpinner) {
            this.passwordInput.focus();
        }
    }

    render() {
        return (
            <Fade in timeout={1000} onEntered={this.checkForAutofill}>
                <div className={this.props.classes.layout}>
                    <LoadingSpinner
                        color="secondary"
                        open={!!this.props.showSpinner}
                    >
                        <Card raised className={this.props.classes.card}>
                            <CardContent
                                className={this.props.classes.cardContent}
                            >
                                {this.Branding}
                                {this.Title}
                                {this.Form}
                            </CardContent>
                        </Card>
                    </LoadingSpinner>
                </div>
            </Fade>
        );
    }

    get Branding() {
        const { classes } = this.props;

        return (
            <div className={classes.logoBox}>
                <Image
                    className={classes.logo}
                    src={this.props.logoUrl}
                    alt="branding"
                />
            </div>
        );
    }

    get Title() {
        return (
            <MaterialText className={this.props.classes.formText} variant="h5">
                {this.props.messages[loginTitleMessageId]}
            </MaterialText>
        );
    }

    get Form() {
        return (
            <form className={this.props.classes.form} onSubmit={this.onSubmit}>
                {this.UserInput}
                {this.PasswordInput}
                {this.ErrorPanel}
                {this.LoginButton}
                {this.InfoPanel}
                {this.ForgottenPassword}
            </form>
        );
    }

    get UserInput() {
        const name = "username";
        const label = this.props.messages[usernameMessageId];

        const inputRef = (input) => {
            this.userInput = input;
        };
        const inputProps = {
            ...this.BasicInputProps,
            name,
            inputRef,
            id: "user-input",
        };

        return this.getFormInput(name, label, <Input {...inputProps} />);
    }

    get PasswordInput() {
        const name = "password";
        const label = this.props.messages[passwordMessageId];

        const type = "password";
        const inputRef = (input) => {
            this.passwordInput = input;
        };
        const inputProps = {
            ...this.BasicInputProps,
            name,
            type,
            inputRef,
            id: "password-input",
        };

        return this.getFormInput(name, label, <Input {...inputProps} />);
    }

    get BasicInputProps() {
        const { classes } = this.props;

        return {
            autoComplete: "on",
            onChange: this.onFieldChange,
            disabled: !!this.props.disableInputs,
            className: this.props.classes.formInputText,
            classes: {
                input: classes.input,
                underline: classes.inputUnderline,
            },
        };
    }

    getFormInput(name, label, inputElement) {
        const { classes } = this.props;

        return (
            <FormControl margin="normal" required fullWidth>
                <InputLabel className={classes.formInputText} htmlFor={name}>
                    {label}
                </InputLabel>
                {inputElement}
            </FormControl>
        );
    }

    get ErrorPanel() {
        const { classes, errorText } = this.props;
        if (!errorText) {
            return null;
        }

        return (
            <span className={classes.errorContainer}>
                <WarningIcon
                    className={`${classes.error} ${classes.errorIcon}`}
                />
                <MaterialText className={classes.error}>
                    {errorText}
                </MaterialText>
            </span>
        );
    }

    get LoginButton() {
        const props = {
            type: "submit",
            fullWidth: true,
            variant: "contained",
            color: "primary",
            className: this.props.classes.submit,
            classes: { disabled: this.props.classes.submitDisabled },
            disabled: !!this.props.disableSubmit || !!this.props.disableInputs,
        };

        return (
            <Button {...props}>
                {this.props.messages[loginButtonMessageId]}
            </Button>
        );
    }

    get InfoPanel() {
        return (
            <Hidden xsDown>
                <div className={this.props.classes.loginInfo}>
                    <MaterialText className={this.props.classes.formText}>
                        {this.props.messages[loginMessageId]}
                    </MaterialText>
                </div>
            </Hidden>
        );
    }

    get ForgottenPassword() {
        const { messages, classes, canForgetPassword, showSpinner, orgId } = this.props;
        if (!canForgetPassword) return null;

        const label = messages[forgottenPasswordMessageId];
        const buttonProps = {
            variant: "contained",
            color: [33,38,39].indexOf(orgId)!==-1 ? "#000" : "secondary",
            className: this.props.classes.submit,
            // classes: { disabled: this.props.classes.submitDisabled },
            disabled: !!showSpinner,
            onClick: this.handleForgottenPassword,
        };

        return (
            <div className={classes.forgotten}>
                <Button {...buttonProps}>{label}</Button>
            </div>
        );
    }

    isFormFilled() {
        const { userInput, passwordInput } = this;
        return (
            this.isFieldFilled(userInput) && this.isFieldFilled(passwordInput)
        );
    }

    isFieldFilled(inputElement) {
        if (inputElement.value !== "") {
            return true;
        }

        const computedStyle = window.getComputedStyle(inputElement);
        const autofilled = computedStyle.content.includes("filled");
        return autofilled;
    }
}

LoginForm.propTypes = {
    disableInputs: PropTypes.bool,
    disableSubmit: PropTypes.bool,
    onCanSubmit: PropTypes.func,
    onCantSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    showSpinner: PropTypes.bool,
    errorText: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    saveUsername: PropTypes.func.isRequired,
    savePassword: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    messages: PropTypes.shape({
        [loginMessageId]: PropTypes.string.isRequired,
        [loginTitleMessageId]: PropTypes.string.isRequired,
        [usernameMessageId]: PropTypes.string.isRequired,
        [loginButtonMessageId]: PropTypes.string.isRequired,
        [passwordMessageId]: PropTypes.string.isRequired,
    }),
};

// LoginForm (connected to store)
// -------------------------------------------------------------

const mapStoreToProps = (store) => {
    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);
    const settingsData = getSettingsData(store);
	const clientSettingsData = getClientSettingsData(settingsData);        

    return {
        canForgetPassword: getEnableForgottenPassword(clientSettingsData),
        urlToken: getUrlToken(clientSessionData),
        errorText: getLoginError(getLoginData(store)),
        logoUrl: getLogoUrl(getClientSettingsData(getSettingsData(store))),
        orgId: getOrgId(clientSessionData)
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveUsername: (value) => dispatch(setUsername(value)),
    savePassword: (value) => dispatch(setPassword(value)),
});

LoginForm = connect(mapStoreToProps, mapDispatchToProps)(LoginForm);
LoginForm = withStyles(styles)(LoginForm);
LoginForm = withMessages(LoginForm);
LoginForm = withRouter(LoginForm);

// EXPORT
// -------------------------------------------------------------
export { LoginForm };
