import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import UploadedFile from './uploaded-file';
import { needToCheckProp } from './common';
import {MaterialScrollWrapper} from 'components/layout/scroll_wrapper/material-scroll-wrapper'

const styles = theme => ({
  list2: {
    maxHeight: '33vh',
    backgroundColor: theme.palette.background.paper
  },
  list1: {
    maxHeight: '65vh',
    backgroundColor: theme.palette.background.paper
  },
  listSubHeader: {
    backgroundColor: 'inherit',
    fontWeight: 'bold',
    color: theme.palette.primary.text
  }
});

class UploadedFileList extends Component {
  state = {
    deleteDialogOpen: false,
    fileNameToDelete: '',
    replaceDialogOpen: false,
    fileNameToReplace: ''
  };
  replaceFiles = [];
  handleDelete = fileIndex => {
    this.fileIndexToDelete = fileIndex;
    const index = this.props.files.findIndex(f => f.index === fileIndex);
    const content = `Do you wish to delete - ${this.props.files[index].name}.`;
    this.props.onDialog(
      true,
      'Delete Uploaded File',
      content,
      ['Yes', 'No'],
      this.handleDeleteConfirmed
    );
  };
  handleDeleteConfirmed = option => {
    this.props.onDialog(false);
    if (option === 'Yes') this.props.onDelete(this.fileIndexToDelete);
  };
  handleReplace = (fileIndex, files) => {
    this.fileIndexToReplace = fileIndex;
    this.replaceFiles = files;
    const index = this.props.files.findIndex(f => f.index === fileIndex);
    const notSameFileName =
      this.replaceFiles.length !== 1 ||
      this.props.files[index].name !== this.replaceFiles[0].name ? (
        <div>
          {'with'}
          {this.replaceFiles.map((f, i) => (
            <div key={i}>{f.name}</div>
          ))}
        </div>
      ) : null;
    let content = (
      <div>
        Do you wish to replace - {this.props.files[index].name}
        {notSameFileName}
      </div>
    );
    this.props.onDialog(
      true,
      'Replace Uploaded File',
      content,
      ['Yes', 'No'],
      this.handleReplaceConfirmed
    );
  };
  handleReplaceConfirmed = option => {
    this.props.onDialog(false);
    if (option === 'Yes')
      this.props.onReplace(this.fileIndexToReplace, this.replaceFiles);
  };
  getListSubHeader = () => {
    const { gotFiles } = this.props;
    let subHeaderText = '';
    if (!gotFiles) subHeaderText = 'Loading...';
    else {
      subHeaderText +=
        this.props.files.length === 0 ? 'No' : this.props.files.length;
      subHeaderText += ' uploaded file';
      if (this.props.files.length !== 1) subHeaderText += 's';
      if (needToCheckProp(this.props.maxFiles))
        subHeaderText += ` (Max ${this.props.maxFiles})`;
    }
    return subHeaderText;
  };
  render() {
    const { classes } = this.props;
    return (
      <Card>
        <MaterialScrollWrapper>
          <List
            className={this.props.instantUpload ? classes.list1 : classes.list2}
            subheader={
              <ListSubheader className={classes.listSubHeader} component="div">
                {this.getListSubHeader()}
              </ListSubheader>
            }
          >
            {this.props.files.map(f => (
              <UploadedFile
                key={f.index}
                file={f}
                onDelete={this.handleDelete}
                onReplace={
                  this.props.onReplace !== null ? this.handleReplace : null
                }
                disabled={this.props.disableButtons}
                multiple={this.props.multiple}
              />
            ))}
          </List>
        </MaterialScrollWrapper>
      </Card>
    );
  }
}

export default withStyles(styles)(UploadedFileList);
