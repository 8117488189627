// npm
import * as Immutable from "immutable";

// redux
import * as ACTION_TYPES from "./action-types";
import { extractMessage } from "redux/reducers/exam/helpers";
import { SET_EXAM_DATA } from "redux/reducers/exam/action-types";
import * as WORKINGS_ACTION_TYPES from "./workings/action-types";

// redux (reducers)
import { TABLE_QUESTION_REDUCER } from "./table/reducer";
import { COMBO_QUESTION_REDUCER } from "./combo/reducer";
import { RADIOS_QUESTION_REDUCER } from "./radios/reducer";
import { FILL_IN_BLANKS_QUESTION_REDUCER } from "./blanks/reducer";
import { CHECKS_QUESTION_REDUCER } from "./checks/reducer";
import { MULTI_TEXT_QUESTION_REDUCER } from "./multi_text/reducer";
import { LONG_TEXT_QUESTION_REDUCER } from "./long_text/reducer";
import { MATCH_QUESTION_REDUCER } from "./match/reducer";
import { WORKINGS_REDUCER } from "./workings/reducer";
import { ASCX_QUESTION_REDUCER } from "./ascx/reducer";
import { ICT_QUESTION_REDUCER } from "./ict/reducer";
import { STATIC_TEXT_QUESTION_REDUCER } from "./static_text/reducer";
import { FORM_QUESTION_REDUCER } from "./form/reducer";
import { RADIOS_TEXT_QUESTION_REDUCER } from "./radios-text/reducer";

// constants
import * as QUESTION_TYPES from "constants/question-types";

// TEMPORARY HACK
import { SET_ASCX_HTML } from "./ascx/action-types";

import { getTimeStamp } from "utils/time-stamp";

import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";

const getQuestionReducer = (questionType) => {
    switch (questionType) {
        case QUESTION_TYPES.RADIOS:
        case QUESTION_TYPES.TRUE_OR_FALSE:
            return RADIOS_QUESTION_REDUCER;
        case QUESTION_TYPES.BLANKS:
            return FILL_IN_BLANKS_QUESTION_REDUCER;
        case QUESTION_TYPES.COMBO:
            return COMBO_QUESTION_REDUCER;
        case QUESTION_TYPES.CHECKS:
            return CHECKS_QUESTION_REDUCER;
        case QUESTION_TYPES.TABLE:
            return TABLE_QUESTION_REDUCER;
        case QUESTION_TYPES.MULTI_TEXT:
            return MULTI_TEXT_QUESTION_REDUCER;
        case QUESTION_TYPES.MATCH:
            return MATCH_QUESTION_REDUCER;
        case QUESTION_TYPES.LONG_TEXT:
            return LONG_TEXT_QUESTION_REDUCER;
        case QUESTION_TYPES.ASCX:
            return ASCX_QUESTION_REDUCER;
        case QUESTION_TYPES.ICT:
            return ICT_QUESTION_REDUCER;
        case QUESTION_TYPES.STATIC_TEXT:
            return STATIC_TEXT_QUESTION_REDUCER;
        case QUESTION_TYPES.FORM:
            return FORM_QUESTION_REDUCER;
        case QUESTION_TYPES.RADIOS_TEXT:  
            return RADIOS_TEXT_QUESTION_REDUCER;             
        default:
            throw "Unknown question type";
    }
};

const extractCommonQuestionProperties = (data, number) => ({
    number,
    id: data["@ID"],
    marks: data["@Marks"],
    type: data["@QuestionType"],
    dragAndDrop: data["@DragAndDrop"],
    resourceUrl: data["@ResourceURL"],
    requiresWorkings: data["@ShowWorkings"] === "True",
    requiresCalculator: data["@ShowCalculator"] === "True",
    text: extractMessage(data.TEXT || {}) || "",
    introText: extractMessage(data.INTROTEXT || {}) || "",
    disabled: data["@Disabled"] === "True",
    bookmarked: false, // temp
    versionId: data["@VersionID"] == null ? null : data["@VersionID"], 
    versionNumber: data["@VersionNumber"] == null ? null : data["@VersionNumber"], 
});

const handleSetExamData = (action) => {
    // 1. Build up common question properties
    const { data, number } = action;
    const commonProperties = extractCommonQuestionProperties(data, number);
    let state = Immutable.Map(commonProperties);

    // 2. Build up workings data (may exist if parent section requires workings)
    state = state.set("workings", WORKINGS_REDUCER(undefined, action));

    // 3. Delegate to concrete question reducer for further initialization
    const questionReducer = getQuestionReducer(data["@QuestionType"]);
    return questionReducer(state, action);
};

const QUESTION_REDUCER = (state = Immutable.Map(), action) => {
    if (action.type === SET_EXAM_DATA) {
        return handleSetExamData(action);
    }

    switch (action.type) {
        case ACTION_TYPES.SET_LOCAL_ANSWER: {
            activityLogger.log(ACTIVITIES.LOCAL_ANSWER, action);
            return state.set("localAnswer", action.value);
        }
        case ACTION_TYPES.SET_SERVER_ANSWER: {
            if (action.value){
                action.value.ClientTime=getTimeStamp();
            }
            activityLogger.log(ACTIVITIES.SERVER_ANSWER, action);
            return state.set("serverAnswer", action.value);
        }
        case ACTION_TYPES.TOGGLE_BOOKMARK:
            return state.set("bookmarked", !state.get("bookmarked"));
        case WORKINGS_ACTION_TYPES.CHANGE:
        case WORKINGS_ACTION_TYPES.ADD: {
            activityLogger.log(ACTIVITIES.WORKINGS, action);
            var workingsState = state.get("workings");
            return state.set(
                "workings",
                WORKINGS_REDUCER(workingsState, action)
            );
        }
        case SET_ASCX_HTML:
            return ASCX_QUESTION_REDUCER(state, action);
        default:
            return state;
    }
};

export { QUESTION_REDUCER };
