
// redux (action-types)
import {SET_NETWORK_ERROR} from 'redux/reducers/app/action-types'


const setNetworkError = (value) => ({
	type: SET_NETWORK_ERROR,
	value
});


export {setNetworkError}