
// utils
import {api} from '../api'
import { activityLogger} from "libs/activity_logger/activity-logger";


const setActivityLogger=(value)=>{
	const numValue = parseInt(value);
	if (!isNaN(numValue) && numValue>0 && numValue<3){
		activityLogger.setMode(numValue);
	}
}

const getConfig = () =>
{
	return api.get("/cosmog.json").then((response) => {
        const parsedResponse = JSON.parse(response);

		api.EndpointUrl = parsedResponse.apiEndpointUrl;
		api.HubpointUrl = parsedResponse.hubEndpointUrl;
        api.AuthenticationUrl = parsedResponse.authenticationUrl;

        if (parsedResponse.activityLogger){
            setActivityLogger(parsedResponse.activityLogger);
		}
		
        return parsedResponse;
    });
}


const configApi = {
	getConfig
}

export {configApi};