import {containsId} from './mapping/selectors'



// Selector functions
// ---------------------------------------------------------------------------

const getMappingDataById = (mappingsData) => (id) => {
	return mappingsData.get(id);
}

const getChildrenForId = (mappingsData) => (id) => {
	const mappingData = getMappingDataById(mappingsData)(id);
	return mappingData ? mappingData.toArray() : [];
}

const getParentSectionId = (mappingsData) => (childId) => {
	return mappingsData.findKey(mapping => containsId(mapping)(childId));
}


export {
	getMappingDataById,
	getChildrenForId,
	getParentSectionId
}