// internal
import { storedUsers } from "./users";
import { storedExams } from "./exams";
import { requestLogMappings } from "./request-log-mappings";
import { localStorageApi, KEYS } from "../local-storage-api";

// xams-utils
import { check } from "@xams-utils/check-types";

// custom
import { Stack } from "custom/stack";
import moment from "moment";

//import {tempShowLocalStorage, tempUpdateLocalStorage, showLogDate} from './network-request-log-test'

const MAX_POPS = 10;

const dateHasExpired = (item, expired = 30) => {
    if (!item) return false;

    const timeStamp = item.item ? item.item.timeStamp : item.timeStamp;

    if (!timeStamp) return false;

    const date = moment.utc(timeStamp);
    const expiredDate = moment().subtract(expired, "days");

    return date < expiredDate;
};

const hasRequestLogGotActivities = (requestLog) => {
    if (!requestLog.payload) return false;
    if (!requestLog.endpoint) return false;

    return (
        requestLog.payload.activities &&
        requestLog.endpoint.indexOf("activitylog")!==-1
    );
};

const removeActivitiesFromRequestLog = (_requestLog) => {
    const requestLog = {};

    Object.keys(_requestLog).forEach(key => {
        if (key === 'payload') {
            const payload = {};
            Object.keys(_requestLog.payload).forEach(key => {
                if (key !=='activities') payload[key] = _requestLog.payload[key];
            });
            requestLog.payload = payload;
        }
        else requestLog[key] = _requestLog[key];
    });

    return {...requestLog};
};

class NetworkRequestLog {
    constructor() {
        const logs = localStorageApi.retrieveDataFrom(KEYS.NETWORK_REQUEST_LOG);
        let logStack = check.nonEmptyObject(logs)
            ? Stack.FromStack(logs)
            : new Stack();
        const initialized = { value: false };

        const initialize = () => {
            const lastItem = logStack.BackItem;
            const firstItem = logStack.FrontItem;

            if (dateHasExpired(firstItem)) {
                removeAllLogs();
            } else if (dateHasExpired(lastItem)) {
                removeExpiredLogs();
            }

            initialized.value = true;
        };

        const removeAllLogs = () => {
            logStack = new Stack();
            saveToLocalStorage();

            requestLogMappings.removeAll();
            storedExams.removeAll();
            storedUsers.removeAll();
        };

        const removeExpiredLogs = () => {
            let poppedItems = [];

            while (true) {
                const lastItem = logStack.BackItem;
                if (!lastItem) break;
                if (dateHasExpired(lastItem)) {
                    const poppedItem = logStack.pop();
                    if (!poppedItem) break;
                    poppedItems = poppedItems.concat([poppedItem]);
                } else {
                    break;
                }
            }

            if (poppedItems.length > 0) {
                saveToLocalStorage();

                let userGuids = [];
                let examGuids = [];

                poppedItems.forEach((item) => {
                    requestLogMappings.removeMappingReference(item.id, false);

                    if (userGuids.indexOf(item.userGuid) === -1) {
                        userGuids = userGuids.concat([item.userGuid]);
                    }
                    if (userGuids.indexOf(item.examGuid) === -1) {
                        examGuids = examGuids.concat([item.examGuid]);
                    }
                });

                requestLogMappings.save();

                userGuids.forEach((userGuid) => {
                    tryRemoveAssociatedUser(userGuid);
                });

                examGuids.forEach((examGuid) => {
                    tryRemoveAssociatedExam(examGuid);
                });
            }
        };

        const tryRemoveAssociatedExam = (guid) => {
            if (!guid) {
                return;
            }
            if (!requestLogMappings.containsExam(guid)) {
                storedExams.remove(guid);
            }
        };

        const tryRemoveAssociatedUser = (guid) => {
            if (!guid) {
                return;
            }
            if (!requestLogMappings.containsUser(guid)) {
                storedUsers.remove(guid);
            }
        };

        const removeExcessLogs = (doubleIt = false) => {
            let counter = 0;
            const counterMax = 100;
            let poppedItems = [];
            const max = doubleIt ? MAX_POPS * 2 : MAX_POPS;

            while (true) {

                while (true) {
                    for (let i = 0; i < max; i++) {
                        const poppedItem = logStack.pop();
                        if (poppedItem) {
                            poppedItems = poppedItems.concat([poppedItem]);
                        } else break;
                    }
                    try {
                        localStorageApi.saveDataTo(
                            KEYS.NETWORK_REQUEST_LOG,
                            logStack
                        );
                        break;
                    } catch (e) {}
                }

                poppedItems.forEach((poppedItem) => {
                    requestLogMappings.removeMappingReference(poppedItem.id, false);
                    tryRemoveAssociatedUser(poppedItem.userGuid);
                    tryRemoveAssociatedExam(poppedItem.examGuid);
                });

                if (requestLogMappings.save()){
                    break;
                }

                counter++;
                if (counter > counterMax) {
                    this.removeAllLogs();
                    break;
                }
            }
        };

        const saveToLocalStorage = () => {
            while (true) {
                try {
                    localStorageApi.saveDataTo(
                        KEYS.NETWORK_REQUEST_LOG,
                        logStack
                    );
                    break;
                } catch (e) {
                    removeExcessLogs();
                }
            }
        };

        this.push = (id, _requestLog) => {
            if (!initialized.value) initialize();

            const requestLog = hasRequestLogGotActivities(_requestLog)
                    ? removeActivitiesFromRequestLog({ ..._requestLog })
                    : _requestLog;

            while (true) {
                try {
                    requestLogMappings.createMapping(id, requestLog);
                    break;
                } catch (e) {
                    removeExcessLogs();
                }
            }

            const { status, response, responseHeaders, timeStamp } = requestLog;
            logStack.push({ id, status, response, responseHeaders, timeStamp });

            saveToLocalStorage();
        };

        this.dangerously_getLogStack = () => {
            return logStack;
        };

        this.clearSpace = () => {
            removeExcessLogs(true);
        };
    }
}

const networkRequestLog = new NetworkRequestLog();
export { networkRequestLog };
