// Responsibility:
// To throw a suitable error if any type check fails

import check from 'check-types'



const TypeException = function(value)
{
	const type = typeof value;
	const name = value.name;
	const error = `Unexpected type for '${name}': [${type}]`;

	this.name = "Type Validation Error";
	this.message = {type, name, value, error};
}



const validateAsObject = (obj) =>
{
	if (!check.object(obj)) { throw new TypeException(obj); }
}

const validateAsString = (str) =>
{
	if (!check.string(str)) { throw new TypeException(str); }
}

const validateAsArray = (arr) =>
{
	if (!check.array(arr)) { throw new TypeException(arr); }
}

const validateAsBool = (bool) =>
{
	if (!check.bool(bool)) { throw new TypeException(bool); }
}

const validateAsNumber = (num) =>
{
	if (!check.number(num)) { throw new TypeException(num); }
}


Object.freeze(validateAsObject);
Object.freeze(validateAsString);
Object.freeze(validateAsArray);
Object.freeze(validateAsBool);
Object.freeze(validateAsNumber);


export {
	validateAsNumber, 
	validateAsBool, 
	validateAsArray, 
	validateAsString, 
	validateAsObject
};