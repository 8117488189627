
// custom
import {Stack} from 'custom/stack'

// xams-utils
import {check} from '@xams-utils/check-types'

// utils
import {networkRequestLog} from 'libs/browser_storage/apis/network-request-log'
import {requestLogMappings} from 'libs/browser_storage/apis/request-log-mappings'


// ---------------------------------------------------------------------------
// PRIVATE FUNCTIONS
// ---------------------------------------------------------------------------

const validateQueryType = (query) =>
{
	if (!check.object(query)) {
		throw "queryLogs: query must be an object";
	}

	const {userGuid} = query;
	if (check.assigned(userGuid) && !check.string(userGuid)) {
		throw "queryLogs: userGuid must be a valid string";
	}

	const {examGuid} = query;
	if (check.assigned(examGuid) && !check.string(examGuid)) {
		throw "findExamRequests: examGuid must be a valid string";
	}

	const {minDt} = query;
	if (check.assigned(minDt) && !check.date(minDt)) {
		throw "findExamRequests: minDt must be a valid date";
	}

	const {maxDt} = query;
	if (check.assigned(maxDt) && !check.date(maxDt)) {
		throw "findExamRequests: maxDt must be a valid date";
	}
}


const matchesUser = (requestData, userGuid) =>
{
	if (!userGuid) { return true; }
	return requestData.userGuid === userGuid;
}

const matchesExam = (requestData, examGuid) =>
{
	if (!examGuid) { return true; }
	return requestData.examGuid === examGuid;
}

const matchesDate = (request, minDate, maxDate) =>
{
	if (!minDate && !maxDate) {
		return true;
	}

	minDate = new Date(minDate);
	maxDate = new Date(maxDate);
	const requestDate = new Date(request.timeStamp);

	if (minDate && requestDate < minDate) { return false; }
	if (maxDate && requestDate > maxDate) { return false; }

	return true;
}


const queryLogs = function*(query)
{
	validateQueryType(query);

	for (let request of getRequestLogStack().generateElements()) {
		const requestData = requestLogMappings.getMapping(request.id);
		if (!requestData) { continue; }

		if (matchesUser(requestData, query.userGuid)) {
			if (matchesExam(requestData, query.examGuid)) {
				if (matchesDate(request, query.minDt, query.maxDt)) {
					yield {...request, ...requestData};
				}
			}
		}
	}
}


const getRequestLogStack = () =>
{
	return networkRequestLog.dangerously_getLogStack();
}


// ---------------------------------------------------------------------------
// PUBLIC INTERFACE
// ---------------------------------------------------------------------------

const findUserActivityByQuery = (query={}) =>	// returns 'userActivity' data for 'BugReport' api call
{
	const userActivity = [];
	const userActivityByGuid = {};

	for (let loggedRequest of queryLogs(query)) {
		const {userGuid} = loggedRequest;
		if (userActivityByGuid.hasOwnProperty(userGuid)) {
			var userActivityItem = userActivityByGuid[userGuid];
		}
		else {
			var userActivityItem = {userGuid, requests: []};
			userActivityByGuid[userGuid] = userActivityItem;
			userActivity.push(userActivityItem);
		}

		const {payload} = loggedRequest;
		const userRequest = {
			endpoint: loggedRequest.endpoint,
			payload: payload ? JSON.stringify(payload) : null,
			timeStamp: loggedRequest.timeStamp,
			status: String(loggedRequest.status),
			response: loggedRequest.response,
			responseHeaders: loggedRequest.responseHeaders
		};

		userActivityItem.requests.push(userRequest);
	}

	return userActivity;
}

const findUserGuidsByQuery = (query={}) =>
{
	const uniqueUserGuids = new Set();

	for (let loggedRequest of queryLogs(query)) {
		uniqueUserGuids.add(loggedRequest.userGuid);
	}

	return [...uniqueUserGuids];
}

const findExamGuidsByQuery = (query={}) =>
{
	const uniqueExamGuids = new Set();

	for (let loggedRequest of queryLogs(query)) {
		if (loggedRequest.examGuid) {
			uniqueExamGuids.add(loggedRequest.examGuid);
		}
	}

	return [...uniqueExamGuids];
}


export {
	findUserActivityByQuery,
	findUserGuidsByQuery,
	findExamGuidsByQuery
}