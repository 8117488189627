import { check } from "@xams-utils/check-types";
import { stringify } from "uuid";

const parseObjects = (objects, a) => {
    return objects.reduce((b, item) => {
        const n = parseObject(item);
        return b.concat(n);
    }, a);
};
const parseObject = (item) => {
    const { property, data } = item;
    const a = [];

    if (data) {
        a.push(["", ""], [property, ""]);

        for (const property in data) {
            try {
                const value = data[property];
                var first = property.charAt(0);

                if (
                    first === first.toLowerCase() &&
                    first !== first.toUpperCase()
                ) {
                    if (check.string(value) || check.number(value)) {
                        a.push([property, value]);
                    }
                    //console.log(property, value);
                } else if (check.object(value)) {
                    try {
                        a.push([property, JSON.stringify(value)]);
                    } catch (e) {
                        //debugger;
                    }
                }
            } catch (e) {}
        }
    }

    return a;
};

const getErrorInfo = (networkErrorInfo, appVersion) => {
    if (!networkErrorInfo) return null;

    const checkObjects = [];
    let errorInfo = [];

    for (const property in networkErrorInfo) {
        if (check.object(networkErrorInfo[property]) || property === "error") {
            checkObjects.push({ property, data: networkErrorInfo[property] });
        } else {
            errorInfo.push([property, networkErrorInfo[property]]);
        }
    }

    errorInfo = parseObjects(checkObjects, errorInfo);

    if (errorInfo.length > 0) {
        if (appVersion) {
            errorInfo.unshift(["appVersion", appVersion]);
        }
    }

    return errorInfo.length > 0 ? errorInfo : null;
};

export { getErrorInfo };
