import {getSectionIds, getSectionDataById} from 'redux/reducers/exam/content/sections/selectors'
import {getQuestionIds, getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {getSectionsData, getQuestionsData, getResourceUrl} from 'redux/reducers/exam/content/selectors'
import {getResourceUrl as getSectionResourceUrl} from 'redux/reducers/exam/content/sections/section/selectors'
import {getResourceUrl as getQuestionResourceUrl} from 'redux/reducers/exam/content/questions/question/selectors'

const getResourceUrls = (contentData)=>{
	const resourceUrls = [];

	const rootResourceUrl = getResourceUrl(contentData);
	if (rootResourceUrl) { resourceUrls.push(rootResourceUrl); }

	const sectionsData = getSectionsData(contentData);
	const sectionIds = getSectionIds(sectionsData);
	sectionIds.forEach(id => {
		const sectionData = getSectionDataById(sectionsData)(id);
		const resourceUrl = getSectionResourceUrl(sectionData);
		if (resourceUrl) { resourceUrls.push(resourceUrl); }
	});

	const questionsData = getQuestionsData(contentData);
	const questionIds = getQuestionIds(questionsData);
	questionIds.forEach(id => {
		const questionData = getQuestionDataById(questionsData)(id);
		const resourceUrl = getQuestionResourceUrl(questionData);
		if (resourceUrl) { resourceUrls.push(resourceUrl); }
	});

	return resourceUrls;
}

export {
	getResourceUrls
}