async function tryAtMost(asyncFunction, maxAttempts, handleError, checkRequestError=null)
{
	let latestError = null;

	for (let i = 1; i <= maxAttempts; i++)
	{
		try 
		{
			const response = await asyncFunction();
			return response;
		}
		catch (asyncError)
		{
			latestError = asyncError;
			if (checkRequestError && checkRequestError(asyncError)){
				break;
			}
			await handleError(asyncError);
		}
	}

	throw latestError;
}


export {tryAtMost};