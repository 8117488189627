import React, { Component } from 'react';
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles';

import {
  getFiles,
  deleteFile,
  uploadFiles,
  replaceFiles
} from './services/upload-service';
import { needToCheckProp, getFirstFileType, getFileExtension } from './common';
import FilesToUploadList from './files-to-upload-list';
import UploadedFileList from './uploaded-file-list';
import CommonDialog from './common-dialog';

const styles = theme => ({
  uploadPanel: {
    backgroundColor: theme.palette.grey[300],
    width: '350px',
    height: 'calc(100% - 40px)',
    padding: '20px'
  }
});
const UPLOADED = 'uploaded';
const TOUPLOAD = 'toupload';
const INVALID_FILE_TYPE = 1;
const FILE_TOO_BIG = 2;
const FILE_TOO_SMALL = 3;
const FILE_DUPLICATE = 5;

const isSameFileExtension = (fileExtension1, fileExtension2) => (
  fileExtension1.toLowerCase() === fileExtension2.toLowerCase()
);

const matchesFileType = (file, fileType) => {
  if (file.type === fileType) {
      return true;
  }

  const fileExtension = getFileExtension(file.name);
  const allowedFileExtension = fileType.startsWith(".")
      ? fileType.substring(1)
      : fileType;
  if (isSameFileExtension(fileExtension, allowedFileExtension)) {
      return true;
  }

  const isFileType = fileType.length > 2 && fileType.slice(-2) === "/*";
  if (!isFileType) {
      return false;
  }
  const actualFileType1 = getFirstFileType(fileType);
  const actualFileType2 = getFirstFileType(file.type);
  return isSameFileExtension(actualFileType1, actualFileType2);
};

const fileTypeOk = (file, accepts) => {
  if (!needToCheckProp(accepts)) {
      return true;
  }

  const isFileTypeOk = accepts.some((acceptedFileExtension) => {
      return matchesFileType(file, acceptedFileExtension);
  });

  return isFileTypeOk;
};

const fileMinSizeOk = (fileSize, minFileSize) =>
  !needToCheckProp(minFileSize) || fileSize >= minFileSize;
const fileMaxSizeOk = (fileSize, maxFileSize) =>
  !needToCheckProp(maxFileSize) || fileSize < maxFileSize;

class UploadPanel extends Component {
  dropRef = React.createRef();
  events = [
    'drag',
    'dragstart',
    'dragend',
    'dragover',
    'dragenter',
    'dragleave',
    'drop'
  ];
  checkUpload = {
    index: 0,
    files: [],
    uploads: [],
    replace: []
  };
  state = {
    uploadedFiles: [],
    filesToUpload: [],
    uploadProgress: -1,
    disableButtons: false,
    disableUpload: false,
    disableUploading: false,
    gotFiles: false,
    commonDialog: { open: false, title: '', content: '', options: [] }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    this.events.forEach(e => div.addEventListener(e, this.handleDragEvent));
    this.fileToUploadIndex = 0;
    getFiles(this.props.formRunGuid, this.updateFiles, this.getFilesFailed);
  }
  updateFiles = files => {
    this.setState({
      uploadedFiles: files,
      gotFiles: true
    });
  };
  getFilesFailed = files => {
    const content = 'There was a problem getting the uploaded files.';
    this.handleDialog({
      open: true,
      title: 'Unable to get Uploaded Files',
      content,
      options: ['Ok'],
      data: { files },
      onSelect: this.getFilesFailedConfirmed
    });
  };
  getFilesFailedConfirmed = (confirmed, index, data) => {
    this.handleDialog({
      open: false
    });
    this.updateFiles(data.files);
  };
  componentWillUnmount() {
    let div = this.dropRef.current;
    this.events.forEach(e => div.removeEventListener(e, this.handleDragEvent));
  }
  handleDragEvent = e => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };
  handleAddFilesToUpload = files => {
    const { maxFilesToUpload: maxFiles, instantUpload } = this.props;
    const newFiles = [...this.state.filesToUpload];
    this.filesToUploadErrors = {
      index: 0,
      errors: []
    };
    const fileCount = this.props.multiple ? files.length : 1;
    for (let i = 0; i < fileCount; i++) {
      if (maxFiles > 0 && newFiles.length >= maxFiles) {
        this.filesToUploadErrors.errors.push({ errorCode: 4, file: files[i] });
        break;
      }
      let errorCode = this.checkFileOkToUpload(files[i]);
      if (errorCode !== 0)
        this.filesToUploadErrors.errors.push({ errorCode, file: files[i] });
      else {
        newFiles.push({ index: this.fileToUploadIndex, file: files[i] });
        this.fileToUploadIndex++;
      }
    }//                                NUMBER (0, ANY ELSE)   BOOLEAN (TRUE/FALSE)
    const disableUpload = maxFiles > 0 && newFiles.length === maxFiles;
    this.setState({
      filesToUpload: newFiles,
      disableUpload
    });
    if (this.filesToUploadErrors.errors.length > 0)
      this.handleFilesToUploadError();
    else if (instantUpload && newFiles.length > 0) this.handleUpload(newFiles);
  };
  checkFileOkToUpload = file => {
    const { maxFileSize, minFileSize, accepts, rejects } = this.props;

    if (!fileTypeOk(file, accepts)) return INVALID_FILE_TYPE;
    if (rejects.length > 0 && fileTypeOk(file, rejects))
      return INVALID_FILE_TYPE;
    if (!fileMinSizeOk(file.size, minFileSize)) return FILE_TOO_SMALL;
    if (!fileMaxSizeOk(file.size, maxFileSize)) return FILE_TOO_BIG;
    if (this.fileNameExists(file.name, TOUPLOAD) !== -1) return FILE_DUPLICATE;
    return 0;
  };
  fileNameExists = (newFileName, typeOfFilesToCheck) => {
    const isUpload = typeOfFilesToCheck === UPLOADED;
    const files = isUpload
      ? this.state.uploadedFiles
      : this.state.filesToUpload;
    for (let i = 0; i < files.length; i++) {
      const checkFile = isUpload ? files[i].name : files[i].file.name;
      if (checkFile === newFileName) return isUpload ? files[i].index : i;
    }
    return -1;
  };
  handleFilesToUploadError = () => {
    const { maxFileSize, minFileSize } = this.props;
    const error = this.filesToUploadErrors.errors[
      this.filesToUploadErrors.index
    ];
    let title = 'Error';
    let content = error.file.name + ' ';
    if (error.errorCode === INVALID_FILE_TYPE) {
      title = 'Invalid File Type';
      content += 'has an invalid file type';
    } else if (error.errorCode === FILE_TOO_BIG) {
      title = 'Maxiumum File Size';
      content = `The size of ${
        error.file.name
      } is greater than the maximum file size of ${maxFileSize}`;
    } else if (error.errorCode === FILE_TOO_SMALL) {
      title = 'Minimum File Size';
      content = `The size of ${
        error.file.name
      } must be greater than the minimum file size of ${minFileSize}`;
    }
    this.handleDialog(
      true,
      title,
      content,
      ['Ok'],
      this.handleFilesToUploadErrorConfirmed
    );
  };
  handleFilesToUploadErrorConfirmed = () => {
    this.handleDialog(false);
    this.filesToUploadErrors.index++;
    const { instantUpload } = this.props;
    const { filesToUpload } = this.state;
    if (this.filesToUploadErrors.index < this.filesToUploadErrors.errors.length)
      this.handleFilesToUploadError();
    else if (instantUpload && filesToUpload.length > 0) this.handleUpload();
  };
  // if (errorCodes.length === 1 && errorCodes[0].errorCode === 5) {
  //   this.replaceFile={...}
  //   const content = `Do you wish to wish to replace file - ${
  //     errorCodes[0].file.name
  //   }.`;
  //   this.props.onDialog(
  //     true,
  //     'Replace Uploaded File',
  //     content,
  //     ['Yes', 'No'],
  //     this.handleSameNameConfirmed
  //   );
  // }

  handleDeleteFileToUpload = (fileIndex = -1) => {
    let newFiles = [];
    if (fileIndex !== -1) {
      newFiles = [...this.state.filesToUpload];
      newFiles = newFiles.filter(f => f.index !== fileIndex);
    }
    this.setState({
      filesToUpload: newFiles,
      disableUpload: false
    });
  };
  handleReplaceUploadedFiles = (fileIndex, files) => {
    const index = this.state.uploadedFiles.findIndex(
      f => f.index === fileIndex
    );
    const file = this.state.uploadedFiles[index];
    replaceFiles(
      this.props.formRunGuid,
      file,
      index,
      files,
      f => {
        this.uploadedFileHasBeenDeleted(f);
      },
      p => {
        this.handleUploadProgress(p);
      },
      (f, s) => {
        this.handleFileUploaded(f, s);
      }
    );
  };
  handleDeleteUploadedFile = (fileIndex = -1) => {
    const index = this.state.uploadedFiles.findIndex(
      f => f.index === fileIndex
    );
    const file = this.state.uploadedFiles[index];
    this.setState({
      disableButtons: true,
      isDeleting: true
    });
    deleteFile(
      file,
      f => {
        this.uploadedFileHasBeenDeleted(f);
      },
      this.uploadedFileHasBeenDeletedFailed
    );
  };
  uploadedFileHasBeenDeleted = file => {
    let newFiles = [...this.state.uploadedFiles];
    newFiles = newFiles.filter(f => f.index !== file.index);
    this.setState({
      uploadedFiles: newFiles,
      disableButtons: false,
      isDeleting: false
    });
  };
  uploadedFileHasBeenDeletedFailed = file => {
    const content = `There was a problem deleting the file - ${file.name}.`;
    this.handleDialog({
      open: true,
      title: 'Unable to Delete File',
      content,
      options: ['Ok'],
      onSelect: this.uploadedFileHasBeenDeletedFailedConfirmed
    });
  };
  uploadedFileHasBeenDeletedFailedConfirmed = () => {
    this.setState({
      disableButtons: false,
      isDeleting: false,
      commonDialog: { open: false }
    });
  };
  handleUpload = (newFiles = null) => {
    const files = newFiles !== null ? newFiles : [...this.state.filesToUpload];
    if (
      needToCheckProp(this.props.maxUploadedFiles) &&
      files.length + this.state.uploadedFiles.length >
        this.props.maxUploadedFiles
    ) {
      const numberOfFilesToUpload =
        this.props.maxUploadedFiles - this.state.uploadedFiles.length;
      files.splice(numberOfFilesToUpload, files.length - numberOfFilesToUpload);
    }
    this.checkDuplicateFileNames(files);
  };
  checkDuplicateFileNames = files => {
    this.checkUpload = {
      checkIndex: 0,
      replaceIndex: 0,
      files,
      upload: [],
      replace: []
    };
    this.checkDuplicateFileName();
  };
  checkDuplicateFileName = () => {
    const file = this.checkUpload.files[this.checkUpload.checkIndex];
    const existingUploadedFileIndex = this.fileNameExists(
      file.file.name,
      UPLOADED
    );
    if (existingUploadedFileIndex !== -1) {
      this.checkUpload.existingUploadedFileIndex = existingUploadedFileIndex;
      const content = `Do you wish to wish to replace - ${file.file.name}.`;
      this.handleDialog(
        true,
        'Replace Uploaded File',
        content,
        ['Yes', 'No'],
        this.checkDuplicateFileNameConfirmed
      );
    } else this.checkDuplicateFileNameConfirmed();
  };
  checkDuplicateFileNameConfirmed = (confirmed = null) => {
    this.handleDialog(false);
    const file = this.checkUpload.files[this.checkUpload.checkIndex];
    if (confirmed === null) this.checkUpload.upload.push(file);
    else if (confirmed === 'Yes')
      this.checkUpload.replace.push({
        index: this.checkUpload.existingUploadedFileIndex,
        file
      });
    if (this.checkUpload.checkIndex === this.checkUpload.files.length - 1)
      this.handleUploadChecked();
    else {
      this.checkUpload.checkIndex++;
      this.checkDuplicateFileName();
    }
  };
  handleUploadChecked = () => {
    if (this.checkUpload.upload.length > 0) {
      this.setState({
        disableButtons: true
      });
      this.checkUpload.replaceIndex = -1;
      uploadFiles(
        this.props.formRunGuid,
        this.checkUpload.upload,
        p => {
          this.handleUploadProgress(p);
        },
        (f, s) => {
          this.handleFileUploaded(f, s);
        },
        this.handleFileUploadedFailed
      );
    } else if (this.checkUpload.replace.length > 0) this.handleCheckReplace();
  };
  handleCheckReplace = () => {
    const replace = this.checkUpload.replace[this.checkUpload.replaceIndex];
    this.handleReplaceUploadedFiles(replace.index, [replace.file.file]);
  };
  handleUploadProgress(progress) {
    this.setState({
      uploadProgress: progress
    });
  }
  handleFileUploaded = (uploadObject, uploadComplete = false) => {
    this.handleDeleteFileToUpload(uploadObject.fileToUploadIndex);
    let newFiles = [...this.state.uploadedFiles];
    if (uploadObject.position === -1) newFiles.push(uploadObject.uploadedFile);
    else newFiles.splice(uploadObject.position, 0, uploadObject.uploadedFile);
    const newProgress = uploadComplete ? -1 : this.state.uploadProgress;
    const disableButtons = !uploadComplete;
    const { maxUploadedFiles } = this.props;
    const disableUploading =
      maxUploadedFiles > 0 && newFiles.length >= maxUploadedFiles;
    this.setState({
      uploadedFiles: newFiles,
      uploadProgress: newProgress,
      disableButtons,
      disableUploading
    });
    if (this.checkUpload.replace.length > 0) {
      this.checkUpload.replaceIndex++;
      if (this.checkUpload.replaceIndex < this.checkUpload.replace.length)
        this.handleCheckReplace();
    }
  };
  handleFileUploadedFailed = uploadObject => {
    const content = `There was a problem uploading the file - ${
      uploadObject.name
    }.`;
    this.handleDialog({
      open: true,
      title: 'Unable to Upload File',
      content,
      options: ['Ok'],
      data: { uploadObject },
      onSelect: this.handleFileUploadedFailedConfirmed
    });
  };
  handleFileUploadedFailedConfirmed = (confirmed, index, data) => {
    this.handleDeleteFileToUpload(data.uploadObject.fileToUploadIndex);
    this.setState({
      uploadProgress: -1,
      disableButtons: false,
      disableUploading: false,
      commonDialog: { open: false }
    });
  };
  handleDialog = (
    isOpen = false,
    title = '',
    content = '',
    options = [],
    onSelect = null
  ) => {
    const commonDialog =
      typeof isOpen === 'boolean'
        ? {
            open: isOpen,
            title: title,
            content: content,
            options: options,
            onSelect: onSelect
          }
        : isOpen;
    this.setState({
      commonDialog
    });
  };

  render()
  {
    const {classes, multiple, maxUploadedFiles, instantUpload} = this.props;
    const pointerEvents = this.props.disabled ? 'none' : undefined;
    const {uploadPanel} = classes;

    return (
      <div ref={this.dropRef} style={{pointerEvents}} className={uploadPanel}>
        <FilesToUploadList
          files={this.state.filesToUpload}
          onAddFilesToUpload={this.handleAddFilesToUpload}
          onUpload={this.handleUpload}
          onDelete={this.handleDeleteFileToUpload}
          uploadProgress={this.state.uploadProgress}
          multiple={multiple}
          disabled={this.state.disableUpload}
          disableUploading={this.state.disableUploading}
          isDeleting={this.state.isDeleting}
          instantUpload={instantUpload}
        />
        <br />
        <UploadedFileList
          files={this.state.uploadedFiles}
          onDelete={this.handleDeleteUploadedFile}
          disableButtons={this.state.disableButtons}
          multiple={multiple}
          maxFiles={maxUploadedFiles}
          onReplace={
            this.props.replaceFiles ? this.handleReplaceUploadedFiles : null
          }
          instantUpload={instantUpload}
          onDialog={this.handleDialog}
          gotFiles={this.state.gotFiles}
        />
        <CommonDialog {...this.state.commonDialog} />
      </div>
    );
  }
}

UploadPanel.propTypes = {
  disabled: PropTypes.bool,
  instantUpload: PropTypes.bool.isRequired,
  replaceFiles: PropTypes.bool.isRequired,
  multiple: PropTypes.bool.isRequired,
  maxFilesToUpload: PropTypes.number.isRequired,
  maxUploadedFiles: PropTypes.number.isRequired,
  maxFileSize: PropTypes.number.isRequired,
  minFileSize: PropTypes.number.isRequired,
  accepts: PropTypes.arrayOf(PropTypes.string).isRequired,
  rejects: PropTypes.arrayOf(PropTypes.string).isRequired
}

UploadPanel.defaultProps = {
  instantUpload: false,
  replaceFiles: false,
  multiple: false,
  maxFilesToUpload: 0,
  maxUploadedFiles: 0,
  maxFileSize: 0,
  minFileSize: 0,
  accepts: [],
  rejects: []
};

UploadPanel = withStyles(styles)(UploadPanel);

// Export
// -------------------------------------------------
export { UploadPanel };
