
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {SideDrawer} from './side/drawer'
import {ContentBox} from './content/content-box'
import {ContentBar} from './content/content-bar'
import {ContentPanel} from './content/content-panel'


class MobileExamLayout extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {leftDrawerOpen: false, rightDrawerOpen: false};
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.toggleDrawer = (side) => () => {
			const stateProperty = `${side}DrawerOpen`;
			this.setState({[stateProperty]: !this.state[stateProperty]});
		}

		this.closeDrawers = () => {
			this.setState({leftDrawerOpen: false, rightDrawerOpen: false});
		}
	}

	render()
	{
		return (
			<React.Fragment>
				{this.getDrawer('left')}
				<ContentBox>
					{this.ContentBar}
					{this.ContentPanel}
				</ContentBox>
				{this.getDrawer('right')}
			</React.Fragment>
		);
	}

	get ContentBar()
	{
		const contentBarProps = {};

		if (this.props.left) {
			contentBarProps.left = {
				Icon: this.props.left.Icon,
				open: this.state.leftDrawerOpen,
				onClick: this.toggleDrawer('left')
			}
		}

		if (this.props.right) {
			contentBarProps.right = {
				Icon: this.props.right.Icon,
				open: this.state.rightDrawerOpen,
				onClick: this.toggleDrawer('right')
			}
		}

		return (
			<ContentBar {...contentBarProps}>
				{this.props.bar.content}
			</ContentBar>
		);
	}

	getDrawer(side)
	{
		const drawerConfig = this.props[side];
		if (!drawerConfig) { return null; }

		const drawerProps = {
			anchor: side,
			open: this.state[`${side}DrawerOpen`]
		}

		return <SideDrawer {...drawerProps}>{drawerConfig.content}</SideDrawer>;
	}

	get ContentPanel()
	{
		return <ContentPanel>{this.props.centre.content}</ContentPanel>;
	}
}

MobileExamLayout.propTypes = {
	bar: PropTypes.shape({
		content: PropTypes.node
	}),
	left: PropTypes.shape({
		content: PropTypes.node,
		prioritize: PropTypes.bool,
		Icon: PropTypes.instanceOf(React.Component)
	}),
	centre: PropTypes.shape({
		content: PropTypes.node
	}),
	right: PropTypes.shape({
		content: PropTypes.node,
		prioritize: PropTypes.bool,
		Icon: PropTypes.instanceOf(React.Component)
	})
}


export {MobileExamLayout}