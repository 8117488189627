
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MaterialText} from 'components/presentation/material-text'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {getIntroText} from 'redux/reducers/exam/content/questions/question/selectors'


// QuestionIntro (not connected to store/styles)
// ----------------------------------------

let QuestionIntro = ({text, classes}) =>
{
	if (!text) { return null; }

	return (
		<React.Fragment>
			<MaterialText className={classes.text}>{text}</MaterialText>
			<Divider/>
		</React.Fragment>
	)
}

QuestionIntro.propTypes = {
	text: PropTypes.string.isRequired
}


// QuestionIntro (connected to store)
// ----------------------------------------

const mapStoreToProps = (store, {questionId}) => {
	const contentData = getContentData(getExamData(store));
	const questionsData = getQuestionsData(contentData);
	const questionData = getQuestionDataById(questionsData)(questionId);
	return {text: getIntroText(questionData)};
};

QuestionIntro = connect(mapStoreToProps)(QuestionIntro);


// QuestionIntro (connected to styles)
// ----------------------------------------

const styles = ({palette}) => ({
	text: {
		color: palette.background.contrastText
	}
})

QuestionIntro = withStyles(styles)(QuestionIntro);


QuestionIntro.propTypes = {
	questionId: PropTypes.number.isRequired
}


// Export
// ----------------------------------------
export {QuestionIntro}