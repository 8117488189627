// npm
import React, { Component } from 'react';

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { MaterialText } from "components/presentation/material-text";

class IctAnswerForm extends Component {
    state = {};
	
	componentWillUnmount() {
        const { value, onChange } = this.props;
        if (value !== "") {
            onChange("visited");
        }
	}
	
    render() {
        const { classes } = this.props;
        return (
            <MaterialText className={classes.text}>
                {"Please upload your evidence"}
            </MaterialText>
        );
    }
}

// let IctAnswerForm = ({classes}) => (
// 	<MaterialText className={classes.text}>
// 		{"Please upload your evidence"}
// 	</MaterialText>
// )

const styles = ({ palette }) => ({
    text: { color: palette.background.contrastText },
});

IctAnswerForm = withStyles(styles)(IctAnswerForm);

export { IctAnswerForm };
