// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AppPageModifier } from "components/pages/app-page-modifier";

// react (hocs)
import { withExamRequestSaver } from "components/hocs/exam_request_saver";

// react
import { LoadingSpinner } from "components/presentation/loading-spinner";
import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";
import { displayActivityLogToConsole } from "libs/activity_logger/activity-logger-helper";

// redux (selectors)
import { isOfflineMode } from "redux/reducers/app/selectors";
import { getExamData, getAppData } from "redux/reducers/selectors";
import { getFormRunGuid } from "redux/reducers/exam/selectors";

// FinishActivityLogger (not connected to store)
// ----------------------------------------

class FinishActivityLogger extends React.Component {
    constructor(props) {
        super(props);

        this.timeoutTimer = 30 * 1000;
        this.handleTimeout = this.handleTimeout.bind(this);
    }

    componentDidMount() {
        const { formRunGuid, examRequestSaver, debugMode } = this.props;

        const onSuccess = () => {
            this.props.onComplete();
            // activityLogger.sent(formRunGuid).then(() => {
            //     this.props.onComplete();
            // });
        };

        const onFail = () => {
            this.props.onComplete();
        };

        if (!activityLogger.isLogging()) {
            onSuccess();
        } else {
            this.timer = setTimeout(this.handleTimeout, this.timeoutTimer);

            activityLogger.log(ACTIVITIES.FINISH_EXAM, {}).then(
                activityLogger.get(formRunGuid).then((activities) => {
                    if (!debugMode) {
                        const payload = { formRunGuid, activities };
                        examRequestSaver.saveActivityLog(payload, {
                            onSuccess,
                            onFail,
                        });
                    } else {
                        displayActivityLogToConsole(ACTIVITIES, {
                            activities,
                            exclude: [
                                ACTIVITIES.LOCAL_ANSWER,
                                ACTIVITIES.SERVER_ANSWER,
                            ],
                        });
                        onSuccess();
                    }
                })
            );
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleTimeout() {
        this.props.onComplete();
    }

    render() {
        const appBarProps = {
            title: "Completing Activity Log",
            loadingTitle: true,
            logo: true,
        };
        
        return (
            <React.Fragment>
                <AppPageModifier
                    id="completing-activity-log"
                    appBarProps={appBarProps}
                    loading
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

FinishActivityLogger.propTypes = {
    offline: PropTypes.bool.isRequired,
    formRunGuid: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onFail: PropTypes.func.isRequired,
};

// FinishActivityLogger (connected to store)
// ----------------------------------------

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const appData = getAppData(store);

    return {
        offline: isOfflineMode(appData),
        formRunGuid: getFormRunGuid(examData),
        debugMode: false,
    };
};

FinishActivityLogger = connect(mapStoreToProps)(FinishActivityLogger);
FinishActivityLogger = withExamRequestSaver(FinishActivityLogger);

// EXPORT
// ----------------------------------------
export { FinishActivityLogger };
