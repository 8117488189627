import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import { getFormValue, getFormSelected, addSpacesToName, insertVariablesIntoText } from "./form-helper";

import { MaterialText } from "components/presentation/material-text";

class FormPopulated extends Component {
    state = {};

    getFieldValue(fieldValue){
        const { value, seps } = this.props;
        
        const formValue = getFormValue(value, seps);
        const newValue=insertVariablesIntoText(fieldValue, formValue);

        return newValue;
    }

    displayPopulated() {
        const { value, seps } = this.props;
        const formValue = getFormValue(value, seps);
        const items = [];

        for (const property in formValue) {
            items.push([property, formValue[property]]);
        }

        if (!check.nonEmptyArray(items)){
            return null;
        }
        
        return (
            <div>
                <MaterialText>
                    <b>Populated:</b>
                </MaterialText>
                {check.nonEmptyArray(items)
                    ? items.map((item, index) => {
                          return (
                              <MaterialText key={index}>
                                  {addSpacesToName(item[0])} : {this.getFieldValue(item[1])}
                              </MaterialText>
                          );
                      })
                    : "None"}
            </div>
        );
    }

    displaySelected() {
        const { value, seps } = this.props;
        const formSelected = getFormSelected(value, seps);

        if (check.nonEmptyString(formSelected) && formSelected!=="finished") {
            return (
                <div style={{ marginBottom: "1em" }}>
                    <MaterialText>
                        <b>Selected:</b> {formSelected}
                    </MaterialText>
                </div>
            );
        }

        return null;
    }
    render() {
        return (
            <div
                style={{
                    display: "inline-block",
                    minWidth: "50%",
                    marginTop: "1em",
                    padding: "1em",
                    border: "1px black solid",
                    background: "gray",
                }}
            >
                {this.displaySelected()}
                {this.displayPopulated()}
            </div>
        );
    }
}

export { FormPopulated };
