
// npm
import React from 'react'

// react
import {DisableNavigationContext,	DisableAnsweringContext} from 'components/pages/exam/disabled-context'
import {ExamStateCard} from './exam-state-card'


const CombinedContextConsumer = ({ children }) => 
(
  <DisableNavigationContext.Consumer>
    {disableNavigation => (
      <DisableAnsweringContext.Consumer>
        {disableAnswering => children({ disableNavigation, disableAnswering })}
      </DisableAnsweringContext.Consumer>
      )}
  </DisableNavigationContext.Consumer>
);

const getExamStatePanel = (disableNavigation, disableAnswering) => 
{
  if (!disableAnswering && !disableNavigation) {
    return null;
  }
  const props = {
    disableNavigation, 
    disableAnswering
  }
  return <ExamStateCard {...props} />
}

const ExamStatePanel = () => 
{
  return (
  <CombinedContextConsumer>
    {({ disableNavigation, disableAnswering }) => getExamStatePanel(disableNavigation, disableAnswering)}
  </CombinedContextConsumer>
  );
}
 

export {ExamStatePanel}