import React, { Component } from "react";
import { withMessages } from "components/hocs/messages";

import withStyles from "@material-ui/core/styles/withStyles";
import { MaterialText } from "components/presentation/material-text";
import Avatar from "@material-ui/core/Avatar";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Typography from "@material-ui/core/Typography";

import { MESSAGE_IDS } from "constants/message-ids";
import { Button } from "@material-ui/core";

const styles = (theme) => ({
    root: {
        display: "flex",
        width: "100%",
        justifyContent: "left",
        "&>div": {
            flex: 1,
        },
        "&>div:first-child": {
            width: "50%",
        },
        "&>div:last-child": {
            marginLeft: theme.spacing.unit,
            width: `calc(100% - ${theme.spacing.unit * 2}px)`,
            display: "flex",
            flexDirection: "column",
        },
    },
    title: {
        display: "flex",
        justifyContent: "left",
        "&>div:nth-child(2)": {
            marginTop: ".5em",
            marginLeft: theme.spacing.unit * 2,
        },
    },
    message: {
        marginTop: theme.spacing.unit * 2,
    },
    image: {
        position: "relative",
        // "&>div:nth-child(1)":{
        //     position: "absolute",
        //     left: 0,
        //     top: 0,
        // },
        "&>div:nth-child(2)": {
            position: "absolute",
            left: 97,
            top: 250,
            width: 210,
            textAlign: "center",
            // border:"1px black solid"
        },
    },
    confirmation: {
        display: "flex",
        justifyContent: "right",
        marginTop: "auto",
    },
});

class PauseImage extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.showImage=false;

        this.handleConfirmation = this.handleConfirmation.bind(this);
    }

    displayImage() {
        if (!this.showImage){
            return null;
        }

        const { classes, confirmExamResume, messages } = this.props;
        const messageID=confirmExamResume ? MESSAGE_IDS.GENERAL.RESUME:MESSAGE_IDS.GENERAL.PAUSED;
        const text = confirmExamResume ? messages[MESSAGE_IDS.GENERAL.RESUME] : messages[MESSAGE_IDS.GENERAL.PAUSED];

        return (
            <div className={classes.image}>
                <div>
                    <img
                        src={process.env.PUBLIC_URL + "/images/pause/owl_1.jpg"}
                    />
                </div>
                <div>
                    <Typography variant="h3" gutterBottom>
                        {text}
                    </Typography>
                </div>
            </div>
        );
    }

    displayTitle() {
        const { classes, confirmExamResume, messages } = this.props;
        const title = confirmExamResume
            ? messages[MESSAGE_IDS.EXAM.CONFIRM_RESUME_TITLE]
            : messages[MESSAGE_IDS.EXAM.PAUSED_TITLE];
        const icon = confirmExamResume ? <PlayArrowIcon /> : <PauseIcon />;

        return (
            <div className={classes.title}>
                <div>
                    <Avatar>{icon}</Avatar>
                </div>
                <div>
                    <MaterialText>
                        <b>{title}</b>
                    </MaterialText>
                </div>
            </div>
        );
    }

    displayMessage() {
        const { confirmExamResume, messages, classes } = this.props;

        const message = confirmExamResume
            ? messages[MESSAGE_IDS.EXAM.CONFIRM_RESUME]
            : messages[MESSAGE_IDS.EXAM.PAUSED];

        return (
            <div className={classes.message}>
                <MaterialText>{message}</MaterialText>
            </div>
        );
    }

    displayConfirmation() {
        const { confirmExamResume, classes, messages } = this.props;
        
        if (!confirmExamResume) {
            return null;
        }

        const text=messages[MESSAGE_IDS.GENERAL.RESUME];
        const buttonProps = {
            variant: "contained",
            color: "primary",
            onClick: this.handleConfirmation,
        };

        return (
            <div className={classes.confirmation}>
                <Button {...buttonProps}>{text}</Button>
            </div>
        );
    }

    handleConfirmation() {
        const { onConfirmation } = this.props;

        onConfirmation();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {this.displayImage()}
                <div>
                    {this.displayTitle()}
                    {this.displayMessage()}
                    {this.displayConfirmation()}
                </div>
            </div>
        );
    }
}

PauseImage = withStyles(styles)(PauseImage);
PauseImage = withMessages(PauseImage);

export { PauseImage };
