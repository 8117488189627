// npm
import React from "react";
import PropTypes from "prop-types";
import check from "check-types";

// react
import { AnswerBox } from "./answer-box";
import { TableAnswerForm } from "../answer_forms/table";
import { isEmptyRow } from "../answer_forms/table-helper";

// redux (selectors)
import { getLocalAnswer } from "redux/reducers/exam/content/questions/question/selectors";
import {
    getId,
    getValues,
} from "redux/reducers/exam/content/questions/question/table/rows/row/selectors";
import {
    getRowsData,
    getResumedAnswer,
    getHasAnswerBox,
    getAnswerBoxSize,
    getAnswerBoxLabel,
} from "redux/reducers/exam/content/questions/question/table/selectors";

const hasLocalAnswerGotAnswerBox = (localAnswer) => {
    const lastRow =
        localAnswer.length > 0 ? localAnswer[localAnswer.length - 1] : null;

    return lastRow && check.assigned(lastRow.answerBox);
};

const getAnswerBoxFromLocalAnswer = (localAnswer) => {
    const lastRow =
        localAnswer.length > 0 ? localAnswer[localAnswer.length - 1] : null;

    return lastRow && check.assigned(lastRow.answerBox)
        ? lastRow.answerBox
        : null;
};
class TableAnswerBox extends React.Component {
    constructor(props) {
        super(props);
        this.setUpExtractionFunctions();
        this.setUpTransformationFunctions();
    }

    // extract information from questionData
    // --------------------------------------
    setUpExtractionFunctions() {
        this.extractInitialAnswer = () => {
            //const rowsData = getRowsData(this.props.data).slice(1);

            const _rowsData = getRowsData(this.props.data);
            const firstRow = { values: getValues(_rowsData.get(0)) };
            const rowsData = isEmptyRow(firstRow)
                ? _rowsData
                : _rowsData.slice(1);            

            const hasAnswerBox = getHasAnswerBox(this.props.data);

            const initialAnswer = rowsData
                .map((rowData) => {
                    const rowValues = getValues(rowData);
                    const values = rowValues.filter((x) => !x); // undefined value indicates an answer field
                    return { rowId: getId(rowData), values };
                })
                .toArray();

            return hasAnswerBox
                ? initialAnswer.concat([{ answerBox: null }])
                : initialAnswer;
        };

        this.extractResumedAnswer = () => {
            const hasAnswerBox = getHasAnswerBox(this.props.data);
            const _serverAnswer = getResumedAnswer(this.props.data);

            if (!_serverAnswer || _serverAnswer === "") {
                return undefined;
            }

            const serverAnswer = hasAnswerBox
                ? _serverAnswer.split("|")[0]
                : _serverAnswer;

            const initialAnswer = this.extractInitialAnswer();
            const resumedAnswer = hasAnswerBox
                ? initialAnswer.slice(0, initialAnswer.length - 1)
                : initialAnswer;
            const resumedAnswerBox = hasAnswerBox
                ? initialAnswer.slice(initialAnswer.length)
                : null;

            const valuesLengths = resumedAnswer.map((x) => x.values.length);
            serverAnswer.split(",").forEach((value, i) => {
                if (value === "") {
                    return;
                }
                let serverIndex = i;
                let initialAnswerIndex = 0;
                let valuesIndex = 0;
                while (serverIndex >= valuesLengths[initialAnswerIndex]) {
                    serverIndex -= valuesLengths[initialAnswerIndex];
                    initialAnswerIndex++;
                }
                valuesIndex = serverIndex;
                resumedAnswer[initialAnswerIndex].values[valuesIndex] = value;
            });

            if (hasAnswerBox) {
                const answerBox = _serverAnswer.split("|")[1];
                return resumedAnswer.concat([{ answerBox }]);
            } else return resumedAnswer;
        };

        this.extractFormData = () => {
            const formData = {
                rows: getRowsData(this.props.data)
                    .map((rowData) => ({
                        id: getId(rowData),
                        values: getValues(rowData),
                    }))
                    .toArray(),
            };

            const hasAnswerBox = getHasAnswerBox(this.props.data);

            if (hasAnswerBox) {
                const answerBoxSize = getAnswerBoxSize(this.props.data);
                const answerBoxLabel = getAnswerBoxLabel(this.props.data);

                formData.answerBox = { size: answerBoxSize, label: answerBoxLabel };
            }

            return formData;
        };
    }

    // convert between answer values
    // --------------------------------------
    setUpTransformationFunctions() {
        this.getFormValue = (formAnswer) => {
            const { rowId, index, value, answerBox } = formAnswer;
            const formValue = [...getLocalAnswer(this.props.data)]; // Caution in dealing with localAnswer when it is a plain object/

            if (answerBox) {
                formValue[formValue.length - 1].answerBox = value;
            } else {
                const rowFormValueIndex = formValue.findIndex(
                    (x) => x.rowId === rowId
                ); // array. We do not want modifications propogating to redux
                const rowFormValue = { ...formValue[rowFormValueIndex] };
                const rowValues = [...rowFormValue.values];
                rowValues[index] = value;
                rowFormValue.values = rowValues;
                formValue[rowFormValueIndex] = rowFormValue;
            }

            return formValue;
        };

        this.toFormValue = (localAnswer) => localAnswer;
        this.toLocalAnswer = (formValue) => formValue;

        this.toServerAnswer = (localAnswer) => {
            const answerText = [];
            const options = [];

            localAnswer.forEach(({ rowId: optionId, values }) => {
                if (optionId) {
                    options.push({
                        optionID: optionId,
                        optionText: values.join("|"),
                    });

                    if (values.length !== 0) {
                        answerText.push(...values);
                    }
                }
            });

            const rowAnswerText = answerText.join(",");
            const hasAnswerBox = hasLocalAnswerGotAnswerBox(localAnswer);
            const _answerText = hasAnswerBox
                ? `${rowAnswerText}|${getAnswerBoxFromLocalAnswer(localAnswer)}`
                : rowAnswerText;

            return { answerText: _answerText, options };
        };
    }

    render() {
        const answerBoxProps = {
            questionData: this.props.data,
            FormComponent: TableAnswerForm,
            toFormValue: this.toFormValue,
            getFormValue: this.getFormValue,
            toLocalAnswer: this.toLocalAnswer,
            toServerAnswer: this.toServerAnswer,
            extractFormData: this.extractFormData,
            extractInitialAnswer: this.extractInitialAnswer,
            extractResumedAnswer: this.extractResumedAnswer,
        };

        return <AnswerBox {...answerBoxProps} />;
    }
}

TableAnswerBox.propTypes = {
    data: PropTypes.object.isRequired,
};

export { TableAnswerBox };
