
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-components
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamIntroPage} from './page'
import {CommencedChecker} from './commenced-checker'
import {RedirectToExamQuestions} from './redirect-to-questions'
import {StartActivityLogger} from './start-activity-logger'
import {PublicExamPage} from './public-exam'
import { AudioCheckPage } from './audio_check/audio-check-page'

// machines
import {examIntro} from 'machines/exam-intro'

// constants
import { ACTIVITIES } from "libs/activity_logger/activity-logger";


const {EVENTS, STATES} = examIntro;


const ExamIntroPageMachine = ({examGuid}) =>
{
	return (
		<StateMachine machine={examIntro.machine}>
			<StateView>
				{{
					[STATES.ATTEMPTING_TO_RESUME]: () => (
						<StateControl onCommenced={EVENTS.CAN_RESUME_EXAM} onNotCommenced={EVENTS.CANT_RESUME_EXAM}>
							{(props) => <CommencedChecker {...props}/>}
						</StateControl>
					),
					[STATES.PUBLIC_EXAM]: () => (
						<StateControl onComplete={EVENTS.DISPLAY_INTRO}>
							{({onComplete}) => <PublicExamPage onComplete={onComplete}/>}
						</StateControl>
					),	
					[STATES.AUDIO_CHECK_SCREEN]: () => (
						<StateControl onAudioChecked={EVENTS.START_EXAM}>
							{({onAudioChecked}) => <AudioCheckPage onAudioChecked={onAudioChecked}/>}
						</StateControl>
					),																	
					[STATES.INTRO_SCREEN]: () => (
						<StateControl onExamStart={EVENTS.DISPLAY_AUDIO_CHECK}>
							{({onExamStart}) => <ExamIntroPage onExamStart={onExamStart}/>}
						</StateControl>
					),
					[STATES.STARTING_ACTIVITY_LOGGER]: () => (
						<StateControl onComplete={EVENTS.STARTED_ACTIVITY_LOGGER}>
							{(props) => <StartActivityLogger activityType={ACTIVITIES.START_EXAM} {...props}/>}
						</StateControl>
					),					
					[STATES.RESUMING_ACTIVITY_LOGGER]: () => (
						<StateControl onComplete={EVENTS.RESUMED_ACTIVITY_LOGGER}>
							{(props) => <StartActivityLogger activityType={ACTIVITIES.RESUME_EXAM} {...props}/>}
						</StateControl>
					),											
					[STATES.REDIRECT_TO_EXAM_QUESTIONS]: () => (
						<RedirectToExamQuestions examGuid={examGuid}/>
					)
				}}
			</StateView>
		</StateMachine>
	)
}

ExamIntroPageMachine.propTypes = {
	examGuid: PropTypes.string.isRequired
}


export {ExamIntroPageMachine}