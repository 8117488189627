import React, { Component } from 'react';
import PropTypes from "prop-types";

import { AppPageModifier } from "components/pages/app-page-modifier";
import { LoadingSpinner } from "components/presentation/loading-spinner";

import { activityLogger } from "libs/activity_logger/activity-logger";


class MaintainingActivityLogPage extends Component {
    componentDidMount() {
        const { onComplete } = this.props;

        activityLogger.maintain().then(() => {
            onComplete();
        });
    }

    render() {
        const appBarProps = {
            title: "Maintaining Activity Log",
            loadingTitle: true,
            logo: true
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="initializing-session-msgs"
                    appBarProps={appBarProps}
                    loading
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

MaintainingActivityLogPage.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export { MaintainingActivityLogPage };
