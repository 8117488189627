
// npm
import React from 'react'
import PropTypes from 'prop-types'


class Callback extends React.Component
{
	constructor(props)
	{
		super(props);
		if (props.onCreate) { props.onCreate(); }
	}

	componentDidMount()
	{
		if (this.props.onMount)
		{
			this.props.onMount();
		}
	}

	componentWillUnmount()
	{
		if (this.props.onUnmount)
		{
			this.props.onUnmount();
		}
	}

	render()
	{
		return null;
	}
}

Callback.propTypes = {
	onCreate: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func
}


export {Callback}