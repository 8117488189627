import { assessmentApi } from "libs/api/interface/api-assessment";

import { activityLoggerSent } from "./activity-logger-sent";
import { dbStorageApi, KEYS } from "libs/browser_storage/db-storage-api";
import { check } from "@xams-utils/check-types";

const resetActivityLogger = () => {
    return new Promise((resolve) => {
        dbStorageApi.delete(KEYS.STUDENT_ACTIVITY).then(() => {
            dbStorageApi.delete(KEYS.SENT_EXAMS).then(() => {
                dbStorageApi.initializeDB(0).then(() => {
                    dbStorageApi.initializeDB(1).then(() => {
                        activityLoggerSent.clear();
                        resolve();
                    });
                });
            });
        });
    });
};

const deleteFormRunGuids = (formRunGuids) => {
    return new Promise((resolve) => {
        if (!check.nonEmptyArray(formRunGuids)) {
            resolve();
        } else {
            deleteKeyFormRunGuids(formRunGuids, KEYS.STUDENT_ACTIVITY).then(
                () => {
                    deleteKeyFormRunGuids(formRunGuids, KEYS.SENT_EXAMS).then(
                        () => {
                            activityLoggerSent.remove(formRunGuids).then(() => {
                                resolve();
                            });
                        }
                    );
                }
            );
        }
    });
};

const deleteKeyFormRunGuids = (formRunGuids, key) => {
    return new Promise((resolve) => {
        dbStorageApi.retrieveAll(key, true).then((records) => {
            const deletedRecords = records
                .filter(
                    (record) => formRunGuids.indexOf(record.formRunGuid) !== -1
                )
                .map((record) => ({
                    _id: record.id,
                    _rev: record._rev,
                    _deleted: true,
                }));

            dbStorageApi.remove(key, deletedRecords).then(() => {
                resolve();
            });
        });
    });
};

const uploadFormRunGuids = (formRunGuids) => {
    return new Promise((resolve) => {
        const foundFormRunGuids = {};
        dbStorageApi
            .retrieveAll(KEYS.STUDENT_ACTIVITY, true)
            .then((records) => {
                records.forEach((record) => {
                    const { formRunGuid } = record;
                    if (formRunGuids.indexOf(formRunGuid) !== -1) {
                        if (!foundFormRunGuids[formRunGuid])
                            foundFormRunGuids[formRunGuid] = {
                                formRunGuid,
                                activities: [],
                            };
                        foundFormRunGuids[
                            formRunGuid
                        ].activities = foundFormRunGuids[
                            formRunGuid
                        ].activities.concat([record]);
                    }
                });

                const _foundFormRunGuids = [];
                Object.keys(foundFormRunGuids).forEach(
                    (foundFormRunGuid, index) => {
                        _foundFormRunGuids[index] = foundFormRunGuids[foundFormRunGuid];
                    }
                );
                if (check.nonEmptyArray(_foundFormRunGuids)){
                    uploadFormRunGuid(_foundFormRunGuids, 0, resolve);
                }
                else{
                    resolve();
                }
            });
    });
};

const uploadFormRunGuid = (formRunGuids, currentFormRunGuid, resolve) => {
    const { formRunGuid, activities } = formRunGuids[currentFormRunGuid];
    const payload = {
        formRunGuid,
        activities: activities.map((activity) => {
            const { id, type, value } = activity;
            return {
                id,
                type,
                value,
            };
        }),
    };

    const onComplete = () => {
        if (currentFormRunGuid === formRunGuids.length - 1) {
            resolve();
        } else {
            uploadFormRunGuid(formRunGuids, currentFormRunGuid + 1, resolve);
        }
    };

    const onSuccess = () => {
        return activityLoggerSent.reSend(formRunGuid).then(() => {
            onComplete();
        });
    };

    assessmentApi.saveActivityLog(payload).then(onSuccess, onComplete);
};

export { resetActivityLogger, deleteFormRunGuids, uploadFormRunGuids };
