
const error = (message) => `XState Config: ${message}`;


class XStateConfig
{
	constructor(name)
	{
		this._name = name;
		this._states = {};
		this._transitions = {};
		this._initialState = undefined;
	}

	set initialState(stateName)
	{
		if (!stateName) { throw error("Unknown initial state"); }
		this._initialState = stateName;
	}

	addState(stateName, stateConfig)
	{
		if (!stateName) { throw error("Unknown state name"); }
		if (!stateConfig) { throw error("Unknown state config"); }
		if (stateName.includes('.')) { throw error("State name can't contain '.'"); }
		this._states[stateName] = stateConfig;
	}

	addTransition(evt, nextStateName)
	{
		if (!evt) { throw error("Unknown event transition"); }
		if (!nextStateName) { throw error("Unknown transition state"); }
		this._transitions[evt] = nextStateName;
	}

	toObject()
	{
		// 1. Insert initial state
		const config = { initial: this._initialState };

		// 2. Insert transitions
		config.on = {...this._transitions};

		// 3. Insert states
		config.states = {};
		Object.keys(this._states).forEach(stateName => {
			config.states[stateName] = this._states[stateName].toObject();
		});

		return config;
	}
}


export {XStateConfig}