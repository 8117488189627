
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

// material-ui
import HomeIcon from '@material-ui/icons/Home'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {withMessages} from 'components/hocs/messages'
import {ActionButton} from 'components/layout/action_button/button'

// other
import {MESSAGE_IDS} from 'constants/message-ids'


const returnFromExamMessageId = MESSAGE_IDS.EXAM.RETURN_HOME;


const iconStyles = ({palette}) => {
	return {
			text: {
			color: palette.secondary.contrastText + "!important"
		}
	}
}

let MyHomeIcon = ({classes, ...props}) => {
	return <HomeIcon className={classes.text} {...props}/>;
}

MyHomeIcon = withStyles(iconStyles)(MyHomeIcon);


// ReturnToSchedulesButton (not connected to router/messages)
// --------------------------------------------------------------------------
let ReturnToSchedulesButton = ({history, classes, messages}) =>
{
	const onClick = () => history.replace('/schedules');

	return (
		<ActionButton Icon={MyHomeIcon} color='secondary' onClick={onClick}>
			{messages[returnFromExamMessageId]}
		</ActionButton>
	)
}

ReturnToSchedulesButton.propTypes = {
	history: PropTypes.object.isRequired,
	messages: PropTypes.shape({
		[returnFromExamMessageId]: PropTypes.string.isRequired
	})
}

ReturnToSchedulesButton = withRouter(ReturnToSchedulesButton);
ReturnToSchedulesButton = withMessages(ReturnToSchedulesButton);


// Export
// --------------------------------------------------------------------------
export {ReturnToSchedulesButton}