import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import {
    getComponentValue,
    getStyle,
    addFontStyles,
    disableArrowKeyListeners,
    enableArrowKeyListeners,
} from "../form-helper";

class FormFieldTextArea extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        const { onChange, name, enabled } = this.props;
        if (enabled) {
            onChange(name, e.target.value);
        }
    };

    render() {
        const { formValue, name, enabled, fontObj, spellCheck, maxLength } =
            this.props;
        const value = getComponentValue(name, formValue);
        const styleObj = addFontStyles(getStyle(this.props), fontObj);

        const spellChecker = check.nonEmptyString(spellCheck)
            ? spellCheck === "true"
            : false;

        const inputProps = {
            style: styleObj,
            onChange: this.handleChange,
            autoComplete: false,
            spellCheck: spellChecker,
            value,
            onFocus: disableArrowKeyListeners,
            onBlur: enableArrowKeyListeners,
        };

        if (check.nonEmptyString(maxLength)) {
            inputProps.maxlength = maxLength;
        }

        const divStyleObj = {};
        if (check.assigned(styleObj.width)) {
            divStyleObj.width = styleObj.width;
        }

        if (!enabled) {
            inputProps.readOnly = true;
        }

        return (
            <div style={divStyleObj}>
                <textarea {...inputProps} />
            </div>
        );
    }
}

export { FormFieldTextArea };
