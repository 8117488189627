
// npm
import React from 'react'

// react
import {RichTextEditor as Editor} from './editor'

// utils
import {keyListener, KEYS} from 'utils/key-listener'


const disableArrowKeyListeners = () =>
{
	keyListener.disable(KEYS.LEFT_ARROW);
	keyListener.disable(KEYS.RIGHT_ARROW);
}

const enableArrowKeyListeners = () =>
{
	keyListener.enable(KEYS.LEFT_ARROW);
	keyListener.enable(KEYS.RIGHT_ARROW);
}

const RichTextEditor = (props) =>
{
	const onFocus = disableArrowKeyListeners;
	const onBlur = enableArrowKeyListeners;
	return <Editor onFocus={onFocus} onBlur={onBlur} {...props}/>;
}


export {RichTextEditor}