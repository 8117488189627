// npm
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// react
import { AnswerPanel } from "./answer_panel/panel";
import { ScenarioPanel } from "./scenario_panel/panel";
import { QuestionPanel } from "./question_panel/panel";
import { ExamStatePanel } from "./exam_state_panel/panel";
import { DisplayAllQuestionsPanel } from "./display_all_questions/display-all-questions-panel";
import {
    DisableNavigationContext,
    DisableAnsweringContext,
} from "components/pages/exam/disabled-context";

import { getMappingsAndSections, findCurrentSection, displayAllQuestionsInSection } from "./content-helper";

class ExamContent extends Component {
    constructor(props) {
        super(props);

        this.displayQuestions = this.displayQuestions.bind(this);
    }

    displayQuestions() {
        const { displayAllQuestions, currentQuestionId, currentSection, questionIdSetFromQuestion } =
            this.props;

        if (displayAllQuestions) {
            const props = {
                currentQuestionId,
                currentSection,
                questionIdSetFromQuestion,
            };

            return <DisplayAllQuestionsPanel {...props} />;
        }

        return (
            <React.Fragment>
                <ScenarioPanel questionId={currentQuestionId} />
                <QuestionPanel questionId={currentQuestionId} />
                <AnswerPanel questionId={currentQuestionId} />
            </React.Fragment>
        );
    }

    render() {
        const { paused, reading } = this.props;
        const disableNavigation = paused;
        const disableAnswering = paused || reading;

        return (
            <DisableNavigationContext.Provider value={disableNavigation}>
                <DisableAnsweringContext.Provider value={disableAnswering}>
                    <ExamStatePanel />
                    {this.displayQuestions()}
                </DisableAnsweringContext.Provider>
            </DisableNavigationContext.Provider>
        );
    }
}

ExamContent.propTypes = {
    currentQuestionId: PropTypes.number.isRequired,
    reading: PropTypes.bool,
    paused: PropTypes.bool,
};

const mapStoreToProps = (store) => {
    const { mappings, sections, questionIdSetFromQuestion, currentQuestionId } =
        getMappingsAndSections(store);

    const currentSection = findCurrentSection(
        currentQuestionId,
        mappings,
        sections
    );
    const displayAllQuestions = displayAllQuestionsInSection(currentSection);

    return {
        displayAllQuestions, 
        currentSection,
        currentQuestionId,
        questionIdSetFromQuestion,
    };
};

ExamContent = connect(mapStoreToProps)(ExamContent);

export { ExamContent };
