
import {check} from '../../index'


const arrayLike = function(arrayLikeObject)
{
	return check.integer(arrayLikeObject.length);
}


export {arrayLike}