import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MaterialText } from "components/presentation/material-text";

import { CONSTANTS } from "../reset_password/constants";
import { Hidden } from "@material-ui/core";

const styles = ({ palette }) => ({
    root: {
        // alignItems: "flex-start",
    },
    listItem: {
        paddingLeft: 0,
        // alignItems: 'flex-start'
    },
    listItemText: {
        // minHeight: 48
    },
    avatar: {
        background: palette.primary.main,
    },
    progress: {
        color: palette.common.white,
    },
});

const getIcon = (status, classes) => {
    if (status === CONSTANTS.SUCCESS) return <DoneIcon />;
    else if (status === CONSTANTS.FAILED) return <ClearIcon />;
    else if (status === CONSTANTS.SUBMITTING)
        return <CircularProgress size={32} className={classes.progress} />;

    return "i";
};

let ForgotPasswordInfo = (props) => {
    const { message, status, classes } = props;
    const icon = getIcon(status, classes);

    return (
        <div className={classes.root}>
            <List aria-label={message}>
                <ListItem alignItems="flex-start" className={classes.listItem}>
                    <Hidden xsDown>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>{icon}</Avatar>
                        </ListItemAvatar>
                    </Hidden>
                    <ListItemText
                        className={classes.listItemText}
                        primary={
                            <MaterialText variant="body1">
                                {message}
                            </MaterialText>
                        }
                    />
                </ListItem>
            </List>
        </div>
    );
};

ForgotPasswordInfo = withStyles(styles)(ForgotPasswordInfo);

export { ForgotPasswordInfo };
