// npm
import {createStore, applyMiddleware} from 'redux'

// redux
import {ROOT_REDUCER} from './reducers/reducer'
import {snapshotStore} from './middleware/snapshot-store'
import {detectInactivity} from './middleware/detect-inactivity'


let store;

const getStore = function()
{
	if (!store)
	{
		store = createStore(
			ROOT_REDUCER, 
			undefined, 
			applyMiddleware(snapshotStore, detectInactivity)
		);
	}

	return store;
}


export {getStore}