
// npm
import * as Immutable from 'immutable'

// custom
import {getProp} from 'custom/object-helper'

// redux
import {extractMessage} from '../helpers'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const extractMessageData = ({EXAM}) => ({
	introText: extractMessage(EXAM.TEXT),
	outroText: extractMessage(getProp(EXAM, 'EXAMPROPERTIES.OUTROTEXT')),
	proctoredOutroText: extractMessage(getProp(EXAM, 'EXAMPROPERTIES.PROCTOREDOUTROTEXT'))
});


const MESSAGES_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			return Immutable.Map(extractMessageData(action.data));
		default:
			return state;
	}
}


export {MESSAGES_REDUCER}