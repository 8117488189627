// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";

// redux
import { addSchedule, addElearning } from "./actions";
import { getUserSessionData } from "redux/reducers/session/selectors";
import { getUserGuid } from "redux/reducers/session/user/selectors";
import { getSessionData } from "redux/reducers/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getProctoringMode } from "redux/reducers/settings/client/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getUseLocalScormApi } from "redux/reducers/settings/app/selectors";

// utils
import { assessmentApi } from "libs/api/interface/api-assessment";
import { eLearningApi } from "libs/api/interface/api-e-learning";
import { sortElearning } from "./e-learning";

// SchedulesFetcher (not connected to store)
// --------------------------------------------

class SchedulesFetcher extends React.Component {
    componentDidMount() {
        const onSuccess = (response) => {
            const parsedResponse = JSON.parse(response);

            const {assessments, courses} = parsedResponse;

            const uniqueAssessments = check.nonEmptyArray(assessments)
                ? assessments.filter((assessment, index, a) => {
                      if (index === a.length - 1) {
                          return true;
                      }
                      const b = a.slice(index + 1);
                      const hasFutureExamGuid = check.nonEmptyArray(b)
                          ? b.some(
                                (c) => c.objectGUID === assessment.objectGUID
                            )
                          : false;

                      return !hasFutureExamGuid;
                  })
                : [];

            this.props.saveSchedules(uniqueAssessments || []);

            const sortedCourses = sortElearning(courses || []);
            this.props.saveElearning(sortedCourses);
            this.props.onSuccess();
        };
        assessmentApi
            .getExamScheduleWork(this.props.userGuid, this.props.proctoringMode)
            .then(onSuccess, this.props.onFail);            
    }

    getElearning() {
        const onSuccess = (response) => {
            const parsedResponse = JSON.parse(response);

            const courses = sortElearning(parsedResponse.courses);
            this.props.saveElearning(courses);
            this.props.onSuccess();
        };

        const payload = {
            userGuid: this.props.userGuid,
            includeCompleted: true,
        };

        eLearningApi
            .getCourseAttempts(payload)
            .then(onSuccess, this.props.onFail);
    }

    render() {
        return null;
    }
}

SchedulesFetcher.propTypes = {
    userGuid: PropTypes.string.isRequired,
    saveSchedules: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFail: PropTypes.func.isRequired,
};

// SchedulesFetcher (connected to store)
// --------------------------------------------

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);

    return {
        userGuid: getUserGuid(getUserSessionData(getSessionData(store))),
        proctoringMode: getProctoringMode(
            getClientSettingsData(settingsData)
        ),
    };
};


const mapDispatchToProps = (dispatch) => ({
    saveSchedules: (response) => {
        response.forEach((item) => {
            dispatch(addSchedule(item));
        });
    },
    saveElearning: (response) => {
        response.forEach((item) => {
            dispatch(addElearning(item));
        });
    },
});

SchedulesFetcher = connect(
    mapStoreToProps,
    mapDispatchToProps
)(SchedulesFetcher);

export { SchedulesFetcher };
