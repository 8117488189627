
// redux (action-types)
import {CLEAR_EXAM} from 'redux/reducers/exam/action-types'
import {CLEAR_SCHEDULES} from 'redux/reducers/schedules/action-types'
import {DISCARD_TOKENS} from 'redux/reducers/session/tokens/action-types'
import {CLEAR_MESSAGES} from 'redux/reducers/messages/session/action-types'
import {REMOVE_SESSION} from 'redux/reducers/session/user/action-types'
import {SET_NETWORK_ERROR} from 'redux/reducers/app/action-types'


const getRemoveCurrentSessionActions = () => ([
	{type: CLEAR_EXAM},
	{type: DISCARD_TOKENS},
	{type: CLEAR_SCHEDULES},
	{type: CLEAR_MESSAGES},
	{type: REMOVE_SESSION}
]);


const setNetworkError = (value) => ({
	type: SET_NETWORK_ERROR,
	value
});


export {getRemoveCurrentSessionActions, setNetworkError}