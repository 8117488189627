
// npm
import React from 'react'
import PropTypes from 'prop-types'

import {getResumedAnswer} from 'redux/reducers/exam/content/questions/question/ict/selectors'

// react
import {AnswerBox} from './answer-box'
import {IctAnswerForm} from '../answer_forms/ict'


class IctAnswerBox extends React.Component {
  constructor(props) {
    super(props);
    this.setUpExtractionFunctions();
    this.setUpTransformationFunctions();
  }

  // extract information from questionData
  // --------------------------------------
  setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => undefined;
		this.extractResumedAnswer = () => {
			return getResumedAnswer(this.props.data);
		};
		this.extractFormData = () => { return this.props.data };
	}

  // convert between answer values
  // --------------------------------------
  setUpTransformationFunctions() {
    this.getFormValue = formAnswer => formAnswer;
    this.toFormValue = localAnswer => localAnswer;
    this.toLocalAnswer = formValue => formValue;
    this.toServerAnswer = localAnswer => {return {answer:localAnswer, options:[]}};
  }

  render() {
    const answerBoxProps = {
      questionData: this.props.data,
      FormComponent: IctAnswerForm,
      toFormValue: this.toFormValue,
      getFormValue: this.getFormValue,
      toLocalAnswer: this.toLocalAnswer,
      toServerAnswer: this.toServerAnswer,
      extractFormData: this.extractFormData,
      extractInitialAnswer: this.extractInitialAnswer,
      extractResumedAnswer: this.extractResumedAnswer
    };

    return <AnswerBox {...answerBoxProps} />;
  }
}

IctAnswerBox.propTypes = {
  data: PropTypes.object.isRequired
}

export {IctAnswerBox}