// npm
import React from "react";
import PropTypes from "prop-types";
import { check } from "@xams-utils/check-types";

// material-ui
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { MaterialText } from "components/presentation/material-text";
import { DisableAnsweringContext } from "components/pages/exam/disabled-context";

// custom
import { splitOut } from "custom/string-helper";
import { checkPropTypes } from "prop-types";
import { getOptionWidthFromLength } from "./option-helper";

const OPTION_STRING = "[]";


const splitSegments = (text) => {
    const segments = [];
    let startingPosition = 0;
    //const text = _text.replace(/<br\s*\/?>/gi,' ');

    while (true) {
        const startBracket = text.indexOf("[", startingPosition);

        if (startBracket === -1) {
            segments.push(text.substr(startingPosition).trim());
            break;
        }

        const endBracket = text.indexOf("]", startBracket);

        if (endBracket === -1) {
            segments.push(text.substr(startingPosition).trim());
            break;
        }

        segments.push(text.substring(startingPosition, startBracket).trim());
        segments.push(text.substring(startBracket, endBracket + 1).trim());

        startingPosition = endBracket + 1;
    }
    return segments;
};

const isDropDownSegment = (textSegment) => {
    if (!textSegment) return false;

    return (
        textSegment.length > 1 &&
        textSegment.charAt(0) === "[" &&
        textSegment.charAt(textSegment.length - 1) === "]"
    );
};

class BlanksAnswerForm extends React.Component {
    constructor(props) {
        super(props);
        this.selectionIndex = 0;

        this.getRenderableSegments = this.getRenderableSegments.bind(this);
    }
    render() {
        const {
            formData: { text },
        } = this.props;

        //const textSegments = splitOut(text, OPTION_STRING);
        const lines = text.split(/<br\s*\/?>/gi);
        this.selectionIndex = 0;

        if (lines.length === 1) {
            return this.displayDiv(text);
        } else {
            return lines.map((line, index) => {
                if (line.trim() === "") {
                    return <br />;
                }

                return (
                    <React.Fragment>
                        {this.displayDiv(line, index)}
                        <br />
                    </React.Fragment>
                );
            });
        }
    }

    displayDiv(text, index = null) {
        const textSegments = splitSegments(text);
        const renderableSegments = this.getRenderableSegments(
            textSegments,
            index
        );

        return (
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                }}
            >
                {renderableSegments}
            </div>
        );
    }

    getRenderableSegments(textSegments, _index = null) {
        return textSegments.map((textSegment, index) => {
            const key =
                _index !== null
                    ? _index + "_" + index + "_" + this.selectionIndex
                    :  index + "_"+this.selectionIndex;

            console.log("_index", _index, key);

            return isDropDownSegment(textSegment)
                ? this.createDropdown(key, this.selectionIndex++, textSegment)
                : this.getTextNode(key, textSegment);

            // return textSegment === OPTION_STRING ?
            // 	this.createDropdown(key, selectionIndex++) :
            // 	this.getTextNode(key, textSegment);
        });
    }

    createDropdown(key, selectionIndex, textSegment) {
        return (
            <DisableAnsweringContext.Consumer key={key}>
                {(disabledValue) =>
                    this.getDropdown(selectionIndex, disabledValue, textSegment)
                }
            </DisableAnsweringContext.Consumer>
        );
    }

    getDropdown(selectionIndex, disabled, textSegment) {
        const onChange = ({ target: { value } }) => {
            this.props.onChange({ selectionIndex, option: value });
        };

        const { classes } = this.props;
        const optionWidth = this.getOptionWidth(textSegment);

        const inputProps = {
            className: classes.text,
            classes: { underline: classes.dropdownUnderline },
        };

        const props = {
            className: `${classes.margins} ${classes.answerText}`,
            classes: { icon: classes.text },
            input: <Input {...inputProps} />,
            style: {minWidth: `${optionWidth}px`},
            value: this.props.value[selectionIndex],
            onChange: !disabled
                ? onChange
                : (e) => {
                      e.preventDefault();
                  },
        };

        return <Select {...props}>{this.getDropdownItems(textSegment)}</Select>;
    }

    getTextNode(key, text) {
        const { classes } = this.props;

        return (
            <MaterialText key={key} className={classes.text}>
                {text}
            </MaterialText>
        );
    }

    getDropdownItems(textSegment) {
        const options=this.getOptions(textSegment);

        return options.map((option) => {
            return <MenuItem value={option}>{option}</MenuItem>;
        });
    }

    getOptions(textSegment){
        let options = this.props.formData.options;

        if (
            this.props.formData.variableOptions &&
            textSegment !== OPTION_STRING
        ) {
            const variableName = textSegment.substring(
                1,
                textSegment.length - 1
            );

            if (this.props.formData.variableOptions[variableName]) {
                options = this.props.formData.variableOptions[variableName];
            }
        }
        
        return options;
    }

    getOptionWidth(textSegment){
        const options=this.getOptions(textSegment);

        const optionLength = options.reduce((total, option) => {
            return check.nonEmptyString(option) &&  option.length > total ? option.length : total;
        }, 0);

        const optionWidth=getOptionWidthFromLength(optionLength, 200);

        return optionWidth; 
    }
}

BlanksAnswerForm.propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        text: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};

const styles = ({ palette, spacing }) => ({
    answerText: {
        color: palette.primary.main + "!important",
    },
    text: {
        color: palette.background.contrastText + "!important",
    },
    dropdownUnderline: {
        "&:before": {
            borderBottom: `1px solid ${palette.background.contrastText} !important`,
        },
        "&:after": {
            borderBottom: `1px solid ${palette.background.contrastText} !important`,
        },
    },
    margins: {
        marginLeft: spacing.unit,
        marginRight: spacing.unit,
    },
});

BlanksAnswerForm = withStyles(styles)(BlanksAnswerForm);

export { BlanksAnswerForm };
