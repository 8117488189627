
// npm
import React from 'react'

// material-ui
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

// react
import {withMessages} from 'components/hocs/messages'
import {ActionButton} from 'components/layout/action_button/button'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const resumeMessageId = MESSAGE_IDS.GENERAL.RESUME;


let ResumeButton = ({onClick, messages}) => (
	<ActionButton onClick={onClick} Icon={ArrowForwardIcon} alignment={'right'}>
		{messages[resumeMessageId]}
	</ActionButton>
)


ResumeButton = withMessages(ResumeButton);


export {ResumeButton}