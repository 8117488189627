
// npm
import * as Immutable from 'immutable'

// redux
import * as ACTION_TYPES from './action-types'

const initialState={
	showCompleted: false,
	showCourseInfo: true,
	elearning: Immutable.List()
}

const ELEARNING_REDUCER = (state=Immutable.Map(initialState), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.CLEAR_ELEARNING:{
			return state.set('elearning', Immutable.List());
		}
		case ACTION_TYPES.ADD_ELEARNING:{
			return state.update('elearning', elearning => elearning.push(Immutable.Map(action)));
		}
		case ACTION_TYPES.SET_SHOW_COMPLETED:{
			return state.set('showCompleted', action.value)
		}
		case ACTION_TYPES.SET_SHOW_COURSE_INFO:{
			return state.set('showCourseInfo', action.value)
		}		
		default:
			return state;
	}
}


export {ELEARNING_REDUCER}