
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {RubricFormMachine} from './machine'
import {withMessages} from 'components/hocs/messages'
import {AppPageModifier} from 'components/pages/app-page-modifier'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const welcomeMessageId = MESSAGE_IDS.APP_BAR.WELCOME;


let RubricPage = ({messages}) =>
{
	const appBarProps = {
		title: messages[welcomeMessageId],
		logout: true,
		logo: true
	}

	return (
		<React.Fragment>
			<AppPageModifier id="rubric" appBarProps={appBarProps}/>
			<div style={{padding: 16}}>
				<RubricFormMachine/>
			</div>
		</React.Fragment>
	)
}

RubricPage.propTypes = {
	messages: PropTypes.shape({
		[welcomeMessageId]: PropTypes.string.isRequired
	})
}

RubricPage = withMessages(RubricPage);


export {RubricPage}