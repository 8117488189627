
// npm
import * as Immutable from 'immutable'

// redux
import {extractMessage} from 'redux/reducers/exam/helpers'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'

// custom
import {getProp} from 'custom/object-helper'


const PART_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			state = state.set('id', action.partData['@ID']);
			state = state.set('lineCount', action.partData['@NumberOfLines']);
			state = state.set('usesRichText', action.partData['@RichEditor']);
			state = state.set('marks', action.partData['@Score']);
			state = state.set('answerText', action.partData['ANSWERTEXT']);
			state = state.set('workingAreaSize', action.partData['@WorkingAreaSize']);
			state = state.set('suffix', action.partData['@Suffix']);
			const partText = getProp(action, 'partData.TEXT');
			return state.set('text', extractMessage(partText || {}) || "");
		default:
			return state;
	}
}


export {PART_REDUCER}