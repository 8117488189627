
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-utils
import {check} from '@xams-utils/check-types'

// components
import {MachineContext} from '../machine-context'


class StateView extends React.Component
{
	constructor(props)
	{
		super(props);
		
		if (Object.keys(props.children).some(key => key === 'undefined')) {
			throw "<StateView> received an undefined state mapping";
		}
	}

	*getActiveStateNames(state)
	{
		if (check.object(state)) {
			for (let parentStateName in state) {
				yield parentStateName;
				yield* this.getActiveStateNames(state[parentStateName]);
			}
		}
		else {
			yield state;
		}
	}

	componentDidMount()
	{
		if (this.props.onEnter) {
			this.props.onEnter();
		}
	}

	componentWillUnmount()
	{
		if (this.props.onExit) {
			this.props.onExit();
		}
	}

	render()
	{
		const activeStateNames = this.getActiveStateNames(this.context.state);
		const {children:viewMappings} = this.props;

		for (const activeStateName of activeStateNames) {
			const getView = viewMappings[activeStateName];
			if (getView) { return getView(); }
		}

		return null;
	}
}

StateView.propTypes = {
	onEnter: PropTypes.func,
	onExit: PropTypes.func,
	children: PropTypes.objectOf(PropTypes.func).isRequired													// an object where each key represents a state, and each value is a function returning the elements to render in that state
}

StateView.contextType = MachineContext;


export {StateView}