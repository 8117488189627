import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { LoadingSpinner } from "components/presentation/loading-spinner";

import { EnhancedTable } from "./activity-log-table";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { activityLogger } from "libs/activity_logger/activity-logger";
import {resetActivityLogger, deleteFormRunGuids, uploadFormRunGuids} from "libs/activity_logger/activity-logger-reset";
import { AppPageModifier } from "components/pages/app-page-modifier";


const styles = ({ spacing, breakpoints, palette }) => ({
    root: {
        margin: spacing.unit * 3,
        overflowX: "auto",
    },
    table: {
        minWidth: 700,
    },
    layout: {
        marginTop: spacing.unit * 3,
        width: "auto",
        display: "block", // Fix IE11 issue.
        marginLeft: spacing.unit * 2,
        marginRight: spacing.unit * 2,
        paddingTop: spacing.unit * 2,
        [breakpoints.up(800 + spacing.unit * 3 * 2)]: {
            width: 800,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
});

class ActivityLogsPage extends Component {
    constructor(props) {
        super(props);

        this.timer = null;
        this.state = { logs: [], selected: [], busy: false };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    getData() {
        if (activityLogger.initialised) {
            activityLogger.getLogs().then((logs) => {
                this.setState({ logs });
            });
        } else {
            this.timer = setTimeout(() => {
                this.getData();
            }, 500);
        }
    }

    handleSelectChange(selected){
        this.setState({selected});
    }

    handleDelete(){
        const {selected, logs} = this.state;
        if (logs.length === selected.length){
            this.handleReset();
        }
        else{
            const formRunGuids = selected.map(item=>logs[item-1].formRunGuid);
            this.setState({busy: true, logs:[]}, ()=>{
                deleteFormRunGuids(formRunGuids).then(()=>{
                    this.setState({busy: false}, ()=>{
                        this.getData();
                    });
                })
            });
        }
    }

    handleUpload(){
        const {selected, logs} = this.state;
        const formRunGuids = selected.map(item=>logs[item-1].formRunGuid);
        this.setState({busy: true, logs:[]}, ()=>{
            uploadFormRunGuids(formRunGuids).then(()=>{
                this.setState({busy: false}, ()=>{
                    this.getData();
                });
            })
        });        
    }

    handleReset(){
        this.setState({busy: true, logs:[]}, ()=>{
            resetActivityLogger().then(()=>{
                this.setState({busy: false}, ()=>{
                    this.getData();
                });
            })
        });
    }

    reDisplay(){

    }

    render() {
        const appBarProps = {
            title: "Activity Logs",
            welcomeMessage: true,
            logout: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier id="schedules" appBarProps={appBarProps} />
                {this.displayLogs()}
            </React.Fragment>
        );
    }

    displayLogs() {
        const { logs, selected, busy } = this.state;

        if (busy) {
            return <LoadingSpinner size="lg" />;
        }

        const { classes } = this.props;
        const rows = [
            {
                id: "formRunGuid",
                numeric: false,
                disablePadding: true,
                label: "Form Run Guid",
            },
            { id: "date", date: true, disablePadding: true, label: "Date" },
            { id: "sent", date: true, disablePadding: true, label: "Sent" },
        ];

        const icons = [
            {
                name: "Schedules",
                icon: <ArrowUpwardIcon />,
                onSelect: () => {
                    this.movePage("schedules");
                },
            },
            {
                name: "Reset",
                icon: <ClearIcon />,
                onSelect: this.handleReset,
            },
            {
                name: "Delete",
                icon: <DeleteIcon />,
                disabled: selected.length===0,
                onSelect: this.handleDelete,
            },
            {
                name: "Upload",
                icon: <CloudUploadIcon />,
                disabled: selected.length===0,
                onSelect: this.handleUpload,
            },                                     
        ];

        const props = {
            title: "Activity Logs",
            rows,
            data: logs,
            checkBox: true,
            rowsPerPage: 10,
            onSelect: ({ formRunGuid }) => {
                const { history } = this.props;
                history.replace(`/log/${formRunGuid}`);
            },
            onSelectChange: this.handleSelectChange,
            icons,
        };

        return (
            <div className={classes.root}>
                <EnhancedTable {...props} />
            </div>
        );
    }

    movePage(page) {
        const { history } = this.props;
        history.replace(`/${page}`);
    }
}

ActivityLogsPage = withStyles(styles)(ActivityLogsPage);
ActivityLogsPage = withRouter(ActivityLogsPage);

export { ActivityLogsPage };
