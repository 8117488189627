
// npm
import {connect} from 'react-redux'

// react
import {MessageChecker} from '../checker'

// redux (selectors)
import {getMessagesData} from 'redux/reducers/selectors'
import {getSessionMessageData} from 'redux/reducers/messages/selectors'
import {getMessageById} from 'redux/reducers/messages/general/selectors'

// constants
import {getSessionMessageIds} from 'constants/message-ids'


const mapStoreToProps = (store) => ({
	messageIds: getSessionMessageIds(),
	getMessage: getMessageById(getSessionMessageData(getMessagesData(store)))
});

const SessionMessageChecker = connect(mapStoreToProps)(MessageChecker);


// Export
// ------------------------------------------------------
export {SessionMessageChecker}