
import check from 'check-types'

import * as SELECTORS from './selectors'
import * as TIME_SELECTORS from './time/selectors'
import * as CONTENT_COMPLEX_SELECTORS from './content/complex-selectors'



const getReadingTimeRemaining = (examData, questionId) =>
{
	const contentData = SELECTORS.getContentData(examData);
	const readingTimeRemaining = CONTENT_COMPLEX_SELECTORS.getReadingTimeRemaining(contentData, questionId);
	if (check.assigned(readingTimeRemaining)) { return readingTimeRemaining; }

	const timeData = SELECTORS.getTimeData(examData);
	return readingTimeRemaining || TIME_SELECTORS.getReadingTimeRemaining(timeData) || 0;
}

const getAnsweringTimeRemaining = (examData, questionId) =>
{
	const contentData = SELECTORS.getContentData(examData);
	const answeringTimeRemaining = CONTENT_COMPLEX_SELECTORS.getAnsweringTimeRemaining(contentData, questionId);
	if (check.assigned(answeringTimeRemaining)) { return answeringTimeRemaining; }

	const timeData = SELECTORS.getTimeData(examData);
	return answeringTimeRemaining || TIME_SELECTORS.getExamTimeRemaining(timeData) || 0;
}

const getTimeRemaining = (examData, questionId) =>
{
	const readingTimeRemaining = getReadingTimeRemaining(examData, questionId);
	const answeringTimeRemaining = getAnsweringTimeRemaining(examData, questionId);
	return readingTimeRemaining + answeringTimeRemaining;
}

const getReadingTime = (examData, questionId) =>
{
	const contentData = SELECTORS.getContentData(examData);
	const readingTimeRemaining = CONTENT_COMPLEX_SELECTORS.getReadingTime(contentData, questionId);
	if (check.assigned(readingTimeRemaining)) { return readingTimeRemaining; }

	const timeData = SELECTORS.getTimeData(examData);
	return readingTimeRemaining || TIME_SELECTORS.getReadingTime(timeData) || 0;
}

const getAnsweringTime = (examData, questionId) =>
{
	const contentData = SELECTORS.getContentData(examData);
	const readingTimeRemaining = CONTENT_COMPLEX_SELECTORS.getAnsweringTime(contentData, questionId);
	if (check.assigned(readingTimeRemaining)) { return readingTimeRemaining; }

	const timeData = SELECTORS.getTimeData(examData);
	return readingTimeRemaining || TIME_SELECTORS.getExamTime(timeData) || 0;
}

const getWarningTime = (examData, questionId) =>
{
	const contentData = SELECTORS.getContentData(examData);
	const readingTimeRemaining = CONTENT_COMPLEX_SELECTORS.getWarningTime(contentData, questionId);
	if (check.assigned(readingTimeRemaining)) { return readingTimeRemaining; }

	const timeData = SELECTORS.getTimeData(examData);
	return readingTimeRemaining || TIME_SELECTORS.getWarningTime(timeData) || 0;
}


export {
	getReadingTimeRemaining,
	getAnsweringTimeRemaining,
	getTimeRemaining,
	getReadingTime,
	getAnsweringTime,
	getWarningTime
}