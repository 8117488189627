export default class PDFJsBackend {
    constructor(){
        this.displayed=false;
    }

    init = (source, element) => {
        if (!this.displayed) {
            const iframe = document.createElement('iframe');
            //debugger;
            iframe.id = 'pdfjs-iframe';
            iframe.src = `${process.env.PUBLIC_URL}/pdf/pdfjs-3.2.146-legacy-dist/web/viewer_restricted_v1.html?file=${source}`;
            iframe.style='height: calc(100% - 12px); width: 100%;';
            // iframe.width = '100%';
            // iframe.height = '99%';
        
            element.appendChild(iframe);
            this.displayed=true;
        }
    };
}
