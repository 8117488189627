
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react
import {BugReportPopup} from './popup'
import {BugReportButton} from './button'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {isAdmin, getUserGuid, getName} from 'redux/reducers/session/user/selectors'


class BugReporter extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			show: false
		}

		this.openBugReportForm = () => {
			this.setState({show: true});
		}

		this.closeBugReportForm = () => {
			this.setState({show: false});
		}
	}

	render()
	{
		if (!this.props.admin) {
			return null;
		}

		return (
			<React.Fragment>
				<BugReportButton onClick={this.openBugReportForm}/>
				{this.state.show && this.BugReportPopup}
			</React.Fragment>
		)
	}

	get BugReportPopup()
	{
		return <BugReportPopup onClose={this.closeBugReportForm}/>;
	}
}

BugReporter.propTypes = {
	admin: PropTypes.bool,
	userGuid: PropTypes.string,
	userName: PropTypes.string
}


const mapStoreToProps = (store) =>
{
	const userSessionData = getUserSessionData(getSessionData(store));

	return {
		admin: isAdmin(userSessionData),
		userGuid: getUserGuid(userSessionData),
		userName: getName(userSessionData)
	}
}

BugReporter = connect(mapStoreToProps)(BugReporter);


export {BugReporter}