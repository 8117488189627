// internal
import { localStorageApi, KEYS } from "../local-storage-api";

class StoredExams {
    constructor() {
        const exams = localStorageApi.retrieveDataFrom(KEYS.EXAMS);

        const save = () => {
            localStorageApi.saveDataTo(KEYS.EXAMS, exams);
        };

        this.set = (guid, examData) => {
            if (exams.hasOwnProperty(guid)) {
                return;
            }
            exams[guid] = examData;
            save();
        };

        this.remove = (guid) => {
            if (!exams.hasOwnProperty(guid)) {
                return;
            }
            delete exams[guid];
            save();
        };

        this.removeAll = () => {
            Object.keys(exams).forEach((id) => {
                delete exams[id];
            });
            save();
        };

        this.dangerously_getUsers = () => {
            return exams;
        };
    }
}

const storedExams = new StoredExams();
export { storedExams };
