
// npm
import React from 'react'

// react
import {AppPageContext} from './context'


const AppPageProvider = (props) => (
	<AppPageContext.Provider value={props.value}>
		{props.children}
	</AppPageContext.Provider>
)


export {AppPageProvider}