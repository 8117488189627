
// npm
import React from 'react'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {Align} from 'components/layout/align'
import {MaterialText} from 'components/presentation/material-text'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


let NavigationPanelTitle = ({classes, messages}) => (
	<Align>
		<MaterialText className={classes.text} variant="h6" noWrap>
			{messages[MESSAGE_IDS.EXAM.QUESTION_NAV_TITLE]}
		</MaterialText>
	</Align>
)


const styles = ({palette}) => ({
	text: {
		color: palette.background.contrastText
	}
})

NavigationPanelTitle = withStyles(styles)(NavigationPanelTitle);
NavigationPanelTitle = withMessages(NavigationPanelTitle);


export {NavigationPanelTitle}