
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {AnswerBox} from './answer-box'
import {MultiTextAnswerForm} from '../answer_forms/multi-text'

// redux
import {getLocalAnswer} from 'redux/reducers/exam/content/questions/question/selectors'
import {getPartsData, getShowWordCount} from 'redux/reducers/exam/content/questions/question/multi_text/selectors'
import * as PART_SELECTORS from 'redux/reducers/exam/content/questions/question/multi_text/parts/part/selectors'


class MultiTextAnswerBox extends React.Component
{
	constructor(props)
	{
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => {
			const initialAnswer = {};
			getPartsData(this.props.data).forEach(partData => {
				initialAnswer[PART_SELECTORS.getId(partData)] = "";
			});
			return initialAnswer;
		};

		this.extractResumedAnswer = () => {
			const resumedAnswer = {};
			getPartsData(this.props.data).forEach(partData => {
				const resumedValue = PART_SELECTORS.getResumedAnswer(partData) || ""
				resumedAnswer[PART_SELECTORS.getId(partData)] = resumedValue;
			});
			return resumedAnswer;
		}

		this.extractFormData = () => ({
			showWordCount: getShowWordCount(this.props.data),
			parts: getPartsData(this.props.data).map(partData => ({
				id: PART_SELECTORS.getId(partData),
				text: PART_SELECTORS.getText(partData),
				marks: PART_SELECTORS.getMarks(partData),
				lineCount: PART_SELECTORS.getLineCount(partData),
				usesRichText: PART_SELECTORS.usesRichText(partData),
				suffix: PART_SELECTORS.getSuffix(partData)
			}))
		})
	}

	setUpTransformationFunctions()
	{
		this.getFormValue = (formAnswer) => {
			const formValue = {...getLocalAnswer(this.props.data)}
			formValue[formAnswer.partId] = formAnswer.partValue;
			return formValue;
		}

		this.toServerAnswer = (localAnswer) => {
			const partIds = Object.keys(localAnswer);
			const partValues = Object.values(localAnswer);
			return {
				answerText: partValues.join('\n'),
				options: partIds.map((partId, i) => {
					const optionText = partValues[i];
					return {optionID: parseInt(partId, 10), optionText};
				})
			}
		}
	}

	render()
	{
		const answerBoxProps = {
			FormComponent: MultiTextAnswerForm,
			questionData: this.props.data,
			getFormValue: this.getFormValue,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer
		}

		return <AnswerBox {...answerBoxProps}/>;
	}
}

MultiTextAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export {MultiTextAnswerBox}