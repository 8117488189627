import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from '@material-ui/icons/Error';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({ spacing, palette, breakpoints }) => ({
    root: {
        [breakpoints.down(800)]: {
            width: "80%",
        },
        [breakpoints.up(801)]: {
            width: 800,
        },
        marginLeft: 'auto', 
        marginRight: 'auto',
        marginTop: spacing.unit * 2,
    },
    avatar: {
        backgroundColor: palette.error.dark    
    },
    button: {
        marginLeft: 'auto',        
    }
});

class FullScreenError extends Component {
    state = {};
    render() {
        const { title, message, buttonClick, buttonText, classes } = this.props;
        const buttonProps = {
            color: "primary",
            variant: "contained",
            onClick: buttonClick,
            className: classes.button
        };

        return (
            <Card className={classes.root}>
                <CardHeader
                    avatar={<Avatar aria-label="error" className={classes.avatar}><b>!</b></Avatar>}
                    title={title}
                    titleTypographyProps={{variant:"h5"}}
                />

                <CardContent>
                    <Typography
                        variant="h6"
                        color="textSecondary"
                        component="p"
                    >
                        {message}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button {...buttonProps}>{buttonText}</Button>
                </CardActions>
            </Card>
        );
    }
}

FullScreenError = withStyles(styles)(FullScreenError);

export { FullScreenError };
