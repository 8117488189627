
// npm
import {Map as ImmutableMap} from 'immutable'

// redux (action-types)
import * as ACTION_TYPES from './action-types'


const RESULT_REDUCER = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.SET_EXAM_RESULT:
			return ImmutableMap({
				marks: action.marks,
				totalMarks: action.totalMarks,
				score: action.score,
				grade: action.grade,
				passed: action.passed,
				message: action.message,
				allowImmediateResit: action.allowImmediateResit,
				resultStillBeingProcessed: action.resultStillBeingProcessed
			})
		default:
			return state;
	}
}


export {RESULT_REDUCER}