
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamInstructionsPage} from './instructions'
import {ExamQuestionsPageRouter} from './questions'
import {ExamSaver} from 'components/pages/exam/exam_saver'
import {ConnectionLossNotifier} from './connection-loss-notifier'

// machines
import {examRunning} from 'machines/exam-running'


const {EVENTS, RUNNING_STATES:STATES} = examRunning;


const PlayingExamView = () =>
{
	return (
		<React.Fragment>
			<ExamSaver/>
			<ConnectionLossNotifier/>
			<StateView>
			 {{
			 		[STATES.QUESTIONS]: () => (
			 			<StateControl onShowInstructions={EVENTS.SHOW_INSTRUCTIONS}>
			 				{(props) => <ExamQuestionsPageRouter {...props}/>}
			 			</StateControl>
			 		),
			 		[STATES.INSTRUCTIONS]: () => (
			 			<StateControl onShowQuestions={EVENTS.SHOW_QUESTIONS}>
			 				{(props) => <ExamInstructionsPage {...props}/>}
			 			</StateControl>
			 		)
			 }}
			</StateView>
		</React.Fragment>
	)
}


export {PlayingExamView}