import moment from "moment";

const getTimeStamp=()=>{
  // const date = new moment();  
  // return date.subtract(1, "days").toISOString();  
  
  return new Date().toISOString();
}

const incrementTimeStamp = (timeStamp) => {
    const date = new moment(timeStamp);
  
    return date.add(1, "ms").toISOString();
  };

  export {incrementTimeStamp, getTimeStamp}