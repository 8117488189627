
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MaterialScrollWrapper} from 'components/layout/scroll_wrapper/material-scroll-wrapper'
import {LoadingSpinner} from 'components/presentation/loading-spinner'

// constants
import {appBarHeight} from './app_bar/constants'


let PageWrapper = ({classes, children, loading}) => (
	<MaterialScrollWrapper>
		<div className={classes.wrapper}>
			<LoadingSpinner size='lg' disableModal open={loading}>
				{children}
			</LoadingSpinner>
		</div>
	</MaterialScrollWrapper>
)

PageWrapper.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	loading: PropTypes.bool.isRequired
}


const styles = (theme) => ({
	wrapper: {
		height: `calc(100% - ${appBarHeight}px)`,
		background: theme.palette.background.dark,
		width: '100%'
	}
})

PageWrapper = withStyles(styles)(PageWrapper)


export {PageWrapper}