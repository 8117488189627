import React, { Component } from "react";
import { check } from '@xams-utils/check-types';
import { getStyle, getComponentValue, addFontStyles, getActionObj, insertVariablesIntoText } from "../form-helper";

class FormSelectRadio extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        const {fontObj}=props;
        const styleObj = addFontStyles(getStyle(props), fontObj);

        this.state={styleObj }
    }

    componentDidUpdate(prevProps){
        const actionObj=getActionObj(prevProps, this.props);

        if (check.nonEmptyObject(actionObj)){
            const {styleObj}=this.state;
            const newStyleObj={...styleObj, ...actionObj.style}

            this.setState({styleObj: newStyleObj});
        }
    }

    handleChange(index) {
        const { onChange, name, options, actionOnSelect, action } = this.props;
        // console.log('Set Value', name, options[index]);
        // debugger;
        if (check.nonEmptyString(actionOnSelect) && check.nonEmptyString(action)){
            onChange(name, options[index], action);
        }
        else{
            onChange(name, options[index]);
        }
    }

    render() {
        const { formValue, name, enabled, options } = this.props;
        const {styleObj}=this.state;

        const value=getComponentValue(name, formValue);
        
        return (
            <div style={styleObj}>
                {options.map((option, index) => {
                    const inputProps = {
                        name,
                        type: "radio",
                        checked: value === option,
                        onChange: ()=>this.handleChange(index),
                        style:styleObj,
                        disabled:!enabled
                    };

                    const optionText=insertVariablesIntoText(option,  formValue);

                    return (
                        <div key={index}>
                            <label>
                                <input {...inputProps} />
                                {optionText}
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export { FormSelectRadio };
