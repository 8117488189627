
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ScheduleDataChecker} from './checker'
import {ScheduleDataFetcher} from './fetcher'
import {ScheduleNotFoundPopup} from './schedule-not-found-popup'

// machines
import {examInit} from 'machines/exam-init'


const {EVENTS, INITIALIZING_SCHEDULE_DATA_STATES:STATES} = examInit;


const InitializingScheduleView = (examGuid) =>
{
	return (
		<StateView>
			{{
				[STATES.CHECKING_DATA]: () => {
					const controlProps = {
						onSuccess: EVENTS.SUCCESS,
						onScheduleNotFound: EVENTS.SCHEDULE_NOT_FOUND,
						onSchedulesNotLoaded: EVENTS.SCHEDULES_NOT_LOADED
					}

					return (
						<StateControl {...controlProps}>
							{(props) => <ScheduleDataChecker examGuid={examGuid} {...props}/>}
						</StateControl>
					)
				},
				[STATES.FETCHING_DATA]: () => (
					<StateControl onSuccess={EVENTS.SUCCESS} onFail={EVENTS.ERROR}>
						{(props) => <ScheduleDataFetcher {...props}/>}
					</StateControl>
				),
				[STATES.DATA_NOT_FOUND]: () => (
					<ScheduleNotFoundPopup/>
				)
			}}
		</StateView>
	)
}


export {InitializingScheduleView}