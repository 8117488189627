
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (selectors)
import {getCacheData} from 'redux/reducers/selectors'
import {getCachedImageData} from 'redux/reducers/cache/selectors'
import {getCachedUrl} from 'redux/reducers/cache/images/selectors'


let Image = (imgProps) => {
    const { style } = imgProps;
    const newImgProps = style
        ? { ...imgProps, style: { ...style, pointerEvents: "none", userSelect: "none" } }
        : { ...imgProps, style: { pointerEvents: "none", userSelect: "none" } };

    return <img {...newImgProps} />;
};


const mapStoreToProps = (store) => ({store});
const mergeProps = ({store}, {dispatch}, {src, ...imgProps}) =>
{
	const cachedImageData = getCachedImageData(getCacheData(store));
	const cachedUrl = getCachedUrl(cachedImageData, src);

	return {src: cachedUrl || src, ...imgProps};	
}

Image = connect(mapStoreToProps, undefined, mergeProps)(Image);


export {Image}