
// redux (action-types)

import {CACHE_IMAGE} from 'redux/reducers/cache/images/action-types'


const cacheImage = (imageUrl, blobUrl) => ({
	type: CACHE_IMAGE,
	imageUrl,
	blobUrl
});


export {cacheImage}