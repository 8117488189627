
// npm
import {containsDuplicates} from 'custom/array-helper'


// CONSTANTS
// ----------------------------------------------------------------------------

const MESSAGE_IDS = 
{
	GENERAL:
	{
		CONFIRM_ACTION: 'ConfirmAction',
		PASSWORD: 'Password',
		CONFIRM: 'OK',
		CANCEL: 'Cancel',
		CLOSE: 'Close',
		NUMBERS: 'Numbers',
		START: 'Start',
		BACK: 'Back',
		RESUME: 'Resume',
		PAUSED: 'Paused',
		FINISH: 'Finish',
		LOG_OFF: 'LogOff',
		MARKS: 'Marks',
		MARK: 'Mark',
		NEXT: 'Next',
		PREVIOUS: 'Previous',
		BROWSER_NAV_INTERRUPTED: 'BrowserNavigationInterrupt'
	},
	LOGIN: 
	{
		APP_BAR_TITLE: 'AppBar_Login',
		TITLE: 'Login_Title',
		USERNAME: 'UserName',
		MESSAGE: 'LoginMessage',
		BUTTON: 'LogIn',
		WRONG_PASSWORD: 'LoginFailed',
		SUSPENDED: 'LoginSuspended',
		INVALID_LOGIN_TOKEN: 'InvalidLoginToken',
	},
	FORGOTTEN_PASSWORD:{
		TITLE: 'LoginForgot',
		INFO: 'LoginForgotInfo',
		RETURN: 'LoginForgotReturn',
		SUBMIT: 'LoginForgotSubmit',
		SUBMITTING: 'LoginForgotSubmitting',
		ERROR: 'LoginForgotError',
		SUCCESS: 'LoginForgotSuccess',
	},
	SET_PASSWORD: 
	{
		APP_BAR_TITLE: 'AppBar_SetPassword', // Set Password
		TITLE: 'SetPassword_Title', // Set Password
		MESSAGE: 'SetPasswordMessage', // Welcome to XAMS, now set your password or else....
		NO_MATCH: 'SetPassword_NoMatch', // Passwords dont match
		INVALID_PASSWORD: 'Invalid_Password', // Your password is invalid...
		PASSWORD: 'SetPassword_Password',
		RETYPE: 'SetPassword_Retype',
		SUBMITTING: 'SetPassword_Submit' // Setting your password
	},
	RESET_PASSWORD:{
		TITLE: 'ResetPassword_Title',
		MESSAGE: 'ResetPassword_Message',
		SUBMITTING: 'ResetPassword_Submit', // Resetting your password
		SUCCESS: 'ResetPassword_Success',
		ERROR: 'ResetPassword_Error'
	},
	ELEARNING:{
		COMPLETE: 'Elearning_Complete',
		COMPLETE_RETURN: 'Elearning_Complete_Return',
	},
	RUBRIC: 
	{
		SUBMIT: 'Submit',
		RUBRIC: 'rubric',
		CONFIRM_NAME: 'ConfirmName',
		CONFIRM_INSTRUCTIONS: 'UnderstoodInstructions',
		INTRO: 'StudentHome'
	},
	SCHEDULE: 
	{
		APP_BAR_TITLE: 'AppBar_Schedules',
		TITLE: 'Schedule_Title',
		COMPLETED: 'Completed',
		START_TIME: 'StartTime',
		PASSWORD: 'SchedulePassword',
		PASSWORD_PROMPT: 'SchedulePassword_Info'
	},
	APP_BAR: 
	{
		WELCOME: 'WelcomeUser'
	},
	EXAM: 
	{
		ASSESSMENT_COMPLETE: 'AssessmentComplete',
		FINISH_CONFIRM_TITLE_1: 'Exam_FinishConfirm',
		FINISH_CONFIRM_TITLE_2: 'Exam_DoubleCheck',
		FINISH_CONFIRM_INFO_1: 'Exam_FinishConfirmInfo',
		FINISH_CONFIRM_INFO_2: 'Exam_DoubleCheckInfo',
		FINISH_CONFIRM_PART_TITLE_1: 'Exam_FinishConfirmPart',
		FINISH_CONFIRM_PART_TITLE_2: 'Exam_DoubleCheckPart',
		FINISH_CONFIRM_PART_INFO_1: 'Exam_FinishConfirmPartInfo',
		FINISH_CONFIRM_PART_INFO_2: 'Exam_DoubleCheckPartInfo',
		FINISH_CONFIRM_PRACTICE_TITLE_1: 'PracticeExam_FinishConfirm',
		FINISH_CONFIRM_PRACTICE_TITLE_2: 'PracticeExam_DoubleCheck',
		FINISH_CONFIRM_PRACTICE_INFO_1: 'PracticeExam_FinishConfirmInfo',
		FINISH_CONFIRM_PRACTICE_INFO_2: 'PracticeExam_DoubleCheckInfo',
		FINISH_PAPER_PART_ALERT_TITLE: 'Exam_FinishPaperPartAlert_Title',
		FINISH_PAPER_PART_ALERT_INFO: 'Exam_FinishPaperPartAlert_Info',
		RETURN_TO_EXAM: 'Exam_Return',
		RETURN_HOME: 'Exam_ReturnHome',
		PROGRESS: 'Progress',
		INSTRUCTIONS: 'Instructions',
		CANT_START: 'CantStart',
		SCENARIO_HEADING: 'ScenarioHeading',
		ALREADY_COMPLETED: 'Assessment_Already_Completed',
		PAUSED: 'Assessment_Paused',
		PAUSED_TITLE: 'Assessment_Paused_Title',
		CONFIRM_RESUME: 'Assessment_Confirm_Resume',
		CONFIRM_RESUME_TITLE: 'Assessment_Confirm_Resume_Title',		
		CANT_NAVIGATE: 'CantNavigateToExam',
		SIT_NEXT_RESIT: 'SitNextResit',
		PAPER_PART_NAV_INTERRUPTED: 'PaperPartNavigationInterrupt',
		QUESTION_NAV_TITLE: 'QuestionNavigationPanelTitle',
		START_ANSWERING: 'StartAnswering',
		MULTITEXT_MAX_CHARACTERS: 'MultiText_MaxLinesReached',
		PROCTORING_EXAM_OUTRO_MULTI: 'Proctoring-ExamOutroMulti',
		PROCTORING_EXAM_OUTRO_SINGLE: 'Proctoring-ExamOutroSingle',

		PROCTORING_INTEGRITY_ADVOCATE_EXAM_OUTRO_MULTI: 'ProctoringIaExamOutroMulti',
		PROCTORING_INTEGRITY_ADVOCATE_EXAM_OUTRO_SINGLE: 'ProctoringIaExamOutroSingle',
		PROCTORING_INTEGRITY_ADVOCATE_EXAM_OUTRO_PRACTICE: 'ProctoringIaExamOutroPractice',

		CONFIRM_MULTI_TEXT_OPTION_CHANGE: 'ConfirmMultiTextOptionChange'
	},
	SESSION:
	{
		INACTIVITY: 'Inactivity',
		DOUBLE_LOGIN: 'DoubleLoginWarning',
		DOUBLE_LOGIN_HEADING: 'DoubleLoginHeading'
	},
	PRACTICE_DOWNLOAD: {
		TIMED_OUT: 'PracticeDownloadTimedOut'
	},
	ERRORS: {
		NETWORK_ERROR_TITLE: 'ErrorNetworkErrorTitle',
		NETWORK_ERROR_SUBHEADER: 'ErrorNetworkErrorSubheader',
		EXAM_COMPLETER_OFFLINE_TITLE: 'ErrorExamCompleterOfflineTitle',
		EXAM_COMPLETER_OFFLINE_MESSAGE: 'ErrorExamCompleterOfflineMessage',
	},
	PDF_CHECK:{
		TITLE: 'PdfCheckTitleUIv6',
		SUPPORTED: 'PdfCheckSupportedUIv6',
		NOT_SUPPORTED: 'PdfCheckNotSupportedUIv6',
		OTHER_SUPPORTED: 'PdfCheckOtherSupportedUIv6',
		CONTINUE: 'PdfCheckContinueUIv6',
		CANCEL: 'PdfCheckCancelUIv6'		
	},
	AUDIO:{
		TEST_PAGE: 'AudioTestPageUIv6'
	}	
};


// SAFEGUARD
// ----------------------------------------------------------------------------

(function ensureMessageIdUniqueness() {
	const allMessageIds = [];
	Object.values(MESSAGE_IDS).forEach(messageGroup => {
		allMessageIds.push(...Object.values(messageGroup));
	});

	if (containsDuplicates(allMessageIds)) {
		throw "Dupe message ids found";
	}
})();


// INTERFACE
// ----------------------------------------------------------------------------

const getGeneralMessageIds = () => ([
	...Object.values(MESSAGE_IDS.GENERAL),
	...Object.values(MESSAGE_IDS.LOGIN),
	...Object.values(MESSAGE_IDS.SET_PASSWORD),
	...Object.values(MESSAGE_IDS.FORGOTTEN_PASSWORD),
	...Object.values(MESSAGE_IDS.RESET_PASSWORD)
]);

const getSessionMessageIds = () => ([
	...Object.values(MESSAGE_IDS.RUBRIC),
	...Object.values(MESSAGE_IDS.SCHEDULE),
	...Object.values(MESSAGE_IDS.APP_BAR),
	...Object.values(MESSAGE_IDS.EXAM),
	...Object.values(MESSAGE_IDS.SESSION),
	...Object.values(MESSAGE_IDS.ELEARNING),
	...Object.values(MESSAGE_IDS.PRACTICE_DOWNLOAD),
	...Object.values(MESSAGE_IDS.ERRORS),
	...Object.values(MESSAGE_IDS.PDF_CHECK),
	...Object.values(MESSAGE_IDS.AUDIO),	
]);

const messageNotDefined=(message)=>{
	return !message || message.toLowerCase()==='message undefined';
}


// EXPORT
// ----------------------------------------------------------------------------
export {
	MESSAGE_IDS,
	getGeneralMessageIds,
	getSessionMessageIds,
	messageNotDefined
}