import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { passwordApi } from "libs/api/interface/api-password";
import { authenticationApi } from "libs/api/interface/api-authentication";

import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getLogoUrl } from "redux/reducers/settings/client/selectors";
import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId, getUrlToken } from "redux/reducers/session/client/selectors";

import Fade from "@material-ui/core/Fade";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import WarningIcon from "@material-ui/icons/Warning";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

import { Image } from "components/presentation/image";
import { withMessages } from "components/hocs/messages";
import { MaterialText } from "components/presentation/material-text";
import { Align } from "components/layout/align";
import { ForgotPasswordInfo } from "../forgot_password/forgot-password-info";
import {
    getSetPasswordToken,
    getSetPasswordUserDetails,
} from "../login/form/login_token";

import * as ACTIONS from "../login/form/actions";
import { MESSAGE_IDS } from "constants/message-ids";

import { styles } from "./styles";
import { CONSTANTS } from "./constants";

const returnMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.RETURN;
const errorMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.ERROR;
const successMessageId = MESSAGE_IDS.FORGOTTEN_PASSWORD.SUCCESS;

const loginMessageId = MESSAGE_IDS.SET_PASSWORD.MESSAGE;
const loginTitleMessageId = MESSAGE_IDS.SET_PASSWORD.TITLE;
const usernameMessageId = MESSAGE_IDS.LOGIN.USERNAME;
const loginButtonMessageId = MESSAGE_IDS.SET_PASSWORD.BUTTON;
const passwordMessageId = MESSAGE_IDS.GENERAL.PASSWORD;
const retypePasswordMessageId = MESSAGE_IDS.SET_PASSWORD.RETYPE;
const noMatchMessageId = MESSAGE_IDS.SET_PASSWORD.NO_MATCH;
const invalidPasswordMessageId = MESSAGE_IDS.SET_PASSWORD.INVALID_PASSWORD;

const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const hasPasswordBeenSet = (response) => {
    if (!response) return false;

    const result = parseInt(response);
    if (isNaN(result)) return false;

    return result === CONSTANTS.RESULT_CODE.SUCCESS;
};

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.initializeBoundMethods();

        this.userInput = null;
        this.passwordInput = null;
        this.retypePasswordInput = null;

        this.state = {
            disableSubmit: true,
            submitting: false,
            submitted: null,
            showPassword: false,
            errorText: null,
        };
    }

    initializeBoundMethods() {
        this.onFieldChange = () => {
            const { submitting } = this.state;
            if (!submitting) {
                const filled = this.isFormFilled();
                this.setState({ disableSubmit: !filled });
            }
        };

        this.onSubmit = (e) => {
            e.preventDefault();

            const password = this.passwordInput.value;
            const retypePassword = this.retypePasswordInput.value;

            if (password !== retypePassword) {
                const errorText = this.props.messages[noMatchMessageId];

                this.setState({ errorText });
                this.passwordInput.focus();
            } else {
                this.submitPassword(password);
            }
        };

        this.submitPassword = () => {
            const setPasswordFailed = (response) => {
                this.setState({
                    submitting: false,
                    submitted: CONSTANTS.FAILED,
                });
            };

            const password = this.passwordInput.value;

            this.setState(
                {
                    submitting: true,
                    errorText: null,
                },
                () => {
                    const loginToken = getSetPasswordToken();
                    const payload = { password, token: loginToken };
                    const success = this.Reset
                        ? this.resetPasswordSuccess
                        : this.setPasswordSuccess;

                    authenticationApi
                        .resetPassword(payload)
                        .then(success, setPasswordFailed);
                }
            );
        };

        this.setPasswordSuccess = (result) => {
            if (hasPasswordBeenSet(result)) {
                const {
                    saveUsername,
                    savePassword,
                    onPasswordSet,
                } = this.props;

                const { id: username } = getSetPasswordUserDetails();
                const newPassword = this.passwordInput.value;

                saveUsername(username);
                savePassword(newPassword);

                onPasswordSet();
            } else {
                this.displayInvalidPasswordMessage();
            }
        };

        this.resetPasswordSuccess = (result) => {
            if (hasPasswordBeenSet(result)) {
                this.setState({
                    submitting: false,
                    disableSubmit: true,
                    submitted: CONSTANTS.SUCCESS,
                });
            } else {
                this.displayInvalidPasswordMessage();
            }
        };

        this.displayInvalidPasswordMessage = () => {
            const { messages } = this.props;
            const errorText = messages[invalidPasswordMessageId];
            this.setState({ errorText, submitting: false });
        };

        this.handleReturnToLogin = () => {
            const { onCancel, history, urlToken } = this.props;
            if (onCancel){
                // sessionStorageApi.resetDataFor(KEYS.STUDENT_LOGIN_DETAILS);            
                // sessionStorageApi.resetDataFor(KEYS.STUDENT_LOGIN);
                onCancel();
            }
            else{
                history.replace(`/login?${urlToken}`);
            }
        };

        this.handleShowPassword = () => {
            const { showPassword } = this.state;
            const _showPassword = !showPassword;
            this.setState({ showPassword: _showPassword });
        };
    }

    get Branding() {
        const { classes } = this.props;

        return (
            <div className={classes.logoBox}>
                <Image
                    className={classes.logo}
                    src={this.props.logoUrl}
                    alt="branding"
                />
            </div>
        );
    }

    get TitleMessage() {
        const { messages, pageType } = this.props;
        const titleMessageId =
            pageType === CONSTANTS.RESET
                ? MESSAGE_IDS.RESET_PASSWORD.TITLE
                : MESSAGE_IDS.SET_PASSWORD.TITLE;

        return messages[titleMessageId];
    }

    get Title() {
        const { classes } = this.props;

        return (
            <Align>
                <MaterialText className={classes.formText} variant="h5">
                    {this.TitleMessage}
                </MaterialText>
            </Align>
        );
    }

    get Form() {
        const { classes } = this.props;

        return (
            <form
                className={classes.form}
                autoComplete="false"
                onSubmit={this.onSubmit}
            >
                {this.UserInput}
                {this.PasswordInput}
                {this.ErrorPanel}
                {this.Buttons}
            </form>
        );
    }

    get UserInput() {
        const name = "existing-username";
        const label = this.props.messages[usernameMessageId];
        const { id } = getSetPasswordUserDetails();

        const inputRef = (input) => {
            this.userInput = input;
        };
        const inputProps = {
            ...this.BasicInputProps,
            name,
            inputRef,
            id: "exisiting-user-input",
            readOnly: true,
            value: id,
        };

        return this.getFormInput(name, label, false, <Input {...inputProps} />);
    }

    get PasswordInput() {
        return (
            <React.Fragment>
                {this.InitialPasswordInput}
                {this.RetypePasswordInput}
            </React.Fragment>
        );
    }

    get InitialPasswordInput() {
        const { showPassword } = this.state;
        const name = "initialPassword";
        const label = this.props.messages[passwordMessageId];

        const type = showPassword ? null : "password";
        const inputRef = (input) => {
            this.passwordInput = input;
        };
        const inputProps = {
            ...this.BasicInputProps,
            name,
            type,
            required: true,
            inputRef,
            id: "initial-password-input",
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleShowPassword}
                    >
                        {showPassword ? (
                            <VisibilityIcon />
                        ) : (
                            <VisibilityOffIcon />
                        )}
                    </IconButton>
                </InputAdornment>
            ),
        };

        return this.getFormInput(name, label, true, <Input {...inputProps} />);
    }

    get RetypePasswordInput() {
        const { showPassword } = this.state;
        const name = "retypePassword";
        const label = this.props.messages[retypePasswordMessageId];

        const type = showPassword ? null : "password";
        const inputRef = (input) => {
            this.retypePasswordInput = input;
        };

        const inputProps = {
            ...this.BasicInputProps,
            name,
            type,
            inputRef,
            id: "retype-password-input",
        };

        return this.getFormInput(name, label, true, <Input {...inputProps} />);
    }

    get BasicInputProps() {
        const { classes } = this.props;
        const { submitting } = this.state;

        return {
            autoComplete: "off",
            onChange: this.onFieldChange,
            disabled: submitting,
            className: classes.formInputText,
            classes: {
                input: classes.input,
                underline: classes.inputUnderline,
            },
        };
    }

    getFormInput(name, label, required = false, inputElement) {
        const { classes } = this.props;

        return (
            <FormControl margin="normal" required={required} fullWidth>
                <InputLabel
                    className={classes.formInputText}
                    shrink={true}
                    htmlFor={name}
                >
                    {label}
                </InputLabel>
                {inputElement}
            </FormControl>
        );
    }

    get ErrorPanel() {
        const { classes } = this.props;
        const { errorText } = this.state;

        if (!errorText) {
            return null;
        }

        return (
            <span className={classes.errorContainer}>
                <WarningIcon
                    className={`${classes.error} ${classes.errorIcon}`}
                />
                <MaterialText className={classes.error}>
                    {errorText}
                </MaterialText>
            </span>
        );
    }

    get Buttons() {
        const { classes } = this.props;

        return (
            <div className={classes.buttons}>
                <div>{this.ReturnButton}</div>
                <div>{this.SubmitButton}</div>
            </div>
        );
    }

    get SubmitButton() {
        const { disableSubmit, submitting } = this.state;
        const { messages } = this.props;
        const disabled = disableSubmit || submitting;
        const props = {
            type: "submit",
            variant: "contained",
            color: "primary",
            disabled,
        };
        const label = this.TitleMessage;

        return <Button {...props}>{label}</Button>;
    }

    get ReturnButton() {
        const { submitting } = this.state;
        const { messages } = this.props;
        const disabled = submitting;
        const props = {
            variant: "contained",
            color: "secondary",
            disabled,
            onClick: this.handleReturnToLogin,
        };
        const label = messages[returnMessageId];

        return <Button {...props}>{label}</Button>;
    }

    get Reset() {
        const { pageType } = this.props;
        return pageType === CONSTANTS.RESET;
    }

    get Set() {
        const { pageType } = this.props;
        return pageType === CONSTANTS.SET;
    }

    get InfoPanel() {
        const { submitting, submitted } = this.state;
        const { messages } = this.props;
        const infoMessageId = this.Reset
            ? MESSAGE_IDS.RESET_PASSWORD.MESSAGE
            : MESSAGE_IDS.SET_PASSWORD.MESSAGE;
        const submittingMessageId = this.Reset
            ? MESSAGE_IDS.RESET_PASSWORD.SUBMITTING
            : MESSAGE_IDS.SET_PASSWORD.SUBMITTING;
        const successMessageId = this.Reset
            ? MESSAGE_IDS.RESET_PASSWORD.SUCCESS
            : MESSAGE_IDS.SET_PASSWORD.SUCCESS;
        const errorMessageId = MESSAGE_IDS.RESET_PASSWORD.ERROR;

        let status = CONSTANTS.INFORMATION;
        const { first } = getSetPasswordUserDetails();
        let message = messages[infoMessageId].replace("~", first);

        if (submitting) {
            status = CONSTANTS.SUBMITTING;
            message = messages[submittingMessageId];
        } else if (submitted) {
            status = submitted;
            message =
                status === CONSTANTS.SUCCESS
                    ? messages[successMessageId]
                    : messages[errorMessageId];
        }

        const props = { message, status };

        return <ForgotPasswordInfo {...props} />;
    }

    isFormFilled() {
        const { passwordInput, retypePasswordInput } = this;

        return (
            this.isFieldFilled(passwordInput) &&
            this.isFieldFilled(retypePasswordInput)
        );
    }

    isFieldFilled(inputElement) {
        if (inputElement.value !== "") {
            return true;
        }

        const computedStyle = window.getComputedStyle(inputElement);
        const autofilled = computedStyle.content.includes("filled");

        return autofilled;
    }

    render() {
        const { classes } = this.props;
        return (
            <Fade in timeout={1000}>
                <div className={classes.layout}>
                    <Card raised className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            {this.Branding}
                            {this.Title}
                            {this.InfoPanel}
                            {this.Form}
                        </CardContent>
                    </Card>
                </div>
            </Fade>
        );
    }
}

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);
    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);
    return {
        orgID: getOrgId(clientSessionData),
        logoUrl: getLogoUrl(clientSettingsData),
        urlToken: getUrlToken(clientSessionData),
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveUsername: (value) => dispatch(ACTIONS.setUsername(value)),
    savePassword: (value) => dispatch(ACTIONS.setPassword(value)),
    setLoginError: (msg) => {
        dispatch(ACTIONS.setLoginError(msg));
    },
    clearLoginError: () => {
        dispatch(ACTIONS.clearLoginError());
    },
});

ResetPassword = connect(mapStoreToProps, mapDispatchToProps)(ResetPassword);
ResetPassword = withStyles(styles)(ResetPassword);
ResetPassword = withMessages(ResetPassword);
ResetPassword = withRouter(ResetPassword);

export { ResetPassword };
