
// redux (action-types)
import {LOAD_TOKENS} from 'redux/reducers/session/tokens/action-types'
import {LOAD_SETTINGS, CREATE_SETTINGS} from 'redux/reducers/settings/client/action-types'
import {LOAD_SESSION as LOAD_USER_SESSION} from 'redux/reducers/session/user/action-types'
import {LOAD_SESSION as LOAD_CLIENT_SESSION, CREATE_SESSION} from 'redux/reducers/session/client/action-types'
import {SET_NETWORK_ERROR_INFO} from 'redux/reducers/app/action-types'

const loadClientSession = () => ({type: LOAD_CLIENT_SESSION});
const loadUserSession = () => ({type: LOAD_USER_SESSION});
const loadClientSettings = () => ({type: LOAD_SETTINGS});
const loadTokens = () => ({type: LOAD_TOKENS});


const createClientSession = (orgId, urlToken) => ({
	type: CREATE_SESSION,
	urlToken,
	orgId
});

const createClientSettings = (logo, appBarLogo, primaryColour, secondaryColour, enableForgottenPassword, legacySingleSignon, adminUrl) => ({
	type: CREATE_SETTINGS,
	primaryColour,
	secondaryColour,
	appBarLogo,
	logo,
	enableForgottenPassword,
	legacySingleSignon,
	adminUrl	
});

const setNetworkErrorInfo = (value) => ({
	type: SET_NETWORK_ERROR_INFO,
	value
});

export {
	loadClientSession,
	loadUserSession,
	loadClientSettings,
	loadTokens,
	createClientSession,
	createClientSettings,
	setNetworkErrorInfo
}