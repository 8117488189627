
// npm
import React from 'react'
import PropTypes from 'prop-types'

// utils
import {scheduledBugReports} from 'libs/browser_storage/apis/scheduled-bug-reports'


const SEND_INTERVAL = 10000;


class ScheduledBugReportSender extends React.Component
{
	componentDidMount()
	{
		scheduledBugReports.processQueue();	

		this.timeoutId = setInterval(() => {
			scheduledBugReports.processQueue();
		}, SEND_INTERVAL);
	}

	render()
	{
		return this.props.children;
	}

	componentWillUnmount()
	{
		clearInterval(this.timeoutId);
	}
}

ScheduledBugReportSender.propTypes = {
	children: PropTypes.node.isRequired
}


export {ScheduledBugReportSender}