import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getProctorioData } from "redux/reducers/selectors";
import { getParent, getDebugMode } from "redux/reducers/proctorio/selectors";
import {
    clearData,
    setClientName,
    setUserInfo1,
    setUserInfo2,
    setExamGuid,
} from "../actions";

import { AppPageModifier } from "components/pages/app-page-modifier";

import { decodeString, encodeString } from "../helper";

class ProctorioReturnPage extends Component {
    state = {};

    constructor(props) {
        super(props);

        const {
            match: { params },
        } = props;

        this.clientName = params.clientName;
        this.userInfo1 = decodeString(params.userInfo1);
        this.userInfo2 = decodeString(params.userInfo2);
    }

    componentDidMount() {
        const { proctorioActive } = this.props;

        if (proctorioActive) {
            const { proctorioDebug } = this.props;

            if (proctorioDebug) {
                const { dataClear } = this.props;

                dataClear(true);
                const userInfo1 = encodeString(this.userInfo1);
                const userInfo2 = encodeString(this.userInfo2);
                const url = `http://localhost:3000/proctorio-return/${this.clientName}/${userInfo1}/${userInfo2}`;

                window.location = url;
            } else {
                window.top.postMessage(
                    ["exam_state_change", "exam_end"],
                    "https://getproctorio.com"
                );
            }
        } else {
            const {
                history,
                clientNameSet,
                userInfo1Set,
                userInfo2Set,
                examGuidSet,
            } = this.props;

            clientNameSet(this.clientName);
            userInfo1Set(this.userInfo1);
            userInfo2Set(this.userInfo2);
            examGuidSet(null);

            history.push(`/proctorio/return`);
        }
    }

    render() {
        const appBarProps = {
            title: "Checking Proctorio",
            loadingTitle: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="proctorio-end-page"
                    appBarProps={appBarProps}
                    loading
                />
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);

    return {
        proctorioActive: getParent(proctorioData),
        proctorioDebug: getDebugMode(proctorioData),
    };
};

const mapDispatchToProps = (dispatch) => ({
    dataClear: (value) => dispatch(clearData(value)),
    clientNameSet: (value) => dispatch(setClientName(value)),
    userInfo1Set: (value) => dispatch(setUserInfo1(value)),
    userInfo2Set: (value) => dispatch(setUserInfo2(value)),
    examGuidSet: (value) => dispatch(setExamGuid(value)),
});

ProctorioReturnPage = connect(
    mapStoreToProps,
    mapDispatchToProps
)(ProctorioReturnPage);

ProctorioReturnPage = withRouter(ProctorioReturnPage);

export { ProctorioReturnPage };
