
// npm
import React from 'react'
import check from 'check-types'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

// material-ui
import {styles} from './form-styles'
import Fade from '@material-ui/core/Fade'
import Input from '@material-ui/core/Input'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {withMessages} from 'components/hocs/messages'
import {MaterialText} from 'components/presentation/material-text'
import {LoadingSpinner} from 'components/presentation/loading-spinner'

// redux
import {setExamPassword} from './actions'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getExamPassword} from 'redux/reducers/exam/selectors'
import {getPasswordError} from 'redux/reducers/exam/selectors'
import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getProctoringMode} from 'redux/reducers/settings/client/selectors'

// other
import {MESSAGE_IDS} from 'constants/message-ids'


const submitMessageId = MESSAGE_IDS.RUBRIC.SUBMIT;
const cancelMessageId = MESSAGE_IDS.GENERAL.CANCEL;
const passwordMessageId = MESSAGE_IDS.SCHEDULE.PASSWORD;
const schedulePasswordPromptMessageId = MESSAGE_IDS.SCHEDULE.PASSWORD_PROMPT;


// ExamPasswordForm (not connected to store)
// -------------------------------------------

class ExamPasswordForm extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = { value: "" };																										// password value
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.onFieldChange = (evt) => {
			this.setState({value: evt.target.value});
		}

		this.onChange = (wasEmpty, isEmpty) => {
			 if (wasEmpty && !isEmpty) { this.props.onCanSubmit(); }
			 else if (isEmpty && !wasEmpty) { this.props.onCantSubmit(); }
			 else if (!isEmpty && this.props.disableSubmit) { this.props.onCanSubmit(); }
		}

		this.focusOnPassword = () => {
			if (this.passwordInput !== null) {
				this.passwordInput.focus();
			}
		}

		this.onSubmit = (e) => {
			e.preventDefault(); 
			this.props.relayPassword(this.state.value);
			this.props.onSubmit();
			setTimeout(this.focusOnPassword, 100);
		}
	}

	componentDidUpdate(_, previousState)
	{
		if (previousState.value === this.state.value) { return; }

		const isEmpty = check.emptyString(this.state.value);
		const wasEmpty = check.emptyString(previousState.value);
		this.onChange(wasEmpty, isEmpty);
	}

	componentDidMount()
	{
    this.passwordInput.focus();
	}

	returnToSchedulesPage(props)
	{
		this.props.history.replace('/schedules');
	}
	
	render()
	{
		const { classes } = this.props;

		return (
			<div className={classes.layout}>
				<Fade in timeout={1000}>
					<Paper className={classes.form}>
						{this.InfoText}
						<Divider/>
						<form className={classes.form} onSubmit={this.onSubmit}>
							{this.PasswordInput}
							{this.ErrorPanel}
							<div className={classes.buttons}>
								{this.CancelButton}
								{this.SubmitButton}
							</div>
						</form>
						{this.LoadingPanel}
					</Paper>
				</Fade>
			</div>
		);
	}

	get InfoText()
	{
		const {classes} = this.props;
		return(
			<React.Fragment>
				<div className={classes.passwordText}>
					<LockOpenIcon className={`${classes.lockIcon} ${classes.text}`}/>
					<MaterialText className={classes.text} variant="h6">
						{this.props.messages[schedulePasswordPromptMessageId]}
					</MaterialText>
				</div>
			</React.Fragment>
		)
	}

	get PasswordInput()
	{
		const {classes} = this.props;

		const props = {
			className: classes.formInputText,
			classes: {underline: classes.inputUnderline},
			autoFocus: true,
			type: 'password',
			value: this.state.value,
			onChange: this.onFieldChange,
			disabled: !!this.props.disableInputs,
			inputRef: (input) => { this.passwordInput = input; }
		};

		return (
			<FormControl margin="normal" required fullWidth>
				<InputLabel className={classes.formInputText} htmlFor="password">
					{this.props.messages[passwordMessageId]}
				</InputLabel>
				<Input {...props} />
			</FormControl>
		)
	}

	get ErrorPanel()
	{
		if (!this.props.errorText) { return null; }
		const {classes} = this.props;

		return (
			<MaterialText className={classes.error}>
				{this.props.errorText}
			</MaterialText>
		);
	}

	get SubmitButton()
	{
		const {classes} = this.props;

		const props = {
			type: 'submit',
			variant: "contained",
			color: "primary",
			classes: {
				root: `${classes.submit} ${classes.button}`,
				disabled: classes.buttonDisabled
			},
			disabled: !!this.props.disableSubmit
		};

		return (
			<Button {...props}>
				{this.props.messages[submitMessageId]}
			</Button>
		)
	}

	get CancelButton()
	{
		//jd 4/10/2020 - suppress cancel button if proctored and single exam
		if (this.props.proctoringMode == 1) return;

		const {classes} = this.props;

		const props = {
			variant: "contained",
			color: "secondary",
			classes: {
				root: classes.submit,
				disabled: classes.buttonDisabled
			},
			onClick: () => this.returnToSchedulesPage(props)
		};

		return (
			<Button {...props}>
				{this.props.messages[cancelMessageId]}
			</Button>
		)
	}

	get TextField()
	{
		const props = {
			disabled: !!this.props.disableInputs,
			autoFocus: true,
			onChange: this.onFieldChange,
			type: 'password'
		}

		return <TextField {...props}/>;
	}

	get LoadingPanel()
	{
		return !this.props.showSpinner ? null :
			<LoadingSpinner color="secondary"/>;
	}
}


ExamPasswordForm.propTypes = {
	disableInputs: PropTypes.bool,
	disableSubmit: PropTypes.bool,
	onCanSubmit: PropTypes.func,
	onCantSubmit: PropTypes.func,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	errorText: PropTypes.string,
	relayPassword: PropTypes.func.isRequired,
	showSpinner: PropTypes.bool,
	password: PropTypes.string,
	history: PropTypes.object.isRequired,
	messages: PropTypes.shape({
		[submitMessageId]: PropTypes.string.isRequired,
		[cancelMessageId]: PropTypes.string.isRequired,
		[passwordMessageId]: PropTypes.string.isRequired,
		[schedulePasswordPromptMessageId]: PropTypes.string.isRequired
	})
}


// ExamPasswordForm (connected to store)
// -------------------------------------------

const mapStoreToProps = (store) => ({
	errorText: getPasswordError(getExamData(store)),
	password: getExamPassword(getExamData(store)),
	proctoringMode: getProctoringMode(getClientSettingsData(getSettingsData(store)))
});

const mapDispatchToProps = (dispatch) => ({
	relayPassword: (value) => dispatch(setExamPassword(value))
});

ExamPasswordForm = connect(mapStoreToProps, mapDispatchToProps)(ExamPasswordForm);


// ExamPasswordForm (connected to router/styles/messages)
// -------------------------------------------

ExamPasswordForm = withRouter(ExamPasswordForm);
ExamPasswordForm = withStyles(styles)(ExamPasswordForm);
ExamPasswordForm = withMessages(ExamPasswordForm);



// EXPORT
// -------------------------------------------
export {ExamPasswordForm}