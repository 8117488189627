// Responsibility:
// Load image from url asynchronously. Invoke callbacks on result

// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (actions)
import {cacheImage} from './actions'

// redux (selectors)
import {getCacheData} from 'redux/reducers/selectors'
import {getCachedImageData} from 'redux/reducers/cache/selectors'
import {getCachedUrl} from 'redux/reducers/cache/images/selectors'

// utils
import {api} from 'libs/api/api'
import {generalApi} from 'libs/api/interface/api-general'


class ImagePreloader extends React.Component
{
	componentDidMount()
	{
		const {url} = this.props;

		if (this.props.isCached(url)) {
			this.props.onSuccess();
		}
		else if (url.startsWith(api.endpointUrl)) {
			this.loadIntoRam();
		}
		else {
			this.loadIntoBrowserStorage();
		}
	}

	loadIntoRam()
	{
		const onSuccess = ({blob}) => {
			const blobUrl = URL.createObjectURL(blob);
			this.props.cacheImage(blobUrl);
			this.props.onSuccess();
		}

		const {url, onFail} = this.props;
		generalApi.fetchResource(url).then(onSuccess, onFail);
	}

	loadIntoBrowserStorage()
	{
		const img = new Image();
		img.onload = () => { this.props.onSuccess(); }
		img.onerror = () => { this.props.onFail(); }
		img.src = this.props.url;
	}

	render()
	{
		return null;
	}
}

ImagePreloader.propTypes = {
	url: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	isCached: PropTypes.func.isRequired,
	cacheImage: PropTypes.func.isRequired
}


const mapStoreToProps = (store) => ({store});
const mapDispatchToProps = (dispatch) => ({dispatch});

const mergeProps = ({store}, {dispatch}, {onSuccess, onFail, url}) =>
{
	const finalProps = {onSuccess, onFail, url};

	const cachedImageData = getCachedImageData(getCacheData(store));
	finalProps.isCached = () => !!getCachedUrl(cachedImageData, url);
	finalProps.cacheImage = (cachedUrl) => dispatch(cacheImage(url, cachedUrl));

	return finalProps;
}

ImagePreloader = connect(mapStoreToProps, mapDispatchToProps, mergeProps)(ImagePreloader);


export {ImagePreloader}