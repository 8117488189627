
// npm
import React from 'react'

// react
import {ExamRequestSaverContext} from './context'


const withExamRequestSaver = (Component) =>
{
	return (props) => {
		return (
			<ExamRequestSaverContext.Consumer>
				{(examRequestSaver) => <Component examRequestSaver={examRequestSaver} {...props}/>}
			</ExamRequestSaverContext.Consumer>
		)
	}
}


export {withExamRequestSaver}