import React, { Component } from "react";
import * as signalR from "@microsoft/signalr";
import moment from "moment";
import { check } from "@xams-utils/check-types";
import { connect } from "react-redux";

import {
    getExamData,
    getSettingsData,
    getSessionData,
} from "redux/reducers/selectors";
import { getFormRunGuid } from "redux/reducers/exam/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getHubUrl } from "redux/reducers/settings/app/selectors";
import { getIsDownloadablePracticeExam } from "redux/reducers/settings/client/selectors";
import { getPublicExamUserName } from "redux/reducers/session/user/selectors";

import {
    getUserSessionData,
    getClientSessionData,
} from "redux/reducers/session/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";

import { getName, shouldShowFeedback } from "redux/reducers/exam/selectors";

import { MaterialText } from "components/presentation/material-text";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { ProgressLabel } from "./progress-label";

import { OutroTitle } from "../outro-title";

import { ExamLayout } from "components/pages/exam/_layout/layout";
import { Align } from "components/layout/align";

import { getTimeStamp } from "utils/time-stamp";
import { errorApi } from "libs/api/interface/api-error";

import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS, messageNotDefined } from "constants/message-ids";

import { LearnerFeedbackPanel } from "components/pages/_exam/initialized/outro/page/learner-feedback-panel";

const LOCAL_MESSAGES = {
    PREPARING_DOWNLOAD: "Preparing Your Results For Downloading",
    PREPARING_DOWNLOAD_TITLE: "Preparing Results",
    DOWNLOAD_RESULTS: "Click Here to Download Your Results",
    DOWNLOAD_RESULTS_TITLE: "Download Results",
    ERROR_TITLE: "Unable to download results",
    ERROR_MESSAGE: "Sorry, we are unable to download your results at present",
};

const styles = ({ spacing, palette, breakpoints }) => ({
    root: {
        display: "flex",
        width: "100%",
        minHeight: "32px",
        "&>div": {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
        },
        "&>div:first-child": {
            paddingRight: spacing.unit * 2,
        },
        "&>div:last-child": {
            width: "100%",
        },
    },
    select: {
        "&>div": {
            cursor: "pointer",
        },
    },
    noSelect: {
        "&>div": {
            cursor: "default",
        },
    },
    panel: {
        backgroundColor: palette.background.light,
        //maxWidth: 800,
        [breakpoints.down(820)]: {
            width: "100%",
        },
        [breakpoints.up(821)]: {
            width: 800,
        },
        marginTop: spacing.unit * 2,
        paddingTop: spacing.unit * 2,
        alignSelf: "stretch",
    },
    text: {
        fontSize: "large",
    },
});

const getErrorObject = (errorObj) => {
    var obj = {};

    try {
        if (check.string(errorObj)) {
            return errorObj;
        }

        obj.text = errorObj.toString();
        if (check.assigned(errorObj.message)) {
            obj.message = errorObj.message;
        }

        if (errorObj) {
            for (let key in errorObj) {
                obj[key] = errorObj[key];
            }
        }
    } catch (e) {
        debugger;
    }

    return obj;
};

class ResultDownloader extends Component {
    constructor(props) {
        super(props);

        this.willSendErrorReport = true;

        this.invoked = false;
        this.connected = false;
        this.timedOutValue = 60000;
        this.startedTimeStamp = null;
        this.progressArray = [];
        this.tryStart = {
            max: 3,
            timeOut: 15000,
            tries: 0,
        };

        this.tryAgainTimer = null;
        this.timer = null;

        this.handleClick = this.handleClick.bind(this);
        this.setupSignalR = this.setupSignalR.bind(this);
        this.sendErrorReport = this.sendErrorReport.bind(this);
        this.timedOut = this.timedOut.bind(this);
        this.handleHubStartError = this.handleHubStartError.bind(this);
        this.startConnection = this.startConnection.bind(this);

        this.state = {
            url: null,
            progress: null,
            error: false,
        };
    }

    componentDidMount() {
        this.startConnection();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        clearTimeout(this.tryAgainTimer);
    }

    startConnection() {
        this.startedTimeStamp = getTimeStamp();
        this.timer = setTimeout(this.timedOut, this.timedOutValue);
        this.setupSignalR();
    }

    timedOut() {
        clearTimeout(this.timer);
        clearTimeout(this.tryAgainTimer);

        this.sendErrorReport("Timed out", "Timed Out");
    }

    setupSignalR() {
        const { hubUrl, orgId } = this.props;
        const method = "practiceExamAnswersHub";
        const url = `${hubUrl}${method}`;

        let connection = new signalR.HubConnectionBuilder();
        if (this.tryStart.tries > 0)
            connection = connection.withUrl(url, {
                transport: signalR.HttpTransportType.LongPolling,
            });
        else connection = connection.withUrl(url);
        connection = connection.build();

        connection.on("Progress", (data) => {
            this.setState({ progress: data });
            this.progressArray.push({ timeStamp: getTimeStamp(), data });
        });

        connection.on("Link", (data) => {
            clearTimeout(this.timer);
            this.setState({ url: data });
        });

        const { formRunGuid } = this.props;
        const fileName = this.getFileName();
        const methodInvoke = "practiceExamAnswersByGUID";

        connection
            .start()
            .then(() => {
                this.connected = true;
                connection
                    .invoke(methodInvoke, formRunGuid, fileName, orgId)
                    .then(() => {
                        this.invoked = true;
                    })
                    .catch((e) => {
                        this.sendErrorReport(
                            e,
                            `Trying to invoke method: ${methodInvoke}`
                        );
                    });
            })
            .catch((e) => {
                this.handleHubStartError(e, url);
            });

        this.setState({ progress: 0 });
    }

    handleHubStartError(error, url) {
        this.tryStart.tries++;
        const setError = this.tryStart.tries === this.tryStart.max;

        this.sendErrorReport(
            error,
            `Trying to start connection (#${this.tryStart.tries}): ${url}`,
            setError
        );

        if (!setError) {
            this.tryAgainTimer = setTimeout(
                this.startConnection,
                this.tryStart.timeOut
            );
        }
    }

    sendErrorReport(e, message = "Error", setError = true) {
        clearTimeout(this.timer);
        clearTimeout(this.tryAgainTimer);

        const { formRunGuid, publicUserName, examName } = this.props;
        const { progress } = this.state;

        const reason = {
            startedTimeStamp: this.startedTimeStamp,
            errorTimeStamp: getTimeStamp(),
            formRunGuid,
            connected: this.connected,
            invoked: this.invoked,
            progress,
            error: getErrorObject(e),
            userName: publicUserName,
            examName,
            progressArray: this.progressArray,
        };

        const payload = {
            reporterGuid: "PRACTICE EXAM DOWNLOADER[V6/PLAYER]",
            bugDescription: message,
            reason,
        };

        if (this.willSendErrorReport) {
            errorApi.sendBugReport(payload);
        } else {
            console.log("sendBugReport", payload);
            console.log("Error", e);
        }

        if (setError) {
            this.setState({ error: true });
        }
    }

    handleClick() {
        const { url } = this.state;

        const { onFinish } = this.props;
        window.open(url);
        onFinish();
    }

    getFileName() {
        const { examName } = this.props;
        //const examName='Maths 101 (MS111)';
        const today = moment();
        const title = encodeURIComponent(examName.replace(/\s/g, "_"));
        return `${title}_${today.format("DD_MM_YYYY")}.pdf`;
    }

    displayMessage() {
        const { classes, messages } = this.props;
        const { progress, url, error } = this.state;

        if (error) {
            let message = messages[MESSAGE_IDS.PRACTICE_DOWNLOAD.TIMED_OUT];

            if (messageNotDefined(message))
                message = LOCAL_MESSAGES.ERROR_MESSAGE;

            return (
                <MaterialText className={classes.text}>{message}</MaterialText>
            );
        }
        if (url) {
            const message = LOCAL_MESSAGES.DOWNLOAD_RESULTS;

            return (
                <MaterialText className={classes.text}>{message}</MaterialText>
            );
        } else {
            const progressProps = {
                value: progress,
                message: LOCAL_MESSAGES.PREPARING_DOWNLOAD,
            };

            return <ProgressLabel {...progressProps} />;
        }
    }

    render() {
        const { classes, isDownloadablePracticeExam, shouldShowFeedback } =
            this.props;

        if (!isDownloadablePracticeExam) {
            return null;
        }
        const { url, error } = this.state;
        const divProps = {
            onClick: url && !error ? this.handleClick : null,
        };
        const divClass = `${classes.root} ${
            url ? classes.select : classes.noSelect
        }`;

        const icon = error ? (
            <ErrorOutlineIcon fontSize="large" />
        ) : (
            <CloudDownloadIcon fontSize="large" />
        );

        const title = error
            ? LOCAL_MESSAGES.ERROR_TITLE
            : url
            ? LOCAL_MESSAGES.DOWNLOAD_RESULTS_TITLE
            : LOCAL_MESSAGES.PREPARING_DOWNLOAD_TITLE;

        const examLayoutProps = {
            bar: {
                content: (
                    <React.Fragment>
                        <OutroTitle title={title} />
                    </React.Fragment>
                ),
            },
            centre: {
                content: (
                    <Align top>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            {shouldShowFeedback && <LearnerFeedbackPanel />}
                            <Card className={classes.panel}>
                                <CardContent>
                                    <div className={divClass}>
                                        <div {...divProps}>{icon}</div>
                                        <div {...divProps}>
                                            {this.displayMessage()}
                                            {/* <Typography>Download Results</Typography> */}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Align>
                ),
            },
        };

        return <ExamLayout {...examLayoutProps} />;
    }
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);
    const appSettingsData = getAppSettingsData(settingsData);

    const sessionData = getSessionData(store);
    const userSessionData = getUserSessionData(sessionData);
    const clientSessionData = getClientSessionData(sessionData);

    return {
        formRunGuid: getFormRunGuid(examData),
        hubUrl: getHubUrl(appSettingsData),
        isDownloadablePracticeExam:
            getIsDownloadablePracticeExam(clientSettingsData),
        examName: getName(examData),
        publicUserName: getPublicExamUserName(userSessionData),
        orgId: getOrgId(clientSessionData),
        shouldShowFeedback: shouldShowFeedback(examData),
    };
};

ResultDownloader = withMessages(withStyles(styles)(ResultDownloader));
ResultDownloader = connect(mapStoreToProps)(ResultDownloader);

// https://test.xams.co.uk/learner/exam/demo?userid=491030&examid=228049&downloadResult=true
// https://fs2019.xams.co.uk/learner/exam/demo?userid=832321&examid=227733&downloadResult=true

// https://test.xams.co.uk/learner/exam/demo?userid=491030&examid=227733&downloadResult=true
// https://v5-3.xams.co.uk/learner/exam/demo?&userid=774104&examID=154429&downloadResult=true
// https://dev.xams.co.uk/learner/exam/demo?userid=829059&examid=315949&downloadResult=true

export { ResultDownloader };
