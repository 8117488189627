import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import check from 'check-types'

import {
    setEntered,
    setParent,
    setClientName,
    setUserInfo1,
    setUserInfo2,
    setExamGuid,
} from "./actions";

import { AppPageModifier } from "components/pages/app-page-modifier";
import { LoadingSpinner } from "components/presentation/loading-spinner";

const getParameters = (params, variables) =>{
    const returnObj = {};

    for (const variable in variables) {
        if (check.assigned(params[variable])) {
            returnObj[variable] = params[variable];
        }
    }

    return returnObj;
}

class ProctorioStartPage extends Component {
    state = {};

    constructor(props) {
        super(props);

        const {
            match: { params },
        } = props;

        const {
            clientName,
            userInfo1,
            userInfo2,
            examGuid,
        } = getParameters(params, {
            clientName,
            userInfo1,
            userInfo2,
            examGuid,
        });

        const validParameters =
            clientName && userInfo1 && userInfo2 && examGuid;

        this.clientName = clientName;
        this.userInfo1 = userInfo1;
        this.userInfo2 = userInfo2;
        this.examGuid = examGuid;

        this.state = { valid: validParameters };
    }

    componentDidMount() {
        const {
            enteredSet,
            parentSet,
            clientNameSet,
            userInfo1Set,
            userInfo2Set,
            examGuidSet,
            history
        } = this.props;

        enteredSet(true);
        parentSet(false);
        clientNameSet(this.clientName);
        userInfo1Set(this.userInfo1);
        userInfo2Set(this.userInfo2);
        examGuidSet(this.examGuid);

        history.push(`/proctorio/start`);
    }

    render() {
        const appBarProps = {
            title: "Authenicating Data",
            loadingTitle: true,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="authenicating-proctorio-data"
                    appBarProps={appBarProps}
                    loading
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    enteredSet: (value) => dispatch(setEntered(value)),
    parentSet: (value) => dispatch(setParent(value)),
    clientNameSet: (value) => dispatch(setClientName(value)),
    userInfo1Set: (value) => dispatch(setUserInfo1(value)),
    userInfo2Set: (value) => dispatch(setUserInfo2(value)),
    examGuidSet: (value) => dispatch(setExamGuid(value)),
});

ProctorioStartPage = connect(null, mapDispatchToProps)(ProctorioStartPage);

ProctorioStartPage = withRouter(ProctorioStartPage);

export { ProctorioStartPage };

// http://localhost:3000/proctorio/lantra/markburton/Qoni8535/81f85c8e-6991-43e4-9845-4f721bb921a5
