// npm
import React from 'react'
import {connect} from 'react-redux'

// react
import {Workings} from './workings'
import {Calculator} from './calculator'
import {MaterialScrollWrapper} from 'components/layout/scroll_wrapper/material-scroll-wrapper'

// redux (selectors)
import {getContentData} from 'redux/reducers/exam/selectors'
import {getExamData, getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getCalculatorEvaluationMode} from 'redux/reducers/settings/client/selectors'
import {getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {getWorkingsData} from 'redux/reducers/exam/content/questions/question/selectors'
import {getCurrentQuestionId, getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getWorkings} from 'redux/reducers/exam/content/questions/question/workings/selectors'

// redux (actions)
import {saveWorkings} from './actions'

import check from 'check-types';
import { addCalculatorSymbols } from './calculator-helper'


// WorkingsPanel (not connected to store)
// ------------------------------------------------------

const setFocusPosition=(elem,caretPos)=>{
  if(elem != null) {
    if(elem.createTextRange) {
        var range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
    }
    else {
        try{
            elem.focus();
            elem.setSelectionRange(caretPos, caretPos);
        }
        catch(e){
            elem.focus();
        }
    }
}  
}

class WorkingsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.workingsRef = React.createRef();
    this.setFocus = false;
    this.handleWorkingsChange = this.handleWorkingsChange.bind(this);
    this.handleCalculatorResult = this.handleCalculatorResult.bind(this);
  }

  componentDidUpdate() {
    if (this.setFocus) {
      const workingsElement = this.workingsRef.current;
      if (workingsElement){
        if (this.cursorPosition) {
          setFocusPosition(workingsElement, this.cursorPosition);
          this.cursorPosition = null;
        }
        else workingsElement.focus();
      }
      this.setFocus = false;
    }
  }

  handleWorkingsChange(value, setFocus = false) {
    if (check.number(setFocus)){
      this.setFocus = true;
      this.cursorPosition=setFocus;
    }
    else{
      this.setFocus = setFocus;
    }
    this.props.onChange(value);
  }

  handleCalculatorResult(_result) {
    const result = addCalculatorSymbols(_result);
    const { value } = this.props;
    const workingsElement = this.workingsRef.current;
    const { selectionStart, selectionEnd } = workingsElement;

    this.cursorPosition = selectionStart + result.length + 1;

    const workingsBeforeSelection = value.substring(0, selectionStart);
    const workingsAfterSelection = value.substring(selectionEnd);
    const newValue = `${workingsBeforeSelection}${result}\n${workingsAfterSelection}`;
    
    this.handleWorkingsChange(newValue, true);
  }

  render() {
    const workingsProps = {
      value: this.props.value,
      workingsRef: this.workingsRef,
      onChange: this.handleWorkingsChange
    };

    const wrapperDivStyle = {
      display: 'flex',
      width: 220,
      height: '100%',
      boxSizing: 'border-box',
      flexDirection: 'column'
    };

    return (
      <MaterialScrollWrapper horizontal={false}>
        <div style={wrapperDivStyle}>
          <Workings {...workingsProps} />
          {this.Calculator}
        </div>
      </MaterialScrollWrapper>
    );
  }

  get Calculator() {
    if (this.props.disableCalculator) {
      return null;
    }
    
    let {evaluationType} = this.props;
    if (!check.assigned(evaluationType)) evaluationType = 1;

    const props = {
      onResult: this.handleCalculatorResult,      
      evaluationType
    }
    return <Calculator {...props} />;
  }
}

// WorkingsPanel (connected to store)
// ------------------------------------------------------

const mapStoreToProps = store => {
  const contentData = getContentData(getExamData(store));
  const questionsData = getQuestionsData(contentData);
  const currentQuestionId = getCurrentQuestionId(contentData);
  const questionData = getQuestionDataById(questionsData)(currentQuestionId);

  const settingsData = getSettingsData(store);
  const clientSettingsData = getClientSettingsData(settingsData);

  return {
    value: getWorkings(getWorkingsData(questionData)),
    evaluationType: getCalculatorEvaluationMode(clientSettingsData),
    currentQuestionId
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: questionId => value => {
    dispatch(saveWorkings(questionId, value));
  }
});

const mergeProps = ({value, currentQuestionId, evaluationType}, {onChange}, {disableCalculator}) => ({
  onChange: onChange(currentQuestionId),
  disableCalculator,
  evaluationType,
  value
});

const args = [mapStoreToProps, mapDispatchToProps, mergeProps];
WorkingsPanel = connect(...args)(WorkingsPanel);


// Export
// ------------------------------------------------------
export {WorkingsPanel}