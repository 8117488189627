const getAppData = (store) => store.get('app');
const getCacheData = (store) => store.get('cache');
const getSessionData = (store) => store.get('session');
const getLoginData = (store) => store.get('login');
const getRubricData = (store) => store.get('rubric');
const getSchedulesData = (store) => store.get('schedules');
const getElearningData = (store) => store.get('elearning');
const getProctorioData = (store) => store.get('proctorio');
const getExamPassword = (store) => store.get('examPassword');
const getExamData = (store) => store.get('exam');
const getMessagesData = (store) => store.get('messages');
const getThemeData = (store) => store.get('theme');
const getSettingsData = (store) => store.get('settings');

export {
	getAppData,
	getCacheData,
	getSessionData,
	getLoginData,
	getRubricData,
	getSchedulesData,
	getElearningData,
	getProctorioData,
	getExamPassword,
	getExamData,
	getMessagesData,
	getThemeData,
	getSettingsData
}