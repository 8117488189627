
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// internal
import * as actions from './actions'


// SessionLoader (not connected to store)
// ---------------------------------------------

class SessionLoader extends React.Component
{
	componentDidMount()
	{
		this.props.loadUserSession();
		this.props.loadClientSession();
		this.props.loadClientSettings();
		this.props.loadTokens();
		this.props.onLoad();
	}

	render()
	{
		return null;
	}
}

SessionLoader.propTypes = {
	loadUserSession: PropTypes.func.isRequired,
	loadClientSession: PropTypes.func.isRequired,
	loadClientSettings: PropTypes.func.isRequired,
	loadTokens: PropTypes.func.isRequired,
	onLoad: PropTypes.func.isRequired
}


// SessionLoader (not connected to store)
// ---------------------------------------------

const mapDispatchToProps = (dispatch) => ({
	loadUserSession: () => dispatch(actions.loadUserSession()),
	loadClientSession: () => dispatch(actions.loadClientSession()),
	loadClientSettings: () => dispatch(actions.loadClientSettings()),
	loadTokens: () => dispatch(actions.loadTokens())
});

SessionLoader = connect(undefined, mapDispatchToProps)(SessionLoader);


// EXPORT
// ---------------------------------------------
export {SessionLoader}