// npm
import * as Immutable from 'immutable'

// xams-utils
import {check} from '@xams-utils/check-types'

// redux
import * as ACTION_TYPES from './action-types'

// utils
import {COOKIE_NAMES, cookies} from 'utils/cookies'
import {KEYS, sessionStorageApi} from 'libs/browser_storage/session-storage-api'



const handleSessionCreation = (state, action) =>
{
	const {id, guid, firstName, lastName, languageId, dateOfBirth, admin, learnerIdentifier, email} = action;

	const data = {id, guid, languageId, firstName, lastName, dateOfBirth, admin, learnerIdentifier, email};

	sessionStorageApi.saveDataTo(KEYS.USER, data);
	return Immutable.Map(data);
}

const handleSessionRemoval = (state, action) =>
{
	sessionStorageApi.resetDataFor(KEYS.USER);
	return Immutable.Map();
}

const loadFromQueryString = () =>
{
	const urlParameters = new URLSearchParams(window.location.search);
	const externalSessionData = urlParameters.get('x');
	if (!externalSessionData) { return; }

	const parsedSessionData = JSON.parse(externalSessionData);
	const data = parsedSessionData.user;
	sessionStorageApi.saveDataTo(KEYS.USER, data);

	return data;
}

const handleSessionLoad = () =>
{
	const data = loadFromQueryString() || sessionStorageApi.retrieveDataFrom(KEYS.USER);
	return Immutable.Map(data);
}


const USER_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.LOAD_SESSION:
			return handleSessionLoad(state, action);
		case ACTION_TYPES.CREATE_SESSION:
			return handleSessionCreation(state, action);
		case ACTION_TYPES.REMOVE_SESSION:
			return handleSessionRemoval(state, action);
		case ACTION_TYPES.SET_PUBLIC_EXAM_USER_NAME:
			return state.set('publicExamUserName', action.value);
		default:
			return state;
	}
}

export {USER_REDUCER}