// npm
import { Map as ImmutableMap } from "immutable";
import { check } from "@xams-utils/check-types";

// redux
import * as ACTION_TYPES from "./action-types";
import * as EXAM_ACTION_TYPES from "../../../action-types";
import { extractMessage } from "redux/reducers/exam/helpers";

// custom
import { getProp } from "custom/object-helper";

// TEMP
const extractTimeData = (data) => {
    if (!data["@AllowedTime"]) {
        return {};
    }

    const sectionTime = data["@AllowedTime"];
    const readingTime = data["@ReadingTime"];

    const totalTime = sectionTime + readingTime;
    const totalTimeRemaining = data["@TimeRemaining"] || totalTime;

    const sectionTimeRemaining =
        totalTimeRemaining < sectionTime ? totalTimeRemaining : sectionTime;
    const readingTimeRemaining =
        totalTimeRemaining < sectionTime
            ? 0
            : totalTimeRemaining - sectionTimeRemaining;

    return {
        sectionTime,
        readingTime,
        sectionTimeRemaining,
        readingTimeRemaining,
        warningTime: data["@WarningTime"],
        canBypassReadingTime: !!getProp(
            data,
            "PAPERPARTPROPERTIES.@AllowBypassReadingTime"
        ),
    }; // note: look into minimizing properties and instead infer these values in selectors
};

const getDisplayAllQuestions = (data) => {
    const flow = getProp(data, "SECTIONPROPERTIES.@Flow");

    return flow === "SinglePage";
};

const getMediaProperties = (data) => {
    const mediaUrl = getProp(data, "MEDIAPROPERTIES.@MediaURL");

    if (check.nonEmptyString(mediaUrl)) {
        const mediaObj = { url: mediaUrl };

        const autoPlay = getProp(data, "MEDIAPROPERTIES.@AutoPlay");
        mediaObj.autoPlay = autoPlay === "True";

        const canPause = getProp(data, "MEDIAPROPERTIES.@CanPause");
        mediaObj.canPause = canPause === "True";

        mediaObj.duration = 0;
        mediaObj.elapsed = 0;

        return mediaObj;
    }

    return null;
};

const SECTION_REDUCER = (state = ImmutableMap(), action) => {
    switch (action.type) {
        case EXAM_ACTION_TYPES.SET_EXAM_DATA:
            var { data } = action;
            return state.withMutations((mutableState) => {
                mutableState.set("id", data["@ID"]);
                mutableState.set("name", data["@Name"]);
                mutableState.set("text", extractMessage(data.TEXT));
                mutableState.set("resourceUrl", data["@ResourceURL"]);
                mutableState.set("marks", parseInt(data["@Marks"]), 10);
                mutableState.set(
                    "requiresWorkings",
                    data["@ShowWorkings"] === "True"
                );
                mutableState.set(
                    "requiresCalculator",
                    data["@ShowCalculator"] === "True"
                );
                mutableState.set(
                    "scenarioText",
                    extractMessage(data.INTROTEXT)
                );

                mutableState.set(
                    "displayAllQuestions",
                    getDisplayAllQuestions(data)
                );
                mutableState.set("media", getMediaProperties(data));

                // one way stuff
                const isOneWay = getProp(data, "PAPERPARTPROPERTIES.@OneWay");
                mutableState.set("oneWay", isOneWay);

                if (isOneWay) {
                    const timeData = extractTimeData(data);
                    Object.keys(timeData).forEach((key) => {
                        mutableState.set(key, timeData[key]);
                    });
                }
            });
        case ACTION_TYPES.SET_TIME_REMAINING:
            return state.set(
                "sectionTimeRemaining",
                action.value < 0 ? 0 : action.value
            );
        case ACTION_TYPES.SET_READING_TIME_REMAINING:
            return state.set(
                "readingTimeRemaining",
                action.value < 0 ? 0 : action.value
            );
        case ACTION_TYPES.SET_COMPLETE:
            return state.set("complete", true);
        case ACTION_TYPES.SET_AUDIO_ELAPSED:{
          const media = state.get('media');
          media.elapsed=action.value;

          return state.set('media', media);
        }
        case ACTION_TYPES.SET_AUDIO_STATE:{
            const media = state.get('media');
            media.state=action.value;

            return state.set('media', media);
        }                       
        default:
            return state;
    }
};

export { SECTION_REDUCER };
