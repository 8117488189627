// npm
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {ScenarioText} from './scenario-text'
import {ExpansionPanel} from 'components/layout/expansion-panel'

// redux (selectors)
import {getExamData, getSettingsData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getMappingsData} from 'redux/reducers/exam/content/selectors'
import {getParentSectionId} from 'redux/reducers/exam/content/mappings/selectors'
import {getQuestionScenarioText, getQuestionNoInSection} from 'redux/reducers/exam/content/complex-selectors'
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getRememberScenario } from 'redux/reducers/settings/client/selectors';

import { getShowScenario } from "redux/reducers/exam/selectors";
import { SET_SHOW_SCENARIO } from "redux/reducers/exam/action-types";

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


const styles = (theme) => ({
	root: {
		margin: theme.spacing.unit * 2,
		backgroundColor: theme.palette.background.light
	},
	expanded: {
		margin: 0
	},
	text: {
		color: theme.palette.background.contrastText
	}
});


// ScenarioPanel (not connected to store/styles)
// ------------------------------------------------

class ScenarioPanel extends Component {
	state = {  }

	constructor(props){
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e, value){
		const {setShowScenario} = this.props;
		setShowScenario(value);
	}

	render() { 
		const {text, classes, questionNumberInSection, messages, showScenario, rememberShowScenario} = this.props;

		if (!text) { return null; }

		const props = {
			key: `scenariotext${questionNumberInSection}`,		
			summary: messages[MESSAGE_IDS.EXAM.SCENARIO_HEADING],
			classes: {expanded: classes.expanded},
			className: classes.root
		}

		if (rememberShowScenario){
			props.open = showScenario;
			props.onChange= this.handleChange;
		}
		else {
			props.startOpen = questionNumberInSection === 1;
		}

		return (
			<React.Fragment>
				<div style={{height: 8}}/>
				<ExpansionPanel {...props}>
					<ScenarioText className={classes.text} text={text}/>
				</ExpansionPanel>
			</React.Fragment>
		)		
	}
}

ScenarioPanel.propTypes = {
	text: PropTypes.string,
	classes: PropTypes.object.isRequired,
	questionNumberInSection: PropTypes.number.isRequired
}


// ScenarioPanel (connected to store/styles)
// ------------------------------------------------

const mapStoreToProps = (store, {questionId}) => 
{
	const examData = getExamData(store);

	const contentData = getContentData(examData);
	const mappingsData = getMappingsData(contentData);
	const sectionId = getParentSectionId(mappingsData)(questionId);

	const text = getQuestionScenarioText(contentData)(questionId);
	const questionNumberInSection = getQuestionNoInSection(contentData)(sectionId, questionId);

	const showScenario = getShowScenario(examData);
	
	const settingsData = getSettingsData(store);
	const clientSettingsData = getClientSettingsData(settingsData);	
	const rememberShowScenario = getRememberScenario(clientSettingsData);	

	return {text, questionNumberInSection, showScenario, rememberShowScenario};
}

const mapDispatchToProps = (dispatch) => ({
    setShowScenario: (value) => {
        dispatch({
            type: SET_SHOW_SCENARIO,
            value,
        });
    }
});

ScenarioPanel = connect(mapStoreToProps, mapDispatchToProps)(ScenarioPanel);
ScenarioPanel = withStyles(styles)(ScenarioPanel);
ScenarioPanel = withMessages(ScenarioPanel);


// Export
// ------------------------------------------------
export {ScenarioPanel}