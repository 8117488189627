// npm
import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "immutable-prop-types";

// utils
import { getProp } from "custom/object-helper";

// constants
import { MULTI_TEXT, RADIOS_TEXT } from "constants/question-types";

class MultiTextSaver extends React.Component {
    constructor(props) {
        super(props);
        this.resetTimer();
    }

    render() {
        return null;
    }

    componentDidUpdate(previousProps) {
        if (this.shouldResetElapsedTime(previousProps)) {
            this.resetTimer();
        } else if (this.shouldSaveQuestion()) {
            this.saveQuestion();
        }
    }

    shouldResetElapsedTime(previousProps) {
        return previousProps.question.id !== this.props.question.id;
    }

    resetTimer() {
        this.timeOnLastSave = this.props.timeRemaining;
        this.answerOnLastSave = this.props.question.answer;
    }

    shouldSaveQuestion() {
        const { question } = this.props;
        const questionTypes = [MULTI_TEXT, RADIOS_TEXT];

        if (
            questionTypes.indexOf(question.type) === -1 ||
            !this.hasAnswerChanged()
        ) {
            // if (question.type !== MULTI_TEXT || !this.hasAnswerChanged()) {
            return false;
        }

        const elapsedTime = this.timeOnLastSave - this.props.timeRemaining;
        return elapsedTime >= this.props.interval;
    }

	hasAnswerChanged() {
        const { question } = this.props;
        const answerName =
            question.type === RADIOS_TEXT ? "otherText" : "answerText";

        const previousAnswer = getProp(this, `answerOnLastSave.${answerName}`);
        const newAnswer = getProp(this.props, `question.answer.${answerName}`);

        return previousAnswer !== newAnswer;
    }

    saveQuestion() {
        this.props.saveQuestion();
        this.resetTimer();
    }
}

MultiTextSaver.defaultProps = {
    interval: 30,
};

MultiTextSaver.propTypes = {
    saveQuestion: PropTypes.func.isRequired,
    interval: PropTypes.number.isRequired,
    timeRemaining: PropTypes.number.isRequired,
    question: PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        answer: PropTypes.object.isRequired,
    }).isRequired,
};

export { MultiTextSaver };
