// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// redux (actions)
import {
    setLocalAnswer,
    setServerAnswer,
    setCurrentQuestionId,
} from "./actions";

// redux (selectors)
import {
    getId,
    getLocalAnswer,
} from "redux/reducers/exam/content/questions/question/selectors";

// AnswerBox (not connected to store)
// ---------------------------------------

class AnswerBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = { mounted: false };

        this.setAnswer = (formValue) => {
            const questionId = getId(this.props.questionData);
            const localAnswer = this.props.toLocalAnswer(formValue);
            const serverAnswer = this.props.toServerAnswer(localAnswer);
            this.props.setLocalAnswer(questionId, localAnswer);
            this.props.setServerAnswer(questionId, serverAnswer);
        };

        this.setCurrentQuestion = () => {
          const questionId = getId(this.props.questionData);

          this.props.setCurrentQuestion(questionId, true);
      };

        this.onFormChange = (formAnswer) => {
            const formValue = this.props.getFormValue(formAnswer);

            this.setAnswer(formValue);
            if (this.props.noQuestionPanel) {
              this.setCurrentQuestion();
            }
        };
    }

    componentDidMount() {
        this.setInitialFormValue();
        this.setState({ mounted: true });
    }

    setInitialFormValue() {
        const localAnswer = getLocalAnswer(this.props.questionData);
        const resumedAnswer = localAnswer || this.props.extractResumedAnswer();
        const initialAnswer =
            resumedAnswer || this.props.extractInitialAnswer();
        const formValue = this.props.toFormValue(initialAnswer);
        this.setAnswer(formValue);
    }

    render() {
        return this.state.mounted ? this.Form : null;
    }

    get Form() {
        const { FormComponent, questionData } = this.props;
        const localAnswer = getLocalAnswer(questionData);

        const props = {
            onChange: this.onFormChange,
            formData: this.props.extractFormData(),
            value: this.props.toFormValue(localAnswer),
        };

        return <FormComponent {...props} />;
    }
}

// default transformations assume no transformation is required
AnswerBox.defaultProps = {
    toLocalAnswer: (formValue) => formValue,
    toFormValue: (localAnswer) => localAnswer,
    extractFormValue: (formAnswer) => formAnswer,
};

AnswerBox.propTypes = {
    FormComponent: PropTypes.oneOfType([
        PropTypes.instanceOf(React.Component),
        PropTypes.func,
    ]).isRequired,
    questionData: PropTypes.object.isRequired,
    toFormValue: PropTypes.func.isRequired,
    getFormValue: PropTypes.func.isRequired,
    toLocalAnswer: PropTypes.func.isRequired,
    toServerAnswer: PropTypes.func.isRequired,
    extractFormData: PropTypes.func.isRequired,
    extractResumedAnswer: PropTypes.func.isRequired,
    extractInitialAnswer: PropTypes.func.isRequired,
    setLocalAnswer: PropTypes.func.isRequired,
    setServerAnswer: PropTypes.func.isRequired,
};

// AnswerBox (connected to store)
// ---------------------------------------

const mapDispatchToProps = (dispatch) => ({
    setLocalAnswer: (questionId, localAnswer) => {
        dispatch(setLocalAnswer(questionId, localAnswer));
    },
    setServerAnswer: (questionId, serverAnswer) => {
        dispatch(setServerAnswer(questionId, serverAnswer));
    },
    setCurrentQuestion: (questionId) => {
      dispatch(setCurrentQuestionId(questionId));
  },    
});

AnswerBox = connect(undefined, mapDispatchToProps)(AnswerBox);

// EXPORT
// ---------------------------------------
export { AnswerBox };
