
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// utils
import {assessmentApi} from 'libs/api/interface/api-assessment'

// redux (selectors)
import {getUserGuid} from 'redux/reducers/session/user/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getSessionData, getSchedulesData} from 'redux/reducers/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'
import {getScheduleType, getScheduleGuid, getExamGuid, getExamTypeGuid} from 'redux/reducers/schedules/schedule/selectors'
import { getSettingsData } from "redux/reducers/selectors"
import { getProctoringMode } from "redux/reducers/settings/client/selectors";
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import { getProctoringSessionID } from 'redux/reducers/settings/client/selectors'

// redux (actions)
import {setCantStartMessage} from './actions'


// CanStartChecker (not connected to store)
// ------------------------------------------

class CanStartChecker extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.onSuccess = (response) => {
			const parsedResponse = JSON.parse(response);

			if (parsedResponse.canStart) {
				this.props.onSuccess();
			}
			else {
				const {scheduleGuid} = this.props;
				this.props.setCantStartMessage(scheduleGuid, parsedResponse.message);
				this.props.onFail(); 
			}
		}
	}

	componentDidMount()
	{
		const args = [
			this.props.userGuid,
			this.props.scheduleGuid,
			this.props.scheduleType,
			this.props.examGuid,
			this.props.examTypeGuid,
			this.props.proctoringMode,
			this.props.proctoringSessionID
		]

		assessmentApi.canExamStart(...args)
		.then(this.onSuccess, this.props.onError);
	}

	render()
	{
		return null;
	}
}

CanStartChecker.propTypes = {
	userGuid: PropTypes.string.isRequired,
	scheduleGuid: PropTypes.string.isRequired,
	scheduleType: PropTypes.string.isRequired,
	examGuid: PropTypes.string.isRequired,
	examTypeGuid: PropTypes.string.isRequired,
	setCantStartMessage: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
}


// CanStartChecker (connected to store)
// ------------------------------------------

const mapStoreToProps = (store, {examGuid}) =>
{ 
	const schedulesData = getSchedulesData(store);
	const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
	const settingsData = getSettingsData(store);
	const userGuid = getUserGuid(getUserSessionData(getSessionData(store)));
	const scheduleType = getScheduleType(scheduleData);
	const scheduleGuid = getScheduleGuid(scheduleData);
	const examTypeGuid = getExamTypeGuid(scheduleData);
    const proctoringMode =  getProctoringMode(getClientSettingsData(settingsData));
    const proctoringSessionID = getProctoringSessionID(getClientSettingsData(getSettingsData(store)));
	return {userGuid, scheduleGuid, scheduleType, examGuid, examTypeGuid, proctoringMode,proctoringSessionID}
}

const mapDispatchToProps = (dispatch) =>
{
	return {
		setCantStartMessage: (scheduleGuid, value) => {
			dispatch(setCantStartMessage(scheduleGuid, value));
		}
	}
}

CanStartChecker = connect(mapStoreToProps, mapDispatchToProps)(CanStartChecker);



export {CanStartChecker}