
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {NavigationPanelTitle} from './panel-title'
import {NavigationPanelContent} from './panel-content'
import { MaterialScrollWrapper } from "components/layout/scroll_wrapper/material-scroll-wrapper";

// constants
import {contentBarHeight} from '../../../constants'


const wrapperStyle = {
	width: 192,
	height: '100%',
	display: 'flex',
	alignItems: 'stretch',
	flexDirection: 'column',
	marginLeft: 8,
	marginRight: 8
}

const QuestionsNavigationPanel = (onQuestionChange) => (
	<div style={wrapperStyle}>
		<div style={{height: contentBarHeight, flexShrink: 0}}>
			<NavigationPanelTitle/>
		</div>
		<MaterialScrollWrapper>
		<div style={{flexShrink: 1}}>
			<NavigationPanelContent onQuestionChange={onQuestionChange}/>
		</div>
		</MaterialScrollWrapper>
	</div>
)


export {QuestionsNavigationPanel}