import React, { Component } from "react";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";

import { getSessionData } from "redux/reducers/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import { getUserId, getUserGuid, getLanguageId } from "redux/reducers/session/user/selectors";
import {getOrgId} from 'redux/reducers/session/client/selectors'
import {getClientSessionData} from 'redux/reducers/session/selectors'


import { AppPageModifier } from "components/pages/app-page-modifier";

import { eLearningApi } from "libs/api/interface/api-e-learning";

import { Course } from "./course";

import { getCourseData } from "../helper";

class CoursePage extends Component {
    state = {};

    constructor(props) {
        super(props);

        const {
            match: { params },
        } = props;

        this.courseAttemptGuid = check.assigned(params.elearningGuid)
            ? params.elearningGuid
            : null;

        this.handleUpdateCourseData = this.handleUpdateCourseData.bind(this);

        this.state = { data: null };
    }

    componentDidMount() {
        const onCourseInfoSuccess=(response)=>{
            const parsedReponse = JSON.parse(response);
            const data={info: parsedReponse[0], ...this.courseData};

            this.setState({ data });
        }

        const onSuccess = (response) => {
            const parsedReponse = JSON.parse(response);
            const data = getCourseData(parsedReponse);

            if (data){
                if (!data.courseInfoTextId){
                    this.setState({ data });
                }
                else{
                    this.courseData = data;

                    const {orgID, langID} = this.props;
                    const payload = {orgID, langID, iDs:[data.courseInfoTextId]}

                    eLearningApi.getCourseInfo(payload).then(onCourseInfoSuccess, onFail);
                }
            }
        };

        const onFail = (data) => {
            debugger;
        };

        if (this.courseAttemptGuid) {
            eLearningApi
                .getCourseAttemptDetails(this.courseAttemptGuid)
                .then(onSuccess, onFail);
        }
    }

    handleUpdateCourseData(data) {
        return new Promise((resolve) => {
            this.setState({ data }, () => {
                resolve();
            });
        });
    }

    displayCourses() {
        const { data } = this.state;
        if (!data) return null;

        const courseProps = {
            updateCourseData: this.handleUpdateCourseData,
            ...data,
        };

        return <Course {...courseProps} />;
    }

    playSco() {}

    render() {
        const { data } = this.state;
        const title = check.assigned(data) ? data.name : "Loading course";
        const appBarProps = {
            title,
            logout: true,
            logo: true,
            loadingTitle: !check.assigned(data),
        };

        return (
            <React.Fragment>
                <AppPageModifier id="course" appBarProps={appBarProps} />
                {this.displayCourses()}
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const sessionData = getSessionData(store);
    const userSessionData = getUserSessionData(sessionData);

    return {
        userId: getUserId(userSessionData),
        orgID: getOrgId(getClientSessionData(sessionData)),
        langID: getLanguageId(userSessionData),
        userGuid: getUserGuid(userSessionData)
    };
};

CoursePage = connect(mapStoreToProps)(CoursePage);

export { CoursePage };
