import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUrlToken } from "redux/reducers/session/client/selectors";
import { getSessionData } from "redux/reducers/selectors";
import {
    getClientSessionData,
    getUserSessionData,
} from "redux/reducers/session/selectors";
import { isAdmin } from "redux/reducers/session/user/selectors";

import { AppPageModifier } from "components/pages/app-page-modifier";
import { LoadingSpinner } from "components/presentation/loading-spinner";

class AdminPage extends Component {
    state = {};

    componentDidMount() {
        const { urlToken } = this.props;
        const adminUrl = 'https://dev.xams.co.uk/user/login/login';
        const url = `${adminUrl}?${urlToken}`;
        //debugger;
        //window.location.replace(url);
    }

    render() {
        const appBarProps = {
            title: "Redirecting to admin",
            loadingTitle: true,
            logo: true
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="initializing-session-msgs"
                    appBarProps={appBarProps}
                    loading
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const sessionData = getSessionData(store);
    const userSessionData = getUserSessionData(sessionData);
    const clientSessionData = getClientSessionData(sessionData);
    const urlToken = getUrlToken(clientSessionData);
    const admin = isAdmin(userSessionData);

    return { urlToken, admin };
};

AdminPage = connect(mapStoreToProps)(AdminPage);
AdminPage = withRouter(AdminPage);

export {AdminPage};
