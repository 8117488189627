// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { milliseconds, seconds } from "time-convert";

// react (hocs)
import { withExamRequestSaver } from "components/hocs/exam_request_saver";

// react
import { ExamTimeSaver } from "./time";
import { MultiTextSaver } from "./multi-text";
import { ExamQuestionSaver } from "./question";

import { getTimeStamp } from "utils/time-stamp";

// redux (selectors)
import { getExamData } from "redux/reducers/selectors";
import { getPaperPartId } from "redux/reducers/exam/content/complex-selectors";
import { getFormRunGuid, getContentData } from "redux/reducers/exam/selectors";
import { getQuestionDataById } from "redux/reducers/exam/content/questions/selectors";
import {
    getCurrentQuestionId,
    getQuestionsData,
} from "redux/reducers/exam/content/selectors";
import { getWorkings } from "redux/reducers/exam/content/questions/question/workings/selectors";
import {
    getTimeRemaining,
    getReadingTimeRemaining,
} from "redux/reducers/exam/complex-selectors";
import {
    getType,
    getServerAnswer,
    getNumber,
    getWorkingsData,
    getVersionId,
    getVersionNumber,
} from "redux/reducers/exam/content/questions/question/selectors";
import { getTimeData } from "redux/reducers/exam/selectors";
import { getExamElapsedTime } from "redux/reducers/exam/time/selectors";

// constants
import { MULTI_TEXT, RADIOS_TEXT } from "constants/question-types";

// ExamSaver (not connected to store)
// -------------------------------------------------

class ExamSaver extends React.Component {
    constructor(props) {
        super(props);
        this.initializeBoundMethods();
    }

    initializeBoundMethods() {
        const saveQuestion = (props) => {
            const { question } = props;
            const answer = this.props.getQuestionAnswer(question.id);
            if (!answer) {
                return;
            }

            const payload = {
                timeRemaining: props.timeRemaining,
                elapsedTime: this.props.elapsedTime,
                page: question.number,
                formRunGuid: props.formRunGuid,
                questionNumber: question.number || this.props.question.number,
                questionVersionId:
                    question.versionId || this.props.question.versionId,
                questionVersionNumber:
                    question.versionNumber || this.props.question.versionNumber,
                workings: question.workings,
                objectID: question.id,
                ...answer,
            };

            props.examRequestSaver.saveAnswer(payload);
        };

        this.saveCurrentQuestion = () => {
            saveQuestion(this.props);
        };

        this.savePreviousQuestion = () => {
            if (!this.previousProps) {
                alert("Can't save previous question");
            }
            saveQuestion(this.previousProps);
        };

        this.saveTime = () => {
            const payload = {
                formRunGuid: this.props.formRunGuid,
                timeRemaining: this.props.timeRemaining,
                elapsedTime: this.props.elapsedTime,
                page: this.props.question.number,
                ClientTime: getTimeStamp(),
            };

            this.props.examRequestSaver.saveExamTime(payload);
        };
    }

    render() {
        return (
            <React.Fragment>
                {this.ExamTimeSaver}
                {this.QuestionSaver}
                {this.MultiTextSaver}
            </React.Fragment>
        );
    }

    componentDidUpdate(previousProps) {
        this.previousProps = previousProps;
    }

    get ExamTimeSaver() {
        const { question } = this.props;

        const props = {
            saveTime: this.saveTime,
            paperPartId: question.paperPartId,
            timeRemaining: this.props.timeRemaining,
        };

        return <ExamTimeSaver {...props} />;
    }

    get QuestionSaver() {
        const { readingTime } = this.props;
        if (readingTime) {
            return null;
        }

        const props = {
            savePreviousQuestion: this.savePreviousQuestion,
            saveCurrentQuestion: this.saveCurrentQuestion,
            currentQuestionId: this.props.question.id,
            paperPartId: this.props.question.paperPartId,
        };

        return <ExamQuestionSaver {...props} />;
    }

    get MultiTextSaver() {
        const { readingTime } = this.props;
        if (readingTime) {
            return null;
        }

        const { question } = this.props;
        if (question.type !== MULTI_TEXT && question.type !== RADIOS_TEXT) {
            return null;
        }

        const props = {
            timeRemaining: this.props.timeRemaining,
            saveQuestion: this.saveCurrentQuestion,
            question: {
                id: question.id,
                type: question.type,
                answer: this.props.getQuestionAnswer(question.id),
            },
        };

        return <MultiTextSaver {...props} />;
    }
}

ExamSaver.propTypes = {
    examRequestSaver: PropTypes.shape({
        saveAnswer: PropTypes.func.isRequired,
        saveExamTime: PropTypes.func.isRequired,
    }).isRequired,
    question: PropTypes.shape({
        type: PropTypes.string.isRequired,
        answer: PropTypes.any,
        id: PropTypes.number.isRequired,
        number: PropTypes.number.isRequired,
        versionId: PropTypes.number.isRequired,
        versionNumber: PropTypes.number.isRequired,
        paperPartId: PropTypes.number,
        workings: PropTypes.string,
    }),
    formRunGuid: PropTypes.string.isRequired,
    timeRemaining: PropTypes.number.isRequired,
    readingTime: PropTypes.bool.isRequired,
};

// ExamSaver (connected to store)
// -------------------------------------------------

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const contentData = getContentData(examData);
    const questionsData = getQuestionsData(contentData);

    const questionId = getCurrentQuestionId(contentData);
    const questionData = getQuestionDataById(questionsData)(questionId);
    const workingsData = getWorkingsData(questionData);

    const timeRemaining = getTimeRemaining(examData, questionId);
    const elapsedTime = getExamElapsedTime(getTimeData(examData));

    return {
        formRunGuid: getFormRunGuid(examData),
        readingTime: getReadingTimeRemaining(examData, questionId) > 0,
        timeRemaining: Math.round(seconds.from(milliseconds)(timeRemaining)),
        elapsedTime,
        question: {
            id: questionId,
            type: getType(questionData),
            number: getNumber(questionData),
            paperPartId: getPaperPartId(contentData)(questionId),
            workings: workingsData ? getWorkings(workingsData) : undefined,
            versionId: getVersionId(questionData),
            versionNumber: getVersionNumber(questionData),
        },
        getQuestionAnswer: (id) => {
            const questionData = getQuestionDataById(questionsData)(id);

            return getServerAnswer(questionData);
        },
    };
};

ExamSaver = connect(mapStoreToProps)(ExamSaver);
ExamSaver = withExamRequestSaver(ExamSaver);

export { ExamSaver };
