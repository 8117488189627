import React, { Component } from 'react';
import {check} from '@xams-utils/check-types'

import { getComponentValue, getStyle } from "../form-helper";


class FormFieldValue extends Component {
    state = {  } 
    render() {

        const { formValue, name } = this.props;
        const value = getComponentValue(name, formValue);

        const styleObj=getStyle(this.props);
        const _value=check.nonEmptyString(value)?value:""

        return (<div style={styleObj}>{_value}</div>);
    }
}
 
export {FormFieldValue}