
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// utils
import {ENDPOINTS} from 'libs/api/constants'
import {api, REQUEST_STATES} from 'libs/api/api'
import {storedUsers} from 'libs/browser_storage/apis/users'
import {storedExams} from 'libs/browser_storage/apis/exams'
import {localStorageApi, KEYS} from 'libs/browser_storage/local-storage-api'
import {networkRequestLog} from 'libs/browser_storage/apis/network-request-log'

// redux (selectors)
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getSessionData, getExamData} from 'redux/reducers/selectors'
import {getName as getExamName, getGuid as getExamGuid} from 'redux/reducers/exam/selectors'
import {getUserId, getUserGuid, getName as getUserName} from 'redux/reducers/session/user/selectors'


const IGNORED_ENDPOINTS = [
	ENDPOINTS.PING,
	ENDPOINTS.LOG_ERROR,
	ENDPOINTS.BUG_REPORT,
	ENDPOINTS.ASSESSMENT.SAVE_TIME
];


// ApiRequest = {
// 	id: "string",
//  endpoint: "string",
//  payload: {} || null,
//  statusCode: Number || null,
//  type: "POST|GET|DELETE|DOWNLOAD"
// }


// RequestLog = {
// 	id: "string",
// 	endpoint: "string",
// 	payload: "string?",
// 	type: "POST|GET|DELETE|DOWNLOAD",
// 	timeStamp: Number (in ms),
// 	userGuid: "string",
// 	examGuid: "string?",
//  status: "string description or status code",
// 	response: "",
// 	responseHeaders: ""
// }


class NetworkRequestLogger extends React.Component
{
	constructor(props)
	{
		super(props);

		const shouldIgnoreRequest = (request) => {
			return IGNORED_ENDPOINTS.some(endpoint => {
				return request.endpoint === endpoint;
			});
		}

		const convertToRequestLog = (requestState, {statusCode, ...apiRequest}) => ({
			...apiRequest,
			timeStamp: Date.now(),
			userGuid: this.props.userGuid,
			status: statusCode || requestState,
			examGuid: this.props.examGuid || null
		});

		const storeUserAndExam = () => {
			const {userGuid, userName} = this.props;
			storedUsers.set(userGuid, {name: userName});

			const {examGuid, examName} = this.props;
			if (examGuid) {
				storedExams.set(examGuid, {name: examName});
			}
		}

		this.logNetworkRequest = (requestState, apiRequest) => {
			if (shouldIgnoreRequest(apiRequest)) { return; }
			const requestLog = convertToRequestLog(requestState, apiRequest);
			storeUserAndExam();
			networkRequestLog.push(requestLog.id, requestLog);
		}
	}

	componentDidMount()
	{
		this.unsubscribeFromApi = api.subscribe(this.logNetworkRequest);
	}

	componentWillUnmount()
	{
		this.unsubscribeFromApi();
	}

	render()
	{
		return this.props.children || null;
	}
}

NetworkRequestLogger.propTypes = {
	children: PropTypes.node,
	userId: PropTypes.number.isRequired,
	userGuid: PropTypes.string.isRequired,
	userName: PropTypes.string.isRequired,
	examGuid: PropTypes.string,
	examName: PropTypes.string
}


const mapStoreToProps = (store) =>
{
	const sessionData = getSessionData(store);
	const userSessionData = getUserSessionData(sessionData);

	const examData = getExamData(store);

	return {
		userId: getUserId(userSessionData),
		userGuid: getUserGuid(userSessionData),
		userName: getUserName(userSessionData),
		examGuid: getExamGuid(examData),
		examName: getExamName(examData)
	}
}

NetworkRequestLogger = connect(mapStoreToProps)(NetworkRequestLogger);


export {NetworkRequestLogger}