
// @xams-utils
import {check} from '@xams-utils/check-types'

// @xams-entities
import {Browser} from '@xams-entities/browser'
import {Version} from '@xams-entities/version'
import {VersionRange} from '@xams-entities/version-range'


const sortSupportedBrowsers = (currentBrowser, supportedBrowsers) =>
{
  return Object.keys(supportedBrowsers)
    .map(browser => {
      return getTextForSupportedBrowser(browser, supportedBrowsers[browser]);
    })
    .sort((a, b) => {
      if (Browser.equals(currentBrowser, a.browser)) {
        return -1;
      }
      else if (Browser.equals(currentBrowser, b.browser)) {
        return 1;
      }
      return a.browser.name.localeCompare(b.browser.name);
    });
};

const getTextForSupportedBrowser = (browser, browserSupport) =>
{
  const support = {browser: Browser.create(browser)};
  if (check.assigned(browserSupport.include)) {
    support.include = getVersionText(browserSupport.include);
  }
  if (check.assigned(browserSupport.exclude)) {
    support.exclude = getVersionText(browserSupport.exclude);
  }
  return support;
};

const getVersionText = supportedVersions =>
{
  const versionText = [];
  const singleVersions = [];

  supportedVersions.forEach(supportedVersion => {
    if (Version.is(supportedVersion)) {
      singleVersions.push(Version.toString(supportedVersion));
    }
    else {
      versionText.push(VersionRange.toString(supportedVersion));
    }
  });

  if (singleVersions.length > 0) {
    versionText.push(getSingleVersionsText(singleVersions));
  }

  return versionText;
};

const getSingleVersionsText = singleVersions =>
{
  let singleVersionsText = 'Version' + (singleVersions.length > 1 ? 's' : '');
  singleVersionsText += ' '+singleVersions.sort((a, b) => {
      return a.localeCompare(b);
    }).join(', ');
  return singleVersionsText;
};


export {sortSupportedBrowsers}
