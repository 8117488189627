// npm
import React from "react";
import PropTypes from "prop-types";
import { check } from "@xams-utils/check-types";

// react
import { AnswerBox } from "./answer-box";
import { McqTextAnswerForm } from "../answer_forms/mcq_text/mcq-text";

// redux (selectors)
import { getOptionsData } from "redux/reducers/exam/content/questions/question/radios/selectors";
import { getSelectedOptionData } from "redux/reducers/exam/content/questions/question/radios/options/selectors";
import {
    getOptionText,
    getOptionId,
} from "redux/reducers/exam/content/questions/question/radios/options/option/selectors";
import { getOtherText, getNumberOfLines, getShowWordCount } from "redux/reducers/exam/content/questions/question/radios-text/selectors";
import { getLocalAnswer } from "redux/reducers/exam/content/questions/question/selectors";

const splitOptionText = (text) => {
    const pos = text.indexOf("<br />");

    return pos === -1
        ? { label: text, description: "" }
        : {
              label: text.substr(0, pos),
              description: removeStartBr(text.substring(pos + 6)),
          };
};

const removeStartBr = (_text) => {
    const text = _text.trim();

    if (text.substring(0, 6) === "<br />") {
        return removeStartBr(text.substring(6));
    }

    return text;
};

class RadiosTextAnswerBox extends React.Component {
    constructor(props) {
        super(props);
        this.setUpExtractionFunctions();
        this.setUpTransformationFunctions();
    }

    // extract information from questionData
    // --------------------------------------
    setUpExtractionFunctions() {
        this.extractInitialAnswer = () => undefined;

        this.extractResumedAnswer = () => {
            const resumedAnswer = {};

            const otherText = getOtherText(this.props.data);
            if (check.nonEmptyString(otherText)) {
                resumedAnswer.text = otherText;
            }

            const optionsData = getOptionsData(this.props.data);
            const selectedOptionData = getSelectedOptionData(optionsData);
            if (selectedOptionData) {
                resumedAnswer.radio = getOptionId(selectedOptionData);
            }

            return check.nonEmptyObject(resumedAnswer)?resumedAnswer:undefined;
        };

        this.extractFormData = () => {
            const lines = getNumberOfLines(this.props.data);
            const showWordCount = getShowWordCount(this.props.data)

            const options = getOptionsData(this.props.data)
                .map((optionData) => {
                    const value = getOptionId(optionData).toString();
                    const labelText = getOptionText(optionData);
                    const { label, description } = splitOptionText(labelText);

                    return { value, label, description };
                })
                .toArray();

            return { options, lines, showWordCount};
        };
    }

    // convert between answer values
    // --------------------------------------
    setUpTransformationFunctions() {
        this.getFormValue = (formAnswer) => {
            const formValue = { ...getLocalAnswer(this.props.data) };

            if (check.object(formAnswer)) {
                const { radio, text } = formAnswer;
                
                if (radio) formValue.radio = radio.target.value;
                if (text) formValue.text = text.target.value;
            }

            return formValue;
        };

        this.toFormValue = (localAnswer) => {
            if (localAnswer) {
                const { radio, text } = localAnswer;

                return { radio: radio.toString(), text };
            }

            return undefined;
            // return localAnswer ? localAnswer.toString() : undefined
        };

        this.toLocalAnswer = (formValue) => {
            if (formValue) {
                const { radio, text } = formValue;

                return { radio: parseInt(radio, 10), text };
            }

            return undefined;
            //return parseInt(formValue, 10)
        };

        this.toServerAnswer = (localAnswer) => {
            if (!localAnswer) {
                return localAnswer;
            }

            const { radio, text } = localAnswer;

            const selectedOption = this.getSelectedOption(radio);
            const optionId = getOptionId(selectedOption);
            const optionText = getOptionText(selectedOption);
            const options = [{ optionID: optionId, optionText }];

            return { options, answerText: optionText, otherText: text };
        };
    }

    render() {
        const answerBoxProps = {
            FormComponent: McqTextAnswerForm,
            questionData: this.props.data,
            toFormValue: this.toFormValue,
            getFormValue: this.getFormValue,
            toLocalAnswer: this.toLocalAnswer,
            toServerAnswer: this.toServerAnswer,
            extractFormData: this.extractFormData,
            extractInitialAnswer: this.extractInitialAnswer,
            extractResumedAnswer: this.extractResumedAnswer,
            noQuestionPanel: this.props.noQuestionPanel,
        };

        return <AnswerBox {...answerBoxProps} />;
    }

    getSelectedOption(localAnswer) {
        const optionsData = getOptionsData(this.props.data);
        return optionsData.find((optionData) => {
            return getOptionId(optionData) === localAnswer;
        });
    }
}

RadiosTextAnswerBox.propTypes = {
    data: PropTypes.object.isRequired,
};

export { RadiosTextAnswerBox };
