
const getScheduleCount = (schedulesData) => {
	return schedulesData.size;
}

const getScheduleDataByIndex = (schedulesData) => (i) => {
	return schedulesData.get(i);
}

const isScheduleForLastCompletedExamComplete = (schedulesData, examGuid) => {
	const currentSchedule = schedulesData.find(x => x.get('examGuid') === examGuid);
	
	//const completed = currentSchedule.get('completed');
	const currentProctoringSessionID = currentSchedule.get('proctoringSessionID');

	if (currentProctoringSessionID === 0){
		return false;
	}

	const schedules = schedulesData.filter(schedule => {
		const completed = schedule.get('completed');
		const proctoringSessionID = schedule.get('proctoringSessionID');

		return (!completed && proctoringSessionID === currentProctoringSessionID)
	}).map(schedule=>schedule.get('examGuid'));
	//debugger;
	return schedules.size === 0;
}

const getScheduleDataByGuid = (schedulesData) => (guid) => {
	return schedulesData.find(x => x.get('guid') === guid);
}

const getScheduleDataByExamGuid = (schedulesData) => (examGuid) => {
	return schedulesData.find(x => x.get('examGuid') === examGuid);
}


export {
	getScheduleCount,
	getScheduleDataByIndex,
	getScheduleDataByGuid,
	getScheduleDataByExamGuid,
	isScheduleForLastCompletedExamComplete
}