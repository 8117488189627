import React, { Component } from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import Avatar from "@material-ui/core/Avatar";

import { MaterialText } from "components/presentation/material-text";

class PrintStaticTextDialog extends Component {
    state = {};

    getTitle() {
        const { printing, error, classes } = this.props;
        if (printing) {
            return (
                <div className={classes.title}>
                    <Avatar className={classes.errorIcon}>
                        <PrintIcon fontSize="medium" />
                    </Avatar>
                    <div>Printing</div>
                </div>
            );
        } else if (error) {
            return (
                <div className={classes.title}>
                    <Avatar className={classes.errorIcon}>!</Avatar>
                    <div>Error</div>
                </div>
            );
        }
    }

    getContent() {
        const { printing, error, classes } = this.props;
        if (printing) {
            return (
                <div className={classes.progress}>
                    <LinearProgress color="secondary" />
                </div>
            );
        } else if (error) {
            const { onErrorClose } = this.props;
            const buttonProps = {
                onClick: onErrorClose,
                variant: "contained",
                colour: "secondary",
            };

            return (
                <div className={classes.error}>
                    <MaterialText variant="h6">
                        There was a problem printing
                    </MaterialText>
                    <div className={classes.errorButton}>
                        <Button {...buttonProps}>Close</Button>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { printing, error, classes } = this.props;
        const dialogProps = {
            "aria-labelledby": "print-static-text-dialog-title",
            classes: { paper: classes.paper },
            open: printing || error,
            className: classes.dialog,
        };
        return (
            <Dialog {...dialogProps}>
                <DialogTitle id="simple-dialog-title">
                    {this.getTitle()}
                </DialogTitle>
                {this.getContent()}
            </Dialog>
        );
    }
}

const styles = ({ spacing }) => ({
    paper: { minWidth: "500px" },
    progress: {
        margin: `${spacing.unit * 2}px ${spacing.unit * 2}px ${
            spacing.unit * 4
        }px ${spacing.unit * 2}px`,
    },
    title: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "&>div:last-child": {
            marginLeft: `${spacing.unit * 1}px`,
        },
    },
    errorIcon: {
        width: spacing.unit * 5,
        height: spacing.unit * 5,
    },
    error: {
        margin: `${spacing.unit * 2}px`,
    },
    errorButton: {
        display: "flex",
        justifyContent: "flex-end",
    },
});

PrintStaticTextDialog = withStyles(styles)(PrintStaticTextDialog);

export { PrintStaticTextDialog };
