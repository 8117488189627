
// redux (action-types)
import {SET_MESSAGE as SET_GENERAL_MESSAGE} from 'redux/reducers/messages/general/action-types'
import {SET_MESSAGE as SET_SESSION_MESSAGE} from 'redux/reducers/messages/session/action-types'


const setGeneralMessage = (id, value) => ({type: SET_GENERAL_MESSAGE, id, value});
const setSessionMessage = (id, value) => ({type: SET_SESSION_MESSAGE, id, value});


export {setGeneralMessage, setSessionMessage}