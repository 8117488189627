
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Input from '@material-ui/core/Input'


const getDateTime = (value) =>
{
	if (!value) { return ''; }

	const isDate = value instanceof Date;
	const dateTime = isDate ? value : new Date(value);
	const dateTimeString = dateTime.toISOString();

	const colonIndex = dateTimeString.lastIndexOf(':');
	return dateTimeString.slice(0, colonIndex);
}

const DateTimeSelector = (props) =>
{
	const currentDate = getDateTime(props.value);

	const onChange = ({target:{value}}) => {
		props.onChange(new Date(value));
	}

	return <Input type='datetime-local' value={currentDate} onChange={onChange}/>;
}

DateTimeSelector.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string,
		PropTypes.number
	])
}


export {DateTimeSelector}