
// utils
import {api} from '../api'
import { check } from '@xams-utils/check-types';

// constants
import {ENDPOINTS} from '../constants'



const getExamSchedule = (userGuid, proctoringMode) => api.get(ENDPOINTS.SCHEDULE.FETCH, {userGuid, proctoringMode});

const getExamScheduleWork = (userGuid, proctoringMode) => api.get(ENDPOINTS.SCHEDULE.WORK, {userGuid, proctoringMode, showCompletedCourses: true});

const saveExamTime = ({formRunGuid, timeRemaining, elapsedTime, page, IsFromLocalStorage, ClientTime}, id) => 
{
	const payload = {formRunGuid, timeRemaining, page, elapsedTime};

	if (IsFromLocalStorage){
		payload.IsFromLocalStorage=IsFromLocalStorage;
	}
	if (ClientTime){
		payload.ClientTime=ClientTime;
	}	

	return api.post(ENDPOINTS.ASSESSMENT.SAVE_TIME, payload, undefined, undefined, id);
}

const getExam = (userGuid, scheduleGuid, scheduleType, examGuid, examTypeGuid, versionNumber, extraTime) => 
{
	const payload = {
		userGuid,
		scheduleGuid,
		scheduleType,
		examGuid,
		examObjectTypeGuid: examTypeGuid,
		versionNumber
	};

	if (check.integer(extraTime) && extraTime>0){
		payload.extraTime=extraTime;
	}

	return api.get(ENDPOINTS.ASSESSMENT.FETCH, payload);
}

const canExamStart = (userGuid, scheduleGuid, scheduleType, examGuid, examTypeGuid, proctoringMode,proctoringSessionID) =>
{
	const payload = {
		userGuid,
		scheduleGuid,
		scheduleType,
		examGuid,
		examObjectTypeGuid: examTypeGuid,
		proctoringMode,
		proctoringSessionID
	};

	return api.get(ENDPOINTS.SCHEDULE.CAN_START, payload);
}

const checkExamPassword = (scheduleGuid, password, userGuid) =>
{
	const payload = {scheduleGuid, password, userGuid};
	return api.post(ENDPOINTS.SCHEDULE.VERIFY_PASSWORD, payload);
}

const startExam = (userGuid, scheduleGuid, scheduleType, examGuid, examTypeGuid, versionNumber, publicExamUserName, appVersion) =>
{
	const payload = {
		userGuid,
		scheduleGuid,
		scheduleType,
		examGuid,
		examObjectTypeGuid: examTypeGuid,
		versionNumber,
		publicUserName: publicExamUserName,
		playerVersion: appVersion
	};

	return api.get(ENDPOINTS.ASSESSMENT.START, payload);
}

const finishExam = ({formRunGuid, examTimeRemaining, IsFromLocalStorage, ClientTime, elapsedTime}, id) =>
{
	const payload = {formRunGuid, timeRemaining: examTimeRemaining, elapsedTime};
	
	if (IsFromLocalStorage){
		payload.IsFromLocalStorage=IsFromLocalStorage;
	}
	if (ClientTime){
		payload.ClientTime=ClientTime;
	}	
	
	return api.post(ENDPOINTS.ASSESSMENT.COMPLETE, payload, undefined, undefined, id);
}

const saveActivityLog=(payload, id) =>{
	const {formRunGuid} = payload;
	const endPoint = `${ENDPOINTS.ASSESSMENT.SAVE_ACTIVITY_LOG}/${formRunGuid}` ;

	return api.post(endPoint, payload, undefined, undefined, id);
}

const saveAnswer = (payload, id) =>
{
	return api.post(ENDPOINTS.ASSESSMENT.SAVE_ANSWER, payload, undefined, undefined, id);
}

const resetSchedule = (userGuid, scheduleGuid, scheduleType, examGuid, examTypeGuid) =>
{
	const payload = {
		userGuid,
		scheduleGuid,
		scheduleType,
		examGuid,
		examObjectTypeGuid: examTypeGuid
	};

	return api.get(ENDPOINTS.ASSESSMENT.RESET, payload);
}

const getResult = (formRunGuid) =>
{
	return api.get(ENDPOINTS.ASSESSMENT.GET_RESULT, {formRunGuid});
}

const isComplete = (formRunGuid) => {
	const payload = {
		properties:["ID","GUID","Processed"],
		parameters:[
			{
				name: "GUID",
				value: formRunGuid,
				expression: "equals"
			}
		]
	}

	return api.post(ENDPOINTS.ASSESSMENT.IS_COMPLETE, payload);
}

const getProctorioLaunchUrl=(userID, proctorSessionID, options, host)=>{
	return api.get(ENDPOINTS.PROCTORING.GET_PROCTORIO_LAUNCH_URL, {proctorSessionID, userID, options, host});	
}

const postPrintStaticTextUrl=(parameters)=>{
	const url = `${ENDPOINTS.ASSESSMENT.PRINT_STATIC_TEXT}?${parameters}`;
	return api.post(ENDPOINTS.ASSESSMENT.PRINT_STATIC_TEXT, {});	
}

const getPrintStaticTextUrl=(payload)=>{
	return api.get(ENDPOINTS.ASSESSMENT.PRINT_STATIC_TEXT, payload);	
}

const assessmentApi = {
	getExamSchedule,
	getExamScheduleWork,
	saveExamTime,
	getExam,
	canExamStart,
	checkExamPassword,
	startExam,
	finishExam,
	saveActivityLog,
	saveAnswer,
	resetSchedule,
	getResult,
	isComplete,
	getProctorioLaunchUrl,
	getPrintStaticTextUrl,
 	postPrintStaticTextUrl
}

export {assessmentApi};