// npm
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";

// material-ui
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

// react
import { ImmediateResitButton } from "./immediate-resit-button";
import { MaterialText } from "components/presentation/material-text";

// redux (selectors)
import { getExamData } from "redux/reducers/selectors";
import { getResultData } from "redux/reducers/exam/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getResultFeedbackMask } from "redux/reducers/settings/client/selectors";

// LearnerFeedbackPanel (not conected to store)
// --------------------------------------------------------------------------

const styles = ({ spacing, palette, breakpoints }) => ({
    paper: {
        marginTop: spacing.unit * 2,
        [breakpoints.down(840)]: {
            width: "calc(100% - 40px)",
            marginLeft: 20,
            marginRight: 20,
        },
        [breakpoints.up(841)]: {
            width: 800,
        },
        alignSelf: "stretch",
    },
    results: {
        padding: spacing.unit * 2,
    },
    avatar: {
        background: palette.primary.main,
    },
    listItem: {
        alignItems: "flex-start",
    },
    text: {
        color: palette.background.contrastText,
        fontWeight: 700,
    },
});

const getTable = (rows) => {
    return (
        <table>
            {rows.map((row) => (
                <tr>
                    <td>{row[0]}</td>
                    <td>: {row[1]}</td>
                </tr>
            ))}
        </table>
    );
};

const getWhatToShowFromMask = (mask) => {
    let showMarks = false;
    let showGrade = false;
    let showPercentage = false;

    if (mask === -1) {
        showMarks = true;
        showPercentage = true;
    } else {
        const binary = getBinaryFromMask(mask, 3);

        showMarks = getBinaryValue(binary, 2);
        showGrade = getBinaryValue(binary, 1);
        showPercentage = getBinaryValue(binary, 0);
    }

    return { showMarks, showGrade, showPercentage };
};

const getBinaryFromMask = (mask, bits) => {
    let binaryValue = mask.toString(2);
    let leading = "";

    const leadingLength = bits - binaryValue.length;

    if (leadingLength > 0) {
        leading = new Array(leadingLength + 1).join("0");
    } else if (leadingLength < 0) {
        binaryValue = binaryValue.substr(binaryValue.length - bits);
    }

    return leading + binaryValue;
};

const getBinaryValue = (binary, place) => {
    return check.nonEmptyString(binary) && binary.length > place
        ? binary.charAt(place) === "1"
        : false;
};

class LearnerFeedbackPanel extends Component {
    state = {};

    getResultTable() {
        const { result, resultFeedbackMask } = this.props;
        const { marks, totalMarks, score, grade } = result;
        const { showMarks, showGrade, showPercentage } =
            getWhatToShowFromMask(resultFeedbackMask);

        const rows = [];

        if (showMarks) {
            rows.push(["Your marks", `${marks} / ${totalMarks}`]);
        }
        if (showGrade) {
            rows.push(["Your grade", grade]);
        }
        if (showPercentage) {
            rows.push(["Your result", `${score} %`]);
        }

        return rows.length > 0 ? getTable(rows) : null;
    }

    get Avatar() {
        const { result, classes } = this.props;
        const { passed } = result;
        if (!check.boolean(passed)) return null;

        const icon = passed ? <DoneIcon /> : <ClearIcon />;
        return (
            <ListItemAvatar>
                <Avatar className={classes.avatar}>{icon}</Avatar>
            </ListItemAvatar>
        );
    }

    get ResultStillBeingProcessed() {
        const { classes, result } = this.props;
        const { message } = result;

        return (
            <div className={classes.results}>
                <MaterialText className={classes.text}>{message}</MaterialText>
            </div>
        );
    }

    get Feedback() {
        const { result, classes } = this.props;
        const { message, allowImmediateResit, resultStillBeingProcessed } =
            result;

        if (resultStillBeingProcessed) {
            return this.ResultStillBeingProcessed;
        }

        const listItemClass =
            !check.nonEmptyString(message) || message.length > 80
                ? classes.listItem
                : null;

        return (
            <React.Fragment>
                <List>
                    <ListItem className={listItemClass}>
                        {this.Avatar}
                        <ListItemText primary={message} />
                    </ListItem>
                </List>
                {this.displayResult()}
                {allowImmediateResit ? this.ResitButton : null}
            </React.Fragment>
        );
    }

    displayResult() {
        const { classes } = this.props;
        const results = this.getResultTable();

        return results ? (
            <React.Fragment>
                <Divider variant="middle" />
                <div className={classes.results}>
                    <MaterialText variant="body1">{results}</MaterialText>
                </div>
            </React.Fragment>
        ) : null;
    }

    get ResitButton() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Divider variant="middle" />
                <div className={classes.results}>
                    <ImmediateResitButton />
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { classes } = this.props;

        return <Paper className={classes.paper}>{this.Feedback}</Paper>;
    }
}

LearnerFeedbackPanel.propTypes = {
    result: PropTypes.shape({
        marks: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
        passed: PropTypes.bool.isRequired,
        score: PropTypes.number.isRequired,
        totalMarks: PropTypes.number.isRequired,
        allowImmediateResit: PropTypes.bool.isRequired,
    }),
};

// LearnerFeedbackPanel (conected to store)
// --------------------------------------------------------------------------

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const resultData = getResultData(examData);

    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);
    const resultFeedbackMask = getResultFeedbackMask(clientSettingsData);

    return { result: resultData.toJS(), resultFeedbackMask };
};

LearnerFeedbackPanel = connect(mapStoreToProps)(LearnerFeedbackPanel);

LearnerFeedbackPanel = withStyles(styles)(LearnerFeedbackPanel);

// Export
// --------------------------------------------------------------------------
export { LearnerFeedbackPanel };
