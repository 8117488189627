
// npm
import * as Immutable from 'immutable'

// redux
import * as ACTION_TYPES from './action-types'
import {CREDENTIALS_REDUCER} from './credentials/reducer'


const reducerMapping = {
	credentials: CREDENTIALS_REDUCER
}

const LOGIN_REDUCER = (state=Immutable.Map(), action) =>
{
	['credentials'].forEach(stateProperty => {
		let _state = state.get(stateProperty);
		let _reducer = reducerMapping[stateProperty];
		state = state.set(stateProperty, _reducer(_state, action));
	});

	switch (action.type)
	{
		case ACTION_TYPES.SET_ERROR:
			return state.set('error', action.value);
		case ACTION_TYPES.CLEAR_ERROR:
			return state.delete('error');
		default:
			return state;
	}
}


export {LOGIN_REDUCER}