
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// xams-components-functional
import {StateMachine, StateView} from 'temp/xams.UI.Components/machine'
import {Callback} from '@xams-components-functional/callback'

// react
import {InitializingView} from './initializing/view'
import {SetNetworkError} from 'components/pages/network_error/set-network-error'

// redux (actions)
import {setAppInitialized} from './actions'

// machines
import {init} from 'machines/init'


const {STATES} = init;


// NOT CONNECTED TO STORE
// ---------------------------------------------------------------------

let AppInitializationMachine = ({setAppInitialized}) =>
{
	return (
		<StateMachine machine={init.machine}>
			<StateView>
				{{
					[STATES.INITIALIZING]: () => (
						InitializingView()
					),
					[STATES.INITIALIZE_ERROR]: () => (
						<SetNetworkError/>
					),
					[STATES.INITIALIZED]: () => (
						<Callback onMount={setAppInitialized}/>
					)
				}}
			</StateView>
		</StateMachine>
	)
}

AppInitializationMachine.propTypes = {
	setAppInitialized: PropTypes.func.isRequired
}


// CONNECTED TO STORE
// ---------------------------------------------------------------------

const mapDispatchToProps = (dispatch) => ({
	setAppInitialized: () => dispatch(setAppInitialized())
});

AppInitializationMachine = connect(undefined, mapDispatchToProps)(AppInitializationMachine);


// EXPORT
// ---------------------------------------------------------------------
export {AppInitializationMachine}