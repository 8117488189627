// npm
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { check } from "@xams-utils/check-types";

// material-ui
import { styles } from "./multi-text-styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import withStyles from "@material-ui/core/styles/withStyles";

// react (HOCs)
import { withMessages } from "components/hocs/messages";

// react
import { MarksChip } from "../../marks-chip";
import { MaterialText } from "components/presentation/material-text";
import { RichTextEditor } from "components/presentation/text_editors/rich";
import { DisableAnsweringContext } from "components/pages/exam/disabled-context";
import { MultiLineTextEditor } from "components/presentation/text_editors/multi_line";
import { SingleLineTextEditor } from "components/presentation/text_editors/single_line";
import { WordCount } from "components/presentation/text_editors/word-count";

import {getSessionData} from 'redux/reducers/selectors'
import {getClientSessionData} from 'redux/reducers/session/selectors'
import {getOrgId} from 'redux/reducers/session/client/selectors'

// MultiTextAnswerForm (not connected to styles)
// -----------------------------------------------

class MultiTextAnswerForm extends React.Component {
    render() {
        return (
            <Table>
                <TableBody>{this.Parts}</TableBody>
            </Table>
        );
    }

    get Parts() {
        return this.props.formData.parts.map((part) => {
            const partElement = part.usesRichText
                ? this.getRichTextPart(part)
                : part.lineCount > 1
                ? this.getMultiLinePart(part)
                : this.getSingleLinePart(part);

            return (
                <TableRow key={part.id}>
                    <TableCell style={{ borderBottom: 0 }} colSpan="3">
                        {partElement}
                    </TableCell>
                </TableRow>
            );
        });
    }

    getRichTextPart(part) {
        const { showWordCount } = this.props.formData;
        const props = {
            canPaste: false,
            initialValue: this.props.value[part.id],
            showWordCount,
            disabled: this.isDisabled(),
            onChange: (content) => {
                this.props.onChange({ partId: part.id, partValue: content });
            },
            // onChange: ({target}) => {
            // 	this.props.onChange({partId: part.id, partValue: target.getContent()});
            // }
        };

        return (
            <React.Fragment>
                {this.renderPartQuestion(part.text)}
                <div className={this.props.classes.richText}>
                    <RichTextEditor {...props} />
                </div>
                {this.renderPartMarks(part.marks)}
            </React.Fragment>
        );
    }

    getMultiLinePart(part) {
        return (
            <React.Fragment>
                {this.renderPartQuestion(part.text)}
                {this.getMultiLineTextEditor(part)}
                {this.renderWordCount(part)}
                {this.renderPartMarks(part.marks)}
            </React.Fragment>
        );
    }

    getMultiLineTextEditor(part) {
        const props = {
            canPaste: false,
            lineCount: part.lineCount,
            value: this.props.value[part.id],
            disabled: this.isDisabled(),
            onChange: ({ target: { value } }) => {
                this.props.onChange({ partId: part.id, partValue: value });
            },
        };

        return (
            <MultiLineTextEditor
                {...props}
                {...this.SharedNonRichEditorProps}
            />
        );
    }

    getSingleLinePart(part) {
        return (
            <React.Fragment>
                {this.renderPartQuestion(part.text)}
                {this.getSingleLineTextEditor(part)}
                {this.renderWordCount(part)}
                {this.renderPartMarks(part.marks)}
            </React.Fragment>
        );
    }

    getSingleLineTextEditor(part) {
        const props = {
            canPaste: false,
            value: this.props.value[part.id],
            disabled: this.isDisabled(),
            suffix: part.suffix,
            onChange: ({ target: { value } }) => {
                this.props.onChange({ partId: part.id, partValue: value });
            },
        };

        return (
            <SingleLineTextEditor
                {...props}
                {...this.SharedNonRichEditorProps}
            />
        );
    }

    renderPartQuestion(textValue) {
        if (!textValue) {
            return null;
        }
        const { classes } = this.props;

        return (
            <div className={classes.question}>
                <MaterialText className={classes.text}>
                    {textValue}
                </MaterialText>
            </div>
        );
    }

    renderPartMarks(numberOfMarks) {
        if (!numberOfMarks) {
            return null;
        }
        const { parts } = this.props.formData;
        if (parts.size <= 1) {
            return null;
        }

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                }}
            >
                <MarksChip style={{ marginBottom: 8 }} marks={numberOfMarks} />
            </div>
        );
    }

    renderWordCount(part) {
        const { showWordCount } = this.props.formData;

        if (!showWordCount) {
            return null;
        }

        const value = this.props.value[part.id];
        
        return <WordCount text={value}/>
    }

    isDisabled() {
        return this.context;
    }

    get SharedNonRichEditorProps() {
        const { classes } = this.props;

        return {
            InputProps: {
                className: classes.inputText,
                // classes: {underline: classes.inputUnderline}
            },
        };
    }
}

MultiTextAnswerForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    // must finish these
};

MultiTextAnswerForm.contextType = DisableAnsweringContext;

// MultiTextAnswerForm (connected to styles)
// -----------------------------------------------

MultiTextAnswerForm = withStyles(styles)(MultiTextAnswerForm);
MultiTextAnswerForm = withMessages(MultiTextAnswerForm);

const mapStoreToProps = (store) => {
    return {
        orgId: getOrgId(getClientSessionData(getSessionData(store))),
    };
};

MultiTextAnswerForm = connect(mapStoreToProps)(MultiTextAnswerForm);

// EXPORT
// -----------------------------------------------
export { MultiTextAnswerForm };
