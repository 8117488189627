const ADD_ELEARNING = '[elearning]add.elearning'
const CLEAR_ELEARNING = '[elearning]clear.elearning';
const SET_SHOW_COMPLETED = '[elearning]set.completed';
const SET_SHOW_COURSE_INFO = '[elearning]set.course_info';

export {
	ADD_ELEARNING, 
	CLEAR_ELEARNING,
	SET_SHOW_COMPLETED,
	SET_SHOW_COURSE_INFO
}