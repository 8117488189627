
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Drawer from '@material-ui/core/Drawer'
import withStyles from '@material-ui/core/styles/withStyles'

// constants
import {contentBarHeight} from '../constants'
import {appBarHeight} from 'components/pages/constants'


// SideDrawer (not connected to styles)
// ------------------------------------------------

let SideDrawer = ({classes, anchor, open, children}) =>
{
	const drawerProps = {classes, anchor, open};
	const offset = appBarHeight + contentBarHeight;

	return (
		<Drawer {...drawerProps}>
			<div style={{marginTop: offset, height: `calc(100% - ${offset}px`}}>
				{children}
			</div>
		</Drawer>
	)
}

SideDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	anchor: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired
}


// SideDrawer (connected to styles)
// ------------------------------------------------

const styles = ({zIndex, palette}) => ({
	paper: {
		zIndex: zIndex.appBar - 2,
		background: palette.background.light + "!important"
	},
	modal: {
		zIndex: zIndex.appBar - 1
	}
});

SideDrawer = withStyles(styles)(SideDrawer);


// Export
// ------------------------------------------------
export {SideDrawer}