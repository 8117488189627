
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {ExamOutroPageMachine} from './machine'


const ExamOutro = () =>
{
	return <ExamOutroPageMachine/>;
}


export {ExamOutro}