import React, { Component } from 'react';
import { connect } from "react-redux";

import { AppPageModifier } from "components/pages/app-page-modifier";

import withStyles from "@material-ui/core/styles/withStyles";

import {getAdminRedirectUrl, mapStoreToProps, mapDispatchToProps, mergeProps} from './helper'

const styles = ({ breakpoints, spacing }) => ({
    iframeContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 10000,
    },
    iframe: {
        margin: 0,
        padding: 0,
    },
    visible: {
        visibility: "visible",
    },
    hidden: {
        visibility: "hidden",
    },
});

class AdminRedirectIframe extends Component {
    state = {  }

    constructor(props){
        super(props);

        this.handleMessageFromAdmin = this.handleMessageFromAdmin.bind(this);

        this.adminIframe = null;
    }

    componentDidMount() {
        const { userID, adminUrl, logout } = this.props;
        const url = getAdminRedirectUrl(userID, adminUrl);

        window.addEventListener("message", this.handleMessageFromAdmin);
        this.setState({ iFrameVisible: true }, () => {
            this.adminIframe.src = url;
        });        
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessageFromAdmin);
    }

    handleMessageFromAdmin(evt) {
        const {logout} = this.props;
        const { data } = evt;
        const { type } = data;
        debugger;
        if (type === 'logout'){
            logout(true);            
        }
    }

    displayIframe() {
        const { iFrameVisible } = this.state;
        const { classes } = this.props;

        const divClass = `${classes.iframeContainer} ${
            iFrameVisible ? classes.visible : classes.hidden
        }`;

        return (
            <div className={divClass}>
                <iframe
                    ref={(frame) => {
                        this.adminIframe = frame;
                    }}
                    className={classes.iframe}
                    frameBorder={0}
                    height={"100%"}
                    width={"100%"}
                />
            </div>
        );
    }    

    render() {
        const appBarProps = {
            title: "Redirecting to Admin",
            loadingTitle: false,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="admin-redirect-iframe"
                    appBarProps={appBarProps}
                />
                {this.displayIframe()}
            </React.Fragment>
        );
    }
}

AdminRedirectIframe = connect(
    mapStoreToProps,
    mapDispatchToProps,
    mergeProps
)(AdminRedirectIframe);

AdminRedirectIframe = withStyles(styles)(AdminRedirectIframe);
 
export {AdminRedirectIframe};