// npm libraries
import {combineReducers} from 'redux-immutable'

// redux-related modules
import {APP_REDUCER} from './app/reducer'
import {CACHE_REDUCER} from './cache/reducer'
import {LOGIN_REDUCER} from './login/reducer'
import {SESSION_REDUCER} from './session/reducer'
import {SCHEDULES_REDUCER} from './schedules/reducer'
import {ELEARNING_REDUCER} from './elearning/reducer'
import {PROCTORIO_REDUCER} from './proctorio/reducer'
import {MESSAGES_REDUCER} from './messages/reducer'
import {EXAM_REDUCER} from './exam/reducer'
import {THEME_REDUCER} from './theme/reducer'
import {SETTINGS_REDUCER} from './settings/reducer'


const ROOT_REDUCER = combineReducers({
	app: APP_REDUCER,
	cache: CACHE_REDUCER,
	exam: EXAM_REDUCER,
	login: LOGIN_REDUCER,
	messages: MESSAGES_REDUCER,
	schedules: SCHEDULES_REDUCER,
	elearning: ELEARNING_REDUCER,
	proctorio: PROCTORIO_REDUCER,
	session: SESSION_REDUCER,
	settings: SETTINGS_REDUCER,
	theme: THEME_REDUCER
});


export {ROOT_REDUCER}