
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE NAMES
// #########################################

const STATES = {
	COMPLETING_EXAM: 'completing_exam',
	FINISHING_ACTIVITY_LOGGER: 'finishing_activity_logger',
	CLOSING_INTEGRTITY_ADVOCATE: 'closing_integrity_advocate',
	FETCHING_RESULTS: 'fetching_results',
	DOWNLOADING_RESULTS: 'downloading_results',
	OUTRO_SCREEN: 'displaying_outro',
	NETWORK_ERROR: 'network_error'
}


// #########################################
// EVENT NAMES
// #########################################

const EVENTS = {
	ERROR: 'error',
	FINISHED: 'success',
	GOT_COMPLETION: 'got.completion',
	EXAM_COMPLETED: 'exam.completed',
	RESULT_DOWNLOADED: 'result.downloaded',
	FINISHED_ACTIVITY_LOGGER: 'finish.activity_logger',
	CLOSED_INTEGRITY_ADVOCATE: 'closed_integrity_advocate'
}


// #########################################
// STATES
// #########################################

const finishingActivityLogger = new XStateConfig();
finishingActivityLogger.addTransition(EVENTS.FINISHED_ACTIVITY_LOGGER, STATES.CLOSING_INTEGRTITY_ADVOCATE);
finishingActivityLogger.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const closingIntegrityAdvocate = new XStateConfig();
closingIntegrityAdvocate.addTransition(EVENTS.CLOSED_INTEGRITY_ADVOCATE, STATES.COMPLETING_EXAM);

const completingExam = new XStateConfig();
completingExam.addTransition(EVENTS.EXAM_COMPLETED, STATES.FETCHING_RESULTS);
completingExam.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const fetchingResults = new XStateConfig();
fetchingResults.addTransition(EVENTS.FINISHED, STATES.OUTRO_SCREEN);
fetchingResults.addTransition(EVENTS.GOT_COMPLETION, STATES.DOWNLOADING_RESULTS);
fetchingResults.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const downloadingResults = new XStateConfig();
downloadingResults.addTransition(EVENTS.RESULT_DOWNLOADED, STATES.OUTRO_SCREEN);
downloadingResults.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const displaying = new XStateConfig();
const networkError = new XStateConfig();


// #########################################
// MACHINE
// #########################################

const _examOutro = new XStateConfig();
_examOutro.initialState = STATES.FINISHING_ACTIVITY_LOGGER;
_examOutro.addState(STATES.FINISHING_ACTIVITY_LOGGER, finishingActivityLogger);
_examOutro.addState(STATES.CLOSING_INTEGRTITY_ADVOCATE, closingIntegrityAdvocate);
_examOutro.addState(STATES.COMPLETING_EXAM, completingExam);
_examOutro.addState(STATES.FETCHING_RESULTS, fetchingResults);
_examOutro.addState(STATES.DOWNLOADING_RESULTS, downloadingResults);
_examOutro.addState(STATES.OUTRO_SCREEN, displaying);
_examOutro.addState(STATES.NETWORK_ERROR, networkError);

const machine = Machine(_examOutro.toObject());
machine.id = "Exam Outro Machine";


// #########################################
// EXPORT
// #########################################

const examOutro = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES}
}

export {examOutro}