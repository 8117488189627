
// npm
import {Map as ImmutableMap} from 'immutable'

// redux (reducers)
import {TIME_REDUCER} from './time/reducer'
import {CONTENT_REDUCER} from './content/reducer'
import {MESSAGES_REDUCER} from './messages/reducer'
import {RESULT_REDUCER} from './result/reducer'

// redux (action types)
import * as ACTION_TYPES from './action-types'

// utils
import {getProp} from 'custom/object-helper'
import {combineImmutableReducers} from 'utils/combine-immutable-reducers'
import {activityLogger, ACTIVITIES} from 'libs/activity_logger/activity-logger'
import { check } from '@xams-utils/check-types'


const ownReducer = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.SET_EXAM_DATA:
			return state.withMutations(mutableState => {
				const pausedProperty=getProp(action, 'data.EXAM.@Paused') || false;
				const paused = check.boolean(pausedProperty)
                    ? pausedProperty
                    : check.nonEmptyString(pausedProperty)
                    ? pausedProperty.toLowerCase() === "true"
                    : false;
				mutableState.set('paused', paused);
				mutableState.set('confirmResume', false);

				const examProperties = getProp(action, 'data.EXAM.EXAMPROPERTIES') || {};
				mutableState.set('showFeedbackReport', examProperties['@ShowFeedback'] || false);
				mutableState.set('canDownloadFeedbackReport', examProperties['@DownloadAnswers'] || false);
				mutableState.set('showScenario', true);
				mutableState.set('showExamInFullScreen', false);
				mutableState.set('initialFullScreen', false);	

				const practice = examProperties["@Practice"];
                if (check.boolean(practice)) {
                    mutableState.set("practice", practice);
                }
			});
		case ACTION_TYPES.SET_CURRENT_EXAM:
			return state.withMutations(mutableState => {
				mutableState.set('name', action.name);
				mutableState.set('guid', action.examGuid);
				mutableState.set('practice', action.practice);
				mutableState.set('scheduleGuid', action.scheduleGuid);
			})
		case ACTION_TYPES.SET_PASSWORD:
			return state.set('p', action.value);
		case ACTION_TYPES.CLEAR_PASSWORD:
			return state.delete('p');
		case ACTION_TYPES.CLEAR_EXAM:
			return ImmutableMap();
		case ACTION_TYPES.CLEAR_EXAM_DATA:
			return ImmutableMap({																												// keeping only properties from SET_CURRENT_EXAM
				name: state.get('name'),
				guid: state.get('guid'),
				scheduleGuid: state.get('scheduleGuid')
			});
		case ACTION_TYPES.SET_PASSWORD_ERROR:
			return state.set('passwordError', action.value);
		case ACTION_TYPES.PAUSE_EXAM:
			return state.set('paused', true);
		case ACTION_TYPES.RESUME_EXAM:
			return state.set('paused', false);
		case ACTION_TYPES.CONFIRM_RESUME_EXAM:{
			return state.set('confirmResume', action.value);			
		}			
		case ACTION_TYPES.SET_FORMRUN_GUID:{
			activityLogger.log(ACTIVITIES.SET_FORM_RUN_GUID, {formRunGuid: action.value});
			return state.set('formRunGuid', action.value);
		}
		case ACTION_TYPES.SET_FORMRUN_ID:
			return state.set('formRunId', action.id);
		case ACTION_TYPES.SET_SHOW_SCENARIO:
			return state.set('showScenario', action.value);				
		case ACTION_TYPES.SET_LEAVE_EXAM:
			return state.set('leaveExam', action.value);							
		case ACTION_TYPES.SET_RESUME_EXAM:
			return state.set('resumeExam', action.value);
		case ACTION_TYPES.SET_SHOW_EXAM_IN_FULL_SCREEN:
			return state.set('showExamInFullScreen', action.value);			
		case ACTION_TYPES.SET_INITIAL_FULL_SCREEN:
			return state.set('initialFullScreen', action.value);										
		default:
			return state;
	}
}

const EXAM_REDUCER = combineImmutableReducers(ownReducer, {
	time: TIME_REDUCER,
	content: CONTENT_REDUCER,
	messages: MESSAGES_REDUCER,
	result: RESULT_REDUCER
});


export {EXAM_REDUCER}