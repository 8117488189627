// npm
import React from "react";

// react
import { withMessages } from "components/hocs/messages";
import { ContentBarTitle } from "components/pages/exam/_layout/content/bar/title";


let PageTitle = ({title}) => {
    return <ContentBarTitle>{title}</ContentBarTitle>;
};

PageTitle = withMessages(PageTitle);

export { PageTitle };
