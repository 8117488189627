import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import {
    getComponentValue,
    getStyle,
    addFontStyles,
    disableArrowKeyListeners,
    enableArrowKeyListeners,
} from "../form-helper";

import { FORM_FIELD_TYPES, FORM_SEPERATORS } from "constants/form";

class FormFieldInput extends Component {
    state = {};

    constructor(props) {
        super(props);

        const today = new Date();
        this.year = today.getFullYear();

        this.inputRef = React.createRef();
        this.regEx = new RegExp(`[${FORM_SEPERATORS.join(",")}]`, "g");

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    handleChange = (e) => {
        const { onChange, name, enabled } = this.props;
        if (enabled) {
            // const value=e.target.value.replace(/[a,b,c]/g, "");
            const value = e.target.value.replace(this.regEx, "");
            onChange(name, value);
        }
    };

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const { onAction, action } = this.props;

            onAction(action);
        }
    };

    handleKeyUp = (e) => {
        const keyCode = e.keyCode;

        if (keyCode >= 32) {
            const value = e.target.value.replace(this.regEx, "");
            const { autoTab, maxLength } = this.props;

            if (
                check.nonEmptyString(value) &&
                value.length === parseInt(maxLength)
            ) {
                document.getElementById(autoTab).focus();
            }
        }
    };

    render() {
        const {
            formValue,
            type,
            name,
            enabled,
            fontObj,
            spellCheck,
            maxLength,
            action,
            autoTab,
            noPaste
        } = this.props;
        const value = getComponentValue(name, formValue);
        const styleObj = addFontStyles(getStyle(this.props), fontObj);

        const spellChecker = check.nonEmptyString(spellCheck)
            ? spellCheck === "true"
            : false;

        const inputProps = {
            id: name,
            name,
            style: styleObj,
            onChange: this.handleChange,
            value,
            autoComplete: "nope",
            spellCheck: spellChecker,
            onFocus: disableArrowKeyListeners,
            onBlur: enableArrowKeyListeners,
        };

        if (noPaste){
            inputProps.onPaste=(e)=>e.preventDefault();   
        }

        if (check.nonEmptyString(maxLength)) {
            inputProps.maxlength = maxLength;
            if (check.nonEmptyString(autoTab)) {
                inputProps.onKeyUp = this.handleKeyUp;
                inputProps.ref = this.inputRef;
            }
        }

        if (check.nonEmptyString(action)) {
            inputProps.onKeyDown = this.handleKeyDown;
        }

        const divStyleObj = {};
        if (check.assigned(styleObj.width)) {
            divStyleObj.width = styleObj.width;
        }

        if (type === FORM_FIELD_TYPES.DATE) {
            inputProps.type = "date";
            inputProps.min = this.year + "-01-01";
            inputProps.max = this.year + "-12-31";
        } else if (type === FORM_FIELD_TYPES.TIME) {
            inputProps.type = "time";
        }

        if (!enabled) {
            inputProps.readOnly = true;
        }

        const formProps = { autoComplete: "off" };

        return (
            <div style={divStyleObj}>
                <form {...formProps}>
                    <input {...inputProps} />
                </form>
            </div>
        );
    }
}

export { FormFieldInput };
