
// utils
import {massReplace} from 'custom/string-helper'


const detokenizeMessage = (message, tokenMappings={}) =>
{
	Object.keys(tokenMappings).forEach(token => {
		const replacement = tokenMappings[token];
		message = massReplace(message, token, replacement);
	});

	return message;
}


export {detokenizeMessage}