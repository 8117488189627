
// xams-utils
import {check} from '@xams-utils/check-types'


// Cookies class
// ------------------------------------------------------------------------------

const validateCookieName = (name) =>
{
	if (!check.nonEmptyString(name)) {
		throw "[Cookies.set] name must be a non-empty string";
	}
}

const validateCookieValue = (value) =>
{
	if (!check.string(value)) {
		throw "[Cookies.set] value must be a string";
	}
}

const validateCookiePath = (path) =>
{
	if (!check.nonEmptyString(path)) {
		throw "[Cookies.set] path must be a non-empty string";
	}

	if (!path.startsWith('/')) {
		throw "[Cookies.set] path must begin with a '/' (RFC 6265)";
	}
}

const validateCookieDomain = (domain) =>
{
	if (!check.nonEmptyString(domain)) {
		throw "[Cookies.set] domain must be a non-empty string";
	}

	if (domain.indexOf('.') === -1) {
		throw "[Cookies.set] domain is not valid";
	}
}

const validateCookieMaxAge = (maxAge) =>
{
	if (!check.integer(maxAge)) {
		throw "[Cookies.set] maxAge must be an integer (in seconds)";
	}
}

const validateCookieExpires = (expires) =>
{
	const date = Date.parse(expires);
	
	if (date === NaN) {
		throw "[Cookies.set] expires must be a date-formatted string";
	}
}

const validateCookieSecure = (secure) =>
{
	if (!check.boolean(secure)) {
		throw "[Cookies.set] secure must be a boolean";
	}
}

const validateCookieSameSite = (sameSite) =>
{
	if (!check.boolean(sameSite)) {
		throw "[Cookies.set] sameSite must be a boolean";
	}
}


// Cookies class
// ------------------------------------------------------------------------------

class Cookies
{
	set(name, value, options={})
	{
		const {path, domain, maxAge, expires, secure, sameSite} = options;
		const preformattedCookie = [];

		validateCookieName(name);
		preformattedCookie.push(`${name}=`);

		validateCookieValue(value);
		preformattedCookie.push(value);

		if (check.assigned(path)) {
			validateCookiePath(path);
			preformattedCookie.push(`;path=${path}`);
		}

		if (check.assigned(domain)) {
			validateCookieDomain(domain);
			preformattedCookie.push(`;domain=${domain}`);
		}

		if (check.assigned(maxAge)) {
			validateCookieMaxAge(maxAge);
			preformattedCookie.push(`;max-age=${maxAge}`)
		}

		if (check.assigned(expires)) {
			validateCookieExpires(expires);
			const date = new Date(expires);
			preformattedCookie.push(`;expires=${date.toUTCString(date)}`);
		}

		if (check.assigned(secure)) {
			validateCookieSecure(secure);
			preformattedCookie.push(";secure");
		}

		if (check.assigned(sameSite)) {
			validateCookieSameSite(sameSite);
			preformattedCookie.push(";samesite=strict");
		}

		document.cookie = preformattedCookie.join('');
	}

	get(name)
	{
		const cookiesString = decodeURIComponent(document.cookie);
		const cookies = cookiesString.split(';').map(cookie => cookie.trim());
		const cookieIndex = cookies.findIndex(cookie => cookie.startsWith(`${name}=`));
		if (cookieIndex === -1) { return; }
		const cookie = cookies[cookieIndex];
		return cookie.substring(cookie.indexOf('=') + 1);
	}

	delete(name, options={})
	{
		this.set(name, "", {maxAge: 1, ...options});
	}
}


// EXPORT
// ------------------------------------------------------------------------------

const COOKIE_NAMES = {
	USER: 'xams-user',
	CLIENT: 'xams-client',
	TOKENS: 'xams-token',
	SETTINGS: 'xams-settings',
	SCHEDULE: 'xams-schedule',
	EXAM: 'xams-exam'
}

const cookies = new Cookies();

export {COOKIE_NAMES, cookies}