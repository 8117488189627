import React from "react";

import { MaterialText } from "components/presentation/material-text";
import { withMessages } from "components/hocs/messages";

import Avatar from "@material-ui/core/Avatar";
import PauseIcon from "@material-ui/icons/Pause";
import withStyles from "@material-ui/core/styles/withStyles";

import { MESSAGE_IDS } from "constants/message-ids";

const styles = (theme) => ({
    title: {
        display: "flex",
        justifyContent: "left",
        "&>div:nth-child(2)": {
            marginTop: ".5em",
            marginLeft: theme.spacing.unit * 2,
        },
    },
    message: {
        marginTop: theme.spacing.unit * 2,
    },
});

let PauseMessage = (props) => {
    const { classes, messages } = props;

    const title = messages[MESSAGE_IDS.EXAM.PAUSED_TITLE];
    const message = messages[MESSAGE_IDS.EXAM.PAUSED];

    return (
        <div>
            <div className={classes.title}>
                <div>
                    <Avatar>
                        <PauseIcon />
                    </Avatar>
                </div>
                <div>
                    <MaterialText>
                        <b>{title}</b>
                    </MaterialText>
                </div>
            </div>
            <div className={classes.message}>
                <MaterialText>{message}</MaterialText>
            </div>
        </div>
    );
};

PauseMessage = withStyles(styles)(PauseMessage);
PauseMessage = withMessages(PauseMessage);

export { PauseMessage };
