
// npm
import check from 'check-types'


// private functions
// ---------------------------------------
const extractImageSrc = (htmlString) =>
{
	const srcIndex = htmlString.indexOf('src="');
	if (srcIndex === -1) { return; }
	const openingIndex = htmlString.indexOf('"', srcIndex) + 1;
	const closingIndex = htmlString.indexOf('"', openingIndex);
	return htmlString.slice(openingIndex, closingIndex);
}

function* extractImageSrcs(htmlString)
{
	let openingTagIndex = htmlString.indexOf('<img');
	let closingTagIndex = htmlString.indexOf('>', openingTagIndex);

	while (openingTagIndex !== -1 && closingTagIndex !== -1)
	{
		const htmlSubstring = htmlString.substring(openingTagIndex, closingTagIndex);
		const src = extractImageSrc(htmlSubstring);
		if (!!src) { yield src; }

		openingTagIndex = htmlString.indexOf('<img', closingTagIndex);
		closingTagIndex = htmlString.indexOf('>', openingTagIndex);
	}
}


// public functions
// ---------------------------------------

const fromHtmlString = (htmlString) =>
{
	if (!check.string(htmlString)) { return; }
	return [...extractImageSrcs(htmlString)];
}


const imageSrcExtractor = {
	fromHtmlString
}

export {imageSrcExtractor}