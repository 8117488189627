
// npm
import React from 'react'
import generateKey from 'uuid/v4'
import PropTypes from 'prop-types'
import {List as ImmutableList} from 'immutable'

// material-ui
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// custom
import {splitOut} from 'custom/string-helper'

// react
import {DraggableOption} from './draggable-option'
import {DroppableOption} from './droppable-option'
import {MaterialText} from 'components/presentation/material-text'

// utils
import {htmlToReact} from 'utils/html-to-react' // temporary (must use MaterialText)


const ERRORS = { NO_REF: "Dnd (blanks): Include 'ref' in droppable component" };

class BlanksDragAndDropAnswerForm extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundProperties();
		this.initializeBoundMethods();

		const highlightedDroppable = null;
		// these keys are needed so that we can revert positioning of a draggable 
		// option in the event that it was not dropped on a dropbox node
		let draggableKeys = this.props.formData.options.map(x => generateKey());
		this.state = {draggableKeys: ImmutableList(draggableKeys), highlightedDroppable};
	}

	initializeBoundProperties()
	{
		this.fontSize = 12;

		// cache segments since they don't change (and are used quite often)
		this.segments = splitOut(this.props.formData.text, '[]');

		// these refs are needed to determine if a draggable option was dropped
		// on a particular dropbox DOM node
		const droppableSegments = this.segments.filter(segment => segment === '[]');
		this.droppableRefs = droppableSegments.map(segment => React.createRef());
	}

	initializeBoundMethods()
	{
		this.updateDraggableKey = (index) => {
			const {draggableKeys} = this.state;
			this.setState({draggableKeys: draggableKeys.set(index, generateKey())});
		}

		this.findHoveredDroppable = (x, y) => {
			// First check to see if the currently-higlighted dropbox 
			// is still being hovered over (for efficiency reasons)
			const {state:{highlightedDroppable}, droppableRefs} = this;
			const existsHighlighted = highlightedDroppable !== null;
			const droppableRef = existsHighlighted && droppableRefs[highlightedDroppable];
			const droppable = droppableRef && droppableRef.current;
			if (droppable && droppable.containsPoint(x, y)) { return droppableRef; }

			// If not, find the new dropbox we're hovering over, 
			// ...skipping the one we just checked
			return droppableRefs.find((ref, i) => {
				if (ref === droppableRef) { return false; }
				if (!ref.current) { throw ERRORS.NO_REF; }
				return ref.current.containsPoint(x, y);
			});
		}

		this.onOptionDrag = (selectionIndex, e, data) => {
			const droppableRef = this.findHoveredDroppable(e.clientX, e.clientY);
			const existsHighlighted = this.state.highlightedDroppable !== null;
			const exitingDroppable = !droppableRef && existsHighlighted;
			const enteringDroppable = !!droppableRef && !existsHighlighted;
			if (exitingDroppable) { this.setState({highlightedDroppable: null}); }
			else if (enteringDroppable) {
				const droppableIndex = this.droppableRefs.indexOf(droppableRef);
				this.setState({highlightedDroppable: droppableIndex}); 
			}
		}

		this.onOptionDrop = (selectionIndex) => {
			const {state:{highlightedDroppable}, droppableRefs} = this;
			const existsHighlighted = highlightedDroppable !== null;
			const droppableRef = existsHighlighted && droppableRefs[highlightedDroppable];
			if (!droppableRef) { return this.updateDraggableKey(selectionIndex); }			// returns nothing (using as filter)
			this.setState({highlightedDroppable: null});
			const value = this.props.formData.options[selectionIndex];
			const answerIndex = this.droppableRefs.indexOf(droppableRef);
			this.props.onChange({selectionIndex: answerIndex, option: value}); 
		}
	}

	render()
	{
		return (
			<div id="blanks-dnd">
				<Card style={{backgroundColor: 'transparent'}}>
					<div style={{position: 'relative'}}>
						{this.UndraggedOptions}
						{this.Answer}
					</div>
				</Card>
			</div>
		)
	}

	get UndraggedOptions()
	{
		const {onOptionDrag:onDrag, onOptionDrop:onDragStop} = this;
		const commonProps = {bounds: '#blanks-dnd', onDrag, onDragStop};

		return this.props.formData.options.map((optionValue, selectionIndex) => {
			if (this.props.value.includes(optionValue)) { return null; }
			const value = htmlToReact.parse(optionValue);
			const draggableKey = this.state.draggableKeys.get(selectionIndex);
			const props = {id: selectionIndex, value, draggableKey};
			return <DraggableOption {...props} {...commonProps}/>;
		}).filter(x => !!x);
	}

	get Answer()
	{
		return (
			<Typography>
				<p style={{fontSize: this.fontSize}}>
					{this.AnswerRenderables}
				</p>
			</Typography>
		);
	}

	get AnswerRenderables()
	{
		let selectionIndex = 0;
		return this.segments.map(segment => {
			return segment === '[]' ?
				this.getDroppable(selectionIndex++) :
				this.getTextSegment(segment);
		});
	}

	getDroppable(selectionIndex)
	{
		const props = {
			ref: this.droppableRefs[selectionIndex],
			value: this.props.value[selectionIndex],
			highlighted: this.state.highlightedDroppable === selectionIndex,
			onReset: () => this.props.onChange({selectionIndex, optionAnswer: null})
		}

		return <DroppableOption {...props}/>;
	}

	getTextSegment(text)
	{
		return (
			<MaterialText className={this.props.classes.text}>
				{text}
			</MaterialText>
		)
	}
}

BlanksDragAndDropAnswerForm.propTypes = {
	value: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		text: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
	}).isRequired
}


const styles = ({palette}) => ({
	text: {
		display: 'inline',
		color: palette.background.contrastText
	}
});

BlanksDragAndDropAnswerForm = withStyles(styles)(BlanksDragAndDropAnswerForm);


export {BlanksDragAndDropAnswerForm}