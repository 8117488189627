import React from "react";

import { AppPageModifier } from "components/pages/app-page-modifier";

import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS } from "constants/message-ids";

import { CONSTANTS } from "./constants";
import { ResetPassword } from "./reset-password";

let ResetPasswordPage = (props) => {
    const { pathname } = window.location;
    const pageType =
        pathname.indexOf("reset") !== -1 ? CONSTANTS.RESET : CONSTANTS.SET;
    const title =
        pageType === CONSTANTS.RESET
            ? props.messages[MESSAGE_IDS.RESET_PASSWORD.TITLE]
            : props.messages[MESSAGE_IDS.SET_PASSWORD.TITLE];
    const { onPasswordSet, onCancel } = props;
    const resetProps = { pageType, onPasswordSet, onCancel };

    return (
        <React.Fragment>
            <AppPageModifier appBarProps={{ title, logo: true }} />
            <ResetPassword {...resetProps} />
        </React.Fragment>
    );
};

ResetPasswordPage = withMessages(ResetPasswordPage);

export { ResetPasswordPage };
