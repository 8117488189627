
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

// material-ui
import Button from '@material-ui/core/Button'

// react
import {withMessages} from 'components/hocs/messages'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const confirmMessageId = MESSAGE_IDS.GENERAL.CONFIRM;


// CloseErrorButton (not connected to router/messages)
// --------------------------------------------------------
let CloseErrorButton = ({history, messages}) =>
{
	const props = {
		size: 'small',
		color: 'primary',
		variant: 'outlined',
		disableRipple: true,
		disableFocusRipple: true,
		onClick: () => history.replace('/schedules')
	}

	return (
		<Button {...props}>
			{messages[confirmMessageId]}
		</Button>
	);
}

CloseErrorButton.propTypes = {
	history: PropTypes.object.isRequired,
	messages: PropTypes.shape({
		[confirmMessageId]: PropTypes.string.isRequired
	})
}


// CloseErrorButton (not connected to router/messages)
// --------------------------------------------------------
CloseErrorButton = withRouter(CloseErrorButton);
CloseErrorButton = withMessages(CloseErrorButton);


// Export
// --------------------------------------------------------
export {CloseErrorButton}