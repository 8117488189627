
// npm
import React from 'react'


// Size = Stretch to fill as much space possible
// Flex-align children along the vertical axis
const style = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	flexGrow: 1
}

const ContentBox = ({children}) => (
	<div style={style}>{children}</div>
)


export {ContentBox}