// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// material-ui
import { styles } from "./styles";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AutoRenewIcon from "@material-ui/icons/Autorenew";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { Clock } from "./clock";
import { ScheduleItem } from "./item";
import { withMessages } from "components/hocs/messages";
import { MaterialText } from "components/presentation/material-text";

// redux (selectors)
import { getSchedulesData, getElearningData } from "redux/reducers/selectors";
import { getScheduleCount } from "redux/reducers/schedules/selectors";
import { getElearningCount } from "redux/reducers/elearning/selectors";

// constants
import { MESSAGE_IDS } from "constants/message-ids";
import { ElearningSchedules } from "components/pages/e_learning/elearning-schedules";

const schedulesTitleMessageId = MESSAGE_IDS.SCHEDULE.TITLE;

// SchedulesForm (not connected to store)
// --------------------------------------------------------------------------

class SchedulesForm extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.layout}>
                <Fade in timeout={1000}>
					<React.Fragment>
                    {this.Assessments}
                    {this.Elearning}
					</React.Fragment>
                </Fade>
            </div>
        );
    }

    get Assessments() {
        const { classes } = this.props;
        return (
            <Paper className={classes.form}>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    {this.SchedulesText}
                    {this.RefreshButton}
                </div>
                {this.SchedulesList}
            </Paper>
        );
    }

    get Elearning() {
        const { eLearningCount, classes } = this.props;
        if (eLearningCount === 0) return null;

        return (
            <Paper className={classes.elearning}>
                <ElearningSchedules />
            </Paper>
        );
    }

    get SchedulesText() {
        const { classes } = this.props;
        return (
            <div className={classes.schedulesText}>
                <AccessTimeIcon className={classes.clockIcon} color="primary" />
                <MaterialText className={classes.text} variant="h6">
                    {this.props.messages[schedulesTitleMessageId]}
                </MaterialText>
                <Clock />
            </div>
        );
    }

    get SchedulesList() {
        const { classes } = this.props;
        const scheduleItems = [];
        for (var i = 0; i < this.props.scheduleCount; i++) {
            scheduleItems.push(
                <React.Fragment key={i}>
                    <Divider />
                    <ScheduleItem i={i} />
                </React.Fragment>
            );
        }
        return <List className={classes.schedulesTable}>{scheduleItems}</List>;
    }

    get RefreshButton() {
        return (
            <Tooltip title="Reload schedules">
                <IconButton onClick={this.props.onRefresh}>
                    <AutoRenewIcon />
                </IconButton>
            </Tooltip>
        );
    }
}

SchedulesForm.propTypes = {
    classes: PropTypes.object.isRequired,
    onRefresh: PropTypes.func.isRequired,
    scheduleCount: PropTypes.number.isRequired,
    messages: PropTypes.shape({
        [schedulesTitleMessageId]: PropTypes.string.isRequired,
    }).isRequired,
};

// SchedulesForm (connected to store/styles/messages)
// --------------------------------------------------------------------------

const mapStoreToProps = (store) => ({
    scheduleCount: getScheduleCount(getSchedulesData(store)),
    eLearningCount: getElearningCount(getElearningData(store)),
});

SchedulesForm = connect(mapStoreToProps)(SchedulesForm);
SchedulesForm = withMessages(SchedulesForm);
SchedulesForm = withStyles(styles)(SchedulesForm);

export { SchedulesForm };
