
// utils
import {combineImmutableReducers} from 'utils/combine-immutable-reducers'

// redux (reducers)
import {GENERAL_MESSAGES_REDUCER} from './general/reducer'
import {SESSION_MESSAGES_REDUCER} from './session/reducer'


const MESSAGES_REDUCER = combineImmutableReducers(undefined, {
	general: GENERAL_MESSAGES_REDUCER,
	session: SESSION_MESSAGES_REDUCER
});


export {MESSAGES_REDUCER}