
// Responsibility:
// To notify when the bounds of the incoming child's DOM element has changed

// npm
import React from 'react'
import PropTypes from 'prop-types'
import {withWindowSizeListener} from 'react-window-size-listener'

// react
import {GetDomElement} from './get-dom-element'


const MUTATION_OBSERVER_CONFIG = {
	attributes: true,
	attributeFilter: ['style']
}


class BoundsListener extends React.Component
{
	constructor(props)
	{
		super(props);
		this.domElement = null;
		this.elementPos = {x: null, y: null};
		this.elementSize = {w: null, h: null};
		this.initializeBoundMethods();
		this.observer = new MutationObserver(this.notifyChangeInElementBounds);
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.windowSize !== this.props.windowSize)
		{
			this.notifyChangeInElementBounds();
		}
	}

	initializeBoundMethods()
	{
		this.notifyChangeInElementBounds = () => {
			const newElementRect = this.domElement.getBoundingClientRect();
			const {onPositionChange, onSizeChange} = this.props;

			if (onPositionChange) {
				const newPosition = this.getNewPosition(newElementRect);
				if (newPosition) {
					this.elementPos.x = newPosition.x;
					this.elementPos.y = newPosition.y;
					onPositionChange(newPosition.x, newPosition.y);
				}
			}

			if (onSizeChange) {
				const newSize = this.getNewSize(newElementRect);
				if (newSize) {
					this.elementSize.w = newSize.w;
					this.elementSize.h = newSize.h;
					onSizeChange(newSize.w, newSize.h);
				}
			}
		}

		this.updateObserver = () => {
			this.observer.disconnect();
			this.observer.observe(this.domElement, MUTATION_OBSERVER_CONFIG);
		}

		this.updateDomElement = (domElement) => {
			this.domElement = domElement;
			this.updateObserver();
			this.notifyChangeInElementBounds();
		}
	}

	render()
	{
		return (
			<GetDomElement onDomElement={this.updateDomElement}>
				{this.props.children}
			</GetDomElement>
		);
	}

	getNewPosition({left:x, top:y})
	{
		const relativeToDocument = this.props.relativeTo === 'document';
		x += relativeToDocument ? window.pageXOffset : 0;
		y += relativeToDocument ? window.pageYOffset : 0;
		if (this.elementPos.x !== x || this.elementPos.y !== y) { return {x, y}; }
	}

	getNewSize({top, left, bottom, right})
	{
		const w = right - left;
		const h = bottom - top;
		if (this.elementSize.w !== w || this.elementSize.h !== h) { return {w, h}; }
	}
}

BoundsListener.propTypes = {
	relativeTo: PropTypes.oneOf(['viewport', 'document']).isRequired,								// use 'document' for position changes that shouldn't be affected by window scroll
	children: PropTypes.element.isRequired,
	onPositionChange: PropTypes.func,
	onSizeChange: PropTypes.func
}

BoundsListener.defaultProps = {
	relativeTo: 'viewport'
}


BoundsListener = withWindowSizeListener(BoundsListener);


export {BoundsListener}