import React, { Component } from "react";

import { withMessages } from "components/hocs/messages";

import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { MaterialText } from "components/presentation/material-text";

import { MESSAGE_IDS } from "constants/message-ids";

const styles = ({ palette, spacing, breakpoints }) => ({
    root: {
        width: "100%",
        // maxWidth: 360,
        backgroundColor: palette.background.paper,
    },
    paper: {
        padding: spacing.unit *2,
        marginBottom: spacing.unit * 2,
        '&>div:first-child':{
            paddingBottom: spacing.unit * 2,
        }
    }
});

const completeMessageId = MESSAGE_IDS.ELEARNING.COMPLETE;
const completeReturnMessageId = MESSAGE_IDS.ELEARNING.COMPLETE_RETURN;
const schedulesMessageId = MESSAGE_IDS.SCHEDULE.APP_BAR_TITLE;

class CourseInfoMessage extends Component {
    state = {};

    componentDidMount(){
    }

    render() {
        const { info } = this.props;
        if (!info) return null;
        
        const { classes } = this.props;

        return (
            <Paper className={classes.paper}>
                <div><MaterialText>{info}</MaterialText></div>
            </Paper>
        );
    }
}

CourseInfoMessage = withStyles(styles)(CourseInfoMessage);

CourseInfoMessage = withMessages(CourseInfoMessage);

export { CourseInfoMessage };
