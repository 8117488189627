
const MESSAGE_TOKENS = {
	CURRENT_PAPER_PART: "¬PaperPart¬",
	NEXT_PAPER_PART: "¬NextPaperPart¬"
}


Object.keys(MESSAGE_TOKENS).forEach((key) => {
	while (MESSAGE_TOKENS[key].indexOf('¬') >= 0) {
		MESSAGE_TOKENS[key] = MESSAGE_TOKENS[key].replace("¬", "&not;"); // the server sends down "&not;" instead of "¬"
	}
});


export {MESSAGE_TOKENS}