
// npm
import {connect} from 'react-redux'

// react
import {MessageFetcher} from '../fetcher'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getOrgId} from 'redux/reducers/session/client/selectors'
import {getUserGuid, getLanguageId} from 'redux/reducers/session/user/selectors'
import {getClientSessionData, getUserSessionData} from 'redux/reducers/session/selectors'

// redux (actions)
import {setSessionMessage} from '../actions'

// constants
import {getSessionMessageIds} from 'constants/message-ids'


const mapStoreToProps = (store) =>
{
	const sessionData = getSessionData(store);
	const userData = getUserSessionData(sessionData);

	return {
		messageIds: getSessionMessageIds(),
		orgId: getOrgId(getClientSessionData(sessionData)),
		languageId: getLanguageId(userData),
		userGuid: getUserGuid(userData)
	}
}

const mapDispatchToProps = (dispatch) => ({
	setMessage: (id, value) => dispatch(setSessionMessage(id, value))
});

const SessionMessageFetcher = connect(mapStoreToProps, mapDispatchToProps)(MessageFetcher);


// Export
// --------------------------------------------------------------------------
export {SessionMessageFetcher}