
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'


const RedirectToExamIntro = (props) =>
{
	const url = `/exam/${props.match.params.examGuid}/intro`;
	return <Redirect to={url}/>;
}

RedirectToExamIntro.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			examGuid: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}


export {RedirectToExamIntro}