import React, { Component } from "react";

import { eLearningApi } from "libs/api/interface/api-e-learning";

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import withStyles from "@material-ui/core/styles/withStyles";

import { getStatusText, getStatusIcon, hasScormNotStarted, hasScormCompleted, hasScormFailed } from "../helper";
import { ResetScormButton } from "./reset-scorm-button";
import { PlayScormButton } from "./play-scorm-button";

const styles = ({ palette, spacing, breakpoints }) => ({
    sco: {
        width: "100%",
        // maxWidth: 360,
        backgroundColor: palette.background.paper,
        cursor: "pointer",
    },
    secondaryAction: {
        paddingRight: spacing.unit * 2,
    },
    listItem: {
        [breakpoints.down('xs')]: {
            width: 'calc(100% - 48px)',
            alignItems: 'flexStart'
        },  
    },
    paper: {
        [breakpoints.down('xs')]: {
            paddingLeft: 0,
        },  
    },
    listItemAvatar: {
        root: {
            alignItems: 'flexStart'
        }
    }
});

const isScoEnabled=(scos,sco)=>{
    let scoFound = false;
    let enabled = true;

    if (hasScormFailed(sco.status)) return false;
    
    scos.forEach(_sco=>{
        if (!scoFound){
            if (sco.attemptId===_sco.attemptId){
                scoFound = true;
            }
            else{
                const {status} = _sco;
                if (!hasScormCompleted(status)) {
                    enabled=false;
                }
            }
        }
    });

    return enabled;
}

let CourseScos = (props) => {
    const { scos, classes, onPlaySco, updateCourseData, showCompletedMessage } = props;
    const lastSco = scos.length - 1;

    if (showCompletedMessage) return null;

    return (
        <Paper className={classes.paper}>
            <List className={classes.sco}>
                {scos.map((sco, index) => {
                    const enabled = isScoEnabled(scos, sco);
                    const scoProps = {
                        key: index,
                        enabled,
                        index,
                        onPlaySco,
                        updateCourseData,
                        isLast: index === lastSco,
                        ...sco,
                    };

                    return <CourseSco {...scoProps} />;
                })}
            </List>
        </Paper>
    );
};

class CourseSco extends Component {
    constructor(props) {
        super(props);

        this.handlePlaySco = this.handlePlaySco.bind(this);
        this.handleResetSco = this.handleResetSco.bind(this);
        this.handleResetSuccess = this.handleResetSuccess.bind(this);
    }

    handlePlaySco() {
        const { index, onPlaySco } = this.props;

        onPlaySco(index);
    }

    handleResetSco() {
        const { attemptId } = this.props;
        const payload = { scoAttemptId: attemptId };

        eLearningApi
            .resetScormData(payload)
            .then(this.handleResetSuccess, this.handleResetFailue);
    }

    handleResetSuccess(response) {
        const data = JSON.parse(response);

        if (data) {
            const { courseData } = data;
            if (courseData) {
                const { updateCourseData } = this.props;
                updateCourseData(courseData);
            }
        }
    }

    handleResetFailure(response) {
        debugger;
    }

    get SecondaryAction() {
        const { classes, enabled, status } = this.props;
        if (!enabled || hasScormFailed(status)) return null;
        return (
            <ListItemSecondaryAction className={classes.secondaryAction}>
                {this.resetButton}
                {this.scormButton}
            </ListItemSecondaryAction>
        );
    }

    get resetButton() {
        const { status } = this.props;

        return hasScormNotStarted(status) ? null : (
            <ResetScormButton onClick={this.handleResetSco} />
        );
    }

    get scormButton() {
        const { status } = this.props;
        const buttonProps = { status, onClick: this.handlePlaySco, status };
        return <PlayScormButton {...buttonProps} />;
    }

    get Icon() {
        const { status, enabled } = this.props;

        return getStatusIcon(status, enabled);
    }

    get PrimaryText() {
        const { name } = this.props;

        return name;
    }

    get SecondaryText() {
        const { status, dateStarted, dateEnded } = this.props;

        return getStatusText(status, dateStarted, dateEnded);
    }

    render() {
        const { isLast, classes, enabled } = this.props;

        const itemProps={
            alignItems: "flex-start",
            disabled: !enabled,
            className: classes.listItem,
            onClick:this.handlePlaySco
        }

        return (
            <React.Fragment>
                <ListItem button {...itemProps}>
                    <ListItemAvatar className={classes.listItemAvatar}>
                        <Avatar>{this.Icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={this.PrimaryText}
                        secondary={this.SecondaryText}
                    />
                    {this.SecondaryAction}
                </ListItem>
                {!isLast && <Divider />}
            </React.Fragment>
        );
    }
}

CourseScos = withStyles(styles)(CourseScos);
CourseSco = withStyles(styles)(CourseSco);

export { CourseScos };
