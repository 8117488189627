
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {GetDomElement} from 'components/functional/get-dom-element'

// styles
import './material-scroll-wrapper.css'


class MaterialScrollWrapper extends React.Component
{
	constructor(props)
	{
		super(props);

		this.updateScrollStyling = (domElement) => {
			const {horizontal, vertical} = this.props;
			domElement.classList.add('material-scroll');
			if (horizontal) { domElement.classList.add('horizontal-scroll'); }
			if (vertical) { domElement.classList.add('vertical-scroll'); }
		}
	}

	render()
	{
		return (
			<GetDomElement onDomElement={this.updateScrollStyling}>
				{this.props.children}
			</GetDomElement>
		)
	}
}

MaterialScrollWrapper.propTypes = {
	children: PropTypes.element.isRequired,
	vertical: PropTypes.bool.isRequired,
	horizontal: PropTypes.bool.isRequired
}

MaterialScrollWrapper.defaultProps = {
	vertical: true,
	horizontal: true
}


export {MaterialScrollWrapper}