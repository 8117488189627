// redux (action-types)
import {
    SET_LOCAL_ANSWER,
    SET_SERVER_ANSWER,
} from "redux/reducers/exam/content/questions/question/action-types";
import { SET_CURRENT_QUESTION_ID } from "redux/reducers/exam/content/action-types";

const setLocalAnswer = (questionId, value) => ({
    type: SET_LOCAL_ANSWER,
    questionId,
    value,
});

const setServerAnswer = (questionId, value) => ({
    type: SET_SERVER_ANSWER,
    questionId,
    value,
});

const setCurrentQuestionId = (value) => ({
    type: SET_CURRENT_QUESTION_ID,
    value,
    setFromQuestion: true,
});

export { setLocalAnswer, setServerAnswer, setCurrentQuestionId };
