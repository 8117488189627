
// npm
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react
import {Timer} from 'components/functional/timer'
import {TimerUi} from 'components/presentation/timer'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getPaperPartId} from 'redux/reducers/exam/content/complex-selectors'
import {getSectionDataById} from 'redux/reducers/exam/content/sections/selectors'
import {getContentData, getTimeData, isExamPaused} from 'redux/reducers/exam/selectors'
import {getSectionsData, getCurrentQuestionId} from 'redux/reducers/exam/content/selectors'
import {getReadingTimeRemaining, getAnsweringTimeRemaining, getReadingTime, getAnsweringTime, getWarningTime} from 'redux/reducers/exam/complex-selectors'

// redux (actions)
import {setExamTimeRemaining, setSectionTimeRemaining, setExamReadingTimeRemaining, setSectionReadingTimeRemaining, incrementExamElapsedTime} from './actions'

import { activityLogger, ACTIVITIES } from 'libs/activity_logger/activity-logger'

// ExamTimer (not connected to store)
// ------------------------------------------------

class ExamTimer extends Component {
    constructor(props){
        super(props);

        this.handleOnTick = this.handleOnTick.bind(this);
    }

    componentDidMount(){
        const {startTime, resumeTime, timerKey, timerPaperPartId} = this.props;

        activityLogger.log(ACTIVITIES.EXAM_TIMER, {
            mode: 'mount',
            startTime,
            resumeTime,
            timerPaperPartId,
            timerKey
        });
    }

    componentDidUpdate(prevProps){
        const {startTime: prevStartTime, resumeTime:prevResumeTime} = prevProps;
        const {startTime, resumeTime, timerKey, timerPaperPartId} = this.props;

        if (startTime !== prevStartTime) {
            activityLogger.log(ACTIVITIES.EXAM_TIMER, {
                mode: 'update',
                startTime,
                resumeTime,
                timerPaperPartId,
                timerKey,
                prevStartTime,
                prevResumeTime
            });            
        }
    }

    handleOnTick(value, tickValue){
        const {setTimeRemaining, incrementElapsedTime} = this.props;

        setTimeRemaining(value);
        if (tickValue) incrementElapsedTime(tickValue)
    }

    render() { 
        const {startTime, resumeTime, warningTime, timerPaperPartId, onFinish, timerKey, setTimeRemaining, paused} = this.props;

        const timerProps = {
            key: timerKey,
            startTime: startTime,
            resumeTime: resumeTime,
            onTick: this.handleOnTick,
            paused: paused,
            onFinish: ()=>{onFinish(timerPaperPartId)}
        }
    
        const TimerUiComponent = (props) => {
            const color = resumeTime <= warningTime ? 'orange' : undefined;
            return <TimerUi color={color} {...props}/>;
        }
    
        return <Timer {...timerProps}><TimerUiComponent/></Timer>;
    }
}

ExamTimer.propTypes = {
	timerKey: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]).isRequired,
	onFinish: PropTypes.func.isRequired,
	startTime: PropTypes.number.isRequired,
	warningTime: PropTypes.number.isRequired,
	resumeTime: PropTypes.number.isRequired,
	setTimeRemaining: PropTypes.func.isRequired,
	paused: PropTypes.bool.isRequired
}


// ExamTimer (connected to store)
// ------------------------------------------------

const mapStoreToProps = (store) => ({store});
const mapDispatchToProps = (dispatch) => ({dispatch});

const mergeProps = ({store}, {dispatch}, {reading, onFinish, currentQuestionId, timerPaperPartId}) =>
{
	const examData = getExamData(store);
	const contentData = getContentData(examData);
	const paperPartId = getPaperPartId(contentData)(currentQuestionId);

	const startTime = reading ? getReadingTime(examData, currentQuestionId) : getAnsweringTime(examData, currentQuestionId);
	const warningTime = reading ? 0 : getWarningTime(examData, currentQuestionId);
	const resumeTime = reading ? getReadingTimeRemaining(examData, currentQuestionId) : getAnsweringTimeRemaining(examData, currentQuestionId);

	const setTimeRemaining = paperPartId ?
		(value) => dispatch(reading ? setSectionReadingTimeRemaining(value, paperPartId) : setSectionTimeRemaining(value, paperPartId)) :
		(value) => dispatch(reading ? setExamReadingTimeRemaining(value) : setExamTimeRemaining(value));

    const incrementElapsedTime = (value) =>{
        dispatch(incrementExamElapsedTime(value));
    }


	const timerKey = `exam-timer${paperPartId}${reading}`;
	const paused = isExamPaused(getExamData(store));

	return {onFinish, startTime, warningTime, resumeTime, setTimeRemaining, timerKey, paused, timerPaperPartId, incrementElapsedTime};
}


const args = [mapStoreToProps, mapDispatchToProps, mergeProps];
ExamTimer = connect(...args)(ExamTimer);

ExamTimer.propTypes = {
	reading: PropTypes.bool.isRequired
}


// Export
// ------------------------------------------------
export {ExamTimer}