
const styles = ({spacing, breakpoints, palette}) => ({
	scroller: {
		height: '100%',
		overflowY: 'scroll',
		paddingBottom: 200,
	},
	layout: {
	  width: 'auto',
	  display: 'block', // Fix IE11 issue.
	  marginBottom: spacing.unit * 2,
	  marginLeft: spacing.unit * 2,
		marginRight: spacing.unit * 2,
		paddingTop: spacing.unit * 2,
	  [breakpoints.up(800 + spacing.unit * 3 * 2)]: {
			width: 800,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	text: {
		color: palette.background.contrastText + "!important"
	},
	form: {
		padding: spacing.unit * 2,
		position: 'relative',
		backgroundColor: palette.background.light
	},
	elearning: {
		marginTop: spacing.unit * 4,
		padding: spacing.unit * 2,
		position: 'relative',
		backgroundColor: palette.background.light
	},	
	schedulesText: {
		width: '100%',
		display: 'flex',
		flexGrow: 1,
		paddingLeft: 50,
		marginBottom: spacing.unit
	},
	switch:{
		display: 'flex',
		justifyContent: 'flex-end'
	},
	clockIcon: {
		fontSize: 38,
		left: 15,
		marginTop: 2,
		position: 'absolute',
		display: 'inline-block',
		color: palette.background.contrastText + "!important"
	},
	scheduleButton: {
		width: 120,
		backgroundColor: palette.secondary.main + "!important",
		color: palette.secondary.contrastText + "!important"
	},
	buttonDisabled: {
		backgroundColor: palette.secondary.light + "!important",
		color: palette.secondary.contrastText + "!important"
	}
});

export {styles}