
// npm
import React from 'react'

// react
import {AppInitializationMachine} from './machine'
import {LoadingText} from 'components/presentation/loading-text'
import {AppPageModifier} from 'components/pages/app-page-modifier'


const AppInitializationPage = () =>
{
	const appBarProps = {
		title: "Initializing your app",
		loadingTitle: true,
		logo: false
	}

	return (
		<React.Fragment>
			<AppPageModifier id="initialization" appBarProps={appBarProps} loading/>
			<AppInitializationMachine/>
		</React.Fragment>
	)
}


export {AppInitializationPage}