
// redux (selectors)
import * as GENERAL_SELECTORS from './general/selectors'
import * as SESSION_SELECTORS from './session/selectors'
import {getGeneralMessageData, getSessionMessageData} from './selectors'


const getMessageIds = (messagesData) => {
	const generalMessageData = getGeneralMessageData(messagesData);
	const generalMessageIds = GENERAL_SELECTORS.getMessageIds(generalMessageData);

	const sessionMessageData = getSessionMessageData(messagesData);
	const sessionMessageIds = SESSION_SELECTORS.getMessageIds(sessionMessageData);

	return [...generalMessageIds, ...sessionMessageIds];
}

const getMessageById = (messagesData) => (id) => {
	const generalMessageData = getGeneralMessageData(messagesData);
	const generalMessage = GENERAL_SELECTORS.getMessageById(generalMessageData)(id);
	if (generalMessage) { return generalMessage; }

	const sessionMessageData = getSessionMessageData(messagesData);
	const sessionMessage = SESSION_SELECTORS.getMessageById(sessionMessageData)(id);
	return sessionMessage;
}


export {getMessageIds, getMessageById}