import React, { Component } from "react";
import { connect } from "react-redux";

import { getSettingsData } from "redux/reducers/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getApiEndpointUrl } from "redux/reducers/settings/app/selectors";
import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";

import withStyles from "@material-ui/core/styles/withStyles";
import { MaterialText } from "components/presentation/material-text";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import { urlHasChanged, hasAudioStateChanged } from "./audio-helper";

const CONSTANTS = { loading: 0, playing: 1, paused: 2, finished: 3, error: 4 };

const toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
};

class AudioChip extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            audioState: props.state ? props.state : CONSTANTS.loading,
            elapsed: 0,
            duration: null,
            url: null,
        };

        this.canPlay = this.canPlay.bind(this);
        this.finishPlay = this.finishPlay.bind(this);
        this.audioDataLoaded = this.audioDataLoaded.bind(this);
        this.updateElapsed = this.updateElapsed.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.playAudio = this.playAudio.bind(this);
    }

    getUrl(_url) {
        // const url =
        // "~/ROOT/OCN West Midlands/Media/sample files/1. C2 Paper 1 - Questions.mp3";

        if (_url.substring(0, 6) === "~/ROOT") {
            const { apiEndpointUrl, orgId } = this.props;
            let url = `${apiEndpointUrl}resource/ROOT/${_url.substring(7)}`;

            if (_url.indexOf("?orgID") === -1) {
                url += "?orgID=" + orgId;
            }

            return url;
        }
        return _url;
    }

    startAudio(update = false) {
        const { url, elapsed } = this.props;
        const _url = this.getUrl(url);
        // const _url =
        //     "https://scorm-common.xams.co.uk/content/health_and_safety/audio/unit_1/M1_U1.1.000.mp3";

        this.setState({ url: _url });

        this.startAudioTime = new Date();
        this.audio = new Audio(_url);

        if (elapsed > 0) {
            this.audio.currentTime = elapsed;
        }

        this.audio.addEventListener("canplaythrough", this.canPlay);
        this.audio.addEventListener("ended", this.finishPlay);
        this.audio.addEventListener("loadeddata", this.audioDataLoaded);
        this.audio.addEventListener("timeupdate", this.updateElapsed);
        this.audio.addEventListener("error", this.handleError);
    }

    endAudio() {
        if (this.audio) {
            this.audio.pause();
        }
        clearTimeout(this.startTimer);
        this.audio.removeEventListener("canplay", this.canPlay);
        this.audio.removeEventListener("ended", this.finishPlay);
        this.audio.removeEventListener("loadeddata", this.audioDataLoaded);
        this.audio.removeEventListener("timeupdate", this.updateElapsed);
        this.audio.removeEventListener("error", this.handleError);
    }

    componentDidMount() {
        this.startAudio();
    }

    componentDidUpdate(prevProps) {
        if (urlHasChanged(this.props, prevProps)) {
            const audioState = this.props.state
                ? this.props.state
                : CONSTANTS.loading;

            this.setState({ audioState }, () => {
                this.endAudio();
                this.startAudio(true);
            });
        } else if (hasAudioStateChanged(this.props, prevProps)) {
            const { state: newAudioState } = this.props;

            if (newAudioState === CONSTANTS.paused) {
                this.audio.pause();
            } else if (newAudioState === CONSTANTS.playing) {
                this.audio.play();
            }
        }
    }

    componentWillUnmount() {
        this.endAudio();
    }

    canPlay() {
        const { audioState, duration } = this.state;
        const { autoPlay, autoDelay } = this.props;

        if (audioState === CONSTANTS.finished) {
            this.setState({ elapsed: duration });
        } else {
            if (autoPlay) {
                if (autoDelay) {
                    const currentTime = new Date();
                    const delayTime = autoDelay * 1000;
                    const elapsed = currentTime - this.startAudioTime;
                    console.log("autoDelay", delayTime, elapsed);

                    if (elapsed < delayTime) {
                        this.startTimer = setTimeout(
                            this.playAudio,
                            delayTime - elapsed
                        );
                        return;
                    }
                }
                this.playAudio();
            } else {
                this.setState({ audioState: CONSTANTS.paused });
            }
        }
    }

    playAudio() {
        this.elapsedTimer = new Date();
        this.audio.play();
        this.setState({ audioState: CONSTANTS.playing });
    }

    finishPlay() {
        const { autoRepeat, onFinished } = this.props;

        if (autoRepeat) {
            this.audio.play();
            this.setState({ audioState: CONSTANTS.playing });
        } else {
            this.setState({ audioState: CONSTANTS.finished });
            if (onFinished) {
                onFinished();
            }
        }
    }

    audioDataLoaded() {
        this.setState({ duration: Math.floor(this.audio.duration) });
    }

    updateElapsed() {
        const { duration } = this.state;
        const { elapsedUpdate, onElapsed } = this.props;

        if (duration) {
            console.log(this.audio.currentTime);
            const audioTime = Math.floor(this.audio.currentTime);
            this.setState({ elapsed: audioTime });

            if (elapsedUpdate) {
                const currentTime = new Date();
                const elapsedTime = currentTime - this.elapsedTimer;

                if (elapsedTime > elapsedUpdate * 1000) {
                    if (onElapsed) {
                        onElapsed(audioTime);
                        this.elapsedTimer = currentTime;
                    }
                }
            }
        }
    }

    handleError(e) {
        console.log(e);
        this.setState({ audioState: CONSTANTS.error, error: e });
    }

    displayState() {
        const { audioState, url, error, elapsed, duration } = this.state;
        const { classes, autoPlay, canPause } = this.props;

        let text = "";
        if (audioState === CONSTANTS.loading) text = "Loading";
        else if (audioState === CONSTANTS.playing) text = "Playing";
        else if (audioState === CONSTANTS.paused) text = "Paused";
        else if (audioState === CONSTANTS.finished) text = "Finished";
        else if (audioState === CONSTANTS.error) text = `Error: ${url}`;

        const timing = !duration
            ? ""
            : !elapsed
            ? `${toHHMMSS(0)} / ${toHHMMSS(duration)}`
            : `${toHHMMSS(elapsed)} / ${toHHMMSS(duration)}`;

        const label = (
            <div className={classes.label}>
                <div>{text}</div>
                <div>{timing}</div>
            </div>
        );

        return (
            <div className={classes.state}>
                {/* <MaterialText>{text}</MaterialText> */}
                <Chip
                    avatar={
                        <Avatar className={classes.avatar}>
                            <MusicNoteIcon />
                        </Avatar>
                    }
                    label={label}
                    className={classes.chip}
                    deleteIcon={this.getIcon()}
                    onDelete={this.handleClick}
                    onClick={this.handleClick}
                />
            </div>
        );
    }

    getIcon() {
        const { audioState } = this.state;
        const { classes, autoPlay, canPause } = this.props;

        if (audioState === CONSTANTS.finished) {
            return <StopRoundedIcon disabled />;
        } else if (audioState === CONSTANTS.loading) {
            return <HourglassEmptyIcon disabled />;
        }

        if (!autoPlay && !canPause) {
            if (audioState === CONSTANTS.paused) {
                return <PlayCircleFilledWhiteIcon className={classes.icon} />;
            } else if (audioState !== CONSTANTS.playing) {
                return <PauseCircleFilledIcon />;
            }
        }

        if (canPause) {
            if (audioState === CONSTANTS.paused) {
                return <PlayCircleFilledWhiteIcon className={classes.icon} />;
            } else if (audioState === CONSTANTS.playing) {
                return <PauseCircleFilledIcon className={classes.icon} />;
            }
        } else {
            return <PlayCircleFilledWhiteIcon disabled />;
        }
    }

    handleClick() {
        const { audioState } = this.state;
        const { canPause } = this.props;

        if (audioState === CONSTANTS.playing && canPause) {
            this.audio.pause();
            this.setState({ audioState: CONSTANTS.paused });
        } else if (audioState === CONSTANTS.paused) {
            this.audio.play();
            this.setState({ audioState: CONSTANTS.playing });
        }
    }

    displayDuration() {
        const { elapsed, duration } = this.state;
        const { classes } = this.props;

        if (!duration) return null;

        const text = !elapsed
            ? `${toHHMMSS(0)} / ${toHHMMSS(duration)}`
            : `${toHHMMSS(elapsed)} / ${toHHMMSS(duration)}`;

        return (
            <div className={classes.duration}>
                <MaterialText>{text}</MaterialText>
            </div>
        );
    }

    render() {
        const { classes, url } = this.props;

        return (
            <div className={classes.root}>
                {this.displayState()}
                {/* {this.displayDuration()} */}
            </div>
        );
    }
}

const styles = ({ spacing, palette }) => {
    return {
        root: {
            display: "flex",
            justifyContent: "flex-start",
        },
        state: {
            paddingRight: spacing.unit * 2,
        },
        chip: {
            // margin: spacing.unit,
        },
        label: {
            display: "flex",
            justifyContent: "flex-start",
            "&>div:first-child": {
                minWidth: "80px",
            },
        },
        avatar: {
            backgroundColor: palette.primary.main,
            color: palette.background.light,
        },
        icon: { color: palette.primary.main },
    };
};

AudioChip = withStyles(styles)(AudioChip);

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const appSettingsData = getAppSettingsData(settingsData);
    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);

    return {
        apiEndpointUrl: getApiEndpointUrl(appSettingsData),
        orgId: getOrgId(clientSessionData),
    };
};

AudioChip = connect(mapStoreToProps)(AudioChip);

export { AudioChip, CONSTANTS };
