
const validateMinMax = (min, max) => {
	if (min >= max) { throw "randomize: min-max error"; }
}

// -------------------------------------------------------
// Note: min=inclusive, max=exclusive

const randomInteger = (min, max) =>
{
	return Math.floor(randomFloat(min, max));
}

const randomFloat = (min, max) =>
{
	return Math.random() * (max - min) + min;
}

// -------------------------------------------------------

const randomize = {
	integer: (min=0, max=10) => {
		validateMinMax(min, max);
		return randomInteger(min, max);
	},
	float: (min=0, max=10) => {
		validateMinMax(min, max);
		return randomFloat(min, max);
	}
}

// -------------------------------------------------------

export {randomize}