const getOptionWidthFromLength = (optionLength, _default = 200) => {
    let optionWidth = _default;

    if (optionLength > 0) {
        optionWidth =
            optionLength > 20
                ? optionLength * 10
                : optionLength > 10
                ? optionLength * 12
                : optionLength * 14;
    }
    if (optionWidth < 100) optionWidth = 100;

    return optionWidth;
};

export { getOptionWidthFromLength };
