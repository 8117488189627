
const ENDPOINTS = {
  PING: 'assessment/api/assessment/ping',
  ORGS: 'org/api/Orgs',
  USER: 'org/api/Users',
  LOG_ERROR: 'error/api/Error',
  BUG_REPORT: 'error/api/DebugReport',
  LOGOUT: 'assessment/api/assessment/logout',
  ABANDON_SESSION: 'assessment/api/assessment/abandon',
  AUTHENTICATION: 'connect/token',
  LOGIN_TOKEN: 'security/api/Token/CheckStudentLogin',
  ADMIN_LOGIN: 'user/login/zigzagoon',
  INIT: 'assessment/api/assessment/init',
  MESSAGES: 'message/api/Messages/system',
  TIME: 'assessment/api/assessment/serverdatetime',
  SETTING: 'settings/api/settings/setting',
  ASCX: 'ascx/api/ascx',
  SET_PASSWORD_TOKEN:{
    CHECK_LOCAL: 'http://localhost/projects/sco_player/server/set_password_token/check.php',
    CHECK: 'security/api/Password/checktoken',
    SET: 'http://localhost/projects/sco_player/server/set_password/token/set.php',
    RESET: 'security/api/password/reset',
  },
  PASSWORD: {
    FORGOT : 'security/api/password/recover',
    RESET: 'security/api/password/reset',
    GET_LOGIN_DATA: 'security/api/Token/StudentLogin'
  },  
  SCHEDULE: {
    FETCH: 'assessment/api/assessment/schedules',
    WORK: 'assessment/api/assessment/work',
    CAN_START: 'assessment/api/assessment/canstart',
    VERIFY_PASSWORD: 'assessment/api/assessment/checkpassword'
  },
  ASSESSMENT: {
    START: 'assessment/api/assessment/start',
    RESET: 'assessment/api/assessment/reset',
    FETCH: 'assessment/api/assessment/exam/json',
    COMPLETE: 'assessment/api/assessment/complete',
    GET_RESULT: 'assessment/api/assessment/result',
    IS_COMPLETE: 'formrun/api/FormRuns/query',
    SAVE_TIME: 'assessment/api/assessment/savetime',
    SAVE_ANSWER: 'assessment/api/assessment/saveanswer',
    SAVE_ACTIVITY_LOG: 'assessment/api/assessment/activitylog',
    PRINT_STATIC_TEXT: 'print/Question/pdfAsLink'
  },
  UPLOAD: {
    DATABASEINFO: 'upload/databaseinfo',
    UPLOAD: 'upload/upload',
    FILES: 'upload/upload/files',
    DELETE: 'upload/upload',
    DOWNLOAD: 'upload/upload'
  },
  ELEARNING:{
    COURSE: {
      QUERY: 'lms/api/CourseAttempts/query',
      INFO: 'message/api/Messages/form'
    },    
    SCORM:{
      GET: 'lms/api/ScoAttempt',
      SAVE: 'lms/api/ScoAttempt',
      RESET: 'http://localhost/projects/sco_player/server/ajax/reset_scorm_data.php',
    }
  },
  PROCTORING:{
    GET_PROCTORIO_LAUNCH_URL: 'proctor/api/Proctorio/launchurl'
  },
  DASH:{
    EVENTS: 'dashboard/dashevents',
  }
}

const RETRY_COUNT = 3;
const DEFAULT_ERROR_MESSAGE = 'There is a problem with the XAMS service';

const STATUS_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  DOUBLE_LOGIN: 499
}

const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE'
}

// oauth2 credentials require both the user to authenticate
// and the client app to be authorised. So the client app has
// an id and secret which is added to user credentials for requesting
// access token and subsequently used to get a refresh token.
const OAUTH = {
  ID: 'ro.client',
  SECRET: 'secret',
  GRANT_TYPES: {
    PASSWORD: 'password',
    REFRESH: 'refresh_token'
  }
}

// Generic errors are handled close to the api's implementation rather than
// from the dozens of api calls (to limit duplicated handling)
const GENERIC_ERRORS = {
  DOUBLE_LOGIN: 'Double Login Error',
  DOUBLE_LOGIN_THROW: 'double-login error was handled',
  UNAUTHORIZED: 'No authorization for page'
};

Object.freeze(OAUTH);
Object.freeze(ENDPOINTS);
Object.freeze(REQUEST_TYPES);
Object.freeze(GENERIC_ERRORS);


export {
  OAUTH,
  ENDPOINTS,
  RETRY_COUNT,
  STATUS_CODES,
  REQUEST_TYPES,
  GENERIC_ERRORS,
  DEFAULT_ERROR_MESSAGE
}