
// redux (helpers)
import {traverseParentIds, traverseDepthFirst} from './mappings/helpers'

// redux (selectors)
import * as SELECTORS from './selectors'
import {getSectionDataById} from './sections/selectors'


const traverseParents = (contentData) => function*(childId)
{
	const sectionsData = SELECTORS.getSectionsData(contentData);
	const mappingsData = SELECTORS.getMappingsData(contentData);
	const _traverseParentIds = traverseParentIds(mappingsData);
	const _getSectionDataById = getSectionDataById(sectionsData);
	for (let parentId of _traverseParentIds(childId)) { 
		yield _getSectionDataById(parentId);
	}
}

const traverseChildIds = (contentData) => function*(id)
{
	const mappingsData = SELECTORS.getMappingsData(contentData);
	const _traverseChildIds = traverseDepthFirst(mappingsData);
	for (let childId of _traverseChildIds(id)) { yield childId; }
}


export {traverseParents, traverseChildIds}