import React, { Component } from "react";

import { AppPageModifier } from "components/pages/app-page-modifier";

import { RedirectProctorioMachine } from "./redirect-machine";

class RedirectProctorioMachinePage extends Component {
    state = {};

    constructor(props) {
        super(props);

        const {
            match: { params },
        } = props;

        this.proctorProvider = params.proctorProvider;
        this.proctoringSessionID = params.proctoringSessionID;
        this.examGuid = params.examGuid;
    }

    render() {
        const appBarProps = {
            title: "Proctorio Initialisation",
            loadingTitle: true,
            logo: true,
            logout: false,
        };

        const machineProps = {
            proctorProvider: this.proctorProvider,
            proctoringSessionID: this.proctoringSessionID,
            examGuid: this.examGuid,
        };

        return (
            <React.Fragment>
                <AppPageModifier appBarProps={appBarProps} loading />
                <RedirectProctorioMachine {...machineProps} />
            </React.Fragment>
        );
    }
}

export { RedirectProctorioMachinePage };
