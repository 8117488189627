
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {DoubleLogin} from './double-login'
import {TokenRefresher} from './token-refresher'
import {SessionPageRouter} from './session-page-router'


const SessionPageWrapper = () => (
	<React.Fragment>
		<DoubleLogin>
			<SessionPageRouter/>
			<TokenRefresher/>
		</DoubleLogin>
	</React.Fragment>
)


export {SessionPageWrapper}