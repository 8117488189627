
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import GradeIcon from '@material-ui/icons/Grade'

// utils
import {pluralize} from 'custom/string-helper'


const getChipLabel = (marks) => (
	`${pluralize("mark", marks)}`
)

const avatar = (
	<Avatar>
		<GradeIcon/>
	</Avatar>
)

const MarksChip = ({marks, color, style}) =>
{
	const label = getChipLabel(marks);
	return <Chip style={style} color={color} avatar={avatar} label={label}/>;
}

MarksChip.defaultProps = {
	style: {},
	color: 'primary'
}

MarksChip.propTypes = {
	marks: PropTypes.number.isRequired
}


export {MarksChip}