
// redux (selectors)
import * as SELECTORS from './selectors'


const traverseParentIds = (mappingsData) => function*(childId)
{
	const getParentSectionId = SELECTORS.getParentSectionId(mappingsData);
	let nextParentId = getParentSectionId(childId);

	while (nextParentId && nextParentId !== 'root')
	{
		yield nextParentId;
		nextParentId = getParentSectionId(nextParentId);
	}
}

const traverseDepthFirst = (mappingsData) => function*(id)
{
	const childIds = SELECTORS.getChildrenForId(mappingsData)(id);
	if (!childIds) { return; }

	for (let i = 0; i < childIds.length; i++)
	{
		const childId = childIds[i];
		yield childId;
		yield* traverseDepthFirst(mappingsData)(childId);
	}
}


export {traverseParentIds, traverseDepthFirst}