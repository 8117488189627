
// npm
import {connect} from 'react-redux'

// react
import {MessageFetcher} from '../fetcher'

// redux (selectors)
import {getSessionData} from 'redux/reducers/selectors'
import {getClientSessionData} from 'redux/reducers/session/selectors'
import {getOrgId} from 'redux/reducers/session/client/selectors'

// redux (actions)
import {setGeneralMessage} from '../actions'

// constants
import {getGeneralMessageIds} from 'constants/message-ids'


const mapStoreToProps = (store) => ({
	messageIds: getGeneralMessageIds(),
	orgId: getOrgId(getClientSessionData(getSessionData(store)))
});

const mapDispatchToProps = (dispatch) => ({
	setMessage: (id, value) => dispatch(setGeneralMessage(id, value))
});

const GeneralMessageFetcher = connect(mapStoreToProps, mapDispatchToProps)(MessageFetcher);


// Export
// --------------------------------------------------------------------------
export {GeneralMessageFetcher}