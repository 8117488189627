import React, { Component } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "material-ui-pickers";
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";

const datesAreTheSame = (_date1, _date2) => {
    const date1 = new Date(_date1);
    const date2 = new Date(_date2);

    if (date1.getDate() !== date2.getDate()) return false;
    if (date1.getMonth() !== date2.getMonth()) return false;
    if (date1.getFullYear() !== date2.getFullYear()) return false;

    return true;
};

const styles = () => ({
    root: {
        marginLeft: "-2px",
        display: "flex",
        width: "100%",
        "&>div:last-child": {
            flex: 1,
        },
    },
    checkBox: {
        margin: 0,
        paddingLeft: 0,
    },
    datePicker: {
        width: "100%",
    },
});

class ConfirmDob extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleDateChange = this.handleDateChange.bind(this);

        this.state = { date: new Date(), match: false };
    }

    handleDateChange(value) {
        const { dob, onSelect } = this.props;
        const match = datesAreTheSame(value, dob);
        this.setState({ date: value, match });
        onSelect(null, match);
    }

    render() {
        const { date, match } = this.state;
        const { classes, label } = this.props;

        return (
            <div className={classes.root}>
                <div>
                    <Checkbox
                        className={classes.checkBox}
                        checked={match}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </div>
                <div>
                    <DatePicker
                        className={classes.datePicker}
                        margin="normal"
                        label={label}
                        value={date}
                        format="do MMMM yyyy"
                        onChange={this.handleDateChange}
                    />
                </div>
            </div>
        );
    }
}

ConfirmDob = withStyles(styles)(ConfirmDob);

export { ConfirmDob };
