import React, { Component } from 'react';
import PropTypes from "prop-types";
import { check } from "@xams-utils/check-types";

import {authenticationApi} from 'libs/api/interface/api-authentication'

import {sessionStorageApi, KEYS} from 'libs/browser_storage/session-storage-api'

import { AppPageModifier } from "components/pages/app-page-modifier";
import { LoadingSpinner } from "components/presentation/loading-spinner";

import {getSetPasswordToken} from '../form/login_token'

const checkIfNeedToSetPassword=(parsedResponse)=>{
    if (!parsedResponse) return false;

    const {resultCode} = parsedResponse;
    if (!check.assigned(resultCode) || resultCode === 3) return false;

    return true;
    // return parsedResponse && parsedResponse.passwordRequired;
}

class CheckingSetPasswordToken extends Component {
    componentDidMount() {
        const { onNeedToSetPassword, onNoNeedToSetPassword } = this.props;

        const saveOffUserDetails=(parsedResponse)=>{
            const {userFirstName, userLastName, logonID} = parsedResponse;
            sessionStorageApi.saveDataTo(KEYS.STUDENT_LOGIN_DETAILS, {
                id: logonID,
                first: userFirstName,
                last: userLastName,
            });
        }

        const onSuccess=(response)=>{
            const parsedResponse = JSON.parse(response);
            const needToSetPassword=checkIfNeedToSetPassword(parsedResponse);

            if (needToSetPassword){
                saveOffUserDetails(parsedResponse);
                onNeedToSetPassword();
            }
            else{
                onNoNeedToSetPassword();
            }
        }

        const onFailed=(response)=>{
            debugger;
            onNoNeedToSetPassword();
        }

        const loginToken = getSetPasswordToken();

        if (loginToken){           
            authenticationApi.checkSetPasswordToken(loginToken)
            .then(onSuccess, onFailed);  
        }
        else onNoNeedToSetPassword();
    }

    saveOffUserDetails(){

    }

    render() {
        const appBarProps = {
            title: "Checking login token",
            loadingTitle: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="initializing-session-msgs"
                    appBarProps={appBarProps}
                    loading
                />
                <LoadingSpinner size="lg" />;
            </React.Fragment>
        );
    }
}

// http://localhost:3000/user/N6pF0Z4avdFAr70sJJqOLcfySd96reQtLoA37rJOqRrLU51qw0Vp3_3vywZmdvYT?tQ8!HOSJCiTrDg1V5ZQF5IPCEmaF45dhOOyNXa7o9DyBQa5cA4bP8oSRIrvO0Epg

// http://localhost:3000/user/
// N6pF0Z4avdFAr70sJJqOLcfySd96reQtLoA37rJOqRrLU51qw0Vp3_3vywZmdvYT
// ?tQ8!HOSJCiTrDg1V5ZQF5IPCEmaF45dhOOyNXa7o9DyBQa5cA4bP8oSRIrvO0Epg



// http://localhost:3000/user/
// N6pF0Z4avdFAr70sJJqOLcfySd96reQtLoA37rJOqRrLU51qw0Vp3_3vywZmdvYT
// ?kYICLOo4wgNFH4JY2XTxM!a!Jh!pvosqGhV85ibB_M7bAglttttD_D0s5xjqvKR!

// http://localhost:3000/user/N6pF0Z4avdFAr70sJJqOLcfySd96reQtLoA37rJOqRrLU51qw0Vp3_3vywZmdvYT?kYICLOo4wgNFH4JY2XTxM!a!Jh!pvosqGhV85ibB_M7bAglttttD_D0s5xjqvKR!



CheckingSetPasswordToken.propTypes = {
    onNeedToSetPassword: PropTypes.func.isRequired,
    onNoNeedToSetPassword: PropTypes.func.isRequired
};

export { CheckingSetPasswordToken };
