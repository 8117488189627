
const styles = theme => ({
	scroller: {
		height: '100%',
		overflowY: 'scroll',
		paddingBottom: 200,
	},
	layout: {
	  width: 'auto',
	  display: 'block', // Fix IE11 issue.
	  marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
	  [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
			width: 800,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	form: {
		padding: theme.spacing.unit * 2,
		backgroundColor: theme.palette.background.light
	},
	checks: {
		marginTop: theme.spacing.unit * 1,
		fontWeight: 'bold'
	},
	rubricTextContainer: {
		marginBottom: theme.spacing.unit * 2
	},
	rubricText: {
		color: theme.palette.background.contrastText
	},
	rubricPaper: {
		backgroundColor: theme.palette.background.main,
		padding: theme.spacing.unit,
		flexGrow: 1,
	},
	buttons: {
		textAlign: 'right'
	},
	button: {
		backgroundColor: theme.palette.primary.main + "!important",
		color: theme.palette.primary.contrastText + "!important"
	},
	buttonDisabled: {
		backgroundColor: theme.palette.background.light + "!important",
		color: theme.palette.background.contrastText + "!important"
	}
});


export {styles}