
// npm
import React from 'react'
import check from 'check-types'
import PropTypes from 'prop-types'

// react
import {MaterialText} from 'components/presentation/material-text'

// material-ui
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'


const getSummary = (summary, classes) =>
{
	if (!check.string(summary)) { return summary; }																														// summary as 'node'
	return <MaterialText className={classes.text} variant='button'>{summary}</MaterialText>;									// summary as 'string'
}

let ExpansionPanel = ({className, classes, children, startOpen, summary, open, onChange}) =>
{
	const panelProps = {className};

	if (check.assigned(open)) {
		panelProps.expanded=open;

		if (check.assigned(onChange)) {
			panelProps.onChange=onChange;
		}
	}
	else if (check.assigned(startOpen)) {
		panelProps.defaultExpanded=startOpen;
	}

	return (
		<MuiExpansionPanel {...panelProps}>
			<ExpansionPanelSummary className={classes.text} expandIcon={<ExpandMoreIcon className={classes.text}/>}>
				{getSummary(summary, classes)}
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				{children}
			</ExpansionPanelDetails>
		</MuiExpansionPanel>
	)
}

ExpansionPanel.propTypes = {
	className: PropTypes.object,
	children: PropTypes.node.isRequired,
	startOpen: PropTypes.bool,
	summary: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string
	])
}

ExpansionPanel.defaultProps = {
	startOpen: false,
	className: {},
	summary: ''
}


const styles = ({palette}) => ({
	text: {
		color: palette.background.contrastText
	}
})

ExpansionPanel = withStyles(styles)(ExpansionPanel);


export {ExpansionPanel}