
// npm
import React from 'react'

// material-ui
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MaterialText} from 'components/presentation/material-text'


const getMessage = (disableNavigation, disableAnswering) => {
  if (disableNavigation && disableAnswering) {
    return 'Paused';
  }
  if (disableAnswering) {
    return 'Reading Time';
  }
  return '';
}

let ExamStateCard = ({disableNavigation, disableAnswering, classes}) => {
  return (
    <Paper className={classes.panel}>
      <MaterialText className={classes.text} variant='h5'>
        {getMessage(disableNavigation, disableAnswering)}
      </MaterialText>
    </Paper> 
  );    
}

// ExamStateCard (connected to styles)
// ---------------------------------------------------

const styles = ({spacing, palette}) => ({
  panel: {
    backgroundColor: palette.secondary.light,
    margin: spacing.unit * 2,
    padding: spacing.unit * 2,
    textAlign: 'center'
  },
  text: {
    color: palette.secondary.contrastText
  }
})

ExamStateCard = withStyles(styles)(ExamStateCard);
 
export {ExamStateCard}