
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {Popup} from 'components/layout/popup'
import {CancelButton} from './buttons/cancel'
import {ConfirmButton} from './buttons/confirm'
import {withMessages} from 'components/hocs/messages'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const finishConfirmTitle2 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_TITLE_2;
const finishConfirm2 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_INFO_2;


let ExamFinishConfirmPopup2 = ({onClose, onConfirm, messages}) =>
{
	const props = {
		title: messages[finishConfirmTitle2],
		content: {text: messages[finishConfirm2]},
		buttons: [
			<ConfirmButton onClick={onConfirm}/>,
			<CancelButton onClick={onClose}/>
		],
		onClose
	}

	return <Popup {...props}/>;
}

ExamFinishConfirmPopup2.propTypes = {
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[finishConfirmTitle2]: PropTypes.string.isRequired,
		[finishConfirm2]: PropTypes.string.isRequired
	}).isRequired
}


ExamFinishConfirmPopup2 = withMessages(ExamFinishConfirmPopup2);


export {ExamFinishConfirmPopup2}