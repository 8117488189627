
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react
import {Image} from 'components/presentation/image'

// redux (selectors)
import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getAppBarLogoUrl} from 'redux/reducers/settings/client/selectors'


// AppBarLogo (not connected to store)
// ----------------------------------------------------------

let AppBarLogo = (props) => (
	<Image src={props.logoUrl} alt="logo" height={36}></Image>
);

AppBarLogo.propTypes = {
	logoUrl: PropTypes.string.isRequired
}


// AppBarLogo (connected to store)
// ----------------------------------------------------------

const mapStoreToProps = (store) => ({
	logoUrl: getAppBarLogoUrl(getClientSettingsData(getSettingsData(store)))
});

AppBarLogo = connect(mapStoreToProps)(AppBarLogo);


// Export
// ----------------------------------------------------------
export {AppBarLogo}