
// redux (action-types)
import {SET_CANT_START_MESSAGE} from 'redux/reducers/schedules/schedule/action-types'


const setCantStartMessage = (scheduleGuid, message) => ({
	type: SET_CANT_START_MESSAGE,
	guid: scheduleGuid,
	message
});


export {setCantStartMessage}