
// npm
import * as Immutable from 'immutable'

// redux
import * as ACTION_TYPES from './action-types'

const initialState={
	entered: false,
	parent: false,
	clientName: null,
	userInfo1: null,
	userInfo2: null,
	examGuid: null,
	completedExamGuid: null,
	debug: false,
	calling: 'multi',
	error: null,
}

const PROCTORIO_REDUCER = (state=Immutable.Map(initialState), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.SET_ENTERED:{
			return state.set('entered', action.value)
		}
		case ACTION_TYPES.SET_PARENT:{
			return state.set('parent', action.value)
		}
		case ACTION_TYPES.SET_CLIENT_NAME:{
			return state.set('clientName', action.value)
		}
		case ACTION_TYPES.SET_USER_INFO_1:{
			return state.set('userInfo1', action.value)
		}
		case ACTION_TYPES.SET_USER_INFO_2:{
			return state.set('userInfo2', action.value)
		}
		case ACTION_TYPES.SET_EXAM_GUID:{
			return state.set('examGuid', action.value)
		}		
		case ACTION_TYPES.SET_COMPLETED_EXAM_GUID:{
			return state.set('completedExamGuid', action.value)
		}				
		case ACTION_TYPES.CLEAR_DATA:{
			return Immutable.Map(initialState)
		}		
		case ACTION_TYPES.SET_ERROR:{
			return state.set('error', action.value)
		}		

		default:
			return state;
	}
}


export {PROCTORIO_REDUCER}