
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {ExamInitializationMachine} from './machine'


const ExamPage = ({match}) => {
	return <ExamInitializationMachine examGuid={match.params.examGuid}/>;
}

ExamPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			examGuid: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}


export {ExamPage}