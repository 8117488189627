
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import check from 'check-types'

// redux (actions)
import {revokeUserSession} from './actions'

// react
import {LoginPageMachine} from './machine'
import {AppPageModifier} from 'components/pages/app-page-modifier'

// utils
import {sessionStorageApi, KEYS} from 'libs/browser_storage/session-storage-api'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// LoginPage (not connected to store)
// -------------------------------------------

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        const {
            match: { params },
        } = props;

        const loginToken = check.assigned(params.loginToken)
            ? params.loginToken
            : null;

        const setPasswordToken = check.assigned(params.setPasswordToken)
            ? params.setPasswordToken
            : null;

        sessionStorageApi.resetDataFor(KEYS.STUDENT_LOGIN_DETAILS);            
        sessionStorageApi.resetDataFor(KEYS.STUDENT_LOGIN);
        sessionStorageApi.saveDataTo(KEYS.STUDENT_LOGIN, {
            loginToken,
            setPasswordToken,
        });

        sessionStorageApi.resetDataFor(KEYS.STUDENT_REFERRER);

        if (loginToken && document.referrer)
            sessionStorageApi.saveDataTo(KEYS.STUDENT_REFERRER, {
                referrer: document.referrer,
            });
    }

    componentDidMount() {
        this.props.clearUserSession();
        sessionStorageApi.resetDataFor(KEYS.EXTERNAL_EXAM); // these get populated when we open a 'preview' from the admin repo
        sessionStorageApi.resetDataFor(KEYS.EXTERNAL_SCHEDULE); // entering login page means we're NOT PREVIEWING, so we reset any potential data that exists here
    }

    render() {
        const title = this.props.messages[MESSAGE_IDS.LOGIN.APP_BAR_TITLE];

        return (
            <React.Fragment>
                <AppPageModifier
                    id="login"
                    appBarProps={{ title, logo: true }}
                />
                <LoginPageMachine />
            </React.Fragment>
        );
    }
}

LoginPage.propTypes = {
	clearUserSession: PropTypes.func.isRequired
}


// LoginPage (connected to store)
// -------------------------------------------

const mapDispatchToProps = (dispatch) => ({
	clearUserSession: () => dispatch(revokeUserSession())
})

LoginPage = connect(undefined, mapDispatchToProps)(LoginPage);
LoginPage = withMessages(LoginPage);


// EXPORT
// -------------------------------------------
export {LoginPage}