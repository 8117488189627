
import {SET_CURRENT_QUESTION_ID} from 'redux/reducers/exam/content/action-types'
import {SET_COMPLETE} from 'redux/reducers/exam/content/sections/section/action-types'


const setCurrentQuestionId = (value) => ({
	type: SET_CURRENT_QUESTION_ID,
	value
});

const setPaperPartComplete = (id) => ({
	type: SET_COMPLETE,
	id
})


export {setCurrentQuestionId, setPaperPartComplete}