
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {Document, Page} from 'react-pdf'

// constants
import {SCALE} from './constants'


class DocumentLoader extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {pageCount: null, pageToLoad: 1, maxPageWidth: null};

		this.onDocumentLoad = ({numPages}) => {
			this.setState({pageCount: numPages});
		}

		const createOnPageLoad = () => {
			let maxPageWidth = 0;

			const updateMaxPageWidth = (width) => {
				if (width > maxPageWidth) { maxPageWidth = width; }
			}

			const updateLoaderState = () => {
				let {pageToLoad, pageCount} = this.state;
				const newState = pageToLoad < pageCount ?
					{pageToLoad: pageToLoad + 1} : {maxPageWidth};
				this.setState(newState);
			}
			
			return ({width}) => {
				updateMaxPageWidth(width * SCALE);
				updateLoaderState();
			}
		}

		// IIFE to restrict scope of maxPageWidth
		this.onPageLoad = createOnPageLoad();
	}

	componentDidUpdate()
	{
		const {pageCount, maxPageWidth} = this.state;

		if (pageCount && maxPageWidth) {
			this.props.onLoad({pageCount, maxPageWidth});
		}
	}

	render()
	{
		const documentProps = {
			file: this.props.resourceUrl,
			onLoadSuccess: this.onDocumentLoad
		}

		return (
			<Document {...documentProps}>
				{this.DocumentPage}
			</Document>
		);
	}

	get DocumentPage()
	{
		const pageProps = {
			onLoadSuccess: this.onPageLoad,
			pageNumber: this.state.pageToLoad
		}

		return <Page {...pageProps}/>
	}
}

DocumentLoader.propTypes = {
	resourceUrl: PropTypes.string.isRequired,
	onLoad: PropTypes.func.isRequired
}


export {DocumentLoader}