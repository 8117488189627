
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Switch, Route, withRouter} from 'react-router-dom'

// react
import {LoginPage} from './login/page'
import {ForgotPasswordPage} from './forgot_password/page'
import {ProctorioMachinePage} from './proctorio/machine-page'
import {ProctorioStartPage} from './proctorio/start-page'
import {ProctorioFinishPage} from './proctorio/end/finish-page'
import {ProctorioReturnPage} from './proctorio/return/return-page'
import {NetworkErrorPage} from './network_error/page'
import {SessionPageWrapper} from './session-page-wrapper'
import {AppInitializationPage} from './app_initialization/page'

// redux (selectors)
import {getAppData} from 'redux/reducers/selectors'
import {isInitialized, hasNetworkErrorOccurred} from 'redux/reducers/app/selectors'

// utils
import {routingBlocker, BLOCKING_TYPES} from 'utils/routing-blocker'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// PageRouter (not connected to store/router)
// ------------------------------------------------------

class PageRouter extends React.Component
{
	constructor(props)
	{
		super(props);

		this.unsubscribe = routingBlocker.subscribe((location, action) => {
			if (action === "PUSH" || action === "REPLACE") { return; }

			const atLogin = this.props.location.pathname.includes('login');
			const toNonLogin = !location.pathname.includes('login');

			if (atLogin && toNonLogin) {
				const {messages} = this.props;
				
				return {
					type: BLOCKING_TYPES.BLOCK,
					message: messages[MESSAGE_IDS.GENERAL.BROWSER_NAV_INTERRUPTED]
				}
			}
		})
	}

	render()
	{
		const {initialized, networkError, location} = this.props;

		if (networkError) { return <NetworkErrorPage/>; }
		if (!initialized) { return <AppInitializationPage/>; }

		return (
			<Switch location={location}>
				<Route path="/login" exact component={LoginPage}/>
				<Route path="/login/:loginToken" exact component={LoginPage}/>
				<Route path="/proctorio-return/:clientName/:userInfo1/:userInfo2" exact component={ProctorioReturnPage}/>
				<Route path="/proctorio/:clientName/:userInfo1/:userInfo2/:examGuid" exact component={ProctorioStartPage}/>
				<Route path="/proctorio/start" exact component={ProctorioMachinePage}/>
				<Route path="/proctorio/return" exact component={ProctorioMachinePage}/>
				<Route path="/proctorio/finish" exact component={ProctorioFinishPage}/>				
				<Route path="/user/:setPasswordToken" exact component={LoginPage}/>
				<Route path="/reset/:setPasswordToken" exact component={LoginPage}/>
				<Route path="/forgot" exact component={ForgotPasswordPage}/>
				{/* <Route path="/reset" exact component={ResetPasswordPage}/> */}
				<Route path="" component={SessionPageWrapper}/>
			</Switch>
		)
	}

	componentWillUnmount()
	{
		this.unsubscribe();
	}
}

PageRouter.propTypes = {
	initialized: PropTypes.bool.isRequired,
	networkError: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
}


// PageRouter (not connected to store/router)
// ------------------------------------------------------

const mapStoreToProps = (store) => ({
	initialized: isInitialized(getAppData(store)),
	networkError: hasNetworkErrorOccurred(getAppData(store))
})

PageRouter = connect(mapStoreToProps)(PageRouter);
PageRouter = withRouter(PageRouter);
PageRouter = withMessages(PageRouter);


// EXPORT
// ------------------------------------------------------
export {PageRouter}