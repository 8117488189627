
// internal
import {localStorageApi, KEYS} from '../local-storage-api'

// utils
import {errorApi} from 'libs/api/interface/api-error'
import {findUserActivityByQuery} from 'components/pages/layout/app_bar/bug_reporter/popup/query-logs'


class ScheduledBugReports
{
	constructor()
	{
		const {queue=[]} = localStorageApi.retrieveDataFrom(KEYS.SCHEDULED_BUG_REPORTS);


		const save = () => {
			localStorageApi.saveDataTo(KEYS.SCHEDULED_BUG_REPORTS, {queue});
		}

		const isAlreadyScheduled = (query) => {
			const queryKeys = Object.keys(query);

			return !!queue.find(report => {
				return queryKeys.every(key => query[key] === report.query[key]);
			});
		}


		this.schedule = (message, query, reason={}) => {
			if (!isAlreadyScheduled(query)) {
				queue.push({message, query, reason});
				save();
			}
		}

		this.remove = (report) => {
			const index = queue.indexOf(report);
			queue.splice(index, 1);
			save();
		}

		this.processQueue = () => {
			queue.forEach(scheduledReport => {
				const payload = {
					reporterGuid: "SYSTEM[V6/PLAYER]",
					bugDescription: scheduledReport.message,
					reason: scheduledReport.reason,
					userActivity: findUserActivityByQuery(scheduledReport.query)
				}

				errorApi.sendBugReport(payload)
				.then(() => { this.remove(scheduledReport); })
			});
		}
	}
}


const scheduledBugReports = new ScheduledBugReports();
export {scheduledBugReports}