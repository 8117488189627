class SaveCounter{
    constructor(){
        this.counter = 0;
        this.counters = [];
    }

    getCounter(){
        const newCounter = parseInt(this.counter);
        this.counters = this.counters.concat([newCounter]);
        this.counter++;

        return newCounter;
    }

    removeCounter(_counter){
        this.counters = this.counters.filter(counter=>counter!==_counter);

    }

    isEmpty(){
        return this.counters.length===0;
    }
}

export {SaveCounter}