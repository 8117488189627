
// npm
import React from 'react'
import PropTypes from 'prop-types'


const divStyle = {
	display: 'flex',
	flexWrap: 'wrap'
}


const ResultPanel = ({children}) => (
	<div style={divStyle}>
		{children}
	</div>
);

ResultPanel.propTypes = {
	children: PropTypes.node.isRequired
}


export {ResultPanel}