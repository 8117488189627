import React, { Component } from 'react';

import {addCalculatorSymbols} from './calculator-helper'

import withStyles from '@material-ui/core/styles/withStyles';

const displayValue=(value)=>{
  if (!value) return "";
  return value.replace('/', '\u00F7').replace('*','\u00D7')
}

class CalculatorDisplay extends Component {
  render() {
    const { value, classes } = this.props;

    return (
    <div className={classes.container}>
      {addCalculatorSymbols(value)}
    </div>);
  }
}

const styles = ({ spacing }) => ({
  container: {
    background: 'black',
    fontWeight: 'bold',
    color: 'lime',
    width: `calc(100% - ${spacing.unit * 4}px)`,
    padding: spacing.unit,
    fontFamily: 'Courier New, Courier, monospace',
    fontSize: '16px',
    minHeight: '18px',
    margin: `${spacing.unit*1.5}px ${spacing.unit}px ${spacing.unit*.5}px  ${spacing.unit}px`,
    borderRadius: '5px',
    wordBreak: 'break-all'
  }
});

CalculatorDisplay = withStyles(styles)(CalculatorDisplay);

export { CalculatorDisplay };
