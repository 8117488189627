import React, { Component } from "react";

import { withMessages } from "components/hocs/messages";

import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import DoneIcon from "@material-ui/icons/Done";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { MaterialText } from "components/presentation/material-text";

import { MESSAGE_IDS } from "constants/message-ids";

const styles = ({ palette, spacing, breakpoints }) => ({
    root: {
        width: "100%",
        // maxWidth: 360,
        backgroundColor: palette.background.paper,
    },
    paper: {
        // padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        "&>div:first-child": {
            paddingBottom: spacing.unit * 2,
        },
    },
    info: {
        padding: spacing.unit * 2,
    },
    avatar: {
        background: palette.primary.main,
    },
});

const completeMessageId = MESSAGE_IDS.ELEARNING.COMPLETE;
const completeReturnMessageId = MESSAGE_IDS.ELEARNING.COMPLETE_RETURN;
const schedulesMessageId = MESSAGE_IDS.SCHEDULE.APP_BAR_TITLE;

class CourseCompleteMessage extends Component {
    state = {};

    componentDidMount() {}

    get Message() {
        const { classes } = this.props;
        const { completeMessage, returnMessage } = this.Messages;

        return (
            <React.Fragment>
                <List aria-label={completeMessage}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <DoneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={completeMessage} />
                    </ListItem>
                </List>
                <Divider variant="middle" />
                <div className={classes.info}>
                    <MaterialText variant="body1">{returnMessage}</MaterialText>
                </div>
            </React.Fragment>
        );
    }

    get OldMessage() {
        const { completeMessage, returnMessage } = this.Messages;

        return (
            <React.Fragment>
                <div>
                    <MaterialText>{completeMessage}</MaterialText>
                </div>
                <div>
                    <MaterialText>{returnMessage}</MaterialText>
                </div>
            </React.Fragment>
        );
    }

    get Messages() {
        const { name, messages } = this.props;
        const schedules = messages[schedulesMessageId].toLowerCase();
        const completeMessage = messages[completeMessageId].replace(
            "~name~",
            name
        );
        const returnMessage = messages[completeReturnMessageId].replace(
            "~schedules~",
            schedules
        );

        return { completeMessage, returnMessage };
    }

    render() {
        const { showCompletedMessage } = this.props;
        if (!showCompletedMessage) return null;

        const { classes } = this.props;

        return <Paper className={classes.paper}>{this.Message}</Paper>;
    }
}

CourseCompleteMessage = withStyles(styles)(CourseCompleteMessage);

CourseCompleteMessage = withMessages(CourseCompleteMessage);

export { CourseCompleteMessage };
