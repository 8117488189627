import React from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({ spacing, palette }) => ({
    root: {
        width: "100%",
        cursor: "default",
    },
    topLine: {
        display: "flex",
        width: "100%",
        "&>div": {
            display: "flex",
            alignItems: "center",
        },
        "&>div:first-child": {
            flexGrow: 1,
            paddingRight: spacing.unit * 2,
            "&>div": {
                width: `calc(100% - 16px)`,
            },
        },
        "&>div:last-child": {
            minWidth: "35px",
            textAlign: "center",
        },
    },
    bottomLine: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        paddingTop: spacing.unit * 2
    },
});

let ProgressLabel = (props) => {
    const { value, classes, message } = props;
    //const message = "Getting your downloadable results";
    return (
        <div className={classes.root}>
            <div className={classes.topLine}>
                <div>
                    <LinearProgress variant="determinate" value={value} />
                </div>
                <div>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >{`${Math.round(value)}%`}</Typography>
                </div>
            </div>
            <div className={classes.bottomLine}>
                <Typography variant="body1" color="textSecondary">
                    {message}
                </Typography>
            </div>
        </div>
    );
};

ProgressLabel = withStyles(styles)(ProgressLabel);

export { ProgressLabel };
