
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

// react
import {MaterialText} from 'components/presentation/material-text'
import {MobileActionButton} from 'components/layout/action_button/mobile'
import {activityLogger, ACTIVITIES} from 'libs/activity_logger/activity-logger'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getParentSectionId} from 'redux/reducers/exam/content/mappings/selectors'
import {getNumber} from 'redux/reducers/exam/content/questions/question/selectors'
import {getQuestionDataById, getQuestionCount} from 'redux/reducers/exam/content/questions/selectors'
import {getQuestionsData, getMappingsData, getCurrentQuestionId} from 'redux/reducers/exam/content/selectors'
import {getNextQuestionId, getQuestionNoInSection, getPaperPartId, getQuestionCountInSection, getPreviousQuestionId} from 'redux/reducers/exam/content/complex-selectors'

// utils
import {keyListener, KEYS} from 'utils/key-listener'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// MiniQuestionNavigation (not connected to store)
// -----------------------------------------------------------------------

class MiniQuestionNavigation extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundMethods();
	}

	navigate(direction)
	{
		direction = direction === 1 ? 'next' : 'previous';
		const nextQuestionId = this.props[`${direction}Question`].id;

		if (nextQuestionId) {
			const activity = direction === 'next' ? ACTIVITIES.MOVE_NEXT_QUESTION : ACTIVITIES.MOVE_PREVIOUS_QUESTION;
			activityLogger.log(activity, {nextQuestionId});
			this.props.onQuestionChange(nextQuestionId);
		}
	}

	initializeBoundMethods()
	{
		this.navigateLeft = this.navigate.bind(this, -1);
		this.navigateRight = this.navigate.bind(this, 1);
	}

	componentDidMount()
	{
		this.unsubscribeFromLeftArrowKey = keyListener.subscribe(KEYS.LEFT_ARROW, this.navigateLeft);
		this.unsubscribeFromRightArrowKey = keyListener.subscribe(KEYS.RIGHT_ARROW, this.navigateRight);
	}

	componentWillUnmount()
	{
		this.unsubscribeFromLeftArrowKey();
		this.unsubscribeFromRightArrowKey();
	}

	render()
	{
		return (
			<div className={this.props.classes.container}>
				{this.getQuestionButton(-1)}
				{this.QuestionProgress}
				{this.getQuestionButton(1)}
			</div>
		)
	}

	getQuestionButton(direction)
	{
		const {previousQuestion, nextQuestion, useDesktopButtons} = this.props;

		const Icon = direction === -1 ? NavigateBeforeIcon : NavigateNextIcon;
		const onClick = direction === -1 ? this.navigateLeft : this.navigateRight;
		const disabled = direction === -1 ? !previousQuestion.id : !nextQuestion.id;

		const {GENERAL:{PREVIOUS, NEXT}} = MESSAGE_IDS;
		const text = this.props.messages[direction === -1 ? PREVIOUS : NEXT];

		const buttonProps = {Icon, onClick, disabled};
		return <MobileActionButton {...buttonProps}>{text}</MobileActionButton>;
	}

	get QuestionProgress()
	{
		return (
			<MaterialText className={this.props.classes.text} variant='overline'>
				{`${this.CurrentQuestionNumber} / ${this.props.questionCount}`}
			</MaterialText>
		)
	}

	get CurrentQuestionNumber()
	{
		const {previousQuestion, nextQuestion} = this.props;
		return previousQuestion.number ? previousQuestion.number + 1 :
			nextQuestion.number ? nextQuestion.number - 1 : 1;
	}
}

MiniQuestionNavigation.propTypes = {
	previousQuestion: PropTypes.shape({
		id: PropTypes.number,
		number: PropTypes.number
	}).isRequired,
	nextQuestion: PropTypes.shape({
		id: PropTypes.number,
		number: PropTypes.number
	}).isRequired,
	questionCount: PropTypes.number.isRequired,
	onQuestionChange: PropTypes.func.isRequired
}


// MiniQuestionNavigation (connected to store)
// -----------------------------------------------------------------------

const mapStoreToProps = (store) =>
{
	const contentData = getContentData(getExamData(store));
	const questionsData = getQuestionsData(contentData);
	const mappingsData = getMappingsData(contentData);

	const currentQuestionId = getCurrentQuestionId(contentData);
	const currentPaperPartId = getPaperPartId(contentData)(currentQuestionId);

	// const questionCount = currentPaperPartId ?
	// 	getQuestionCountInSection(contentData)(currentPaperPartId) :
	// 	getQuestionCount(questionsData);

	const questionCount = getQuestionCount(questionsData);

	const _getQuestionDataById = getQuestionDataById(questionsData);

	const nextQuestionId = getNextQuestionId(contentData);
	const nextQuestionData = _getQuestionDataById(nextQuestionId);
	//const nextQuestionNumber = !nextQuestionData ? undefined : currentPaperPartId ? getQuestionNoInSection(contentData)(currentPaperPartId, nextQuestionId) : getNumber(nextQuestionData);
	const nextQuestionNumber = !nextQuestionData ? undefined : getNumber(nextQuestionData);
	const nextQuestion = {id: nextQuestionId, number: nextQuestionNumber};

	const previousQuestionId = getPreviousQuestionId(contentData);
	const previousQuestionData = _getQuestionDataById(previousQuestionId);
	//const previousQuestionNumber = !previousQuestionData ? undefined : currentPaperPartId ? getQuestionNoInSection(contentData)(currentPaperPartId, previousQuestionId) : getNumber(previousQuestionData);
	const previousQuestionNumber = !previousQuestionData ? undefined : getNumber(previousQuestionData);
	const previousQuestion = {id: previousQuestionId, number: previousQuestionNumber};

	return {questionCount, previousQuestion, nextQuestion};
}

MiniQuestionNavigation = connect(mapStoreToProps)(MiniQuestionNavigation);
MiniQuestionNavigation = withMessages(MiniQuestionNavigation);

MiniQuestionNavigation.propTypes = {
	onQuestionChange: PropTypes.func.isRequired
}


// MiniQuestionNavigation (connected to styles)
// -----------------------------------------------------------------------

const styles = ({palette}) => ({
	container: {
		flexShrink: 0,
		display: 'flex',
		alignItems: 'center'
	},
	text: {
		color: palette.background.contrastText
	}
});

MiniQuestionNavigation = withStyles(styles)(MiniQuestionNavigation);


// Export
// -----------------------------------------------------------------------
export {MiniQuestionNavigation}