
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {PartFinishedPopup} from './part-finished'
import {ExamFinishConfirmPopup} from './exam-finish-confirm'
import {ExamFinishConfirmPopup2} from './exam-finish-confirm-2'
import {PartFinishConfirmPopup} from './part-finish-confirm'
import {PartFinishConfirmPopup2} from './part-finish-confirm-2'
import {PracticeFinishConfirmPopup} from './practice-finish-confirm'
import {PracticeFinishConfirmPopup2} from './practice-finish-confirm-2'


const POPUP_TYPES = {
  EXAM_FINISH_CONFIRM: 0,
  EXAM_FINISH_CONFIRM_2: 1,
  PART_FINISH_CONFIRM: 2,
  PART_FINISH_CONFIRM_2: 3,
  PRACTICE_EXAM_FINISH_CONFIRM: 4,
  PRACTICE_EXAM_FINISH_CONFIRM_2: 5,
  PART_FINISHED: 6
}


const extractPopupData = (type, data) =>
{
	switch (type)
	{
		case POPUP_TYPES.PART_FINISH_CONFIRM:
		case POPUP_TYPES.PART_FINISH_CONFIRM_2:
		case POPUP_TYPES.PART_FINISHED:
			return data;
		case POPUP_TYPES.EXAM_FINISH_CONFIRM:
		case POPUP_TYPES.EXAM_FINISH_CONFIRM_2:
		case POPUP_TYPES.PRACTICE_EXAM_FINISH_CONFIRM:
		case POPUP_TYPES.PRACTICE_EXAM_FINISH_CONFIRM_2:
		default:
			return undefined;
	}
}

const ExamPopup = ({type, data, ...props}) =>
{
	const popupProps = {data: extractPopupData(type, data), ...props};

	switch (type)
	{
		case POPUP_TYPES.EXAM_FINISH_CONFIRM:
			return <ExamFinishConfirmPopup {...popupProps}/>;
		case POPUP_TYPES.EXAM_FINISH_CONFIRM_2:
			return <ExamFinishConfirmPopup2 {...popupProps}/>;
		case POPUP_TYPES.PART_FINISH_CONFIRM:
			return <PartFinishConfirmPopup {...popupProps}/>;
		case POPUP_TYPES.PART_FINISH_CONFIRM_2:
			return <PartFinishConfirmPopup2 {...popupProps}/>;
		case POPUP_TYPES.PRACTICE_EXAM_FINISH_CONFIRM:
			return <PracticeFinishConfirmPopup {...popupProps}/>;
		case POPUP_TYPES.PRACTICE_EXAM_FINISH_CONFIRM_2:
			return <PracticeFinishConfirmPopup2 {...popupProps}/>;
		case POPUP_TYPES.PART_FINISHED:
			return <PartFinishedPopup {...popupProps}/>
		default:
			return null;
	}
}

ExamPopup.propTypes = {
	type: PropTypes.oneOf(Object.values(POPUP_TYPES)),
	onConfirm: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	data: PropTypes.shape({
		paperPartName: PropTypes.string,
		nextPaperPartName: PropTypes.string
	}).isRequired,
}

ExamPopup.defaultProps = {
	type: null,
	data: {}
}


export {ExamPopup, POPUP_TYPES}