
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'


// SidePanel (not connected to styles)
// --------------------------------------------

let SidePanel = ({classes, children}) => (
	<div className={classes.panel}>
		<Paper className={classes.paper} square>
			{children}
		</Paper>
	</div>
)

SidePanel.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired
}


// SidePanel (connected to styles)
// --------------------------------------------

const styles = ({zIndex, palette}) => ({
	paper: {
		height: '100%',
		backgroundColor: palette.background.light
	},
	panel: {
		flexShrink: 0,
		zIndex: zIndex.appBar
	}
})

SidePanel = withStyles(styles)(SidePanel);


// Export
// --------------------------------------------
export {SidePanel}