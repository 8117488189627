import React, { Component } from "react";
import { check } from "@xams-utils/check-types";
import { connect } from "react-redux";
import moment from "moment";

import { getProctorioData } from "redux/reducers/selectors";
import { getError } from "redux/reducers/proctorio/selectors";
import { getAppData } from "redux/reducers/selectors";
import { getNetworkErrorInfo } from "redux/reducers/app/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getAppVersion } from "redux/reducers/settings/app/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";

import { AppPageModifier } from "components/pages/app-page-modifier";

import { Align } from "components/layout/align";
import { MaterialText } from "components/presentation/material-text";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const displayErrorObject = (obj) => {
    const a = [];

    if (check.nonEmptyObject(obj)) {
        for (const property in obj) {
            a.push(
                <MaterialText>
                    <b>{property}</b>: {obj[property]}
                </MaterialText>
            );
        }
    }
    
    return a;
};

const displayDateTime=()=>{
    const a=[]

    a.push(<MaterialText><b>date</b>: {moment().format("dddd, MMMM Do YYYY")}</MaterialText>)
    a.push(<MaterialText><b>time</b>: {moment().format("HH:mm:ss")}</MaterialText>)

    return a;
}

class LoadingFailed extends Component {
    state = {};

    displayError() {
        const { loadingError } = this.props;
        const { error, payload } = loadingError;

        return (
            <div>
                {displayDateTime()}
                {displayErrorObject(error)}
                {displayErrorObject(payload)}
            </div>
        );
    }

    displayLoadingError(title) {
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <MaterialText variant="h6">{title}</MaterialText>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {this.displayError()}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    displayMessage(title){
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <MaterialText variant="h6">{title}</MaterialText>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div>
                    {displayDateTime()}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    render() {
        const { loadingError, message } = this.props;
        let title = "There was an problem setting up your Proctorio Exam";

        if (check.nonEmptyString(message)) {
            title += ": " + message;
        } else if (check.nonEmptyObject(loadingError)) {
            const { name } = loadingError;
            if (check.nonEmptyString(name)) {
                title += ": " + name;
            }
        }
        const appBarProps = {
            title,
            loadingTitle: false,
            logo: true,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="proctorio-error"
                    appBarProps={appBarProps}
                />
                <Align top>
                    <div style={{ paddingTop: "32px" }}>
                        {check.nonEmptyObject(loadingError)
                            ? this.displayLoadingError(title)
                            : this.displayMessage(title)}
                    </div>
                </Align>
            </React.Fragment>
        );
    }
}

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);
    const appData = getAppData(store);
    const appSettingsData = getAppSettingsData(getSettingsData(store));

    return {
        networkErrorInfo: getNetworkErrorInfo(appData),
        appVersion: getAppVersion(appSettingsData),
        loadingError: getError(proctorioData),
    };
};

LoadingFailed = connect(mapStoreToProps)(LoadingFailed);

export { LoadingFailed };
