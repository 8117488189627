
// xams-utils
import {check} from '@xams-utils/check-types'
import {IdGenerator} from '@xams-utils/id-generator'

// constants
import {REQUEST_TYPES} from './constants'


const createEndpointParams = (params={}) =>
{
	if (!check.object(params)) { throw "ApiRequest: params not an object"; }
	if (check.emptyObject(params)) { return ""; }

	let finalParams = '?';

	Object.entries(params).forEach(([paramName, paramValue]) => {										// Object.entries ensures we're working with 'own' properties
		finalParams = `${finalParams}${paramName}=${paramValue}&`;
	});

	// remove final '&' character
	const cutOffIndex = finalParams.lastIndexOf('&');																// using lastIndexOf instead of string length in case..
	return finalParams.slice(0, cutOffIndex);																				// ..there are any problems with unicode characters
}


class ApiRequest
{
	constructor(type, endpoint, retries, id=null)
	{
		this.id = id || IdGenerator.uuid();
		this.type = type;
		this.endpoint = endpoint;
		this.retries = retries;
		this.payload = null;
		this.checkErrorRequest = null;
		this.responseType = null;
		this.progressListener = null;
	}

	setPayload(payload)
	{
		this.payload = payload;
	}

	setResponseType(responseType)
	{
		this.responseType = responseType;
	}

	setProgressListener(progressListener)
	{
		this.progressListener = progressListener;
	}

	setCheckRequestError(checkRequestError){
		this.checkRequestError = checkRequestError;
	}

	getEndpoint()
	{
		const isPostRequest = this.type === REQUEST_TYPES.POST;
		const params = isPostRequest ? "" : createEndpointParams(this.payload);

		return `${this.endpoint}${params}`;
	}

	getPayload()
	{
		return this.type === REQUEST_TYPES.POST ? this.payload : null;								// payload added to endpoint for other request types
	}
}


export {ApiRequest}