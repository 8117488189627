import React, { Component } from "react";
import {withRouter} from 'react-router-dom'

import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'


const styles = ({palette, spacing }) => ({
	buttons: {
        marginTop: spacing.unit * 2,
		textAlign: 'right'
	},
	button: {
		backgroundColor: palette.primary.main + "!important",
		color: palette.primary.contrastText + "!important"
	},
	buttonDisabled: {
		backgroundColor: palette.background.light + "!important",
		color: palette.background.contrastText + "!important"
	},
	submit: {
		marginLeft: spacing.unit
	},
	inputUnderline: {
		'&:before': {
			borderBottom: `1px solid ${palette.background.contrastText} !important`
		}
	}
});


class PdfCheckButtons extends Component {
    state = {};

    returnToSchedulesPage(props) {
        this.props.history.replace("/schedules");
    }

    render() {
        const {classes}=this.props;

        return (
            <React.Fragment><Divider/>
            <div className={classes.buttons}>
                {this.ContinueButton}
                {this.CancelButton}
            </div>
            </React.Fragment>
        );
    }

    get ContinueButton() {
        const { classes, onContinue, canContinue, messages } = this.props;

        if (!canContinue){
            return null;
        }

        const props = {
            variant: "contained",
            color: "primary",
            classes: {
                root: `${classes.submit} ${classes.button}`,
                disabled: classes.buttonDisabled,
            },
            disabled: !!this.props.disableSubmit,
            onClick: () => onContinue(),
        };

        return <Button {...props}>{messages.continue}</Button>;
    }

    get CancelButton() {
        //jd 4/10/2020 - suppress cancel button if proctored and single exam
        if (this.props.proctoringMode == 1) return;

        const { classes, messages } = this.props;

        const props = {
            variant: "contained",
            color: "secondary",
            classes: {
                root: classes.submit,
                disabled: classes.buttonDisabled,
            },
            onClick: () => this.returnToSchedulesPage(props),
        };

        return <Button {...props}>{messages.cancel}</Button>;
    }
}

PdfCheckButtons = withRouter(PdfCheckButtons);
PdfCheckButtons = withStyles(styles)(PdfCheckButtons);

export { PdfCheckButtons };
