const BROWSER = {
  AOL: 'aol',
  EDGE: 'edge',
  EDGE_CHROMIUM: 'edge-chromium',
  CHROME: 'chrome',
  FIREFOX: 'firefox',
  FXIOS: 'fxios',
  CRIOS: 'crios',
  IE: 'ie',
  SAFARI: 'safari',
  IOS : 'ios',
};

const BROWSER_IMAGES = [
  BROWSER.CHROME,
  BROWSER.EDGE,
  BROWSER.EDGE_CHROMIUM,
  BROWSER.FIREFOX,
  BROWSER.FXIOS,
  BROWSER.CRIOS,
  BROWSER.OPERA,
  BROWSER.SAFARI,
  BROWSER.AOL,
  BROWSER.IE,
  BROWSER.IOS
];

export {BROWSER, BROWSER_IMAGES}
