
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {Popup} from 'components/layout/popup'
import {CancelButton} from './buttons/cancel'
import {ConfirmButton} from './buttons/confirm'
import {withMessages} from 'components/hocs/messages'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const finishConfirmTitle1 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_TITLE_1;
const finishConfirm1 = MESSAGE_IDS.EXAM.FINISH_CONFIRM_INFO_1;


let ExamFinishConfirmPopup = ({onClose, onConfirm, messages}) =>
{
	const props = {
		title: messages[finishConfirmTitle1],
		content: {text: messages[finishConfirm1]},
		buttons: [
			<ConfirmButton onClick={onConfirm}/>,
			<CancelButton onClick={onClose}/>
		],
		onClose
	}

	return <Popup {...props}/>;
}

ExamFinishConfirmPopup.propTypes = {
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[finishConfirmTitle1]: PropTypes.string.isRequired,
		[finishConfirm1]: PropTypes.string.isRequired
	}).isRequired
}


ExamFinishConfirmPopup = withMessages(ExamFinishConfirmPopup);


export {ExamFinishConfirmPopup}