
// npm
import React from 'react'

// react (layout)
import {ContentBarTitle} from 'components/pages/exam/_layout/content/bar/title'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


let OutroTitle = ({messages, title}) => {
	const message = messages[MESSAGE_IDS.EXAM.ASSESSMENT_COMPLETE];
	const barTitle=title?`${message} - ${title}`:message;

	return (
	<ContentBarTitle>
		{barTitle}
	</ContentBarTitle>
)}


OutroTitle = withMessages(OutroTitle);


export {OutroTitle}