
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-components
import {Callback} from '@xams-components-functional/callback'
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {renderOtherViews} from './other-view'
import {renderFormFromView} from './form-view'

// machines
import {form} from 'machines/form'


const LoginFormMachine = ({onFinish}) =>
{
	return (
		<StateMachine machine={form.machine}>
			{renderFormFromView()}
			{renderOtherViews(onFinish)}
		</StateMachine>
	)
}

LoginFormMachine.propTypes = {
	onFinish: PropTypes.func.isRequired
}


export {LoginFormMachine}