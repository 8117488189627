import React from "react";
import { connect } from "react-redux";

// redux (selectors)
import { getSettingsData } from "redux/reducers/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getAppVersion } from "redux/reducers/settings/app/selectors";

import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

let VersionNumber = ({ versionNumber, classes }) => {
    const chipProps = {
        label: `v ${versionNumber}`,
        className: classes.chip,
    };
    const title = `XAMS Player Version ${versionNumber}`;

    return (
        <Tooltip title={title}>
            <Chip {...chipProps} />
        </Tooltip>
    );
};

const styles = ({ palette }) => {
    return {
        chip: {
            backgroundColor: palette.primary.main,
            color: palette.background.light,
        },
    };
};

VersionNumber = withStyles(styles)(VersionNumber);

const mapStoreToProps = (store) => {
    const settingsData = getSettingsData(store);
    const appSettingsData = getAppSettingsData(settingsData);

    const versionNumber = getAppVersion(appSettingsData);

    return { versionNumber };
};

VersionNumber = connect(mapStoreToProps)(VersionNumber);

export { VersionNumber };
