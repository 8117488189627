import React from "react";
import moment from "moment";
import { check } from "@xams-utils/check-types";

import Hidden from "@material-ui/core/Hidden";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import StarIcon from "@material-ui/icons/Star";
import BlockIcon from "@material-ui/icons/Block";
import ClearIcon from "@material-ui/icons/Clear";


const hasScormFailed = (status) => status === 3;
const hasScormCompleted = (status) => status === 2;
const hasScormStarted = (status) => status === 1;
const hasScormNotStarted = (status) => status === 0;

const displayDate = (_date, long = true) => {
    const date = check.integer(_date)
        ? new moment(_date * 1000)
        : new moment(_date);

    const text = long
        ? date.format("dddd, MMMM Do YYYY, HH:mm")
        : date.format("DD/MM/YY, HH:mm");

    return text;
};

const getStatusIcon = (status, _enabled = true) => {
    const enabled = hasScormFailed(status) ? true : _enabled;
    const iconProps = { color: enabled ? "primary" : "disabled" };

    if (hasScormCompleted(status)) return <StarIcon {...iconProps} />;
    else if (hasScormStarted(status)) return <StarHalfIcon {...iconProps} />;
    else if (hasScormFailed(status)) return <ClearIcon {...iconProps} />;

    return enabled ? (
        <StarBorderIcon {...iconProps} />
    ) : (
        <BlockIcon {...iconProps} />
    );
};

const getStatusText = (status, dateStarted, dateEnded) => {
    if (hasScormCompleted(status)) {
        return (
            <React.Fragment>
                {"Completed "}
                <Hidden smUp>{displayDate(dateEnded, false)}</Hidden>
                <Hidden xsDown>{displayDate(dateEnded, true)}</Hidden>
            </React.Fragment>
        );
    } else if (hasScormStarted(status)) {
        return (
            <React.Fragment>
                {"Started "}
                <Hidden smUp>{displayDate(dateStarted, false)}</Hidden>
                <Hidden xsDown>{displayDate(dateStarted, true)}</Hidden>
            </React.Fragment>
        );
    }
    else if (hasScormFailed(status)) {
        return 'Failed'
    }

    return "Not started";
};

const getStatusButtonText = (status) => {
    if (hasScormCompleted(status)) return "Review";
    else if (hasScormStarted(status)) return "Resume";

    return "Start";
};

const checkForCompletedSwitch = (eLearning, currentValue=false) => {
    let allCompleted = true;
    let oneCompleted = false;

    eLearning.forEach((eLearning) => {
        const { status } = eLearning;

        if (hasScormCompleted(status)) oneCompleted = true;
        else allCompleted = false;
    });

    let hasCompletedSwitch = false;
    let completedSwitch = false;

    if (!oneCompleted) {
        hasCompletedSwitch = false;
        completedSwitch = false;
    } else if (allCompleted) {
        hasCompletedSwitch = false;
        completedSwitch = true;
    } else {
        hasCompletedSwitch = true;
        completedSwitch = currentValue;
    }

    return { hasCompletedSwitch, completedSwitch };
};

const getNotCompleted = (eLearning) => {
    return eLearning.filter((eLearning) => {
        const { status } = eLearning;
        return !hasScormCompleted(status);
    });
};

const getCourseData = (parsedReponse) => {
    if (!parsedReponse) return null;

    const { results } = parsedReponse;
    if (check.nonEmptyArray(results)) {

        return convertCourseData(results[0]);
    }

    return [];
};

const convertCourseData=(data)=>{
    if (!check.nonEmptyObject(data)) return null;

    const _data = {
        id: data.Course_ID || data.courseID,
        attemptId: data.ID || data.id,
        name: data.Course_Name || data.courseName,
        status: data.Status || data.status,
        dateStarted: data.DateStarted || data.dateStarted,
        dateEnded: data.DateEnded || data.dateEnded,
        guid: data.GUID || data.guid,
        courseInfoTextId: data.LearningObject_TextMessageID,
        scos: convertCourseDataScos(data.ScoAttempts || data.scoAttemptDetails)
    };

    return _data;
}

const convertCourseDataScos=(scos)=>{
    if (!scos) return [];

    return scos.map((sco, index) => {
        return {
            id: sco.Sco_ID || sco.scoID,
            attemptId: sco.ID || sco.id,
            name: sco.Sco_Name || sco.scoName,
            href: sco.Sco_Url || sco.url,
            status: sco.Status || sco.status,
            //status: index===0? 3 : sco.Status || sco.status,
            dateStarted: sco.DateStarted || sco.dateStarted,
            dateEnded: sco.DateEnded || sco.dateEnded,
            guid: sco.GUID || sco.guid,
        };
    });   
}

const getInitialScormData = (parsedResponse, studentInfo) => {
    const { attemptData, courseAttemptDetails } = parsedResponse;
    const initialData = attemptData
        ? attemptData.data
            ? [...attemptData.data]
            : []
        : [];

    const coreEntry = initialData.length === 0 ? "ab-initio" : "resume";
    initialData.push({ e: "cmi.core.entry", v: coreEntry });

    const {name, id} = studentInfo;
    initialData.push({ e: "cmi.core.student.id", v: id });
    initialData.push({ e: "cmi.core.student.name", v: name });

    const courseData = convertCourseData(courseAttemptDetails);

    return { initialData, courseData};
};

const getCourseDataFromSaveResponse=(parsedResponse)=>{
    const {courseData} = parsedResponse;
    const _courseData = courseData ? courseData : parsedResponse;

    return convertCourseData(_courseData);
}

export {
    getStatusIcon,
    getStatusText,
    getStatusButtonText,
    hasScormCompleted,
    hasScormStarted,
    hasScormFailed,
    hasScormNotStarted,
    checkForCompletedSwitch,
    getNotCompleted,
    getCourseData,
    getInitialScormData,
    getCourseDataFromSaveResponse
};
