import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { check } from "@xams-utils/check-types";
import { MaterialText } from "components/presentation/material-text";

const addValuesToLabel = (_label, values) => {
    const label = Object.keys(values).reduce((text, value) => {

        return text.replace(`~${value}~`, values[value]);
    }, _label);

    return label;
};

class ConfirmationDialog extends Component {
    state = {};

    getButtons() {
        const { onClose } = this.props;
        const noProps = {
            onClick: () => onClose(false),
            variant: "contained",
        };
        const yesProps = {
            onClick: () => onClose(true),
            variant: "contained",
            color: "primary",
            autoFocus: true,
        };

        return (
            <DialogActions>
                <Button {...noProps}>No</Button>
                <Button {...yesProps}>Yes</Button>
            </DialogActions>
        );
    }

    getContent() {
        const { label, values } = this.props;
        const lines = label.split("#");
        const text = lines.map((line) => {
            if (check.nonEmptyString(line)) {
                if (line.indexOf("~") !== -1) {
                    const text = addValuesToLabel(line, values);
                    return <MaterialText>{text}</MaterialText>;
                }
                return (
                    <p>
                        <b>{line}</b>
                    </p>
                );
            }
        });

        return (
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
        );
    }

    getTitle() {
        const title = "Change Options";

        return <DialogTitle id="alert-dialog-title">{title}</DialogTitle>;
    }

    render() {
        const { open, onClose } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {this.getTitle()}
                {this.getContent()}
                {this.getButtons()}
            </Dialog>
        );
    }
}

export { ConfirmationDialog };
