
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {milliseconds, seconds} from 'time-convert'
import ImmutablePropTypes from 'immutable-prop-types'

// redux (selectors)
import {getContentData} from 'redux/reducers/exam/selectors'
import {getTimeRemaining} from 'redux/reducers/exam/complex-selectors'
import {getCurrentQuestionId} from 'redux/reducers/exam/content/selectors'
import {getPaperPartId} from 'redux/reducers/exam/content/complex-selectors'


class ExamTimeSaver extends React.Component
{
	constructor(props)
	{
		super(props);
		this.timeOnLastSave = props.timeRemaining;
	}

	render()
	{
		return null;
	}

	componentDidUpdate(previousProps)
	{
		if (this.shouldSave(previousProps)) {
			this.saveTime();
		}
	}

	shouldSave(previousProps)
	{
		if (previousProps.paperPartId !== this.props.paperPartId) {
			return true;
		}

		const elapsedTime = this.timeOnLastSave - this.props.timeRemaining;
		return elapsedTime >= this.props.interval;
	}

	saveTime()
	{
		this.props.saveTime();
		this.timeOnLastSave = this.props.timeRemaining;
	}
}

ExamTimeSaver.defaultProps = {
	interval: 30
}

ExamTimeSaver.propTypes = {
	paperPartId: PropTypes.number,
	saveTime: PropTypes.func.isRequired,
	interval: PropTypes.number.isRequired,
	timeRemaining: PropTypes.number.isRequired
}


export {ExamTimeSaver}