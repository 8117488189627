
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// utils
import {routingBlocker} from 'utils/routing-blocker'

// redux (selectors)
import {getAppData} from 'redux/reducers/selectors'
import {hasNetworkErrorOccurred} from 'redux/reducers/app/selectors'

// redux (actions)
import {setNetworkError} from './actions'


// NetworkErrorBrowserNavigation (not connected to store)
// -------------------------------------------------------------------------

class NetworkErrorBrowserNavigation extends React.Component
{
	constructor(props)
	{
		super(props);

		this.unsubscribe = routingBlocker.subscribe((location, action) => {
			if (this.props.networkError && action === "POP") {
				this.props.removeNetworkError();
			}
		})
	}

	render()
	{
		return this.props.children || null;
	}

	componentWillUnmount()
	{
		this.unsubscribe();
	}
}

NetworkErrorBrowserNavigation.propTypes = {
	networkError: PropTypes.bool.isRequired,
	removeNetworkError: PropTypes.func.isRequired
}


// NetworkErrorBrowserNavigation (connected to store)
// -------------------------------------------------------------------------

const mapStoreToProps = (store) => ({
	networkError: hasNetworkErrorOccurred(getAppData(store))
});

const mapDispatchToProps = (dispatch) => ({
	removeNetworkError: () => dispatch(setNetworkError(false))
});

const args = [mapStoreToProps, mapDispatchToProps];
NetworkErrorBrowserNavigation = connect(...args)(NetworkErrorBrowserNavigation);


// EXPORT
// -------------------------------------------------------------------------
export {NetworkErrorBrowserNavigation}