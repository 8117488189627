import React, { Component } from "react";
import { connect } from "react-redux";

import { getProctorioData } from "redux/reducers/selectors";
import {
    getUserInfo1,
    getUserInfo2,
    getExamGuid,
} from "redux/reducers/proctorio/selectors";

import { ProctorioMachine } from "./machine";

class ProctorioMachinePage extends Component {
    state = {};

    // componentDidMount() {
    //    document.body.style.height = "1000px";
    // }

    render() {
        const { userInfo1, userInfo2, examGuid } = this.props;

        const machineProps = {
            userInfo1,
            userInfo2,
            examGuid,
        };

        return <ProctorioMachine {...machineProps} />;
    }
}

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);
    
    return {
        userInfo1: getUserInfo1(proctorioData),
        userInfo2: getUserInfo2(proctorioData),
        examGuid: getExamGuid(proctorioData),
    };
};

ProctorioMachinePage = connect(mapStoreToProps)(ProctorioMachinePage);

export { ProctorioMachinePage };
