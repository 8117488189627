
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux
import * as actions from './actions'
import {getExamData} from 'redux/reducers/selectors'
import {getSessionData} from 'redux/reducers/selectors'
import {getScheduleGuid, getExamPassword} from 'redux/reducers/exam/selectors'
import {getUserGuid} from 'redux/reducers/session/user/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'

// utils
import {assessmentApi} from 'libs/api/interface/api-assessment'



// ExamPasswordAuthenticator (not connected to store)
// -------------------------------------------------
class ExamPasswordAuthenticator extends React.Component
{
	componentDidMount()
	{
		const onSuccess = (response) => {
			const {result} = JSON.parse(response);
			
			switch (result) {
				case true:
					this.props.onSuccess();
					break;
				case false:
					this.props.setPasswordError("Invalid password"); //NEEDS A MESSAGE!
					this.props.onFail();
					break;
				default:
					throw "response has no 'result'";
			}

			this.props.clearPassword();
		}

		assessmentApi.checkExamPassword(this.props.scheduleGuid, this.props.p, this.props.userGuid)
		.then(onSuccess, this.props.onError);
	}

	render()
	{
		return null;
	}
}

ExamPasswordAuthenticator.propTypes = {
	p: PropTypes.string.isRequired,
	scheduleGuid: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	setPasswordError: PropTypes.func.isRequired
}


// ExamPasswordAuthenticator (connected to store)
// -------------------------------------------------

const mapStoreToProps = (store) => ({
	p: getExamPassword(getExamData(store)),
	scheduleGuid: getScheduleGuid(getExamData(store)),
	userGuid: getUserGuid(getUserSessionData(getSessionData(store)))	
});

const mapDispatchToProps = (dispatch) => ({
	clearPassword: () => {
		dispatch(actions.clearPassword());
	},
	setPasswordError: (msg) => {
		dispatch(actions.setPasswordError(msg));
	}
})

ExamPasswordAuthenticator = connect(mapStoreToProps, mapDispatchToProps)(ExamPasswordAuthenticator);


// EXPORT
// -------------------------------------------------
export {ExamPasswordAuthenticator}