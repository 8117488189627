import {check} from 'temp/xams.UI.Utils/check-types'

// Starting from beginning or end, count the number of times a character appears consecutively
const countConsecutiveCharacters = (string, char, startFromEnd=false) =>
{
	let count = 0;

	const minIndex = 0;
	const maxIndex = string.length - 1;

	const startIndex = startFromEnd ? maxIndex : minIndex;
	const endIndex = startFromEnd ? minIndex - 1 : maxIndex + 1;										// +/- 1 as using !== equality check

	for (let i = startIndex; i !== endIndex; startFromEnd ? i-- : i++)
	{
		if (string.charAt(i) !== char) { break; }
		else { count++; }
	}

	return count;
}


// Split a string into non-captured and captured parts
// e.g. "dog hog" split out using 'og' would return ["d", "og", "h", "og"]
const splitOut = (source, toSearch) =>
{
	const result = [];
	let index = source.indexOf(toSearch);

	while (index >= 0)
	{
		if (index > 0) { result.push(source.slice(0, index)); }
		result.push(source.slice(index, index + toSearch.length));
		source = source.slice(index + toSearch.length);
		index = source.indexOf(toSearch);
	}

	result.push(source);
	return result;
}


const pluralize = (string, _count) => {
    let count = _count;
	
    if (check.string(_count)) {
        count = parseInt(_count);
    }

    const pluralizeString =
        count === 1 ? `${count} ${string}` : `${count} ${string}s`;

    return pluralizeString;
};


const massReplace = (string, substring, replacement) =>
{
	while (string.indexOf(substring) >= 0) {
		string = string.replace(substring, replacement);
	}

	return string;
}


export {countConsecutiveCharacters, splitOut, pluralize, massReplace}