import React, { Component } from "react";

import { check } from "@xams-utils/check-types";

import { eLearningApi } from "libs/api/interface/api-e-learning";

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import CircularProgress from '@material-ui/core/CircularProgress';

import { MaterialText } from "components/presentation/material-text";
import { getStatusText, getStatusIcon } from "../helper";
import { CONSTANTS } from "./constants";
import { ResetScormButton } from "./reset-scorm-button";
import { GoSchedulesButton } from "./go-schedules-button";

const styles = ({ palette, spacing, breakpoints }) => ({
    root: {
        width: "100%",
        // maxWidth: 360,
        backgroundColor: palette.background.paper,
    },
    paper: {
        marginBottom: spacing.unit * 2,
    },
    leftIcon: {
        marginRight: spacing.unit,
    },
    buttons: {
        display: "flex",
    },
    secondaryAction: {
        paddingRight: spacing.unit * 2,
        alignItems: "flex-start",
    },
    listItem: {
        [breakpoints.down("xs")]: {
            width: "calc(100% - 48px)",
        },
    },
    info: {
        padding: spacing.unit * 2,
    },
});

class CourseTitle extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleReturnSchedules = this.handleReturnSchedules.bind(this);
        this.handleResetCourse = this.handleResetCourse.bind(this);
        this.handleResetSuccess = this.handleResetSuccess.bind(this);
    }

    get SecondaryAction() {
        const { page, classes, buttons, message } = this.props;

        if (buttons && !message) {
            return (
                <ListItemSecondaryAction className={classes.secondaryAction}>
                    {buttons.map(button=>button)}
                </ListItemSecondaryAction>
            );      
        }

        if (page !== CONSTANTS.COURSE) return null;

        return (
            <ListItemSecondaryAction className={classes.secondaryAction}>
                {this.ReturnButton}
                {this.ResetButton}
            </ListItemSecondaryAction>
        );
    }

    get ResetButton() {
        const buttonProps = {
            onClick: this.handleResetCourse,
        };

        return <ResetScormButton {...buttonProps} />;
    }

    get ReturnButton() {
        const buttonProps = {
            onClick: this.handleReturnSchedules,
        };

        return <GoSchedulesButton {...buttonProps} />;
    }

    handleResetCourse() {
        const { courseAttemptId } = this.props;
        const payload = { courseAttemptId };

        eLearningApi
            .resetScormData(payload)
            .then(this.handleResetSuccess, this.handleResetFailue);
    }

    handleResetSuccess(response) {
        const data = JSON.parse(response);

        if (data) {
            const { courseData } = data;
            if (courseData) {
                const { updateCourseData } = this.props;
                updateCourseData(courseData);
            }
        }
    }

    handleResetFailure(response) {
        debugger;
    }

    handleReturnSchedules() {
        const { onReturnToSchedules } = this.props;
        onReturnToSchedules();
    }

    get Icon() {
        const { status, message } = this.props;
        if (message) return <CircularProgress size={32}/>
        return getStatusIcon(status);
    }

    get PrimaryText() {
        const { name, courseName } = this.props;
        if (!check.assigned(courseName)) return name;

        return (
            <div>
                {courseName && (
                    <div>
                        <Hidden smUp>{courseName}</Hidden>
                    </div>
                )}
                <div>{name}</div>
            </div>
        );
    }

    get SecondaryText() {
        const { status, dateStarted, dateEnded, message } = this.props;
        const statusText = getStatusText(status, dateStarted, dateEnded);

        return message?message:statusText;
    }

    get Info() {
        const { info, classes } = this.props;
        if (!info) return null;
        
        return (
            <React.Fragment>
                <Divider variant="middle" />
                <div className={classes.info}>
                    <MaterialText variant="body2">{info}</MaterialText>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { classes, page } = this.props;
        const listItemProps = { alignItems: "flex-start" };

        if (page === CONSTANTS.COURSE)
            listItemProps.className = classes.listItem;

        return (
            <Paper className={classes.paper}>
                <List className={classes.root}>
                    <ListItem {...listItemProps}>
                        <ListItemAvatar>
                            <Avatar>{this.Icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={this.PrimaryText}
                            secondary={this.SecondaryText}
                        />
                        {this.SecondaryAction}
                    </ListItem>
                </List>
                {this.Info}
            </Paper>
        );
    }
}

CourseTitle = withStyles(styles)(CourseTitle);

export { CourseTitle };
