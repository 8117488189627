
const getUserId = (userSessionData) => userSessionData.get('id');
const getUserGuid = (userSessionData) => userSessionData.get('guid');
const getLanguageId = (userSessionData) => userSessionData.get('languageId');
const getFirstName = (userSessionData) => userSessionData.get('firstName');
const getLastName = (userSessionData) => userSessionData.get('lastName');
const getDateOfBirth = (userSessionData) => userSessionData.get('dateOfBirth');
const getLearnerIdentifier = (userSessionData) => userSessionData.get('learnerIdentifier');
const isAdmin = (userSessionData) => !!userSessionData.get('admin');
const getEmail = (userSessionData) => userSessionData.get('email');

const getName = (userSessionData) => (
	`${userSessionData.get('firstName')} ${userSessionData.get('lastName')}`
);

const getPublicExamUserName = (userSessionData) => userSessionData.get('publicExamUserName');

export {
	getUserId,
	getUserGuid,
	getLanguageId, 
	getFirstName, 
	getLastName, 
	getName,
	isAdmin,
	getDateOfBirth,
	getLearnerIdentifier,
	getPublicExamUserName,
	getEmail
}