
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {MaterialText} from 'components/presentation/material-text'
import {DisableAnsweringContext} from 'components/pages/exam/disabled-context'

// material-ui
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'


// ComboAnswerForm (not connected to styles)
// ----------------------------------------------------------


const getOptions = (options) => {
	return options.map(({value, text}) => {
		return <MenuItem value={value}>{text}</MenuItem>;
	});
}

const ComboDropDown = ({value, onChange, options, classes}) => {
	const inputProps = {
		className: classes.inputText,
		classes: {underline: classes.inputUnderline}
	}

	const selectProps = {
		input: <Input {...inputProps}/>,
		classes: {icon: classes.icon},
		onChange,
		value
	}

	return ( 
		<FormControl>
			<Select {...selectProps}>
				{getOptions(options)}
			</Select>
		</FormControl>		
	);
}

class ComboAnswerForm extends React.Component
{
	render()
	{ 
		let {formData, onChange, classes} = this.props;
		
		if (this.isDisabled()) { 
			onChange = undefined; 
		}

		const dropdownProps = {
			options: formData.options,
			value: this.props.value,
			onChange,
			classes
		}

		return ( 
			<div style={{display: 'flex', alignItems: 'center'}}>
				<MaterialText className={classes.text}>
					{formData.label}
				</MaterialText>
				<ComboDropDown {...dropdownProps}/>
			</div>			
		);
	}
	
	isDisabled()
	{
		return this.context;
	}
}
 
ComboAnswerForm.contextType = DisableAnsweringContext;

ComboAnswerForm.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		label: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired
		})).isRequired
	})
}

// ComboAnswerForm (connected to styles)
// ----------------------------------------------------------

const styles = ({palette, spacing}) => ({
	inputText: {
		color: palette.primary.main + "!important"
	},
	inputUnderline: {
		'&:before': {
			borderBottom: `1px solid ${palette.background.contrastText} !important`
		},
		'&:after': {
			borderBottom: `1px solid ${palette.background.contrastText} !important`	
		}
	},
	icon: {
		color: palette.background.contrastText
	},
	text: {
		color: palette.background.contrastText,
		marginRight: spacing.unit * 2
	}
})

ComboAnswerForm = withStyles(styles)(ComboAnswerForm);


// Export
// ----------------------------------------------------------
export {ComboAnswerForm}