// npm
import check from "check-types";

// api stuff
import { ApiRequest } from "./api-request";
import { HttpRequest } from "./http-request";
import { tryAtMost } from "custom/promise-helper";
import {
    STATUS_CODES,
    REQUEST_TYPES,
    RETRY_COUNT,
    GENERIC_ERRORS,
} from "./constants";

import * as signalR from "@microsoft/signalr";
import { concatParams } from "./helper";

// utils
import { localStorageApi, KEYS } from "libs/browser_storage/local-storage-api";

// IIFE to cache the generic error types
const isGenericErrorType = (() => {
    const genericErrorTypes = Object.values(GENERIC_ERRORS);
    return (genericErrorType) => {
        return genericErrorTypes.some(
            (errorType) => errorType === genericErrorType
        );
    };
})();

const REQUEST_STATES = {
    STARTING: "STARTING",
    RESPONDED: "RESPONDED",
    NO_RESPONSE: "FAILED",
};

const createEvent = (state, endpoint, payload, statusCode = null) => ({
    state,
    endpoint,
    payload,
    statusCode,
});

const getBeginning = (ending, endpointUrl) => {
    if (isAdminLogin(ending, endpointUrl)) return "";

    if (ending.length < endpointUrl.length) return endpointUrl;

    const start = ending.substring(0, endpointUrl.length);
    //console.log(start.toLowerCase(), endpointUrl.toLowerCase(), start.toLowerCase() === endpointUrl.toLowerCase());

    return start.toLowerCase() === endpointUrl.toLowerCase() ? "" : endpointUrl;
};

const isTempElearning = (ending, endpointUrl) => {
    const tempElearning = "http://localhost/projects/sco_player/server/";
    return ending.lastIndexOf(tempElearning, 0) === 0;
};

const isAdminLogin = (ending, endpointUrl) => {
    const adminUrl = "https://";
    return ending.lastIndexOf(adminUrl, 0) === 0;
};

class Api {
    constructor() {
        this.token = null;
        this.endpointUrl = "";
        this.hubpointUrl = ""
        this.refreshToken = null;
        this.genericErrorHandlers = {};
        this.handleRequestError = this.handleRequestError.bind(this);
        this.errorObj = null;

        this.listeners = [];
    }

    post(
        endpoint,
        payload = "{}",
        retries = RETRY_COUNT,
        progressListener = null,
        id,
        checkRequestError = null
    ) {
        const apiRequest = new ApiRequest(
            REQUEST_TYPES.POST,
            endpoint,
            retries,
            id
        );

        if (check.function(checkRequestError)) {
            apiRequest.setCheckRequestError(checkRequestError);
        }

        apiRequest.setProgressListener(progressListener);
        apiRequest.setPayload(payload);

        return this.makeRequest(apiRequest);
    }

    get(endpoint, params = {}, retries = RETRY_COUNT) {
        const apiRequest = new ApiRequest(REQUEST_TYPES.GET, endpoint, retries);
        apiRequest.setPayload(params);
        return this.makeRequest(apiRequest);
    }

    delete(endpoint, params = {}, retries = RETRY_COUNT) {
        const apiRequest = new ApiRequest(
            REQUEST_TYPES.DELETE,
            endpoint,
            retries
        );
        apiRequest.setPayload(params);
        return this.makeRequest(apiRequest);
    }

    download(endpoint, params = {}, retries = RETRY_COUNT) {
        const apiRequest = new ApiRequest(REQUEST_TYPES.GET, endpoint, retries);
        apiRequest.setResponseType("blob");
        apiRequest.setPayload(params);
        return this.makeRequest(apiRequest);
    }

    makeRequest(apiRequest) {
        this.emitEvent(apiRequest, REQUEST_STATES.STARTING);

        return new Promise((resolve, reject) => {
            const asyncRequest = this.sendRequest.bind(this, apiRequest);
            const { checkRequestError } = apiRequest;

            tryAtMost(
                asyncRequest,
                apiRequest.retries,
                this.handleRequestError,
                checkRequestError
            )
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    sendRequest(apiRequest) {
        const {
            type: requestType,
            progressListener,
            responseType,
        } = apiRequest;

        const createEndpoint = () => {
            //const beginning = responseType === 'blob' ? '' : this.endpointUrl;
            //const beginning = this.endpointUrl;

            const ending = apiRequest.getEndpoint();
            const beginning = getBeginning(ending, this.endpointUrl);

            if (ending === "connect/token") {
                return `${this.authenticationUrl}connect/token`;
            }

            return `${beginning}${ending}`;
        };

        const endpoint = createEndpoint();
        const payload = apiRequest.getPayload();

        return new Promise((resolve, reject) => {
            const httpRequest = new HttpRequest(this.token, endpoint);

            if (requestType !== null) {
                httpRequest.requestType = requestType;
            }
            if (progressListener !== null) {
                httpRequest.progressListener = progressListener;
            }
            if (responseType !== null) {
                httpRequest.ResponseType = responseType;
            }

            const onError = (request) => {
                this.emitEvent(apiRequest, REQUEST_STATES.NO_RESPONSE, request);

                this.errorObj = {
                    error: request,
                    apiRequest,
                    endpoint,
                    payload,
                };
                // try {
                //     var err = new Error();
                //     this.errorObj.stack = err.stack;
                // } catch (e) {
                //     this.errorObj.stack = e.stack;
                // }

                reject(request);
            };

            const onSuccess = (request) => {
                this.emitEvent(apiRequest, REQUEST_STATES.RESPONDED, request);
                resolve(
                    responseType ? getResolverObject(request) : request.response
                );
            };

            httpRequest.OnError = onError;

            httpRequest.OnLoad = (request) => {
                if (request.status === 200) {
                    onSuccess(request);
                } else {
                    onError(request);
                }
            };

            httpRequest.NetworkErrorConfiguration =
                this.networkErrorConfiguration;
            httpRequest.Payload = payload;
            httpRequest.run();
        });
    }

    async handleRequestError(request) {
        const { status } = request;

        const genericErrorType =
            status === STATUS_CODES.DOUBLE_LOGIN
                ? GENERIC_ERRORS.DOUBLE_LOGIN
                : status === STATUS_CODES.UNAUTHORIZED
                ? GENERIC_ERRORS.UNAUTHORIZED
                : null;

        const handlers = this.genericErrorHandlers[genericErrorType] || [];

        for (let i = 0; i < handlers.length; i++) {
            await handlers[i]();
        }
    }

    // These errors are not intended to be handled by the api caller.
    // e.g. double-login, unauthorized etc.
    // Instead, an external handler deals with the error
    // on handler success: retry original api call (assuming retry count hasn't been exceeded)
    // on handler failure: abort original api call (which propogates back to original api caller)
    // @note: Handlers can be synchronous or async functions
    addGenericErrorHandler(genericError, handler) {
        if (!isGenericErrorType(genericError)) {
            throw "Not a generic_error type";
        }
        if (!check.function(handler)) {
            throw "Generic error handler must be a function";
        }
        if (!this.genericErrorHandlers.hasOwnProperty(genericError)) {
            this.genericErrorHandlers[genericError] = [];
        }

        this.genericErrorHandlers[genericError].push(handler);

        const removeHandler = () => {
            const index =
                this.genericErrorHandlers[genericError].indexOf(handler);
            this.genericErrorHandlers[genericError].splice(index, 1);
        };

        return removeHandler;
    }

    subscribe(listener) {
        this.listeners.push(listener);

        const unsubscribe = () => {
            const index = this.listeners.indexOf(listener);
            this.listeners.splice(index, 1);
        };

        return unsubscribe;
    }

    emitEvent(
        apiRequest,
        state,
        request = { getAllResponseHeaders: () => null }
    ) {
        const failedResponse = request.status !== 200;

        const event = {
            id: apiRequest.id,
            type: apiRequest.type,
            payload: apiRequest.payload,
            endpoint: apiRequest.endpoint,
            statusCode: request.status || null,
            response: failedResponse ? request.response || null : null,
            responseHeaders: failedResponse
                ? request.getAllResponseHeaders()
                : null,
        };

        this.listeners.forEach((listener) => {
            listener(state, event);
        });
    }

    get Token() {
        return this.token;
    }

    get RefreshToken() {
        return this.refreshToken;
    }

    set Token(value) {
        this.token = value;
    }

    set RefreshToken(value) {
        this.refreshToken = value;
    }

    set EndpointUrl(value) {
        this.endpointUrl = value;
    }

    set HubpointUrl(value) {
        this.hubpointUrl = value;
    }

    set AuthenticationUrl(value) {
        this.authenticationUrl = value;
    }

    isEndpointInitialized() {
        return this.endpointUrl !== "";
    }

    produceRandomNetworkErrors(frequency, errorCode) {
        this.networkErrorConfiguration = { frequency, errorCode };
        return () => {
            this.networkErrorConfiguration = undefined;
        };
    }

    subscribeSignalR(endpoint, params) {
      endpoint = this.hubpointUrl + endpoint + concatParams(params);
      const hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(endpoint)
          .configureLogging(signalR.LogLevel.Debug)
          .withAutomaticReconnect()
          .build();
      startHub(hubConnection);
      return hubConnection;
  }
}

// temporary bodge/hack (for downloading blobs from api)
const findFileName = (request) => {
    let contentDisposition = request.getResponseHeader("Content-Disposition");
    if (!contentDisposition) {
        return "";
    }
    contentDisposition = contentDisposition.replace(/"/g, "");
    return contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    )[1];
};

const getResolverObject = (request) => ({
    blob: request.response,
    fileName: findFileName(request),
});

async function startHub(connection) {
  try {
      await connection.start();
      console.log("SignalR Connected.");
  } catch (err) {
      console.log(err);
  }
};

const api = new Api();
export { api, REQUEST_STATES };
