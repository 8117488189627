
// npm
import React from 'react'

// react
import {SingleLineTextEditor as Editor} from './editor'

// utils
import {keyListener, KEYS} from 'utils/key-listener'


const disableArrowKeyListeners = () =>
{
	keyListener.disable(KEYS.LEFT_ARROW);
	keyListener.disable(KEYS.RIGHT_ARROW);
}

const enableArrowKeyListeners = () =>
{
	keyListener.enable(KEYS.LEFT_ARROW);
	keyListener.enable(KEYS.RIGHT_ARROW);
}

const SingleLineTextEditor = ({inputProps={}, ...props}) =>
{
	inputProps.onFocus = disableArrowKeyListeners;
	inputProps.onBlur = enableArrowKeyListeners;

	return <Editor inputProps={inputProps} {...props}/>;
}


export {SingleLineTextEditor}