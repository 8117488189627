
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import IconButton from '@material-ui/core/IconButton'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import withStyles from '@material-ui/core/styles/withStyles'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {isBookmarked} from 'redux/reducers/exam/content/questions/question/selectors'

// redux (actions)
import {toggleBookmark} from './actions'


// Bookmarker (not connected to store/styles)
// -------------------------------------------------------------

let Bookmarker = ({selected, onClick, classes:{text, textSelected}}) =>
{
	return (
		<IconButton onClick={onClick}>
			<BookmarkIcon className={selected ? textSelected : text}/>
		</IconButton>
	);
}

Bookmarker.propTypes = {
	selected: PropTypes.bool,
	onClick: PropTypes.func.isRequired
}


// Bookmarker (connected to store)
// -------------------------------------------------------------

const mapStoreToProps = (store, {questionId}) =>
{
	const contentData = getContentData(getExamData(store));
	const questionsData = getQuestionsData(contentData);
	const questionData = getQuestionDataById(questionsData)(questionId);
	return {selected: isBookmarked(questionData)};
}

const mapDispatchToProps = (dispatch, {questionId}) => ({
	onClick: () => dispatch(toggleBookmark(questionId))
});

Bookmarker = connect(mapStoreToProps, mapDispatchToProps)(Bookmarker);


// Bookmarker (connected to styles)
// ----------------------------------------

const styles = ({palette}) => ({
	text: {
		color: palette.background.contrastText + "!important"
	},
	textSelected: {
		color: palette.secondary.main + "!important"
	}
})

Bookmarker = withStyles(styles)(Bookmarker);

Bookmarker.propTypes = {
	questionId: PropTypes.number.isRequired
}


// Export
// -------------------------------------------------------------
export {Bookmarker}