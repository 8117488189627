
// npm
import React from 'react'

// xams-components
import {StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {BrowserChecker} from './browser_checker'
import {UnsupportedBrowserPage} from './unsupported_browser'

// machines
import {init} from 'machines/init'


const {EVENTS, VALIDATING_APP_SETTINGS_STATES:STATES} = init;


const ValidatingAppSettingsView = () => (
	<StateView>
		{{
			[STATES.CHECKING_BROWSER_SUPPORT]: () => (
				<StateControl onSupported={EVENTS.SUCCESS} onNotSupported={EVENTS.DETECTED_UNSUPPORTED_BROWSER}>
					{(props) => <BrowserChecker {...props}/>}
				</StateControl>
			),
			[STATES.UNSUPPORTED_BROWSER]: () => (
				<UnsupportedBrowserPage/>
			)
		}}
	</StateView>
)


export {ValidatingAppSettingsView}