
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (actions)
import {createClientSession, createClientSettings, setNetworkErrorInfo} from './actions'

// utils
import {generalApi} from 'libs/api/interface/api-general'
import { ENDPOINTS } from "libs/api/constants";



// SessionInitializer (not connected to store)
// ---------------------------------------------

const getClientUrl=()=>{
    const pathName = window.location.pathname;

    if (pathName.startsWith('/proctorio')) {
        const params = pathName.split('/');
       	const url = params.length>1?`${window.location.protocol}//${window.location.hostname}/clients/${params[2]}`:document.location.href;

      	return url;
    }

    return document.location.href;
}

class SessionInitializer extends React.Component
{
	constructor(props){
		super(props);

		this.handleError=this.handleError.bind(this);
	}

	handleError(error){
		const {onError, saveNetworkErrorInfo} = this.props;
		const url = getClientUrl();

		saveNetworkErrorInfo({error, endpoint: ENDPOINTS.INIT, url});
		onError(error);
	}

	componentDidMount()
	{
		const onSuccess = (response) => {
			const parsedResponse = JSON.parse(response);

			if (!parsedResponse.success) { this.props.onFail(); return; }
			
			this.props.createClientSession(parsedResponse);
			this.props.createClientSettings(parsedResponse);
			this.props.onSuccess();
		}

		const url = getClientUrl();
		generalApi.init(url).then(onSuccess, this.props.onError);
	}

	render()
	{
		return null;
	}
}

SessionInitializer.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
}


// SessionInitializer (connected to store)
// ---------------------------------------------

const mapDispatchToProps = (dispatch, {onSuccess, onFail}) => ({
	createClientSession: ({orgID, urlToken}) => {
		dispatch(createClientSession(orgID, urlToken));
	},
	createClientSettings: ({logo, appBarLogo, primaryColour, secondaryColour, enableForgottenPassword, legacySingleSignon, adminUrl}) => {
		dispatch(createClientSettings(logo, appBarLogo, primaryColour, secondaryColour, enableForgottenPassword, legacySingleSignon, adminUrl));
	},
	saveNetworkErrorInfo: (value) => {
		dispatch(setNetworkErrorInfo(value));
	}
})

SessionInitializer = connect(undefined, mapDispatchToProps)(SessionInitializer);


export {SessionInitializer}