
const styles = ({palette, spacing}) => ({
	question: {
		marginTop: spacing.unit * 2
	},
	marks: {
		textAlign: 'right'
	},
	richText: {
		marginTop: spacing.unit * 2,
		marginBottom: spacing.unit
	},
	text: {
		color: palette.background.contrastText
	},
	inputText: {
		color: `${palette.primary.main} !important`
	},
	inputUnderline: {
		'&:before': {
			borderBottom: `1px solid ${palette.background.contrastText} !important`
		},
		'&:after': {
			borderBottom: `1px solid ${palette.background.contrastText} !important`	
		}
	}
});

export {styles}