// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { check } from "@xams-utils/check-types";

// xams-utils
import { IdGenerator } from "@xams-utils/id-generator";

// material-ui
import Button from "@material-ui/core/Button";

// react
import { Popup } from "components/layout/popup";
import { ExamRequestSaverContext } from "./context";

// redux (selectors)
import { getGuid as getExamGuid } from "redux/reducers/exam/selectors";
import {
    getAppData,
    getSessionData,
    getExamData,
} from "redux/reducers/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import { getUserGuid } from "redux/reducers/session/user/selectors";
import { isOfflineMode } from "redux/reducers/app/selectors";

// redux (actions)
import { setOfflineMode } from "./actions";

// utils
import { examApiQueue } from "./exam-api-queue";
import { generalApi } from "libs/api/interface/api-general";
import { assessmentApi } from "libs/api/interface/api-assessment";
import { localStorageApi, KEYS } from "libs/browser_storage/local-storage-api";
import { networkRequestLog } from "libs/browser_storage/apis/network-request-log";
import {
    addActiviesToPayLoad,
    removeActiviesFromPayload,
    getErrorObject,
} from "./provider-activity-logger";

// utils (bodge)
import { api } from "libs/api/api";
import { ENDPOINTS, GENERIC_ERRORS } from "libs/api/constants";
import { ApiRequest } from "libs/api/api-request";
import {
    activityLogger,
    ACTIVITIES,
} from "libs/activity_logger/activity-logger";

//import { uploadOfflineFiles } from "libs/offline_uploader/offline_uploader";

const TYPES = {
    SAVE_ANSWER: 1,
    SAVE_TIME: 2,
    COMPLETE_EXAM: 3,
    SAVE_ACTIVITY_LOG: 4,
};

const APIS = {
    [TYPES.SAVE_ANSWER]: assessmentApi,
    [TYPES.SAVE_TIME]: assessmentApi,
    [TYPES.COMPLETE_EXAM]: assessmentApi,
    [TYPES.SAVE_ACTIVITY_LOG]: assessmentApi,
};

const FUNCTIONS = {
    [TYPES.SAVE_ANSWER]: "saveAnswer",
    [TYPES.SAVE_TIME]: "saveExamTime",
    [TYPES.COMPLETE_EXAM]: "finishExam",
    [TYPES.SAVE_ACTIVITY_LOG]: "saveActivityLog",
};

// Not connected to store
// --------------------------------------------------------------------------

class ExamRequestSaverProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showPopup: false };

        this.initializeBoundMethods();

        this.providerValue = {
            saveAnswer: this.saveAnswer,
            saveExamTime: this.saveExamTime,
            completeExam: this.completeExam,
            saveActivityLog: this.saveActivityLog,
        };

        this.callbacks = {}; // {id: {onSuccess, onFail}}
    }

    initializeBoundMethods() {
        // TEMP
        // ------------------------------------------------------
        const getEndpoint = (type, payload) => {
            switch (type) {
                case TYPES.SAVE_ANSWER:
                    return ENDPOINTS.ASSESSMENT.SAVE_ANSWER;
                case TYPES.SAVE_TIME:
                    return ENDPOINTS.ASSESSMENT.SAVE_TIME;
                case TYPES.COMPLETE_EXAM:
                    return ENDPOINTS.ASSESSMENT.COMPLETE;
                case TYPES.SAVE_ACTIVITY_LOG: {
                    const { formRunGuid } = payload;
                    return `${ENDPOINTS.ASSESSMENT.SAVE_ACTIVITY_LOG}/${formRunGuid}`;
                }
                default:
                    break;
            }

            // return type === TYPES.SAVE_ANSWER
            //     ? ENDPOINTS.ASSESSMENT.SAVE_ANSWER
            //     : type === TYPES.SAVE_TIME
            //     ? ENDPOINTS.ASSESSMENT.SAVE_TIME
            //     : ENDPOINTS.ASSESSMENT.COMPLETE;
        };

        const logRequest = (type, payload, id, state) => {
            const endpoint = getEndpoint(type, payload);
            const apiRequest = new ApiRequest("POST", endpoint, 5, id);
            const _payload =
                type === TYPES.SAVE_ACTIVITY_LOG
                    ? removeActiviesFromPayload({ ...payload })
                    : payload;
            apiRequest.setPayload(_payload);
            api.emitEvent(apiRequest, state);
        };

        const getLocalExamQueue = (localExamQueues) => {
            const { userGuid, examGuid } = this.props;

            if (!localExamQueues[userGuid]) {
                localExamQueues[userGuid] = {};
            }

            const usersQueues = localExamQueues[userGuid];
            if (!usersQueues[examGuid]) {
                usersQueues[examGuid] = [];
            }

            return usersQueues[examGuid];
        };

        const saveToLocalStorage = (key, data) => {
            while (true) {
                try {
                    localStorageApi.saveDataTo(key, data);
                    break;
                } catch (e) {
                    networkRequestLog.clearSpace();
                }
            }
        };

        const storeRequestLocally = (type, _payload, id) => {
            const localExamQueues = localStorageApi.retrieveDataFrom(
                KEYS.EXAM_QUEUES
            );
            const examQueue = getLocalExamQueue(localExamQueues);
            const payload =
                type === TYPES.SAVE_ACTIVITY_LOG
                    ? removeActiviesFromPayload({ ..._payload })
                    : _payload;
            examQueue.push({ type, payload, id });

            saveToLocalStorage(KEYS.EXAM_QUEUES, localExamQueues);
            //localStorageApi.saveDataTo(KEYS.EXAM_QUEUES, localExamQueues);
        };

        const removeRequestLocally = (id) => {
            const localExamQueues = localStorageApi.retrieveDataFrom(
                KEYS.EXAM_QUEUES
            );
            const examQueue = getLocalExamQueue(localExamQueues);
            const requestIndex = examQueue.findIndex((item) => item.id === id);
            examQueue.splice(requestIndex, 1);

            if (examQueue.length === 0) {
                const userQueues = localExamQueues[this.props.userGuid];
                delete userQueues[this.props.examGuid];
                if (Object.keys(userQueues).length === 0) {
                    delete localExamQueues[this.props.userGuid];
                }
            }
            saveToLocalStorage(KEYS.EXAM_QUEUES, localExamQueues);
            //localStorageApi.saveDataTo(KEYS.EXAM_QUEUES, localExamQueues);
        };

        const storeFailedRequestLocally = (
            type,
            _payload,
            id,
            fromTrySendRequest = false
        ) => {
            const payload =
                type === TYPES.SAVE_ACTIVITY_LOG
                    ? removeActiviesFromPayload({ ..._payload })
                    : _payload;
            logRequest(type, payload, id, "SAVED LOCALLY");

            const { queue = [] } = localStorageApi.retrieveDataFrom(
                KEYS.FAILED_EXAM_REQUESTS
            );
            queue.push({ type, payload, id });
            saveToLocalStorage(KEYS.FAILED_EXAM_REQUESTS, { queue });

            if (type === TYPES.SAVE_ACTIVITY_LOG && fromTrySendRequest) {
                const { onFail } = this.callbacks[id] || {};
                if (onFail) {
                    onFail({});
                }
            }
            //localStorageApi.saveDataTo(KEYS.FAILED_EXAM_REQUESTS, {queue});
        };

        const moveStoredRequestsToFailed = () => {
            const localExamQueues = localStorageApi.retrieveDataFrom(
                KEYS.EXAM_QUEUES
            );
            const examQueue = getLocalExamQueue(localExamQueues);
            examQueue.forEach(({ type, payload, id }) => {
                storeFailedRequestLocally(type, payload, id);
            });
            examQueue.splice(0, examQueue.length);

            saveToLocalStorage(KEYS.EXAM_QUEUES, localExamQueues);
            //localStorageApi.saveDataTo(KEYS.EXAM_QUEUES, localExamQueues);
        };
        // ------------------------------------------------------

        const sendRequest = (type, payload, id) => {
            logRequest(type, payload, id, "QUEUED"); // TEMP BODGE
            const api = APIS[type];
            const func = FUNCTIONS[type];

            storeRequestLocally(type, payload, id);
            examApiQueue.add(() => {
                if (this.props.offline || this.offline) {
                    return Promise.resolve();
                }

                const onSuccess = (response) => {
                    removeRequestLocally(id);
                    const { onSuccess } = this.callbacks[id] || {};
                    if (onSuccess) {
                        onSuccess(response);
                    }
                };

                const onAcivityLogSuccess = (response) => {
                    const { formRunGuid } = payload;

                    activityLogger.sent(formRunGuid).then(() => {
                        onSuccess(response);
                    });
                    //onSuccess(response);
                    // removeFormRunGuidFromActivityLog(payload).then(() => {
                    //     onSuccess(response);
                    // });
                };

                const onFail = (error) => {
                    if (error === GENERIC_ERRORS.DOUBLE_LOGIN_THROW) {
                        removeRequestLocally(id);
                    } else {
                        this.offline = true;
                        moveStoredRequestsToFailed();

                        const errorObj = getErrorObject(error);

                        const offlineReason = {
                            offline: true,
                            error: errorObj,
                        };

                        if (type !== TYPES.SAVE_ACTIVITY_LOG) {
                            offlineReason.func = func;
                            offlineReason.payload = payload;
                            offlineReason.id = id;
                        }

                        this.props.setOfflineMode(offlineReason);

                        activityLogger.log(ACTIVITIES.OFFLINE, {
                            error: errorObj,
                            _type: type,
                            func,
                            payload,
                        });
                    }

                    const { onFail } = this.callbacks[id] || {};
                    if (onFail) {
                        onFail(error);
                    }
                };

                if (type === TYPES.SAVE_ACTIVITY_LOG) {
                    addActiviesToPayLoad(payload).then((payload) => {
                        api[func](payload, id).then(
                            onAcivityLogSuccess,
                            onFail
                        );
                    });
                } else {
                    return api[func](payload, id).then(onSuccess, onFail);
                }
            });
        };

        this.trySendRequest = (type, payload, id) => {
            if (this.props.offline) {
                storeFailedRequestLocally(type, payload, id, true);
            } else {
                sendRequest(type, payload, id);
                // if (type === TYPES.COMPLETE_EXAM) {
                //     const { formRunGuid } = payload;

                //     uploadOfflineFiles(formRunGuid)
                //         .then(() => {
                //             sendRequest(type, payload, id);
                //         })
                //         .catch((e) => {
                //             debugger;
                //             sendRequest(type, payload, id);
                //         });
                // } else {
                //     sendRequest(type, payload, id);
                // }
            }
        };

        this.moveAllExamQueusToFailedRequests = () => {
            const examQueues = localStorageApi.retrieveDataFrom(
                KEYS.EXAM_QUEUES
            );
            const { queue = [] } = localStorageApi.retrieveDataFrom(
                KEYS.FAILED_EXAM_REQUESTS
            );
            const oldQueueLength = queue.length;

            Object.keys(examQueues).forEach((userGuid) => {
                Object.keys(examQueues[userGuid]).forEach((examGuid) => {
                    examQueues[userGuid][examGuid].forEach(
                        ({ type, payload }) => {
                            queue.push({
                                type,
                                payload,
                                id: IdGenerator.uuid(),
                            });
                        }
                    );
                });
            });

            if (oldQueueLength !== queue.length) {
                saveToLocalStorage(KEYS.FAILED_EXAM_REQUESTS, { queue });
                //localStorageApi.saveDataTo(KEYS.FAILED_EXAM_REQUESTS, {queue});
                localStorageApi.resetDataFor(KEYS.EXAM_QUEUES);
            }
        };

        this.flushFailedRequests = (createNewIds = false) => {
            const { queue } = localStorageApi.retrieveDataFrom(
                KEYS.FAILED_EXAM_REQUESTS
            );

            if (queue) {
                queue.forEach(({ type, payload, id }) => {
                    if (createNewIds) {
                        id = IdGenerator.uuid();
                    }

                    payload.IsFromLocalStorage = true;

                    this.trySendRequest(type, payload, id);
                });
            }

            localStorageApi.resetDataFor(KEYS.FAILED_EXAM_REQUESTS);
        };

        const generateCallbacksId = (callbacks = {}) => {
            const id = IdGenerator.uuid();
            this.callbacks[id] = callbacks;
            return id;
        };

        this.saveAnswer = (payload, callbacks) => {
            const id = generateCallbacksId(callbacks);
            this.trySendRequest(TYPES.SAVE_ANSWER, payload, id);
        };

        this.saveExamTime = (payload, callbacks) => {
            const id = generateCallbacksId(callbacks);
            this.trySendRequest(TYPES.SAVE_TIME, payload, id);
        };

        this.completeExam = (payload, callbacks) => {
            const id = generateCallbacksId(callbacks);

            this.trySendRequest(TYPES.COMPLETE_EXAM, payload, id);
        };

        this.saveActivityLog = (payload, callbacks) => {
            const id = generateCallbacksId(callbacks);
            this.trySendRequest(TYPES.SAVE_ACTIVITY_LOG, payload, id);
        };

        this.startPinging = () => {
            this.intervalId = setInterval(() => {
                generalApi.ping().then(() => {
                    this.props.setOfflineMode(false);
                    activityLogger.log(ACTIVITIES.ONLINE, {});
                    clearInterval(this.intervalId);
                });
            }, this.props.pingInterval);
        };
    }

    componentDidMount() {
        activityLogger.initialise().then(() => {
            this.moveAllExamQueusToFailedRequests();
            const { queue } = localStorageApi.retrieveDataFrom(
                KEYS.FAILED_EXAM_REQUESTS
            );

            if (queue) {
                this.flushFailedRequests(true);
                this.setState({ showPopup: true });
            }
        });
    }

    componentDidUpdate(previousProps) {
        if (previousProps.offline !== this.props.offline) {
            if (this.props.offline) {
                this.startPinging();
            } else {
                this.offline = false;
                this.flushFailedRequests();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <ExamRequestSaverContext.Provider value={this.providerValue}>
                    {this.props.children}
                </ExamRequestSaverContext.Provider>
                {this.Popup}
            </React.Fragment>
        );
    }

    get Popup() {
        if (this.state.showPopup) {
            const title = "Unsaved answers have been synced!";
            const text =
                "We noticed that a connection was lost while a previous " +
                "exam was running. Any answers made during that time have " +
                "now been synced to XAMS.";

            const onClick = () => this.setState({ showPopup: false });
            const okButton = (
                <Button size="small" onClick={onClick}>
                    {"OK"}
                </Button>
            );

            return (
                <Popup title={title} content={{ text }} buttons={[okButton]} />
            );
        }

        return null;
    }
}

ExamRequestSaverProvider.defaultProps = {
    pingInterval: 30000,
};

ExamRequestSaverProvider.propTypes = {
    offline: PropTypes.bool.isRequired,
    pingInterval: PropTypes.number.isRequired,
    setOfflineMode: PropTypes.func.isRequired,
    userGuid: PropTypes.string.isRequired,
    // examGuid: PropTypes.string.isRequired,
};

// Connected to store
// --------------------------------------------------------------------------

const mapStoreToProps = (store) => ({
    offline: !!isOfflineMode(getAppData(store)),
    userGuid: getUserGuid(getUserSessionData(getSessionData(store))),
    examGuid: getExamGuid(getExamData(store)),
});

const mapDispatchToProps = (dispatch) => ({
    setOfflineMode: (value) => dispatch(setOfflineMode(value)),
});

ExamRequestSaverProvider = connect(
    mapStoreToProps,
    mapDispatchToProps
)(ExamRequestSaverProvider);

// Export
// --------------------------------------------------------------------------
export { ExamRequestSaverProvider };
