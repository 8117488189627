
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

// redux (actions)
import {getRemoveCurrentSessionActions} from 'components/actions'

// redux (selectors)
import {getUserGuid} from 'redux/reducers/session/user/selectors'
import {getUrlToken} from 'redux/reducers/session/client/selectors'
import {getAppSettingsData} from 'redux/reducers/settings/selectors'
import {getSessionData, getSettingsData} from 'redux/reducers/selectors'
import {isInactivityTimerActive} from 'redux/reducers/settings/app/selectors'
import {getClientSessionData, getUserSessionData} from 'redux/reducers/session/selectors'

// utils
import {inactivityManager} from 'utils/inactivity-manager'
import {authenticationApi} from 'libs/api/interface/api-authentication'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// InactivityDetector (not connected to store/router)
// -----------------------------------------------------------------------

class InactivityDetector extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundMethods();	
	}

	initializeBoundMethods()
	{
		this.handleInactivity = () => {
			const {messages} = this.props;
			window.alert(messages[MESSAGE_IDS.SESSION.INACTIVITY]);
			this.props.logOut();
		}
	}

	componentDidMount()
	{
		if (this.props.active) {
			inactivityManager.subscribe(this.handleInactivity);
		}
	}

	componentDidUpdate(previousProps)
	{
		if (previousProps.active !== this.props.active) {
			if (this.props.active) {
				inactivityManager.subscribe(this.handleInactivity);
			}
			else {
				inactivityManager.unsubscribe();
			}
		}
	}

	render()
	{
		return this.props.children || null;
	}

	componentWillUnmount()
	{
		if (this.props.active) {
			inactivityManager.unsubscribe();
		}
	}
}

InactivityDetector.propTypes = {
	children: PropTypes.node,
	active: PropTypes.bool.isRequired,
	logOut: PropTypes.func.isRequired,
	messages: PropTypes.shape({
		[MESSAGE_IDS.SESSION.INACTIVITY]: PropTypes.string.isRequired
	}).isRequired
}


// InactivityDetector (connected to store)
// -----------------------------------------------------------------------

const mapStoreToProps = (store) => ({store});
const mapDispatchToProps = (dispatch) => ({dispatch});


const isActive = (store) =>
{
	const settingsData = getSettingsData(store);
	const appSettingsData = getAppSettingsData(settingsData);
	return isInactivityTimerActive(appSettingsData);
}

// const isExamPreview = (store) => 																							// decided to keep inactivity timer active during exam previews (9/12/19)
// {
// 	const examData = getExamData(store);
// 	const examGuid = getGuid(examData);
// 	if (!examGuid) { return false; }

// 	const schedulesData = getSchedulesData(store);
// 	const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
// 	if (!scheduleData) { return false; }

// 	return !!getVersion(scheduleData);
// }

const createLogoutFunction = (store, dispatch, history) => () =>
{
	const sessionData = getSessionData(store);
	const urlToken = getUrlToken(getClientSessionData(sessionData));
	const userGuid = getUserGuid(getUserSessionData(sessionData));

	authenticationApi.abandonUserSession(userGuid, "Inactivity");
	history.push(`/login?${urlToken}`);
	getRemoveCurrentSessionActions().forEach(action => dispatch(action));
}

const mergeProps = ({store}, {dispatch}, {history, children, messages}) =>
{
	const active = isActive(store);
	const logOut = createLogoutFunction(store, dispatch, history);									// this component is rendered after login (so a user session will exist)

	return {active, logOut, messages, children};
}

InactivityDetector = connect(mapStoreToProps, mapDispatchToProps, mergeProps)(InactivityDetector);


// InactivityDetector (connected to router)
// -----------------------------------------------------------------------
InactivityDetector = withRouter(InactivityDetector)
InactivityDetector = withMessages(InactivityDetector);


// Export
// -----------------------------------------------------------------------
export {InactivityDetector}