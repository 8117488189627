
// npm
import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'immutable-prop-types'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {Align} from 'components/layout/align'
import {MaterialText} from 'components/presentation/material-text'

// redux (selectors)
import {getSectionName, getMarks} from 'redux/reducers/exam/content/sections/section/selectors'

// utils
import {pluralize} from 'custom/string-helper'


// SectionHeading (not connected)
// --------------------------------------------------------------

const generateMarksText = (marks) => (
	` (${marks} mark${marks === 1 ? '' : 's'})`
)

let SectionHeading = ({sectionData, classes}) =>
{
	const name = getSectionName(sectionData);
	const marks = getMarks(sectionData);
	const marksText = isNaN(marks)?'':` (${pluralize('mark', marks)})`;

	return (
		<div style={{width: '100%'}}>
			<Align>
				<MaterialText className={classes.text}>
					{`${name}${marksText}`}
				</MaterialText>
			</Align>
		</div>
	)
}

SectionHeading.propTypes = {
	sectionData: ImmutablePropTypes.map,
	classes: PropTypes.object.isRequired
}


// SectionHeading (not connected)
// --------------------------------------------------------------

const styles = ({palette}) => ({
	text: {color: palette.background.contrastText}
});

SectionHeading = withStyles(styles)(SectionHeading);


// SectionHeading (EXPORT)
// --------------------------------------------------------------
export {SectionHeading}