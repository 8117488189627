
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MarksChip} from '../marks-chip'
import {MaterialText} from 'components/presentation/material-text'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {getText, getMarks} from 'redux/reducers/exam/content/questions/question/selectors'


// QuestionText (not connected to store)
// ---------------------------------------------------------------

let QuestionText = ({text=" ", marks, classes, questionId}) =>
{
	return (
		<React.Fragment>
			<MaterialText key={`qtext${questionId}`} className={classes.text}>
				{text}
			</MaterialText>
			<MarksChip style={{marginTop: 8}} marks={marks}/>
		</React.Fragment>
	);
}

QuestionText.propTypes = {
	text: PropTypes.string,
	marks: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired
}


// QuestionText (connected to store)
// ---------------------------------------------------------------

const mapStoreToProps = (store, {questionId}) => {
	const contentData = getContentData(getExamData(store));
	const questionsData = getQuestionsData(contentData);
	const questionData = getQuestionDataById(questionsData)(questionId);
	return {text: getText(questionData), marks: getMarks(questionData)};
};

QuestionText = connect(mapStoreToProps)(QuestionText);


// QuestionText (connected to styles)
// ---------------------------------------------------------------

const styles = ({palette}) => ({
	text: {
		color: palette.background.contrastText
	}
})

QuestionText = withStyles(styles)(QuestionText);

QuestionText.propTypes = {
	questionId: PropTypes.number.isRequired
}


// Export
// ---------------------------------------------------------------
export {QuestionText}