
// npm
import React from 'react'
import PropTypes from 'prop-types'
import { check } from '@xams-utils/check-types'

// react
import { AnswerBox } from './answer-box'
import { FormAnswerForm } from '../answer_forms/form/form-answer-form'
import { FORM_SEPERATORS } from "constants/form";

// redux (selectors)
import { getResumedAnswer, getFormData } from 'redux/reducers/exam/content/questions/question/form/selectors'
import { getInitialValues } from '../answer_forms/form/form-helper'


const getInitialAnswer = (props) => {
	const formData = props.get('formData');

	if (formData){
		const initialValues = getInitialValues(formData);

		if (check.nonEmptyArray(initialValues)) {
			const initialValueString = initialValues.map(value => [value.name, value.value].join(FORM_SEPERATORS[2])).join(FORM_SEPERATORS[1])
			const initialValue = ["", initialValueString, ""].join(FORM_SEPERATORS[0]);

			return initialValue;
		}
	}

	return undefined;

}




class FormAnswerBox extends React.Component {
	constructor(props) {
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	// extract information from questionData
	// --------------------------------------
	setUpExtractionFunctions() {
		this.extractInitialAnswer = () => {
			const initialAnswer = getInitialAnswer(this.props.data);

			return initialAnswer;
		};
		this.extractResumedAnswer = () => {
			return getResumedAnswer(this.props.data);
		};
		this.extractFormData = () => {
			const { data } = this.props;
			const formData = getFormData(data);
			return formData;
		};
	}

	// convert between answer values
	// --------------------------------------
	setUpTransformationFunctions() {
		this.getFormValue = (formAnswer) => formAnswer;
		this.toFormValue = (localAnswer) => localAnswer;
		this.toLocalAnswer = (formValue) => formValue;
		this.toServerAnswer = (localAnswer) => {
			return { answerText: localAnswer, options: [] };
		};

	}

	render() {
		const answerBoxProps = {
			questionData: this.props.data,
			FormComponent: FormAnswerForm,
			toFormValue: this.toFormValue,
			getFormValue: this.getFormValue,
			toLocalAnswer: this.toLocalAnswer,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer
		}

		return <AnswerBox {...answerBoxProps} />;
	}
}

FormAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export { FormAnswerBox }