
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-utils
import {check} from '@xams-utils/check-types'

// components
import {MachineContext} from '../machine-context'


class StateControl extends React.Component
{
	render()
	{
		const {children:getControl, ...events} = this.props;
		return getControl(this.createTransitionCallbacks(events));
	}

	createTransitionCallbacks(events)
	{
		const callbacks = {};

		Object.keys(events).forEach(key => {
			const event = events[key];
			this.validateEvent(event);
			callbacks[key] = this.createTransitionCallback(event);
		});

		return callbacks;
	}

	createTransitionCallback(event)
	{
		return () => {
			this.context.transition(event);
		}
	}

	validateEvent(event)
	{
		if (!check.string(event)) {
			throw "<StateControl> Every prop must be an event string";
		}
	}
}

StateControl.propTypes = {
	children: PropTypes.func.isRequired
}

StateControl.contextType = MachineContext;



export {StateControl}