
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import ImmutablePropTypes from 'immutable-prop-types'

// react
import {MaterialText} from 'components/presentation/material-text'

// redux
import {getType} from 'redux/reducers/exam/content/questions/question/selectors'


// UnsupportedAnswerBox (not connected to store)
// ----------------------------------------------------

let UnsupportedAnswerBox = ({questionType}) => (
	<MaterialText variant="h6">
		{`Unsupported questionType type: ${questionType}`}
	</MaterialText>
)

UnsupportedAnswerBox.propTypes = {
	questionType: PropTypes.string.isRequired
}


// UnsupportedAnswerBox (connected to store)
// ----------------------------------------------------

const mapStoreToProps = (store, {data}) => ({
	questionType: getType(data),
	data: undefined
});

UnsupportedAnswerBox = connect(mapStoreToProps)(UnsupportedAnswerBox);

UnsupportedAnswerBox.propTypes = {
	data: ImmutablePropTypes.map.isRequired
}


// Export
// ----------------------------------------------------
export {UnsupportedAnswerBox}