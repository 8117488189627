// npm
import React from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// material-ui
import { styles } from "./form-styles";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// react
import { withMessages } from "components/hocs/messages";
import { MaterialText } from "components/presentation/material-text";

// redux (selectors)
import { getSessionData, getSettingsData } from "redux/reducers/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import {
    getDateOfBirth,
    getLearnerIdentifier,
} from "redux/reducers/session/user/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import {
    getConfirmDobMode,
    getConfirmLearnerIdentifierMode,
} from "redux/reducers/settings/client/selectors";

import { ConfirmDob } from "./confirm-dob";
import { ConfirmLearnerIdentifier } from "./confirm_learner_identifier/confirm-learner-identifier";

// constants
import { MESSAGE_IDS } from "constants/message-ids";

const nextMessageId = MESSAGE_IDS.GENERAL.NEXT;
const rubricMessageId = MESSAGE_IDS.RUBRIC.RUBRIC;
const rubricIntroMessageId = MESSAGE_IDS.RUBRIC.INTRO;
const confirmNameMessageId = MESSAGE_IDS.RUBRIC.CONFIRM_NAME;
const confirmInstructionsMessageId = MESSAGE_IDS.RUBRIC.CONFIRM_INSTRUCTIONS;

class RubricForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { checkedCount: 0 };
        //this.checkboxCount = this.NeedsToConfirmDob ? 3 : 2;
        this.checkboxCount = 2;
        if (this.NeedsToConfirmDob) {
            this.checkboxCount++;
        }
        if (this.NeedsToConfirmLearnerIdentifier) {
            this.checkboxCount++;
        }

        this.initializeBoundMethods();
    }

    initializeBoundMethods() {
        this.onCheckboxChange = (_, value) => {
            const { checkedCount } = this.state;
            this.setState({
                checkedCount: value ? checkedCount + 1 : checkedCount - 1,
            });
        };
    }

    componentDidUpdate(_, prevState) {
        const { checkedCount } = this.state;
        const oldCheckedCount = prevState.checkedCount;

        if (checkedCount === oldCheckedCount) {
            return;
        }

        if (checkedCount < 0 || checkedCount > this.checkboxCount) {
            throw `Check count error, ${checkedCount}, ${this.checkboxCount}`;
        }

        if (oldCheckedCount === this.checkboxCount) {
            this.props.onUnfilled();
        } else if (checkedCount === this.checkboxCount) {
            this.props.onFilled();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.layout}>
                <Fade in timeout={1000}>
                    <Paper className={classes.form}>
                        {this.Rubric}
                        <Divider />
                        {this.Agreement}
                        {this.Button}
                    </Paper>
                </Fade>
            </div>
        );
    }

    get Rubric() {
        const { classes, messages } = this.props;

        return (
            <React.Fragment>
                <div className={classes.rubricTextContainer}>
                    <MaterialText className={classes.rubricText} variant="h6">
                        {messages[rubricIntroMessageId]}
                    </MaterialText>
                </div>
                <div className={classes.rubric}>
                    <Paper className={classes.rubricPaper}>
                        <MaterialText className={classes.rubricText}>
                            {messages[rubricMessageId]}
                        </MaterialText>
                    </Paper>
                </div>
            </React.Fragment>
        );
    }

    get Agreement() {
        return (
            <div className={this.props.classes.checks}>
                <FormGroup>
                    {this.ConfirmMessage1}
                    {this.ConfirmMessage2}
                    {this.DateOfBirthConfirmMessage}
                    {this.LearnerIdentifierConfirmMessage}
                </FormGroup>
            </div>
        );
    }

    get ConfirmMessage1() {
        const label = (
            <MaterialText
                className={this.props.classes.rubricText}
                variant="subtitle2"
            >
                {this.props.messages[confirmNameMessageId]}
            </MaterialText>
        );

        return <FormControlLabel label={label} control={this.Checkbox} />;
    }

    get ConfirmMessage2() {
        const label = (
            <MaterialText
                className={this.props.classes.rubricText}
                variant="subtitle2"
            >
                {this.props.messages[confirmInstructionsMessageId]}
            </MaterialText>
        );

        return <FormControlLabel label={label} control={this.Checkbox} />;
    }

    get NeedsToConfirmDob() {
        const { dateOfBirth, confirmDobMode } = this.props;

        return (
            dateOfBirth &&
            confirmDobMode &&
            [1, 2].indexOf(confirmDobMode) !== -1
        );
    }

    get DateOfBirthConfirmMessage() {
        if (!this.NeedsToConfirmDob) return null;

        const { dateOfBirth, confirmDobMode } = this.props;

        if (confirmDobMode === 2) {
            const dobProps = {
                onSelect: this.onCheckboxChange,
                dob: dateOfBirth,
                label: "Please select your Date of Birth",
            };

            return <ConfirmDob {...dobProps} />;
        } else if (confirmDobMode === 1) {
            const label = (
                <MaterialText
                    className={this.props.classes.rubricText}
                    variant="subtitle2"
                >
                    {"I confirm that my date of birth is: "}
                    <Moment format="LL" date={dateOfBirth} />
                </MaterialText>
            );

            return <FormControlLabel label={label} control={this.Checkbox} />;
        }

        return null;
    }

    get NeedsToConfirmLearnerIdentifier() {
        const { learnerIdentifier, confirmLearnerIdentifierMode } = this.props;

        return (
            learnerIdentifier &&
            confirmLearnerIdentifierMode &&
            [1, 2].indexOf(confirmLearnerIdentifierMode) !== -1
        );
    }

    get LearnerIdentifierConfirmMessage() {
        if (!this.NeedsToConfirmLearnerIdentifier) return null;

        const { learnerIdentifier, confirmLearnerIdentifierMode, classes } =
            this.props;

        const props = {
            identifier: learnerIdentifier,
            confirmMode: confirmLearnerIdentifierMode,
            onChange: this.onCheckboxChange,
            textClass: classes.rubricText,
            confirmMessage: "I can confirm that my @ is: @",
            enterMessage: "Please enter your @",
        };

        return <ConfirmLearnerIdentifier {...props} />;
    }

    get Checkbox() {
        return <Checkbox color="primary" onChange={this.onCheckboxChange} />;
    }

    get Button() {
        const disabled = !this.props.onSubmit;
        const onClick = () => this.props.onSubmit();
        const { classes } = this.props;

        return (
            <div className={classes.buttons}>
                <Button
                    className={classes.button}
                    classes={{ disabled: classes.buttonDisabled }}
                    variant="contained"
                    disabled={disabled}
                    onClick={onClick}
                >
                    {this.props.messages[nextMessageId]}
                    <ArrowForwardIcon className={classes.rightIcon} />
                    &nbsp;
                </Button>
            </div>
        );
    }
}

RubricForm.propTypes = {
    onFilled: PropTypes.func,
    onUnfilled: PropTypes.func,
    onSubmit: PropTypes.func,
    messages: PropTypes.shape({
        [nextMessageId]: PropTypes.string.isRequired,
        [rubricMessageId]: PropTypes.string.isRequired,
        [rubricIntroMessageId]: PropTypes.string.isRequired,
        [confirmNameMessageId]: PropTypes.string.isRequired,
        [confirmInstructionsMessageId]: PropTypes.string.isRequired,
    }),
};

RubricForm = withStyles(styles)(RubricForm);
RubricForm = withMessages(RubricForm);

const mapStoreToProps = (store) => {
    const sessionData = getSessionData(store);
    const userSessionData = getUserSessionData(sessionData);
    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    return {
        dateOfBirth: getDateOfBirth(userSessionData),
        confirmDobMode: getConfirmDobMode(clientSettingsData),
        learnerIdentifier: getLearnerIdentifier(userSessionData),
        confirmLearnerIdentifierMode:
            getConfirmLearnerIdentifierMode(clientSettingsData),
    };
};

RubricForm = connect(mapStoreToProps)(RubricForm);

export { RubricForm };
