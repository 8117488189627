
// utils
import {combineImmutableReducers} from 'utils/combine-immutable-reducers'

// redux (reducers)
import {APP_SETTINGS_REDUCER} from './app/reducer'
import {CLIENT_SETTINGS_REDUCER} from './client/reducer'


const SETTINGS_REDUCER = combineImmutableReducers(undefined, {
	app: APP_SETTINGS_REDUCER,
	client: CLIENT_SETTINGS_REDUCER
});


export {SETTINGS_REDUCER}