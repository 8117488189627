
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Switch, Route, withRouter} from 'react-router-dom'

// react
import {ExamQuestionPage} from './page'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getSectionsData} from 'redux/reducers/exam/content/selectors'
import {getPaperPartId} from 'redux/reducers/exam/content/complex-selectors'
import {getSectionDataById} from 'redux/reducers/exam/content/sections/selectors'
import {isComplete} from 'redux/reducers/exam/content/sections/section/selectors'


// redux (actions)
import {setCurrentQuestionId as setCurrentQuestionIdAction} from './actions'

// constants
import {routingBlocker, BLOCKING_TYPES} from 'utils/routing-blocker'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


// ExamQuestionsPageRouter (not connected to store/router)
// ------------------------------------------------------------------------

class ExamQuestionsPageRouter extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.createQuestionPage = ({match}) => {
			const {guid, questionId} = match.params;
			const {history, onShowInstructions, setCurrentQuestionId} = this.props;

			const pageProps = {
				onQuestionChange: (id) => {
					setCurrentQuestionId(id);
					history.push(`/exam/${guid}/${id}`)
				},
				onFinish: () => history.push(`/exam/${guid}/outro`),
				onShowInstructions,
				questionId
			}

			return <ExamQuestionPage {...pageProps}/>;
		}

		const getQuestionIdFromLocation = ({pathname}) => {
			const lastForwardSlash = pathname.lastIndexOf('/');
			const questionId = pathname.substring(lastForwardSlash + 1);
			return parseInt(questionId, 10);
		}

		this.blockInvalidRoutingMessage = (questionId) => {
			if (this.props.isValidQuestion(questionId)) { return; }
			return {
				type: BLOCKING_TYPES.BLOCK,
				message: this.props.messages[MESSAGE_IDS.EXAM.PAPER_PART_NAV_INTERRUPTED]
			}
		}

		this.unsubscribe = routingBlocker.subscribe((location, action) => {
			if (action === "PUSH" || action === "REPLACE") { return; }									// PUSH/REPLACE happens in 'onQuestionChange'. That function will always navigate to a valid question & already handles updating the store
			if (!location.pathname.includes('exam')) { return; }

			const questionId = getQuestionIdFromLocation(location);
			const blockMessage = this.blockInvalidRoutingMessage(questionId);
			if (!blockMessage) { this.props.setCurrentQuestionId(questionId); }
			return blockMessage;
		})
	}

	render()
	{
		return (
			<Switch>
				<Route exact path="/exam/:guid/:questionId" render={this.createQuestionPage}/>
				<Route path="" component={() => "Sorry, you just experienced a routing error. Please note the current url in your browser."}/>
			</Switch>
		)
	}

	componentWillUnmount()
	{
		this.unsubscribe();
	}
}

ExamQuestionsPageRouter.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	onShowInstructions: PropTypes.func.isRequired,
	setCurrentQuestionId: PropTypes.func.isRequired,
	isValidQuestion: PropTypes.func.isRequired,
	messages: PropTypes.object.isRequired
}


// ExamQuestionsPageRouter (connected to store/router)
// ------------------------------------------------------------------------

const mapStoreToProps = (store) => ({
	isValidQuestion: (id) => {
		const contentData = getContentData(getExamData(store));
		const paperPartId = getPaperPartId(contentData)(id);
		if (!paperPartId) { return true; }

		const sectionsData = getSectionsData(contentData);
		const partData = getSectionDataById(sectionsData)(paperPartId);
		return !isComplete(partData);
	}
})

const mapDispatchToProps = (dispatch) => ({
	setCurrentQuestionId: (id) => dispatch(setCurrentQuestionIdAction(id))
})

ExamQuestionsPageRouter = connect(mapStoreToProps, mapDispatchToProps)(ExamQuestionsPageRouter);
ExamQuestionsPageRouter = withRouter(ExamQuestionsPageRouter);
ExamQuestionsPageRouter = withMessages(ExamQuestionsPageRouter);


// ExamQuestionsPageRouter (EXPORT)
// ------------------------------------------------------------------------
export {ExamQuestionsPageRouter}