
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Button from '@material-ui/core/Button'


const CancelButton = ({onClick}) =>
{
	const props = {
		size: 'small',
		color: 'secondary',
		variant: 'outlined',
		disableRipple: true,
		disableFocusRipple: true,
		onClick
	}

	return (
		<Button {...props}>
			{"Cancel"}
		</Button>
	);
}


export {CancelButton}