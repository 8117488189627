
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// utils
import {assessmentApi} from 'libs/api/interface/api-assessment'

// react
import {AppPageModifier} from 'components/pages/app-page-modifier'

// redux (selectors)
import {getUserGuid, getPublicExamUserName} from 'redux/reducers/session/user/selectors'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getScheduleGuid, getGuid} from 'redux/reducers/exam/selectors'
import {getScheduleDataByExamGuid} from 'redux/reducers/schedules/selectors'
import {getSessionData, getSchedulesData, getExamData, getSettingsData} from 'redux/reducers/selectors'
import {getScheduleType, getExamGuid, getExamTypeGuid, getVersion} from 'redux/reducers/schedules/schedule/selectors'

import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getAppVersion } from "redux/reducers/settings/app/selectors";

// redux (actions)
import * as actions from './actions'


// ExamStarter (not connected to store)
// ----------------------------------------

class ExamStarter extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.onSuccess = (response) => { 
			let {formRunGUID:formRunGuid} = JSON.parse(response);
			this.props.setFormRunGuid(formRunGuid);
			this.props.commenceSchedule(this.props.scheduleGuid);
			this.props.onStarted();
		}
	}

	componentDidMount()
	{
		const args = [
			this.props.userGuid,
			this.props.scheduleGuid,
			this.props.scheduleType,
			this.props.examGuid,
			this.props.examTypeGuid,
			this.props.version,
			this.props.publicExamUserName,
			this.props.appVersion
		]

		assessmentApi.startExam(...args)
		.then(this.onSuccess, this.props.onFail);
	}

	render()
	{
		return <AppPageModifier id="exam-starter" loading/>;
	}
}

ExamStarter.propTypes = {
	userGuid: PropTypes.string.isRequired,
	scheduleGuid: PropTypes.string.isRequired,
	scheduleType: PropTypes.string.isRequired,
	examGuid: PropTypes.string.isRequired,
	examTypeGuid: PropTypes.string.isRequired,
	version: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	  ]),
	onStarted: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired
}


// ExamStarter (connected to store)
// ----------------------------------------

const mapStoreToProps = (store) =>
{
	const examData = getExamData(store);
	const examGuid = getGuid(examData);
	const scheduleGuid = getScheduleGuid(examData);

	const schedulesData = getSchedulesData(store);
	const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);

	const sessionData = getSessionData(store);
	const userSessionData = getUserSessionData(sessionData);	
	const userGuid = getUserGuid(userSessionData);
	const publicExamUserName = getPublicExamUserName(userSessionData);

	const scheduleType = getScheduleType(scheduleData);
	const examTypeGuid = getExamTypeGuid(scheduleData);
	const version = getVersion(scheduleData);

	const appSettingsData = getAppSettingsData(getSettingsData(store));
	const appVersion = getAppVersion(appSettingsData);

	return {userGuid, scheduleGuid, scheduleType, examGuid, examTypeGuid, version, publicExamUserName, appVersion};
}

const mapDispatchToProps = (dispatch) => ({
	setFormRunGuid: (formRunGuid) => dispatch(actions.setFormRunGuid(formRunGuid)),
	commenceSchedule: (scheduleGuid) => dispatch(actions.commenceSchedule(scheduleGuid))
});

ExamStarter = connect(mapStoreToProps, mapDispatchToProps)(ExamStarter);


// EXPORT
// ----------------------------------------
export {ExamStarter}