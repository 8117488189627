// npm
import React from "react";
import { check } from "@xams-utils/check-types";

import { detectBrowser } from "components/pages/app_initialization/initializing/validating_app_settings/browser_checker/detect-browser";
import { errorApi } from "libs/api/interface/api-error";

// material-ui
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { BoundsListener } from "components/functional/bounds-listener";

// utils
import { keyListener, KEYS } from "utils/key-listener";
import {
    addCalculatorSymbols,
    hasCalculatorSymbols,
} from "./calculator-helper";

const disableArrowKeyListeners = () => {
    keyListener.disable(KEYS.LEFT_ARROW);
    keyListener.disable(KEYS.RIGHT_ARROW);
};

const enableArrowKeyListeners = () => {
    keyListener.enable(KEYS.LEFT_ARROW);
    keyListener.enable(KEYS.RIGHT_ARROW);
};

const getScreenHeight = () => {
    const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    return height;
};

const logError = (errorText) => {
    const { browser, version } = detectBrowser();

    errorApi.logError({
        orgID: null,
        userID: null,
        languageId: null,
        errorMessage: errorText,
        stackTrace: null,
        info: null,
        siteId: null,
        centreId: null,
        app: "xams.UI.Web.Player",
        browser: {
            browser,
            version,
        },
    });
};

const calculateDefaultRowCount = () => {
    const screenHeight = getScreenHeight();

    // return calculateRowCount(screenHeight - 380);
    return calculateRowCount(screenHeight / 2);
};

const calculateRowCount = (height) => {
    const textSize = 19;
    // const textSize = 16; // Error
    const rowCount = Math.floor((height - 37) / textSize) - 1;

    return rowCount < 1 ? 1 : rowCount;
};

// Workings (not connected to styles)
// --------------------------------------------------------------------------

class Workings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rowCount: 1,
            useListener: true,
        };

        this.updateCounter = 0;
        this.lastTime = 0;
        this.timeCounter = 0;

        this.checkTimeOk = () => {
            const currentTime = Date.now();
            const timeDiff = currentTime - this.lastTime;

            // console.log('checkTimeOk', this.timeCounter, currentTime, this.lastTime, timeDiff);

            if (timeDiff > 1000) {
                this.timeCounter = 0;
            } else {
                this.timeCounter++;
                if (this.timeCounter > 25) {
                    console.log("set half screen", this.timeCounter);

                    this.setState({ rowCount: calculateDefaultRowCount() });

                    return false;
                }
            }

            this.lastTime = currentTime;
            return true;
        };

        this.updateRowCount = (_, height) => {
            if (check.number(height)) {
                const { rowCount } = this.state;
                const newRowCount = calculateRowCount(height);

                if (rowCount !== newRowCount && rowCount > 0) {
                    if (this.checkTimeOk()) {
                        try {
                            this.setState({ rowCount: newRowCount });
                        } catch (e) {
                            const newRowCount = calculateDefaultRowCount();

                            console.log("turn off listener", newRowCount);

                            this.setState({
                                rowCount: newRowCount,
                                useListener: false,
                            });
                        }
                    }
                }
            }
        };
    }

    render() {
        const { classes, onChange } = this.props;
        const { useListener } = this.state;

        const textFieldProps = {
            multiline: true,
            value: this.props.value,
            label: "Workings",
            onChange: ({ target: { value } }) => this.props.onChange(value),
            // onChange: ({ target: { value } }) => {
            //     const { value:_value, cursor } = hasCalculatorSymbols(
            //         value,
            //         this.props.workingsRef
            //     );
            //     if (cursor !== null) {
            //         onChange(addCalculatorSymbols(_value), cursor);
            //     } else {
            //         onChange(addCalculatorSymbols(value));
            //     }
            // },
            rows: this.state.rowCount,
            margin: "none",
            variant: "outlined",
            InputProps: {
                className: classes.inputText,
                classes: { notchedOutline: classes.inputBorder },
            },
            InputLabelProps: {
                className: classes.inputText,
                shrink: true,
            },
            inputProps: {
                ref: this.props.workingsRef,
                onPaste: (e) => {
                    e.preventDefault();
                },
                onDrop: (e) => {
                    e.preventDefault();
                },
                onFocus: disableArrowKeyListeners,
                onBlur: enableArrowKeyListeners,
                spellCheck: false
            },
        };

        if (useListener) {
            return (
                <BoundsListener onSizeChange={this.updateRowCount}>
                    <div className={classes.container}>
                        <TextField {...textFieldProps} />
                    </div>
                </BoundsListener>
            );
        } else {
            return (
                <div className={classes.container}>
                    <TextField {...textFieldProps} />
                </div>
            );
        }
    }
}

// Workings (connected to styles)
// --------------------------------------------------------------------------

const styles = ({ palette, spacing }) => ({
    container: {
        boxSizing: "border-box",
        margin: spacing.unit,
        marginBottom: 0,
        flexShrink: 1,
        flexGrow: 1,
    },
    inputText: {
        color: `${palette.background.contrastText} !important`,
        // fontFamily: 'Courier New, Courier, monospace'
    },
    inputBorder: {
        borderColor: `${palette.background.contrastText} !important`,
    },
});

Workings = withStyles(styles)(Workings);

// Export
// --------------------------------------------------------------------------
export { Workings };
