// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// material-ui
import { styles } from "./styles";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForward from "@material-ui/icons/ArrowForward";
import withStyles from "@material-ui/core/styles/withStyles";

// redux (selectors)
import { getSchedulesData, getProctorioData } from "redux/reducers/selectors";
import { getUserSessionData } from "redux/reducers/session/selectors";
import { getUserGuid } from "redux/reducers/session/user/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getSessionData, getSettingsData } from "redux/reducers/selectors";
import * as SELECTORS from "redux/reducers/schedules/schedule/selectors";
import { getScheduleDataByIndex } from "redux/reducers/schedules/selectors";
import { isResetScheduleCheatActive } from "redux/reducers/settings/app/selectors";
import { getParent } from "redux/reducers/proctorio/selectors";

// react
import { withMessages } from "components/hocs/messages";

// other
import { MESSAGE_IDS } from "constants/message-ids";
import { assessmentApi } from "libs/api/interface/api-assessment";
import { PROCTORING_TYPES } from "constants/proctoring";

const resumeMessageId = MESSAGE_IDS.GENERAL.RESUME;
const startMessageId = MESSAGE_IDS.GENERAL.START;

// ScheduleItem (not connected to store)
// ------------------------------------------

class ScheduleItem extends React.Component {
    constructor(props) {
        super(props);

        // Temporary cheat code (remove when no longer in use!!!)
        // ----------------------------------------------------------------
        this.state = { reset: false, resetting: false };

        this.handleCheat = () => {
            this.setState({ resetting: true });

            const args = [
                this.props.userGuid,
                this.props.scheduleGuid,
                this.props.scheduleType,
                this.props.examGuid,
                this.props.examTypeGuid,
            ];

            assessmentApi.resetSchedule(...args).then(() => {
                this.setState({ reset: true, resetting: false });
            });
        };
        // ----------------------------------------------------------------

        this.openExam = () => {
            if (this.needToStartProctorio()) {
                const {
                    proctorProvider,
                    proctoringSessionID,
                    examGuid,
                } = this.props;

                this.props.history.push(
                    `/proctoring/${proctorProvider}/${proctoringSessionID}/${examGuid}`
                );
            } else {
                const { examGuid } = this.props;

                this.props.history.push(`/exam/${examGuid}`);
            }
        };

        this.needToStartProctorio = () => {
            const { proctorProvider, proctorioActive } = this.props;

            return proctorProvider === PROCTORING_TYPES.PROCTORIO && !proctorioActive;
        };
    }

    render() {
        const { examName, classes, useResetScheduleCheat, completed } = this.props;
        const typographyProps = { className: classes.text };

        const listItemProps = completed
            ? { }
            : {
                  onClick: this.openExam,
                  button: true,
              };

        const listItemTextProps = {
            primaryTypographyProps: typographyProps,
            secondaryTypographyProps: typographyProps,
            primary: examName,
            secondary: this.SecondaryText,
        };

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <ListItem {...listItemProps}>
                    <ListItemText {...listItemTextProps} />
                </ListItem>
                <div style={{ flexShrink: 0 }}>
                    {useResetScheduleCheat
                        ? this.CheatAction
                        : this.SecondaryAction}
                </div>
            </div>
        );
    }

    get SecondaryText() {
        if (this.props.practice) {
            return null;
        }
        if (this.props.scheduleInfo) {
            return this.props.scheduleInfo;
        }
        if (this.props.completed) {
            const message = 'Completed';
            return (
                <React.Fragment>
                    <Hidden smUp>
                        <div>
                            <div>{message}</div>
                            <div>{this.props.completedDtFmt}</div>
                        </div>
                    </Hidden>
                    <Hidden
                        xsDown
                    >{`${message} - ${this.props.completedDtFmt}`}</Hidden>
                </React.Fragment>
            );
        }
        return this.props.startDtFmt;
    }

    get SecondaryAction() {
        if (this.props.completed) {
            return null;
        }

        return this.props.commenced
            ? this.getSecondaryAction(resumeMessageId)
            : this.getSecondaryAction(startMessageId);
    }

    getSecondaryAction(messageId) {
        const { classes } = this.props;

        const buttonProps = {
            className: classes.scheduleButton,
            classes: { disabled: classes.buttonDisabled },
            onClick: this.openExam,
            variant: "contained",
            color: "primary",
            size: "small",
        };

        return (
            <Button {...buttonProps}>
                <Hidden xsDown>{this.props.messages[messageId]}</Hidden>
                <ArrowForward />
            </Button>
        );
    }

    get CheatAction() {
        const buttonText = this.state.reset
            ? "Reset! Reload page"
            : this.state.resetting
            ? "Resetting..."
            : this.props.commenced
            ? "Reset schedule"
            : this.props.messages[startMessageId];

        const buttonProps = {
            disabled: this.state.reset || this.state.resetting,
            color: "primary",
            variant: "contained",
            size: "small",
            onClick: buttonText === "Start" ? this.openExam : this.handleCheat,
        };

        return <Button {...buttonProps}>{buttonText}</Button>;
    }
}

ScheduleItem.propTypes = {
    scheduleInfo: PropTypes.string,
    scheduleGuid: PropTypes.string.isRequired,
    scheduleType: PropTypes.string.isRequired,
    examName: PropTypes.string.isRequired,
    examGuid: PropTypes.string.isRequired,
    examTypeGuid: PropTypes.string.isRequired,
    startDt: PropTypes.string,
    startDtFmt: PropTypes.string,
    completedDt: PropTypes.string,
    completedDtFmt: PropTypes.string,
    commenced: PropTypes.bool.isRequired,
    completed: PropTypes.bool.isRequired,
    practice: PropTypes.bool.isRequired,
    requiresPassword: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    userGuid: PropTypes.string.isRequired,
    useResetScheduleCheat: PropTypes.bool.isRequired,
    messages: PropTypes.shape({
        [resumeMessageId]: PropTypes.string.isRequired,
        [startMessageId]: PropTypes.string.isRequired,
    }),
};

// ScheduleItem (connected to store)
// ------------------------------------------------------------------------

const mapStoreToProps = (store, { i }) => {
    const scheduleData = getScheduleDataByIndex(getSchedulesData(store))(i);
    const proctorioData = getProctorioData(store);

    return {
        i: undefined, // don't pass on 'i' prop as no longer needed
        scheduleGuid: SELECTORS.getScheduleGuid(scheduleData),
        scheduleType: SELECTORS.getScheduleType(scheduleData),
        scheduleInfo: SELECTORS.getScheduleInfo(scheduleData),
        examName: SELECTORS.getExamName(scheduleData),
        examGuid: SELECTORS.getExamGuid(scheduleData),
        examTypeGuid: SELECTORS.getExamTypeGuid(scheduleData),
        startDt: SELECTORS.getScheduleStartTime(scheduleData),
        startDtFmt: SELECTORS.getScheduleStartTimeFmt(scheduleData),
        completedDt: SELECTORS.getScheduleCompletedTime(scheduleData),
        completedDtFmt: SELECTORS.getScheduleCompletedTimeFmt(scheduleData),
        commenced: SELECTORS.hasScheduleCommenced(scheduleData),
        completed: SELECTORS.hasScheduleCompleted(scheduleData),
        practice: SELECTORS.isSchedulePractice(scheduleData),
        requiresPassword: SELECTORS.requiresSchedulePassword(scheduleData),
        userGuid: getUserGuid(getUserSessionData(getSessionData(store))),
        useResetScheduleCheat: isResetScheduleCheatActive(
            getAppSettingsData(getSettingsData(store))
        ),
        proctorProvider: SELECTORS.getProctorProvider(scheduleData),
        proctoringSessionID: SELECTORS.getProctoringSessionID(scheduleData),
        proctorioActive: getParent(proctorioData),        
    };
};

// ScheduleItem (connected to router/styles/messages)
// ------------------------------------------------------------------------

ScheduleItem = withRouter(connect(mapStoreToProps)(ScheduleItem));
ScheduleItem = withStyles(styles)(ScheduleItem);
ScheduleItem = withMessages(ScheduleItem);

export { ScheduleItem };
