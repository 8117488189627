import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import {
    getComponentValue,
    getStyle,
    addFontStyles,
    getActionObj,
} from "../form-helper";
import { FORM_SELECT_TYPES, FORM_SEPERATORS } from "constants/form";

const isChecked = (option, value) => {
    if (check.nonEmptyString(value)) {
        const items = value.split(FORM_SEPERATORS[3]);
        if (check.nonEmptyArray(items)) {
            return items.some((item) => item === option);
        }
    }
    return false;
};

const addValue = (option, value) => {
    if (check.nonEmptyString(value)) {
        const items = value.split(FORM_SEPERATORS[3]);
        if (check.nonEmptyArray(items)) {
            if (items.some((item) => item === option)) {
                return value;
            } else {
                items.push(option);
                return items.join(FORM_SEPERATORS[3]);
            }
        }
    }
    return option;
};

const removeValue = (option, value) => {
    if (check.nonEmptyString(value)) {
        const items = value.split(FORM_SEPERATORS[3]);
        if (check.nonEmptyArray(items)) {
            if (items.some((item) => item === option)) {
                const newItems = items.filter((item) => item !== option);
                return newItems.join(FORM_SEPERATORS[3]);
            } else {
                return value;
            }
        }
    }
    return "";
};

class FormSelectCheckbox extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.updateAction = this.updateAction.bind(this);

        const { fontObj } = props;
        const styleObj = addFontStyles(getStyle(props), fontObj);
        this.state = { styleObj };
    }

    handleChange(index, checked) {
        const { onChange, name, type, options, action } = this.props;

        const option = options[index];
        const value = this.getValue();
        if (type === FORM_SELECT_TYPES.CHECKBOX) {
            const newValue = checked
                ? addValue(option, value)
                : removeValue(option, value);
            check.nonEmptyString(action)?onChange(name, newValue, action):onChange(name, newValue);
        } else if (type === FORM_SELECT_TYPES.CHECKBOX_RADIO) {
            const newValue = checked ? option : "";
            check.nonEmptyString(action)?onChange(name, newValue, action):onChange(name, newValue);
        }
    }

    getValue() {
        const { formValue, name } = this.props;

        return getComponentValue(name, formValue);
    }

    componentDidMount(){
        const actionObj = getActionObj(null, this.props);
        //console.log('componentDidMount', actionObj)
        this.updateAction(actionObj)        
    }

    componentDidUpdate(prevProps) {
        const actionObj = getActionObj(prevProps, this.props);
        //console.log('componentDidUpdate', actionObj)
        this.updateAction(actionObj);
    }

    updateAction(actionObj){
        if (check.nonEmptyObject(actionObj)) {
            if (check.nonEmptyObject(actionObj.styles)){
                const { styleObj } = this.state;
                const newStyleObj = { ...styleObj, ...actionObj.styles };

                this.setState({ styleObj: newStyleObj });
            }
            else if (check.nonEmptyString(actionObj.message)){
            }
        }        
    }

    render() {
        const { name, enabled, options } = this.props;
        const { styleObj } = this.state;

        const value = this.getValue();

        return (
            <div style={styleObj}>
                {options.map((option, index) => {
                    const checked = isChecked(option, value);
                    const inputProps = {
                        name,
                        type: "checkbox",
                        checked,
                        onChange: (e) =>
                            this.handleChange(index, e.target.checked),
                        style: styleObj,
                        disabled: !enabled,
                    };
                    return (
                        <div key={index}>
                            <label>
                                <input {...inputProps} />
                                {option}
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export { FormSelectCheckbox };
