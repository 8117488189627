
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux (actions)
import {clearExam} from './actions'


// ExamClearer (not connected to store)
// ------------------------------------------------------------------

class ExamClearer extends React.Component
{
	componentDidMount()
	{
		this.props.clearPreviousExam();
		this.props.onClear();
	}

	render() { return null; }
}

ExamClearer.propTypes = {
	clearPreviousExam: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired
}


// ExamClearer (connected to store)
// ------------------------------------------------------------------

const mapDispatchToProps = (dispatch) => ({
	clearPreviousExam: () => dispatch(clearExam())
});

ExamClearer = connect(undefined, mapDispatchToProps)(ExamClearer)


// Export
// ------------------------------------------------------------------
export {ExamClearer}