// npm
import React from "react";
import { connect } from "react-redux";

// redux (selectors)
import { getExamData, getSessionData } from "redux/reducers/selectors";
import { getFormRunGuid } from "redux/reducers/exam/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getOrgId } from "redux/reducers/session/client/selectors";

// redux (action-types)
import {
    PAUSE_EXAM,
    RESUME_EXAM,
    CONFIRM_RESUME_EXAM,
} from "redux/reducers/exam/action-types";

import { dashApi } from "libs/api/interface/api-dash";

// utils
import { scheduledBugReports } from "libs/browser_storage/apis/scheduled-bug-reports";
import { check } from "@xams-utils/check-types";

const setExamPaused = (value) => ({
    type: value ? PAUSE_EXAM : RESUME_EXAM,
});

const setConfirmResumeExam = (value) => ({
    type: CONFIRM_RESUME_EXAM,
    value,
});

const POPUPS = {
    NONE: 0,
    CONNECTION_LOST: 1,
    CONNECTION_RETURNED: 2,
};

class ExamPauseNotifier extends React.Component {
    constructor(props) {
        super(props);

        this.pauseExam = this.pauseExam.bind(this);
        this.unPauseExam = this.unPauseExam.bind(this);

        const { formRunGuid } = this.props;

        if (check.nonEmptyString(formRunGuid)) {
            this.formRunGuid = formRunGuid;
        } else {
            this.formRunGuid = null;
        }

        this.startHub = this.startHub.bind(this);

        this.hubConnection = null;
    }

    componentDidMount() {
        if (check.nonEmptyString(this.formRunGuid)) {
            const { canOrgPause } = this.props;

            if (canOrgPause) {
                this.startHub();
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!check.nonEmptyString(this.formRunGuid)) {
            const { formRunGuid: prevFormGuid } = prevProps;
            const { formRunGuid, canOrgPause } = this.props;

            if (
                !check.nonEmptyString(prevFormGuid) &&
                check.nonEmptyString(formRunGuid)
            ) {
                this.formRunGuid = formRunGuid;

                if (canOrgPause) {
                    console.log("ExamPauseNotifier - Update formRunGuid", formRunGuid);
                    
                    this.startHub();
                }
            }
        }
    }

    startHub() {
        const { subscribe } = dashApi;
        const { formRunGuid } = this.props;

        console.log("ExamPauseNotifier  - Starting HUB", formRunGuid);

        this.hubConnection = subscribe({ formRunGuid });

        this.hubConnection.on("dashevent", (msg) => {
            if (check.nonEmptyString(msg)) {
                console.log("ExamPauseNotifier - Received hub event: " + msg);

                const data = JSON.parse(msg);

                if (check.nonEmptyObject(data)) {
                    const { paused } = data;

                    if (check.boolean(paused)) {
                        if (paused) {
                            this.pauseExam();
                        } else {
                            this.unPauseExam();
                        }
                    }
                }
            }
        });

    }

    componentWillUnmount() {
        if (this.hubConnection != null) {
            console.log("ExamPauseNotifier - Stopping signalR..");
            this.hubConnection.stop();
        }
    }

    pauseExam() {
        const { setExamPaused } = this.props;

        setExamPaused(true);
    }

    unPauseExam() {
        const { setExamPaused } = this.props;

        setExamPaused(false);
    }

    render() {
        return null;
    }
}

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const formRunGuid = getFormRunGuid(examData);

    const sessionData = getSessionData(store);
    const clientSessionData = getClientSessionData(sessionData);
    const orgId = getOrgId(clientSessionData);
    const canOrgPause = orgId === 29;

    return { formRunGuid, orgId, canOrgPause, canOrgPause };
};

const mapDispatchToProps = (dispatch) => ({
    setExamPaused: (value) => dispatch(setExamPaused(value)),
    confirmResumeExam: (value) => dispatch(setConfirmResumeExam(value)),
});

ExamPauseNotifier = connect(
    mapStoreToProps,
    mapDispatchToProps
)(ExamPauseNotifier);

export { ExamPauseNotifier };
