
// npm
import * as Immutable from 'immutable'

// redux
import {ROW_REDUCER} from './row/reducer'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const createRows = (state, action) =>
{
	const {data} = action;

	for (let i = 0; i < data.length; i++)
	{
		const rowData = data[i];
		const rowAction = {...action, data: rowData};
		state = state.push(ROW_REDUCER(undefined, rowAction));
	}

	return state;
}

const ROWS_REDUCER = (state=Immutable.List(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			return createRows(state, action);
		default:
			return state;
	}
}


export {ROWS_REDUCER}