
// npm
import check from 'check-types'


// 
// See first: https://github.com/ReactTraining/history
//
// 
// Note:
//
// It's only possible to attach one 'block' listener to react-router's history
// object, however we need to manage several of them across the app.
//
// So in order to do this, we need one listener that can call a bunch of other
// listeners every time a route transition has taken place.
// 
// I made this RoutingBlocker to do that. Listeners are added via 'subscribe,'
// and whenever a transition happens they will be called in reverse order until
// a successful blocking value is found.


// Note #2:
//
// There are several ways we can block a route transition. These are:
// 
// BLOCK: Show alert() and outright block transition
// CONFIRM: Show confirm() and block transition if user doesn't accept
// NOTIFY: Show alert() and continue the transition as normal
// 
// As before, it's only possible to set this up in one place (namely in
// <BrowserRouter>). Typically, <BrowserRouter> receives a string message
// to display to the user as a popup when a transition should be blocked.
//
// In our case, we will need to encode the 'type' of popup into that string as
// well.
//
// With that in mind, any listeners subscribing to this class must return an 
// object {type, message} rather than only the message (as was mandated in the
// history docs).
//
// This class will then handle encoding the type into the final message, which
// is then received by <BrowserRouter> to determine the kind of popup to show.


const BLOCKING_TYPES = {
	BLOCK: "block.navigation.completely",
	CONFIRM: "navigation.requires.user.confirmation",
	NOTIFY: "navigation.proceeds.with.alert"
}

const blockingTypes = Object.values(BLOCKING_TYPES);


const validateBlockingMessage = (blockingMessage) =>
{
	if (!check.string(blockingMessage)) {
		throw "RoutingBlocker: 'blockingMessage' is not a string";
	}
}

const validateSetTransition = (setTransition) =>
{
	if (!check.function(setTransition)) {
		throw "RoutingBlocker: 'setTransition' is not a function";
	}
}

const validateBlockingValue = (blockingValue) =>
{
	if (!check.object(blockingValue)) {
		throw "RoutingBlocker: 'blockingValue' is not an object";
	}

	if (!blockingTypes.includes(blockingValue.type)) {
		throw "RoutingBlocker: 'blockingValue' doesn't contain a valid blocking 'type'";
	}

	if (!check.string(blockingValue.message)) {
		throw "RoutingBlocker: 'blockingValue' doesn't contain a string 'message'";
	}
}

const getPopup = (blockingMessage, setTransition) =>
{
	validateBlockingMessage(blockingMessage);
	validateSetTransition(setTransition);

	if (blockingMessage.startsWith(BLOCKING_TYPES.BLOCK)) {
		const finalMessage = blockingMessage.substring(BLOCKING_TYPES.BLOCK.length);
		window.alert(finalMessage);
		setTransition(false);
	}
	else if (blockingMessage.startsWith(BLOCKING_TYPES.CONFIRM)) {
		const finalMessage = blockingMessage.substring(BLOCKING_TYPES.CONFIRM.length);
		setTransition(window.confirm(finalMessage));
	}
	else if (blockingMessage.startsWith(BLOCKING_TYPES.NOTIFY)) {
		const finalMessage = blockingMessage.substring(BLOCKING_TYPES.NOTIFY.length);
		window.alert(finalMessage);
		setTransition(true);
	}
	else {
		setTransition(false);
	}
}


class RoutingBlocker
{
	constructor()
	{
		const blockingFunctions = [];

		this.block = (history) => {
			return history.block((location, action) => {
				for (let i = blockingFunctions.length - 1; i >= 0; i--) {
					const blockingValue = blockingFunctions[i](location, action);
					if (blockingValue) {
						validateBlockingValue(blockingValue);
						return blockingValue.type + blockingValue.message;
					}
				}
			})
		}

		const unsubscribe = (blockingFunction) => () => {
			const index = blockingFunctions.indexOf(blockingFunction);
			if (index < 0) { return; }
			blockingFunctions.splice(index, 1);
		}

		this.subscribe = (blockingFunction) => {
			blockingFunctions.push(blockingFunction);
			return unsubscribe(blockingFunction);
		}
	}
}


const routingBlocker = new RoutingBlocker();


export {routingBlocker, getPopup, BLOCKING_TYPES}