import React, { Component } from "react";
import { connect } from "react-redux";

import { getProctorioData } from "redux/reducers/selectors";
import { getParent, getDebugMode } from "redux/reducers/proctorio/selectors";

import Avatar from "@material-ui/core/Avatar";

class ProctoringIndicator extends Component {
    render() {
        const { display } = this.props;

        return display ? <Avatar>P</Avatar> : null;
    }
}

const mapStoreToProps = (store) => {
    const proctorioData = getProctorioData(store);
    const proctorioActive = getParent(proctorioData);
    const debugMode = getDebugMode(proctorioData)

    return {
        display: proctorioActive && debugMode
    };
};

ProctoringIndicator = connect(mapStoreToProps)(ProctoringIndicator);

export { ProctoringIndicator };
