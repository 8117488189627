import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  dragHighlight: { backgroundColor: theme.palette.primary.main }
});

class FileDragAndDrop extends Component {
  state = { dragging: false, highlightClass: null };
  dropRef = React.createRef();
  handleDrag = e => {
    if (this.props.disabled) return;
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = e => {
    if (this.props.disabled) return;
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
    this.handleHighlight(true);
  };
  handleDragOut = e => {
    if (this.props.disabled) return;
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter > 0) return;
    this.setState({ dragging: false });
    this.handleHighlight(false);
  };
  handleDrop = e => {
    if (this.props.disabled) return;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.onFileDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
    this.handleHighlight(false);
  };
  handleHighlight = highlight => {
    const className = highlight ? this.props.classes.dragHighlight : null;
    this.setState({ highlightClass: className });
  };
  componentDidMount() {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }
  render() {
    return (
      <div ref={this.dropRef} className={this.state.highlightClass}>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(FileDragAndDrop);
