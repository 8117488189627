import React, { Component } from "react";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";
import isEqual from "lodash/isEqual";

import {
    getMappingsAndSections,
    findCurrentSection,
    displayAllQuestionsInSection,
} from "../content-helper";
import { setAudioElapsed, setAudioState } from "./actions";

import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

import { MaterialText } from "components/presentation/material-text";
import { AudioChip, CONSTANTS  } from "components/functional/audio/audio-chip";
import { isAudio, getMediaPropsFromSection, urlHasChanged, hasAudioStateChanged } from "components/functional/audio/audio-helper";

class DisplayAllQuestionsBar extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.handleAudioElapsed = this.handleAudioElapsed.bind(this);
        this.handleAudioFinished = this.handleAudioFinished.bind(this);

        const state={}
        const { audio } = props;

        if (audio){
            state.audioProps=audio;
        }

        this.state={...state};
    }

    componentDidUpdate(prevProps){
        const {audio: prevAudio}=prevProps;
        const {audio}=this.props;

        if (urlHasChanged(audio,prevAudio)){
            this.setState({audioProps:audio?{...audio}:null});
        }
        else if (hasAudioStateChanged(audio,prevAudio)) {        
            const {audioProps}=this.state;
            const newAudioProps={...audioProps, state:audio.state}

            this.setState({audioProps:newAudioProps});
        }
    }

    displayScenarioText(text) {
        if (!text) return null;

        const { classes } = this.props;

        return <MaterialText className={classes.text}>{text}</MaterialText>;
    }

    displayText(text) {
        if (!text) return null;

        const { classes } = this.props;

        return <MaterialText className={classes.text}>{text}</MaterialText>;
    }

    displayMedia() {
        const { classes } = this.props;
        const {audioProps}=this.state;

        if (audioProps) {

            //const props = { autoDelay: 1, ...media, autoPlay:false, canPause:true };
            const props = {
                autoDelay: 0,
                ...audioProps,
                onElapsed: this.handleAudioElapsed,
                onFinished: this.handleAudioFinished,
                elapsedUpdate: 5,
            };

            return (
                <div className={classes.text}>
                    <AudioChip {...props} />
                </div>
            );
        }

        return null;
    }

    handleAudioElapsed(elapsed) {
        const { setElapsedAudio, currentSection } = this.props;

        setElapsedAudio(parseInt(currentSection.id), elapsed);
    }

    handleAudioFinished() {
        const { setStateAudio, currentSection } = this.props;
        setStateAudio(parseInt(currentSection.id), CONSTANTS.finished);
    }  

    displayDivider() {
        return <Divider />;
    }

    hasMedia(){
        const {audio}=this.props;

        return audio;
    }

    render() {
        // return null;

        const { displayAllQuestions, currentSection, classes } = this.props;

        if (!displayAllQuestions) {
            return null;
        }

        const { scenarioText, text} = currentSection.data;
        if (!scenarioText && !text && !this.hasMedia()) return null;

        return (
            <Paper className={classes.panel}>
                <div className={classes.root}>
                    {this.displayScenarioText(scenarioText)}
                    {scenarioText && text && this.displayDivider()}
                    {this.displayText(text)}
                    {(scenarioText || text) && this.hasMedia() && this.displayDivider()}
                    {this.hasMedia() && this.displayMedia()}
                </div>
            </Paper>
        );
    }
}

const mapStoreToProps = (store, { currentQuestionId }) => {
    const { mappings, sections } = getMappingsAndSections(
        store,
        currentQuestionId
    );

    const currentSection = findCurrentSection(
        currentQuestionId,
        mappings,
        sections
    );

    const displayAllQuestions = displayAllQuestionsInSection(currentSection);

    return {
        displayAllQuestions,
        currentSection,
        canExpand: true,
        ...getMediaPropsFromSection(currentSection)
    };
};

const mapDispatchToProps = (dispatch) => ({
    setElapsedAudio: (id, value) => {
        dispatch(setAudioElapsed(id, value));
    },
    setStateAudio: (id, value) => {
        dispatch(setAudioState(id, value));
    },    
});

DisplayAllQuestionsBar = connect(
    mapStoreToProps,
    mapDispatchToProps
)(DisplayAllQuestionsBar);

const styles = ({ palette, spacing }) => {
    const _spacing = spacing.unit * 2;

    return {
        panel: {
            padding: `0px ${_spacing}px`,
            // marginBottom: 0,
            // padding: spacing.unit * 2,
            margin: `0px ${_spacing}px 0px`,
            backgroundColor: palette.background.light,
        },
        root: {
            padding: _spacing,
            backgroundColor: palette.background.default,
        },

        text: {
            margin: `${_spacing}px 0px`,
        },
        divider: {
            marginTop: spacing.unit,
            marginBottom: spacing.unit,
        },
    };
};

DisplayAllQuestionsBar = withStyles(styles)(DisplayAllQuestionsBar);

export { DisplayAllQuestionsBar };
