import React from 'react';
import CalculatorButton from './calculator-button';

const CalculatorButtonRow = ({ onSelect, buttons }) => {
  return (
    <div style={{display: 'flex', width: '100%'}}>
      {buttons.map(button => (
        <CalculatorButton
          key={button}
          value={button}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

export default CalculatorButtonRow;
