
// npm
import {List as ImmutableList} from 'immutable'

// redux
import * as EXAM_ACTION_TYPES from '../../../action-types'


const MAPPING_REDUCER = (state=ImmutableList(), action) =>
{
	switch (action.type)
	{
		case EXAM_ACTION_TYPES.SET_EXAM_DATA:
			return state.withMutations(mutableState => {
				action.mappees.forEach(mappee => {
					mutableState.push(mappee['@ID']);
				});
			});
		default:
			return state;
	}
}


export {MAPPING_REDUCER}