// redux
import { extractMessage } from "redux/reducers/exam/helpers";
import { SET_EXAM_DATA } from "redux/reducers/exam/action-types";

import { check } from "@xams-utils/check-types";

// custom
import { getProp } from "custom/object-helper";
import { shuffle } from "custom/array-helper";

// constants
import { WORDS_WITH_SQUARE_BRACKETS } from "./constants";

const isVariable = (text) => {
    if (!text) {
        return false;
    }

    if (text.charAt(0) !== "V" && text.charAt(0) !== "v") {
        return false;
    }

    if (text.length === 1) {
        return false;
    }

    for (let i = 1; i < text.length; i++) {
        if (text.charCodeAt(i) < 48 || text.charCodeAt(i) > 57) {
            return false;
        }
    }

    return true;
};

const extractAnswerText = (data) => extractMessage(data.ANSWERTEXT || {});

const toLocalAnswerTextOld = (serverAnswerText) =>
    serverAnswerText.replace(WORDS_WITH_SQUARE_BRACKETS, "[]");

const toLocalAnswerText = (serverAnswerText) => {
    let startingPosition = 0;
    let returnText = "";

    while (true) {
        const startBracket = serverAnswerText.indexOf("[", startingPosition);

        if (startBracket === -1) {
            returnText += serverAnswerText.substr(startingPosition);
            break;
        }

        const endBracket = serverAnswerText.indexOf("]", startBracket);
        //debugger;

        if (endBracket === -1) {
            returnText += serverAnswerText.substr(startingPosition);
            break;
        }

        returnText += serverAnswerText.substring(
            startingPosition,
            startBracket
        );

        const optionText = serverAnswerText.substring(
            startBracket + 1,
            endBracket
        );

        if (isVariable(optionText)) {
            returnText += "[" + optionText + "]";
        } else returnText += "[]";
        startingPosition = endBracket + 1;
    }

    return returnText;
};

const removeBrackets = (text) => {
    text = text.replace(/\[/g, "");
    return text.replace(/\]/g, "");
};

const extractOptions = (serverAnswerText) => {
    const options = serverAnswerText.match(WORDS_WITH_SQUARE_BRACKETS);
    const unshuffledOptions = options.map((option) => removeBrackets(option));
    return shuffle(unshuffledOptions);
};

const extractVariableOptions = (data) => {
    if (!data.BLANKS) return null;
    if (check.array(data.BLANKS.BLANK)) {
        const options = data.BLANKS.BLANK.reduce((options, blank) => {
            if (blank["@Text"] && isVariable(blank["@Text"])) {
                if (!options) {
                    options = [];
                }
                if (
                    blank.OPTIONS &&
                    blank.OPTIONS.OPTION &&
                    check.array(blank.OPTIONS.OPTION)
                ) {
                    options[blank["@Text"]] = shuffle(blank.OPTIONS.OPTION);
                }
            }
            return options;
        }, null);

        return options;
    } else if (check.object(data.BLANKS.BLANK)) {
        const blank = data.BLANKS.BLANK;

        if (blank["@Text"] && isVariable(blank["@Text"])) {
            if (
                blank.OPTIONS &&
                blank.OPTIONS.OPTION &&
                check.array(blank.OPTIONS.OPTION)
            ) {
                const options = [];
                options[blank["@Text"]] = shuffle(blank.OPTIONS.OPTION);

                return options;
            }
        }
    }

    return null;
};

const handleSetExamData = (state, action) => {
    const serverAnswerText = extractAnswerText(action.data); // format: "How [now] brown [cow]"
    const answerText = toLocalAnswerText(serverAnswerText); // format: "How [] brown []"
    const options = extractOptions(serverAnswerText);
    const variableOptions = extractVariableOptions(action.data);

    state = state.set("answerText", answerText);
    state = state.set("options", options);
    state = state.set("variableOptions", variableOptions);
    return state.set("resumedAnswer", getProp(action, "data.ANSWER"));
};

// Note: state should always be initialized at this point (via QUESTION_REDUCER)
const FILL_IN_BLANKS_QUESTION_REDUCER = (state, action) => {
    switch (action.type) {
        case SET_EXAM_DATA:
            return handleSetExamData(state, action);
        default:
            return state;
    }
};

export { FILL_IN_BLANKS_QUESTION_REDUCER };
