
// npm
import React from 'react'
import PropTypes from 'prop-types'

// utils
import {messagesApi} from 'libs/api/interface/api-messages'


class MessageFetcher extends React.Component
{
	constructor(props)
	{
		super(props);

		this.onSuccess = (response) => {
			const {messageIds, setMessage} = this.props;
			response = JSON.parse(response);
			response.forEach((value, index) => setMessage(messageIds[index], value));
			this.props.onSuccess();
		}
	}

	componentDidMount()
	{
		const {messageIds, orgId, userGuid, languageId} = this.props;

		messagesApi.requestMessages(messageIds, orgId, userGuid, languageId)
		.then(this.onSuccess, this.props.onFail);
	}

	render() { return null; }
}

MessageFetcher.propTypes = {
	onFail: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,

	setMessage: PropTypes.func.isRequired,
	messageIds: PropTypes.arrayOf(PropTypes.string).isRequired,

	orgId: PropTypes.number.isRequired,
	languageId: PropTypes.number,
	userGuid: PropTypes.string
}


export {MessageFetcher}