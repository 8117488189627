const timerLog = [];
let timerLogOff = false;

const formatNumber = (number, format) => {
    const st = "" + number;
    const size = st.length;
    const add = format.length - size;

    return add > 0 ? `${format.charAt(0).repeat(add)}${number}` : number;
};

const displayDateTime = (timeStamp) => {
    const date = new Date(timeStamp);

    const hours = formatNumber(date.getHours(), "00");
    const minutes = formatNumber(date.getMinutes(), "00");
    const seconds = formatNumber(date.getSeconds(), "00");
    const milliseconds = formatNumber(date.getMilliseconds(), "000");

    return `${hours}:${minutes}:${seconds}:${milliseconds}`;
};

const displayTime = (duration) => {
    const ms = 1000;

    var milliseconds = Math.floor(duration % 1000),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    return `${duration} = ${hours > 0 ? ` ${hours} h ` : ""}${
        hours > 0 || minutes > 0 ? ` ${minutes} m ` : ""
    }${
        hours > 0 || minutes > 0 || seconds > 0 ? ` ${seconds} s ` : ""
    }${milliseconds} ms`;
    // return `${milliseconds} = ${hours>0?` ${hours} h `:''}${hours>0 || minutes>0?` ${minutes} m `:''}${hours>0 || minutes>0 || seconds>0?` ${seconds} s `:''}${rest} ms';
};

const addLog = (log1 = null, log2 = null, log3 = null) => {
    if (log1 === null) {
        displayLog();
    } else {
        var log = [];

        if (log1 !== null) log.push(log1);
        if (log2 !== null) log.push(log2);
        if (log3 !== null) log.push(log3);

        displayLogLine(log);
        timerLog.push(log);
    }
};
const displayLog = () => {
    console.clear();
    timerLog.forEach((log) => {
        displayLogLine(log);
    });
};
const displayLogLine = (log) => {
    if (log.length === 1) console.log(log[0]);
    else if (log.length === 2) console.log(log[0], log[1]);
    else if (log.length === 3) console.log(log[0], log[1], log[2]);
};

export { addLog, displayDateTime, displayTime };
