
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

// react
import {UserSelector} from './user-selector'
import {ExamSelector} from './exam-selector'
import {DateTimeSelector} from './date-time-selector'
import {MaterialText} from 'components/presentation/material-text'
import {MultiLineTextEditor} from 'components/presentation/text_editors/multi_line'


class BugReportForm extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			userGuid: null,
			examGuid: undefined,
			minDt: null,
			maxDt: null,
			bugDescription: ""
		}

		this.maxDate = new Date();
		this.initializeBoundMethods();
	}

	initializeBoundMethods()
	{
		this.setUserGuid = (userGuid) => {
			const examGuid = userGuid === null ? undefined : null;
			this.setState({userGuid, examGuid});
		}

		this.setExamGuid = (examGuid) => {
			this.setState({examGuid});
		}

		const isValidDateRange = (minDate, maxDate) => {
			if (!minDate || !maxDate) { return true; }
			const minDateTime = minDate.getTime();
			const maxDateTime = maxDate.getTime();
			return maxDateTime >= minDateTime;
		}

		const validateDateRange = (dateKey, date) => {
			const isMinDt = dateKey === 'minDt';
			const minDt = isMinDt ? date : this.state.minDt;
			const maxDt = isMinDt ? this.state.maxDt : date;
			if (!isValidDateRange(minDt, maxDt)) {
				alert("Bug end time can't happen before start time");
				return false;
			}
			return true;
		}

		const validateDate = (dateKey, date) => {
			if (!date) { return true; }

			const dateTime = date && date.getTime();
			const maxDateTime = this.maxDate.getTime();

			if (dateTime > maxDateTime) {
				alert("Bug time can't be later than now");
				return false;
			}

			return true;
		}

		const setDt = (dateKey) => (dt) => {
			if (!validateDate(dateKey, dt)) { dt = null; }
			else if (!validateDateRange(dateKey, dt)) { dt = null; }
			this.setState({[dateKey]: dt});
		}

		this.setMinDt = setDt('minDt');
		this.setMaxDt = setDt('maxDt');

		this.setBugDescription = ({target:{value}}) => {
			this.setState({bugDescription: value});
		}
	}

	render()
	{
		const divStyle = {
			display: 'flex',
			minWidth: 500,
			flexDirection: 'column',
			alignItems: 'stretch'
		}

		return (
			<Table>
				{this.getRow('Which user did this happen to?', <UserSelector onChange={this.setUserGuid}/>)}
				{this.ExamSelectorRow}
				{this.getDateTimeSelectorRow('min')}
				{this.getDateTimeSelectorRow('max')}
				{this.getRow("What was the bug?", <MultiLineTextEditor lineCount={2} value={this.state.bugDescription} onChange={this.setBugDescription}/>)}
			</Table>
		)
	}

	componentDidUpdate()
	{
		this.props.onChange(this.state, this.state.bugDescription !== "");
	}

	get ExamSelectorRow()
	{
		if (this.state.examGuid === undefined) {
			return null;
		}

		const {userGuid} = this.state;
		const node = <ExamSelector userGuid={userGuid} onChange={this.setExamGuid}/>
		return this.getRow('Exam', node);
	}

	getDateTimeSelectorRow(type)
	{
		const label = `Approximate bug ${type === 'min' ? 'start' : 'end'} time`;
		const onChange = this[type === 'min' ? 'setMinDt' : 'setMaxDt'];
		const value = this.state[type === 'min' ? 'minDt' : 'maxDt'];
		return this.getRow(label, <DateTimeSelector value={value} onChange={onChange}/>);
	}

	getRow(label, node)
	{
		return (
			<TableRow>
				<TableCell>
					<MaterialText>{label}</MaterialText>
				</TableCell>
				<TableCell>
					{node}
				</TableCell>
			</TableRow>
		)
	}
}

BugReportForm.propTypes = {
	onChange: PropTypes.func.isRequired
}


export {BugReportForm}