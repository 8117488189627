
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MaterialText} from 'components/presentation/material-text'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionSectionText} from 'redux/reducers/exam/content/complex-selectors'


// SectionText (not connected)
// ----------------------------------------

let SectionText = ({text, classes}) =>
{
	if (!text) { return null; }

	return (
		<React.Fragment>
			<MaterialText className={classes.text}>{text}</MaterialText>
			<Divider className={classes.divider}/>
		</React.Fragment>
	)
}

SectionText.propTypes = {
	text: PropTypes.string
}


// SectionText (connected to store)
// ----------------------------------------

const mapStoreToProps = (store, {questionId}) => ({
	text: getQuestionSectionText(getContentData(getExamData(store)))(questionId)
});

SectionText = connect(mapStoreToProps)(SectionText);


// SectionText (connected to styles)
// ---------------------------------------------------------------

const styles = ({palette, spacing}) => ({
	text: {
		color: palette.background.contrastText
	},
	divider: {
		marginTop: spacing.unit,
		marginBottom: spacing.unit
	}
})

SectionText = withStyles(styles)(SectionText);


SectionText.propTypes = {
	questionId: PropTypes.number.isRequired
}


// Export
// ----------------------------------------
export {SectionText}