
// npm
import React from 'react'
import PropTypes from 'prop-types'

// xams-components
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamOutroPage} from './page'
import {ExamCompleter} from './exam-completer'
import {FinishActivityLogger} from './finish-activity-logger'
import { CloseIntegrityAdvocate } from './close-integrity-advocate'
import {ResultFetcher} from './result-fetcher'
import { ResultDownloader } from './page/download_results/result-downloader'
import {SetNetworkError} from 'components/pages/network_error/set-network-error'

// machines
import {examOutro} from 'machines/exam-outro'


const {STATES, EVENTS} = examOutro;


const ExamOutroPageMachine = () =>
{
	return (
		<StateMachine machine={examOutro.machine}>
			<StateView>
				{{
					[STATES.FINISHING_ACTIVITY_LOGGER]: () => (
						<StateControl onComplete={EVENTS.FINISHED_ACTIVITY_LOGGER} onFail={EVENTS.FINISHED_ACTIVITY_LOGGER}>
							{(props) => <FinishActivityLogger {...props}/>}
						</StateControl>
					),
					[STATES.CLOSING_INTEGRTITY_ADVOCATE]: () => (
						<StateControl onComplete={EVENTS.CLOSED_INTEGRITY_ADVOCATE}>
							{(props) => <CloseIntegrityAdvocate {...props}/>}
						</StateControl>
					),																															
					[STATES.COMPLETING_EXAM]: () => (
						<StateControl onComplete={EVENTS.EXAM_COMPLETED} onFail={EVENTS.ERROR}>
							{(props) => <ExamCompleter {...props}/>}
						</StateControl>
					),
					[STATES.FETCHING_RESULTS]: () => (
						<StateControl onFinish={EVENTS.FINISHED} onComplete ={EVENTS.GOT_COMPLETION} onError={EVENTS.ERROR}>
							{(props) => <ResultFetcher {...props}/>}
						</StateControl>
					),
					[STATES.DOWNLOADING_RESULTS]: () => (
						<StateControl onFinish={EVENTS.RESULT_DOWNLOADED} onError={EVENTS.ERROR}>
							{(props) => <ResultDownloader {...props}/>}
						</StateControl>
					),					
					[STATES.OUTRO_SCREEN]: () => (
						<ExamOutroPage/>
					),
					[STATES.NETWORK_ERROR]: () => (
						<SetNetworkError/>
					)
				}}
			</StateView>
		</StateMachine>
	)
}


export {ExamOutroPageMachine}