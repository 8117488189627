
import {SET_OFFLINE_MODE} from 'redux/reducers/app/action-types'


const setOfflineMode = (value) => ({
	type: SET_OFFLINE_MODE,
	value
});


export {setOfflineMode}