// npm
// import {Map as ImmutableMap} from 'immutable'
import * as Immutable from "immutable";
import { check } from "@xams-utils/check-types";
import { getTimeStamp } from "utils/time-stamp";

// redux
import * as ACTION_TYPES from "./action-types";

const initialState = {
    initialized: false,
    networkError: false,
    networkErrorInfo: null,
    offlineReason: Immutable.List(),
};

const APP_REDUCER = (state = Immutable.Map(initialState), action) => {
    switch (action.type) {
        case ACTION_TYPES.INITIALIZED:
            return state.set("initialized", true);
        case ACTION_TYPES.SET_NETWORK_ERROR:
            return state.set("networkError", action.value);
        case ACTION_TYPES.SET_NETWORK_ERROR_INFO:
            return state.set("networkErrorInfo", action.value);            
        case ACTION_TYPES.SET_OFFLINE_MODE: {
            if (check.boolean(action.value)) {
                return state.set("offline", action.value);
            } else if (check.object(action.value)) {
                const { offline } = action.value;
                state = state.set("offline", offline);

                const reason = { ...action.value };
                reason.timeStamp = getTimeStamp();

                //state = state.set('offlineReason', Immutable.Map(action.value));
                state = state.update("offlineReason", (reasons) =>
                    reasons.push(Immutable.Map(reason))
                );

                return state;
            }
        }
        default:
            return state;
    }
};

export { APP_REDUCER };
