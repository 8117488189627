
// npm
import React from 'react'

const Hide = ({children}) => (
	<div style={{position: 'absolute', display: 'none'}}>
		{children}
	</div>
)

export {Hide}