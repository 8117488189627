
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'
import {Switch, Route} from 'react-router-dom'

// react
import {ExamIntro} from './intro'
import {ExamOutro} from './outro'
import {ExamRunningPage} from './running/page'
import {RedirectToExamIntro} from './redirect-to-intro'


class ExamRouter extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {wasInIntro: false};
	}

	componentDidMount()
	{
		this.setState({wasInIntro: true});
	}

	render()
	{
		// ExamIntroPage sets up current question id (as part of the 'attempt resume' process). 
		// I've enforced this router to always go through the 'intro' screen first so as to ensure
		// the exam gets set up correctly (there were issues before when reloading page).
		// This process may need a re-think in the future, but for now it's working.
		if (!this.state.wasInIntro) {
			return <Route path="/exam/:examGuid/" component={RedirectToExamIntro}/>;
		}

		return (
			<Switch location={this.props.location}>
				<Route exact path="/exam/:examGuid/intro" component={ExamIntro}/>
				<Route exact path="/exam/:examGuid/outro" component={ExamOutro}/>
				<Route exact path="/exam/:examGuid/:questionNumber" component={ExamRunningPage}/>
				<Route path="" component={RedirectToExamIntro}/>
			</Switch>
		)
	}
}

ExamRouter.propTypes = {
	location: PropTypes.object.isRequired
}


export {ExamRouter}