
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE NAMES
// #########################################

const STATES = {
	AUTHENICATING_USER: 'authenicating_user',
	INITIALIZING_SESSION_MESSAGES: 'initializing_session_messages',
	FETCHING_CLIENT_SETTINGS: 'fetching_client_setttings',
	CHECKING_PROCTORIO_PARENT: 'checking_proctorio_parent',
	REDIRECT_TO_EXAM: 'redirect_to_exam',
	LOADING_FAILED: 'loading_fail',
	INITIALIZING_SESSION_MESSAGES_FAILED: 'initializing_session_messages_failed',
	FETCHING_CLIENT_SETTINGS_FAILED: 'fetching_client_setttings_failed',	
}


// #########################################
// EVENT NAMES
// #########################################

const EVENTS = {
	USER_AUTHENICATED: 'user_authenicated',
	SESSION_MESSAGES_INITIALIZED: 'session_messages_initialized',
	CLIENT_SETTINGS_FETCHED: 'client_settings_fetched',
	PROCTORIO_PARENT_CHECKED: 'proctorio_parent_checked',
	LOAD_FAILURE: 'load_failure',
}


// #########################################
// STATES
// #########################################

const authenicatingUser = new XStateConfig();
authenicatingUser.addTransition(EVENTS.USER_AUTHENICATED, STATES.INITIALIZING_SESSION_MESSAGES);
authenicatingUser.addTransition(EVENTS.LOAD_FAILURE, STATES.LOADING_FAILED);

const initializingSessionMessages = new XStateConfig();
initializingSessionMessages.addTransition(EVENTS.SESSION_MESSAGES_INITIALIZED, STATES.FETCHING_CLIENT_SETTINGS);
initializingSessionMessages.addTransition(EVENTS.LOAD_FAILURE, STATES.INITIALIZING_SESSION_MESSAGES_FAILED);

const fetchingClientSetttings = new XStateConfig();
fetchingClientSetttings.addTransition(EVENTS.CLIENT_SETTINGS_FETCHED, STATES.CHECKING_PROCTORIO_PARENT);
fetchingClientSetttings.addTransition(EVENTS.LOAD_FAILURE, STATES.FETCHING_CLIENT_SETTINGS_FAILED);

const checkProctorioParent = new XStateConfig();
checkProctorioParent.addTransition(EVENTS.PROCTORIO_PARENT_CHECKED, STATES.REDIRECT_TO_EXAM);
checkProctorioParent.addTransition(EVENTS.LOAD_FAILURE, STATES.LOADING_FAILED);

const redirectToExam = new XStateConfig();

const loadingFailed = new XStateConfig();
const initializingSessionMessagesFailed = new XStateConfig();
const fetchingClientSetttingsFailed = new XStateConfig();


// #########################################
// MACHINE
// #########################################

const _proctorio = new XStateConfig();
_proctorio.initialState = STATES.AUTHENICATING_USER;
_proctorio.addState(STATES.AUTHENICATING_USER, authenicatingUser);
_proctorio.addState(STATES.INITIALIZING_SESSION_MESSAGES, initializingSessionMessages);
_proctorio.addState(STATES.FETCHING_CLIENT_SETTINGS, fetchingClientSetttings);
_proctorio.addState(STATES.CHECKING_PROCTORIO_PARENT, checkProctorioParent);
_proctorio.addState(STATES.REDIRECT_TO_EXAM, redirectToExam);
_proctorio.addState(STATES.LOADING_FAILED, loadingFailed);
_proctorio.addState(STATES.INITIALIZING_SESSION_MESSAGES_FAILED, initializingSessionMessagesFailed);
_proctorio.addState(STATES.FETCHING_CLIENT_SETTINGS_FAILED, fetchingClientSetttingsFailed);

const machine = Machine(_proctorio.toObject());
machine.id = "Proctorio Machine";


// #########################################
// EXPORT
// #########################################

const proctorio = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES}
}

export {proctorio}