
// npm
import React from 'react'
import generateId from 'uuid/v4'
import PropTypes from 'prop-types'
import {Map as ImmutableMap} from 'immutable'

// material-ui
import Card from '@material-ui/core/Card'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {DraggableOption} from './draggable-option'
import {DroppableOption} from './droppable-option'
import {MaterialText} from 'components/presentation/material-text'

// custom
import {getKeyWithValue} from 'custom/object-helper'

// utils
import {htmlToReact} from 'utils/html-to-react'


const ERRORS = { NO_REF: "Dnd (match): Include 'ref' in droppable component" };

class MatchDragAndDropAnswerForm extends React.Component
{
	constructor(props)
	{
		super(props);
		this.initializeBoundProperties();
		this.initializeBoundMethods();

		const draggableKeys = {};
		const {formData:{selections}} = props;
		selections.forEach(({id}) => { draggableKeys[id] = generateId(); });

		this.state = { 
			highlightedOptionId: null,
			draggableKeys: ImmutableMap(draggableKeys) 
		}
	}

	initializeBoundProperties()
	{
		this.droppableRefs = {};
		this.props.formData.options.forEach(({id}) => {
			this.droppableRefs[id] = React.createRef();
		});
	}

	initializeBoundMethods()
	{
		this.generateNewDraggableKey = (id) => {
			const {draggableKeys} = this.state;
			this.setState({draggableKeys: draggableKeys.set(id, generateId())});
		}

		this.findHoveredDroppable = (x, y) => {
			// First check to see if the currently-higlighted droppable 
			// is still being hovered over (for efficiency reasons)
			const {state:{highlightedOptionId:highlightedId}, droppableRefs} = this;
			const droppableRef = highlightedId && droppableRefs[highlightedId];
			const droppable = droppableRef && droppableRef.current;
			if (droppable && droppable.containsPoint(x, y)) { return droppableRef; }

			// If not, find the new droppable we're hovering over, 
			// ...skipping the one we just checked
			return Object.values(droppableRefs).find(ref => {
				if (ref === droppableRef) { return false; }
				if (!ref.current) { throw ERRORS.NO_REF; }
				return ref.current.containsPoint(x, y);
			});
		}

		this.onSelectionDrag = (id, e, data) => {
			const {highlightedOptionId} = this.state;
			const droppableRef = this.findHoveredDroppable(e.clientX, e.clientY);
			const exitingDroppable = highlightedOptionId && !droppableRef;
			const enteringDroppable = !highlightedOptionId && droppableRef;
			if (!exitingDroppable && !enteringDroppable) { return; }
			const newHighlightedId = exitingDroppable ? null : 
				getKeyWithValue(this.droppableRefs, droppableRef);
			this.setState({highlightedOptionId: newHighlightedId});
		}

		this.onSelectionDrop = (id, e, data) => {
			const {highlightedOptionId} = this.state;
			if (!highlightedOptionId) { return this.generateNewDraggableKey(id); }			// returns nothing (using as filter)
			this.setState({highlightedOptionId: null});
			this.saveAnswer(highlightedOptionId, id);
		}
	}

	render()
	{
		return (
			<div id='match-dnd'>
				<Card style={{backgroundColor: 'transparent'}}>
					<div style={{position: 'relative'}}>
						<div style={{padding: 5}}>{this.DraggableSelections}</div>
						<div style={{padding: 5}}>{this.DroppableOptions}</div>
					</div>
				</Card>
			</div>
		)
	}

	get DraggableSelections()
	{
		const {value, formData:{selections}} = this.props;
		const answeredSelectionIds = Object.values(value).filter(x => !!x);
		const unansweredSelections = selections.filter(({id}) => {
			return !answeredSelectionIds.includes(id);
		});

		const {onSelectionDrop:onDragStop, onSelectionDrag:onDrag} = this;
		const commonProps = {bounds: '#match-dnd', onDragStop, onDrag};

		return unansweredSelections.map(({id, text}) => {
			const draggableKey = this.state.draggableKeys.get(id.toString());
			const value = htmlToReact.parse(text);
			const uniqueProps = {id, draggableKey, value};
			return <DraggableOption {...uniqueProps} {...commonProps}/>;
		});
	}

	get DroppableOptions()
	{
		const {formData:{options, selections}, value:_value, classes} = this.props;

		const divStyle = {
			display: 'inline-block',
			padding: '2px 5px 2px 5px',
			verticalAlign: 'bottom'
		}

		return options.map(({id, text}) => {
			const selectionId = _value[id];
			// eslint-disable-next-line
			const valueWrapper = selections.find(x => x && x.id == selectionId); 				// double-equals on purpose

			const value = valueWrapper && valueWrapper.text;
			const ref = this.droppableRefs[id];
			const onReset = () => this.saveAnswer(id, undefined);
			const highlighted = this.state.highlightedOptionId === id.toString();
			const props = {ref, value, onReset, highlighted};

			return (
				<div style={divStyle}>
					<MaterialText className={classes.text}>{text}</MaterialText>
					<DroppableOption {...props}/>
				</div>
			);
		});
	}

	saveAnswer(optionId, selectionId)
	{
		this.props.onChange({optionId, selectionId});
	}
}

MatchDragAndDropAnswerForm.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		options: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired
		})),
		selections: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired
		}))
	})
}


const styles = ({palette}) => ({
	text: {color: palette.background.contrastText}
});

MatchDragAndDropAnswerForm = withStyles(styles)(MatchDragAndDropAnswerForm);


export {MatchDragAndDropAnswerForm}