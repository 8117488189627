
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

// react
import {ActionButton} from 'components/layout/action_button/button'

// messages
import {withMessages} from 'components/hocs/messages'
import {MESSAGE_IDS} from 'constants/message-ids'


let StartAnsweringButton = ({onClick, messages}) => (
	<ActionButton Icon={ArrowForwardIcon} onClick={onClick} alignment='right'>
		{messages[MESSAGE_IDS.EXAM.START_ANSWERING]}
	</ActionButton>
)

StartAnsweringButton.propTypes = {
	onClick: PropTypes.func.isRequired
}


StartAnsweringButton = withMessages(StartAnsweringButton);


export {StartAnsweringButton}