// npm
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";

import { getGuid } from "redux/reducers/exam/selectors";
import { getExamData } from "redux/reducers/selectors";

// material-ui
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import Select from "@material-ui/core/Select";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { MaterialText } from "components/presentation/material-text";
import { DisableAnsweringContext } from "components/pages/exam/disabled-context";

import { getOptionWidthFromLength } from "./option-helper";


// DropdownSelections
// -----------------------------------------------------------

const getDropdownSelections = (selections) =>
    selections.map((selection) => {
        return <MenuItem value={selection.id}>{selection.text}</MenuItem>;
    });

// MatchDropdown
// -----------------------------------------------------------

const preventChangeEvent = (e) => e.preventDefault();

const MatchDropdown = ({
    optionId,
    value,
    selections,
    onChange,
    classes,
    newStyling,
    optionWidth,
}) => {
    const inputProps = {
        className: classes.text,
        classes: { underline: classes.inputUnderline },
    };

    const getDropdown = (disabled) => {
        let handleChange = disabled
            ? preventChangeEvent
            : (evt) => {
                  const {
                      target: { value: selectionId },
                  } = evt;
                  onChange({ optionId, selectionId });
              };

        const selectProps = {
            className: classes.answerText,
            classes: { icon: classes.text },
            onChange: handleChange,
            input: <Input {...inputProps} />,
            value,
        };

        if (newStyling) {
            // selectProps.classes.root = classes.select;
            selectProps.style = { minWidth: `${optionWidth}px` };
        }

        return (
            <Select {...selectProps}>
                {getDropdownSelections(selections)}
            </Select>
        );
    };

    return (
        <DisableAnsweringContext.Consumer>
            {(disabledValue) => getDropdown(disabledValue)}
        </DisableAnsweringContext.Consumer>
    );
};

// MatchAnswerForm (not connected)
// -----------------------------------------------------------

const getMatchItems = ({
    value,
    onChange,
    formData,
    classes,
    newStyling,
    optionWidth,
}) => {
    const { selections } = formData;
    const sharedProps = {
        onChange,
        selections,
        classes,
        newStyling,
        optionWidth,
    };

    return formData.options.map((option) => {
        const dropdownProps = {
            value: value[option.id],
            optionId: option.id,
            ...sharedProps,
        };

        const leftCellProps = newStyling ? { align: "right" } : null;
        const rightCellProps = newStyling
            ? { align: "left" }
            : { align: "right" };

        return (
            <TableRow className={classes.noBorder}>
                <TableCell className={classes.noBorder} {...leftCellProps}>
                    <MaterialText className={classes.text}>
                        {option.text}
                    </MaterialText>
                </TableCell>
                <TableCell className={classes.noBorder} {...rightCellProps}>
                    <MatchDropdown {...dropdownProps} />
                </TableCell>
            </TableRow>
        );
    });
};

class MatchAnswerForm extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.optionWidth = 200;

        const { formData } = props;
        if (formData) {
            const { selections } = formData;
            if (selections) {
                const optionLength = selections.reduce((total, selection) => {
                    const { text } = selection;
                    return check.nonEmptyString(text) && text.length > total ? text.length : total;
                }, 0);
                this.optionWidth = getOptionWidthFromLength(optionLength, 200);
            }
        }
    }
    render() {
        const { newStyling } = this.props;

        const tableStyle = newStyling ? { width: "auto" } : null;
        const tableBodyStyle = newStyling
            ? { width: "auto" }
            : { width: "100%" };
        const _props = { optionWidth: this.optionWidth, ...this.props };

        return (
            <Table style={tableStyle}>
                <TableBody style={tableBodyStyle}>
                    {getMatchItems(_props)}
                </TableBody>
            </Table>
        );
    }
}

MatchAnswerForm.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                text: PropTypes.string.isRequired,
            })
        ),
        selections: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                text: PropTypes.string.isRequired,
            })
        ),
    }),
};

// MatchAnswerForm (connected to styles)
// -----------------------------------------------------------

const styles = ({ palette }) => ({
    text: {
        color: palette.background.contrastText,
    },
    inputUnderline: {
        "&:before": {
            borderBottom: `1px solid ${palette.background.contrastText} !important`,
        },
        "&:after": {
            borderBottom: `1px solid ${palette.background.contrastText} !important`,
        },
    },
    answerText: {
        color: palette.primary.main + " !important",
    },
    noBorder: {
        borderBottom: `0px solid black !important`,
    },
    select: {
        width: "200px",
    },
});

MatchAnswerForm = withStyles(styles)(MatchAnswerForm);

const mapStoreToProps = (store) => {
    // const examData = getExamData(store);
    // const examGuid = getGuid(examData);
    // const newStyling = examGuid === "d7d83b01-6b19-48dc-82d5-5816ee25dabe";
    const newStyling = true;
    
    return {
        newStyling,
    };
};

MatchAnswerForm = connect(mapStoreToProps)(MatchAnswerForm);

// EXPORT
// -----------------------------------------------------------
export { MatchAnswerForm };
