// npm

import React, { Component } from "react";

import { connect } from "react-redux";
import { getAppData } from "redux/reducers/selectors";
import { getNetworkErrorInfo } from "redux/reducers/app/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getAppVersion } from "redux/reducers/settings/app/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";

// material-ui
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import { MaterialText } from "components/presentation/material-text";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";

// react
import { Align } from "components/layout/align";
import { AppPageModifier } from "components/pages/app-page-modifier";

import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS, messageNotDefined } from "constants/message-ids";

import { getErrorInfo } from "./error";
import { api } from "libs/api/api";

const displayErrorLine = (line) => {
    if (line[0] === "" && line[1] === "") return "---";

    return `${line[0]}: ${line[1]}`;
};

class NetworkErrorPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggleInfo = this.handleToggleInfo.bind(this);
        this.errorInfo = getErrorInfo(api.errorObj, props.appVersion);
        this.state = { showExtraInfo: false };
    }

    displayNetworkErrorCard(title, subheader) {
        const { classes } = this.props;
        const cardProps = this.hasErrors()?{className: classes.card}:{};

        return (
            <Card {...cardProps}>
                <CardHeader title={title} subheader={subheader} />
                <CardContent>
                    {this.displayError()}
                    {this.displayRetryButton()}
                </CardContent>
            </Card>
        );
    }

    hasErrors() {
        return this.errorInfo && this.errorInfo.length > 0;
    }

    displayError() {
        if (this.hasErrors()) {
            const { showExtraInfo } = this.state;
            const { classes } = this.props;

            const icon = showExtraInfo ? (
                <ExpandLessIcon />
            ) : (
                <ExpandMoreIcon />
            );
            return (
                <React.Fragment>
                    <Paper className={classes.paper} elevation={3}>
                        <div className={classes.extraInfo}>
                            <MaterialText>Additional Information</MaterialText>
                            <div>
                                <IconButton
                                    onClick={this.handleToggleInfo}
                                    aria-label="delete"
                                >
                                    {icon}
                                </IconButton>
                            </div>
                        </div>
                    </Paper>
                    <br />
                    {showExtraInfo ? (
                        <React.Fragment>
                            <Paper className={classes.paper} elevation={3}>
                                <div className={classes.errorInfo}>
                                    {this.errorInfo.map((item) => (
                                        <MaterialText>
                                            {displayErrorLine(item)}
                                        </MaterialText>
                                    ))}
                                </div>
                            </Paper>
                            <br />
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            );
        }
    }

    handleToggleInfo() {
        const { showExtraInfo } = this.state;
        this.setState({ showExtraInfo: !showExtraInfo });
    }

    displayRetryButton() {
        return (
            <Align>
                <Button
                    onClick={() => {
                        window.location.reload(false);
                    }}
                >
                    <RefreshIcon style={{ marginRight: 8 }} fontSize="large" />
                    {"Retry"}
                </Button>
            </Align>
        );
    }

    render() {
        const { messages } = this.props;

        let title = messages[MESSAGE_IDS.ERRORS.NETWORK_ERROR_TITLE];
        let subheader = messages[MESSAGE_IDS.ERRORS.NETWORK_ERROR_SUBHEADER];

        if (messageNotDefined(title)) {
            title = "Network Error.";
        }

        if (messageNotDefined(subheader)) {
            subheader = "Please try again in a few moments.";
        }

        const alignProps = this.hasErrors() ? { top: true } : {};

        return (
            <React.Fragment>
                <AppPageModifier id="Network-error" appBarProps={{ title }} />
                <Align {...alignProps}>
                    {this.displayNetworkErrorCard(title, subheader)}
                </Align>
            </React.Fragment>
        );
    }
}

const styles = ({ spacing }) => ({
    card: {
        marginTop: spacing.unit * 4,
        width: "50%",
    },
    extraInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    paper: {
        padding: spacing.unit * 2,
    },
    errorInfo: {
        maxHeight: "50vh",
        overflowY: "scroll",
    },
});

NetworkErrorPage = withStyles(styles)(NetworkErrorPage);

NetworkErrorPage = withMessages(NetworkErrorPage);

const mapStoreToProps = (store) => {
    const appData = getAppData(store);
    const appSettingsData = getAppSettingsData(getSettingsData(store));

    return {
        networkErrorInfo: getNetworkErrorInfo(appData),
        appVersion: getAppVersion(appSettingsData),
    };
};

NetworkErrorPage = connect(mapStoreToProps)(NetworkErrorPage);

export { NetworkErrorPage };
