
// npm
import {connect} from 'react-redux'

// react
import {MessageChecker} from '../checker'

// redux (selectors)
import {getMessagesData} from 'redux/reducers/selectors'
import {getGeneralMessageData} from 'redux/reducers/messages/selectors'
import {getMessageById} from 'redux/reducers/messages/general/selectors'

// constants
import {getGeneralMessageIds} from 'constants/message-ids'


const mapStoreToProps = (store) => ({
	messageIds: getGeneralMessageIds(),
	getMessage: getMessageById(getGeneralMessageData(getMessagesData(store)))
});

const GeneralMessageChecker = connect(mapStoreToProps)(MessageChecker);


// Export
// ------------------------------------------------------
export {GeneralMessageChecker}