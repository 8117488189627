// utils
import { api } from '../api';

// constants
import { ENDPOINTS, RETRY_COUNT } from '../constants';


const getFiles = (formRunGUID) => {
  return api.get(ENDPOINTS.UPLOAD.FILES + '/' + formRunGUID, {});
}

const uploadFile = (formRunGUID, file, progressListener = null) => {
  const payload = new FormData();
  payload.append('formRunGUID', formRunGUID);
  payload.append('file', file);

  return api.post(
    ENDPOINTS.UPLOAD.UPLOAD,
    payload,
    RETRY_COUNT,
    progressListener
  );
};

const deleteFile = (guid) =>{
  return api.delete(ENDPOINTS.UPLOAD.DELETE + '/' + guid, {});
}

const downloadFile = (guid) => {
  return api.download(`${ENDPOINTS.UPLOAD.DOWNLOAD}/${guid}`, {});
}


const uploadApi = {
  getFiles,
  uploadFile,
  deleteFile,
  downloadFile
}

export {uploadApi}