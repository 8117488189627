// npm
import React from "react";

// xams-components
import {
    StateMachine,
    StateView,
    StateControl,
} from "temp/xams.UI.Components/machine";

// react
import { CanStartChecker } from "components/pages/_exam/initializing/validating_schedule/can-start-checker";
import { ScheduleCompletedPopup } from "components/pages/_exam/initializing/validating_schedule/completed-popup";
import { ScheduleCantStartPopup } from "components/pages/_exam/initializing/validating_schedule/cant-start-popup";
import { ScheduleCompletionChecker } from "components/pages/_exam/initializing/validating_schedule/completion-checker";
import { RedirectToProctorio } from "./redirect-to-proctorio";
import { CantRedirectPopup } from "./cant-redirect-popup";

// machines
import { proctorioRedirect } from "machines/proctorio-redirect";

const { EVENTS, STATES } = proctorioRedirect;

const RedirectProctorioMachine = ({
    examGuid,
    proctorProvider,
    proctoringSessionID,
}) => {
    return (
        <StateMachine machine={proctorioRedirect.machine}>
            <StateView>
                {{
                    [STATES.CHECKING_SCHEDULE_COMPLETION]: () => (
                        <StateControl
                            onComplete={EVENTS.SCHEDULE_COMPLETE}
                            onIncomplete={EVENTS.SCHEDULE_INCOMPLETE}
                        >
                            {(props) => (
                                <ScheduleCompletionChecker
                                    examGuid={examGuid}
                                    {...props}
                                />
                            )}
                        </StateControl>
                    ),
                    [STATES.SCHEDULE_ALREADY_COMPLETED]: () => (
                        <ScheduleCompletedPopup />
                    ),
                    [STATES.CHECKING_SCHEDULE_CAN_START]: () => (
                        <StateControl
                            onSuccess={EVENTS.SCHEDULE_CAN_START}
                            onFail={EVENTS.SCHEDULE_CANT_START}
                            onError={EVENTS.ERROR}
                        >
                            {(props) => (
                                <CanStartChecker
                                    examGuid={examGuid}
                                    {...props}
                                />
                            )}
                        </StateControl>
                    ),
                    [STATES.SCHEDULE_CANT_START]: () => (
                        <ScheduleCantStartPopup examGuid={examGuid} />
                    ),
                    [STATES.REDIRECT_TO_PROCTORIO]: () => (
                        <StateControl onError={EVENTS.ERROR}>
                            {(props) => (
                                <RedirectToProctorio
                                    examGuid={examGuid}
                                    proctorProvider={proctorProvider}
                                    proctoringSessionID={proctoringSessionID}
                                    {...props}
                                />
                            )}
                        </StateControl>
                    ),
                    [STATES.CANT_REDIRECT]: () => <CantRedirectPopup />,
                }}
            </StateView>
        </StateMachine>
    );
};

export { RedirectProctorioMachine };

// http://localhost:3000/proctorio/sfjuk/benanderson/5v!b&pm+/2141e118-6d3a-430f-8079-3e766b650f16
