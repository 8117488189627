
const getSelectedOptions = (optionsData) => (
	optionsData.filter(x => x.get('selected'))
);

const getOptionTextById = (optionsData) => (id) =>
{
	const option = optionsData.find(x => x.get('id') === id);
	return option.get('text');
}


export {getSelectedOptions, getOptionTextById}