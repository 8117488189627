
// redux (action-types)
import * as ACTION_TYPES from './action-types'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


// Note: 'state' should always be initialized at this point (via QUESTION_REDUCER)
const ASCX_QUESTION_REDUCER = (state, action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			return state.withMutations(mutableState => {
				const {data} = action;
				const ascxProperties = data['PROPERTIES'].ASCXPROPERTY;

				mutableState.set('controlName', data['@ControlName']);
				mutableState.set('resumedAnswer', data['ANSWER']);
				mutableState.set('properties', ascxProperties.map(property => ({
					name: property['@PropertyName'],
					propType: property['@PropertyType'],
					value: property['@PropertyValue'],
					default: property['@DefaultValue']
				})));
			});
		case ACTION_TYPES.SET_ASCX_HTML:
			return state.set('html', action.html);
		default:
			return state;
	}
}


export {ASCX_QUESTION_REDUCER}