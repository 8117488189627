
// @xams-utils
import {check} from '@xams-utils/check-types'

// @xams-entities
import {VersionRange} from '@xams-entities/version-range'
import {Version} from '@xams-entities/version'


const checkVersionSupport = (version, supportedVersion) =>
{
  let include = false;
  let exclude = false;

  if (check.assigned(supportedVersion.include)) {
    include = checkVersionsSupported(version, supportedVersion.include);
  }
  if (check.assigned(supportedVersion.exclude)) {
    exclude = checkVersionsSupported(version, supportedVersion.exclude);
  }

  return include && !exclude;
};

const checkVersionsSupported = (version, supportedVersions) =>
{
  return supportedVersions.some(supportedVersion => {
    return isVersionSupported(version, supportedVersion);
  });
};


const isVersionSupported = (version, supportedVersion) =>
{
  if (Version.is(supportedVersion)) {
    return Version.equals(supportedVersion, version);        
  }
  else if (VersionRange.is(supportedVersion)) {
    return VersionRange.contains(supportedVersion, version);        
  }
  return false;
};


export {checkVersionSupport}