
// npm
import React from 'react'

// xams-components
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {ExamClearer} from './exam-clearer'
import {InitializingView} from './initializing/view'
import {ExamInitializedPage} from './initialized/page'
import {SetNetworkError} from 'components/pages/network_error/set-network-error'

// machines
import {examInit} from 'machines/exam-init'


const {EVENTS, STATES} = examInit;


const ExamInitializationMachine = ({examGuid}) =>
{
	return (
		<StateMachine machine={examInit.machine}>
			<StateView>
				{{
					[STATES.CLEARING_PREVIOUS_EXAM]: () => (
						<StateControl onClear={EVENTS.PREVIOUS_EXAM_CLEARED}>
							{({onClear}) => <ExamClearer onClear={onClear}/>}
						</StateControl>
					),
					[STATES.INITIALIZING]: () => (
						InitializingView(examGuid)
					),
					[STATES.NETWORK_ERROR]: () => (
						<SetNetworkError/>
					),
					[STATES.INITIALIZED]: () => (
						<ExamInitializedPage/>
					)
				}}
			</StateView>
		</StateMachine>
	)
}


export {ExamInitializationMachine}