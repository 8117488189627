// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {MaterialText} from 'components/presentation/material-text'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getQuestionsData} from 'redux/reducers/exam/content/selectors'
import {getQuestionDataById} from 'redux/reducers/exam/content/questions/selectors'
import {getEvidenceText} from 'redux/reducers/exam/content/questions/question/ict/selectors.js'


// EvidenceText (not connected)
// ----------------------------------------

let EvidenceText = ({text, classes}) =>
{
  if (!text) { return null; }

  return (
    <React.Fragment>
      <MaterialText className={classes.text}>{text}</MaterialText>
      <Divider className={classes.divider}/>
    </React.Fragment>
  );
};

EvidenceText.propTypes = {
  text: PropTypes.string
}


// EvidenceText (connected to store)
// ----------------------------------------

const mapStoreToProps = (store, {questionId}) => {
  const contentData = getContentData(getExamData(store));
  const questionsData = getQuestionsData(contentData);
  const questionData = getQuestionDataById(questionsData)(questionId);
  return {text: getEvidenceText(questionData)};
};

EvidenceText = connect(mapStoreToProps)(EvidenceText);


// EvidenceText (connected to styles)
// ----------------------------------------

const styles = ({palette, spacing}) => ({
  text: {
    color: palette.background.contrastText
  },
  divider: {
  	marginTop: spacing.unit,
	 marginBottom: spacing.unit
  }
})

EvidenceText = withStyles(styles)(EvidenceText);

EvidenceText.propTypes = {
  questionId: PropTypes.number.isRequired
}


// Export
// ----------------------------------------
export {EvidenceText}