
// npm
import * as Immutable from 'immutable'

// custom
import {getProp} from 'custom/object-helper'

// redux
import {extractMessage} from 'redux/reducers/exam/helpers'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'


const OPTION_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			return state.withMutations(mutableState => {
				mutableState.set('id', action.data['@ID']);
				mutableState.set('marks', action.data['@Score']);
				mutableState.set('text', extractMessage(getProp(action, 'data.TEXT')));
				mutableState.set('selectedId', getProp(action, 'data.@SelectedOptionID'));
			});
		default:
			return state;
	}
}


export {OPTION_REDUCER}