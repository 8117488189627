// npm
import React from "react";
import PropTypes from "prop-types";

// material-ui
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// react
import { withMessages } from "components/hocs/messages";
import { ActionButton } from "components/layout/action_button/button";

// other
import { MESSAGE_IDS } from "constants/message-ids";

const startMessageId = MESSAGE_IDS.GENERAL.START;
const resumeMessageId = MESSAGE_IDS.GENERAL.RESUME;

let StartExamButton = ({ onClick, messages, resume, disabled }) => {
    const messageId = resume ? resumeMessageId : startMessageId;

    return (
        <ActionButton
            Icon={ArrowForwardIcon}
            alignment={"right"}
            onClick={onClick}
            disabled={disabled}
        >
            {messages[messageId]}
        </ActionButton>
    );
};

StartExamButton.propTypes = {
    messages: PropTypes.shape({
        [startMessageId]: PropTypes.string.isRequired,
    }),
    onClick: PropTypes.func.isRequired,
};

StartExamButton = withMessages(StartExamButton);

export { StartExamButton };
