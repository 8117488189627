
// npm
import {List as ImmutableList} from 'immutable'

// redux
import {SELECTION_REDUCER} from './selection/reducer'
import {SET_EXAM_DATA} from 'redux/reducers/exam/action-types'

// custom
import {shuffle} from 'custom/array-helper'


const createRandomizedSelections = (state, {type, data}) =>
{
	data = shuffle(data);
	data.forEach(selectionData => {
		const action = {type, data: selectionData};
		state = state.push(SELECTION_REDUCER(undefined, action));
	})
	return state;
}

const SELECTIONS_REDUCER = (state=ImmutableList(), action) =>
{
	switch (action.type)
	{
		case SET_EXAM_DATA:
			return createRandomizedSelections(state, action);
		default:
			return state;
	}
}


export {SELECTIONS_REDUCER}