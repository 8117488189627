
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// Redux
import {getAppSettingsData} from 'redux/reducers/settings/selectors'
import {getSettingsData} from 'redux/reducers/selectors'
import {getSupportedBrowsers} from 'redux/reducers/settings/app/selectors'

// React
import {checkBrowserSupport} from './check-browser-support'


class BrowserChecker extends React.Component
{
  componentDidMount()
  {
    if (this.isCurrentBrowserSupported()) {
      this.props.onSupported();
    }
    else {
      this.props.onNotSupported();
    }
  }

  isCurrentBrowserSupported()
  {
    const {supportedBrowsers} = this.props;

    return checkBrowserSupport(supportedBrowsers);
  };

  render()
  {
    return null;
  }
}


BrowserChecker.propTypes = {
  supportedBrowsers: PropTypes.object.isRequired,
  onSupported: PropTypes.func.isRequired,
  onNotSupported: PropTypes.func.isRequired
};


const mapStoreToProps = store =>
{
  const appSettingsData = getAppSettingsData(getSettingsData(store));
  return {
    supportedBrowsers: getSupportedBrowsers(appSettingsData)
  };
};

BrowserChecker = connect(mapStoreToProps)(BrowserChecker);


export {BrowserChecker}