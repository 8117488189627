
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

// react
import {ExamRouter} from './router'
import {AppPageModifier} from 'components/pages/app-page-modifier'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getName} from 'redux/reducers/exam/selectors'


// ExamInitializedPage (not connected to store)
// --------------------------------------------------------

let ExamInitializedPage = ({examName, location}) =>
{
	return (
		<React.Fragment>
			<AppPageModifier appBarProps={{title: examName, logo: true}}/>
			<ExamRouter location={location}/>
		</React.Fragment>
	)
}

ExamInitializedPage.propTypes = {
	examName: PropTypes.string.isRequired
}


// ExamInitializedPage (connected to store)
// --------------------------------------------------------

const mapStoreToProps = (store) => ({
	examName: getName(getExamData(store))
});

ExamInitializedPage = connect(mapStoreToProps)(ExamInitializedPage);
ExamInitializedPage = withRouter(ExamInitializedPage);


// Export
// --------------------------------------------------------
export {ExamInitializedPage}