import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import {
    getCurrentBrowser,
    getBrowserName,
    populateMessage,
    getSupportedVersionText,
    getOtherSupportedBrowsers,
    displayVersion,
} from "./pdf-browser-support";

import { TextPanel } from "../../../../exam/_presentation/text-panel";
import { PdfCheckButtons } from "./pdf-check-buttons";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";
import { Align } from "components/layout/align";
import { PdfCheckBrowserIcon } from "./pdf-check-browser-icon";

const styles = ({ palette, spacing }) => ({
    support: {
        display: "flex",
        justifyContent: "flex-start",
        paddingTop: spacing.unit,
        paddingBottom: spacing.unit,
        "&>div:first-child": {
            paddingRight: spacing.unit,
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
});

class PdfCheckPanel extends Component {
    constructor(props) {
        super(props);

        this.currentBrowser = getCurrentBrowser();
    }

    displayButtons(options) {
        const { proctoringMode, onContinue, messages } = this.props;
        const buttonProps = { proctoringMode, onContinue, messages, ...options };
        
        return <PdfCheckButtons {...buttonProps} />;
    }

    displayCurrentBrowser() {
        const { name, version, os } = this.currentBrowser;
        const text = (
            <div>
                <div>Name : {name}</div>
                <div>Version : {version}</div>
                <div>OS : {os}</div>
                <br />
                <div>User Agent</div>
                <div>{navigator.userAgent}</div>
                <br />
                {this.displayButtons({canContinue: true})}
            </div>
        );
        return <TextPanel text={text} />;
    }

    displayBrowserSupport() {
        const { browserSupport } = this.props;
        const { versionNotSupported, notSupported } =
            browserSupport;

        if (check.assigned(versionNotSupported)) {
            return this.displayBrowserVersionNotSupported(
                versionNotSupported
            );
        } else if (check.assigned(notSupported)) {
            return this.displayBrowserNotSupported();
        }
    }

    displayBrowserVersionNotSupported(supportedBrowser) {
        const { classes, messages } = this.props;
        const { version } = this.currentBrowser;
        const displayName = getBrowserName(supportedBrowser);
        const supportedVersionText = getSupportedVersionText(supportedBrowser);
        const iconProps={browser: supportedBrowser}

        const text = (
            <div>
                <div className={classes.support}>
                    <div><PdfCheckBrowserIcon {...iconProps}/></div>
                    <div>
                        {populateMessage(messages.supported, [
                            ["version", displayVersion(version)],
                            ["browser", displayName],
                            ["supported", supportedVersionText],
                        ])}
                    </div>
                </div>
                {this.displayOtherSupportedBrowsers()}
                <br />
                {this.displayButtons()}
            </div>
        );

        return <TextPanel text={text} />;
    }

    displayBrowserNotSupported() {
        const { classes, messages } = this.props;
        const { version } = this.currentBrowser;
        const browser={...this.currentBrowser};
        const iconProps={browser};

        const displayName = getBrowserName(browser);

        const text = (
            <div>
                <div className={classes.support}>
                    <div><PdfCheckBrowserIcon {...iconProps} /></div>
                    <div>
                        {populateMessage(messages.notSupported, [
                            ["version", displayVersion(version)],
                            ["browser", displayName],
                        ])}
                    </div>
                </div>
                {this.displayOtherSupportedBrowsers()}
                <br />
                {this.displayButtons()}
            </div>
        );

        return <TextPanel text={text} />;
    }

    displayOtherSupportedBrowsers() {
        const { os } = this.currentBrowser;
        const { supportedBrowsers, browserSupport, messages } = this.props;

        if (!check.nonEmptyString(messages.otherSupported)) {
            return null;
        }

        const { versionNotSupported } = browserSupport;

        const otherSupportedBrowsers = getOtherSupportedBrowsers(
            supportedBrowsers,
            check.nonEmptyObject(versionNotSupported)
                ? versionNotSupported
                : null,
            os
        );

        if (!check.nonEmptyArray(otherSupportedBrowsers)) {
            return null;
        }
        return (
            <div>
                <div>{messages.otherSupported}</div>
                <List>
                    {otherSupportedBrowsers.map((browser) => {
                        const props = {
                            primary: getBrowserName(browser),
                            secondary: getSupportedVersionText(browser),
                        };
                        const iconProps={browser};
                        return (
                            <ListItem>
                                <Avatar><PdfCheckBrowserIcon {...iconProps}/></Avatar>
                                <ListItemText {...props} />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        );
    }

    render() {
        const { browserSupport, classes } = this.props;
        if (browserSupport.supported) {
            return <Align top>{this.displayCurrentBrowser()}</Align>;
        }
        return (
            <Align top>
                <div className={classes.column}>
                    {/* {this.displayCurrentBrowser()} */}
                    {this.displayBrowserSupport()}
                </div>
            </Align>
        );
    }
}

PdfCheckPanel = withStyles(styles)(PdfCheckPanel);

export { PdfCheckPanel };
