// Use TEMP to log specific things temporarily
// Make sure to convert to a different log_type when you're done!
const LOG_TYPES = {
	IMPORTANT: 0,
	UNIMPORTANT: 1,
	TEMP: 2
}

const MODES = {
	IMPORTANT_ONLY: 0,
	UNIMPORTANT_ONLY: 1,
	TEMP_ONLY: 2,
	DISABLED: 3,
	ALL: 4
}

// -----------------------------

const environment = MODES.TEMP_ONLY;


const shouldLog = function(type)
{
	switch (environment)
	{
		case MODES.DISABLED:
			return false;
		case MODES.ALL:
			return true;
		case MODES.IMPORTANT_ONLY:
			return type === LOG_TYPES.IMPORTANT;
		case MODES.UNIMPORTANT_ONLY:
			return type === LOG_TYPES.UNIMPORTANT;
		case MODES.TEMP_ONLY:
			return type === LOG_TYPES.TEMP;
		default:
			return false;
	}
}

const log = function(toLog, type)
{
	if (shouldLog(type)) { console.log(toLog); }
}

const dir = function(toLog, type)
{
	if (shouldLog(type)) { console.dir(toLog); }
}

const error = function(toLog, type)
{
	if (shouldLog(type)) { console.error(...arguments); }
}

const exception = function(toLog, type)
{
	if (shouldLog(type)) { console.error(...arguments); }
}


const Console = {log, dir, error, exception};
Object.freeze(Console);


export {Console, LOG_TYPES}