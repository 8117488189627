// npm
import {Map as ImmutableMap} from 'immutable'

// xams-utils
import {check} from '@xams-utils/check-types'

// redux (action-types)
import * as ACTION_TYPES from './action-types'

// utils
import {KEYS, sessionStorageApi} from 'libs/browser_storage/session-storage-api'
import {COOKIE_NAMES, cookies} from 'utils/cookies'


const handleSessionCreation = (action) =>
{
	const data = {
		id: action.orgId,
		urlToken: action.urlToken
	};

	sessionStorageApi.saveDataTo(KEYS.CLIENT_SESSION, data);
	return ImmutableMap(data);
}

const handleSessionRemoval = () =>
{
	sessionStorageApi.resetDataFor(KEYS.CLIENT_SESSION);
	return ImmutableMap();
}

const loadFromQueryString = () =>
{
	const urlParameters = new URLSearchParams(window.location.search);
	const externalSessionData = urlParameters.get('x');
	if (!externalSessionData) { return; }

	const parsedSessionData = JSON.parse(externalSessionData);
	const data = parsedSessionData.client;
	sessionStorageApi.saveDataTo(KEYS.CLIENT_SESSION, data);

	return data;
}

const handleSessionLoad = () =>
{
	const data = loadFromQueryString() || sessionStorageApi.retrieveDataFrom(KEYS.CLIENT_SESSION);
	return ImmutableMap(data);
}


const CLIENT_REDUCER = (state=ImmutableMap(), action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.LOAD_SESSION:
			return handleSessionLoad();
		case ACTION_TYPES.CREATE_SESSION:
			return handleSessionCreation(action);
		case ACTION_TYPES.REMOVE_SESSION:
			return handleSessionRemoval();
		default:
			return state;
	}
}

export {CLIENT_REDUCER}