
// redux (action-types)
import {ADD_SCHEDULE, CLEAR_SCHEDULES} from 'redux/reducers/schedules/action-types'
import {ADD_ELEARNING, CLEAR_ELEARNING} from 'redux/reducers/elearning/action-types'


const addSchedule = (payload) => ({
	type: ADD_SCHEDULE,
	...payload
})

const addElearning = (payload) => ({
	type: ADD_ELEARNING,
	...payload
})

const clearSchedules = () => ({
	type: CLEAR_SCHEDULES
});

const clearElearning = () => ({
	type: CLEAR_ELEARNING
});


export {clearSchedules, addSchedule, addElearning, clearElearning}