import React from 'react';
import PDFJSBackend from "./pdf-js-backend";

class PdfJsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.viewerRef = React.createRef();
    this.backend = new PDFJSBackend();
  }

  componentDidMount() {
    const { resourceUrl } = this.props;
    const element = this.viewerRef.current;

    this.backend.init(resourceUrl, element);
  }
  

  render() {
    const { maxPageWidth } = this.props;

    return (
      <div ref={this.viewerRef} id='viewer' style={{ width: `${maxPageWidth-12}px`, height: '100%'}}>

      </div>
    )
  }
}

export {PdfJsPanel}