
// @Note:
// Token modifications must always be synced between redux store, api, and 
// session storage. To make this synchronization fuss-free, our token reducer
// handles that from a single location. This means that we need only dispatch 
// an action to redux to modify the tokens, and the synchronization gets taken
// care of automagically. For this very reason, we must use a react component
// to handle token refreshes.


// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux
import {SAVE_TOKENS} from 'redux/reducers/session/tokens/action-types'

// utils
import {api} from 'libs/api/api'
import {GENERIC_ERRORS} from 'libs/api/constants'
import {authenticationApi} from 'libs/api/interface/api-authentication'

// redux (actions)
const saveTokens = (access, refresh) => ({
	type: SAVE_TOKENS,
	refresh,
	access
});


// TokenRefresher (not connected to the store)
// ----------------------------------------------------------------------------

class TokenRefresher extends React.Component
{
	constructor(props)
	{
		super(props);

		const unauthorizedError = GENERIC_ERRORS.UNAUTHORIZED;

		this.unsubscribeFromApi = api.addGenericErrorHandler(unauthorizedError, () => {
			return authenticationApi.refreshApiToken().then(this.props.saveTokens);
		})
	}

	render()
	{
		return null;
	}

	componentWillUnmount()
	{
		this.unsubscribeFromApi();
	}
}

TokenRefresher.propTypes = {
	saveTokens: PropTypes.func.isRequired
}


// TokenRefresher (connected to the store)
// ----------------------------------------------------------------------------

const mapDispatchToProps = (dispatch) => ({
	saveTokens: (tokenData) => {
		const {access_token, refresh_token} = JSON.parse(tokenData);
		dispatch(saveTokens(access_token, refresh_token));
	}
});

TokenRefresher = connect(undefined, mapDispatchToProps)(TokenRefresher);


// EXPORT
// ----------------------------------------------------------------------------
export {TokenRefresher}