import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CommonDialogOption from './common-dialog-option';

class CommonDialog extends Component {
  handleSelect = index => {
    this.props.onSelect(this.props.options[index], index, this.props.data);
  };
  render() {
    let { title, content, options } = this.props;
    if (options === undefined) options = [];
    if (content === undefined) content = '';
    if (title === undefined) title = '';
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleNotConfirmed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {options.map((option, index) => (
            <CommonDialogOption
              key={index}
              index={index}
              text={option}
              onSelect={this.handleSelect}
            />
          ))}
        </DialogActions>
      </Dialog>
    );
  }
}

export default CommonDialog;
