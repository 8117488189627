// internal
import * as ACTION_TYPES from './action-types'


const THEME_REDUCER = (state='Default', action) =>
{
	switch (action.type)
	{
		case ACTION_TYPES.SET_THEME:
			return (state = action.value);
		default:
			return state;
	}
}

export {THEME_REDUCER}