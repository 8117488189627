
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {DrawerIconButton} from './bar/drawer-icon-button'

// constants
import {contentBarHeight} from '../constants'


// ContentBar (not connected to styles)
// ---------------------------------------------------

let ContentBar = (props) =>
{
	const {left, right} = props;

	return (
		<AppBar className={props.classes.root} elevation={0}>
			<Toolbar>
				{left && <DrawerIconButton {...left}/>}
				<div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
					{props.children}
				</div>
				{right && <DrawerIconButton {...right}/>}
			</Toolbar>
		</AppBar>
	)
}

ContentBar.propTypes = {
	left: PropTypes.shape({
		Icon: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.instanceOf(React.Component)
		]).isRequired,
		open: PropTypes.bool.isRequired,
		onClick: PropTypes.func.isRequired
	}),
	right: PropTypes.shape({
		Icon: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.instanceOf(React.Component)
		]).isRequired,
		open: PropTypes.bool.isRequired,
		onClick: PropTypes.func.isRequired
	})
}


// ContentBar (connected to styles)
// ---------------------------------------------------

const styles = ({palette}) =>
{
	return {
		root: {
			position: 'relative',
			height: contentBarHeight,
			backgroundColor: palette.background.light
		}
	}
}

ContentBar = withStyles(styles)(ContentBar);


// ContentBar (not connected to styles)
// ---------------------------------------------------
export {ContentBar}