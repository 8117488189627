
import {SET_CURRENT_QUESTION_ID} from 'redux/reducers/exam/content/action-types'
import {SET_PUBLIC_EXAM_USER_NAME} from 'redux/reducers/session/user/action-types'


const setCurrentQuestionId = (value) => ({
	type: SET_CURRENT_QUESTION_ID,
	value
});

const setPublicExamUserName = (value) => ({
	type: SET_PUBLIC_EXAM_USER_NAME,
	value
});


export {setCurrentQuestionId, setPublicExamUserName}