// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// redux (selectors)
import { getSessionData } from "redux/reducers/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getUrlToken } from "redux/reducers/session/client/selectors";

// LoginUrlInitializer (not connected to store)
// ----------------------------------------------

class LoginUrlInitializer extends React.Component {
    componentDidMount() {
        this.props.onFinish();
    }

    render() {
        const { pathname } = window.location;
        const page =
            pathname.indexOf("login") !== -1 ||
            pathname.indexOf("user") !== -1 ||
            pathname.indexOf("forgot") !== -1 ||
			pathname.indexOf("proctorio") !== -1 ||
            pathname.indexOf("reset") !== -1
                ? pathname
                : "/login";

        return <Redirect to={`${page}?${this.props.urlToken}`} />;
    }
}

LoginUrlInitializer.propTypes = {
    urlToken: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
};

// LoginUrlInitializer (connected to store)
// ----------------------------------------------

const mapStoreToProps = (store) => ({
    urlToken: getUrlToken(getClientSessionData(getSessionData(store))),
});

LoginUrlInitializer = connect(mapStoreToProps)(LoginUrlInitializer);

export { LoginUrlInitializer };
