
// npm
import React from 'react'

// xams-components
import {StateMachine, StateView, StateControl} from 'temp/xams.UI.Components/machine'

// react
import {SessionInitializer} from 'components/pages/app_initialization/initializing/initializing_client/session-initializer'
// import {LoadClient} from './load-client'
import {AuthenicateUser} from './authenicate-user'
import {LoadSchedule} from './load-schedule'
import {LoadingFailed} from './loading-failed'
import {RedirectToExam} from './redirect-to-exam'
import {ClientSettingsFetcher} from 'components/pages/client-settings-fetcher'
import {InitializingSessionMessagesPage} from 'components/pages/login/initializing-session-messages-page'
import {ProctorioParentChecker} from './check-parent'

// machines
import {proctorio} from 'machines/proctorio'

const {EVENTS, STATES} = proctorio;


const ProctorioMachine = ({userInfo1, userInfo2, examGuid}) =>
{
	return (
		<StateMachine machine={proctorio.machine}>
			<StateView>
				{{
					[STATES.AUTHENICATING_USER]: () => (
						<StateControl onSuccess={EVENTS.USER_AUTHENICATED} onFail={EVENTS.LOAD_FAILURE}>
							{(props) => <AuthenicateUser userInfo1={userInfo1} userInfo2={userInfo2} {...props} />}
						</StateControl>
					),
					[STATES.INITIALIZING_SESSION_MESSAGES]: () => (
						<StateControl onLoad={EVENTS.SESSION_MESSAGES_INITIALIZED} onError={EVENTS.LOAD_FAILURE}>
							{(props) => <InitializingSessionMessagesPage {...props}/>}
						</StateControl>
					),					                    
					[STATES.FETCHING_CLIENT_SETTINGS]: () => (
						<StateControl onSuccess={EVENTS.CLIENT_SETTINGS_FETCHED} onError={EVENTS.LOAD_FAILURE}>
							{(props) => <ClientSettingsFetcher {...props}/>}
						</StateControl>
					),
					[STATES.CHECKING_PROCTORIO_PARENT]: () => (
						<StateControl onSuccess={EVENTS.PROCTORIO_PARENT_CHECKED} onError={EVENTS.LOAD_FAILURE}>
							{(props) => <ProctorioParentChecker examGuid={examGuid} {...props}/>}
						</StateControl>
					),					                    					
					[STATES.REDIRECT_TO_EXAM]: () => (
						<RedirectToExam examGuid={examGuid}/>
					),
					[STATES.LOADING_FAILED]: () => (
						<LoadingFailed />
					),
					[STATES.INITIALIZING_SESSION_MESSAGES_FAILED]: () => (
						<LoadingFailed message="Session Messages Failed To Load"/>
					),											                    
					[STATES.FETCHING_CLIENT_SETTINGS_FAILED]: () => (
						<LoadingFailed message="Client Settings Failed To Load"/>
					),											          					
				}}
			</StateView>
		</StateMachine>
	)
}

export {ProctorioMachine}

// http://localhost:3000/proctorio/coelrind/1/2/3
//  http://localhost:3000/proctorio/coelrind/indianajones/Yami9336/3ef645e8-ac54-4ffe-9036-637202c8dadf