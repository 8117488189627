
// npm
import check from 'check-types'
import {Map as ImmutableMap} from 'immutable'

// redux
import {SECTION_REDUCER} from './section/reducer'
import * as EXAM_ACTION_TYPES from '../../action-types'														// explicitly using EXAM_ACTION_TYPES to make clear it is not a 'sections' action type
import * as SECTION_ACTION_TYPES from './section/action-types'

// custom
import {getProp} from 'custom/object-helper'


// Note: state is mutable here
const populateSections = (state, taskGroups) =>
{
	if (!check.nonEmptyArray(taskGroups)) { return; }
	const actionType = EXAM_ACTION_TYPES.SET_EXAM_DATA;

	taskGroups.forEach(taskGroup => {
		const action = {type: actionType, data: taskGroup};
		state.set(taskGroup['@ID'], SECTION_REDUCER(undefined, action));

		const nestedTaskgroups = getProp(taskGroup, 'OBJECTS.TASKGROUP');
		const nestedPaperParts = getProp(taskGroup, 'OBJECTS.PAPERPART');
		populateSections(state, nestedTaskgroups);
		populateSections(state, nestedPaperParts);
	});
}

const SECTIONS_REDUCER = (state=ImmutableMap(), action) =>
{
	if (Object.values(SECTION_ACTION_TYPES).includes(action.type)) {
		const sectionData = state.get(action.id);
		return state.set(action.id, SECTION_REDUCER(sectionData, action));
	}

	switch (action.type)
	{
		case EXAM_ACTION_TYPES.SET_EXAM_DATA:
			return state.withMutations(mutableState => {
				const taskGroups = getProp(action, 'data.EXAM.OBJECTS.TASKGROUP');
				const paperParts = getProp(action, 'data.EXAM.OBJECTS.PAPERPART');
				populateSections(mutableState, taskGroups);
				populateSections(mutableState, paperParts);
			});
		default:
			return state;
	}
}


export {SECTIONS_REDUCER}