import {api} from '../api'
import {ENDPOINTS} from '../constants'


const getAscx = (questionID, controlName, initialData, props) =>
{
	const payload = 
	{
		questionID, controlName, initialData, props
	};
	return api.post(ENDPOINTS.ASCX, payload);
}


export {getAscx}