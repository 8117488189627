// npm
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getSettingsData } from "redux/reducers/selectors";
import { getAppVersion } from "redux/reducers/settings/app/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";

// react
import { LoadingSpinner } from "components/presentation/loading-spinner";
import {detectBrowser} from 'components/pages/app_initialization/initializing/validating_app_settings/browser_checker/detect-browser'
import { activityLogger } from "libs/activity_logger/activity-logger";

class StartActivityLogger extends React.Component {
  componentDidMount() {
    const { activityType, onComplete, appVersion } = this.props;
    const {browser, version} = detectBrowser();
    const payload = {
      appVersion,
      browser, 
      version
    }

    activityLogger.log(activityType, payload);
    onComplete();
  }

  render() {
    return <LoadingSpinner size="lg" />;
  }
}

StartActivityLogger.propTypes = {
  activityType: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

const mapStoreToProps = (store) => {
  const appSettingsData = getAppSettingsData(getSettingsData(store));

  return {
      appVersion: getAppVersion(appSettingsData),
  };
};

StartActivityLogger = connect(mapStoreToProps)(StartActivityLogger);

export { StartActivityLogger };
