
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {withMessages} from 'components/hocs/messages'
import {ContentBarTitle} from 'components/pages/exam/_layout/content/bar/title'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


const examInstructionsMessageId = MESSAGE_IDS.EXAM.INSTRUCTIONS;


let IntroTitle = ({messages}) => (
	<ContentBarTitle>
		{messages[examInstructionsMessageId]}
	</ContentBarTitle>
)

IntroTitle.propTypes = {
	messages: PropTypes.shape({
		[examInstructionsMessageId]: PropTypes.string.isRequired
	})
}


IntroTitle = withMessages(IntroTitle);


export {IntroTitle}