// other modules
import {DBApi} from './db/pouch/pouch-api'


const KEYS = {
	SENT_EXAMS: 'sent_exams',
	STUDENT_ACTIVITY: 'student_activity',
	UPLOAD_FILES: 'upload_files'	
}

const dbStorageApi = new DBApi(Object.values(KEYS));
Object.freeze(dbStorageApi);

export {dbStorageApi, KEYS}