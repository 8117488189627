const CLEAR = '[login][credentials]clear';
const CLEAR_PASSWORD = '[login][credentials]clear.password';
const SET_USERNAME = '[login][credentials]set.username';
const SET_PASSWORD = '[login][credentials]set.password';

export {
	CLEAR,
	CLEAR_PASSWORD,
	SET_USERNAME,
	SET_PASSWORD
}