
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {MaterialText} from 'components/presentation/material-text'


const ScenarioText = ({text, className}) =>
{
	if (!text) { return null; }
	return <MaterialText className={className}>{text}</MaterialText>;
}

ScenarioText.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string.isRequired
}


export {ScenarioText}