// npm
import React from "react";

// react
import { ExamRunningPageMachine } from "./machine";
import { LeaveResumeExam } from "./leave_resume_exam/leave-resume-exam";
import { ExamPauseNotifier } from './pause/exam-pause-notifier'


const ExamRunningPage = ({ match }) => {
    const examGuid = match.params.examGuid;

    return (
        <LeaveResumeExam>
            <ExamPauseNotifier/>
            <ExamRunningPageMachine examGuid={examGuid} />
        </LeaveResumeExam>
    );
};

export { ExamRunningPage };
