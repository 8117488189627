import React, { Component } from "react";
import { check } from "@xams-utils/check-types";

import { getStyle } from "../form-helper";

class FormFile extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleClick() {
        const { enabled } = this.props;
        if (enabled) {
            this.fileInputRef.current.click();
        }
    }

    handleChange(e) {
        const { field } = this.props;

        if (check.nonEmptyString(field)) {
            const { onChange } = this.props;
            const selectedFiles = e.target.files;

            if (selectedFiles && selectedFiles.length > 0) {
                const selectedFile = e.target.files[0];

                onChange(field, selectedFile.name);
            }
        }
    }

    render() {
        const { text, enabled } = this.props;
        const styleObj = getStyle(this.props);
        if (!enabled){
            delete styleObj.cursor;
        }
        const buttonProps = { style: styleObj, onClick: this.handleClick };
        const inputProps = {
            type: "file",
            hidden: true,
            onChange: this.handleChange,
            ref: this.fileInputRef,
        };

        return (
            <React.Fragment>
                <input {...inputProps} />
                <button {...buttonProps}>{text}</button>
            </React.Fragment>
        );
    }
}

export { FormFile };
