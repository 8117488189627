
// npm
import React from 'react'
import PropTypes from 'prop-types'
import partialCircle from 'svg-partial-circle'
import {milliseconds, seconds, minutes} from 'time-convert'


// constants
const MAX_ANGLE = 2 * Math.PI;
const SIZE = 64;
const CENTER = SIZE / 2;
const RADIUS = Math.round(CENTER / 4 * 3);
const ONE_MIN = minutes.to(milliseconds)(1);
const ANGLE_OFFSET = Math.PI / 2;


class TimerUi extends React.Component
{
	render()
	{
		return (
			<svg width={SIZE} height={SIZE}>
				{this.Circle}
				{this.Text}
				{this.ElapsedArc}
			</svg>
		)
	}

	get Circle()
	{
		const dimensionalProps = {cx: CENTER, cy: CENTER, r: RADIUS};
		const colorProps = {stroke: 'gray', fill: this.props.color};
		return <circle {...dimensionalProps} {...colorProps}/>;
	}

	get Text()
	{
		const {time, textColor} = this.props;
		const textValue = time > ONE_MIN ?
			`${Math.ceil(minutes.from(milliseconds)(time))}m` : 
			`${Math.ceil(seconds.from(milliseconds)(time))}s`;

		return (
			<text x={CENTER} y={CENTER} textAnchor='middle' stroke={textColor} dy={5}>
				{textValue}
			</text>
		)
	}

	get ElapsedArc()
	{
		const [startAngle, endAngle] = this.calculateElapsedArcAngles();
		const commands = partialCircle(CENTER, CENTER, RADIUS, startAngle, endAngle);
		if (commands.length === 0) { return null; }

		const arcCurveCommands = commands[1];
		const arcEndPoint = {x: arcCurveCommands[6], y: arcCurveCommands[7]};
		const dPathAttribute = commands.map(command => command.join(' ')).join(' ');

		return (
			<React.Fragment>
				<path stroke='black' strokeWidth='3' fill='transparent' d={dPathAttribute}/>,
				<circle cx={arcEndPoint.x} cy={arcEndPoint.y} r={2} stroke='black' fill='black'/>
			</React.Fragment>
		);
	}

	calculateElapsedArcAngles()
	{
		const {time, startTime, endTime} = this.props;
		const toElapse = Math.abs(endTime - startTime);
		const elapsed = Math.abs(time - startTime);
		const endAngle = (elapsed / toElapse) * MAX_ANGLE;
		return [-ANGLE_OFFSET, endAngle - ANGLE_OFFSET];
	}
}

TimerUi.propTypes = {
	time: PropTypes.number.isRequired,
	endTime: PropTypes.number.isRequired,
	startTime: PropTypes.number.isRequired,
	textColor: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired
}

TimerUi.defaultProps = {
	textColor: 'black',
	color: 'white'
}


export {TimerUi}