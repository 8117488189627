// npm
import React, { Component } from "react";

// material-ui
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// react
import { MaterialText } from "components/presentation/material-text";
import { AudioChip } from "components/functional/audio/audio-chip";

// AudioCheckPanel (not connected to styles)
// --------------------------------------------------------------------

class AudioCheckPanel extends Component {
    state = {};
    render() {
        const { onChange, checked, classes, audio,message,label } = this.props;

        return (
            <Paper className={classes.panel}>
                <div><MaterialText>{message}</MaterialText></div>
                <div>
                    <AudioChip {...audio} />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox checked={checked} onChange={onChange} />
                        }
                        label={label}
                    />
                </div>
            </Paper>
        );
    }
}

const styles = ({ palette, spacing }) => ({
    panel: {
        flexGrow: 1,
        maxWidth: 800,
        boxSizing: "border-box",
        padding: spacing.unit*2,
        margin: spacing.unit*2,
        backgroundColor: palette.background.light,
        "&>div:first-child":{
            marginBottom: spacing.unit*2
        },
        "&>div:last-child":{
            marginTop: spacing.unit,
            marginLeft: spacing.unit
        }        
    },
    text: {
        color: palette.background.contrastText,
    },
});

AudioCheckPanel = withStyles(styles)(AudioCheckPanel);

// Export
// --------------------------------------------------------------------
export { AudioCheckPanel };
