// other modules
import {validateAsObject, validateAsString} from 'utils/type-validator'



const toFormData = (obj) =>
{
	let formData = new FormData();
	for (let prop in obj) { formData.append(encodeURIComponent(prop), encodeURIComponent(obj[prop])); }
	return formData;
}


// ---------------
// @getProp @DOC
// ---------------
// Traverse a nested object's properties and return the desired property value.
// If any of the nested props do not exist, return null instead. 
// Useful if we only want the value if it exists (null if not).
// ---------------
const getProp = (obj, prop) => // prop = a string describing the nested property to get
{
	validateAsObject(obj);
	validateAsString(prop);

	const props = prop.split('.');
	let currentLevel = obj;

	for (var i = 0; i < props.length; i++) 
	{
		const prop = props[i];
		currentLevel = currentLevel.hasOwnProperty(prop) ? currentLevel[prop] : null;
		if (!currentLevel) break;
	}

	return currentLevel;
}


const getKeyWithValue = (obj, value) =>
{
	validateAsObject(obj);
	for (let key in obj) { 
		if (obj[key] === value) { 
			return key;
		} 
	}
}


export {getProp, toFormData, getKeyWithValue};