import React, { Component } from "react";
import { connect } from "react-redux";

import { getSettingsData } from "redux/reducers/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getApiEndpointUrl } from "redux/reducers/settings/app/selectors";

import { getAscxImageUrl } from "utils/get-ascx-image-url";
import {Image} from 'components/presentation/image'
import { getStyle } from "./form-helper";


class FormImage extends Component {
    state = {};
    render() {
        const { src, name, apiEndpointUrl } = this.props;
        const styleObj = getStyle(this.props);
        const altName = `image - ${name}`;
        const imageProps = {
            style: styleObj,
            src: getAscxImageUrl(src, apiEndpointUrl),
            alt: altName
        };

        return (
            <div>
                <Image {...imageProps}/>
                {/* <img {...imageProps} alt={altName}></img> */}
            </div>
        );
    }
}

const mapStoreToProps = (store) => {
 
    const settingsData = getSettingsData(store);
    const appSettingsData = getAppSettingsData(settingsData);
  
    return {
      apiEndpointUrl: getApiEndpointUrl(appSettingsData),
    };
  };
  
  FormImage = connect(mapStoreToProps)(FormImage);

export { FormImage };
