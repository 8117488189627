import React from "react";

import { AppPageModifier } from "components/pages/app-page-modifier";

import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS } from "constants/message-ids";

import { ForgotPassword } from "./forgot-password";

let ForgotPasswordPage = (props) => {
    const title = props.messages[MESSAGE_IDS.FORGOTTEN_PASSWORD.TITLE];

    return (
        <React.Fragment>
            <AppPageModifier appBarProps={{ title, logo: true }} />
            <ForgotPassword />
        </React.Fragment>
    );
};

ForgotPasswordPage = withMessages(ForgotPasswordPage);

export { ForgotPasswordPage };
