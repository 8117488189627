import { check } from '@xams-utils/check-types';
import React, { Component } from 'react';

class FormDelay extends Component {
    state = {  } 

    constructor(props){
        super(props);

        this.handleTimer=this.handleTimer.bind(this);
    }

    handleTimer(){
        const {action, onAction}=this.props;

        onAction(action);
    }

    componentDidMount(){
        const {delay, action}=this.props;
        if (check.nonEmptyString(delay) && check.nonEmptyString(action)){
            const _delay=parseInt(delay);

            if (!isNaN(_delay)){
                this.timer=setTimeout(this.handleTimer, _delay * 1000)
            }
        }
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render() { 
        return null;
    }
}
 
export {FormDelay}