// npm
import React from "react";
import { connect } from "react-redux";
import { check } from "@xams-utils/check-types";

// material-ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";

// react
import { MaterialText } from "components/presentation/material-text";

// redux (selectors)
import { getMessageData } from "redux/reducers/exam/selectors";
import { getOutroText, getProctoredOutroText } from "redux/reducers/exam/messages/selectors";

import {
    getExamData,
    getSettingsData,
    getSchedulesData,
} from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import {
    getProctoringMode,
    getProctoringType,
} from "redux/reducers/settings/client/selectors";
import { isPracticeExam } from "redux/reducers/exam/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import { getGuid } from "redux/reducers/exam/selectors";
import { getProctorProvider } from "redux/reducers/schedules/schedule/selectors";

// messages
import { withMessages } from "components/hocs/messages";
import { MESSAGE_IDS } from "constants/message-ids";

let OutroTextPanel = (props) => {
    const {
        text,
        proctoredText,
        classes,
        messages,
        proctoringMode,
        practiceExam,
        proctoringProvider,
    } = props;

    return (
        <Card className={classes.panel}>
            <CardContent>
                <MaterialText className={classes.text}>
                    {getText(
                        text,
                        proctoredText,
                        proctoringMode,
                        messages,
                        practiceExam,
                        proctoringProvider
                    )}
                </MaterialText>
            </CardContent>
        </Card>
    );
};

const getText = (
    text,
    proctoredText,
    proctoringMode,
    messages,
    practiceExam,
    proctoringProvider
) => {
    if (proctoringMode === "1" || proctoringMode === "2") {
        if (check.nonEmptyString(proctoredText)){
            return proctoredText;
        }

        const mode = practiceExam
            ? "PRACTICE"
            : proctoringMode === "1"
            ? "SINGLE"
            : "MULTI";

        const proctoringName=proctoringProvider?proctoringProvider.replaceAll(' ','_')+'_':"";
        const messageId =
            `PROCTORING_${proctoringName}EXAM_OUTRO_${mode}`.toUpperCase();

        console.log("proctoringMode", proctoringMode);
        console.log("proctoringProvider", proctoringProvider);
        console.log("proctoringName", proctoringName);
        console.log("practiceExam", practiceExam);
        console.log("mode", mode);
        console.log("messageId", messageId);

        if (
            MESSAGE_IDS.EXAM[messageId] &&
            messages[MESSAGE_IDS.EXAM[messageId]]
        ) {
            console.log(
                "Return",
                MESSAGE_IDS.EXAM[messageId],
                messages[MESSAGE_IDS.EXAM[messageId]]
            );
            return messages[MESSAGE_IDS.EXAM[messageId]];
        }

        console.log("Using Default");
        if (proctoringMode == "1") {
            console.log(
                "Return",
                MESSAGE_IDS.EXAM.PROCTORING_EXAM_OUTRO_SINGLE,
                messages[MESSAGE_IDS.EXAM.PROCTORING_EXAM_OUTRO_SINGLE]
            );
            return messages[MESSAGE_IDS.EXAM.PROCTORING_EXAM_OUTRO_SINGLE];
        } else if (proctoringMode == "2") {
            console.log(
                "Return",
                MESSAGE_IDS.EXAM.PROCTORING_EXAM_OUTRO_MULTI,
                messages[MESSAGE_IDS.EXAM.PROCTORING_EXAM_OUTRO_MULTI]
            );
            return messages[MESSAGE_IDS.EXAM.PROCTORING_EXAM_OUTRO_MULTI];
        }
    }

    console.log("Return", text);

    return text;
};

const mapStoreToProps = (store) => {
    const clientSettingsData = getClientSettingsData(getSettingsData(store));
    const examData = getExamData(store);
    const examGuid = getGuid(examData);
    const schedulesData = getSchedulesData(store);
    const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);
    const messageData=getMessageData(examData);

    return {
        text: getOutroText(messageData),
        proctoredText: getProctoredOutroText(messageData),
        proctoringMode: getProctoringMode(clientSettingsData),
        practiceExam: isPracticeExam(examData),
        proctoringType: getProctoringType(clientSettingsData),
        proctoringProvider: getProctorProvider(scheduleData),
    };
};

OutroTextPanel = connect(mapStoreToProps)(OutroTextPanel);

const styles = ({ palette, spacing, breakpoints }) => ({
    text: {
        color: palette.background.contrastText,
    },
    panel: {
        backgroundColor: palette.background.light,
        //maxWidth: 800,
        [breakpoints.down(840)]: {
            width: "calc(100% - 40px)",
            marginLeft: 20,
            marginRight: 20,
        },
        [breakpoints.up(841)]: {
            width: 800,
        },
        marginTop: spacing.unit * 2,
        alignSelf: "stretch",
    },
});

OutroTextPanel = withMessages(withStyles(styles)(OutroTextPanel));

export { OutroTextPanel };
