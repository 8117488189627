
import {Machine} from 'xstate'
import {XStateConfig} from './xstate-config'


// #########################################
// STATE & EVENT NAMES
// #########################################

const STATES = {
	CHECKING_SET_PASSWORD: 'checking_set_password',
	SET_PASSWORD: 'set_password',
	LOGIN_SET_PASSWORD: 'login_set_password',
	SHOWING_FORM: 'initializing_client',
	CHECKING_ADMIN_REDIRECT: 'checking_admin_redirect',
	ADMIN_REDIRECT_URL: 'admin_redirect_url',
	ADMIN_REDIRECT_IFRAME: 'admin_redirect_iframe',
	INITIALIZING_SESSION_MESSAGES: 'initializing_messages',
	MAINTAINING_ACTIVITY_LOG: 'maintain_activity_log',
	// UPLOADING_OFFLINE_FILES: 'uploading_offline_files',	
	REDIRECTING_TO_RUBRIC: 'preloading_logos',
	NETWORK_ERROR: 'network_error'
}

const EVENTS = {
	ERROR: 'error',
	AUTHENTICATED: 'authenticated',
	PASSWORD_AUTHENTICATED: 'password.authenticated',
	NEED_TO_SET_PASSWORD: 'need.to.set.password',
	NO_NEED_TO_SET_PASSWORD: 'no.need.to.set.password',
	PASSWORD_SET: 'password.set',
	NOT_ADMIN: 'not.admin',
	REDIRECTING_ADMIN_URL: 'redirecting.admin.url',
	REDIRECTING_ADMIN_IFRAME: 'redirecting.admin.iframe',
	MESSAGES_INITIALIZED: 'messages.initialized',
	// OFFLINE_FILES_UPLOADED: 'offline_files_uploaded',
	ACTIVITY_LOG_MAINTAINED: 'activity_log_maintained'	
}


// #########################################
// LOGIN STATES
// #########################################

const checkingSetPasswordToken = new XStateConfig();
checkingSetPasswordToken.addTransition(EVENTS.NEED_TO_SET_PASSWORD, STATES.SET_PASSWORD);
checkingSetPasswordToken.addTransition(EVENTS.NO_NEED_TO_SET_PASSWORD, STATES.SHOWING_FORM);

const setPassword = new XStateConfig();
setPassword.addTransition(EVENTS.PASSWORD_SET, STATES.LOGIN_SET_PASSWORD);
setPassword.addTransition(EVENTS.NO_NEED_TO_SET_PASSWORD, STATES.SHOWING_FORM);

const loginSetPassword = new XStateConfig();
loginSetPassword.addTransition(EVENTS.AUTHENTICATED, STATES.CHECKING_ADMIN_REDIRECT);
loginSetPassword.addTransition(EVENTS.NEED_TO_SET_PASSWORD, STATES.SET_PASSWORD);

const showingForm = new XStateConfig();
showingForm.addTransition(EVENTS.AUTHENTICATED, STATES.CHECKING_ADMIN_REDIRECT);

const checkingAdminRedirect = new XStateConfig();
checkingAdminRedirect.addTransition(EVENTS.NOT_ADMIN, STATES.INITIALIZING_SESSION_MESSAGES);
checkingAdminRedirect.addTransition(EVENTS.REDIRECTING_ADMIN_URL, STATES.ADMIN_REDIRECT_URL);
checkingAdminRedirect.addTransition(EVENTS.REDIRECTING_ADMIN_IFRAME, STATES.ADMIN_REDIRECT_IFRAME);

const adminRedirectUrl = new XStateConfig();
const adminRedirectIframe = new XStateConfig();

const initializingSessionMessages = new XStateConfig();
initializingSessionMessages.addTransition(EVENTS.MESSAGES_INITIALIZED, STATES.MAINTAINING_ACTIVITY_LOG);
initializingSessionMessages.addTransition(EVENTS.ERROR, STATES.NETWORK_ERROR);

const maintainingActivityLog = new XStateConfig();
maintainingActivityLog.addTransition(EVENTS.ACTIVITY_LOG_MAINTAINED, STATES.REDIRECTING_TO_RUBRIC);

// const uploadingOfflineFiles = new XStateConfig();
// uploadingOfflineFiles.addTransition(EVENTS.OFFLINE_FILES_UPLOADED, STATES.REDIRECTING_TO_RUBRIC);

const redirectingToRubric = new XStateConfig();
const networkError = new XStateConfig();


// #########################################
// LOGIN MACHINE
// #########################################

const _login = new XStateConfig();
_login.initialState = STATES.CHECKING_SET_PASSWORD;
_login.addState(STATES.CHECKING_SET_PASSWORD, checkingSetPasswordToken);
_login.addState(STATES.SET_PASSWORD, setPassword);
_login.addState(STATES.LOGIN_SET_PASSWORD, loginSetPassword);
_login.addState(STATES.SHOWING_FORM, showingForm);
_login.addState(STATES.CHECKING_ADMIN_REDIRECT, checkingAdminRedirect);
_login.addState(STATES.ADMIN_REDIRECT_URL, adminRedirectUrl);
_login.addState(STATES.ADMIN_REDIRECT_IFRAME, adminRedirectIframe);
_login.addState(STATES.INITIALIZING_SESSION_MESSAGES, initializingSessionMessages);
_login.addState(STATES.MAINTAINING_ACTIVITY_LOG, maintainingActivityLog);
// _login.addState(STATES.UPLOADING_OFFLINE_FILES, uploadingOfflineFiles);
_login.addState(STATES.REDIRECTING_TO_RUBRIC, redirectingToRubric);
_login.addState(STATES.NETWORK_ERROR, networkError);

const machine = Machine(_login.toObject());
machine.id = "Login Machine";


// #########################################
// EXPORT
// #########################################

const login = {
	machine,
	EVENTS: {...EVENTS},
	STATES: {...STATES}
}

export {login}