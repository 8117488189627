// npm
import React, { Component } from "react";
import { check } from "@xams-utils/check-types";
import { connect } from "react-redux";
import { withMessages } from "components/hocs/messages";

// react (layout)
import { Align } from "components/layout/align";

// react (concrete)
import { PdfCheckPanel } from "./pdf-check-panel";
import { AppPageModifier } from "components/pages/app-page-modifier";

// redux (selectors)
import { getExamData } from "redux/reducers/selectors";
import { getContentData } from "redux/reducers/exam/selectors";
import { getResourceUrls } from "redux/reducers/exam/content/resource-urls-selector";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getProctoringMode } from "redux/reducers/settings/client/selectors";
import { getAppSettingsData } from "redux/reducers/settings/selectors";
import { getPdfSupportedBrowsers } from "redux/reducers/settings/app/selectors";

import { isResource } from "../resource-helper";
import { checkPdfBrowserSupport } from "./pdf-browser-support";
import { PreloadPdfs } from "./preload-pdf";
import { LoadingSpinner } from "components/presentation/loading-spinner";

import { MESSAGE_IDS } from "constants/message-ids";

// PdfCheckPage (not connected to store)
// -----------------------------------------------------------------

class PdfCheckPage extends Component {
    state = {};
    constructor(props) {
        super(props);

        this.pdfSupported = this.pdfSupported.bind(this);

        this.state = { preload: false, checking: true };
    }

    componentDidMount() {
        const { hasPdf } = this.props;

        if (this.isBrowserSupported()) {
            if (hasPdf) {
                this.setState({ preload: true });
            } else {
                this.pdfSupported();
            }
        }

        this.setState({ checking: false });
    }

    isBrowserSupported() {
        const { hasPdf, browserSupport } = this.props;
        // return !hasPdf;
        return !hasPdf || check.assigned(browserSupport.supported);
    }

    pdfSupported() {
        const { onSuccess } = this.props;

        onSuccess();
    }

    render() {
        const { preload, checking } = this.state;

        if (preload) {
            const { resourceUrls } = this.props;
            const props = { resourceUrls, onComplete: this.pdfSupported };
            return (
                <React.Fragment>
                    <AppPageModifier
                        id="pdf-checker"
                        appBarProps={{
                            title: "Preloading PDFs",
                            loadingTitle: true,
                        }}
                    />
                    <LoadingSpinner size="lg" />;
                    <PreloadPdfs {...props} />
                </React.Fragment>
            );
        } else if (checking || this.isBrowserSupported()) {
            return null;
        }

        const { messages, proctoringMode, supportedBrowsers, browserSupport } =
            this.props;

        const title = messages[MESSAGE_IDS.PDF_CHECK.TITLE];

        const panelProps = {
            messages: {
                supported: messages[MESSAGE_IDS.PDF_CHECK.SUPPORTED],
                notSupported: messages[MESSAGE_IDS.PDF_CHECK.NOT_SUPPORTED],
                otherSupported: messages[MESSAGE_IDS.PDF_CHECK.OTHER_SUPPORTED],
                continue: messages[MESSAGE_IDS.PDF_CHECK.CONTINUE],
                cancel: messages[MESSAGE_IDS.PDF_CHECK.CANCEL],
            },
            proctoringMode,
            supportedBrowsers,
            browserSupport,
            onContinue: this.pdfSupported,
        };

        return (
            <React.Fragment>
                <AppPageModifier
                    id="pdf-checker"
                    appBarProps={{ loading: false, title, logout: true }}
                />
                <Align top>
                    <PdfCheckPanel {...panelProps} />
                </Align>
            </React.Fragment>
        );
    }
}

// PdfCheckPage (connected to store)
// -----------------------------------------------------------------

PdfCheckPage = withMessages(PdfCheckPage);

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const contentData = getContentData(examData);

    const resourceUrls = getResourceUrls(contentData);
    const hasPdf = resourceUrls.some((resourceUrl) =>
        isResource(resourceUrl, "pdf")
    );

    const returnObj = { hasPdf };
    if (hasPdf) {
        const settingsData = getSettingsData(store);
        const appSettingsData = getAppSettingsData(settingsData);

        const supportedBrowsers = getPdfSupportedBrowsers(appSettingsData);

        returnObj.supportedBrowsers = supportedBrowsers;
        returnObj.browserSupport = checkPdfBrowserSupport(supportedBrowsers);
        returnObj.proctoringMode = getProctoringMode(
            getClientSettingsData(settingsData)
        );

        if (returnObj.browserSupport.supported) {
            returnObj.resourceUrls = resourceUrls.filter((resourceUrl) =>
                isResource(resourceUrl, "pdf")
            );
        }
    } else {
        returnObj.browserSupport = { supported: true };
    }

    return returnObj;
};

PdfCheckPage = connect(mapStoreToProps)(PdfCheckPage);

// PdfCheckPage (EXPORT)
// -----------------------------------------------------------------
export { PdfCheckPage };
