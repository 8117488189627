
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// material-ui
import Fab from '@material-ui/core/Fab'
import withStyles from '@material-ui/core/styles/withStyles'

// react
import {BookmarkIcon} from './bookmark-icon'

// redux (selectors)
import {getExamData} from 'redux/reducers/selectors'
import {getContentData} from 'redux/reducers/exam/selectors'
import {getParentSectionId} from 'redux/reducers/exam/content/mappings/selectors'
import {getCurrentQuestionId, getMappingsData} from 'redux/reducers/exam/content/selectors'
import {hasSamePaperPart, getQuestionNoInSection, getPaperPartId} from 'redux/reducers/exam/content/complex-selectors'
import {getId, getNumber, isBookmarked, isAnswered, isDisabled} from 'redux/reducers/exam/content/questions/question/selectors'

// redux (actions)
import {setCurrentQuestionId} from './actions'



// QuestionButton (not connected to store)
// ----------------------------------------------------------------------------

let QuestionButton = (props) =>
{
	const classes = {
		label: props.selected ? props.classes.activeLabel : undefined,
		disabled: props.disabled ? props.classes.buttonDisabledColor : 
						  props.selected ? props.classes.buttonActiveColor : props.classes.buttonColor
	}

	const fabProps = {
		size: 'small',
		onClick: props.onClick,
		disabled: props.disabled,
		className: props.classes.button,
		color: props.answered ? 'primary' : 'default',
		classes: !props.selected ? {} : {label: props.classes.activeLabel}
	}

	return (
		<div style={{order: props.number}}>
			<Fab {...fabProps}>
				{props.number}
				<BookmarkIcon active={props.bookmarked}/>
			</Fab>
		</div>
	);
}

QuestionButton.propTypes = {
	classes: PropTypes.object.isRequired,
	number: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
	bookmarked: PropTypes.bool.isRequired,
	answered: PropTypes.bool.isRequired,
	selected: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired
}


// QuestionButton (connected to styles)
// ----------------------------------------------------------------------------

const styles = ({spacing, palette}) => ({
	activeLabel: {
		textDecoration: 'underline'
	},
	button: {
		margin: spacing.unit / 2,
		padding: 0,
	},
	buttonColor: {
		backgroundColor: palette.primary.main + "!important",
		color: palette.primary.contrastText + "!important"
	},
	buttonActiveColor: {
		backgroundColor: palette.primary.dark + "!important",
		color: palette.primary.contrastText + "!important"
	},
	buttonDisabledColor: {
		backgroundColor: palette.primary.light + "!important",
		color: palette.background.contrastText + "!important"
	}
});

QuestionButton = withStyles(styles)(QuestionButton);


// QuestionButton (connected to store)
// ----------------------------------------------------------------------------

const mapStoreToProps = (store) => {
	const contentData = getContentData(getExamData(store));
	const currentQuestionId = getCurrentQuestionId(contentData);

	return {
		paperPartId: getPaperPartId(contentData)(currentQuestionId),
		currentQuestionId,
		contentData
	}
};

const mapDispatchToProps = (dispatch) => ({dispatch});

const mergeProps = ({currentQuestionId, paperPartId, contentData}, {dispatch}, {questionData, onClick}) => ({
	selected: getId(questionData) === currentQuestionId,
	bookmarked: isBookmarked(questionData),
	answered: isAnswered(questionData),
	disabled: isDisabled(questionData) || !hasSamePaperPart(contentData)(currentQuestionId, getId(questionData)),
	// number: paperPartId ? getQuestionNoInSection(contentData)(paperPartId, getId(questionData)) : getNumber(questionData),
	number: getNumber(questionData),
	onClick
})

const args = [mapStoreToProps, mapDispatchToProps, mergeProps];
QuestionButton = connect(...args)(QuestionButton);


// Export
// ----------------------------------------------------------------------------
export {QuestionButton}