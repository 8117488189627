
const styles = ({spacing, breakpoints, palette}) =>
{
	return {
		layout: {
		  width: 'auto',
		  display: 'block', // Fix IE11 issue.
		  marginLeft: spacing.unit * 2,
		  marginRight: spacing.unit * 2,
		  [breakpoints.up(600 + spacing.unit * 3 * 2)]: {
				width: 600,
				marginLeft: 'auto',
				marginRight: 'auto',
				overflowY: 'hidden',
			},
		},
		card: {
			position: 'relative',
		  marginTop: spacing.unit * 2,
			backgroundColor: palette.background.light,
			padding: `${spacing.unit * 2}px ${spacing.unit * 3}px ${spacing.unit * 3}px`
		},
		cardContent: {
		  display: 'flex',
		  flexDirection: 'column',
		  alignItems: 'center'
		},
		paperContent: {
		  display: 'flex',
		  flexDirection: 'column',
		  alignItems: 'center'
		},
		form: {
		  width: '100%', // Fix IE11 issue.
		  marginTop: spacing.unit,
		},
		formText: {
			color: palette.background.contrastText
		},
		formInputText: {
			color: palette.background.contrastText + "!important"
		},
		submit: {
			marginTop: spacing.unit * 3,
		},
		submitDisabled: {
			backgroundColor: palette.background.light + "!important",
			color: palette.background.contrastText + "!important"
		},
		loginInfo: {
			marginTop: spacing.unit * 3,
		},
		errorContainer: {
			height: spacing.unit * 2,
			padding: spacing.unit / 2,
			display: 'flex',
			alignItems: 'center'
		},
		error: {
			color: palette.error.main
		},
		errorIcon: {
			marginRight: spacing.unit
		},
		logoBox: {
			marginTop: 4,
			marginBottom: spacing.unit * 3,
			overflow: 'hide'
		},
		logo: {
			maxWidth: '100%'
		},
		input: {
			'&:-webkit-autofill': {
				content: '"filled"'
			}
		},
		inputUnderline: {
			'&:before': {
				borderBottom: `1px solid ${palette.background.contrastText} !important`
			}
		},
		forgotten:{
			// paddingTop: spacing.unit*2,
			display:'flex',
			justifyContent: 'flex-end',
			cursor: 'pointer',
		}
	}
}
 

export {styles}