import moment from "moment";
import { check } from "@xams-utils/check-types";
import { incrementTimeStamp } from "utils/time-stamp";

const hasDateExpired = (currentDate, recordDate, expired = 30) => {
    const date1 = new moment(currentDate).subtract(expired, "days");
    const date2 = new moment(recordDate);

    return date2 < date1;
};

const ensureUniqueIDS = (items) => {
    for (let i = 0; i < items.length; i++) {
        if (i == 0) continue;
        if (items[i - 1]._id === items[i]._id)
            items[i]._id = incrementTimeStamp(items[i - 1]._id);
    }
    return items;
};

const removeTypeFromValue = (_obj) => {
    const obj = {};
    
    if (check.object(_obj)){
        Object.keys(_obj).forEach((key) => {
            if (key !== "type") obj[key] = _obj[key];
        });
    }

    return obj;
};

const encryptValue=(_value) => {
    if (!_value) return '';

    const value = removeTypeFromValue(_value);

    //return value;

    if (check.object(value) || check.string(value)) {
        const textValue = check.object(value) ? JSON.stringify(value) : value;

        try{
            return window.btoa(textValue);
        }
        catch(e){
            try{
                window.btoa(unescape(encodeURIComponent(textValue)));
            }
            catch(e){
                return value;
            }
        }     
    }
    
    return value;
}

const decryptValue=(value) => {
    if (!value) return '';

    try{
        return window.atob(value);
    }
    catch(e){
        return value;
    }
}

const displayToConsole=(text1=null, text2=null, text3=null)=>{
    if (check.assigned(text1) && check.assigned(text2) && check.assigned(text3)) console.log(text1, text2, text3);
    else if (check.assigned(text1) && check.assigned(text2)) console.log(text1, text2);
    else if (check.assigned(text1)) console.log(text1);
}

const getActivityTypeName=(ACTIVITIES, activityType)=>{
    let activityName = 'Not Known';

    for (const key in ACTIVITIES) {
        if (ACTIVITIES[key] === activityType) {
            activityName = key;
        }
    }

    return activityName;
}

const displayActivityLogToConsole = (ACTIVITIES, {activities, include, exclude}) => {
    activities.forEach((activity)=>{
        let canDisplay = true;

        if (include && include.length>0){
            if (include.indexOf(activity.type)===-1){
                canDisplay = false;
            }
        }
        else if (exclude && exclude.length>0){
            if (exclude.indexOf(activity.type)!==-1){
                canDisplay = false;
            }
        }
        
        if (canDisplay){
            const activityName = getActivityTypeName(ACTIVITIES, activity.type);
            console.log(activity.id, activityName, decryptValue(activity.value));
        }
    })
}


export { hasDateExpired, ensureUniqueIDS, encryptValue, decryptValue, displayToConsole, displayActivityLogToConsole, getActivityTypeName };
