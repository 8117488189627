
// npm
import React from 'react'
import PropTypes from 'prop-types'

// react
import {AnswerBox} from './answer-box'
import {McqAnswerForm} from '../answer_forms/mcq'

// redux (selectors)
import {getOptionsData} from 'redux/reducers/exam/content/questions/question/radios/selectors'
import {getSelectedOptionData} from 'redux/reducers/exam/content/questions/question/radios/options/selectors'
import {getOptionText, getOptionId} from 'redux/reducers/exam/content/questions/question/radios/options/option/selectors'


class RadiosAnswerBox extends React.Component
{
	constructor(props)
	{
		super(props);
		this.setUpExtractionFunctions();
		this.setUpTransformationFunctions();
	}

	// extract information from questionData
	// --------------------------------------
	setUpExtractionFunctions()
	{
		this.extractInitialAnswer = () => undefined;

		this.extractResumedAnswer = () => {
			const optionsData = getOptionsData(this.props.data);
			const selectedOptionData = getSelectedOptionData(optionsData);
			return selectedOptionData && getOptionId(selectedOptionData);
		};

		this.extractFormData = () => {
			const options = getOptionsData(this.props.data).map(optionData => {
				const value = getOptionId(optionData).toString();
				const label = getOptionText(optionData);
				return {value, label};
			}).toArray();
			
			return {options};
		}
	}

	// convert between answer values
	// --------------------------------------
	setUpTransformationFunctions()
	{
		this.getFormValue = (formAnswer) => formAnswer.target.value;
		this.toFormValue = (localAnswer) => localAnswer ? localAnswer.toString() : undefined;
		this.toLocalAnswer = (formValue) => parseInt(formValue, 10);

		this.toServerAnswer = (localAnswer) => {
			if (!localAnswer) { return localAnswer; }
			const selectedOption = this.getSelectedOption(localAnswer);
			const optionId = getOptionId(selectedOption);
			const optionText = getOptionText(selectedOption);
			const options = [{optionID: optionId, optionText}];
			return {options, answerText: optionText};
		};
	}

	render()
	{
		const answerBoxProps = {
			FormComponent: McqAnswerForm,
			questionData: this.props.data,
			toFormValue: this.toFormValue,
			getFormValue: this.getFormValue,
			toLocalAnswer: this.toLocalAnswer,
			toServerAnswer: this.toServerAnswer,
			extractFormData: this.extractFormData,
			extractInitialAnswer: this.extractInitialAnswer,
			extractResumedAnswer: this.extractResumedAnswer,
			noQuestionPanel: this.props.noQuestionPanel
		}

		return <AnswerBox {...answerBoxProps}/>;
	}

	getSelectedOption(localAnswer)
	{
		const optionsData = getOptionsData(this.props.data);
		return optionsData.find(optionData => {
			return getOptionId(optionData) === localAnswer;
		})
	}
}

RadiosAnswerBox.propTypes = {
	data: PropTypes.object.isRequired
}


export {RadiosAnswerBox}